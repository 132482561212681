import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { ExpressionEval } from '../utils';

@Pipe({
  name: 'valueEval',
})
export class ValueEvalPipe implements PipeTransform {
  transform(value: any, expression?: string): any {
    let val = null;
    try {
      val = ExpressionEval.evaluate(expression, {
        '$value': value,
        '$msg': value,
      });
    } catch (_err) {
      // do nothing
    }
    return val;
  }
}
