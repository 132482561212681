import { WidgetBase } from './widget-base';
import { WidgetLineGroup } from './widget-line-group';

export class WidgetLineGraph extends WidgetBase {
  lineGroups?: WidgetLineGroup[];
  public rangeMin?: number;
  public rangeMax?: number;
  public override callsign?: string;
  public newDataExpiresInSeconds?: number = 60; // For Line Graph V2

  public static fromModel(model: any): WidgetLineGraph {
    const widget = new WidgetLineGraph();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    // Gridster
    this.minItemCols = model.minItemCols ? model.minItemCols : 2;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;

    // Range Min
    if (model.rangeMin !== undefined) {
      this.rangeMin = model.rangeMin;
    } else if (model.rangeMinimumValue !== undefined) {
      this.rangeMin = model.rangeMinimumValue;
    }

    // Range Max
    if (model.rangeMax !== undefined) {
      this.rangeMax = model.rangeMax;
    } else if (model.rangeMaximumValue !== undefined) {
      this.rangeMax = model.rangeMaximumValue;
    }

    if (model.callsign !== undefined) {
      this.callsign = model.callsign;
    }

    // newDataExpiresInSeconds
    if (model.newDataExpiresInSeconds !== undefined) {
      this.newDataExpiresInSeconds = model.newDataExpiresInSeconds;
    }

    if (model.lineGroups?.length > 0) {
      this.lineGroups = model.lineGroups.map((group, index) => {
        return WidgetLineGroup.fromModel(group, index);
      });
    }
  }
}
