import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@shared/services';

/**
 * Directive to show content only to legacy users
 * Components with this directive should eventually be removed once all users have migrated
 */
@Directive({ selector: '[appLegacyUsersOnly]' })
export class LegacyUsersOnlyDirective {
  @Input() set appLegacyUsersOnly(condition: boolean) {
    if (!condition || this.userService.user.isLegacyUser) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}
}
