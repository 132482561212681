import { Component, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { AppService, DevelopmentService, UserService, AccountService } from '@shared/services';
import { environment } from '@env/environment';
import { ESidebarStatus, GuiStateService } from '@shared/services/gui/gui-state.service';
import { FeatureFlags } from '@shared/services/feature-flag/feature-flags';
import { RocosIcon } from '@shared/models';
import { urls } from '@env/environment';
import { TranslocoService } from '@ngneat/transloco';
import { EXPERIMENTAL_FEATURE } from '@shared/enums';

interface Sidebar {
  groups: SidebarItemGroup[];
  pinGroup: SidebarItemGroup;
}

interface SidebarItemGroup {
  title: string;
  items: SidebarItem[];
  isAdminOnly?: boolean;
  experimentalFeature?: string;
}

interface SidebarItem {
  icon: string;
  label: string;
  url?: string;
  adminOnly?: boolean;
  experimentalFeature?: string;
  featureFlag?: FeatureFlags;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() public sidebarStatus: ESidebarStatus;
  public version = environment.version;
  public showLogging = false;
  public customerLogoUrl: string = null;
  public projectId: string;
  public sidebar: Sidebar = {
    pinGroup: {
      title: '',
      items: [],
    },
    groups: [],
  };

  public constructor(
    private appService: AppService,
    private developmentService: DevelopmentService,
    private userService: UserService,
    private accountService: AccountService,
    private guiState: GuiStateService,
    private translocoService: TranslocoService,
  ) {
    this.appService.projectIdChange.subscribe((projectId) => {
      this.onProjectIdChanged(projectId);
    });
    this.appService.accountIdChange.subscribe((accountId) => {
      this.reloadAccountSettings(accountId);
    });
    this.appService.pageTypeChange.subscribe((pageType) => {
      switch (pageType) {
        case 'account':
          this.guiState.updateSidebarStatus(ESidebarStatus.ForceHide);
          break;
        case 'project':
        case 'robot':
          this.onProjectIdChanged(this.appService.projectId);
          break;
      }
    });
  }

  public onMoveLeftButtonClick(): void {
    this.guiState.updateSidebarStatus(ESidebarStatus.Collapsed);
  }
  public onMoveRightButtonClick(): void {
    this.guiState.updateSidebarStatus(ESidebarStatus.Full);
  }

  public toggleLoggingVisibility(): void {
    // If not prod environment or current user is admin, then we display the logger window.
    if (!this.developmentService.isProd || this.userService.isAdmin()) {
      this.showLogging = !this.showLogging;
    }
  }

  private onProjectIdChanged(newProjectId?: string): void {
    if (newProjectId !== undefined) {
      this.projectId = newProjectId;
    }
    this.sidebar = this.createSidebar(this.projectId);
  }

  private reloadAccountSettings(accountId: string): void {
    // Reset logo url immediately.
    this.customerLogoUrl = null;

    this.accountService
      .getAccountSettings(accountId)
      .pipe(first())
      .subscribe((settings) => {
        let logoUrl = null;
        if (settings?.companyLogoURL) {
          logoUrl = settings.companyLogoURL;
        }

        if (logoUrl) {
          this.customerLogoUrl = logoUrl;
        } else {
          this.customerLogoUrl = null;
        }
      });
  }

  private createSidebar(projectId: string): Sidebar {
    const sidebar: Sidebar = {
      pinGroup: {
        title: '',
        items: [
          {
            icon: RocosIcon.home,
            label: this.translocoService.translate('common.project') || '',
            url: urls.project.index(projectId),
          },
        ],
      },
      groups: [
        {
          title: 'Connect',
          items: [
            {
              icon: RocosIcon.robots,
              label: this.translocoService.translate('common.robots') || '',
              url: urls.robots.index(projectId),
            },
            {
              icon: RocosIcon.robotDefinitions,
              label: this.translocoService.translate('common.robotDefinitions') || '',
              url: urls.robotDefinitions.index(projectId),
            },
          ],
        },
        {
          title: 'Automate',
          items: [
            {
              icon: RocosIcon.globalOperations,
              label: this.translocoService.translate('common.operations') || '',
              url: urls.operations.index(projectId),
            },
            {
              icon: RocosIcon.environment,
              label: this.translocoService.translate('common.environment') || '',
              url: urls.environment.index(projectId),
              featureFlag: FeatureFlags.EnvironmentRoute,
            },
            {
              icon: RocosIcon.workflow,
              label: this.translocoService.translate('common.automate') || '',
              url: urls.automate.index(projectId),
              experimentalFeature: EXPERIMENTAL_FEATURE.Automate,
            },
          ],
        },
        {
          title: 'Analyze',
          items: [
            {
              icon: RocosIcon.streams,
              label: this.translocoService.translate('common.storageStreams') || '',
              url: urls.streams.index(projectId),
            },
            {
              icon: RocosIcon.dataExplorer,
              label: this.translocoService.translate('common.storageExplorer') || '',
              url: urls.analyze.dataExplorer(projectId),
            },
          ],
        },
        {
          title: 'Admin Tools',
          items: [
            {
              icon: RocosIcon.storageQuery,
              label: this.translocoService.translate('common.storageQuery') || '',
              url: urls.playground.elasticsearch(projectId),
            },
          ],
          experimentalFeature: EXPERIMENTAL_FEATURE.SidebarAdminTools,
        },
      ],
    };
    return sidebar;
  }
}
