import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService, ProjectService } from '@shared/services';
import type { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, mergeMap, startWith } from 'rxjs/operators';

export interface DetailedNetworkInformation {
  downlink?: number;
  effectiveType?: 'slow-2g' | '2g' | '3g' | '4g';
  rtt?: number;
  saveData?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-navbar-connection',
  template: `
    <ng-container *ngIf="projectLoaded">
      <ng-container *ngIf="state$ | async; let state">
        <div class="wrapper" [ngClass]="getStatus(state)?.toLocaleLowerCase()" *ngIf="getStatus(state) !== 'OK'">
          <p class="warn-text">Please check your internet connection</p>
          <span
            [matTooltip]="'Unstable connection DL: ' + state.downlink + ' Mbps , RTT: ' + state.rtt + ' ms'"
            class="ri-connectivity icon"
          ></span>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./navbar-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarConnectionComponent implements OnInit {
  state$: Observable<DetailedNetworkInformation>;
  projectLoaded = false;

  private minimumDownlinkMbps = 1;
  private maximumRttMs = 1000;

  constructor(private projectService: ProjectService, private appService: AppService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!('connection' in navigator)) {
      console.warn('Cannot determine connection quality, unsupported browser.');
      return;
    }

    this.appService.projectIdChange
      .pipe(
        untilDestroyed(this),
        startWith(this.appService.projectId),
        filter((projectId) => !!projectId),
        mergeMap((projectId) => this.projectService.getProjectDefinition(projectId)),
      )
      .subscribe((projectConfig) => {
        this.minimumDownlinkMbps = projectConfig?.connection?.minimumDownlink ?? this.minimumDownlinkMbps;
        this.maximumRttMs = projectConfig?.connection?.minimumRtt ?? this.maximumRttMs;
        this.projectLoaded = true;
        this.cdr.markForCheck();
      });

    this.state$ = fromEvent(navigator.connection, 'change').pipe(
      untilDestroyed(this),
      map((ev: any) => ev.target),
      debounceTime(5000),
      startWith(navigator.connection),
    );
  }

  getStatus(connection: DetailedNetworkInformation): 'OK' | 'ERROR' {
    if (connection.downlink < this.minimumDownlinkMbps) return 'ERROR';
    if (connection.rtt > this.maximumRttMs) return 'ERROR';

    return 'OK';
  }
}
