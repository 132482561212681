import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import type { EditorOptions } from '@shared-modules/code-editor-v3/monaco-editor-config';
import { EditorLanguage, getEditorOptions } from '@shared-modules/code-editor-v3/monaco-editor-config';
import { EditorComponent } from '@shared-modules/monaco-editor';

const LINE_HEIGHT = 18;
const MAX_HEIGHT = 500;
@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CodeEditorComponent),
    },
  ],
})
export class CodeEditorComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {
  @ViewChild('editorElement') editorElement: EditorComponent;

  @Input() autoHeight: boolean = true;
  @Input() language: EditorLanguage = 'javascript';
  @Input() isJson: boolean = false;

  public code: string = '';
  public editorOptions: EditorOptions;

  public ngOnInit() {
    if (this.isJson) {
      this.editorOptions = getEditorOptions('json');
    } else {
      this.editorOptions = getEditorOptions(this.language);
    }
  }

  public ngAfterViewInit() {
    this.onEditorInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['language']) {
      this.editorOptions = getEditorOptions(this.language);
    }
  }

  public writeValue(value: string) {
    this.code = value;
  }

  public registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  public registerOnTouched() {
    // do nothing
  }

  public onEditorInit() {
    if (this.autoHeight) {
      this._updateHeight(this.code);
    }
  }

  public onCodeChange(change: string) {
    this.code = change;
    this._propagateChange(this.code);

    if (this.autoHeight) {
      this._updateHeight(this.code);
    }
  }

  private _updateHeight(code) {
    const countOfLines = code.split('\n').length;
    if (countOfLines >= 1) {
      const currentHeight = countOfLines * LINE_HEIGHT;
      const appliedHeight = currentHeight > MAX_HEIGHT ? MAX_HEIGHT : currentHeight;
      this.editorElement._editorContainer.nativeElement['style'].height = `${appliedHeight}px`;
    }
  }

  private _propagateChange = (_: any) => {
    // do nothing
  };
}
