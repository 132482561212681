<div class="rs-card">
  <div class="head">
    <div class="title with-spinner">Controls</div>
    <div class="right-buttons">
      <button mat-flat-button class="left-icon-button" color="primary" (click)="onAddNewControl()">
        <span class="ri-add"></span>
        <span>Add Control</span>
      </button>
    </div>
  </div>

  <app-loading-content [isLoading]="isLoading">
    <div class="body p-0">
      <div>
        <table mat-table [dataSource]="[]" #componentsTable>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let item"> </mat-cell>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
            <mat-cell *matCellDef="let item"> </mat-cell>
          </ng-container>

          <!-- Parameters Column -->
          <ng-container matColumnDef="parameters">
            <mat-header-cell *matHeaderCellDef> Parameters </mat-header-cell>
            <mat-cell *matCellDef="let item"> </mat-cell>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="editControls">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item; let idx = index">x </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="hover-row"></mat-row>
        </table>
      </div>

      <div>
        <ng-container *ngFor="let item of items; let idx = index">
          <div class="command">
            <app-gamepad-control
              [control]="item?.value"
              (controlChange)="onControlChange(item, $event, idx)"
              (deleteChange)="onRemoveControl(idx)"
              [isEditing]="!item || !item.id || !item.value || !item.value.id"
              [controlActions]="controlActions"
              [currentModule]="currentModule"
              [isOverridden]="item?._isOverridden"
              [functions]="functions"
            >
            </app-gamepad-control>
          </div>
        </ng-container>
      </div>
    </div>
  </app-loading-content>
</div>
