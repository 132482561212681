import { Injectable } from '@angular/core';
import type { GamepadControl, GamepadButtonAction, GamepadExecuteAction, GamepadPayloadContext } from '../../models';
import { GamepadVariablesResult } from './gamepad-variable.service';
import { GamepadPayloadHelper } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class GamepadActionPayloadService {
  controls: GamepadControl[] = [];

  setControls(controls: GamepadControl[]): void {
    this.controls = controls;
  }

  getPayloadsWithActionAndVariables(
    action: GamepadButtonAction,
    variablesResult: GamepadVariablesResult,
    context?: GamepadPayloadContext,
  ): any[] {
    let payloads: any[] = [];

    const commandId = action.commandId;

    // Find target commands
    const targetControls = this.filterControlsById(commandId);

    if (targetControls?.length > 0) {
      const executeActions: GamepadExecuteAction[] = [];

      targetControls.forEach((command) => {
        const parametersObj = GamepadVariablesResult.toParametersObj(variablesResult);
        const actions = command.actions;

        executeActions.push({
          actions,
          parametersObj,
        });
      });

      payloads = GamepadPayloadHelper.getExecutePayloadsByActions(executeActions, context);

      return payloads;
    }

    return payloads;
  }

  filterControlsById(commandId: string) {
    if (this.controls?.length > 0) {
      return this.controls.filter((cmd) => {
        return cmd.id === commandId;
      });
    }

    return [];
  }
}
