<div class="box">
  <div class="header">
    <div class="left">
      <div class="icon">
        <span [ngClass]="sourceInfo.iconClass"></span>
      </div>
      <div class="info">
        <div class="prefix">
          <span [matTooltip]="sourceInfo.prefix">{{ sourceInfo.prefix }}</span>
        </div>
        <div class="title">
          <span [matTooltip]="sourceInfo.id"
            >{{ sourceInfo.id }} {{ sourceInfo.dataType === 'malformed' ? ' (malformed topic schema)' : '' }}</span
          >
        </div>
      </div>
    </div>
    <div class="actions">
      <button type="button" class="left-icon-button" mat-stroked-button color="primary" (click)="onCopyURI()">
        <span class="icon ri-copy"></span>
        <span> Copy URI </span>
      </button>

      <div class="operations" *ngIf="currentPreviewMethod?.name === 'table'">
        <div class="log-filter">
          <form [formGroup]="filterForm" class="">
            <ng-container>
              <button
                type="button"
                class="right-icon-button compact-icon"
                mat-stroked-button
                color="primary"
                [matMenuTriggerFor]="menuLogFilters"
                tabindex="-1"
              >
                <span class="ri-table-settings"></span><span class="ri-nav-down"></span>
              </button>
            </ng-container>

            <mat-menu #menuLogFilters="matMenu">
              <div class="filter-menu">
                <mat-radio-group formControlName="displayOption" (change)="onTableDisplayOptionChanged($event)">
                  <div class="display-options">
                    <mat-radio-button value="refresh">Refresh Table</mat-radio-button>
                    <div class="radio-seperator"></div>
                    <mat-radio-button value="append">Append Rows</mat-radio-button>
                  </div>
                </mat-radio-group>
                <mat-divider class="divider mb-2"></mat-divider>

                <div formArrayName="filters">
                  <ng-container
                    *ngFor="let filter of formArrayFilters.controls; let i = index"
                    [formGroupName]="i"
                    class=""
                  >
                    <div mat-menu-item class="filter-item">
                      <mat-checkbox class="" formControlName="checkedStatus" (change)="onFilterChanged()">
                        {{ filter.value.label }}
                      </mat-checkbox>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </form>
        </div>
      </div>
      <ng-container *ngIf="previewMethods && previewMethods.length > 1">
        <button
          type="button"
          class="right-icon-button"
          mat-stroked-button
          color="primary"
          [matMenuTriggerFor]="menuPreviewMethod"
          tabindex="-1"
        >
          <span>{{ currentPreviewMethod?.text }}</span>
          <span class="ri-nav-down"></span>
        </button>
      </ng-container>

      <button
        type="button"
        class="left-icon-button"
        mat-stroked-button
        color="primary"
        matTooltip="Insert"
        (click)="onInsertDataURI()"
        *ngIf="sourceInfo.isInsertable && insertDataURIMode"
      >
        <span class="icon ri-copy-link"></span>
        <span> Insert </span>
      </button>

      <div class="more">
        <button mat-icon-button matTooltip="more" [matMenuTriggerFor]="moreInfoMenu" tabindex="-1">
          <span class="ri-more icon-button grey-icon"></span>
        </button>
        <mat-menu #moreInfoMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="onClosePreview()">
            <span class="label"> Close preview </span>
          </button>
          <button
            *ngIf="currentPreviewMethod.name !== 'service'"
            mat-menu-item
            (click)="sourceInfo.showStreamQuery = !sourceInfo.showStreamQuery"
          >
            <ng-container *ngIf="sourceInfo.showStreamQuery">
              <span class="label"> Hide stream query </span>
              <!-- <span class="icon">
                <span class="ri-hidden"></span>
              </span> -->
            </ng-container>
            <ng-container *ngIf="!sourceInfo.showStreamQuery">
              <span class="label"> Show stream query </span>
              <!-- <span class="icon">
                <span class="ri-view"></span>
              </span> -->
            </ng-container>
          </button>
          <button
            *ngIf="currentPreviewMethod.name !== 'service'"
            mat-menu-item
            (click)="sourceInfo.showPerfMon = !sourceInfo.showPerfMon"
          >
            <ng-container *ngIf="sourceInfo.showPerfMon">
              <span class="label"> Hide performance monitor </span>
              <!-- <span class="icon">
                <span class="ri-hidden"></span>
              </span> -->
            </ng-container>
            <ng-container *ngIf="!sourceInfo.showPerfMon">
              <span class="label"> Show performance monitor </span>
              <!-- <span class="icon">
                <span class="ri-view"></span>
              </span> -->
            </ng-container>
          </button>
        </mat-menu>
      </div>

      <!-- Menu -->

      <mat-menu #menuPreviewMethod="matMenu">
        <button mat-menu-item *ngFor="let method of previewMethods" (click)="onSelectPreviewMethod(method)">
          <span class="ri-checkmark check-mark" [class.show]="method.name === currentPreviewMethod.name"></span>
          <span class="label">
            {{ method.text }}
            <!-- Right Padding -->
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="sourceInfo.showStreamQuery">
    <app-data-custom-query
      [callsign]="callsign"
      [source]="sourceInfo.source"
      [path]="sourceInfo.path"
      [dataType]="sourceInfo.dataType"
      [querystring]="sourceInfo.querystring"
      (dataURIChange)="sourceChanged($event)"
    >
    </app-data-custom-query>
  </div>

  <div *ngIf="sourceInfo.showPerfMon">
    <app-data-performance
      [callsign]="callsign"
      [path]="sourceInfo.path"
      [payload]="rawOutput"
      [latestMessageReceivedAt]="latestMessageReceivedAt"
    >
    </app-data-performance>
  </div>

  <div>
    <ng-container [ngSwitch]="currentPreviewMethod.name">
      <ng-container *ngSwitchCase="'table'">
        <app-json-data-table
          [isApendingPaused]="isApendingPaused"
          [isApendingMode]="isApendingMode"
          [contentObservable]="dataForTableObservable"
          [displayedColumns]="displayedColumns"
          theme="liveData"
          [displayedColumnsNames]="displayedColumnsNames"
        >
        </app-json-data-table>
      </ng-container>

      <ng-container *ngSwitchCase="'service'">
        <div class="service-container" [formGroup]="serviceForm">
          <ng-container *ngIf="serviceForm.value.serviceRequest !== 'null'">
            <div class="request-payload-header">Request</div>
            <div class="http-payload">
              <app-code-editor [isJson]="true" class="editor" formControlName="serviceRequest"> </app-code-editor>
            </div>
          </ng-container>
          <div class="service-buttons">
            <div
              class="status-text has-error"
              *ngIf="currentStatus?.status > 0 && currentStatus?.responseType === 'result'"
            >
              {{ defaultErrorMessage(currentStatus.status, currentStatus.message) }}
            </div>
            <div
              class="status-text"
              *ngIf="
                currentStatus.status !== undefined &&
                !(currentStatus?.status > 0 && currentStatus?.responseType === 'result')
              "
            >
              <span *ngIf="currentStatus?.message">
                {{ currentStatus?.message }}
              </span>
              <span
                *ngIf="!isLoading && currentStatus.status === successStatus && !currentStatus?.message"
                [class.success-result]="true"
              >
                {{ 'Success' }}
              </span>
            </div>

            <div class="spacer"></div>
            <app-loading-button class="loading-button" *ngIf="isLoading" (click)="cancelService()">
              <span>Cancel Service</span>
            </app-loading-button>
            <app-loading-button class="loading-button" [loading]="isLoading" (click)="callService()">
              <span>Call Service</span>
            </app-loading-button>
          </div>

          <mat-divider class="divider"></mat-divider>

          <div class="response-payload-header">Response</div>
          <div class="response-container">
            <div class="response-payload" *ngFor="let responsePayload of responsePayloads; let idx = index">
              <pre>{{ responsePayload.payload }}</pre>
              <div class="response-divider">
                <mat-divider class="divider"></mat-divider>
                <div *ngIf="true" class="response-timestamp">{{ responsePayload.createdTime | date : 'medium' }}</div>
              </div>
              <div
                class="payload-copy-button"
                *ngIf="responsePayload.payload"
                (click)="saveToClipboard(responsePayload.payload)"
              >
                <span class="ri-copy-to-clipboard"></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'json'">
        <div class="response-payload">
          <div class="pre-container" #preContainer>
            <pre class="preview">{{ output }}</pre>
          </div>
          <div class="payload-copy-button" *ngIf="output !== undefined" (click)="saveToClipboard(rawOutput)">
            <span class="ri-copy-to-clipboard"></span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'lineGraph'">
        <app-preview-line-graph #lineGraphComponent></app-preview-line-graph>
      </ng-container>
    </ng-container>
  </div>
</div>
