import type { ValueTypes } from './primitives';
import type { Scene } from '@babylonjs/core';
import { Color3, type Material, StandardMaterial } from '@babylonjs/core';

export type MaterialTypes =
  | 'red'
  | 'yellow'
  | 'green'
  | 'hot-pink'
  | 'teal'
  | 'pure-green'
  | 'gray'
  | 'gray-transparent'
  | 'gray-wireframe'
  | 'dark-grid'
  | 'simple-grid'
  | 'shadow-receiver-only'
  | 'pointcloud-white'
  | 'voxel-coloured'
  | 'controlPoint';

export type MaterialPropertyType =
  | 'diffuseColor'
  | 'specularColor'
  | 'ambientColor'
  | 'wireframe'
  | 'alpha'
  | 'diffuseTexture'
  | 'bumpTexture'
  | 'pointsCloud'
  | 'pointSize'
  | 'gridRatio'
  | 'mainColor'
  | 'lineColor'
  | 'alphaMode';

export type MaterialValueType = ValueTypes | 'texture';

export const materialPropertyValueTypes: Record<MaterialPropertyType, MaterialValueType> = {
  diffuseColor: 'color3',
  specularColor: 'color3',
  ambientColor: 'color3',
  wireframe: 'boolean',
  alpha: 'number',
  diffuseTexture: 'texture',
  bumpTexture: 'texture',
  pointsCloud: 'boolean',
  pointSize: 'number',
  gridRatio: 'number',
  mainColor: 'color3',
  lineColor: 'color3',
  alphaMode: 'number',
};

export type MaterialTextureTypes = 'diffuseTexture' | 'bumpTexture';

export enum MapMaterial {
  GREEN = 'map-green',
  YELLOW = 'map-yellow',
  TEAL = 'map-teal',
  WHITE = 'map-white',
  RED = 'map-red',
}

export const initMapMaterials = (scene: Scene): void => {
  createMaterial(scene, MapMaterial.GREEN, Color3.Green());
  createMaterial(scene, MapMaterial.YELLOW, Color3.Yellow());
  createMaterial(scene, MapMaterial.TEAL, Color3.Teal());
  createMaterial(scene, MapMaterial.WHITE, Color3.White());
  createMaterial(scene, MapMaterial.RED, Color3.Red());
};

const createMaterial = (scene: Scene, materialName: string, color: Color3): Material => {
  const existingMaterial = scene.getMaterialByName(materialName);
  if (existingMaterial) return existingMaterial as Material;
  const material = new StandardMaterial(materialName, scene);
  material.diffuseColor = color;
  material.ambientColor = color;
  material.emissiveColor = color;
  material.alpha = 0.25;
  material.backFaceCulling = false;
  material.freeze();
  return material;
};
