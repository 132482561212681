import { Injectable } from '@angular/core';
import { mean } from 'lodash';
import type { Subscription } from 'rxjs';
import { Observable, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { RocosClientService } from '../rocos-client';
@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  constructor(private rocosClientService: RocosClientService) {}

  getCommandNetworkLatencyOfRobot(
    projectId: string,
    callsign: string,
    frequencyInSeconds: number = 5,
    destination: string = '/rocos/agent/ping',
    devidedBy2: boolean = true,
    averagedBy: number = 5,
  ): Observable<number> {
    const timerRx = timer(0, frequencyInSeconds * 1000);
    const latencyList = [];
    averagedBy = Math.max(Math.round(averagedBy), 1);
    frequencyInSeconds = Math.max(frequencyInSeconds, 1);

    return timerRx.pipe(
      flatMap(() => {
        return new Observable<number>((observer) => {
          const time1 = Date.now();

          const getAverageLatency = (newLatency) => {
            latencyList.push(newLatency);

            const numToCut = latencyList.length - averagedBy;
            latencyList.splice(0, numToCut);

            return Math.round(mean(latencyList));
          };

          const sub: Subscription = this.rocosClientService
            .sendCommandWithAck(projectId, callsign, destination, null)
            .subscribe(
              (res) => {
                if (res?.stage === 'final') {
                  sub.unsubscribe();

                  const time2 = Date.now();
                  let latency = time2 - time1;

                  if (devidedBy2) {
                    latency = latency / 2;
                  }
                  const average = getAverageLatency(latency);

                  observer.next(average);
                  observer.complete();
                }
              },
              (_err) => {
                sub.unsubscribe();
                observer.next(-1);
                observer.complete();
              },
            );
        });
      }),
    );
  }
}
