<div class="page-404">
  <div class="center">
    <div class="center-content">
      <h1 class="not-found">
        <span>404</span>
      </h1>
      <div class="main-title">Johnny 5 is alive. But unfortunately this page is not…</div>
      <div class="subtitle">
        Maybe try searching for what you're looking for, or head over to our
        <app-external-link linkType="link" targetType="docs">help docs</app-external-link>.
      </div>
    </div>
  </div>
  <div class="bottom-image">
    <img src="assets/images/404-page/johnny_5.png" />
  </div>
</div>
