export class StringUtils {
  static getValidName(str: string) {
    return str.replace(/[^\dA-Za-z]+/g, '-').toLowerCase();
  }

  static containsArithmeticOperators(str: string): boolean {
    const regex: RegExp = /[/+\-*]+/;
    return regex.test(str);
  }

  static containsParentheses(str: string): boolean {
    const regex: RegExp = /[()]+/;

    return regex.test(str);
  }

  static stringToArrayBuffer(str: string) {
    const array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      array[i] = str.charCodeAt(i);
    }
    return array.buffer;
  }

  static trim(str: string, multipleSpacesToOne = false) {
    let trimmed = str;
    if (trimmed) {
      trimmed = trimmed.trim();
    }
    if (trimmed && multipleSpacesToOne) {
      trimmed = trimmed.replace(/\s\s+/g, ' ');
    }

    return trimmed;
  }
}
