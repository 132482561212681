import { StreamSource } from './stream-source';
import type { IStreamSource } from '@team-rocos/rocos-js';

export class Stream {
  public id?: string;
  public name: string;
  public description?: string;
  public tags: string[];
  public data?: StreamSource[];
  public numberOfRobots: number;
  public callsigns?: string[];
  public robotDefinitionIds: string[];

  public color?: string;
  public enabledChart = false;

  public constructor(name?: string, tags?: string[], numberOfRobots?: number) {
    this.name = name ? name : '';
    this.tags = tags ? tags : [];
    this.numberOfRobots = numberOfRobots ? numberOfRobots : 0;
  }

  public static fromModel(model: {
    id?: string;
    name: string;
    description?: string;
    robotDefinitionIds: string[];
    allCallsigns?: string[];
    callsigns?: string[];
    data?: IStreamSource[];
  }): Stream {
    const stream = new Stream();

    stream.id = model.id;
    stream.name = model.name;
    stream.description = model.description;
    stream.robotDefinitionIds = model.robotDefinitionIds;

    if (model.allCallsigns) {
      stream.callsigns = model.allCallsigns;
    } else if (model.callsigns) {
      stream.callsigns = model.callsigns;
    }

    if (model.data) {
      stream.data = model.data.map((source) => {
        return StreamSource.fromModel(source);
      });
    }

    return stream;
  }
}
