<div class="main-container">
  <div class="camera-control-container">
    <button
      (click)="$event.preventDefault()"
      mat-icon-button
      matTooltip="Change Camera"
      [matMenuTriggerFor]="cameraMenu"
      tabindex="-1"
    >
      <span class="icon ri-camera"></span>
    </button>

    <mat-menu #cameraMenu="matMenu">
      <button
        *ngFor="let camera of rendererModelService.sceneManager.camerasByKey"
        mat-menu-item
        (click)="setCamera(camera.key)"
      >
        <span class="icon"> </span>
        <span class="label text-capitalize">
          {{ camera.key }}
        </span>
      </button>
    </mat-menu>
  </div>

  <div class="js-control-container" *ngIf="jsWidgets.length">
    <!-- Run Js-widget -->
    <button
      (click)="$event.preventDefault()"
      mat-icon-button
      matTooltip="Javascript Widgets"
      [matMenuTriggerFor]="jsWidgetsRef"
      tabindex="-1"
    >
      <span class="icon">JS</span>
    </button>

    <mat-menu #jsWidgetsRef="matMenu">
      <button *ngFor="let widget of jsWidgets" mat-menu-item (click)="runWidget(widget)">
        <span class="icon"> </span>
        <span class="label text-capitalize">
          {{ widget.widgetHeading }}
        </span>
      </button>
    </mat-menu>
  </div>

  <canvas #renderContainer class="canvas" name="renderContainer"></canvas>

  <div class="js-widgets" *ngFor="let widget of jsWidgets">
    <app-widget-javascript
      #widgetJavascript
      [widget]="widget"
      [code]="widget.code"
      [widgetId]="widget.id"
      [autoRun]="widget.autoRun"
      [vizRenderer]="vizRenderer"
      [context]="javascriptContext"
      [heading]="widget.widgetHeading"
      [theme]="'dark'"
      [type]="widget.widgetType"
      [editing]="false"
      [height]="''"
      [width]="''"
    >
    </app-widget-javascript>
  </div>
</div>
