export enum FeatureFlags {
  GeoTransforms = 'ground-robotics-geo-transforms',
  Integrations = 'ground-robotics-integrations',
  DockProvisioning = 'robotics-dock-provisioning',
  SendMessageLDV = 'robotics-send-messages-via-live-data-viewer',
  CommandPalette = 'robotics-command-palette',
  EnvironmentRoute = 'robotics-environment-route',
  ProfileFlows = 'robotics-profile-level-flows',
  ProfileFileSync = 'robotics-profile-level-file-sync',
}

export const defaultFeatureFlags: Record<FeatureFlags, boolean> = {
  [FeatureFlags.GeoTransforms]: false,
  [FeatureFlags.Integrations]: false,
  [FeatureFlags.DockProvisioning]: false,
  [FeatureFlags.SendMessageLDV]: false,
  [FeatureFlags.CommandPalette]: false,
  [FeatureFlags.EnvironmentRoute]: false,
  [FeatureFlags.ProfileFlows]: false,
  [FeatureFlags.ProfileFileSync]: false,
};
