<div class="table-view" [class.live-data]="theme === 'liveData'" *ngIf="inputDisplayedColumnsNames">
  <div (scroll)="onScroll($event)" class="table-container" [style.max-height]="maxHeight" #logTableContainer>
    <table mat-table [dataSource]="dataSource" class="logTable" [class.boldFirstColumn]="true" #logTable>
      <ng-container *ngFor="let column of preparedDisplayedColumns; let idx = index">
        <ng-container [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef>
            {{ inputDisplayedColumnsNames[column] }}
            <div class="cell-bottom-border"></div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div [class]="getLevelClass(element, column)" [class.level]="column === 'level'">
              <div>{{ element[column] }}</div>
            </div>
            <div class="cell-bottom-border"></div>
            <div class="cell-bottom-time">{{ element.time }}</div>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!_hideHeader">
        <tr mat-header-row *matHeaderRowDef="preparedDisplayedColumns"></tr>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: preparedDisplayedColumns" class="my-mat-row"></tr>
    </table>
  </div>

  <div class="jump-button" *ngIf="isApendingMode && isApendingPaused">
    <button
      type="button"
      mat-flat-button
      class="rs-inline-button"
      color="primary"
      tabindex="-1"
      (click)="onJumpButtonClicked()"
    >
      Jump to latest<span class="ri-indicator-down adjusted-margin"></span>
    </button>
  </div>
</div>
