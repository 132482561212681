<app-widget-base
  #widgetContainer
  [heading]="heading"
  [floorHeading]="widget && widget.pickerCommandId !== undefined"
  [type]="type"
  [dataURI]="dataURI"
  [isGroupWidget]="isGroupWidget"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [menuItems]="widgetMenuItems"
  [toolbarItems]="toolbarItems"
  (menuItemClick)="onMenuItemClick($event)"
  (toolbarItemClick)="onToolbarItemClick($event)"
  [status]="status"
  [theme]="'dark'"
>
  <div class="video-box rocos-dark-theme" [class.is-streaming]="videoStreaming" [class.is-connecting]="isConnecting">
    <div class="over-video-layer">
      <!-- Before Streaming -->
      <div *ngIf="!videoStreaming">
        <div class="advance-button">
          <!-- Advanced - P2P Only-->
          <ng-container *ngIf="widget?.videoSourceType === 'p2p'">
            <button mat-button class="rs-inline-button" type="button" (click)="isAdvancedMode = !isAdvancedMode">
              <span class="rs-left-button-icon ri-chevron-right" *ngIf="!isAdvancedMode"></span>
              <span class="rs-left-button-icon ri-chevron-down" *ngIf="isAdvancedMode"></span>
              <span>Advanced</span>
            </button>
          </ng-container>
        </div>
        <div *ngIf="isAdvancedMode" class="mt-4 w-100">
          <div class="mt-4 notice-message">This will not be saved and is just for testing the connection.</div>
          <div class="mt-4">
            <mat-form-field>
              <textarea
                matInput
                class="video-command"
                (input)="currentVideoCommand = $any($event.target).value"
                [value]="currentVideoCommand"
                rows="3"
              ></textarea>
              <mat-placeholder>Video Command</mat-placeholder>
            </mat-form-field>
            <div class="d-flex justify-content-end">
              <button type="button" mat-button color="primary" (click)="onReset()">Reset</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Streaming -->
      <div *ngIf="videoStreaming">
        <ng-container *ngIf="isConnecting">
          <div class="center-loading-spinner">
            <app-loading-spinner [isLoading]="true" [theme]="'light'" [size]="'x2'"></app-loading-spinner>
          </div>
        </ng-container>
      </div>

      <!-- Connection Messages -->
      <div class="connection-messages">
        <div>{{ videoSource?.message }}</div>
      </div>
    </div>

    <div
      #videoContainer
      (resized)="onResized()"
      class="video-container"
      [ngClass]="{
        'rotate90': widget?.rotation === 90,
        'rotate-90': widget?.rotation === -90,
        'rotate180': widget?.rotation === 180 || widget?.rotation === -180
      }"
    >
      <!-- Pick Coordinates Enable -->
      <ng-container *ngIf="videoStreaming">
        <ng-container *ngIf="pickerPosition">
          <div
            [ngStyle]="{
              'width': pickerPosition.width,
              'height': pickerPosition.height,
              'top': pickerPosition.top,
              'left': pickerPosition.left
            }"
            class="pick-coordinates-widget"
          >
            <app-widget-pick-coordinates
              (lastCoordinatePicked)="lastCoordinatePicked = $event"
              [projectId]="projectId"
              [robotCallsign]="callsign"
              [widget]="widget"
            >
            </app-widget-pick-coordinates>
          </div>
        </ng-container>
      </ng-container>

      <video class="video-player" #remoteVideoElement id="remotevideo" autoplay loop></video>

      <!-- Video Debug Infos -->
      <ng-container *ngIf="debugOn">
        <app-debug-infos
          [videoSourceStatus]="videoSourceStatus"
          [lastCoordinatePicked]="lastCoordinatePicked"
          [lastWebrtcFramesDecodedChanged]="debugInfos.lastWebrtcFramesDecodedChanged"
          [lastWebrtcFramesReceivedChanged]="debugInfos.lastWebrtcFramesReceivedChanged"
        >
        </app-debug-infos>
      </ng-container>
    </div>
  </div>
</app-widget-base>
