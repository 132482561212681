import {
  type EditorPanelProperties,
  defaultPanels,
  parentDefault,
} from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';
import { Mesh } from '@babylonjs/core';
import type { PrimitiveMetaData } from './primitives';
import type { ICoordinateSpace } from './visualizer/interface/IBinding';
import type { IMeshOps } from './visualizer/interface/IMeshOps';
import { robotMapMetaData } from '../nodes/robot-map';
import { tiles3DMetaData } from '../nodes/tiles3D';
import { spotMetaData } from '../nodes/spot';
import { staticGeoJSONMetaData } from '../nodes/static-geojson';
import { robotGraphMetaData } from '../nodes/robot-graph';
import { locationsMetadata } from '@shared/services/threeD/nodes/locations';

export const defaultMeshOps: IMeshOps = {
  visible: true,
  editable: true,
  listed: true,
  listChildren: true,
  save: true,
};

enum Icons {
  'BOX' = 'ri-3d-box',
  'SPHERE' = 'ri-3d-sphere',
  'PLANE' = 'ri-3d-plane',
  'GROUND' = 'ri-3d-ground',
  'POLYHEDRON' = 'ri-3d-polyhedron',
  'TORUS' = 'ri-3d-torus',
  'POINTCLOUD' = 'ri-3d-pointcloud',
  'CONTROLPOINT' = 'ri-control-point',
}

export type ShapeObjectTypes =
  | 'box'
  | 'torus'
  | 'sphere'
  | 'plane'
  | 'line'
  | 'polyhedron'
  | 'ground'
  | 'cameraFrustum';

export const shapeObjectTypes: Record<ShapeObjectTypes, PrimitiveMetaData> = {
  box: {
    key: 'box',
    label: 'Box',
    icon: Icons.BOX,
    editorPanels: defaultPanels,
  },
  sphere: {
    key: 'sphere',
    label: 'Sphere',
    icon: Icons.SPHERE,
    editorPanels: defaultPanels,
  },
  plane: {
    key: 'plane',
    label: 'Plane',
    icon: Icons.PLANE,
    editorPanels: defaultPanels,
  },
  ground: {
    key: 'ground',
    label: 'Ground',
    icon: Icons.GROUND,
    editorPanels: {
      parent: true,
      properties: {
        ...(defaultPanels.properties as EditorPanelProperties as EditorPanelProperties),
        dimensions: true,
      },
      bindPosition: true,
      bindRotationEuler: true,
      bindRotationQuaternion: true,
    },
  },
  polyhedron: {
    key: 'polyhedron',
    label: 'Polyhedron',
    icon: Icons.POLYHEDRON,
    editorPanels: defaultPanels,
  },
  torus: {
    key: 'torus',
    label: 'Torus',
    icon: Icons.TORUS,
    editorPanels: defaultPanels,
  },
  line: {
    key: 'line',
    label: 'Line',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      parent: parentDefault,
      bindAdvanced: true,
    },
  },
  cameraFrustum: {
    key: 'cameraFrustum',
    label: 'Camera Frustum',
    icon: Icons.POLYHEDRON,
    editorPanels: {
      ...defaultPanels,
      properties: {
        ...(defaultPanels.properties as EditorPanelProperties),
        material: false,
      },
      cameraFrustum: true,
    },
  },
};

export type TransformObjectTypes = 'transform' | 'geoTransform' | 'lsTransform';

export const transformObjectTypes: Record<TransformObjectTypes, PrimitiveMetaData> = {
  transform: {
    key: 'transform',
    label: 'Transform',
    icon: Icons.BOX,
    editorPanels: {
      parent: parentDefault,
      properties: {
        name: true,
        position: true,
        rotation: true,
        scaling: true,
        material: false,
      },
      geoTransform: false,
      bindPosition: true,
      bindRotationEuler: true,
      bindRotationQuaternion: true,
    },
  },
  geoTransform: {
    key: 'geoTransform',
    label: 'Geo Transform',
    icon: Icons.GROUND,
    editorPanels: {
      parent: false,
      properties: {
        name: true,
        position: false,
        rotation: false,
        scaling: false,
        material: false,
      },
      geoTransform: true,
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
    },
  },
  lsTransform: {
    key: 'lsTransform',
    label: 'Site Transform',
    icon: Icons.GROUND,
    editorPanels: {
      parent: ['geoTransform'],
      properties: {
        name: true,
        position: false,
        rotation: false,
        scaling: false,
        material: false,
      },
      geoTransform: false,
      lsTransform: true,
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
    },
  },
};
export type DdObjectTypes = 'tiles' | 'locations' | 'overlays' | 'raster';

export const ddObjectTypes: Record<DdObjectTypes, PrimitiveMetaData> = {
  tiles: tiles3DMetaData,
  locations: locationsMetadata,
  raster: {
    key: 'raster',
    label: 'DroneDeploy Map',
    icon: Icons.GROUND,
    editorPanels: {
      ...defaultPanels,
      parent: ['geoTransform'],
      raster: true,
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
      bindAdvanced: false,
    },
  },
  overlays: {
    key: 'overlays',
    label: 'DroneDeploy Overlay',
    icon: Icons.PLANE,
    editorPanels: {
      ...defaultPanels,
      parent: ['geoTransform'],
      overlays: true,
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
      bindAdvanced: false,
    },
  },
};

export type ComplexObjectTypes =
  | 'basemap'
  | 'pointcloud'
  | 'voxels'
  | 'laserScan'
  | 'occupancyMap'
  | 'geoJSON'
  | 'robotMap'
  | 'robotMapLive'
  | 'staticGeoJSON'
  | 'file-any' // = '3dModel'
  | 'gazebo-world'
  | 'controlPointGroup';

export const complexObjectTypes: Record<ComplexObjectTypes, PrimitiveMetaData> = {
  'file-any': {
    key: 'file-any',
    label: '3D Model (.obj, .stl, .gltf, .glb)',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      file: true,
    },
  },
  'gazebo-world': {
    key: 'gazebo-world',
    label: 'Load Gazebo World File',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      properties: {
        ...(defaultPanels.properties as EditorPanelProperties),
        material: false,
        'gazebo-world': true,
      },
    },
  },
  basemap: {
    key: 'basemap',
    label: 'Base Map',
    icon: Icons.GROUND,
    editorPanels: {
      ...defaultPanels,
      parent: false,
      properties: {
        name: true,
        position: false,
        rotation: false,
        scaling: false,
        material: false,
        'gazebo-world': false,
        dimensions: false,
      },
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
      bindAdvanced: false,
    },
  },
  pointcloud: {
    key: 'pointcloud',
    label: 'Point Cloud',
    icon: Icons.POINTCLOUD,
    editorPanels: {
      ...defaultPanels,
      pointcloud: true,
    },
  },
  laserScan: {
    key: 'laserScan',
    label: 'Laser Scan',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      bindAdvanced: true,
    },
  },
  occupancyMap: {
    key: 'occupancyMap',
    label: 'Occupancy Map',
    icon: Icons.GROUND,
    editorPanels: {
      ...defaultPanels,
      bindAdvanced: true,
    },
  },
  voxels: {
    key: 'voxels',
    label: 'Voxels',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      properties: {
        name: true,
        position: true,
        rotation: true,
        scaling: true,
        material: false,
        'gazebo-world': false,
        dimensions: false,
      },
      bindAdvanced: true,
    },
  },
  geoJSON: {
    key: 'geoJSON',
    label: 'GeoJSON',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      properties: {
        ...(defaultPanels.properties as EditorPanelProperties),
        material: false,
      },
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
      bindAdvanced: false,
      // Custom Panels
      controlPointGroup: false,
      geoJSON: true,
    },
  },
  robotMap: robotMapMetaData,
  robotMapLive: robotGraphMetaData,
  staticGeoJSON: staticGeoJSONMetaData,
  controlPointGroup: {
    key: 'controlPointGroup',
    label: 'Control Points',
    icon: Icons.CONTROLPOINT,
    editorPanels: {
      ...defaultPanels,
      properties: {
        name: true,
        position: false,
        rotation: false,
        scaling: false,
        material: false,
        'gazebo-world': false,
        dimensions: false,
      },
      bindPosition: false,
      bindRotationEuler: false,
      bindRotationQuaternion: false,
      bindAdvanced: false,
      // Custom Panels
      controlPointGroup: true,
    },
  },
};

export type PublicDefaultObjectTypes = ShapeObjectTypes | ComplexObjectTypes | TransformObjectTypes | DdObjectTypes;

export const publicObjectTypes: Record<PublicDefaultObjectTypes, PrimitiveMetaData> = {
  ...shapeObjectTypes,
  ...transformObjectTypes,
  ...complexObjectTypes,
  ...ddObjectTypes,
};

export type InternalObjectTypes = 'file-any-child' | 'octree' | 'spot-robot' | 'controlPoint';

export type DefaultObjectTypesWithRoot = 'root' | PublicDefaultObjectTypes | InternalObjectTypes;

export const internalObjectTypes: Record<InternalObjectTypes, PrimitiveMetaData> = {
  'file-any-child': {
    key: 'file-any-child',
    label: 'Box',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
    },
  },
  octree: {
    key: 'octree',
    label: 'Octree',
    icon: Icons.BOX,
    editorPanels: {
      ...defaultPanels,
      parent: [],
      bindAdvanced: true,
    },
  },
  'spot-robot': spotMetaData,
  controlPoint: {
    key: 'controlPoint',
    label: 'Control Point',
    icon: Icons.CONTROLPOINT,
    editorPanels: {
      parent: ['controlPointGroup'],
      controlPoint: true,
      properties: false,
    },
  },
};

export const allObjectTypes: Record<PublicDefaultObjectTypes, PrimitiveMetaData> = {
  ...publicObjectTypes,
  ...internalObjectTypes,
};

export type RendererDefaultObjectTypes = PublicDefaultObjectTypes | InternalObjectTypes;

export const supportedMarkerShape = ['box', 'sphere', 'torus', 'polyhedron', 'waypoint'];

export const getObjectPrimitiveMesh = (type: RendererDefaultObjectTypes) => {
  return JSON.parse(
    JSON.stringify({
      ...defaultPrimitiveMeshData,
      ...objectPrimitivesMeshData[type],
    }),
  );
};

export interface BaseMeshDataType {
  id?: string;
  width?: number;
  height?: number;
  depth?: number;
  position: number[];
  rotation: number[];
  scaling: number[];
  material: string;
  castsShadow: boolean;
  isPickable: boolean;
  receiveShadows: boolean;
  parent: string | undefined;
  boundSources?: {
    coordinateSpace: ICoordinateSpace;
  };
}

const defaultPrimitiveMeshData: BaseMeshDataType = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  scaling: [1, 1, 1],
  material: 'red',
  castsShadow: true,
  isPickable: false,
  receiveShadows: false,
  parent: undefined,
};

export interface CustomMeshDataType {
  type: RendererDefaultObjectTypes;
  displayName: string;
  options?: Record<string, unknown>;
  maxNumOfInstances?: number;
  fileOptions?: Record<string, unknown>;
  rayColor?: string;
  rayMaxColor?: string;
  renderPointCloud?: boolean;
  renderRays?: boolean;
  renderRayMax?: boolean;
  transparent?: boolean;
  boundingBoxOptions?: Record<string, unknown>;
  boundingBoxLoadingVisibility?: number;
  boundingBoxLoadedVisibility?: number;
  renderArea?: boolean;
  renderDepth?: number;
  pointSize?: number;
  color?: string;
  trailLength?: number;
  controlPointGroup?: {
    method: 'upload' | 'manual';
  };
  controlPoint?: {
    id: string;
  };
  staticGeoJSON: {
    geoJSON: string;
    flowId?: string;
  };
}

const objectPrimitivesMeshData: Record<RendererDefaultObjectTypes, Partial<BaseMeshDataType | CustomMeshDataType>> = {
  transform: {
    type: 'transform',
    displayName: 'Transform',
    castsShadow: false,
    isPickable: false,
    options: {},
  },
  geoTransform: {
    type: 'geoTransform',
    displayName: 'Geo Transform',
    castsShadow: false,
    isPickable: false,
    options: {},
  },
  lsTransform: {
    type: 'lsTransform',
    displayName: 'Site Transform',
    castsShadow: false,
    isPickable: false,
    options: {},
  },
  box: {
    type: 'box',
    displayName: 'Box',
    options: {
      width: 1,
      height: 1,
    },
  },
  sphere: {
    type: 'sphere',
    displayName: 'Sphere',
    options: {
      segments: 32,
      diameter: 1,
    },
  },
  plane: {
    type: 'plane',
    displayName: 'Plane',
    options: {
      height: 20,
      width: 20,
      sideOrientation: 2,
    },
    receiveShadows: true,
  },
  ground: {
    type: 'ground',
    displayName: 'Ground',
    options: {
      height: 20,
      width: 20,
      subdivisions: 1,
    },
    castsShadow: false,
    receiveShadows: true,
  },
  polyhedron: {
    type: 'polyhedron',
    displayName: 'Polyhedron',
    options: {
      type: 3,
      size: 1,
    },
  },
  torus: {
    type: 'torus',
    displayName: 'Torus',
    options: {
      tessellation: 20,
      thickness: 0.8,
    },
  },
  line: {
    type: 'line',
    displayName: 'Line',
    options: {
      points: [
        [0, 0, 0.2],
        [1, 0, 0.2],
        [1, 1, 0.2],
        [0, 1, 0.2],
        [0, 0, 0.2],
      ],
    },
    color: '#00FF00',
    trailLength: 500,
  },
  'file-any': {
    type: 'file-any',
    displayName: '3D Model',
    options: {
      rootUrl: '',
      sceneFileName: '',
    },
    boundingBoxOptions: {
      height: 1,
      width: 1,
      depth: 0.5,
    },
    material: undefined,
    boundingBoxLoadingVisibility: 0.8,
    boundingBoxLoadedVisibility: 0,
    position: [0, 0, 0.25],
  },
  'gazebo-world': {
    type: 'gazebo-world',
    displayName: 'Gazebo World',
    options: {
      rootUrl: 'https://storage.googleapis.com/www.rocos.io/3dmodels/acuityrobotx/',
      fileName: 'MedAcuityOffice_V2.world',
    },
    boundingBoxOptions: {
      size: 5,
    },
    material: 'grey',
    boundingBoxLoadingVisibility: 0.2,
    boundingBoxLoadedVisibility: 0,
  },
  pointcloud: {
    type: 'pointcloud',
    displayName: 'Point Cloud',
    options: {
      segments: 16,
      diameter: 5,
      slice: 0.5,
      bindingType: 'telemetry',
    },
    material: 'pointcloud-white',
    maxNumOfInstances: 1,
  },
  laserScan: {
    type: 'laserScan',
    displayName: 'Laser Scan',
    options: {
      radius: 5,
      tessellation: 32,
      sideorientation: Mesh.DOUBLESIDE,
    },
    material: 'pointcloud-white',
    rayColor: '#00FF00',
    rayMaxColor: '#CCCCCC',
    renderPointCloud: true,
    renderRays: false,
    renderArea: true,
  },
  occupancyMap: {
    type: 'occupancyMap',
    displayName: 'Occupancy Map',
    options: {
      width: 10,
      height: 10,
      depth: 0.1,
    },
    fileOptions: {
      callsign: '',
      filename: '',
      metaFilename: '',
    },
    material: 'gray-wireframe',
  },
  voxels: {
    type: 'voxels',
    displayName: 'Voxels',
    material: 'voxel-coloured',
    renderDepth: 5,
  },
  geoJSON: {
    type: 'geoJSON',
    displayName: 'GeoJSON',
  },
  robotMap: {
    type: 'robotMap',
    displayName: 'Robot Map',
  },
  robotMapLive: {
    type: 'robotMapLive',
    displayName: 'Robot Map Live',
  },
  staticGeoJSON: {
    type: 'staticGeoJSON',
    displayName: 'Static GeoJSON',
    staticGeoJSON: {
      geoJSON: '{}',
      flowId: '',
    },
  },
  tiles: {
    type: 'tiles',
    displayName: 'DroneDeploy 3D Model',
    options: {
      ddPlanId: '',
      layerName: 'Mesh',
      maximumScreenSpaceError: 48,
    },
    pointSize: 2,
  },
  'spot-robot': {
    type: 'spot-robot',
    displayName: 'Spot',
  },
  raster: {
    type: 'raster',
    displayName: 'DroneDeploy Map',
    options: {
      ddPlanId: '',
      zoom: 20,
    },
  },
  basemap: {
    type: 'basemap',
    displayName: 'Base Map',
    options: {
      zoom: 20,
    },
  },
  controlPointGroup: {
    type: 'controlPointGroup',
    displayName: 'Control Point Group',
    material: 'controlPoint',
    controlPointGroup: {
      method: 'upload',
    },
  },
  cameraFrustum: {
    type: 'cameraFrustum',
    displayName: 'Camera Frustum',
  },
  controlPoint: {
    type: 'controlPoint',
    displayName: 'Control Point',
    material: 'controlPoint',
    position: [0, 0, 0.6],
    options: {
      width: 0.05,
      height: 0.4,
      depth: 0.4,
    },
    controlPoint: {
      id: '',
    },
  },
  overlays: {
    type: 'overlays',
    displayName: 'DroneDeploy Overlay',
    transparent: true,
    options: {
      ddOverlayId: '',
      zoom: 20,
    },
  },
  locations: {
    type: 'locations',
    displayName: 'DroneDeploy Locations',
  },

  // Internal objects below
  'file-any-child': undefined,
  octree: undefined,
};
