import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import type { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import type { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { FormUtils } from '@shared/utils/form-utils';
import { EXPERIMENTAL_FEATURE } from '../../enums';
import type { IsLoading } from '../../interfaces';
import type {
  CommandV2,
  GamepadControl,
  GamepadUxComponentCommandVersion,
  GamepadUxComponentControlType,
} from '../../models';
import { GamepadUxComponent, gamepadUxComponentControlTypes } from '../../models';
import { DevelopmentService, ProjectPermissionService, UserService } from '../../services';
import type { EditorOptions } from '@shared-modules/monaco-editor';

export interface ScreenComponentEditDialogData {
  projectId: string;
  component: GamepadUxComponent;
  controls: GamepadControl[];
  commandsV2: CommandV2[];
}

@Component({
  selector: 'app-screen-component-edit-dialog',
  templateUrl: './screen-component-edit-dialog.component.html',
  styleUrls: ['./screen-component-edit-dialog.component.scss'],
})
export class ScreenComponentEditDialogComponent implements OnInit, IsLoading {
  public form: UntypedFormGroup;
  public isLoading: boolean = false;
  public injectedUxComponent: GamepadUxComponent;

  controls: GamepadControl[] = [];
  commandsV2: CommandV2[] = [];
  currentControl: GamepadControl;
  currentCommandV2: CommandV2;
  projectId: string;
  controlsTabEnabled: boolean = false;
  buttnRunJsEnabled: boolean = false;
  isButtonCreatedForControls: boolean;
  isButtonCreatedForRunJs: boolean;
  isAdmin: boolean;

  public get id() {
    return this.form?.get('id');
  }
  public get label() {
    return this.form?.get('label');
  }
  public get description() {
    return this.form?.get('description');
  }
  public get controlType() {
    return this.form?.get('controlType');
  }
  public get commandName() {
    return this.form?.get('commandName');
  }
  public get commandVersion(): AbstractControl {
    return FormUtils.getControl(this.form, 'commandVersion');
  }
  public get commandParameters(): UntypedFormGroup {
    return this.form && (this.form.get('commandParameters') as UntypedFormGroup);
  }
  public get currentCommandV2ParametersLength(): number {
    return this.currentCommandV2?.parameters && Object.keys(this.currentCommandV2.parameters).length;
  }
  public get showButtonOnDashboardCtrl(): AbstractControl {
    return FormUtils.getControl(this.form, 'showButtonOnDashboard');
  }
  public get showButtonOnGlobalOperationsCtrl(): AbstractControl {
    return FormUtils.getControl(this.form, 'showButtonOnGlobalOperations');
  }
  public get showButtonOnLocalOperationsCtrl(): AbstractControl {
    return FormUtils.getControl(this.form, 'showButtonOnLocalOperations');
  }
  public get jsPayloadCtrl(): AbstractControl {
    return FormUtils.getControl(this.form, 'jsPayload');
  }
  public get pickerHint(): AbstractControl {
    return FormUtils.getControl(this.form, 'pickerHint');
  }

  public types = [];

  jsEditorOptions: EditorOptions = {
    language: 'javascript',
    wordWrap: 'on',
    minimap: {
      enabled: false,
    },
  };

  constructor(
    public dialogRef: MatDialogRef<ScreenComponentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ScreenComponentEditDialogData,
    private projectPermissionService: ProjectPermissionService,
    private userService: UserService,
    private devService: DevelopmentService,
  ) {
    this.types = gamepadUxComponentControlTypes;
    this.isAdmin = this.userService.isAdmin();

    if (!data) {
      return;
    }

    this.projectId = data.projectId;
    this.injectedUxComponent = data.component;
    this.controls = data.controls;
    this.commandsV2 = data.commandsV2;

    // Get ExperimentalFeature.ControlsTab flag status
    this.projectPermissionService.checkPermission(this.projectId, EXPERIMENTAL_FEATURE.ControlsTab).subscribe((x) => {
      this.controlsTabEnabled = x;
    });

    // Get ExperimentalFeature.ButtonRunJs flag status
    this.projectPermissionService.checkPermission(this.projectId, EXPERIMENTAL_FEATURE.ButtonRunJs).subscribe((x) => {
      this.buttnRunJsEnabled = x;
    });

    if (data.component) {
      switch (data.component.commandVersion) {
        case 'commands': // Backward compability for old command
        case 'controls':
          this.isButtonCreatedForControls = true;
          break;
        case 'run-js':
          this.isButtonCreatedForRunJs = true;
          break;
      }
    }
  }

  ngOnInit() {
    this.createForm();
  }

  // Identify if the control option is available
  get isControlsVisible() {
    return (
      this.controlsTabEnabled ||
      this.isButtonCreatedForControls ||
      (this.isAdmin && !this.devService.isHideAdminOnlyContent)
    );
  }

  // Identify if the RunJs option is available
  get isRunJsVisible() {
    return (
      this.buttnRunJsEnabled ||
      this.isButtonCreatedForRunJs ||
      (this.isAdmin && !this.devService.isHideAdminOnlyContent)
    );
  }

  onControlSelectionChange(change: MatSelectChange) {
    const value = change.value;

    const control = this.findById(value, this.controls);

    if (control) {
      this.currentControl = control;

      this.createCommandParametersGroupFromControl(control);
    }
  }

  onCommandV2SelectionChange(change: MatSelectChange) {
    const value = change.value;
    const command = this.findById(value, this.commandsV2);

    if (command) {
      this.currentCommandV2 = command;
      this.createCommandParametersGroupFromCommandV2(command);
    }
  }

  onSwitchcommandVersion(change: MatRadioChange) {
    // Reset command name as command version changed.
    this.commandName.setValue(null);

    const value = change.value;

    this.switchcommandVersion(value);
  }

  public onSubmit() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    const component = GamepadUxComponent.fromModel(this.form.value);

    this.dialogRef.close(component);
  }

  public onCancel() {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

  public onLabelChange() {
    this.onUpdateID();
  }

  public onControlTypeChange() {
    this.onUpdateID();

    // Set values by the type of button.
    switch (this.controlType.value as GamepadUxComponentControlType) {
      case 'button':
        break;
      case 'buttonAndPose':
        this.showButtonOnDashboardCtrl.setValue(false);
        break;
      case 'buttonAndGetMission':
        this.showButtonOnDashboardCtrl.setValue(false);
        break;
      default:
        // None
        break;
    }
  }

  private onUpdateID() {
    if (!this.injectedUxComponent?.id) {
      this.generateAndAssignComponentID();
    }
  }

  private createCommandParametersGroupFromControl(control: GamepadControl, component?: GamepadUxComponent) {
    if (control) {
      const params = control.parameters;

      const obj = {};

      const paramsObj = {};
      if (component?.commandParams && component.commandParams.length > 0) {
        const compParams = component.commandParams;

        compParams.forEach((param) => {
          paramsObj[param.id] = param.value;
        });
      }

      if (params?.length > 0) {
        params.forEach((param) => {
          obj[param.name] = new UntypedFormControl(paramsObj[param.name] ? paramsObj[param.name] : null);
          const showInputBoxName = param.name + '_showInputBox';
          obj[showInputBoxName] = new UntypedFormControl(
            paramsObj[showInputBoxName] ? paramsObj[showInputBoxName] : false,
          );
        });
      }

      const paramsGroup = new UntypedFormGroup(obj, [Validators.required]);

      this.form.setControl('commandParameters', paramsGroup);
    }
  }

  private createCommandParametersGroupFromCommandV2(command: CommandV2, component?: GamepadUxComponent) {
    if (command) {
      const params = command.parameters;

      const obj = {};

      const paramsObj = {};
      if (component?.commandParams && component.commandParams.length > 0) {
        const compParams = component.commandParams;

        compParams.forEach((param) => {
          paramsObj[param.id] = param.value;
        });
      }

      const keys = Object.keys(params);

      if (keys?.length > 0) {
        keys.forEach((key) => {
          obj[key] = new UntypedFormControl(paramsObj[key] ? paramsObj[key] : null);
          const showInputBoxName = key + '_showInputBox';
          obj[showInputBoxName] = new UntypedFormControl(
            paramsObj[showInputBoxName] ? paramsObj[showInputBoxName] : false,
          );
        });
      }

      const paramsGroup = new UntypedFormGroup(obj, [Validators.required]);

      this.form.setControl('commandParameters', paramsGroup);
    }
  }

  private createForm() {
    // For backward compatible, we treat undefined as true

    let showButtonOnGlobalOperations = true;
    let showButtonOnLocalOperations = true;
    if (this.injectedUxComponent) {
      if (
        this.injectedUxComponent.showButtonOnGlobalOperations === null ||
        this.injectedUxComponent.showButtonOnGlobalOperations === false
      ) {
        showButtonOnGlobalOperations = false;
      }

      if (
        this.injectedUxComponent.showButtonOnLocalOperations === null ||
        this.injectedUxComponent.showButtonOnLocalOperations === false
      ) {
        showButtonOnLocalOperations = false;
      }
    }

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.id : '', [Validators.required]),
      label: new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.label : '', [
        Validators.required,
      ]),
      description: new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.description : '', []),
      controlType: new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.controlType : 'button', [
        Validators.required,
      ]),
      commandName: new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.commandName : '', [
        Validators.required,
      ]),
      commandVersion: new UntypedFormControl(
        this.injectedUxComponent ? this.injectedUxComponent.commandVersion : 'commands-v2',
        [Validators.required],
      ),
      showInputField: new UntypedFormControl(
        this.injectedUxComponent ? this.injectedUxComponent.showInputField : false,
        [],
      ),
      showButtonOnDashboard: new UntypedFormControl(
        this.injectedUxComponent ? this.injectedUxComponent.showButtonOnDashboard : false,
        [],
      ),
      showButtonOnGlobalOperations: new UntypedFormControl(showButtonOnGlobalOperations, []),
      showButtonOnLocalOperations: new UntypedFormControl(showButtonOnLocalOperations, []),
      pickerHint: new UntypedFormGroup({
        mode: new UntypedFormControl(this.injectedUxComponent?.pickerHint?.mode ?? 'xy_y', []),
        frame: new UntypedFormControl(this.injectedUxComponent?.pickerHint?.frame ?? '', []),
      }),
    });

    if (this.injectedUxComponent?.commandName) {
      // Default: controls
      // For old projects, commandVersion was not there. So we auto assign conrols to the commandVersion
      const commandVersion = this.injectedUxComponent.commandVersion
        ? this.injectedUxComponent.commandVersion
        : 'controls';

      let control: GamepadControl;
      let commandv2: CommandV2;
      switch (commandVersion) {
        case 'controls':
        case 'commands':
          control = this.findById(this.injectedUxComponent.commandName, this.controls);
          if (control) {
            this.currentControl = control;
            this.createCommandParametersGroupFromControl(control, this.injectedUxComponent);
          }
          break;

        case 'commands-v2':
          commandv2 = this.findById(this.injectedUxComponent.commandName, this.commandsV2);
          if (commandv2) {
            this.currentCommandV2 = commandv2;
            this.createCommandParametersGroupFromCommandV2(commandv2, this.injectedUxComponent);
          }
          break;

        case 'run-js':
          // do nothing.
          break;
      }
    }

    if (this.injectedUxComponent?.commandVersion) {
      const commandVersion = this.injectedUxComponent.commandVersion;

      this.switchcommandVersion(commandVersion);
    }
  }

  private findById<T extends { id: string }>(id: string, items: T[]): T {
    if (!items?.length) return null;
    return items.find((comm) => comm.id === id);
  }

  private generateComponentID() {
    const controlType = this.controlType?.value ? this.controlType.value : 'component';
    const prefix = `/ui/${controlType}s`;
    const label: string = this.label.value || '';
    const guid = crypto.randomUUID();
    const cleanedLabel = label.replace(/[^\dA-Za-z]+/g, '-').toLowerCase();
    return `${prefix}/${cleanedLabel}-${guid}`;
  }

  private generateAndAssignComponentID() {
    const id = this.generateComponentID();

    this.id.setValue(id);
  }

  private switchcommandVersion(commandVersion: GamepadUxComponentCommandVersion) {
    switch (commandVersion) {
      case 'run-js':
        if (this.form) {
          this.form.setControl(
            'jsPayload',
            new UntypedFormControl(this.injectedUxComponent ? this.injectedUxComponent.jsPayload : '', []),
          );
        }
        this.commandName.clearValidators();
        this.commandName.updateValueAndValidity();
        break;
      case 'controls':
      case 'commands-v2':
      case 'commands': // this case is for backward compability for old version of command
        this.commandName.setValidators([Validators.required]);
        break;
      default:
        break;
    }
  }
}
