<div class="arrow-container" [ngClass]="buttonColour">
  <div>
    <ng-container *ngFor="let param of paramsArray">
      <div class="form-group parameter-input">
        <label>{{ param.label }}</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="Enter value"
          [ngClass]="buttonColour"
          [(ngModel)]="parameters[param.label]"
        />
      </div>
    </ng-container>
    <div class="button-holder">
      <button mat-button class="coloured-button" [ngClass]="buttonColour" (click)="submit($event)">Send</button>
    </div>
  </div>
</div>
