import { formatDistanceStrict, intervalToDuration, formatDuration } from 'date-fns';
import parse from 'parse-duration';

export class TimeUtils {
  public static timeLabelForRange(startDate: Date, endDate: Date): string {
    const duration = intervalToDuration({
      start: startDate,
      end: endDate,
    });

    return formatDuration(duration, { delimiter: ', ' });
  }

  public static parseDurationToMilliseconds(duration: string): number | null {
    return parse(duration);
  }

  public static getDateOffSetLabel(start: Date | number, end: Date | number): string {
    return formatDistanceStrict(start, end, { addSuffix: true });
  }

  /** Parse time string in the format of HH:mm to hours and minutes
   *
   * @example
   * parseTime('12:30') // { hour: 12, min: 30 }
   * parseTime('12:30:00') // { hour: 12, min: 30 }
   *
   * @throws Error if time is not in the format of HH:mm
   * @param time Time string in the format of HH:mm
   */
  public static parseTime(time: string): { hour: number; min: number } {
    const timeRegex = /^(\d{1,2}):(\d{1,2})$/;
    if (!timeRegex.test(time)) {
      throw new Error('Invalid time format');
    }

    const [hour, min] = time.split(':').map((i) => Number(i));

    if (isNaN(hour) || isNaN(min) || typeof hour !== 'number' || typeof min !== 'number') {
      throw new Error('Invalid time format');
    }

    if (hour < 0 || hour > 23) {
      throw new Error('Invalid hour');
    }

    if (min < 0 || min > 59) {
      throw new Error('Invalid minute');
    }

    return { hour, min };
  }
}
