import { RobotStatus } from '../../enums';
import type { IRobot } from '../../interfaces';
import type { RobotDeploymentResult } from './robot-deployment-result';
import type { RobotMode } from './robot-mode';
import type { RobotType } from './robot-type';

export class Robot implements IRobot {
  public name: string;
  public callsign: string;
  public disabled = false;
  public imageId = '';
  public mode: RobotMode;
  public gatewayIp?: string;
  public gatewayPort?: string;
  public status?: RobotStatus;
  public description?: string;
  public configParameters?: string;
  public robotDefinition?: string;
  public robotDefinitionName?: string;
  public configGroupId?: string;

  public deploymentResult?: RobotDeploymentResult;

  public robotType: RobotType = 'unknown';

  // for the map interface to show which robots are currently selected
  public selected: boolean = false;

  // canonical map and HUD information
  public heading: number = null;
  public latitude: number = null;
  public longitude: number = null;
  // public altitude: number;

  /** as per ROS, short-range Cartesian representations of
   * geographic locations, use the east north up (UNU) convention */
  public xEast: number;
  public yNorth: number;
  public zElevation: number;

  /** Above Sea Level, Above Mean Sea Level (AMSL / MSL) */
  public altitudeASL: number = null;

  /** Relative To Launch, above home position altitude */
  public altitudeRTL: number = null;

  /** Above Ground Level, Terrain, will be same as RTL if home was ground */
  public altitudeAGL: number = null;

  public roll: number;
  public pitch: number;
  public yaw: number;
  public climb: number;
  public throttle: number;
  public batteryVoltage: number;
  public batteryRemaining: number;
  public airspeed: number;
  public groundspeed: number;

  public armed: boolean; // mavlink use HEARTBEAT/BASE_MODE (bit position 128 for armed) so when BASE_MODE >> 7
  public statusText: string;
  public statusTextTimer: any;
  public statusSeverity: string; // use global standard: https://en.wikipedia.org/wiki/Syslog#Severity_levels
  public statusTextHistory: string[] = [];

  /** When true the robot will record its own historic coordinates as they change from when it is actived */
  public recordCoordinates: boolean = false;

  /** GeometryShapeLineString of historic coordinates, requires recordCoordinates to be true to be logged */
  // public coordinateHistory: GeometryShapeLineString;

  /** Simple Array of Lon, Lat, Alt coordinates */
  public coordinateHistoryLonLatAlt = [];

  /** number of historic coordinates to keep in coordinatesHistory */
  public coordinateHistoryMaxSize: number = 5;

  // public coordinateArrayLonLatAlt: any = [];
  public globalLocationDataURI: any = {};
  public expressions: any = {};

  public get isVirtual() {
    return ['virtual', 'cloud'].includes(this.mode);
  }

  constructor(name?: string, callsign?: string) {
    this.name = name ? name : '';
    this.callsign = callsign ? callsign : '';
  }

  public static fromModel(model: IRobot) {
    const robot = new Robot();

    robot.name = model.name;
    robot.callsign = model.callsign;
    robot.disabled = model.disabled;
    robot.imageId = model.imageId;
    robot.mode = model.mode as any;
    robot.gatewayIp = model.gatewayIp;
    robot.gatewayPort = model.gatewayPort;
    robot.description = model.description;
    robot.configParameters = model.configParameters;
    robot.robotDefinition = model.robotDefinition;
    robot.robotDefinitionName = model.robotDefinitionName;

    // TODO
    // Hacker way to distinguish the type of robot for now
    if (robot.configParameters) {
      if (robot.configParameters.includes('turtlebot')) {
        robot.robotType = 'ros';
      } else if (robot.configParameters.includes('dronekit')) {
        robot.robotType = 'mavlink';
      }
    }

    // TODO
    robot.status = RobotStatus.LIVE;

    return robot;
  }

  /** Changes the latest coordinate and adds the item to the history only when lon, lat, alt are all not null */
  public addCoordinateToHistory() {
    if (this.latitude != null && this.longitude != null && this.altitudeRTL != null) {
      this.coordinateHistoryLonLatAlt.push([this.longitude, this.latitude, this.altitudeRTL]);
    }

    // cut back the array to only keep the required history length
    if (this.coordinateHistoryLonLatAlt.length > this.coordinateHistoryMaxSize) {
      const numToCut = this.coordinateHistoryLonLatAlt.length - this.coordinateHistoryMaxSize;
      // this.coordinateHistory.splice(0, numToCut);
      this.coordinateHistoryLonLatAlt.splice(0, numToCut);
    }
  }

  public addStatusText(statusText, statusSeverity?) {
    this.statusText = statusText;
    this.addStatusTextToHistory(statusText, statusSeverity);
  }

  public setConfigParametersFromObject(obj: any) {
    try {
      this.configParameters = JSON.stringify(obj);
    } catch {
      this.configParameters = '';
    }
  }

  public setRobotDefinitionToConfigParameters(robotDefinitionId: string) {
    let obj: any = {};
    try {
      obj = JSON.parse(this.configParameters);

      if (!obj) {
        obj = {};
      }
    } catch {
      obj = {};
    }

    obj.robotDefinition = robotDefinitionId;

    this.setConfigParametersFromObject(obj);
  }

  public setRobotConfigGroupToConfigParameters(configGroupId: string) {
    let obj: any = {};
    try {
      obj = JSON.parse(this.configParameters);

      if (!obj) {
        obj = {};
      }
    } catch {
      obj = {};
    }

    obj.configGroupId = configGroupId;

    this.setConfigParametersFromObject(obj);
  }

  public setRobotDefinitionFromConfigParameters() {
    if (this.configParameters) {
      try {
        const obj = JSON.parse(this.configParameters);

        if (obj.robotDefinition) {
          this.robotDefinition = obj.robotDefinition;
        }
      } catch {
        // do nothing
      }
    }
  }

  public setRobotConfigGroupFromConfigParameters() {
    if (this.configParameters) {
      try {
        const obj = JSON.parse(this.configParameters);

        if (obj.configGroupId) {
          this.configGroupId = obj.configGroupId;
        }
      } catch {
        // do nothing
      }
    }
  }

  public setStatusTextWithTimeout(text: string, clearInSeconds: number = -1) {
    if (this.statusTextTimer) {
      clearTimeout(this.statusTextTimer);
    }

    this.addStatusText(text);

    if (clearInSeconds >= 0) {
      setTimeout(() => {
        this.statusText = '';
      }, clearInSeconds * 1000);
    }
  }

  public canPerformWorkflow(type: string) {
    switch (type) {
      // assume empty workflow type means all robots except cloud
      case '':
        return ['physical', 'virtual'].includes(this.mode);
      case 'cloud':
        return this.mode === 'cloud';
      default:
        return false;
    }
  }

  private addStatusTextToHistory(statusText, _statusSeverity?) {
    this.statusTextHistory.push(statusText);
    if (this.statusTextHistory.length > 5) {
      this.statusTextHistory.splice(0, this.statusTextHistory.length - 5);
    }
  }
}
