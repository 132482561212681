import type { IScene } from './interface/IScene';
import type { ISceneOptions } from './interface/ISceneOptions';
import type { IMesh } from './interface/IMesh';
import type { ICamera } from './interface/ICamera';
import type { ILight } from './interface/ILight';
import type { IMaterial } from './interface/IMaterial';
import type { ISkybox } from './interface/ISkybox';
import type { IPanel } from './interface/IPanel';
import type { ISceneRobot } from './interface/ISceneRobot';
import { meshKeyDecode } from '../../../../helpers/meshKey';
import type { IListRecursive } from './interface/IListRecursive';
import type { GlobalOperationPageConfigModel } from '../../../../models/operation';
import type { GpsLocation } from '@shared/services/threeD/transform';
import type { RendererDefaultObjectTypes } from '../objectPrimitives';
import type { IBoundSource } from './interface/IBinding';
import { BehaviorSubject } from 'rxjs';

export class SceneManager implements IScene {
  public id: string;
  public name: string;
  public startTime: 0; // optional
  public endTime: number = 60000; // optional
  public duration: number = 60000; // optional
  public frameInterval: number = 200;
  public sceneOptions: ISceneOptions;
  public cameras: Record<string, ICamera>;
  public lights: Record<string, ILight>;
  public materials: Record<string, IMaterial>;
  public skyboxes: Record<string, ISkybox>;
  public meshes: Record<string, IMesh> = {};
  public panels: Record<string, IPanel>;
  public robots: Record<string, ISceneRobot> = {};
  public boundSources: Record<string, IBoundSource>;
  public data: unknown; // frames buffered for the renderer

  // keeps an updated list of all bindings in a flat list that are sourced from the hierarchy of
  // boundSourcesthis.sceneManager.panels.jsPanel.widgets
  public bindingsKeyExists: Record<string, boolean> = {};
  // keeps a reverse lookup of what a bindings parent source is
  public bindingsSourceKey: Record<string, string> = {};

  public scenesByKeyRecursive: IListRecursive[] = [
    {
      value: {
        key: 'scene',
        type: 'scene',
      },
      path: [],
      children: [],
    },
  ];
  public meshesByKey: IMesh[] = [];
  public meshesByKeyRecursive: IListRecursive[] = [];
  public camerasByKey: ICamera[] = [];
  public camerasByKeyRecursive: IListRecursive[] = [];
  public lightsByKey: ILight[] = [];
  public lightsByKeyRecursive: IListRecursive[] = [];
  public materialsByKey: IMaterial[] = [];
  public skyboxesByKey: ISkybox[] = [];
  public projectLocation: GpsLocation;

  private _isReady$ = new BehaviorSubject<boolean>(false);
  public get isReady$() {
    return this._isReady$.asObservable();
  }

  public constructor(id?: string, name?: string) {
    this.id = id;
    this.name = name;
  }

  public loadSceneFromJson(sceneData: IScene): void {
    this.sceneOptions = sceneData.sceneOptions
      ? sceneData.sceneOptions
      : {
          ambientColor: undefined,
          clearColor: undefined,
          defaultCamera: undefined,
          hiddenFeatures: undefined,
          shadowsEnabled: undefined,
        };

    this.projectLocation = sceneData.projectLocation;
    this.lights = sceneData.lights ? sceneData.lights : {};
    this.cameras = sceneData.cameras ? sceneData.cameras : {};
    this.materials = sceneData.materials ? sceneData.materials : {};
    this.skyboxes = sceneData.skyboxes ? sceneData.skyboxes : {};
    this.meshes = sceneData.meshes ? sceneData.meshes : {};
    this.boundSources = sceneData.boundSources ? sceneData.boundSources : {};
    this.panels = sceneData.panels ? sceneData.panels : {};
    this.robots = sceneData.robots ? sceneData.robots : {};

    if (!this.sceneOptions.hiddenFeatures) this.sceneOptions.hiddenFeatures = {};
    this.refreshData();

    this._isReady$.next(true);
  }

  public refreshData(): void {
    this.updateBindingsKeyArray();
    this.updateMaterialsKeyArray();
    this.updateMeshesKeyArray();
    this.updateSkyboxesKeyArray();
    this.updateCamerasKeyArray();
    this.updateLightsKeyArray();
  }

  public maxFrames(): number {
    return Math.floor(this.endTime / this.frameInterval);
  }
  public deleteLight(lightId: string): void {
    delete this.lights[lightId];
    this.updateLightsKeyArray();
  }

  public deleteCamera(cameraId: string): void {
    delete this.cameras[cameraId];
    this.updateCamerasKeyArray();
  }

  public deleteMesh(meshId: string): void {
    const pathKey = meshKeyDecode(meshId);
    if (pathKey.length < 2) {
      delete this.meshes[meshId];
    } else if (this.meshes?.[pathKey[0]]?.nodes) {
      let index: number;
      const newMesh = this.meshes[pathKey[0]].nodes.find((item, idx) => {
        if (item.key === meshId) {
          index = idx;
          return true;
        }
        return undefined;
      });
      if (newMesh && index !== undefined) {
        delete this.meshes[pathKey[0]].nodes[index];
        this.meshes[pathKey[0]].nodes.splice(index, 1);
      }
    }
    this.updateMeshesKeyArray();
  }

  public addMesh(meshId: string, meshData: IMesh): void {
    this.meshes[meshId] = meshData;
    this.updateMeshesKeyArray();
  }

  public updateFromJson(json: GlobalOperationPageConfigModel): void {
    if (json.robots) this.robots = json.robots;
    if (json.boundSources) this.boundSources = json.boundSources;

    this.updateBindingsKeyArray();
  }

  public updateMeshesKeyArray(): void {
    this.meshesByKey = [];
    const allMeshes: IMesh[] = [];

    Object.keys(this.meshes).forEach((meshKey) => {
      this.meshes[meshKey].key = meshKey;
      this.meshesByKey.push(this.meshes[meshKey]);
      allMeshes.push(this.meshes[meshKey]);
      if (this.meshes[meshKey].nodes) {
        this.meshes[meshKey].nodes.forEach((item) => {
          allMeshes.push(item);
        });
      }
    });

    const recursiveMeshes = (meshes: IMesh[], path: string[] = []): IListRecursive[] => {
      const result: IListRecursive[] = [];
      meshes.forEach((mesh) => {
        result.push({
          value: mesh,
          path: [...path, mesh.key],
          children: recursiveMeshes(
            allMeshes.filter((item) => item.parent === mesh.key),
            [...path, mesh.key],
          ),
        });
      });
      return result;
    };
    this.meshesByKeyRecursive = recursiveMeshes(allMeshes.filter((item) => !item.parent));
  }

  public updateCamerasKeyArray(): void {
    this.camerasByKey = [];
    Object.keys(this.cameras).forEach((camKey) => {
      this.cameras[camKey].key = camKey;
      this.camerasByKey.push(this.cameras[camKey]);
    });

    this.camerasByKeyRecursive = this.camerasByKey.map((item) => {
      return {
        value: item,
        path: [item.key],
        children: [],
      };
    });
  }

  public updateLightsKeyArray(): void {
    this.lightsByKey = [];
    Object.keys(this.lights).forEach((lightKey) => {
      this.lights[lightKey].key = lightKey;
      this.lightsByKey.push(this.lights[lightKey]);
    });

    this.lightsByKeyRecursive = this.lightsByKey.map((item) => {
      return {
        value: item,
        path: [item.key],
        children: [],
      };
    });
  }

  public updateMaterialsKeyArray(): void {
    this.materialsByKey = [];
    Object.keys(this.materials).forEach((matKey) => {
      this.materials[matKey].key = matKey;
      this.materialsByKey.push(this.materials[matKey]);
    });
  }

  public updateSkyboxesKeyArray(): void {
    this.skyboxesByKey = [];
    Object.keys(this.skyboxes).forEach((sbKey) => {
      this.skyboxes[sbKey].key = sbKey;
      this.skyboxesByKey.push(this.skyboxes[sbKey]);
    });
  }

  public updateBindingsKeyArray(): void {
    if (!this.boundSources) {
      return;
    }
    const bindingsKeyExists = {};
    const bindingsSourceKey = {};
    Object.keys(this.boundSources).forEach((sourceKey) => {
      Object.keys(this.boundSources[sourceKey].bindings).forEach((bindingKey) => {
        bindingsKeyExists[bindingKey] = true;
        bindingsSourceKey[bindingKey] = sourceKey;
      });
    });

    this.bindingsKeyExists = bindingsKeyExists;
    this.bindingsSourceKey = bindingsSourceKey;
  }

  public clearBoundSources(): void {
    if (!this.boundSources) return;
    const callsigns: string[] = Object.keys(this.robots);

    // 1) Remove bindings
    Object.keys(this.boundSources).forEach((key) => {
      const source = this.boundSources[key];
      const bindings = source.bindings;
      if (bindings) {
        Object.keys(bindings).forEach((bindingKey) => {
          if (bindingKey) {
            const objectId = bindingKey.split('.')[0];
            if (!callsigns.includes(objectId)) delete bindings[bindingKey];
          }
        });
      }
    });

    // 2) Remove all empty bindings
    Object.keys(this.boundSources).forEach((key) => {
      const source = this.boundSources[key];
      const bindings = source.bindings;
      if (bindings && Object.keys(bindings).length === 0) {
        delete this.boundSources[key];
      }
    });
  }

  public resetboundSourcesOfRobot(callsign: string): void {
    if (!this.boundSources) return;
    // 1) Remove bindings
    Object.keys(this.boundSources).forEach((key) => {
      const source = this.boundSources[key];
      const bindings = source.bindings;
      if (bindings) {
        Object.keys(bindings).forEach((bindingKey) => {
          if (bindingKey) {
            const objectId = bindingKey.split('.')[0];
            if (callsign === objectId) delete bindings[bindingKey];
          }
        });
      }
    });

    // 2) Remove all empty bindings
    Object.keys(this.boundSources).forEach((key) => {
      const source = this.boundSources[key];
      const bindings = source.bindings;
      if (bindings && Object.keys(bindings).length === 0) delete this.boundSources[key];
    });

    // 3) Update bindings key array.
    this.updateBindingsKeyArray();
  }

  public getCallsigns(): string[] {
    const callsigns = [];
    if (!this.robots) return callsigns;
    Object.keys(this.robots).forEach((key) => {
      callsigns.push(key);
    });
    return callsigns;
  }

  public getDataSourceOfRobot(callsign: string): string {
    if (this.robots?.[callsign]?.dataSource) {
      return this.robots[callsign].dataSource;
    }
    return 'default';
  }

  public findMesh(meshId: string): IMesh | undefined {
    if (!meshId || !this.meshes) return undefined;

    const pathKey = meshKeyDecode(meshId);
    if (pathKey.length < 2) return this.meshes[meshId];

    if (this.meshes?.[pathKey[0]]?.nodes) {
      return this.meshes[pathKey[0]].nodes.find((item) => item.key === meshId);
    }

    return undefined;
  }

  public getMeshes(type?: RendererDefaultObjectTypes, parent?: string): Record<string, IMesh> {
    if (!type && !parent) return this.meshes;

    return Object.values(this.meshes).reduce((acc, mesh) => {
      const meshAndParent = type && type === mesh.type && parent && parent === mesh.parent;
      const meshAndNoParent = type && type === mesh.type && !parent;
      const parentAndNoMesh = parent && parent === mesh.parent && !type;
      if (meshAndParent || meshAndNoParent || parentAndNoMesh) return { ...acc, [mesh.key]: mesh };
      return acc;
    }, {});
  }

  public getChildren(objectId: string) {
    return Object.values(this.meshes)
      .map((mesh) => {
        if (mesh?.parent === objectId) return mesh;
        return null;
      })
      .filter(Boolean);
  }
}
