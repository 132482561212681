export const saveFileWithUrl = (url: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = url;
  anchor.click();
};

export const saveFileFromBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  saveFileWithUrl(url, filename);
};
