export const evaluateToNumber = (value: any): number => {
  try {
    // eslint-disable-next-line no-eval
    const result = parseFloat(eval(value));
    if (isNaN(result)) return 0;
    return result;
  } catch (e) {
    return 0;
  }
};

export const evaluateToNumberArray = (arr: any[]): number[] => {
  return arr.map((value) => evaluateToNumber(value));
};
