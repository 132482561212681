<ng-container *ngIf="gamepadConfig">
  <div class="controller-panel">
    <app-gamepad-preview
      [gamepadTemplate]="gamepadConfig.gamepad"
      [pressedValues]="latestChangedGamepad && latestChangedGamepad.pressedValues"
    >
    </app-gamepad-preview>
  </div>

  <!-- Button Actions -->
  <div class="mt-4">
    <app-gamepad-button-action
      (actionsChange)="onActionsChange($event)"
      [sources]="buttonDefs"
      [controls]="controls"
      [commands]="commands"
      [projectId]="projectId"
      [pressedValues]="latestChangedGamepad && latestChangedGamepad.pressedValues"
      [actions]="gamepadConfig.actions"
    >
    </app-gamepad-button-action>
  </div>

  <!-- Button Variables -->
  <div class="mt-4">
    <app-gamepad-button-config
      [sources]="buttonDefs"
      [pressedValues]="latestChangedGamepad && latestChangedGamepad.pressedValues"
      [variables]="gamepadConfig.variables"
      (variablesChange)="onVariablesChange($event)"
    ></app-gamepad-button-config>
  </div>

  <div class="mt-4">
    <app-gamepad-press-log [pressingGamepad]="latestChangedGamepad"></app-gamepad-press-log>
  </div>
</ng-container>
