import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material';
import { InvitationsDialogComponent } from './invitations-dialog.component';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { SharedComponentsModule } from '@shared/components';
import { RouterModule } from '@angular/router';
import { LoadingButtonsModule } from '@shared-modules/loading-buttons';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  imports: [
    CommonModule,
    LoadingContentModule,
    SharedComponentsModule,
    RouterModule,
    LoadingButtonsModule,
    MatButtonModule,
    MatDialogModule,
    MaterialModule,
  ],
  declarations: [InvitationsDialogComponent],
  exports: [InvitationsDialogComponent],
})
export class InvitationsDialogModule {}
