<app-widget-base
  [heading]="heading"
  [type]="type"
  [editing]="editing"
  (deleted)="onDelete()"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [isGroupWidget]="isGroupWidget"
  [status]="status"
  [theme]="theme"
  [width]="width"
  [height]="height"
>
  <div class="refresh-button" *ngIf="!liveDataMode" [class.with-group-widget]="isGroupWidget">
    <button type="button" mat-icon-button (click)="onRefreshChart()">
      <span class="ri-refresh"></span>
    </button>
  </div>
  <div class="main-content">
    <div class="chart">
      <app-widget-multiple-lines-chart
        #chart
        [rangeMinimumValue]="rangeMinimumValue"
        [rangeMaximumValue]="rangeMaximumValue"
        [height]="'100%'"
        [theme]="theme"
        [baseInterval]="dateFilter?.widgetTimeInterval"
        [newDataExpiresInSeconds]="60"
        [liveDataMode]="liveDataMode && !demoMode"
      ></app-widget-multiple-lines-chart>
    </div>
    <!-- Graph Legend -->
    <div class="legend" [ngClass]="[theme]">
      <div class="entry" *ngFor="let group of lineGroups">
        <div class="dot" [style.color]="group.lineColor">
          <span class="ri-circle"></span>
        </div>
        <div class="text">
          <div class="first-line">
            <span class="data-label" [matTooltip]="group.dataURI">{{ group.dataLabel }}</span>
            <span *ngIf="group.units">
              (<span class="units">{{ group.units }}</span
              >)</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</app-widget-base>
