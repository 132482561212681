import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DashboardScaleService } from '../../services/dashboard-scale/dashboard-scale.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-scale-buttons',
  templateUrl: './dashboard-scale-buttons.component.html',
  styleUrls: ['./dashboard-scale-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardScaleButtonsComponent implements OnInit {
  scale$: Observable<number>;

  constructor(private dashboardScale: DashboardScaleService) {}

  ngOnInit() {
    this.scale$ = this.dashboardScale.settings.pipe(map((dashboardScale) => dashboardScale.scale));
  }

  incrementScale(): void {
    this.dashboardScale.incrementScale();
  }

  decrementScale(): void {
    this.dashboardScale.decrementScale();
  }
}
