import type { PrimitiveMetaData, ValueTypes } from './primitives';

export type LightPropertyType = 'direction' | 'position' | 'intensity' | 'diffuse' | 'specular' | 'enabled';

export type LightValueType = ValueTypes;

export const lightPropertyValueTypes: Record<LightPropertyType, LightValueType> = {
  direction: 'vector3',
  position: 'vector3',
  intensity: 'number',
  diffuse: 'color3',
  specular: 'color3',
  enabled: 'boolean',
};

export type DefaultLightTypes = 'orthographic' | 'directional' | 'point' | 'spot';

export const supportedLightTypes: Record<DefaultLightTypes, PrimitiveMetaData> = {
  orthographic: {
    key: 'orthographic',
    label: 'Orthographic',
    icon: 'ri-alert',
  },
  directional: {
    key: 'directional',
    label: 'Directional',
    icon: 'ri-alert',
  },
  point: {
    key: 'point',
    label: 'Point',
    icon: 'ri-alert',
  },
  spot: {
    key: 'spot',
    label: 'Spot',
    icon: 'ri-alert',
  },
};
