<app-unsupported-browser></app-unsupported-browser>

<app-navbar [sidebarStatus]="sidebarStatus"></app-navbar>

<div class="content">
  <div class="sidebar" [ngClass]="'status-' + sidebarStatus">
    <div class="sidebar-content">
      <app-sidebar [sidebarStatus]="sidebarStatus"></app-sidebar>
    </div>
  </div>

  <div class="main">
    <router-outlet></router-outlet>
  </div>
</div>

<app-command-palette *appFeatureFlag="FeatureFlags.CommandPalette"></app-command-palette>
