export class RobotSettingKeyValue {
  key: string;
  value: any;
}

export enum RobotSettingType {
  OpsGlobal = 'ops-global-settings',
  OpsLocal = 'ops-local-settings',
  Agent = 'agent-settings',
  FlightApp = 'flight-app-settings',
}

export class RobotSetting {
  enabled: boolean = false;
  id: string = '';
  settings: any;
  isNewOne: boolean = false;

  get settingsString(): string {
    return JSON.stringify(this.settings, null, 4);
  }

  static fromModel(model: any): RobotSetting {
    const setting = new RobotSetting();

    setting.id = model.id;
    setting.enabled = model.enabled;
    setting.settings = model.settings;

    return setting;
  }

  toJSON() {
    return {
      id: this.id,
      enabled: this.enabled,
      settings: this.settings,
    };
  }
}
