export class StreamPluginImage {
  public static readonly rosBridge = 'assets/images/stream-sources/ros_logo.png';
  public static readonly mavLink = 'assets/images/stream-sources/mavlink_logo.png';
}

export class StreamPlugin {
  public id: string;
  public name: string;
  public data?: [
    {
      id: string;
    },
  ];

  /**
   * The logo image url for this type of source
   */
  public logoURI: string;

  constructor(id?: string, name?: string) {
    this.id = id;
    this.name = name;
  }

  public static fromModel(model: any): StreamPlugin {
    const plugin = new StreamPlugin();

    plugin.id = model.id;
    plugin.name = model.name;
    if (model.data) {
      plugin.data = model.data.map((source) => {
        return source;
      });
    }

    plugin.updateLogoURI();

    return plugin;
  }

  private updateLogoURI() {
    switch (this.id) {
      case 'rosbridge':
        this.logoURI = StreamPluginImage.rosBridge;
        break;
      case 'mavlink':
        this.logoURI = StreamPluginImage.mavLink;
        break;
    }
  }
}
