import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';
import { format } from 'date-fns';

@UntilDestroy()
@Component({
  selector: 'app-user-clock',
  template: `
    <span class="icon ri-laptop"></span>
    <div>
      <h4>
        Your Time <ng-container *ngIf="timeZone">({{ timeZone }})</ng-container>
      </h4>
      <p>{{ currentTime | date : 'E, d MMM y H:mm:ss' }}</p>
    </div>
  `,
  styleUrls: ['../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserClockComponent implements OnInit {
  @HostBinding('class.local') isLocal = true;

  currentTime = new Date();

  get timeZone() {
    // gets offset in GMT+X format, then replace to UTC so its consistent with our agent
    const offset = format(this.currentTime, 'z')?.replace('GMT', 'UTC');

    let timeZoneCode = /.*\s(.+)/.exec(
      new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }),
    )[1];

    // try fall back method where date-fns doesn't have the tz code, try to guess abbreviation based on name
    if (!timeZoneCode || timeZoneCode.indexOf('GMT') > -1) {
      timeZoneCode = new Date().toTimeString().match(new RegExp('[A-Z](?!.*[(])', 'g'))?.join('');
    }

    // couldn't figure out the specific code, just return the offset
    if (!timeZoneCode || timeZoneCode.indexOf('GMT') > -1) {
      return offset;
    }

    return `${timeZoneCode}:${offset}`;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentTime = new Date();
        this.cdr.markForCheck();
      });
  }
}
