import { SubscriberStatus } from '@team-rocos/rocos-js';

export enum RobotStatus {
  LIVE = 'live',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
}

export const convertSubscriberStatusToRobotStatus = (subscriberStatus: SubscriberStatus): RobotStatus => {
  let robotStatus: RobotStatus;
  switch (subscriberStatus) {
    case SubscriberStatus.ALIVE:
      robotStatus = RobotStatus.LIVE;
      break;

    case SubscriberStatus.DEAD:
      robotStatus = RobotStatus.OFFLINE;
      break;

    case SubscriberStatus.UNKNOWN:
      robotStatus = RobotStatus.UNKNOWN;
      break;

    default:
      robotStatus = RobotStatus.UNKNOWN;
      break;
  }

  return robotStatus;
};
