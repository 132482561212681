import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { IRocosTelemetryMessage } from '@team-rocos/rocos-js';
import type { Observable, OperatorFunction } from 'rxjs';
import { pipe, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface TelemetrySinkData {
  msg: IRocosTelemetryMessage;
  scope: string;
}

@Injectable({ providedIn: 'root' })
export class TelemetrySinkService {
  private enabledMap: Record<string, boolean> = {};
  private messageObservable = new Subject<TelemetrySinkData>();

  public constructor(private router: Router) {}

  // enable telemetry sink for the current scope
  public enable(): void {
    this.enabledMap[this.scope] = true;
  }

  // disable telemetry sink for the current scope
  public disable(): void {
    this.enabledMap[this.scope] = false;
  }

  /* pipe into an observable to automatically capture telemetry messages.

   NOTE: this is performed automatically if you are using the rocos-client service subscription methods.
         only use this if you are using the SDK manually to avoid double counting
   */
  public tap(): OperatorFunction<IRocosTelemetryMessage, IRocosTelemetryMessage> {
    return pipe(
      tap((msg) => {
        this.addMessage(msg);
      }),
    );
  }

  /* manually record a telemetry message into the sink
   *
   * NOTE: insure before use that it will not be automatically recorded to avoid double counting
   */
  public addMessage(msg: IRocosTelemetryMessage): void {
    if (this.enabled) {
      this.messageObservable.next({
        scope: this.scope,
        msg,
      });
    }
  }

  /* forwards all messages received by the telemetry sink */
  public get observable(): Observable<TelemetrySinkData> {
    return this.messageObservable.asObservable();
  }

  // the current scope used by the sink
  public get scope(): string {
    return this.router.url;
  }

  // whether the sink is currently enabled or not
  public get enabled(): boolean {
    return this.enabledMap?.[this.scope] ?? false;
  }
}
