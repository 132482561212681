import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { IUserInfo } from '../../interfaces';

@Component({
  selector: 'app-user-initials',
  templateUrl: './user-initials.component.html',
  styleUrls: ['./user-initials.component.scss'],
})
export class UserInitialsComponent implements OnInit {
  @Input() public user: IUserInfo;

  public initials: string = '';
  public backgroundColour: string = '';

  public ngOnInit(): void {
    this.backgroundColour = this.getCircleColour();
    this.initials = this.getInitials();
  }

  public getCircleColour(): string {
    if (this.user?.firstName) {
      return `circle ${this.user.firstName.substring(0, 1).toLowerCase()}-colour`;
    }

    return 'circle';
  }

  public getInitials(): string {
    if (this.user?.firstName && this.user.lastName) {
      return `${this.user.firstName.substring(0, 1).toUpperCase()}${this.user.lastName.substring(0, 1).toUpperCase()}`;
    }

    return ``;
  }
}
