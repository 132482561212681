import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { forwardRef } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import type { IsLoading } from '../../interfaces';
import type { Robot } from '../../models';
import { RobotService } from '../../services';

const defaultHint = 'No robot selected.';

interface RobotOption {
  callsign: string;
  name?: string;
}

@Component({
  selector: 'app-robot-search',
  templateUrl: './robot-search.component.html',
  styleUrls: ['./robot-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RobotSearchComponent),
    },
  ],
})
export class RobotSearchComponent implements OnChanges, OnInit, IsLoading, ControlValueAccessor {
  @Input() public projectId: string;
  @Input() public placeholder: string = 'Robot Call Sign';
  @Input() public notFoundTooltip: string = 'Robot not found';
  @Input() public robotRequired: boolean = true;
  @Input() public hint: string = defaultHint;
  @Input() public filledRobots: Robot[] = [];
  @Input() public defaultCallsign: string;
  @Input() public robotDefinition: string;
  @Input() public includeStatus = false;

  @Output() public callsignSelected = new EventEmitter<string>();
  @Output() public robotCountUpdated = new EventEmitter<number>();

  public isLoading: boolean = false;

  public _onChanged: (value: any) => void;
  public _onTouched: () => void;

  protected robots: Robot[] = [];
  protected focused = false;

  protected robot = new FormControl<RobotOption>(null, this.robotRequired ? [Validators.required] : []);

  public constructor(private robotService: RobotService, private cdr: ChangeDetectorRef) {}

  public writeValue(callsign: any): void {
    if (callsign === null) {
      this._onChanged?.(null);
      this.robot.setValue(null);
      return;
    }

    if (typeof callsign !== 'string') {
      return;
    }

    this?._onChanged?.(callsign);
    this.robot.setValue({ callsign });
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.robot.disable();
    } else {
      this.robot.enable();
    }
  }

  public ngOnInit(): void {
    this.robot.setValue({ callsign: this.defaultCallsign });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectId'] || changes['filledRobots'] || changes['robotDefinition']) {
      void this.getRobots();
    }
  }

  protected get isRobotSelected(): boolean {
    return !!this.robot.value?.callsign;
  }

  protected onOptionSelected(robot: RobotOption): void {
    if (robot?.callsign) {
      this.callsignSelected.next(robot.callsign);
      this.focused = false;
    }

    this._onChanged?.(robot?.callsign);
    this._onTouched?.();
  }

  private async getRobots(): Promise<void> {
    let robots: Robot[] = [];

    if (this.filledRobots?.length) {
      robots = this.filledRobots;
    } else {
      robots = await this.robotService.list(this.projectId);
    }

    if (this.robotDefinition?.length) {
      robots = robots.filter((j) => j.robotDefinition === this.robotDefinition);
    }

    this.robots = robots;
    this.robotCountUpdated.emit(robots.length);

    this.cdr.markForCheck();
  }
}
