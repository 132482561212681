<div class="horizonWidget fade-in">
  <div class="circleContainer">
    <div
      class="horizon"
      [style.transform]="'rotate(' + (360 - radianToDegrees(rollValue)) + 'deg)'"
      [style.top]="(radianToDegrees(pitchValue) * 2).toFixed(0) + 'px'"
    >
      <div class="colours"></div>
    </div>
    <div class="scaleMask" [style.transform]="'rotate(' + (360 - radianToDegrees(rollValue)) + 'deg)'">
      <div class="scale" [style.top]="(radianToDegrees(pitchValue) * 2 - 53).toFixed(0) + 'px'"></div>
    </div>
    <div class="innerCircle">
      <div class="pointer"></div>
    </div>
    <div class="outerring" [style.transform]="'rotate(' + (360 - radianToDegrees(rollValue)) + 'deg)'"></div>
  </div>
</div>
