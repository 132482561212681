import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommandPaletteComponent } from './command-palette.component';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner';
import { DirectivesModule } from '@shared/directives';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CommandPaletteComponent],
  imports: [CommonModule, LoadingSpinnerModule, DirectivesModule, ReactiveFormsModule],
  exports: [CommandPaletteComponent],
})
export class CommandPaletteModule {}
