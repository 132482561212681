import type { Scene } from '@babylonjs/core';
import { Mesh, Vector3 } from '@babylonjs/core';
import type { FeatureCollection, Geometry } from 'geojson';
import { MapMeshFactory } from '../factories/map-mesh-factory';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import { pointFromFrame, type GpsLocation } from '../transform';
import { isLineString, isPoint } from 'src/app/operation/shared/widget-workflow-list/flowParser/agentGraphsToGeoJson';
import type { FeatureWithProps } from 'src/app/operation/shared/widget-workflow-list/flowParser/agentGraphsToGeoJson';
import type { GuiFactoryService } from '../factories/gui-factory.service';
import { type PrimitiveMetaData } from '../primitives/primitives';
import { parentDefault } from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';

export const staticGeoJSONMetaData: PrimitiveMetaData = {
  key: 'staticGeoJSON',
  label: 'Static GeoJSON',
  icon: 'ri-3d-box',
  editorPanels: {
    parent: parentDefault,
    properties: {
      name: true,
      position: false,
      rotation: false,
      scaling: false,
      material: false,
    },
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    staticGeoJSON: true,
  },
};

export interface StaticMapMeshData {
  staticGeoJSON?: {
    geoJSON: string;
    flowId: string;
  };
}

export class StaticGeoJSON extends Mesh {
  public readonly key: string;
  public readonly geoJSON: string;
  public readonly flowId: string;
  public readonly projectLocation: GpsLocation;
  public readonly guiFactoryService: GuiFactoryService;

  constructor(
    name: string,
    scene: Scene,
    meshData: IMesh,
    projectLocation: GpsLocation,
    guiFactoryService: GuiFactoryService,
  ) {
    super(name, scene);
    this.key = meshData.key;
    this.geoJSON = meshData.staticGeoJSON?.geoJSON;
    this.flowId = meshData.staticGeoJSON?.flowId;
    this.projectLocation = projectLocation;
    this.guiFactoryService = guiFactoryService;

    const geoJSONdata = JSON.parse(this.geoJSON);
    this.renderGeoJson(geoJSONdata);
    this.position.z = 0.1; // lift the map a bit so its not hidden by the ground grid
  }

  override setEnabled(value: boolean): void {
    super.setEnabled(value);
    this.guiFactoryService.setVisibleForAllGuiControlsOfParentName(this.name, value);
  }

  override dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
    this.guiFactoryService.removeAllGuiControlsOfParentName(this.name);
    super.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  private renderGeoJson(collection: FeatureCollection): void {
    if (!collection.features) return;
    const mapMeshFactory: MapMeshFactory = new MapMeshFactory(this.getScene());
    for (const feature of collection.features as FeatureWithProps[]) {
      const frame = feature.crs.properties.frame;

      if (frame.toUpperCase() === 'WGS84') {
        feature.geometry = this.transformGeometry(feature.geometry);
      }

      const mesh = mapMeshFactory.fromGeoJsonFeature(this.name, feature);
      if (mesh) {
        mesh.parent = this;
        if (feature.properties?.label || feature.properties?.node) {
          this.guiFactoryService.addGuiLabel(
            `${feature.properties.label || feature.properties.node}`,
            mesh,
            this.name,
            true,
          );
        }
      }
    }
  }

  private transformGeometry(geometry: Geometry): Geometry {
    if (isPoint(geometry)) {
      const coordinates = geometry.coordinates;
      const transformedPoint = pointFromFrame(
        this.projectLocation,
        'WGS84',
        new Vector3(coordinates[0], coordinates[1], coordinates[2] || 0),
      );
      return {
        ...geometry,
        coordinates: [transformedPoint.x, transformedPoint.y, transformedPoint.z],
      };
    }

    if (isLineString(geometry)) {
      const coordinates = geometry.coordinates.map((coordinate) => {
        const transformedPoint = pointFromFrame(
          this.projectLocation,
          'WGS84',
          new Vector3(coordinate[0], coordinate[1], coordinate[2] || 0),
        );
        return [transformedPoint.x, transformedPoint.y, transformedPoint.z];
      });
      return {
        ...geometry,
        coordinates,
      };
    }

    return geometry;
  }
}
