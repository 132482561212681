import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { MaterialModule } from '../material';
import { DataSourcesTreeComponent } from './data-sources-tree';
import { DataSourcesTreeItemComponent } from './data-sources-tree-item';
import { DataSourcePreviewComponent } from './data-source-preview';
import { SlowlaneDataSourcePreviewComponent } from './slowlane-data-source-preview';
import { DataExplorerWrapperComponent } from './data-explorer-wrapper';
import { PreviewLineGraphComponent } from './preview-line-graph';
import { DataPickerDialogComponent } from './data-picker-dialog';
import { DataPerformanceComponent } from './data-performance';
import { DataCustomQueryComponent } from './data-custom-query';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { MonacoEditorModule } from '@shared-modules/monaco-editor';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CodeEditorModule } from '@shared-modules/code-editor';
import { AngularSplitModule } from 'angular-split';
import { SendMessageDialogComponent } from '@shared/data-explorer/send-message-dialog';
import { MessagePreviewComponent } from '@shared/data-explorer/message-preview';

const components = [
  DataSourcesTreeComponent,
  DataSourcesTreeItemComponent,
  DataSourcePreviewComponent,
  SlowlaneDataSourcePreviewComponent,
  DataExplorerWrapperComponent,
  PreviewLineGraphComponent,
  DataPickerDialogComponent,
  DataPerformanceComponent,
  DataCustomQueryComponent,
  SendMessageDialogComponent,
  MessagePreviewComponent,
];

@NgModule({
  imports: [
    CommonModule,
    AngularSplitModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    LoadingContentModule,
    SharedComponentsModule,
    MonacoEditorModule,
    CodeEditorModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class DataExplorerModule {}
