import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export type FormCastType = 'group' | 'control';

/**
 * Casts a form control to a form group or form control.
 * This shouldn't be necessary in angular 14+
 */
@Pipe({
  name: 'formCast',
})
export class FormCastPipe implements PipeTransform {
  transform(value: AbstractControl, type: 'group'): UntypedFormGroup;
  transform(value: AbstractControl, type: 'control'): UntypedFormControl;
  transform(value: AbstractControl, type: FormCastType): UntypedFormGroup | UntypedFormControl {
    if (type === 'group' && value instanceof UntypedFormGroup) return value;
    if (type === 'control' && value instanceof UntypedFormControl) return value;

    throw new Error(`Cannot cast ${value?.constructor?.name ?? value} to ${type}`);
  }
}
