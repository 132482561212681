import type { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-data-performance',
  templateUrl: './data-performance.component.html',
  styleUrls: ['./data-performance.component.scss'],
})
export class DataPerformanceComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() payload: any;

  @Input() public callsign: string;

  @Input() public path: string;

  @Input() public latestMessageReceivedAt: Date;

  @ViewChild('canvasWrapMessage')
  canvasWrapMessage: ElementRef;
  @ViewChild('canvasWrapInterval')
  canvasWrapInterval: ElementRef;

  @ViewChild('canvasMessage')
  canvasMessage: ElementRef;
  @ViewChild('canvasInterval')
  canvasInterval: ElementRef;

  public lastInterval: number = 0;

  public payloadCount: number = 0; // current number of the received payload
  public payloadSizeSum: number = 0; // total size of all payloads received since started
  public payloadSize: number = 0;

  public ctxMessage: CanvasRenderingContext2D;
  public ctxInterval: CanvasRenderingContext2D;

  private lastPayloadTick: number;
  private aniInterval;
  private pageReady: boolean = false;

  private ratio = [];

  @HostListener('window:resize', ['$event'])
  private resize(_event?) {
    this.setCanvasSize();
  }

  ngOnInit() {
    this.ratio[0] = 1024; // KB
    this.ratio[1] = 0.05; // interval

    this.lastPayloadTick = Date.now();
  }

  ngOnDestroy() {
    clearInterval(this.aniInterval);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['payload'] || changes['latestMessageReceivedAt'])) {
      this.payloadUpdated();
    }
  }

  payloadUpdated() {
    this.lastInterval = Date.now() - this.lastPayloadTick;
    this.lastPayloadTick = Date.now();

    this.payloadSize = this.KByteLength(JSON.stringify(this.payload, null, 0));
    this.payloadCount++;
    this.payloadSizeSum += this.payloadSize;

    if (this.pageReady) {
      this.drawBar(+this.payloadSize, this.ctxMessage, '#1CD387', this.ratio, 0);
    } else {
      // console.warn('first payload came through without page state being ready');
    }
  }

  setCanvasSize() {
    this.canvasMessage.nativeElement.height = this.canvasWrapMessage.nativeElement.offsetHeight;
    this.canvasMessage.nativeElement.width = this.canvasWrapMessage.nativeElement.offsetWidth;

    this.canvasInterval.nativeElement.height = this.canvasWrapInterval.nativeElement.offsetHeight;
    this.canvasInterval.nativeElement.width = this.canvasWrapInterval.nativeElement.offsetWidth;
  }

  ngAfterViewInit(): void {
    this.setCanvasSize();

    this.ctxMessage = this.canvasMessage.nativeElement.getContext('2d');
    this.ctxInterval = this.canvasInterval.nativeElement.getContext('2d');

    this.aniInterval = setInterval(() => {
      this.shiftGraph();

      // draw the last known interval
      this.drawBar(this.lastInterval, this.ctxInterval, '#FFCCCC', this.ratio, 1);
      this.drawDot(this.lastInterval, this.ctxInterval, '#FF9999', this.ratio, 1);
    }, 50);

    this.pageReady = true;
  }

  adjustScale(value, ctx, ratioArray, ratioIndex) {
    if (value * ratioArray[ratioIndex] >= ctx.canvas.height) {
      ratioArray[ratioIndex] = (ctx.canvas.height - ctx.canvas.height / 2) / value;
    }
  }

  drawBar(value, ctx, color, ratioArray, ratioIndex) {
    ctx.fillStyle = color;

    let barHeight = ctx.canvas.height - +(value * ratioArray[ratioIndex]).toFixed(0); // / this.messageRatio).toFixed(0)

    if (barHeight < 0) {
      this.adjustScale(value, ctx, ratioArray, ratioIndex);
      barHeight = ctx.canvas.height - +(value * ratioArray[ratioIndex]).toFixed(0);
    }
    ctx.fillRect(ctx.canvas.width - 1, barHeight, 1, ctx.canvas.height);
  }

  drawDot(value, ctx, color, ratioArray, ratioIndex) {
    let pos = ctx.canvas.height - value * ratioArray[ratioIndex];
    if (pos < 0) {
      this.adjustScale(value, ctx, ratioArray, ratioIndex);
      pos = ctx.canvas.height - value * ratioArray[ratioIndex];
    }
    ctx.fillStyle = color;
    ctx.fillRect(ctx.canvas.width - 1, pos, 1, 1);
  }

  // move everything to the left 1px
  shiftGraph() {
    this.ctxMessage.globalCompositeOperation = 'copy';
    this.ctxMessage.drawImage(this.ctxMessage.canvas, -1, 0);
    this.ctxMessage.globalCompositeOperation = 'source-over';

    this.ctxInterval.globalCompositeOperation = 'copy';
    this.ctxInterval.drawImage(this.ctxInterval.canvas, -1, 0);
    this.ctxInterval.globalCompositeOperation = 'source-over';
  }

  private KByteLength(str) {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) {
        s++;
      } else if (code > 0x7ff && code <= 0xffff) {
        s += 2;
      }
      if (code >= 0xdc00 && code <= 0xdfff) {
        i--;
      } // trail surrogate
    }
    return s / 1024;
  }
}
