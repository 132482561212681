import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { combineLatest, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ProjectService } from './project.service';

export class ProjectPermissionProjectInfo {
  isLoaded: boolean = false;
  isLoading: boolean = false;
  permissions: {
    [permissionId: string]: boolean;
  } = {};

  checkPermission(permissionId: string): boolean {
    return !!this.permissions[permissionId];
  }
}

export class ProjectPermissionInfo {
  info: {
    [projectId: string]: ProjectPermissionProjectInfo;
  } = {};

  getByProjectId(projectId: string): ProjectPermissionProjectInfo {
    if (!this.info[projectId]) {
      this.info[projectId] = new ProjectPermissionProjectInfo();
    }

    return this.info[projectId];
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectPermissionService {
  subjects: {
    [projectId: string]: {
      [permissionId: string]: Subject<boolean>;
    };
  } = {};

  projectsInfo: ProjectPermissionInfo = new ProjectPermissionInfo();

  constructor(private projectService: ProjectService) {}

  /**
   * Check if the project has the permission for the feature flag
   *
   * @param projectId project id to check against
   * @param permissionId can be a single feature flag id or an array of feature flags. If array is passed in,
   * sysetm will verify the permission with AND operaion on top of all flags.
   */
  public checkPermission(projectId: string, permissionId: string | string[]): Observable<boolean> {
    const permissions = Array.isArray(permissionId) ? permissionId : [permissionId];

    const permissionSubjects: Subject<boolean>[] = [];

    permissions.forEach((thisPermissionId) => {
      permissionSubjects.push(this.getSubject(projectId, thisPermissionId));

      this.checkPermissionInBackground(projectId, thisPermissionId);
    });

    // The final result is treated with AND logical operator from all feature flag
    return combineLatest(permissionSubjects).pipe(
      map((perm) => {
        return perm.every(Boolean);
      }),
      first(),
    );
  }

  private checkPermissionInBackground(projectId: string, permissionId: string) {
    const info: ProjectPermissionProjectInfo = this.projectsInfo.getByProjectId(projectId);

    if (info.isLoaded) {
      const hasPermission = info.checkPermission(permissionId);

      const subject = this.getSubject(projectId, permissionId);

      // Do not immediately callback
      setTimeout(() => {
        subject.next(hasPermission);
      }, 0);
    } else {
      if (info.isLoading) {
        // Do nothing - END
      } else {
        // Load Remote Info
        this.loadProjectPermissions(projectId);
      }
    }
  }

  private loadProjectPermissions(projectId: string) {
    const info = this.projectsInfo.getByProjectId(projectId);
    info.isLoading = true;

    this.projectService.getRemotePermissions(projectId).subscribe((permissions: string[]) => {
      if (permissions) {
        info.isLoaded = true;

        // Update permissions object
        if (permissions.length > 0) {
          permissions.forEach((permission) => {
            info.permissions[permission] = true;
          });
        }

        const subjects = this.getSubjectsByProjectId(projectId);

        if (subjects) {
          Object.keys(subjects).forEach((key) => {
            const subject = subjects[key];

            const hasPermission = permissions.includes(key);

            subject.next(hasPermission);
          });
        }
      }
      info.isLoading = false;
    });
  }

  private getSubject(projectId: string, permissionId: string): Subject<boolean> {
    if (!this.subjects[projectId]) {
      this.subjects[projectId] = {};
    }
    if (!this.subjects[projectId][permissionId]) {
      const subject = new Subject<boolean>();
      this.subjects[projectId][permissionId] = subject;
    }

    return this.subjects[projectId][permissionId];
  }

  private getSubjectsByProjectId(projectId: string) {
    if (this.subjects[projectId]) {
      return this.subjects[projectId];
    }

    return {};
  }
}
