import { FullscreenService } from './../../services/fullscreen/fullscreen.service';
import type { OnInit } from '@angular/core';
import { Component, ElementRef, Input } from '@angular/core';
import type { Observable } from 'rxjs';

@Component({
  selector: 'app-fullscreen-controls',
  templateUrl: './fullscreen-controls.component.html',
  styleUrls: ['./fullscreen-controls.component.scss'],
})
export class FullscreenControlsComponent implements OnInit {
  @Input() element: ElementRef = null;

  public fsEnabled$: Observable<boolean>;

  constructor(private fullscreenService: FullscreenService) {}

  ngOnInit(): void {
    this.fsEnabled$ = this.fullscreenService.fullScreenEnabled$.asObservable();
  }

  triggerFullScreen() {
    if (this.element) {
      this.fullscreenService.triggerFullscreen(this.element);
    }
  }

  exitFullscreen() {
    this.fullscreenService.exitFullscreen();
  }
}
