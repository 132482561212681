import type { IButtonColour, IButtonGroupButton, TButtonGroupButtonLayout } from '../../interfaces';
import { WidgetBase } from './widget-base';
import { ButtonGroupColour } from '../../enums';

export class WidgetButtonGroup extends WidgetBase {
  private static buttonColours: Record<ButtonGroupColour, IButtonColour> = {
    [ButtonGroupColour.slate]: { colour: '#232B34', hover: '#363F4B' },
    [ButtonGroupColour.indigo]: { colour: '#653fb2', hover: 'rgb(62, 40, 110)' },
    [ButtonGroupColour.blue]: { colour: '#3f48e9', hover: 'rgb(62, 40, 110)' },
    [ButtonGroupColour.orange]: { colour: '#fd9527', hover: 'rgb(166, 94, 37)' },
    [ButtonGroupColour.red]: { colour: '#d93f3f', hover: 'rgb(139, 41, 41)' },
    [ButtonGroupColour.green]: { colour: '#22BD66', hover: 'rgb(38, 107, 61)' },
  };

  public buttonLayout: TButtonGroupButtonLayout;
  public buttons: IButtonGroupButton[];

  public static fromModel(model: Partial<WidgetButtonGroup>): WidgetButtonGroup {
    const widget = new WidgetButtonGroup();

    widget.readValuesFromModel(model);

    return widget;
  }

  public static getColourForValue(value: string): IButtonColour {
    return WidgetButtonGroup.buttonColours?.[value] || WidgetButtonGroup.buttonColours[ButtonGroupColour.slate];
  }

  public static getValueForColour(colour: string): ButtonGroupColour {
    for (const key of Object.keys(WidgetButtonGroup.buttonColours)) {
      if (
        WidgetButtonGroup.buttonColours[key].colour === colour ||
        WidgetButtonGroup.buttonColours[key].hover === colour
      ) {
        return key as ButtonGroupColour;
      }
    }
    return ButtonGroupColour.slate;
  }

  public override readValuesFromModel(model: Partial<WidgetButtonGroup>): void {
    super.readValuesFromModel(model);

    this.minItemCols = model.minItemCols ? model.minItemCols : 10;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;

    this.buttonLayout = model.buttonLayout;
    this.buttons = model.buttons;
  }
}
