import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, maxLength: number, ellipsisPosition: 'right' | 'left' = 'right', ellipsis = '...'): any {
    let result = null;
    if (value && typeof value === 'string') {
      if (value.length > maxLength) {
        if (ellipsisPosition === 'left') {
          result = ellipsis + value.substr(value.length - maxLength + ellipsis.length, maxLength - ellipsis.length);
        } else if (ellipsisPosition === 'right') {
          result = value.substr(0, maxLength - ellipsis.length) + ellipsis;
        } else {
          result = value;
        }
      } else {
        result = value;
      }
    }

    return result;
  }
}
