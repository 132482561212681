import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-debug-infos',
  templateUrl: './debug-infos.component.html',
  styleUrls: ['./debug-infos.component.scss'],
})
export class DebugInfosComponent {
  @Input() videoSourceStatus = null;
  @Input() lastWebrtcFramesDecodedChanged = null;
  @Input() lastWebrtcFramesReceivedChanged = null;
  @Input() lastCoordinatePicked = null;
}
