import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import type { ButtonDef, ButtonDefWithValue, CommandV2, GamepadControl } from '../../models';
import { GamepadButtonAction } from '../../models';
import { GamepadButtonActionDialogComponent } from '../gamepad-button-action-dialog';

@Component({
  selector: 'app-gamepad-button-action',
  templateUrl: './gamepad-button-action.component.html',
  styleUrls: ['../shared-styles.scss', './gamepad-button-action.component.scss'],
})
export class GamepadButtonActionComponent implements OnChanges {
  @Input()
  actions: GamepadButtonAction[] = [];

  @Input()
  pressedValues: ButtonDefWithValue[] = [];

  @Input()
  controls: GamepadControl[];

  @Input()
  projectId: string;

  @Input()
  commands: CommandV2[];

  @Input()
  sources: ButtonDef[] = [];

  @Output()
  actionsChange = new EventEmitter<GamepadButtonAction[]>();

  @ViewChild('actionsTable')
  actionsTable: MatTable<GamepadButtonAction>;

  public pressedButtonsObject: {
    [name: string]: ButtonDefWithValue;
  } = {};

  get json(): any {
    if (this.actions) {
      return this.actions.map((item) => {
        return item.toJSON();
      });
    }
  }

  dialogRef: MatDialogRef<GamepadButtonActionDialogComponent>;

  displayedColumns: string[] = ['name', 'source', 'frequency', 'type', 'commandId', 'editing'];

  constructor(private dialog: MatDialog, private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commands'] && this.dialogRef?.componentInstance) {
      // Update commands to current opening dialog if there are any
      this.dialogRef.componentInstance.setControls(this.controls);
    }
    if (changes['sources'] && this.dialogRef?.componentInstance) {
      // Update sources to current opening dialog if there are any
      this.dialogRef.componentInstance.setSources(this.sources);
    }

    const obj = {};
    if (changes['pressedValues'] && this.pressedValues) {
      this.pressedValues.forEach((value) => {
        obj[value.type] = value;
      });
    }
    this.pressedButtonsObject = obj;
  }

  onEdit(item: GamepadButtonAction, index: number) {
    const dialogRef = this.dialog.open(GamepadButtonActionDialogComponent, {
      data: {
        projectId: this.projectId,
        model: item,
        controls: this.controls,
        commandsV2: this.commands,
        sources: this.sources,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const model = GamepadButtonAction.fromJSON(result);
        if (this.actions) {
          this.actions.splice(index, 1, model);

          this.actionsTable.renderRows();

          this.actionsChanged();
        }
      }
    });
  }

  onRemove(item: GamepadButtonAction, index: number) {
    const title = 'Delete Action?';
    const message = '<p>Are you sure you want to delete this action?</p>';

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          this.actions.splice(index, 1);

          this.actionsTable.renderRows();

          this.actionsChanged();
        }
      });
  }

  onAddNew() {
    this.dialogRef = this.dialog.open(GamepadButtonActionDialogComponent, {
      data: {
        projectId: this.projectId,
        commandsV2: this.commands,
        controls: this.controls,
        sources: this.sources,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newAction = GamepadButtonAction.fromJSON(result);

        this.actions = [...this.actions, newAction];

        this.actionsChanged();
      }
    });
  }

  private _loadDemoData() {
    const json = [
      {
        name: 'forwardBack',
        source: {
          type: 5,
        },
        frequency: 200,
        triggerType: 'continuously',
        commandId: 'drive',
      },
      {
        name: 'fire',
        source: {
          type: 17,
        },
        frequency: 1000,
        triggerType: 'once',
        commandId: 'forward',
      },
      {
        name: 'stop',
        source: {
          type: 16,
        },
        frequency: 1000,
        triggerType: 'once',
        commandId: 'stop',
      },
    ];
    const actions: GamepadButtonAction[] = GamepadButtonAction.fromJSONToList(json);

    this.actions = actions;
    this.actionsChanged();
  }

  private actionsChanged() {
    this.actionsChange.next(this.actions);
  }
}
