import { Injectable } from '@angular/core';
import { RocosClientService } from '../rocos-client';
import type { ExportDataQuery } from '@team-rocos/rocos-js';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private rocosClientService: RocosClientService) {}

  /**
   * Submit a new job to export data
   *
   * @param projectId Project Id
   * @param query Query
   */
  public exportJobs(projectId: string, query: ExportDataQuery): Observable<any> {
    return this.rocosClientService.rocosClient.data.exportJobs(projectId, query).pipe(
      map((res) => {
        return res.data as any[];
      }),
    );
  }
}
