import type { VideoSource, VideoSourceStatus, StatsResult, VideoSourceStatusEnum } from './video-source';
import { parseStats } from './video-source';
import { Subject } from 'rxjs';
import type { Message, PigeonOperatorService } from '@team-rocos/rocos-js';
import { OperatorConnectRequest, OfferRequest, GetDetailsRequest, AddIceCandidateRequest } from '@team-rocos/rocos-js';
import { environment } from '@env/environment';
import type { DialogService } from '../../../dialogs';
import { first } from 'rxjs/operators';
import { StatusCode } from 'grpc-web';
import type { RocosClientService } from '../../../services';

export class P2PVideoSource implements VideoSource {
  operatorService: PigeonOperatorService;
  videoMessageStream: any;

  videoServerSuccess: Subject<boolean> = new Subject<boolean>();
  videoSourceStatus: Subject<VideoSourceStatus> = new Subject<VideoSourceStatus>();
  videoServer: string = null;
  isVideoStreaming: Subject<boolean> = new Subject<boolean>();
  isVideoConnecting: Subject<boolean> = new Subject<boolean>();
  videoId: string;
  playoutDelayHint: string;
  stream: Subject<MediaStream> = new Subject<MediaStream>();
  videoCommand: string =
    'videotestsrc ! video/x-raw,format=I420 ' +
    '! x264enc bframes=0 speed-preset=veryfast key-int-max=60 ' +
    '! video/x-h264,stream-format=byte-stream';

  peerConnection: RTCPeerConnection = null;
  turbo: number = 1;
  allowDebug: boolean = true;
  webRtcStats: StatsResult;
  lastBitrateRecordTime: number;
  bitrateKbps: number = 0;
  message: string;
  watching: boolean = false;

  lastJitterBufferDelay: number = 0;
  lastJitterBufferEmittedCount: number = 0;

  private _initialVideoSourceStatus = {
    webrtcPeerConnectionStatus: false,
    webrtcIceStateConnected: false,
    webrtcIceConnectionState: null,
    webrtcStreamStarted: false,
    webrtcBitrate: null,
    realInterval: null,
    webrtcAudioIn: null,
    webrtcVideoIn: null,
    webrtcAudioOut: null,
    webrtcVideoOut: null,
    webrtcSlow: null,
    webrtcRoundTripTimeMs: null,
    webrtcPacketLossPercent: null,
    webrtcFramesDropped: null,
    webrtcFramesReceived: null,
    webrtcFramesDecoded: null,
    jitterBufferDelay: null,
    jitterBufferEmittedCount: null,
    avgFramePlayout: null,
  };

  private _videoSourceStatus;
  private _videoStreaming: boolean = false;
  private _videoConnecting: boolean = false;
  private _awaitAnswerTimeout: NodeJS.Timer = null;
  private _awaitPeerConnectionTimeout: NodeJS.Timer = null;
  private _retryWatchTimeout: NodeJS.Timer = null;
  private _updateStatsTimerInterval: NodeJS.Timer = null;
  private _retryTimerInterval: NodeJS.Timer = null;

  constructor(protected dialogService: DialogService, private rocosClientService: RocosClientService) {
    this.resetVideoSourceStatus();
  }

  public initConnection(videoServer: string): void {
    this.videoServer = videoServer;
    // connect to signaling server
    this.operatorService = this.rocosClientService.pigeonOperatorService;
  }

  answerHandler(answerRequest: any): void {
    if (!this.watching) {
      return;
    }
    const remoteDescription = JSON.parse(atob(answerRequest.sdp));

    if (this.peerConnection) {
      this.peerConnection
        .setRemoteDescription(new RTCSessionDescription(remoteDescription))
        .then(() => {
          // will not be called immediately if a connection is already underway
          // log('Finished setRemoteDescription()');
        })
        .catch((err) => {
          this.log(err);
        });
    }
  }

  bootHandler(): void {
    if (!this.watching) {
      return;
    }
    this.message = 'Another operator has taken over the connection.';
    this.stopInternal(false);
  }

  restartWatching(projectId: string, message: string): void {
    console.log(`restart watching: projectId: ${projectId}`);
    this.stopStream(true, message + ': Restarting connection...');
    this._retryWatchTimeout = setTimeout(() => this.startWatching(projectId), 3000);
  }

  startWatching(projectId: string): void {
    this.clearInternalTimers();
    // connect as an operator (disconnects others)...
    this.updateVideoStreaming(false);
    this.updateVideoConnecting(true);

    this.message = 'Initiating...';

    const request = new OperatorConnectRequest();
    request.setVideoid(this.videoId);
    this.videoMessageStream = this.operatorService.connect(request, projectId);
    this.log('Connected to Pigeon Operator Service. Waiting for camera...');
    this.log(`Waiting for camera...`);
    this.message = 'Waiting for camera...';
    this.videoServerSuccess.next(true);
    this.watching = true;

    this.videoMessageStream.on('error', (err: any) => {
      if (this._awaitAnswerTimeout) {
        clearTimeout(this._awaitAnswerTimeout);
      }
      if (this._awaitPeerConnectionTimeout) {
        clearTimeout(this._awaitPeerConnectionTimeout);
      }
      console.log(`Signaling server disconnection`, err);

      this.log(`Status code: ${err.code}`);
      if (err.code === StatusCode.RESOURCE_EXHAUSTED) {
        // The disconnection might be interapted by another operator watchning.
        // We only want to stop stream but not restarting watching again.
        this.bootHandler();
      } else {
        this.restartWatching(projectId, `Netowrk error with code ${err.code}`);
      }
    });

    this.videoMessageStream.on('data', (r: Response) => {
      const response = r as any as Message;
      const messageType = response.getMessagetype();
      this.log(`Received '${messageType}' message`);

      switch (messageType) {
        case 'camera-connected': {
          this.log('Camera connected');
          this.message = 'Camera online. Waiting for video stream...';
          this.setupPeerConnection(projectId);
          this._awaitAnswerTimeout = setTimeout(
            (projId: string, msg: string) => {
              this.restartWatching(projId, msg);
            },
            10000,
            projectId,
            'Timeout waiting for answer from signaling server',
          );
          break;
        }
        case 'answer': {
          clearTimeout(this._awaitAnswerTimeout);
          const payload = response.getPayload();
          const answer = JSON.parse(payload);
          this.answerHandler(answer);
          this._awaitPeerConnectionTimeout = setTimeout(
            (projId: string, msg: string) => {
              this.restartWatching(projId, msg);
            },
            10000,
            projectId,
            'Timeout waiting for peer connection',
          );
          break;
        }
      }
    });
  }

  startStream(projectId: string): void {
    const cameraStatusRequest = new GetDetailsRequest();
    cameraStatusRequest.setVideoid(this.videoId);
    this.operatorService.get(cameraStatusRequest, projectId, (_, response) => {
      const operators = response ? response.getOperators() : 0;

      if (operators > 0) {
        const title = 'Existing Connection Detected';

        const message =
          'There is someone already connected to this camera. ' +
          'If you continue to connect, they will be disconnected.';

        this.dialogService
          .confirm(title, message)
          .pipe(first())
          .subscribe((confirmed) => {
            if (confirmed) {
              this.startWatching(projectId);
            }
          });
      } else {
        this.startWatching(projectId);
      }
    });
  }

  setupPeerConnection(projectId: string): void {
    this.peerConnection = new RTCPeerConnection({
      iceServers: environment.p2pSource.iceServers,
    });

    (this.peerConnection as any).ontrack = (e: { streams: MediaStream[] }) => {
      this.log(
        `PeerConnection received a streams containing ${e.streams[0].getAudioTracks().length} audio tracks(s) and ${
          e.streams[0].getVideoTracks().length
        } video tracks(s)`,
      );
      this.stream.next(e.streams[0]);

      if (this.playoutDelayHint && /^\d+$/.test(this.playoutDelayHint)) {
        // playoutDelayHint value is in second, convert from mili second to second
        const playoutDelayHint = parseInt(this.playoutDelayHint, 10) / 1000;
        if (playoutDelayHint !== 0) {
          const receivers = (this.peerConnection as any).getReceivers();
          // Set the playoutDelayHint
          if (receivers.length > 0 && !receivers[0].playoutDelayHint) {
            receivers[0].playoutDelayHint = playoutDelayHint;
          }
        }
      }
    };

    this.peerConnection.onsignalingstatechange = () => {
      if (this.peerConnection) {
        this.log(`PeerConnection signaling state changed to '${this.peerConnection.signalingState}'`);
      }
    };

    this.peerConnection.oniceconnectionstatechange = () => {
      if (this.peerConnection) {
        this.log(`PeerConnection ice connection state changed to '${this.peerConnection.iceConnectionState}'`);
      }

      if (
        this.peerConnection &&
        (this.peerConnection.iceConnectionState === 'failed' ||
          this.peerConnection.iceConnectionState === 'disconnected' ||
          this.peerConnection.iceConnectionState === 'closed')
      ) {
        // Handle the failure
        this.log('****************************************************');
        this.log('the peer connection failed and we need to handle it');
        this.log('****************************************************');
      }

      if (this.peerConnection?.iceConnectionState === 'connected') {
        if (this._awaitAnswerTimeout) {
          clearTimeout(this._awaitAnswerTimeout);
        }
        if (this._awaitPeerConnectionTimeout) {
          clearTimeout(this._awaitPeerConnectionTimeout);
        }
      }
    };

    this.peerConnection.onicegatheringstatechange = () => {
      if (this.peerConnection) {
        this.log(`PeerConnection ice gathering state changed to '${this.peerConnection.iceGatheringState}'`);
      }
    };

    this.peerConnection.onicecandidateerror = (event: any) => {
      this.log(`PeerConnection error occurred during ICE candidate gathering: Code: '${event.errorCode}'`);
    };

    this.peerConnection.onnegotiationneeded = () => {
      this.createAndSendOffer(null, projectId);
    };

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate?.candidate) {
        const request = new AddIceCandidateRequest();
        request.setVideoid(this.videoId);
        request.setCandidate(event.candidate.candidate);
        this.operatorService.addIceCandidate(request, projectId, (err, response) => {
          if (err) {
            this.log('this.operatorService.addIceCandidate ERROR:');
            this.log(err);
          }
          if (response) {
            this.log('this.operatorService.addIceCandidate RESPONSE:');
            this.log(response);
          }
        });
      } else {
        // All candidates received
        this.log('All candidates received!');
      }
    };

    this.peerConnection.onconnectionstatechange = (_) => {
      // "new" | "connecting" | "connected" | "disconnected" | "failed" | "closed";
      if ((this.peerConnection as any) == null) {
        return;
      }

      const connectionState = this.peerConnection.connectionState;

      this.log(`PeerConnection connection state changed to '${connectionState}'`);

      switch (connectionState) {
        case 'failed':
        case 'disconnected':
          this.message = `${connectionState}: retrying...`;
          // if (this._retryTimerInterval) {
          //   return;
          // }
          // this._retryTimerInterval = setInterval(() => this.retry(projectId), 10000);

          this.restartWatching(projectId, `Stream ${connectionState}`);
          break;
        case 'connected':
          this.message = null;
          this.updateVideoStreaming(true);
          this.updateVideoConnecting(false);
          break;
        default:
          this.message = connectionState;
      }
    };

    this.peerConnection.addTransceiver('video', { 'direction': 'recvonly' });

    if (this._updateStatsTimerInterval) {
      clearInterval(this._updateStatsTimerInterval);
    }
    this._updateStatsTimerInterval = setInterval(() => this.updateStats(), 1000 / this.turbo);
  }

  setStatusUpdateFrequency(interval: number): void {
    if (this._updateStatsTimerInterval) {
      clearInterval(this._updateStatsTimerInterval);
    }
    this._updateStatsTimerInterval = setInterval(() => this.updateStats(), interval);
    this.turbo = 1000 / interval;
  }

  createAndSendOffer(offerOptions: RTCOfferOptions, projectId: string): void {
    offerOptions = offerOptions || { iceRestart: false };
    this.peerConnection
      .createOffer(offerOptions)
      .then((offer) => {
        this.peerConnection.setLocalDescription(offer).then(() => {
          this.sendOffer(this.peerConnection.localDescription, projectId);
        });
      })
      .catch(this.log);
  }

  sendOffer(offer: RTCSessionDescription, projectId: string): void {
    const sdp = btoa(JSON.stringify(offer));
    const videoFormat = 'H264';

    const request = new OfferRequest();
    request.setSdp(sdp);
    request.setVideocommand(this.videoCommand);
    request.setVideoid(this.videoId);
    request.setClientid('');
    request.setVideoformat(videoFormat);

    this.operatorService.offer(request, projectId, (err, response) => {
      if (err) {
        this.log(err);
      }
      if (response) {
        this.log(response);
      }
    });
  }

  retry(projectId: string): void {
    console.log('retrying...');
    if (!this._retryTimerInterval) {
      return;
    }

    if (
      !this._videoStreaming ||
      this.peerConnection === null ||
      (this.peerConnection as any).connectionState === 'connected'
    ) {
      clearInterval(this._retryTimerInterval);
      this._retryTimerInterval = null;
      return;
    }

    this.createAndSendOffer({ iceRestart: true }, projectId);
  }

  updateStats(): void {
    let diff = 1000 / this.turbo;

    if (this.lastBitrateRecordTime) {
      const now = window.performance.now();
      diff = now - this.lastBitrateRecordTime;
    }

    this._videoSourceStatus.realInterval = diff;

    this.lastBitrateRecordTime = window.performance.now();
    if (this.peerConnection) {
      this.peerConnection.getStats(null).then((stats) => {
        const statsResult: StatsResult = parseStats(stats);

        this._videoSourceStatus.webrtcRoundTripTimeMs = statsResult.bandwidth.currentRoundTripTime * 1000;

        this._videoSourceStatus.webrtcPeerConnectionStatus = this.peerConnection.connectionState === 'connected';
        this._videoSourceStatus.webrtcIceStateConnected =
          ['connected', 'completed'].indexOf(this.peerConnection.iceConnectionState) > 0;
        this._videoSourceStatus.webrtcIceConnectionState =
          this.peerConnection.iceConnectionState[0].toUpperCase() + this.peerConnection.iceConnectionState.substr(1);

        if (statsResult.video.packetsReceived > 0) {
          this._videoSourceStatus.webrtcPacketLossPercent = +(
            (statsResult.video.packetsLost / statsResult.video.packetsReceived) *
            100
          ).toFixed(2);
        }

        if (this.peerConnection.getTransceivers().length > 0) {
          const transceiver = this.peerConnection.getTransceivers()[0];
          this._videoSourceStatus.webrtcStreamStarted = !transceiver['stopped'];
        } else {
          this._videoSourceStatus.webrtcStreamStarted = false;
        }

        this._videoSourceStatus.webrtcVideoIn = statsResult.video.recv.tracks.length > 0;

        if (this.webRtcStats && statsResult.video.bytesReceived && this.webRtcStats.video.bytesReceived) {
          if (this.webRtcStats.video.bytesReceived > statsResult.video.bytesReceived) {
            this.webRtcStats.video.bytesReceived = statsResult.video.bytesReceived;
          }
          // multiply to turbo because we may have changed the update rate
          const extraBytesReceived = statsResult.video.bytesReceived - this.webRtcStats.video.bytesReceived;
          const bytesPerSecond = (extraBytesReceived / this._videoSourceStatus.realInterval) * 1000;
          const kbitsPerSecond = bytesPerSecond / 128;
          this.bitrateKbps = kbitsPerSecond;
          this._videoSourceStatus.webrtcBitrate = Math.floor(this.bitrateKbps);
        } else {
          this._videoSourceStatus.webrtcBitrate = '0';
        }

        if (statsResult.video?.recv) {
          if (statsResult.video.recv.framesDropped || statsResult.video.recv.framesDropped === 0) {
            this._videoSourceStatus.webrtcFramesDropped = statsResult.video.recv.framesDropped;
          }
          if (statsResult.video.recv.framesDecoded || statsResult.video.recv.framesDecoded === 0) {
            this._videoSourceStatus.webrtcFramesDecoded = statsResult.video.recv.framesDecoded;
          }
          if (statsResult.video.recv.framesReceived || statsResult.video.recv.framesReceived === 0) {
            this._videoSourceStatus.webrtcFramesReceived = statsResult.video.recv.framesReceived;
          }
          if (statsResult.video.recv.jitterBufferDelay) {
            this._videoSourceStatus.jitterBufferDelay = statsResult.video.recv.jitterBufferDelay;
          }
          if (statsResult.video.recv.jitterBufferEmittedCount) {
            this._videoSourceStatus.jitterBufferEmittedCount = statsResult.video.recv.jitterBufferEmittedCount;
          }

          if (statsResult.video.recv.jitterBufferDelay && statsResult.video.recv.jitterBufferEmittedCount) {
            this._videoSourceStatus.jitterBufferEmittedCount = statsResult.video.recv.jitterBufferEmittedCount;

            const currentJitterBufferDelay = statsResult.video.recv.jitterBufferDelay;
            const delayThisPeriod = currentJitterBufferDelay - this.lastJitterBufferDelay;
            this.lastJitterBufferDelay = currentJitterBufferDelay;

            const currentJitterBufferEmittedCount = statsResult.video.recv.jitterBufferEmittedCount;
            const countThisPeriod = currentJitterBufferEmittedCount - this.lastJitterBufferEmittedCount;
            this.lastJitterBufferEmittedCount = currentJitterBufferEmittedCount;

            const avgDelayThisPeriod = delayThisPeriod / countThisPeriod;
            this._videoSourceStatus.avgFramePlayout = avgDelayThisPeriod;
          } else {
            this._videoSourceStatus.avgFramePlayout = null;
          }
        }

        this.webRtcStats = statsResult;
        this.updateVideoSourceStatus();
      });
    }
  }

  clearInternalTimers(): void {
    if (this._awaitAnswerTimeout) {
      clearTimeout(this._awaitAnswerTimeout);
    }
    if (this._awaitPeerConnectionTimeout) {
      clearTimeout(this._awaitPeerConnectionTimeout);
    }
    if (this._updateStatsTimerInterval) {
      clearInterval(this._updateStatsTimerInterval);
    }
    if (this._retryWatchTimeout) {
      clearTimeout(this._retryWatchTimeout);
    }
    if (this._retryTimerInterval) {
      clearInterval(this._retryTimerInterval);
    }
  }

  stopInternal(restartHasScheduled?: boolean): void {
    // this is called when booted
    // don't send stop command to server - it will disconnect the other viewer
    this.updateVideoStreaming(false);
    this.updateVideoConnecting(restartHasScheduled);

    this.stream.next(null);

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }

    this.clearInternalTimers();
  }

  stopStream(restartHasScheduled: boolean = false, msg?: string): void {
    this.watching = false;
    if (this.videoMessageStream) {
      this.videoMessageStream.cancel();
    }

    this.message = msg;
    this.stopInternal(restartHasScheduled);
    this.resetVideoSourceStatus();
    this.videoSourceStatus.next(this._videoSourceStatus);
  }

  resetVideoSourceStatus() {
    this._videoSourceStatus = JSON.parse(JSON.stringify(this._initialVideoSourceStatus));
  }

  dispose(): void {
    this.stopStream();
    // this.operatorClient = null;
  }

  private log(msg: any): void {
    console.log(msg);
  }

  private updateVideoSourceStatus(key?: VideoSourceStatusEnum, value?: any) {
    if (key !== undefined && value !== undefined) {
      this._videoSourceStatus[key] = value;
    }

    this.videoSourceStatus.next(this._videoSourceStatus);
  }

  private updateVideoStreaming(streaming?: boolean) {
    if (streaming !== undefined) {
      this._videoStreaming = streaming;
    }

    this.isVideoStreaming.next(this._videoStreaming);
  }

  private updateVideoConnecting(connecting?: boolean) {
    if (connecting !== undefined) {
      this._videoConnecting = connecting;
    }

    this.isVideoConnecting.next(this._videoConnecting);
  }
}
