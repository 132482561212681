import type { Scene } from '@babylonjs/core';
import { Mesh } from '@babylonjs/core';
import { MapMeshFactory } from '../../factories/map-mesh-factory';
import type { WaypointLocation } from '../visualizer/interface/IWaypointPicker';
import type { Feature, FeatureCollection } from 'geojson';

export class WaypointArrayGizmo extends Mesh {
  constructor(name: string, scene: Scene) {
    super(name, scene);
  }

  public updateWaypoints(waypoints: WaypointLocation[]): void {
    this.clear();

    const collection = this.getFeatureCollectionfromWaypoints(waypoints);
    const mapMeshFactory: MapMeshFactory = new MapMeshFactory(this.getScene());
    for (const feature of collection.features) {
      const mesh = mapMeshFactory.fromGeoJsonFeature(this.name, feature);
      if (mesh) mesh.parent = this;
    }
  }

  public clear = (): void => {
    this.getChildren().forEach((child) => {
      child.parent = null;
      child.dispose();
    });
  };

  private getFeatureCollectionfromWaypoints(waypoints: WaypointLocation[]): FeatureCollection {
    let prevWaypoint: WaypointLocation;
    const features: Feature[] = waypoints.flatMap((waypoint, i) => {
      const result: Feature[] = [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [waypoint.pos.x, waypoint.pos.y, waypoint.pos.z ?? 0],
          },
          properties: {
            name: i,
          },
        },
      ];

      if (prevWaypoint) {
        result.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [prevWaypoint.pos.x, prevWaypoint.pos.y, prevWaypoint.pos.z ?? 0],
              [waypoint.pos.x, waypoint.pos.y, waypoint.pos.z ?? 0],
            ],
          },
          properties: {
            type: 'ARROW',
            name: `${i - 1}-${i}`,
          },
        });
      }
      prevWaypoint = waypoint;
      return result;
    });

    return {
      type: 'FeatureCollection',
      features,
    };
  }
}
