import type { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import type { AfterViewInit, ComponentRef, OnChanges, OnInit } from '@angular/core';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import type { OverlayRef } from '@angular/cdk/overlay';
import { NoopScrollStrategy, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import type { IButtonGroupButton, IButtonGroupButtonWithStatus } from '../../interfaces';
import { TButtonGroupButtonLayout } from '../../interfaces';
import type { CommandV2, GamepadControl, GamepadPayloadContext, GamepadUxComponent } from '../../models';
import { ConfigGroupItem } from '../../models';
import { GamepadService, RobotControlService, RobotDefinitionService, ToastService } from '../../services';
import { WidgetButtonGroupParamsComponent } from '../../components/widget-button-group-params';
import { ButtonGroupColour } from '../../enums';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-button-group',
  templateUrl: './widget-button-group.component.html',
  styleUrls: ['./widget-button-group.component.scss'],
})
export class WidgetButtonGroupComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChildren('buttonRef') viewButtons: QueryList<MatButton>;

  @Input() public projectId: string;
  @Input() public callsign: string;
  @Input() public buttonLayout: TButtonGroupButtonLayout = 'vertical';
  @Input() public buttons: IButtonGroupButton[] = [];

  public buttonsWithStatus: IButtonGroupButtonWithStatus[];

  private componentItems: ConfigGroupItem<GamepadUxComponent>[] = [];
  private controlItems: ConfigGroupItem<GamepadControl>[] = [];
  private triggerItems: ConfigGroupItem<unknown>[] = [];
  private commandV2Items: ConfigGroupItem<CommandV2>[];

  public constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay,
    private robotDefinitionService: RobotDefinitionService,
    private gamepadService: GamepadService,
    private robotControlService: RobotControlService,
    private toast: ToastService,
  ) {
    super();
  }

  public ngOnInit(): void {
    forkJoin([
      this.robotDefinitionService.getControlsForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getButtonsForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getTriggersForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getCommandsForRobotV2(this.projectId, this.callsign),
    ])
      .pipe(first())
      .subscribe(([controls, components, triggers, commandsV2]) => {
        if (controls?.items) {
          this.controlItems = controls.items;
        } else {
          this.controlItems = [];
        }

        if (components?.items) {
          this.componentItems = components.items;
        } else {
          this.componentItems = [];
        }

        if (triggers?.items) {
          this.triggerItems = triggers.items;
        } else {
          this.triggerItems = [];
        }

        if (commandsV2?.items) {
          this.commandV2Items = commandsV2.items;
        } else {
          this.commandV2Items = [];
        }
      });
  }

  public ngOnChanges(): void {
    this.updateButtonsWithStatus();
  }

  public ngAfterViewInit(): void {
    this.updateButtonOverflowing();
  }

  public isButtonNameOverflowing(ref: MatButton): boolean {
    const nativeElement = ref._elementRef.nativeElement;
    const computedStyle = window.getComputedStyle(nativeElement, null);
    const totalPaddingX = parseInt(computedStyle.paddingLeft, 10) + parseInt(computedStyle.paddingRight, 10);
    return (
      nativeElement.clientWidth - totalPaddingX < nativeElement.querySelector('span.mat-button-wrapper').offsetWidth
    );
  }

  public press(value: IButtonGroupButton, $event: MouseEvent): void {
    $event.preventDefault();
    const clickedComponent = this.componentItems.find((item) => item.value.id === value.id);

    let target = $event.target as HTMLElement;
    target = target.tagName === 'BUTTON' ? target : target.parentElement;

    const commandParams = clickedComponent?.value?.commandParamsAsObject || {};

    // filter out parameters that do not have input box enabled
    const filteredParams = Object.fromEntries(
      Object.entries(commandParams).filter(
        (i) => clickedComponent?.value?.commandParams.find((j) => j.id === `${i[0]}_showInputBox`).value,
      ),
    ) as Record<string, string | number>;

    if (Object.keys(filteredParams).length > 0) {
      const overlay = this.createOverlay(target);
      const paramsComponent = new ComponentPortal(WidgetButtonGroupParamsComponent);

      const paramsComponentRef: ComponentRef<WidgetButtonGroupParamsComponent> = overlay.attach(paramsComponent);
      paramsComponentRef.instance.parameters = filteredParams;
      paramsComponentRef.instance.buttonColour =
        value.colour !== ButtonGroupColour.slate ? value.colour : ButtonGroupColour.blue;
      paramsComponentRef.instance.commandCallback = (params) => {
        clickedComponent.value.commandParams = clickedComponent.value.commandParams.map((item) => {
          if (params?.[item.id] !== undefined) {
            item.value = params?.[item.id];
          }
          return item;
        });
        this.sendCommand(clickedComponent);
      };
      paramsComponentRef.instance.closeCallback = () => {
        overlay.detach();
      };
    } else if (clickedComponent) {
      this.sendCommand(clickedComponent);
    }
  }

  private updateButtonsWithStatus(): void {
    this.buttonsWithStatus = this.buttons.map((button) => {
      return {
        ...button,
        status: 'resting',
        overflowing: false,
      };
    });
  }

  private updateButtonOverflowing(): void {
    //  Do this next tick
    setTimeout(() => {
      const viewButtonArr = this.viewButtons.toArray();
      this.buttonsWithStatus = this.buttonsWithStatus.map((button, index) => {
        return {
          ...button,
          overflowing: this.isButtonNameOverflowing(viewButtonArr[index]),
        };
      });
    });
  }

  private createOverlay(elementRef: Element): OverlayRef {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        panelClass: 'arrow-bottom',
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: 'arrow-top',
      },
    ]);

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: new NoopScrollStrategy(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());

    return overlayRef;
  }

  private getButton(id: string): IButtonGroupButtonWithStatus {
    return this.buttonsWithStatus.find((btn) => btn.id === id);
  }

  private sendCommand(item: ConfigGroupItem<GamepadUxComponent>): void {
    const btn = this.getButton(item.value.id);
    if (btn.status === 'running') {
      return;
    }

    btn.status = 'running';

    const component = item.value;
    if (component.commandVersion === 'controls' || component.commandVersion === 'commands') {
      const controls = ConfigGroupItem.getValues<GamepadControl>(this.controlItems);
      const triggers = ConfigGroupItem.getValues<unknown>(this.triggerItems);
      const context = {
        callsign: this.callsign,
      } as GamepadPayloadContext;

      const payloads = this.gamepadService.getPayloadsFromComponentEvent(
        component,
        'click',
        controls,
        triggers,
        context,
      );

      this.robotControlService
        .sendMessagesByPayloadsWithAck(payloads, this.projectId, this.callsign)
        .pipe(first())
        .subscribe(
          (msg) => {
            btn.status = 'resting';
            this.toast.short(`Success! ${msg}`, null, 'success');
          },
          (err) => {
            btn.status = 'resting';
            this.toast.short(`Failed. ${err.message}`, null, 'failure');
          },
        );
    } else if (component.commandVersion === 'commands-v2') {
      const commandId = component.commandName;
      const commandParams = component.commandParamsAsObject;
      const commandV2 = this.commandV2Items.find((x) => x.value.id === commandId)?.value;

      if (!commandV2) {
        this.toast.short(`Failed. Command not found`, null, 'failure');
        return;
      }
      const timeoutMs = parseInt(commandV2.settings?.['timeoutMs'], 10) || undefined;
      this.robotControlService
        .sendCommandV2WithAck(this.projectId, this.callsign, commandId, commandParams, timeoutMs)
        .pipe(first())
        .subscribe(
          (msg) => {
            btn.status = 'resting';
            this.toast.short(`Success! ${msg}`, null, 'success');
          },
          (err) => {
            btn.status = 'resting';
            this.toast.short(`Failed. ${err.message}`, null, 'failure');
          },
        );
    }
  }
}
