<div class="rocos-dark-theme sidebar" [ngClass]="'status-' + sidebarStatus">
  <div class="customer-logo" *ngIf="customerLogoUrl">
    <img [src]="customerLogoUrl" />
  </div>

  <div class="group pin-group" *ngIf="sidebar.pinGroup">
    <ng-container *ngTemplateOutlet="itemsTemplate; context: { items: sidebar.pinGroup.items, linkExact: true }">
    </ng-container>
  </div>

  <div class="groups">
    <div>
      <ng-container *ngFor="let group of sidebar.groups">
        <div class="group" *appExperimental="group.experimentalFeature; projectId: projectId">
          <div class="title" *ngIf="group.title">
            {{ group.title }}
          </div>

          <ng-container *ngTemplateOutlet="itemsTemplate; context: { items: group.items, linkExact: false }">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="bottom">
    <div class="bottom-content">
      <div class="sidebar-toggle">
        <button mat-icon-button (click)="onMoveLeftButtonClick()" *ngIf="sidebarStatus === 'full'">
          <span class="ri-move-left"></span>
        </button>
        <button mat-icon-button (click)="onMoveRightButtonClick()" *ngIf="sidebarStatus !== 'full'">
          <span class="ri-move-right"></span>
        </button>
      </div>

      <!-- Application version -->
      <div class="version" [matTooltip]="'Version ' + version" (dblclick)="toggleLoggingVisibility()">
        V{{ version }}
      </div>
    </div>
  </div>
</div>

<!-- Application Monitor -->
<app-widget-apm #apm *ngIf="showLogging"></app-widget-apm>

<ng-template #itemsTemplate let-items="items" let-linkExact="linkExact">
  <mat-nav-list>
    <ng-container *ngFor="let item of items">
      <div *appFeatureFlag="item.featureFlag">
        <a
          mat-list-item
          class="item"
          [routerLink]="item.url"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: linkExact }"
          [matTooltip]="item.label"
          *appExperimental="item.experimentalFeature; projectId: projectId"
          matTooltipPosition="right"
          [matTooltipDisabled]="sidebarStatus !== 'collapsed'"
        >
          <div matLine class="item-content">
            <div class="d-flex align-items-center">
              <span class="icon" [ngClass]="item.icon"></span>
              <span class="label">{{ item.label }}</span>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </mat-nav-list>
</ng-template>
