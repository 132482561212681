import { AppInjectorService } from './../../../../core/shared/services/app-injector.service';
import { FullscreenService } from './../../../services/fullscreen/fullscreen.service';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WidgetType, WidgetStatus, Widget } from '../../../models';
import type { BehaviorSubject } from 'rxjs';
import type { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

export class WidgetMenuItem {
  public value: any;
  public text: string;
  public cssClass?: string;
}

export type WidgetBaseComponentTheme = 'none' | 'light' | 'dark';
export type WidgetToolbarItemTheme = 'primary' | 'dark' | 'light' | 'grey' | 'success' | 'none';
export type WidgetToolbarItemPosition = 'right' | 'left';
export type WidgetToolbarItemStyle = 'button' | 'link' | 'plain';

export class WidgetToolbarItem {
  public text: string = '';
  public icon?: string;
  public theme?: WidgetToolbarItemTheme = 'light';
  public tooltip?: string = '';
  public type?: string;
  public link?: any[] = null;
  public position?: WidgetToolbarItemPosition = 'left';
  public style?: WidgetToolbarItemStyle = 'plain';
  public buttonClass?: string;
  public asyncText?: BehaviorSubject<any>;

  public constructor(
    text: string,
    icon: string = '',
    theme: WidgetToolbarItemTheme = 'light',
    tooltip: string = '',
    type: string = '',
    link: any[] = null,
    position: WidgetToolbarItemPosition = 'left',
    style: WidgetToolbarItemStyle = 'plain',
    buttonClass: string = '',
    asyncText: BehaviorSubject<any> = null,
  ) {
    this.text = text;
    this.icon = icon;
    this.theme = theme;
    this.tooltip = tooltip;
    this.type = type;
    this.link = link;
    this.position = position;
    this.style = style;
    this.buttonClass = buttonClass;
    this.asyncText = asyncText;
  }
}

@Component({
  selector: 'app-widget-base',
  templateUrl: './widget-base.component.html',
  styleUrls: ['../styles.scss', './widget-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBaseComponent {
  @Input() public heading: string;
  @Input() public floorHeading: boolean = false;
  @Input() public dataURI: string;
  @Input() public dataName: string;
  @Input() public unit: string;
  @Input() public theme: WidgetBaseComponentTheme = 'dark';
  @Input() public editing: boolean;
  @Input() public valueExpression: string; // Eval expression for the value
  @Input() public decimalPlaces: number;
  @Input() public type: WidgetType;
  @Input() public status: WidgetStatus;
  @Input() public width: string = '100%';
  @Input() public height: string = '100%';
  @Input() public isGroupWidget: boolean = false;
  @Input() public menuItems: WidgetMenuItem[] = [];
  @Input() public toolbarItems: WidgetToolbarItem[];
  @Input() public largerWidth: boolean = false; // Larger size?
  // Set widget to be in foreground by default
  @Input() public layerIndex: number = 0;
  /** On operation page, if a widget is switchable, then it can be placed inside the central window */
  @Input() public viewSwitchable: boolean = false;
  @Input() public widget: Widget;

  @Output() public deleted = new EventEmitter();
  @Output() public editClicked = new EventEmitter();
  @Output() public backgroundLayerChanged = new EventEmitter<MatRadioChange>();
  @Output() public menuItemClick = new EventEmitter<WidgetMenuItem>();
  @Output() public toolbarItemClick = new EventEmitter<WidgetToolbarItem>();

  @ViewChild('widgetContainer') public widgetContainer: ElementRef;

  public smallEditButtons: boolean = false;

  private _fsService: FullscreenService = null;

  public get numberFormat(): string {
    const places = this.decimalPlaces ? this.decimalPlaces : 0;
    return `1.${places}-${places}`;
  }

  public constructor() {
    if (AppInjectorService.injector) {
      // Manual load avoid children dependencies dec
      this._fsService = AppInjectorService.injector.get(FullscreenService);
    } else {
      console.error('Fullscreen Service dependency not found');
    }
  }

  public onBackgroundLayerChanged($event): void {
    this.backgroundLayerChanged.emit($event);
  }

  public onDelete(): void {
    this.deleted.emit();
  }

  public onEdit(): void {
    this.editClicked.emit();
  }

  public onMenuItemClick(item: WidgetMenuItem): void {
    this.menuItemClick.next(item);
  }

  public onToolbarItemClick(item: WidgetToolbarItem): void {
    this.toolbarItemClick.next(item);
  }

  public requestFullScreen(): void {
    if (this._fsService) {
      this._fsService.triggerFullscreen(this.widgetContainer);
    }
  }

  public exitFullScreen(): void {
    if (this._fsService) {
      this._fsService.exitFullscreen();
    }
  }

  public onWidgetResized(): void {
    const offsetWidth = this.widgetContainer.nativeElement.offsetWidth;
    this.smallEditButtons = offsetWidth < 64;
  }
}
