import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from '@core/shared';
import type { ResponseMessages } from '@shared/interfaces';
import { GotoService } from '@shared/services';

export enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}
@Component({
  selector: 'app-core-hosting',
  templateUrl: './core-hosting.component.html',
  styleUrls: ['./core-hosting.component.scss'],
})
export class CoreHostingComponent implements OnInit, ResponseMessages {
  public errorMsg: string;
  public successMsg: string;
  public message: string;
  public showPrevBtn = false;
  public wider = false;
  public messageType: MessageType | null = MessageType.SUCCESS;
  public reserveMessage = false;

  public inviteFrom: string;
  public inviteCode: string;
  public showInviteMessages: boolean = false;
  public showCheck: boolean = false;
  public inviteType: string | 'project' | 'account' = 'project';

  constructor(
    private authStateService: AuthStateService,
    private goto: GotoService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['from']) {
        this.inviteFrom = queryParams['from'];
      }
      if (queryParams['inviteCode']) {
        this.inviteCode = queryParams['inviteCode'];
      }
      if (queryParams['type']) {
        this.inviteType = `${queryParams['type']}`.toLowerCase();
      }

      this.updateUIBasedOnURL();
    });

    this.authStateService.errorMsg.subscribe((msg) => {
      this.message = msg;
      this.messageType = MessageType.ERROR;
    });
    this.authStateService.successMsg.subscribe((msg) => {
      this.message = msg;
      this.messageType = MessageType.SUCCESS;
      if (this.message === 'Your password has been reset successfully.') {
        this.showCheck = true;
      }
    });
    this.authStateService.warningMsg.subscribe((msg) => {
      this.message = msg;
      this.messageType = MessageType.WARNING;
    });
    this.authStateService.reserveMessage.subscribe((val) => {
      this.reserveMessage = val;
    });
  }

  ngOnInit() {
    // Change the showPrevBtn and wider based on routes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.reserveMessage) {
          this.message = '';
          this.messageType = null;
        }

        this.reserveMessage = false;
        this.updateUIBasedOnURL();
      }
    });
  }

  onGoSignIn() {
    this.goto.login();
  }

  private updateUIBasedOnURL() {
    const url = this.router.url;
    this.showInviteMessages = url.startsWith('/login') || url.startsWith('/signup');

    this.wider = url.startsWith('/signup') || url.startsWith('/no-account');

    this.showPrevBtn = url.startsWith('/reset');
  }
}
