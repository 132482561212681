import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper-button',
  templateUrl: './stepper-button.component.html',
  styleUrls: ['./stepper-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperButtonComponent {
  @Input() selected: boolean;

  @Output() public clicked = new EventEmitter<void>();
}
