import type { EvalResponse } from '../../../../utils';
import { ExpressionEval } from '../../../../utils';

export class VizBinding {
  public key: string; // e.g. yurtle.position.x
  public meshId: string;
  public propertyId: string;
  public value: unknown;
  public valueExpression: string;
  public boundSourceKey: string;
  public rendererId: string; // unique id the renderer uses to store the object
  public defaultValue: string;
  public disabled: boolean;

  /** key: object.property.subproperty e.g. "robot1.location.x"
   *  Can also traverse arrays e.g. "robot1.position[3]"
   */

  /**
   * @param bindingKey e.g. 'robo1.position.x'
   * @param boundSourceKey e.g. '/robo1/rosbridge/pose', if null then not bound to dataSource
   * @param valueExpression e.g. '$msg.x', must contain $msg at least once
   * @param defaultValue
   * @param disabled
   */
  public constructor(
    bindingKey: string,
    boundSourceKey: string,
    valueExpression: string,
    defaultValue: string,
    disabled?: boolean,
  ) {
    this.key = bindingKey;
    this.meshId = bindingKey.substr(0, bindingKey.indexOf('.'));
    this.propertyId = bindingKey.substring(this.meshId.length + 1);
    this.rendererId = 'rocos-' + this.meshId;
    this.boundSourceKey = boundSourceKey;
    this.defaultValue = defaultValue;
    this.disabled = disabled || false;
    if (valueExpression === null) {
      this.valueExpression = '$object';
    } else {
      this.valueExpression = valueExpression;
    }
  }

  /** Calculate the value that is to be assigned to the bound binding key
   *  based on the valueExpression and the message payload
   *  (e.g. what will be assigned to robo1.position.x if valueExpress is '$msg.x / 100')
   * */
  public getBoundValue(messagePayload: unknown): EvalResponse {
    return ExpressionEval.evaluateWithResponse(this.valueExpression, {
      '$object': messagePayload,
      '$msg': messagePayload,
    });
  }
}
