import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetMapComponent } from './widget-map';

@NgModule({
  imports: [CommonModule],
  declarations: [WidgetMapComponent],
  exports: [WidgetMapComponent],
})
export class WidgetMapModule {}
