import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { GamepadModule } from '@shared/gamepad';
import { GamepadGuiIndicatorComponent } from './gamepad-widget/gamepad-gui-indicator/gamepad-gui-indicator.component';
import { GamepadBoxIndicatorComponent } from './gamepad-widget/gamepad-box-indicator/gamepad-box-indicator.component';
import { MaterialModule } from '@shared/material';
import { GamepadWidgetComponent } from './gamepad-widget/gamepad-widget.component';

@NgModule({
  declarations: [GamepadWidgetComponent, GamepadGuiIndicatorComponent, GamepadBoxIndicatorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MaterialModule,
    // GamepadModule,
  ],
  exports: [GamepadWidgetComponent],
})
export class GamepadWidgetModule {}
