import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetBaseComponent } from './widget-base';
import { MaterialModule } from '../../material';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule, FormsModule, DirectivesModule],
  declarations: [WidgetBaseComponent],
  exports: [WidgetBaseComponent],
})
export class WidgetSharedModule {}
