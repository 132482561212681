import type { OnInit, OnDestroy, TrackByFunction } from '@angular/core';
import {
  Component,
  Input,
  NgZone,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { TextLayer, IconLayer, PathLayer } from '@deck.gl/layers';
import type { VizBinding } from '../../../services/threeD/primitives/visualizer';
import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import type { WidgetMapComponent } from '../../widget-map';
import { MAPTHEMES } from '../../widget-map';
import type { SubscribeResult } from '../../../services';
import { CodeEvalService } from '../../../services';
import { RocosClientService, RobotService, OperationService, GlobalMapService } from '../../../services';
import { first } from 'rxjs/operators';
import type { CodeEvalEnvironment, Robot, WidgetJavascript } from '../../../models';
import { GlobalOperationPageConfig } from '../../../models';
import { Utils } from '../../../utils';
import { MapboxLayersService } from '@shared/services/mapbox-layers/mapbox-layers.service';

type Layer = TextLayer | IconLayer | PathLayer;

@Component({
  selector: 'app-widget-operation-global',
  templateUrl: './widget-operation-global.component.html',
  styleUrls: ['./widget-operation-global.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetOperationGlobalComponent implements OnInit, OnDestroy {
  @Input()
  public callsign: string;
  @Input()
  public pageId: string;
  @Input()
  public projectId: string;

  @ViewChildren('map')
  public maps: QueryList<WidgetMapComponent>;

  public get map(): WidgetMapComponent {
    return this.maps?.first;
  }

  public mapStyle: MAPTHEMES = MAPTHEMES.SATELLITE;
  public startZoom: number = 1;
  public startLocation: [number, number];
  public configLoaded: boolean = false;
  public isFollowingBot: boolean = true;

  public callsigns: string[] = [];
  public robots: Robot[] = [];
  public jsWidgets: WidgetJavascript[] = [];
  public operationPageContext: any;
  public mapLoaded$ = new Subject<boolean>();

  private codeEvalEnvironment: CodeEvalEnvironment;
  private layers: Layer[] = [];
  private robotTrailColor1 = [12, 141, 255, 150];
  private robotTrailLength: number = 300;
  private recordHistoryInterval;
  private renderRobotMoveInterval;
  private waypointsLayers: Layer[] = [];
  private subscriptions: SubscribeResult[] = [];
  private dashboardRobot: Robot;

  private pageConfig: GlobalOperationPageConfig = new GlobalOperationPageConfig();
  private receivedBoundMessageSubscription: Subscription;

  public constructor(
    private rocosClientService: RocosClientService,
    private robotService: RobotService,
    private operationService: OperationService,
    private globalMapService: GlobalMapService,
    private ngZone: NgZone,
    private mapboxLayers: MapboxLayersService,
    private codeEvalService: CodeEvalService,
    private cdr: ChangeDetectorRef,
  ) {}

  public trackByFn: TrackByFunction<WidgetJavascript> = (_, item) => item.id;

  public ngOnInit(): void {
    this.globalMapService.setProjectId(this.projectId);
    this.reloadOperationPageInfo();
    this.listenBoundMessages();
  }

  public resizeComponent(): void {
    this.map.resizeMap();
  }

  public onMapInitialized(): void {
    this.mapLoaded$.next(true);
    this.initScripts();
    this.loadLayers();
    this.animateRobotMovements();
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => this.rocosClientService.unsubscribe(s));

    clearInterval(this.recordHistoryInterval);
    clearInterval(this.renderRobotMoveInterval);

    this.globalMapService.tryStopBoundSubscriptions();
    this.receivedBoundMessageSubscription?.unsubscribe();
  }

  public onMapDragged(): void {
    this.isFollowingBot = false;
  }

  public onViewUpdated(): void {
    if (!this.map.mapInTransition) {
      this.updateRobotPositionsDeckGL();
    }
  }

  public onFollowBotStateChanged(isFollowing: boolean): void {
    this.isFollowingBot = isFollowing;
  }

  /**
   * @description Zoom (fly) to the target robot.
   * @param robot Target robot
   * @param zoomLevel map zoom level
   */
  public zoomRobot(robot: Robot, zoomLevel?: number): void {
    this.ngZone.runOutsideAngular(() => {
      this.flyToRobot(robot, zoomLevel);
    });
  }

  private loadLayers(): void {
    this.mapboxLayers
      .addLatestOrthoLayer(this.map.map)
      .then(() => this.mapboxLayers.addLatestOverlayLayer(this.map.map))
      .catch((err) => {
        console.warn('Map layers unavailable');
        throw err;
      });
  }

  /**
   * @description Fly to target location
   * @param robot Robot
   * @param zoomLevel map zoom level
   */
  private flyToRobot(robot: Robot, zoomLevel?: number) {
    NgZone.assertNotInAngularZone();

    if (this.map && (robot.longitude || robot.longitude === 0) && (robot.latitude || robot.latitude === 0)) {
      this.map.flyTo(
        {
          longitude: robot.longitude,
          latitude: robot.latitude,
        },
        zoomLevel,
      );
    }
  }

  private reloadOperationPageInfo() {
    this.operationService.getOne(this.projectId, this.pageId).then((page) => {
      if (page?.payload) {
        const pageConfig = JSON.parse(page.payload);
        this.pageConfig = GlobalOperationPageConfig.fromModel(pageConfig);
        this.jsWidgets = pageConfig.panels?.jsPanel?.widgets ?? [];
        this.loadPageFromPageConfig();
      }

      this.configLoaded = true;
      this.cdr.markForCheck();
    });
  }

  private loadPageFromPageConfig() {
    // Load scene
    this.globalMapService.updateSceneJson(JSON.stringify(this.pageConfig));
    // Get and assign callsigns (robot IDs)
    this.callsigns = this.pageConfig.getCallsigns();
    this.onCallsignsChange(this.callsigns);

    this.startZoom = this.pageConfig.getMapStartZoom() ?? this.startZoom;
    this.startLocation = this.pageConfig.getMapStartLocation() ?? this.startLocation;
  }

  private robotsLoaded() {
    this.startRecording();

    // Subscribe and process data
    this.globalMapService.trySubscribeBasedOnDataSources();
  }

  private startRecording() {
    this.robots.forEach((r) => {
      r.recordCoordinates = true;
      r.coordinateHistoryMaxSize = this.robotTrailLength;
    });

    this.recordHistoryInterval = setInterval(() => {
      this.robots.forEach((robot) => {
        robot.addCoordinateToHistory();
      });
    }, 2000);
  }

  /**
   * Listen bound messages (Should only call once)
   */
  private listenBoundMessages() {
    this.receivedBoundMessageSubscription = this.globalMapService.receivedBoundMessage.subscribe(
      (message: VizBinding) => {
        const applyValueToRobot = (robot: Robot, propertyId: string, value: string | number) => {
          if (robot) {
            switch (propertyId) {
              case 'statusText': {
                let statusText = value;
                // Temp transfer for converting array to string
                if (Array.isArray(value) && value.length > 0) {
                  statusText = value
                    .map((num) => {
                      return String.fromCharCode(num);
                    })
                    .filter((ch) => ch !== '\u0000')
                    .join('');
                }

                this.ngZone.run(() => {
                  robot.setStatusTextWithTimeout(statusText as string, 5);
                });
                break;
              }
              default:
                if (Object.prototype.hasOwnProperty.call(robot, propertyId)) {
                  robot[propertyId] = value;
                }
                break;
            }
          }
        };

        if (message) {
          const objectId = message.meshId;
          const propertyId = message.propertyId;
          const value = message.value as string | number;

          // Check robots
          this.robots
            .filter((robot) => {
              return robot.callsign === objectId;
            })
            .forEach((robot) => {
              applyValueToRobot(robot, propertyId, value);
            });

          // Check others - TODO
        }
      },
    );
  }

  private onCallsignsChange(callsigns: string[]) {
    this.robotService
      .list$(this.projectId)
      .pipe(first())
      .subscribe((robots) => {
        const selectedRobots = [];

        robots.forEach((robot) => {
          if (callsigns.includes(robot.callsign)) {
            robot.selected = false;
            selectedRobots.push(robot);
          }
        });

        this.robots = selectedRobots;

        if (this.callsign) {
          this.dashboardRobot = this.robots.find((x) => x.callsign === this.callsign);
          if (this.dashboardRobot) {
            this.dashboardRobot.selected = true;
          }
          this.updateLayersAndViewState();
        }

        this.robotsLoaded();
      });
  }

  private animateRobotMovements() {
    if (this.renderRobotMoveInterval) {
      clearInterval(this.renderRobotMoveInterval);
    }

    this.renderRobotMoveInterval = setInterval(() => {
      this.updateLayersAndViewState();
    }, 500);
  }

  private updateLayersAndViewState() {
    if (this.map && !this.map.mapInTransition) {
      // add robot positions
      this.updateRobotPositionsDeckGL();

      // add any layers from the mapTracking widget
      if (this.layers && this.waypointsLayers?.length > 0) {
        this.layers.push(this.waypointsLayers);
      }

      this.map.updateLayers(this.layers);

      if (this.isFollowingBot && this.dashboardRobot) {
        this.zoomRobot(this.dashboardRobot, this.map.map.getZoom());
      }
    }
  }

  private updateRobotPositionsDeckGL() {
    this.layers = [];
    const iconLayerId = 'icon-layer-robots';
    const activeIconLayerId = 'active-icon-layer-robots';
    const iconShadowLayerId = 'icon-layer-robots-shadow';
    const textLayerId = 'text-layer-robots';
    const pathLayerId = 'path-layer-robots';
    const robotIconUrl = 'assets/images/map-widget/robot_icon.svg';
    const robotSelectedIconUrl = 'assets/images/map-widget/robot_icon_current.svg';
    const robotIconShadowUrl = 'assets/images/map-widget/robot_icon_shadow.svg';
    let labelFontColor = [255, 255, 255];

    // Change the icon and text color by theme color.
    if (this.mapStyle !== MAPTHEMES.SATELLITE) {
      labelFontColor = [0, 0, 0];
    }

    // -----------------------
    // All robots icon Layer
    const robotIconsLayer = new IconLayer({
      id: iconLayerId,
      data: this.robots.filter((r) => r.latitude != null && r.longitude != null && !r.selected),
      wrapLongitude: true, // moves map seam to be safer for NZ at far out zoom
      iconAtlas: robotIconUrl,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 48,
          height: 45,
          anchorX: 24,
          anchorY: 22,
          mask: false,
        },
      },
      sizeScale: 1,
      getPosition: (r) => [r.longitude, r.latitude, r.altitudeRTL ? r.altitudeRTL : 0.5],
      getIcon: () => 'marker',
      getSize: 48,
      getAngle: (r) => this.getHeading(r.heading), // TODO: incorporate map heading later if possible
      // getPixelOffset: [50, 50]
    });
    this.layers.push(robotIconsLayer);

    // -----------------------
    // Active robot icon layer (The circle around robot)
    const activeRobotIconsLayer = new IconLayer({
      id: activeIconLayerId,
      data: this.robots.filter((r) => r.latitude != null && r.longitude != null && r.selected),
      wrapLongitude: true, // moves map seam to be safer for NZ at far out zoom
      iconAtlas: robotSelectedIconUrl,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 48,
          height: 45,
          anchorX: 24,
          anchorY: 22,
          mask: false,
        },
      },
      sizeScale: 1,
      getPosition: (r) => [r.longitude, r.latitude, r.altitudeRTL ? r.altitudeRTL : 0.5],
      getIcon: () => 'marker',
      getSize: 48,
      getAngle: (r) => this.getHeading(r.heading), // TODO: incorporate map heading later if possible
    });
    this.layers.push(activeRobotIconsLayer);

    // -----------------------
    // Robot icons shadow layer
    const robotIconsShadowLayer = new IconLayer({
      id: iconShadowLayerId,
      data: this.robots.filter(
        (r) =>
          r.latitude !== undefined &&
          r.latitude !== null &&
          r.longitude !== undefined &&
          r.longitude !== null &&
          r.altitudeRTL !== undefined &&
          r.altitudeRTL !== null &&
          r.altitudeRTL >= 1, // drone must above ground 1m to display shadow
      ),
      wrapLongitude: true, // moves map seam to be safer for NZ at far out zoom
      iconAtlas: robotIconShadowUrl,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 48,
          height: 45,
          anchorX: 24,
          anchorY: 22,
          mask: false,
        },
      },
      sizeScale: 0.5,
      getPosition: (r) => [r.longitude, r.latitude, 0],
      getColor: [0, 0, 0, 85],
      getIcon: () => 'marker',
      getSize: (r) => Utils.calculateShadowSize(100, r.altitudeRTL),
      getAngle: (r) => this.getHeading(r.heading), // TODO: incorporate map heading later if possible
      // getPixelOffset: [50, 50]
    });
    this.layers.push(robotIconsShadowLayer);

    // -----------------------
    // Robot labels layer
    const robotLabelsLayer = new TextLayer({
      id: textLayerId,
      data: this.robots.filter((r) => r.latitude != null && r.longitude != null && r.altitudeRTL != null),
      wrapLongitude: true, // moves map seam to be safer for NZ at far out zoom
      // getPosition: r => [r.longitude, r.latitude, r.altitudeRTL],
      getPosition: (r) => [r.longitude, r.latitude],
      getText: (r) => r.callsign,
      getSize: 14,
      getAngle: 0,
      fontFamily: '"Roboto Mono", sans-serif',
      getColor: labelFontColor,
      getPixelOffset: (r) => [0, this.getLabelOffset(r.selected)],
    });
    this.layers.push(robotLabelsLayer);

    // -----------------------
    // Robot paths layer
    const robotPathsLayer = new PathLayer({
      id: pathLayerId,
      data: this.robots.filter((r) => r.coordinateHistoryLonLatAlt.length > 2),
      widthMinPixels: 1,
      getPath: (r) => r.coordinateHistoryLonLatAlt,
      getColor: () => this.robotTrailColor1,
      getWidth: () => 1,
    });
    this.layers.push(robotPathsLayer);
  }

  private getHeading(robHeading: number, iconRotationOffset: number = 0): number {
    let heading: number;
    if (this.map.currentViewState && robHeading) {
      heading = 360 - robHeading + this.map.currentViewState.bearing + iconRotationOffset;
    } else {
      heading = 360 - robHeading + iconRotationOffset;
    }
    return heading;
  }

  private getLabelOffset(robotIsSelected): number {
    if (robotIsSelected) {
      return 60;
    } else {
      return 35;
    }
  }

  private initScripts() {
    this.codeEvalEnvironment = this.codeEvalService.createNewEnvironment();

    const mapContextObject = {
      mapbox: this.map.map,
      mapboxHelper: this.map.mapHelper,
      deck: this.map.deck,

      // Override the toJSON function for map context object to prevent un-necessary serialization
      toJSON: () => '',
    };

    this.codeEvalEnvironment.addToContext('map', mapContextObject);
    this.codeEvalEnvironment.addToContext('activeRobot', this.callsign);
    this.codeEvalEnvironment.addToContext('callsigns', this.callsigns);
    this.codeEvalEnvironment.addToContext('projectId', this.projectId);
    this.codeEvalEnvironment.addToContext('currentRobots', this.robots); // this.robots contains all selected robots.

    if (!this.operationPageContext) {
      this.operationPageContext = {};
    }

    this.operationPageContext['codeEvalEnvironment'] = this.codeEvalEnvironment;

    this.cdr.markForCheck();
  }
}
