import type { RobotTemplate, RobotConfig } from '@team-rocos/rocos-js';
import { StreamPlugin } from '../stream';

export class RobotDefinition {
  type: string;
  title: string;
  description?: string;
  plugins: StreamPlugin[];
  configs?: RobotConfig[];
  metaData?: any;
  defaultRobotDefinition?: string;
  isExperimental?: boolean = false;
  isCloud?: boolean = false;

  public static fromRobotTemplate(template: RobotTemplate): RobotDefinition {
    const def = new RobotDefinition();

    def.type = template.id;
    def.title = template.name;
    def.description = template.description;

    def.plugins = template.plugins.map((plugin) => {
      return StreamPlugin.fromModel(plugin);
    });

    def.configs = template.parameters;

    def.metaData = template.metaData;

    if (template.metaData?.defaultRobotDefinition) {
      def.defaultRobotDefinition = template.metaData.defaultRobotDefinition;
    }

    if (template.metaData?.isExperimental) {
      def.isExperimental = template.metaData.isExperimental;
    }

    if (template.metaData?.isCloud) {
      def.isCloud = template.metaData.isCloud;
    }

    return def;
  }

  public get iconName(): string {
    let name = 'ri-ig-robots';
    switch (this.type) {
      case 'v-turtlebot-deployment-v1': // Will be deleted soon
      case 'v-turtlebot-deployment-v2':
        name = 'ri-ig-turtlebot';
        break;
      case 'v-dronekit-deployment-v1':
      case 'v-dronekit-mavros-deployment-v1':
      case 'v-arducopter-deployment-v1':
      case 'v-arducopter-deployment-external-IP-v1':
        name = 'ri-ig-drone';
        break;
      case 'v-rover-deployment-v1':
      case 'v-rover-deployment-external-IP-v1':
        name = 'ri-ig-rover';
        break;
    }

    return name;
  }
}
