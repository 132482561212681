import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, NgZone } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { BaseEditorComponent } from './base-editor';
import { NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from './config';
import { editor } from 'monaco-editor';
import { EditorOptions } from './types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngx-monaco-editor',
  template: '<div class="editor-container" #editorContainer></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        height: 200px;
      }

      .editor-container {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent extends BaseEditorComponent implements ControlValueAccessor {
  private _value: string = '';

  constructor(private zone: NgZone, @Inject(NGX_MONACO_EDITOR_CONFIG) private editorConfig: NgxMonacoEditorConfig) {
    super(editorConfig);
  }

  propagateChange = (_: any) => {
    // Required by ControlValueAccessor
  };
  onTouched = () => {
    // Required by ControlValueAccessor
  };
  @Input()
  set options(options: EditorOptions) {
    this._options = Object.assign({}, this.config.defaultOptions, options);
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options);
    }
  }

  get options(): EditorOptions {
    return this._options;
  }

  @Input()
  set model(model: editor.ITextModel) {
    this.options.model = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }

  writeValue(value: any): void {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.options.model) {
        this._editor.setValue(this._value);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected initMonaco(options: EditorOptions): void {
    let model: editor.ITextModel;

    const hasModel = !!options.model;
    if (hasModel) {
      model = this.monaco.editor.getModel(options.model.uri);
      if (model) {
        model.setValue(this._value);
      } else {
        model = this.monaco.editor.createModel(options.value, options.language, options.model.uri);
      }
    }

    const editorOptions: editor.IStandaloneEditorConstructionOptions = { ...options, model };
    this._editor = this.monaco.editor.create(this._editorContainer.nativeElement, editorOptions);

    if (!hasModel && !options.value) {
      this._editor.setValue(this._value);
    }

    this._editor.onDidChangeModelContent((_e: any) => {
      const value = this._editor.getValue();

      // value is not propagated to parent when executing outside zone.
      this.zone.run(() => {
        this.propagateChange(value);
        this._value = value;
      });
    });

    this._editor.onDidBlurEditorWidget(() => {
      this.onTouched();
    });

    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());

    if (options.jsonSchema?.schema) {
      this.setJSONSchema(options.jsonSchema);
    }

    this.onInit.emit(this._editor);
  }

  private get monaco() {
    return window.monaco;
  }

  private setJSONSchema(schemaSettings: EditorOptions['jsonSchema']) {
    const model = this._editor.getModel();
    const modelUri = model.uri.toString();

    this.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'inmemory://schema/1',
          fileMatch: [modelUri],
          schema: schemaSettings.schema,
        },
      ],
      ...schemaSettings.options,
    });
  }
}
