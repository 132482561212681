import type { WidgetType } from './widget-type';
import type { WidgetStatus } from './widget-status';
import type { IDataUriConfig } from '../common';
import { defaultGridsterOptions } from '@shared-modules/dashboard-v2/services';

export enum WidgetMinGridSize {
  small = 'small',
  min150 = 'min150',
  min300 = 'min300',
  min400 = 'min400',
  min500 = 'min500',
  min600 = 'min600',
}

export enum WidgetDefaultSize {
  cols = 10,
  rows = 12,
}

export interface IWidgetExternalContent<T> {
  type: string;
  content: T;
}

export class WidgetBase {
  public connectionType: string;
  public widgetType: WidgetType;
  public widgetHeading: string;
  public dataURI?: string;
  public dataUriConfig?: IDataUriConfig;
  public callsign?: string;
  public columnFilters?: string[];
  /**
   * Fast lane source
   */
  public source?: string;
  public units: string;
  public valueType?: string;
  public dataName: string;
  /**
   * Value Evaluator Expression.
   */
  public valueExpression?: string;
  public decimalPlaces: number = 0;
  /**
   * Widget Key (Id)
   */
  public key?: string;

  public status: WidgetStatus;
  public widgetTimeout: number = 5; // In seconds

  public id?: string;
  public index?: string;

  public testID?: string;

  public viewSwitchable: boolean = false;

  // Gridster settings
  public x?: number;
  public y?: number;
  public rows?: number = WidgetDefaultSize.rows * 2;
  public cols?: number = WidgetDefaultSize.cols * 3;
  public minItemCols?: number = WidgetDefaultSize.cols;
  public minItemRows?: number = WidgetDefaultSize.rows;
  public resizeEnabled?: boolean;
  public layerIndex?: number = 0;

  /** The min width or height of grid. */
  public gridMinSize: WidgetMinGridSize;

  /** The height of widget inside a grid. */
  public gridComponentHeight: number;
  /** The width of widget inside a grid. */
  public gridComponentWidth: number;

  public externalContent: IWidgetExternalContent<any>;
  public baseGridSize: { height: number; width: number; margin: number };

  public get widgetTheme(): 'light' | 'dark' {
    return this.connectionType === 'data-stream' ? 'light' : 'dark';
  }

  public readValuesFromModel(model: Partial<WidgetBase>): void {
    this.connectionType = model.connectionType;
    this.widgetType = model.widgetType;
    this.widgetHeading = model.widgetHeading;
    this.dataURI = model.dataURI;

    this.source = model.source;
    this.units = model.units;
    this.valueType = model.valueType;
    this.dataName = model.dataName;

    // Default value epxression as $msg.
    this.valueExpression = model.valueExpression ? model.valueExpression : '$msg';

    if (model.decimalPlaces !== undefined) {
      this.decimalPlaces = model.decimalPlaces;
    }
    if (model.widgetTimeout !== undefined) {
      this.widgetTimeout = model.widgetTimeout;
    }
    if (model.key !== undefined) {
      this.key = model.key;
    }

    if (model.id !== undefined && model.id !== null) {
      this.id = model.id;
    } else {
      this.id = crypto.randomUUID();
    }

    this.testID = model.testID;

    if (model.index !== undefined) {
      this.index = model.index;
    }
    if (model.viewSwitchable !== undefined) {
      this.viewSwitchable = model.viewSwitchable;
    }

    if (model.dataUriConfig) {
      this.dataUriConfig = model.dataUriConfig;
    } else {
      this.dataUriConfig = {
        dataURI: model.dataURI,
        dataUriConfigMode: 'basic',
        valueExpression: this.valueExpression,
      };
    }

    if (model.callsign) {
      this.callsign = model.callsign;
    }

    this.x = model.x;
    this.y = model.y;
    this.rows = model.rows ? model.rows : WidgetDefaultSize.rows * 2;
    this.cols = model.cols ? model.cols : WidgetDefaultSize.cols * 3;
    this.minItemCols = model.minItemCols ? model.minItemCols : WidgetDefaultSize.cols;
    this.minItemRows = model.minItemRows ? model.minItemRows : WidgetDefaultSize.rows;

    if (model.resizeEnabled === false) {
      // note, for backward compatible, resizeEnabled undefined is equal to true
      this.resizeEnabled = false;
    }

    if (!isNaN(model.layerIndex)) {
      this.layerIndex = model.layerIndex;
    }

    if (model.externalContent) {
      this.externalContent = model.externalContent;
    }

    this.baseGridSize = {
      height: defaultGridsterOptions.fixedRowHeight,
      width: defaultGridsterOptions.fixedColWidth,
      margin: defaultGridsterOptions.margin,
    };
  }
}
