export type InvitationType = 'PROJECT' | 'SIGNUP' | 'ACCOUNT' | 'AGENTINSTALL';

export class Invitation {
  public email: string;
  public codeChallenge?: string;
  public type: InvitationType;
  public ids: string[] = [];

  constructor(email: string, type: InvitationType, ids?: string[]) {
    this.email = email;
    this.type = type;
    this.ids = ids;
  }
}
