<div class="panel">
  <div
    class="heading"
    [ngClass]="[indentTitle ? 'indent' : '', expandable ? 'expandable' : ''].join(' ')"
    (click)="onClickHeading()"
  >
    <div class="title">
      {{ panelTitle }}
    </div>
    <div class="indicator" *ngIf="expandable">
      <ng-container *ngIf="expanded">
        <span class="ri-chevron-down"></span>
      </ng-container>
      <ng-container *ngIf="!expanded">
        <span class="ri-chevron-left"></span>
      </ng-container>
    </div>
  </div>
  <ng-content *ngIf="expanded" select="[slot=heading]"></ng-content>
  <div class="content" *ngIf="expanded">
    <div class="content-inner">
      <ng-content></ng-content>
    </div>
  </div>
</div>
