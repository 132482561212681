import { parse as datemathParse } from 'datemath-parser';
import type { WidgetTimeInterval } from '../widget';

export interface IStreamDateFilterConfig {
  name: string;
  text: string;
  start: string;
  interval: string;
  widgetTimeInterval: WidgetTimeInterval;
  refreshDelay?: number;
}

export class StreamDateFilterConfig implements IStreamDateFilterConfig {
  name: string;
  text: string;
  start: string;
  interval: string;
  widgetTimeInterval: WidgetTimeInterval;
  /**
   * In seconds
   */
  refreshDelay?: number;

  /**
   * Time range from start to now. In seconds.
   */
  get timeRange(): number {
    const startDate = new Date(datemathParse(this.start));
    const endDate = new Date(datemathParse('now'));

    return (endDate.getTime() - startDate.getTime()) / 1000;
  }

  static fromModel(model: IStreamDateFilterConfig): StreamDateFilterConfig {
    const config = new StreamDateFilterConfig();

    config.name = model.name;
    config.text = model.text;
    config.start = model.start;
    config.interval = model.interval;
    config.widgetTimeInterval = model.widgetTimeInterval;
    config.refreshDelay = model.refreshDelay;

    return config;
  }
}
