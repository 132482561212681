import type { EmbeddedViewRef, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';
import type { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppService, DevelopmentService, ProjectPermissionService, UserService } from '../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appExperimental]',
})
export class ExperimentalDirective implements OnChanges {
  @Input()
  public appExperimental: string | boolean | string[];
  @Input()
  public appExperimentalProjectId?: string;

  public highlight = false;
  public hideExperimentalContent = false;
  public hideAdminOnlyContent = false;
  public embeddedViewRef: EmbeddedViewRef<any>;

  private hasView = false;

  private get borderColor(): string {
    return 'GoldenRod';
  }

  private initialised = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private developmentService: DevelopmentService,
    private userService: UserService,
    private appService: AppService,
    private projectPermissionService: ProjectPermissionService,
  ) {
    this.developmentService.hideExperimentalContent.pipe(untilDestroyed(this)).subscribe((hide) => {
      this.hideExperimentalContent = hide;

      this.statusUpdated();
    });

    this.developmentService.hideAdminContent.pipe(untilDestroyed(this)).subscribe((hide) => {
      this.hideAdminOnlyContent = hide;

      this.statusUpdated();
    });

    this.developmentService.highlightMode.pipe(untilDestroyed(this)).subscribe((highlight) => {
      this.highlight = highlight;

      this.statusUpdated();
    });

    this.appService.projectIdChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.statusUpdated();
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['appExperimental'] || changes['appExperimentalProjectId']) {
      this.statusUpdated();
    }
  }

  /**
   * Callback when development status updated
   */
  private statusUpdated() {
    if (!this.involved) {
      if (!this.hasView) {
        this.updateView(true);
      }
      return;
    }

    if (!this.initialised) {
      this.initialised = true;

      const showContent = this.getShowContentValueBasedOnPermission(false);
      if (!showContent) {
        this.updateView(false);
      }
    }

    this.hasPermission(this.appExperimental)
      .pipe(first())
      .subscribe((permission) => {
        const showContent = this.getShowContentValueBasedOnPermission(permission);
        this.updateView(showContent, permission);
      });
  }

  private getShowContentValueBasedOnPermission(permission: boolean): boolean {
    if (!this.involved) {
      return true;
    }

    // Production
    if (this.isProd) {
      return permission || (this.isAdmin && !this.hideAdminOnlyContent);
    }

    // Dev
    if (!this.hideExperimentalContent) {
      return true;
    }

    return !this.hideAdminOnlyContent || permission;
  }

  private updateView(showContent: boolean, permission: boolean = false) {
    // Listen the development content hide or show event
    if (showContent && !this.hasView) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!showContent && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    const isHighlight = this.involved && !this.isProd && this.highlight;
    // Listen the highlight mode event
    if (this.hasView && this.embeddedViewRef?.rootNodes) {
      const firstNode = this.embeddedViewRef.rootNodes[0];
      if (firstNode?.style !== undefined) {
        if (isHighlight) {
          const borderStyle = this.getBorderStyle(permission);
          firstNode.style.border = `1px ${borderStyle} ${this.borderColor}`;
        } else {
          firstNode.style.border = 'none';
        }
      }
    }
  }

  private get isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  private get isProd(): boolean {
    return environment.production;
  }

  private get involved(): boolean {
    return !!this.appExperimental;
  }

  private hasPermission(feature: string | string[] | boolean): Observable<boolean> {
    let featureInCheck: string | string[];
    if (Array.isArray(feature)) {
      featureInCheck = feature;
    } else {
      featureInCheck = `${feature}`;
    }

    return this.projectPermissionService.checkPermission(
      this.appExperimentalProjectId ?? this.appService.projectId,
      featureInCheck,
    );
  }

  private getBorderStyle(hasPermission: boolean): string {
    return this.hideExperimentalContent ? (hasPermission ? 'dotted' : 'dashed') : 'solid';
  }
}
