import type { ISimpleBinding } from './interface/ISimpleBinding';

export class SimpleBinding implements ISimpleBinding {
  name: string;
  source: string;
  valueExpression: string;

  constructor(name: string, source: string, valueExpression: string) {
    this.name = name;
    this.source = source;
    this.valueExpression = valueExpression;
  }

  static fromJSON(json: any): SimpleBinding {
    if (json) {
      return new SimpleBinding(json.name, json.source, json.valueExpression);
    }
    return null;
  }
}

export class SimpleBindings {
  [name: string]: SimpleBinding;

  static fromJSON(json: any): SimpleBindings {
    if (json) {
      const keys = Object.keys(json);

      if (keys?.length > 0) {
        const bindings: SimpleBindings = {};
        keys.forEach((key) => {
          const binding = SimpleBinding.fromJSON(json[key]);

          if (binding) {
            bindings[key] = binding;
          }
        });

        return bindings;
      }
    }

    return null;
  }
}
