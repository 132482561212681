import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

const maxLetters = 30;

@Pipe({
  name: 'commandParameters',
})
export class CommandParametersPipe implements PipeTransform {
  transform(parameters: { [key: string]: any }): string {
    const params = Object.keys(parameters);
    if (params.length === 0) return '';

    const joined = params.join(', ');
    if (joined.length <= maxLetters) return joined;

    const include = [];
    while (include.join(', ').length <= maxLetters && params.length) include.push(params.shift());

    if (include.join(', ').length > maxLetters) {
      params.unshift(include.pop());
      return `${include.join(', ')}...+${params.length}`;
    }

    return include.join(', ');
  }
}
