// Ref: https://github.com/pgilad/unquoted-property-validator

import unquotedValidator from 'unquoted-property-validator';

export interface UnquotedPropertyValidationResult {
  /**
   * @example
   * var obj = {
        nonQuoted: true,
        'must-be-quoted' : true
    };
   */
  needsQuotes: boolean;
  /**
   * @example
   * obj.bracketFree = true;
     obj['requires-brackets'] = true;
   */
  needsBrackets: boolean;
  /**
   * @example
   * obj['var'] //-> invalid in ES3
   */
  es3Warning: boolean;
  quotedValue: string;
}

export class UnquotedPropertyUtils {
  static validate(propertyName: string): UnquotedPropertyValidationResult {
    const result = unquotedValidator(propertyName);

    return {
      needsQuotes: result.needsQuotes,
      needsBrackets: result.needsBrackets,
      es3Warning: result.es3Warning,
      quotedValue: result.quotedValue,
    } as UnquotedPropertyValidationResult;
  }

  static generateVariablePropertyAccessChain(objectName: string = '$msg', properties: string[] = []): string {
    let str = '';

    str += objectName;

    if (properties?.length > 0) {
      properties.forEach((property) => {
        const result = this.validate(property);

        if (result.needsBrackets) {
          str += `[${result.quotedValue}]`;
        } else {
          str += `.${result.quotedValue}`;
        }
      });
    }

    return str;
  }
}
