import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { WidgetTableFormat } from '../../models';
import { ValueEvalPipe } from '../../pipes';
import { WidgetBaseComponent } from '../shared';
@Component({
  selector: 'app-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['../shared/styles.scss', './widget-table.component.scss'],
})
export class WidgetTableComponent extends WidgetBaseComponent implements OnChanges {
  @Input()
  content: any;
  @Input()
  demoMode: boolean = false;

  @Input()
  tableFormat: WidgetTableFormat;

  table: {
    header: string[];
    content: any[];
  } = {} as any;

  private evalPipe: ValueEvalPipe;

  constructor() {
    super();
    this.evalPipe = new ValueEvalPipe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      if (this.valueExpression) {
        this.content = this.evalPipe.transform(this.content, this.valueExpression);
      }

      switch (this.tableFormat) {
        case 'rows':
        case 'columns':
          this.generateTable(this.content);
          break;

        case 'raw-json':
          // Do nothing here.
          break;

        default:
          break;
      }
    }
    if (changes['demoMode'] && this.demoMode) {
      this.loadDemoData();
      this.generateTable(this.content);
    }
  }

  private loadDemoData() {
    const str = {
      'TIME_BOOT_MS': 135414460,
      'ROLL': 0.00092060165,
      'PITCH': 0.0014621271,
      'YAW': -0.153906,
      'ROLLSPEED': 0.0030121112,
      'PITCHSPEED': 0.0028324912,
      'YAWSPEED': 0.0026501438,
    };

    this.content = str;
  }

  private generateTable(content: any) {
    let headerArr = [];
    const contentArr = [];

    if (content) {
      if (Array.isArray(content)) {
        const arr = content;
        arr.forEach((item) => {
          if (typeof item === 'string' || typeof item === 'number') {
            headerArr = [...headerArr, ...['_NO_HEADER_']];

            contentArr.push({
              _NO_HEADER_: item,
            });
          } else {
            const keys = Object.keys(item);

            headerArr = [...headerArr, ...keys];

            contentArr.push(item);
          }
        });
      } else {
        const keys = Object.keys(content);
        headerArr = [...headerArr, ...keys];
        contentArr.push(content);
      }
    }

    this.table = {
      header: Array.from(new Set(headerArr)),
      content: contentArr,
    };
  }
}
