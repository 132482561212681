<div class="rs-dialog large" id="command-dialog" *ngIf="(mode$ | async) === dialogMode.Command">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>
      Command
      <span class="right-buttons">
        <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div>
      <mat-form-field>
        <input matInput type="text" formControlName="id" placeholder="Command ID" cdkFocusInitial />
        <mat-error *ngIf="form.controls['id']?.errors?.['required']">Required.</mat-error>
        <mat-error *ngIf="form.controls['id']?.errors?.['duplicated']">This id already exists</mat-error>
        <mat-error *ngIf="form.controls['id']?.errors?.['commandId']"
          >Only letters, numbers, /, ., _, and - is allowed, max length 40 chars</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" formControlName="name" placeholder="Description" />
      </mat-form-field>

      <div class="command-type mb-3">
        <h3>Type</h3>
        <mat-radio-group formControlName="type" (change)="onCommandTypeChange($event)">
          <ng-container *ngFor="let commandType of availableCommandTypes">
            <mat-radio-button class="mr-4" [value]="commandType.id">{{ commandType.label }}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
        <mat-error *ngIf="form.controls['type']?.errors?.['required']">Required.</mat-error>
      </div>

      <div class="data-value">
        <mat-form-field formGroupName="settings" class="data-target">
          <input matInput type="text" formControlName="target" placeholder="Target" />
          <mat-error *ngIf="form.controls['target']?.errors?.['required']">Required.</mat-error>
        </mat-form-field>

        <div class="data-picker">
          <button type="button" class="left-icon-button" mat-stroked-button color="primary" (click)="openDataPicker()">
            <span class="icon ri-copy-link"></span><span>Data Picker</span>
          </button>
        </div>
      </div>

      <div class="payload">
        <h3 class="mt-3">Payload</h3>
        <div class="payload-data p-4">
          <h4>Parameters</h4>
          <p>
            Pass information or instructions into the payload template by adding a parameter. E.g.
            <code>{{ 'paramId' | gamepadCommandParam }}</code> will be replaced with <code>paramId</code>'s value when
            the command is run.
          </p>
          <button mat-stroked-button class="left-icon-button mb-2" color="primary" (click)="onAddParam()">
            <span class="ri-add"></span>
            <span>Add Parameter</span>
          </button>

          <div class="parameters mt-2 mb-2">
            <div
              class="item-form-group"
              *ngFor="let param of parameters?.controls; let idx = index"
              formArrayName="parameters"
            >
              <div [formGroupName]="idx">
                <div class="row align-items-center">
                  <div class="col-5">
                    <mat-form-field>
                      <input matInput type="text" formControlName="id" placeholder="Parameter Id" />
                      <mat-error *ngIf="$any(form).controls.parameters.controls[idx].controls.id.errors?.['required']"
                        >Required.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-5">
                    <mat-form-field>
                      <input matInput type="text" formControlName="default" placeholder="Default Value" />
                    </mat-form-field>
                  </div>
                  <div class="col-auto delete-button">
                    <button
                      type="button"
                      mat-icon-button
                      matTooltip="Copy parameter to clipboard"
                      [appCopyText]="
                        $any(form).controls.parameters.controls[idx].controls.id.value | gamepadCommandParam
                      "
                    >
                      <span class="ri-copy-to-clipboard"></span>
                    </button>
                    <button type="button" mat-icon-button (click)="onRemoveParam(idx)">
                      <span class="ri-delete"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div formGroupName="settings" class="mt-2">
            <h4>Parameter Template</h4>
            <div class="editor" [style.height]="'240px'">
              <ngx-monaco-editor
                [style.height]="'240px'"
                [options]="{
                  contextmenu: false,
                  language: 'text/plain',
                  minimap: { enabled: false }
                }"
                formControlName="payloadTemplate"
              >
              </ngx-monaco-editor>
            </div>
            <h4 class="metadata-header">Metadata</h4>
            <div class="editor" [style.height]="'120px'">
              <ngx-monaco-editor
                [style.height]="'120px'"
                formControlName="payloadMeta"
                [options]="{
                  contextmenu: false,
                  language: 'text/plain',
                  minimap: { enabled: false }
                }"
              >
              </ngx-monaco-editor>
            </div>
          </div>
        </div>
      </div>

      <div class="safety" formGroupName="settings">
        <h3>Safety</h3>
        <div class="row align-items-baseline">
          <mat-form-field class="col-5">
            <input matInput type="number" formControlName="timeoutMs" placeholder="Default Timeout in Milliseconds" />
            <mat-error *ngIf="$any(form).controls.settings.controls.timeoutMs?.errors?.['required']"
              >Required.</mat-error
            >
          </mat-form-field>
          <div class="col-5">
            <mat-checkbox formControlName="requiresClockSync">Required Clock Sync</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button type="button" class="left-icon-button mr-3" mat-stroked-button color="primary" (click)="onCancel()">
        <span>Cancel</span>
      </button>
      <app-loading-button [loading]="isLoading" (clicked)="onSubmit()">
        <span>Save</span>
      </app-loading-button>
    </div>
  </form>
</div>

<div *ngIf="(mode$ | async) === dialogMode.DataPicker" class="data-picker-wrapper rs-dialog maximum">
  <h2 mat-dialog-title class="title">
    <span class="left-part">
      <button
        type="button"
        class="back-btn"
        mat-icon-button
        matTooltip="Back"
        tabindex="-1"
        (click)="closeDataPicker()"
      >
        <span class="ri-indicator-left"></span>
      </button>
      <span> Data Picker </span>
    </span>
  </h2>

  <app-data-explorer-wrapper
    [projectId]="projectId"
    [callsign]="callsign$ | async"
    [insertDataURIMode]="true"
    [dataStreamMode]="true"
    [enableRobotPicker]="true"
    [showServiceMode]="true"
    (dataItemChange)="onDataExplorerChange($event)"
  >
  </app-data-explorer-wrapper>
</div>
