import type { ITimeInterval, TimeUnit } from '@amcharts/amcharts4/core';

export class WidgetTimeInterval implements ITimeInterval {
  public timeUnit: TimeUnit;
  public count: number;

  public disabled: boolean = false;

  get label(): string {
    let label = `${this.count} ${this.timeUnit}`;
    if (this.count > 1) label += 's';
    return label;
  }

  get singularLabel(): string {
    return `${this.count} ${this.timeUnit}`;
  }

  // Get format as 1m, 2d, 3s etc.
  get short(): string {
    return `${this.count}${this.timeUnit.substr(0, 1)}`;
  }

  constructor(unit: TimeUnit, count: number) {
    this.timeUnit = unit;
    this.count = count;
  }

  public static default(): WidgetTimeInterval {
    return new WidgetTimeInterval('millisecond', 200);
  }

  public getSeconds(): number {
    switch (this.timeUnit) {
      case 'day':
        return 24 * 60 * 60 * this.count;
      case 'hour':
        return 60 * 60 * this.count;
      case 'minute':
        return 60 * this.count;
      case 'second':
        return this.count;
      default:
        return this.count;
    }
  }
}
