import type { NgxMonacoEditorConfig } from '@shared-modules/monaco-editor';
import type { Monaco, EditorOptions as MonacoOptions } from '@shared-modules/monaco-editor';

export type EditorLanguage = 'javascript' | 'json' | 'text/plain';
export type EditorTheme = 'rocosCustomTheme';
export type EditorOptions = MonacoOptions & {
  theme: EditorTheme;
  language: EditorLanguage;
};

const defaultEditorOptions: EditorOptions = {
  language: 'javascript',
  theme: 'rocosCustomTheme',
  minimap: {
    enabled: false,
  },
  lineNumbers: 'off',
  automaticLayout: true,
  scrollBeyondLastLine: false,
  lineDecorationsWidth: 0,
  hideCursorInOverviewRuler: true,
  wordWrap: 'on',
  contextmenu: false,
  cursorSurroundingLines: 0,
  renderLineHighlight: 'none',
};

export const jsEditorOptions: EditorOptions = {
  ...defaultEditorOptions,
  language: 'javascript',
};

const JSONEditorOptions: EditorOptions = {
  ...defaultEditorOptions,
  language: 'json',
};

const textEditorOptions: EditorOptions = {
  ...defaultEditorOptions,
  language: 'text/plain',
};

export const onMonacoLoad = (monaco: Monaco) => {
  monaco.editor.defineTheme('rocosCustomTheme', {
    base: 'vs-dark' as const,
    inherit: true,
    rules: [],
    colors: {
      'editor.background': '#12161c',
    },
  });
  monaco.editor.setTheme('rocosCustomTheme');
};

// This is called here:
// src/app/app.module.ts
export const ngxMonacoEditorConfig: NgxMonacoEditorConfig = {
  defaultOptions: {
    language: 'json',
    automaticLayout: true,
  },
  onMonacoLoad,
};

export const getEditorOptions = (
  language: EditorLanguage = 'javascript',
  options: Partial<EditorOptions> = {},
): EditorOptions => {
  switch (language) {
    case 'javascript':
      return { ...jsEditorOptions, ...options };

    case 'json':
      return { ...JSONEditorOptions, ...options };

    case 'text/plain':
      return { ...textEditorOptions, ...options };

    default:
      return { ...jsEditorOptions, ...options };
  }
};
