import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardScaleService } from '../../services/dashboard-scale/dashboard-scale.service';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-scale-wrapper',
  templateUrl: './scale-wrapper.component.html',
  styleUrls: ['./scale-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleWrapperComponent implements OnInit {
  scale$: Observable<number>;

  constructor(private dashboardScale: DashboardScaleService) {}

  ngOnInit() {
    this.scale$ = this.dashboardScale.settings.pipe(
      map((s) => s.scale),
      untilDestroyed(this),
    );
  }
}
