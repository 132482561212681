<div class="boxes">
  <div id="box1" class="box" [class.pressed]="pressedButtons && pressedButtons[0]">
    <span class="box-text">
      {{ pressedButtons && pressedButtons[0]?.text && shortNames[pressedButtons[0].text] }}
    </span>
  </div>

  <div id="box2" class="box" [class.pressed]="pressedButtons && pressedButtons[1]">
    <span class="box-text">
      {{ pressedButtons && pressedButtons[1]?.text && shortNames[pressedButtons[1].text] }}
    </span>
  </div>

  <div id="box3" class="box" [class.pressed]="pressedButtons && pressedButtons[2]">
    <span class="box-text">
      {{ pressedButtons && pressedButtons[2]?.text && shortNames[pressedButtons[2].text] }}
    </span>
  </div>

  <div id="box4" class="box" [class.pressed]="pressedButtons && pressedButtons[3]">
    <span class="box-text">
      {{ pressedButtons && pressedButtons[3]?.text && shortNames[pressedButtons[3].text] }}
    </span>
  </div>

  <div id="box5" class="box" [class.pressed]="pressedButtons && pressedButtons[4]">
    <span class="box-text">
      {{ pressedButtons && pressedButtons[4]?.text && shortNames[pressedButtons[4].text] }}
    </span>
  </div>
</div>
