<ng-container *transloco="let tCommon; read: 'common'">
  <div class="dialog">
    <h2 mat-dialog-title class="title">
      <span>{{ title }}</span>
      <span class="right-buttons">
        <button mat-icon-button mat-dialog-close matTooltip="{{ tCommon('cancel') }}" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>

    <div class="dialog-content" mat-dialog-content>
      <div [class.rs-warning]="actionType === 'warning'">
        <div class="warning-icon" *ngIf="actionType === 'warning'">
          <span class="background-dot">
            <span class="ri-exclamation-mark"></span>
          </span>
        </div>
        <div [innerHTML]="message"></div>
      </div>

      <ng-container *ngIf="confirmText">
        <p *ngIf="confirmWarningMessage" [innerHTML]="confirmWarningMessage"></p>
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="{{ tCommon('confirm') }}"
            [(ngModel)]="confirmedText"
            name="confirmedText"
          />
        </mat-form-field>
      </ng-container>
    </div>

    <div class="footer">
      <button mat-stroked-button color="primary" mat-dialog-close tabindex="-1">{{ cancelButtonText }}</button>
      <button
        mat-flat-button
        [color]="actionButtonColor"
        [mat-dialog-close]="true"
        tabindex="-1"
        [disabled]="confirmText && stringUtils.trim(confirmedText, true) !== stringUtils.trim(confirmText, true)"
      >
        {{ actionButtonText }}
      </button>
    </div>
  </div>
</ng-container>
