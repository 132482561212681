import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { AccountService, AppService, GotoService, RocosSdkClientService } from '@shared/services';
import type { CommandPaletteState, SuggestionNode } from './comand-palette.types';
import { type Suggestion, SuggestionLevel } from './comand-palette.types';
import type { Project } from '@shared/models';

const isNavigationEnd = (event: any): event is NavigationEnd => event instanceof NavigationEnd;

enum PathParams {
  PROJECT_ID = 'projectId',
  CALLSIGN = 'callsign',
  DASHBOARD_ID = 'dashboard',
  ACCOUNT_ID = 'accountId',
  PROFILE_ID = 'defId',
  STREAM_ID = 'streamId',
  FLOW_ID = 'flowId',
  OPERATION_ID = 'pageId',
}

const isPathParams = (key: string): key is PathParams => Object.values(PathParams).includes(key as PathParams);

@Injectable({
  providedIn: 'root',
})
export class CommandPaletteService {
  open$ = new Subject<void>();
  suggestions$ = new BehaviorSubject<Suggestion[]>([]);
  breadcrumb$ = new BehaviorSubject<string[]>([]);
  loading$ = new BehaviorSubject(false);
  initialising$ = new BehaviorSubject(true);

  private routeChange$: Observable<void> = this.router.events.pipe(
    filter(isNavigationEnd),
    startWith(null),
    map(() => null),
  );
  private params$ = this.routeChange$.pipe(
    map(() => {
      const params = new Map<PathParams, string>();
      let route = this.activatedRoute;
      while (route?.firstChild) {
        route = route.firstChild;
        const routeParams = route.snapshot?.params;
        if (!routeParams) {
          continue;
        }

        // Using the proper function breaks the type guard
        /* eslint-disable-next-line unicorn/no-array-callback-reference */
        const supportedPathParams = Object.keys(routeParams).filter(isPathParams);

        supportedPathParams.forEach((key) => {
          params.set(key, routeParams[key]);
        });
      }
      return params;
    }),
  );
  private currentParams: Map<PathParams, string> = new Map();

  private stack: SuggestionNode[] = [];
  private currentNode: SuggestionNode;

  private tree: SuggestionNode;
  private state: CommandPaletteState = {};

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private accountService: AccountService,
    private rocosClientService: RocosSdkClientService,
    private goto: GotoService,
  ) {
    this.params$.subscribe((params) => {
      this.currentParams = params;
    });
  }

  open() {
    this.open$.next();
    void this.init();
  }

  async handleSuggestion(suggestion: Suggestion) {
    if (!this.currentNode.selectionHandler) {
      return;
    }

    const nextLevel = this.currentNode.selectionHandler(suggestion);
    const nextNode = this.currentNode.children?.find((child) => child.level === nextLevel);
    if (!nextNode) {
      return;
    }

    this.stack.push(nextNode);
    this.currentNode = nextNode;
    this.updateBreadCrumb();
    this.suggestions$.next([]);
    await this.getSuggestions();
  }

  async handleDeselection() {
    if (this.stack.length === 1) {
      // Don't pop the top level off, as we need it to suggest anything
      return;
    }

    const lastNode = this.stack.pop();
    if (!lastNode) {
      return;
    }
    lastNode.deselectionHandler?.();
    this.currentNode = this.stack[this.stack.length - 1];

    this.updateBreadCrumb();
    await this.getSuggestions();
  }

  handleAction() {
    return this.currentNode.runAction?.();
  }

  private async init() {
    this.initialising$.next(true);
    // We assume something else in the app is going to fetch accounts on load, as that API is expensive
    const accounts = await firstValueFrom(this.accountService.accounts$.pipe(filter(Boolean)));
    const account = accounts.find((a) => a.id === this.appService.accountId);
    const project = account.projects.find((p) => p.id === this.appService.projectId);
    const callsign = this.appService.callsign;
    const dashboardId = this.currentParams.get(PathParams.DASHBOARD_ID);
    const profileId = this.currentParams.get(PathParams.PROFILE_ID);
    const operationId = this.currentParams.get(PathParams.OPERATION_ID);
    const flowId = this.currentParams.get(PathParams.FLOW_ID);

    this.tree = this.buildTree();
    this.stack = [this.tree];
    this.currentNode = this.tree;
    this.state = {
      account,
    };

    await this.recreateState({
      project,
      callsign,
      dashboardId,
      profileId,
      operationId,
      flowId,
    });

    this.initialising$.next(false);

    this.updateBreadCrumb();
    await this.getSuggestions();
  }

  private async recreateState(state: {
    project: Project;
    callsign: string;
    dashboardId: string;
    profileId: string;
    operationId: string;
    flowId: string;
  }) {
    if (!state.project) {
      return;
    }

    this.state.projectId = state.project.id;
    const projectNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.PROJECT);
    if (projectNode) {
      this.stack.push(projectNode);
      this.currentNode = projectNode;
    }

    if (state.callsign) {
      await this.loadCallsignState(state.callsign, state.dashboardId);
      return;
    }

    if (state.profileId) {
      await this.loadProfileState(state.profileId, state.dashboardId);
      return;
    }

    if (state.flowId) {
      await this.loadFlowState(state.flowId);
      return;
    }

    if (state.operationId) {
      await this.loadOperationState(state.operationId);
    }
  }

  private async loadCallsignState(callsign: string, dashboardId?: string) {
    const robotInfo = await this.rocosClientService.client.getRobotService().info(this.state.projectId, callsign);
    this.state.robot = {
      callsign,
      profileId: robotInfo.robotDefinition,
    };
    const robotsNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.ROBOTS);
    const robotNode = robotsNode?.children?.find((child) => child.level === SuggestionLevel.ROBOT);
    if (!robotNode) return;

    this.stack.push(robotsNode);
    this.stack.push(robotNode);
    this.currentNode = robotNode;

    if (!dashboardId) return;

    const dashboards = await this.getDashboardsForRobot();
    const dashboard = dashboards.find((d) => d.id === dashboardId);
    this.state.robotDashboard = {
      id: dashboardId,
      name: dashboard?.name,
    };
    const dashboardsNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.ROBOT_DASHBOARDS);
    const dashboardNode = dashboardsNode.children?.find((child) => child.level === SuggestionLevel.ROBOT_DASHBOARD);
    if (dashboardNode) {
      this.stack.push(dashboardsNode);
      this.stack.push(dashboardNode);
      this.currentNode = dashboardNode;
    }
  }

  private async loadProfileState(profileId: string, dashboardId?: string) {
    const profiles = await this.getProfilesForProject();
    const profile = profiles.find((p) => p.id === profileId);
    this.state.profile = {
      id: profileId,
      name: profile?.name,
    };
    const profilesNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.PROFILES);
    const profileNode = profilesNode?.children?.find((child) => child.level === SuggestionLevel.PROFILE);
    if (!profileNode) return;

    this.stack.push(profilesNode);
    this.stack.push(profileNode);
    this.currentNode = profileNode;

    if (!dashboardId) return;

    const dashboards = await this.getDashboardsForProfile();
    const dashboard = dashboards.find((d) => d.id === dashboardId);
    this.state.profileDashboard = {
      id: dashboardId,
      name: dashboard?.name,
    };
    const dashboardsNode = this.currentNode.children?.find(
      (child) => child.level === SuggestionLevel.PROFILE_DASHBOARDS,
    );
    const dashboardNode = dashboardsNode.children?.find((child) => child.level === SuggestionLevel.PROFILE_DASHBOARD);
    if (dashboardNode) {
      this.stack.push(dashboardsNode);
      this.stack.push(dashboardNode);
      this.currentNode = dashboardNode;
    }
  }

  private async loadFlowState(flowId: string) {
    const flows = await this.getWorkflowsForProject();
    const flow = flows.find((f) => f.id === flowId);
    this.state.workflow = {
      id: flowId,
      name: flow?.name,
    };
    const flowsNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.AUTOMATES);
    const flowNode = flowsNode?.children?.find((child) => child.level === SuggestionLevel.AUTOMATE);
    if (!flowNode) return;

    this.stack.push(flowsNode);
    this.stack.push(flowNode);
    this.currentNode = flowNode;
  }

  private async loadOperationState(operationId: string) {
    const operations = await this.getOperationsForProject();
    const operation = operations.find((o) => o.id === operationId);
    this.state.operation = {
      id: operationId,
      name: operation?.name,
      type: operation?.meta.type,
    };
    const operationsNode = this.currentNode.children?.find((child) => child.level === SuggestionLevel.OPERATIONS);
    const operationNode = operationsNode?.children?.find((child) => child.level === SuggestionLevel.OPERATION);
    if (!operationNode) return;

    this.stack.push(operationsNode);
    this.stack.push(operationNode);
    this.currentNode = operationNode;
  }

  private async getSuggestions() {
    if (!this.currentNode.getSuggestions) {
      this.suggestions$.next([]);
      return;
    }

    this.loading$.next(true);
    const currentLevel = this.currentNode.level;
    const suggestions = await this.currentNode.getSuggestions();
    if (currentLevel !== this.currentNode.level) {
      // Hack to ignore suggestions from old requests since I can't cancel the request
      return;
    }
    this.suggestions$.next(suggestions);
    this.loading$.next(false);
  }

  private buildTree() {
    const robotNode: SuggestionNode = {
      level: SuggestionLevel.ROBOT,
      children: [
        {
          level: SuggestionLevel.ROBOT_DASHBOARDS,
          children: [
            {
              level: SuggestionLevel.ROBOT_DASHBOARD,
              getBreadcrumb: () => {
                return this.state.robotDashboard?.name;
              },
              runAction: () => {
                this.goto
                  .robotDashboard(this.state.projectId, this.state.robot?.callsign, this.state.robotDashboard?.id)
                  .then(() => {
                    // Dashboards don't react to params changes, so we need to reload the page
                    location.reload();
                  });
                return true;
              },
            },
          ],
          getSuggestions: this.getDashboardsForRobot.bind(this),
          selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
            this.state.robotDashboard = {
              id: suggestion.id,
              name: suggestion.name,
            };
            return SuggestionLevel.ROBOT_DASHBOARD;
          },
          getBreadcrumb: () => {
            return 'Dashboards';
          },
          runAction: () => {
            this.goto.robotDashboard(this.state.projectId, this.state.robot?.callsign, 'default');
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_SETTINGS,
          runAction: () => {
            this.goto.robotSettings(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_EVENTS,
          runAction: () => {
            this.goto.robotEvents(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_COMMANDS,
          runAction: () => {
            this.goto.robotCommands(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_CONTROLS,
          runAction: () => {
            this.goto.robotControls(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_BUTTONS,
          runAction: () => {
            this.goto.robotButtons(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_GAMEPADS,
          runAction: () => {
            this.goto.robotGamepads(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_STORAGE_STREAMS,
          runAction: () => {
            this.goto.robotStorageStreams(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_LDV,
          runAction: () => {
            this.goto.robotDataViewer(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_FILES,
          runAction: () => {
            this.goto.robotFiles(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_SHELL,
          runAction: () => {
            this.goto.robotShell(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_AUTOMATE,
          runAction: () => {
            this.goto.robotAutomate(this.state.projectId, this.state.robot?.callsign);
            return true;
          },
        },
        {
          level: SuggestionLevel.ROBOT_ROBOT_MAPS,
          runAction: () => {
            this.goto.robotPage(this.state.projectId, this.state.robot?.callsign, 'robot-maps');
            return true;
          },
        },
      ],
      getSuggestions: async (): Promise<Suggestion[]> => {
        return [
          {
            id: SuggestionLevel.ROBOT_DASHBOARDS,
            name: 'Dashboards',
          },
          {
            id: SuggestionLevel.ROBOT_SETTINGS,
            name: 'Settings',
          },
          {
            id: SuggestionLevel.ROBOT_EVENTS,
            name: 'Events',
          },
          {
            id: SuggestionLevel.ROBOT_COMMANDS,
            name: 'Commands',
          },
          {
            id: SuggestionLevel.ROBOT_CONTROLS,
            name: 'Controls',
          },
          {
            id: SuggestionLevel.ROBOT_BUTTONS,
            name: 'Buttons',
          },
          {
            id: SuggestionLevel.ROBOT_GAMEPADS,
            name: 'Gamepads',
          },
          {
            id: SuggestionLevel.ROBOT_STORAGE_STREAMS,
            name: 'Storage Streams',
          },
          {
            id: SuggestionLevel.ROBOT_LDV,
            name: 'LDV',
          },
          {
            id: SuggestionLevel.ROBOT_FILES,
            name: 'Files',
          },
          {
            id: SuggestionLevel.ROBOT_SHELL,
            name: 'Shell',
          },
          {
            id: SuggestionLevel.ROBOT_AUTOMATE,
            name: 'Automate',
          },
          {
            id: SuggestionLevel.ROBOT_ROBOT_MAPS,
            name: 'Robot Maps',
          },
        ];
      },
      selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
        return suggestion.id as SuggestionLevel;
      },
      deselectionHandler: () => {
        this.state.robot = undefined;
      },
      getBreadcrumb: () => {
        return this.state.robot?.callsign;
      },
      runAction: () => {
        this.goto.robotInfo(this.state.projectId, this.state.robot?.callsign);
        return true;
      },
    };

    const profileNode: SuggestionNode = {
      level: SuggestionLevel.PROFILE,
      children: [
        {
          level: SuggestionLevel.PROFILE_DASHBOARDS,
          children: [
            {
              level: SuggestionLevel.PROFILE_DASHBOARD,
              getBreadcrumb: () => {
                return this.state.profileDashboard?.name;
              },
              runAction: () => {
                this.goto.robotDefinitionDashboard(
                  this.state.projectId,
                  this.state.profile.id,
                  this.state.profileDashboard?.id,
                );
                return true;
              },
            },
          ],
          getBreadcrumb: () => {
            return 'Dashboards';
          },
          getSuggestions: this.getDashboardsForProfile.bind(this),
          selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
            this.state.profileDashboard = {
              id: suggestion.id,
              name: suggestion.name,
            };
            return SuggestionLevel.PROFILE_DASHBOARD;
          },
          runAction: () => {
            this.goto.robotDefinitionDashboard(this.state.projectId, this.state.profile.id, 'default').then(() => {
              // Dashboards don't react to params changes, so we need to reload the page
              location.reload();
            });
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_SETTINGS,
          runAction: () => {
            this.goto.robotDefinitionSettings(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_EVENTS,
          runAction: () => {
            this.goto.robotDefinitionEvents(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_COMMANDS,
          runAction: () => {
            this.goto.robotDefinitionCommands(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_CONTROLS,
          runAction: () => {
            this.goto.robotDefinitionControls(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_BUTTONS,
          runAction: () => {
            this.goto.robotDefinitionButtons(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_GAMEPADS,
          runAction: () => {
            this.goto.robotDefinitionGamepads(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_STORAGE_STREAMS,
          runAction: () => {
            this.goto.robotDefinitionStorageStreams(this.state.projectId, this.state.profile.id);
            return true;
          },
        },
        {
          level: SuggestionLevel.PROFILE_PROVISIONING,
          runAction: () => {
            this.goto.robotDefinitionPage(this.state.projectId, this.state.profile.id, 'provisioning');
            return true;
          },
        },
      ],
      getBreadcrumb: () => {
        return this.state.profile.name;
      },
      getSuggestions: async (): Promise<Suggestion[]> => {
        return [
          {
            id: SuggestionLevel.PROFILE_DASHBOARDS,
            name: 'Dashboards',
          },
          {
            id: SuggestionLevel.PROFILE_SETTINGS,
            name: 'Settings',
          },
          {
            id: SuggestionLevel.PROFILE_EVENTS,
            name: 'Events',
          },
          {
            id: SuggestionLevel.PROFILE_COMMANDS,
            name: 'Commands',
          },
          {
            id: SuggestionLevel.PROFILE_CONTROLS,
            name: 'Controls',
          },
          {
            id: SuggestionLevel.PROFILE_BUTTONS,
            name: 'Buttons',
          },
          {
            id: SuggestionLevel.PROFILE_GAMEPADS,
            name: 'Gamepads',
          },
          {
            id: SuggestionLevel.PROFILE_STORAGE_STREAMS,
            name: 'Storage Streams',
          },
          {
            id: SuggestionLevel.PROFILE_PROVISIONING,
            name: 'Provisioning',
          },
        ];
      },
      selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
        return suggestion.id as SuggestionLevel;
      },
      runAction: () => {
        this.goto.robotDefinitionDashboards(this.state.projectId, this.state.profile.id);
        return true;
      },
    };

    const projectNodes: SuggestionNode[] = [
      {
        level: SuggestionLevel.ROBOTS,
        children: [robotNode],
        getSuggestions: this.getRobotsForProject.bind(this),
        selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
          this.state.robot = {
            callsign: suggestion.id,
            profileId: suggestion.meta.profileId,
          };
          return SuggestionLevel.ROBOT;
        },
        getBreadcrumb: () => {
          return this.state.robot?.callsign ? `Robot` : 'Robots';
        },
        deselectionHandler: () => {
          this.state.robot = undefined;
        },
        runAction: () => {
          this.goto.robotsList(this.state.projectId);
          return true;
        },
      },
      {
        level: SuggestionLevel.PROFILES,
        children: [profileNode],
        getSuggestions: this.getProfilesForProject.bind(this),
        selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
          this.state.profile = {
            id: suggestion.id,
            name: suggestion.name,
          };
          return SuggestionLevel.PROFILE;
        },
        deselectionHandler: () => {
          this.state.profile = undefined;
        },
        getBreadcrumb: () => {
          return this.state.profile ? `Profile` : 'Profiles';
        },
        runAction: () => {
          this.goto.robotDefinitionIndex(this.state.projectId);
          return true;
        },
      },
      {
        level: SuggestionLevel.OPERATIONS,
        children: [
          {
            level: SuggestionLevel.OPERATION,
            getBreadcrumb: () => {
              return this.state.operation.name;
            },
            runAction: () => {
              this.goto.operationDetail(this.state.projectId, this.state.operation.id, this.state.operation.type);
              return true;
            },
            deselectionHandler: () => {
              this.state.operation = undefined;
            },
          },
        ],
        getSuggestions: this.getOperationsForProject.bind(this),
        selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
          this.state.operation = {
            id: suggestion.id,
            name: suggestion.name,
            type: suggestion.meta.type,
          };
          return SuggestionLevel.OPERATION;
        },
        runAction: () => {
          this.goto.operationList(this.state.projectId);
          return true;
        },
        getBreadcrumb: () => {
          return this.state.operation ? `Operations - ${this.state.operation.type}` : 'Operations';
        },
      },
      {
        level: SuggestionLevel.AUTOMATES,
        children: [
          {
            level: SuggestionLevel.AUTOMATE,
            getBreadcrumb: () => {
              return this.state.workflow.name;
            },
            deselectionHandler: () => {
              this.state.workflow = undefined;
            },
            runAction: () => {
              this.goto.workflowDetail(this.state.projectId, this.state.workflow.id);
              return true;
            },
          },
        ],
        getSuggestions: this.getWorkflowsForProject.bind(this),
        selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
          this.state.workflow = {
            id: suggestion.id,
            name: suggestion.name,
          };
          return SuggestionLevel.AUTOMATE;
        },
        runAction: () => {
          this.goto.workflowList(this.state.projectId);
          return true;
        },
        getBreadcrumb: () => {
          return 'Automate';
        },
      },
      {
        level: SuggestionLevel.STORAGE_STREAMS,
        getBreadcrumb: () => {
          return 'Storage Streams';
        },
        runAction: () => {
          this.goto.storageStreamsIndex(this.state.projectId);
          return true;
        },
      },
      {
        level: SuggestionLevel.STORAGE_EXPLORER,
        getBreadcrumb: () => {
          return 'Storage Explorer';
        },
        runAction: () => {
          this.goto.storageExplorerIndex(this.state.projectId);
          return true;
        },
      },
    ];

    const tree: SuggestionNode = {
      level: SuggestionLevel.PROJECTS,
      children: [
        {
          level: SuggestionLevel.PROJECT,
          children: projectNodes,
          getSuggestions: async (): Promise<Suggestion[]> => {
            return [
              {
                id: SuggestionLevel.ROBOTS,
                name: 'Robots',
              },
              {
                id: SuggestionLevel.PROFILES,
                name: 'Profiles',
              },
              {
                id: SuggestionLevel.OPERATIONS,
                name: 'Operations',
              },
              {
                id: SuggestionLevel.AUTOMATES,
                name: 'Automate',
              },
              {
                id: SuggestionLevel.STORAGE_STREAMS,
                name: 'Storage Streams',
              },
              {
                id: SuggestionLevel.STORAGE_EXPLORER,
                name: 'Storage Explorer',
              },
            ];
          },
          selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
            return suggestion.id as SuggestionLevel;
          },
          deselectionHandler: () => {
            this.state.projectId = undefined;
            this.state.robot = undefined;
          },
          getBreadcrumb: () => {
            return this.state.account.projects.find((project) => project.id === this.state.projectId)?.name;
          },
          runAction: () => {
            this.goto.projectIndex(this.state.projectId);
            return true;
          },
        },
      ],
      getSuggestions: async (): Promise<Suggestion[]> => {
        return this.state.account.projects.map((project) => ({
          id: project.id,
          name: project.name,
        }));
      },
      selectionHandler: (suggestion: Suggestion): SuggestionLevel => {
        this.state.projectId = suggestion.id;
        return SuggestionLevel.PROJECT;
      },
      getBreadcrumb: () => {
        const projectName = this.state.account.projects.find((project) => project.id === this.state.projectId)?.name;
        return projectName ? `Project` : 'Projects';
      },
      runAction: () => {
        this.goto.defaultAccountPage();
        return true;
      },
    };

    return tree;
  }

  private updateBreadCrumb() {
    const crumb = this.stack
      .map((entry) => {
        return entry.getBreadcrumb ? entry.getBreadcrumb() : entry.level;
      })
      .filter(Boolean);
    this.breadcrumb$.next(crumb);
  }

  private async getRobotsForProject(): Promise<Suggestion<{ profileId: string }>[]> {
    const robots = await this.rocosClientService.client.getRobotService().getRobots(this.state.projectId);
    return robots.map((robot) => ({
      id: robot.callsign,
      name: robot.callsign,
      meta: {
        profileId: robot.robotDefinition,
      },
    }));
  }

  private async getProfilesForProject(): Promise<Suggestion[]> {
    const profiles = await this.rocosClientService.client.getProfileService().list(this.state.projectId);
    return profiles.map((profile) => ({
      id: profile.id,
      name: profile.name,
    }));
  }

  private async getOperationsForProject(): Promise<Suggestion<{ type: string }>[]> {
    const operations = await this.rocosClientService.client.getProjectService().getOperationsList(this.state.projectId);
    return operations.map((operation) => ({
      id: operation.id,
      name: operation.name,
      meta: {
        type: operation.pageType,
      },
    }));
  }

  private async getWorkflowsForProject(): Promise<Suggestion[]> {
    const workflows = await this.rocosClientService.client.getWorkflowService().list(this.state.projectId);
    return workflows.map((workflow) => ({
      id: workflow.flowId,
      name: workflow.name,
    }));
  }

  private async getDashboardsForRobot(): Promise<Suggestion[]> {
    const robotDashboards = await this.rocosClientService.client
      .getRobotService()
      .listRobotDashboardsV2(this.state.projectId, this.state.robot?.callsign);
    const profileDashboards = await this.rocosClientService.client
      .getRobotService()
      .listDashboardsV2(this.state.projectId, this.state.robot?.profileId);
    const dashboards = [...robotDashboards, ...profileDashboards];
    return dashboards.map((dashboard) => ({
      id: dashboard.dashboardId,
      name: dashboard.name,
    }));
  }

  private async getDashboardsForProfile(): Promise<Suggestion[]> {
    const dashboards = await this.rocosClientService.client
      .getProfileService()
      .dashboards(this.state.projectId, this.state.profile.id);
    return dashboards.map((dashboard) => ({
      id: dashboard.dashboardId,
      name: dashboard.name,
    }));
  }
}
