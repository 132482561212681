import type { OnInit } from '@angular/core';
import type { Breadcrumb } from './breadcrumb';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[] = [];

  public get projectId(): string {
    return this.appService.projectId;
  }

  public get callsign(): string {
    return this.appService.callsign;
  }

  constructor(
    private appService: AppService,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
  ) {
    this.breadcrumbService.breadcrumbChanged.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.breadcrumbService.refreshBreadcrumb();
  }
}
