import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'html',
})
export class HtmlPipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {}

  transform(value: any, _args?: any): any {
    return this.dom.bypassSecurityTrustHtml(value);
  }
}
