import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ButtonDef, CommandV2, GamepadButtonAction, GamepadButtonConfig, GamepadControl } from '../../models';
import { Gamepad, GamepadConfig } from '../../models';

@Component({
  selector: 'app-gamepad-config-wrapper',
  templateUrl: './gamepad-config-wrapper.component.html',
  styleUrls: ['./gamepad-config-wrapper.component.scss'],
})
export class GamepadConfigWrapperComponent implements OnChanges {
  @Input()
  gamepadConfig: GamepadConfig;

  @Input()
  latestChangedGamepad: Gamepad;

  @Input()
  controls: GamepadControl[] = [];

  @Input()
  commands: CommandV2[] = [];

  @Input()
  projectId: string;

  @Output()
  gamepadConfigChange = new EventEmitter<GamepadConfig>();

  buttonDefs: ButtonDef[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gamepadConfig']) {
      this.onGamepadConfigChangedFromOutside();
    }
  }

  onActionsChange(actions: GamepadButtonAction[]) {
    this.gamepadConfig.actions = actions;

    this.onGamepadConfigUpdated();
  }

  onVariablesChange(variables: GamepadButtonConfig[]) {
    this.gamepadConfig.variables = variables;

    this.onGamepadConfigUpdated();
  }

  // Changes from this class
  private onGamepadConfigUpdated() {
    this.gamepadConfigChange.next(this.gamepadConfig);
  }

  // Changes from outside
  private onGamepadConfigChangedFromOutside() {
    // Update button defs
    this.buttonDefs = this.gamepadConfig?.gamepad?.mapping?.getButtonDefs();
  }
}
