import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { gamepadDeadbandThreshold } from '@shared/gamepad';
import type { ButtonDefWithValue } from '@shared/models';

@Component({
  selector: 'app-gamepad-box-indicator',
  templateUrl: './gamepad-box-indicator.component.html',
  styleUrls: ['./gamepad-box-indicator.component.scss'],
})
export class GamepadBoxIndicatorComponent implements OnChanges {
  @Input()
  active: boolean = false;
  @Input()
  pressedValues: ButtonDefWithValue[] = [];

  threshold: number = gamepadDeadbandThreshold;
  pressedButtons: ButtonDefWithValue[];

  shortNames: any = {
    'D-Pad Up': 'UP',
    'D-Pad Down': 'D',
    'D-Pad Left': 'L',
    'D-Pad Right': 'R',
    'A Button': 'A',
    'B Button': 'B',
    'X Button': 'X',
    'Y Button': 'Y',
    'Bumper (Left)': 'LB',
    'Bumper (Right)': 'RB',
    'Trigger (Left)': 'LT',
    'Trigger (Right)': 'RT',
    Back: 'BK',
    Start: 'ST',
    'Stick Button (Left)': 'LS',
    'Stick Button (Right)': 'RS',
    'Stick Axis X (Left)': 'LS',
    'Stick Axis Y (Left)': 'LS',
    'Stick Axis X (Right)': 'RS',
    'Stick Axis Y (Right)': 'RS',
    Guide: 'GD',
  };
  axesGreaterThanThreshold: ButtonDefWithValue[];

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['active'] && changes['active'].currentValue === false) {
      this.pressedButtons = [];
      return;
    }

    if (changes['pressedValues']) {
      this.pressedButtons = [];
      let rightAxisAdded = false;
      let leftAxisAdded = false;

      for (const [i, pressedValue] of this.pressedValues.entries()) {
        // When axis value is greater than threshold, we also show it in the box, but we don't show duplciates
        if (pressedValue.text.includes('Stick')) {
          if (pressedValue.text.includes('Right') && Math.abs(pressedValue.value) > this.threshold && !rightAxisAdded) {
            this.pressedButtons.push(this.pressedValues[i]);
            rightAxisAdded = true;
          }

          if (pressedValue.text.includes('Left') && Math.abs(pressedValue.value) > this.threshold && !leftAxisAdded) {
            this.pressedButtons.push(this.pressedValues[i]);
            leftAxisAdded = true;
          }
        } else {
          this.pressedButtons.push(this.pressedValues[i]);
        }
      }
    }
  }
}
