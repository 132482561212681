import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public statusError: BehaviorSubject<any>;

  constructor() {
    this.statusError = new BehaviorSubject<any>(null);
  }

  public gotUnauthorizedError(err: Error) {
    this.statusError.next(err);
  }

  public gotForbiddenError(err: Error) {
    this.statusError.next(err);
  }
}
