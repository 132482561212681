import { ResourcesUtils } from 'src/app/workflow/modules/graph-editor/utils/resource-utils';
import type { IManifest } from '../workflow-gateway.type';

export const cManifest = (flowId: string): IManifest => {
  return {
    main: [ResourcesUtils.AgentGraphName(flowId), 'Entry'],
    signals: {
      main: {
        entry: [ResourcesUtils.AgentGraphName(flowId), 'Entry'],
        args: [],
      },
    },
    resources: [
      {
        path: 'std-nodes.js',
        provider: 'js',
        runtime: '',
        options: [],
      },
      {
        path: 'std-nodes.json',
        provider: 'visualscript',
        runtime: '',
        options: [],
      },
      {
        path: ResourcesUtils.AgentGraphPath(flowId),
        provider: 'visualscript',
        runtime: '',
        options: [],
      },
    ],
  };
};
