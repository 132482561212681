import type { OnInit } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[appTestID]',
})
export class TestIdDirective implements OnInit {
  @Input() appTestID: string = '';

  public constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    if (environment.testHooksEnabled) {
      this.el.nativeElement.dataset.testid = this.appTestID;
    }
  }
}
