import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDoubleClick]',
})
export class DoubleClickDirective {
  /**
   * Click delay in milliseconds
   */
  @Input()
  clickDelay: number = 200;

  @Output()
  delayedClick: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  doubleClick: EventEmitter<void> = new EventEmitter<void>();

  private timer;
  private prevent = false;

  @HostListener('click')
  onClicked() {
    this.timer = setTimeout(() => {
      if (!this.prevent) {
        this.delayedClick.emit();
      }
      this.prevent = false;
    }, this.clickDelay);
  }

  @HostListener('dblclick')
  onDoubleClicked() {
    clearTimeout(this.timer);
    this.prevent = true;
    this.doubleClick.emit();
  }
}
