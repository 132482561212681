import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { environment } from '@env/environment';

// The following RegExp matches on the whole base uri of the src path and
// picks out two capture groups; the uri and it's environment path.
// This is then used to build the DD image api url in the function below
const replaceRegEx = /(?<uri>https:\/\/storage.googleapis.com\/(?<env>dronedeploy-assets-[\dA-Za-z]+))/;

@Pipe({
  name: 'ddThumbnail',
})
export class DDThumbnailPipe implements PipeTransform {
  transform(value: string, args?: { width: number; height: number }): string {
    if (!value) return '';
    if (!environment.droneDeploy?.thumbnailApi) return value;

    const stringMatch = value.match(replaceRegEx);
    const replaceString = stringMatch?.groups?.['uri'];
    const env = stringMatch?.groups?.['env'];
    if (!replaceString || !env) return value;

    const replaceWith = `${environment.droneDeploy?.thumbnailApi}${args?.height ?? 24}x${args?.width ?? 24}/${env}`;
    return value.replace(replaceString, replaceWith);
  }
}
