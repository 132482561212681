<app-widget-base
  [type]="type"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div
    class="button-group-widget-wrapper"
    [ngClass]="[buttonLayout === 'vertical' ? 'button-group-vertical' : 'button-group-horizontal']"
    [class.profile-widget]="isGroupWidget"
  >
    <button
      [id]="button.id"
      #buttonRef
      mat-button
      class="coloured-button"
      *ngFor="let button of buttonsWithStatus"
      [ngClass]="['mybutton', button.colour, button.status]"
      (click)="press(button, $event)"
      [matTooltip]="button.name"
      buttonLayout="buttonLayout"
      [matTooltipDisabled]="!button.overflowing"
    >
      <div *ngIf="button.status === 'running'" class="loading-content">
        <div class="loading-spinner">
          <app-loading-spinner
            class="spinner"
            [isLoading]="button.status === 'running'"
            theme="light"
            icon="arrows"
          ></app-loading-spinner>
        </div>
        <div class="kicker">
          <span class="kicker-text">RUNNING</span>
        </div>
        <div class="title">{{ button.name }}</div>
      </div>
      <span *ngIf="button.status !== 'running'">
        {{ button.name }}
      </span>
    </button>
  </div>
</app-widget-base>
