<div class="command">
  <form [formGroup]="form" (submit)="onSubmit()">
    <div class="row align-items-start">
      <!-- Control Name -->
      <div class="col-3">
        <ng-container *ngIf="isEditing">
          <mat-form-field>
            <input matInput type="text" formControlName="id" placeholder="Control Name" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!isEditing">
          <span>{{ control?.id }}</span>
        </ng-container>
      </div>

      <!-- Control Actions -->
      <div class="col-5">
        <ng-container *ngIf="!isEditing">
          <div *ngFor="let action of control.actions">
            <span>{{ action?.type }}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="isEditing">
          <ng-container *ngFor="let action of actions?.controls; let idx = index">
            <div class="item-form-group" formArrayName="actions">
              <div [formGroupName]="idx">
                <div class="row">
                  <div class="col">
                    <!-- Drop Down List -->

                    <mat-form-field>
                      <mat-select
                        placeholder="Action Type"
                        formControlName="type"
                        #typeSelector="matSelect"
                        (selectionChange)="onActionTypeSelectionChange($event, idx)"
                      >
                        <ng-container *ngFor="let controlAction of controlActions">
                          <mat-option *appAdminOnly="controlAction.adminOnly" [value]="controlAction.type">
                            {{ controlAction.name }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>

                    <!-- <pre>{{typeSelector.selected?.value | json}}</pre> -->

                    <!-- Metadata -->
                    <ng-container [ngSwitch]="typeSelector?.selected?.['value']">
                      <!-- <span>{{typeSelector.selected?.value}}</span> -->

                      <!-- MAVLink -->
                      <ng-container *ngSwitchCase="'agent-mavlink'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="destination" placeholder="Destination" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload Template</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="2"
                              placeholder="Payload Template"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- ROS Bridge -->
                      <ng-container *ngSwitchCase="'agent-rosbridge'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="destination" placeholder="Destination" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload Template</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="2"
                              placeholder="Payload Template"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- ROS -->
                      <ng-container *ngSwitchCase="'agent-ros'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="destination" placeholder="Destination" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Metadata</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="3"
                              placeholder="Metadata"
                              name="meta"
                              formControlName="meta"
                            ></textarea>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload Template</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="2"
                              placeholder="Payload Template"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- HTTP Control -->
                      <ng-container *ngSwitchCase="'agent-http'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="destination" placeholder="Destination" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload Template</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="8"
                              placeholder="Payload Template"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- TCP Control -->
                      <ng-container *ngSwitchCase="'agent-tcp'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="destination" placeholder="Destination" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload Template</mat-label>
                            <textarea
                              class="json"
                              matInput
                              rows="3"
                              placeholder="Payload Template"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- Slack Message -->
                      <ng-container *ngSwitchCase="'server-slack'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="text" formControlName="webhook" placeholder="Webhook URL" />
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Message Attachment</mat-label>
                            <textarea
                              matInput
                              rows="2"
                              placeholder="Message Attachment"
                              name="message"
                              formControlName="message"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- Function -->
                      <ng-container *ngSwitchCase="'server-function'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <!-- <input matInput type="text" formControlName="functionId" placeholder="Function Id" /> -->

                            <mat-select placeholder="Action Type" formControlName="functionId">
                              <ng-container *ngFor="let func of functions">
                                <mat-option [value]="func.id">
                                  {{ func.name }}
                                </mat-option>
                              </ng-container>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>Payload</mat-label>
                            <textarea
                              matInput
                              rows="2"
                              placeholder="Payload"
                              name="payload"
                              formControlName="payload"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <!-- Client Delay -->
                      <ng-container *ngSwitchCase="'client-delay'">
                        <div formGroupName="metadata">
                          <mat-form-field>
                            <input matInput type="number" formControlName="waitForMilliseconds" placeholder="Delay" />
                            <mat-hint>Milliseconds</mat-hint>
                          </mat-form-field>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div class="col-auto">
                    <button type="button" mat-icon-button (click)="onRemoveAction(idx)">
                      <span class="ri-delete"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" mat-icon-button (click)="onInsertNewAction(idx + 1)" [matTooltip]="'Insert action'">
              <span class="ri-add"></span>
            </button>
          </ng-container>

          <!-- Display a button when there is no actions at all -->
          <ng-container *ngIf="actions.controls && actions.controls.length === 0">
            <button type="button" mat-icon-button (click)="onAddNewAction()">
              <span class="ri-add"></span>
            </button>
          </ng-container>
        </ng-container>
      </div>

      <!-- Control Params -->
      <div class="col-4">
        <div class="row align-items-center">
          <div class="col">
            <ng-container *ngIf="!isEditing">
              <div *ngFor="let param of control.parameters">
                <span>{{ param?.name }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="isEditing">
              <div
                class="item-form-group"
                *ngFor="let param of parameters?.controls; let idx = index"
                formArrayName="parameters"
              >
                <div [formGroupName]="idx">
                  <div class="row align-items-center">
                    <div class="col">
                      <mat-form-field>
                        <input matInput type="text" formControlName="name" placeholder="Parameter Name" />
                      </mat-form-field>
                    </div>
                    <div class="col-auto">
                      <button type="button" mat-icon-button (click)="onRemoveParam(idx)">
                        <span class="ri-delete"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" mat-icon-button (click)="onAddNewParam()">
                <span class="ri-add"></span>
              </button>
            </ng-container>
          </div>

          <!-- Editing -->
          <div class="col-auto">
            <ng-container *ngIf="isOverridden || (!isOverridden && currentModule !== 'robot')">
              <button *ngIf="isEditing" mat-flat-button color="primary" (click)="onSubmit()">Save</button>
              <button type="button" *ngIf="isEditing" mat-stroked-button class="ml-2" (click)="onCancel()">
                Cancel
              </button>

              <button *ngIf="!isEditing" mat-icon-button (click)="onEdit()" matTooltip="Edit">
                <span class="ri-edit"></span>
              </button>
              <button type="button" *ngIf="!isEditing" mat-icon-button (click)="onRemoveControl()" matTooltip="Delete">
                <span class="ri-delete"></span>
              </button>
            </ng-container>
            <ng-container *ngIf="currentModule === 'robot' && !isOverridden">
              <div matTooltip="Defined in Profile">
                <span class="rs-grey-icon rs-icon-button-padding ri-robot-definition"></span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <pre>{{form && form.value | json}}</pre> -->
<!-- <pre>{{controlActions | json}}</pre> -->
