import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { filesize } from 'filesize';

type FileSizeOptions = Exclude<Parameters<typeof filesize>[1], 'output'>;

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  private static transformOne(value: number, options?: FileSizeOptions): string {
    return filesize(value, this.enforceStringOutput(options)) as string;
  }

  private static enforceStringOutput(options?: FileSizeOptions): Parameters<typeof filesize>[1] {
    return {
      ...options,
      output: 'string',
    };
  }

  transform(value: number | number[], options?: FileSizeOptions) {
    if (Array.isArray(value)) {
      return value.map((val) => FileSizePipe.transformOne(val, options));
    }

    return FileSizePipe.transformOne(value, options);
  }
}
