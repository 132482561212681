<ng-container *transloco="let tCommon; read: 'common'">
  <!-- Account Button -->
  <button
    class="account-btn"
    *ngIf="accountButtonLabel"
    mat-button
    [routerLink]="['/account', currentAccountId]"
    appTestID="account-button"
  >
    <span class="icon ri-organization mr-1 rs-grey-icon"></span>
    <span>{{ accountButtonLabel }}</span>
  </button>

  <!-- Project Button -->
  <button mat-button [matMenuTriggerFor]="projectPicker" (menuOpened)="onMenuOpened()" appTestID="project-button">
    <span class="icon ri-project mr-1 rs-grey-icon"></span>
    <span>{{ buttonLabel }}</span>
    <span class="ri-nav-down"></span>
  </button>

  <mat-menu #projectPicker="matMenu" class="projects-menu">
    <!-- Account -->
    <ng-container *ngFor="let account of accounts; trackBy: trackByAccountId">
      <div
        class="account"
        *ngIf="account && ((account.projects && account.projects.length > 0) || account.isOwner)"
        appTestID="projects-menu"
      >
        <div class="account-header">
          <span
            class="left-title"
            [routerLink]="['/account', account.id]"
            routerLinkActive="active"
            (click)="onClickAccount(account)"
          >
            <div>
              <span class="icon ri-organization"></span>
              <span class="title" appTestID="project-title">{{ account.name }}</span>
            </div>

            <div class="id">
              ID: <span>{{ account.id }}</span>
            </div>
          </span>

          <!-- Add New Project -->
          <a
            class="right-button left-icon-button"
            *ngIf="account.isOwner"
            mat-stroked-button
            color="primary"
            [routerLink]="['/account', account.id]"
            [queryParams]="{ create: true }"
          >
            <span class="icon ri-add"></span>
            <span class="text">
              {{ tCommon('newProject') }}
            </span>
          </a>
        </div>

        <!-- Projects -->
        <div
          class="project"
          matRipple
          *ngFor="let project of account.projects; trackBy: trackByProjectId"
          [routerLink]="['/project', project.id]"
          routerLinkActive="active"
        >
          <div>
            <span class="icon ri-project"></span>
            <span>{{ project.name }}</span>
          </div>
          <div class="description">
            <span>{{ project.description }}</span>
          </div>
          <div class="id">
            ID:
            <span>{{ project.id }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>
