import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import type { GamepadControl, GamepadUxComponent } from '../../models';
import { Project } from '../../models';
import { RocosClientService } from '../rocos-client';
import { RocosSdkClientService } from '../rocos-sdk-client';
import type { ExternalProject, NewProjectRequest } from '@dronedeploy/rocos-js-sdk';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public projectsUpdated: Subject<void>;
  defaultCollectionId = '_default';

  /** Local cache of project permissions */
  private projectPermissions: {
    [projectId: string]: string[];
  } = {};

  constructor(private rocosClientService: RocosClientService, private sdk: RocosSdkClientService) {
    this.projectsUpdated = new Subject<void>();
  }

  public listExternalProjects(accountId: string): Promise<ExternalProject[]> {
    return this.sdk.client.getProjectService().listExternalProjects(accountId);
  }

  /**
   * Update project information.
   *
   * @param projId Project Id
   * @param proj Project object
   */
  public update(projId: string, proj: Project): Observable<any> {
    return this.rocosClientService.rocosClient.project.update(projId, proj as any).pipe(
      map((res) => {
        this.projectsUpdated.next(null);

        return res.data;
      }),
    );
  }

  /**
   * Get project information by project id.
   *
   * @param projId Project Id
   */
  public info(projId: string): Observable<Project> {
    return this.rocosClientService.rocosClient.project.info(projId).pipe(
      map((res) => {
        return Project.fromModel(res.data as any);
      }),
    );
  }

  /**
   * Delete project by account id and project id.
   */
  public delete(accountId: string, projectId: string): Observable<any> {
    return this.rocosClientService.rocosClient.project.delete(accountId, projectId).pipe(
      map((res) => {
        this.projectsUpdated.next(null);
        return res.data as any;
      }),
    );
  }

  /**
   * List projects by account id.
   *
   * @param accountId Account Id
   */
  public listByAccount(accountId): Observable<Project[]> {
    return this.rocosClientService.rocosClient.project.projectsUnderAccount(accountId).pipe(
      map((res) => {
        const data = res.data as any[];

        return data.map((proj) => {
          return Project.fromModel(proj);
        });
      }),
    );
  }

  /**
   * Create project by template id
   *
   * @param templateId Template ID
   * @param params Optional parameters
   */
  public createProjectByTemplate(templateId: string, params?: NewProjectRequest): Observable<void> {
    return from(this.sdk.client.getProjectService().createProjectByTemplate(templateId, params)).pipe(
      tap(() => {
        // Emit project updated message out.
        this.projectsUpdated.next(null);
      }),
    );
  }

  public createDoc(projId: string, collectionId: string, doc: any): Observable<any> {
    return this.rocosClientService.rocosClient.dashboard.createDoc(projId, collectionId, doc).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getDocs(projId: string, collectionId: string): Observable<any> {
    return this.rocosClientService.rocosClient.dashboard.getDocs(projId, collectionId).pipe(
      map((res) => {
        return res.data as any[];
      }),
    );
  }

  public deleteDocs(projId: string, collectionId: string, docIds: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.dashboard.deleteDocs(projId, collectionId, docIds).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public getProjectDefinition(projId: string): Observable<any> {
    return this.getDocs(projId, this.defaultCollectionId).pipe(
      map((res) => {
        let def = null;

        if (res?.id && res.docs?.length > 0) {
          def = res.docs[0];
        }

        return def;
      }),
    );
  }

  public updateProjectDefinition(projId: string, doc: any): Observable<any> {
    return this.createDoc(projId, this.defaultCollectionId, doc);
  }

  /**
   * Get Operation Pages
   *
   * @deprecated Please use the operation service `list()` method.
   * @param projId Project Id
   */
  public getOperationPages(projId: string): Observable<any[]> {
    return this.getDocs(projId, this.defaultCollectionId).pipe(
      map((res) => {
        let def = null;

        if (res?.id && res.docs?.length > 0) {
          def = res.docs[0];
        }

        let pages = [];

        if (def?.['operationPages']) {
          pages = def['operationPages'];
        }

        return pages;
      }),
    );
  }

  /**
   * Get Operation Page
   *
   * @deprecated Please use the operation service `getOne()` method.
   * @param projId Project Id
   * @param pageId Operation page Id
   */
  public getOperationPage(projId: string, pageId: string): Observable<any> {
    return this.getOperationPages(projId).pipe(
      map((list) => {
        let targetPage = null;

        if (list?.length > 0) {
          list.forEach((page) => {
            if (page.name === pageId) {
              targetPage = page;
            }
          });
        }

        return targetPage;
      }),
    );
  }

  /**
   * Update the operation page config
   *
   * @deprecated Please use the operation service `update()` method.
   * @param projId Project Id
   * @param pageId Page Id
   * @param pageConfig Config
   */
  public updateOperationPageConfig(projId: string, pageId: string, pageConfig: any): Observable<any> {
    return this.getProjectDefinition(projId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.operationPages) {
          doc['operationPages'] = [];
        }

        const list = doc['operationPages'];

        if (list?.length > 0) {
          list.forEach((page) => {
            if (page.name === pageId) {
              page.config = pageConfig;
            }
          });
        }

        return this.updateProjectDefinition(projId, doc);
      }),
    );
  }

  public checkPermission(projId: string, permission: string, forceRemote: boolean = false): Observable<boolean> {
    return this.getLocalPermissions(projId).pipe(
      concatMap((permissions) => {
        if (permissions && !forceRemote) {
          return this.checkLocalPermission(projId, permission);
        } else {
          return this.checkRemotePermission(projId, permission);
        }
      }),
    );
  }

  public updatePermissions(projId: string, permissions: string[]): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.permissions) {
          doc['permissions'] = [];
        }

        doc.permissions = permissions ? permissions : [];

        // Update local cache
        this.projectPermissions[projId] = permissions;

        return this.updateProjectDefinition(projId, doc);
      }),
    );
  }

  public getPermissions(projId: string, asList: boolean = false, forceRemote: boolean = false): Observable<any> {
    return this.getLocalPermissions(projId).pipe(
      concatMap((permissions) => {
        if (permissions && !forceRemote) {
          return this.getLocalPermissions(projId, asList);
        } else {
          return this.getRemotePermissions(projId, asList);
        }
      }),
    );
  }

  public getRemotePermissions(projId: string, asList: boolean = true): Observable<string[] | any> {
    return this.getProjectDefinition(projId).pipe(
      map((doc) => {
        if (!doc) {
          doc = {};
        }

        if (!doc.permissions) {
          doc.permissions = [];
        }

        const permissions = (doc.permissions as string[]) || [];
        const accessableVirtualRobotTemplateIds = (doc.accessableVirtualRobotTemplateIds as string[]) || [];

        // Update local cache
        this.projectPermissions[projId] = [...permissions, ...accessableVirtualRobotTemplateIds];

        if (!asList) {
          const obj = {};
          if (permissions) {
            permissions.forEach((p) => {
              obj[p] = true;
            });
          }

          return obj;
        } else {
          return permissions;
        }
      }),
    );
  }

  public updateUxComponentsToProjectDefinition(
    projectId: string,
    robotDefinitionId: string,
    uxComponents: GamepadUxComponent[],
  ): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.robotDefinitions) {
          doc['robotDefinitions'] = [];
        }

        let robotDef = null;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions.forEach((def) => {
            if (def.id === robotDefinitionId) {
              robotDef = def;
            }
          });
        }

        if (!robotDef) {
          robotDef = {};
        }

        robotDef.uxComponents = uxComponents;

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }

  public updateClientTriggersToProjectDefinition(
    projectId: string,
    robotDefinitionId: string,
    clientTriggers: any[],
  ): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.robotDefinitions) {
          doc['robotDefinitions'] = [];
        }

        let robotDef = null;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions.forEach((def) => {
            if (def.id === robotDefinitionId) {
              robotDef = def;
            }
          });
        }

        if (!robotDef) {
          robotDef = {};
        }

        robotDef.clientTriggers = clientTriggers;

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }

  public updateControlsToProjectDefinition(
    projectId: string,
    robotDefinitionId: string,
    controls: GamepadControl[],
  ): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.robotDefinitions) {
          doc['robotDefinitions'] = [];
        }

        let robotDef = null;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions.forEach((def) => {
            if (def.id === robotDefinitionId) {
              robotDef = def;
            }
          });
        }

        if (!robotDef) {
          robotDef = {};
        }

        robotDef.controls = controls;

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }

  public updateOperationPagesToProjectDefinition(projectId: string, operationPages: any[]): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.operationPages) {
          doc['operationPages'] = [];
        }

        doc['operationPages'] = operationPages;

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }

  public removeTriggerByComponentAndThenUpdateToProjectDefinition(
    projectId: string,
    robotDefinitionId: string,
    component: GamepadUxComponent,
  ): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.robotDefinitions) {
          doc['robotDefinitions'] = [];
        }

        let robotDef = null;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions.forEach((def) => {
            if (def.id === robotDefinitionId) {
              robotDef = def;
            }
          });
        }

        if (!robotDef) {
          robotDef = {};
        }

        const updatedClientTriggers = [];
        if (robotDef.clientTriggers?.length > 0) {
          robotDef.clientTriggers.forEach((trigger) => {
            let isComponentTrigger = false;
            if (trigger?.widgetConditions && trigger.widgetConditions.length === 1) {
              const first = trigger.widgetConditions[0];

              if (first.controlType === component.controlType && first.id === component.id) {
                isComponentTrigger = true;
              }
            }

            if (!isComponentTrigger) {
              updatedClientTriggers.push(trigger);
            }
          });
        }

        robotDef.clientTriggers = updatedClientTriggers;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions = doc.robotDefinitions.map((def) => {
            if (def.id === robotDefinitionId) {
              return robotDef;
            } else {
              return def;
            }
          });
        }

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }
  public removeComponentAndThenUpdateToProjectDefinition(
    projectId: string,
    robotDefinitionId: string,
    component: GamepadUxComponent,
  ): Observable<any> {
    // Get Def first
    return this.getProjectDefinition(projectId).pipe(
      concatMap((doc) => {
        if (!doc) {
          doc = {};
        }
        if (!doc.robotDefinitions) {
          doc['robotDefinitions'] = [];
        }

        let robotDef = null;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions.forEach((def) => {
            if (def.id === robotDefinitionId) {
              robotDef = def;
            }
          });
        }

        if (!robotDef) {
          robotDef = {};
        }

        const updatedComponents = [];
        if (robotDef.uxComponents?.length > 0) {
          robotDef.uxComponents.forEach((comp) => {
            if (comp.id !== component.id) {
              updatedComponents.push(comp);
            }
          });
        }

        robotDef.uxComponents = updatedComponents;

        if (doc.robotDefinitions.length > 0) {
          doc.robotDefinitions = doc.robotDefinitions.map((def) => {
            if (def.id === robotDefinitionId) {
              return robotDef;
            } else {
              return def;
            }
          });
        }

        return this.updateProjectDefinition(projectId, doc);
      }),
    );
  }

  private getLocalPermissions(projId: string, asList: boolean = true): Observable<string[] | any> {
    return Observable.create((observer) => {
      let permissions = null;
      if (this.projectPermissions[projId]) {
        permissions = this.projectPermissions[projId];
      }

      let res = permissions;

      if (!asList) {
        res = {};
        if (permissions) {
          permissions.forEach((p) => {
            res[p] = true;
          });
        }
      }

      observer.next(res);
      observer.complete();
    });
  }

  private checkLocalPermission(projId: string, permission: string): Observable<boolean> {
    return Observable.create((observer) => {
      let res = false;
      const permissions = this.projectPermissions[projId];
      res = permissions?.includes(permission);

      observer.next(res);
      observer.complete();
    });
  }

  private checkRemotePermission(projId: string, permission: string): Observable<boolean> {
    return this.getRemotePermissions(projId).pipe(
      map((permissions) => {
        return !!permissions.includes(permission);
      }),
    );
  }
}
