import { WidgetBase, WidgetDefaultSize } from './widget-base';

export class WidgetDonutGauge extends WidgetBase {
  public minimumValue: number;
  public maximumValue: number;

  public static fromModel(model: any): WidgetDonutGauge {
    const widget = new WidgetDonutGauge();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.rows = model.rows ? model.rows : WidgetDefaultSize.rows;
    this.cols = model.cols ? model.cols : WidgetDefaultSize.cols;

    this.minimumValue = model.minimumValue;
    this.maximumValue = model.maximumValue;
  }
}
