import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import type { IsLoading } from '@shared/interfaces';
import type { InvitationStatus } from '@shared/models';
import { UserService, ToastService, AuthService, GotoService } from '@shared/services';

@Component({
  selector: 'app-invitations-dialog',
  templateUrl: './invitations-dialog.component.html',
  styleUrls: ['./invitations-dialog.component.scss'],
})
export class InvitationsDialogComponent implements OnInit, IsLoading {
  public invitations: InvitationStatus[];
  public invitationsSent: InvitationStatus[] = [];
  public invitationsReceived: InvitationStatus[] = [];

  public defaultSelectedTabIndex: number = 1;

  public isLoading = false;

  public displayedColumns = ['email', 'created', 'type', 'accepted'];
  public displayedColumnsForReceived = ['sentFrom', 'created', 'type', 'acceptedStatus', 'accepted'];

  public constructor(
    public dialogRef: MatDialogRef<InvitationsDialogComponent>,
    private userService: UserService,
    private toast: ToastService,
    private authService: AuthService,
    private go: GotoService,
  ) {}

  public ngOnInit(): void {
    this.loadInvitationsList();
  }

  public onAccept(invitation: InvitationStatus): void {
    invitation.isLoading = true;

    this.dialogRef.disableClose = true;

    this.userService.acceptInvitation(invitation.id).subscribe(
      () => {
        this.refreshToken(() => {
          this.toast.short('Accepted.', null, 'success');
          this.loadInvitationsList();
          invitation.isLoading = false;
          this.dialogRef.disableClose = false;
        });
      },
      (err) => {
        this.toast.short(`Failed. ${err.message}`, null, 'failure');

        invitation.isLoading = false;
        this.dialogRef.disableClose = false;
      },
    );
  }

  public onViewLinkClick(invite: InvitationStatus): void {
    const type = invite.type;

    const list = invite.accountInviteList;
    const projectId = list[0].projectId;
    const accountId = list[0].accountId;

    switch (type) {
      case 'ACCOUNT':
        this.go.accountIndexPage(accountId);
        break;
      case 'PROJECT':
        this.go.projectIndex(projectId);
        break;
    }

    this.dialogRef.close();
  }

  public onCancel(): void {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

  public isExpired(invite: InvitationStatus): boolean {
    return new Date(invite.expiry) < new Date();
  }

  private loadInvitationsList() {
    this.isLoading = true;

    this.userService.listInvitations().subscribe(
      (invitations) => {
        const sortDescFunc = (a: InvitationStatus, b: InvitationStatus) => {
          return a.createdAt < b.createdAt ? 1 : -1;
        };

        const received = (invitations.received || [])
          .filter((i) => {
            return i.type === 'PROJECT' || i.type === 'ACCOUNT'; // Only Project type should be displayed.
          })
          .sort(sortDescFunc);
        const sent = (invitations.sent || []).sort(sortDescFunc);

        this.invitations = [...received, ...sent];

        this.invitationsReceived = received;
        this.invitationsSent = sent;
      },
      () => {
        // Do Nothing
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  private refreshToken(callback: () => void) {
    this.userService.getNewToken().subscribe((token) => {
      this.authService.updateNewToken(token);

      callback();
    });
  }
}
