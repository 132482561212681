import type { IResolution } from './../../directives/coordinate-picker.directive';
import { WidgetBase } from './widget-base';

export class WidgetImage extends WidgetBase {
  public override callsign?: string;
  public pickerCommandId?: string;
  public pickerCommandParameters?: Record<string, string>;
  public pickerVideoResolution?: IResolution;
  public pickerAnimation?: boolean;
  public rotation?: number;

  public static fromModel(model: WidgetImage): WidgetImage {
    const widget = new WidgetImage();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: WidgetImage): void {
    super.readValuesFromModel(model);

    // Images
    if (model.callsign) {
      this.callsign = model.callsign as string;
    }
    if (model.rotation) {
      this.rotation = Number(model.rotation);
    }
    if (model.pickerCommandId) {
      this.pickerCommandId = model.pickerCommandId as string;
      this.pickerCommandParameters = model.pickerCommandParameters as Record<string, string>;
      this.pickerVideoResolution = model.pickerVideoResolution as IResolution;
      this.pickerAnimation = model.pickerAnimation as boolean;
    }
  }
}
