import type { RobotTypeOption } from '../robot';
import { GamepadButtonTriggerType } from './gamepad-button';

export type ScreenControlType = 'button';

export class ScreenControlValue {
  id: number;
  value: any;
  label: string;

  // Default selected for selection list
  defaultSelected?: boolean;

  constructor(id: number, label: string, value: any, defaultSelected = false) {
    this.id = id;
    this.value = value;
    this.label = label;
    this.defaultSelected = defaultSelected;
  }
}

export class ScreenButtonDef {
  name: string;
  text: string;
  description?: string;
  controlType: ScreenControlType = 'button';
  robotType: RobotTypeOption;
  values: ScreenControlValue[] = [];
  currentValue: ScreenControlValue;

  constructor(
    name: string,
    text: string,
    robotType: RobotTypeOption,
    controlType: ScreenControlType = 'button',
    description?: string,
    values: ScreenControlValue[] = [],
  ) {
    this.name = name;
    this.text = text;
    this.robotType = robotType;
    this.controlType = controlType;
    this.description = description;
    this.values = values;

    // Set default value to first element of values
    if (this.values?.length > 0) {
      this.currentValue = this.values[0];
    }
  }
}

export class ScreenButtonActionDef {
  /** Action name */
  name: string;
  buttonName: string;
  frequency: number;
  triggerType: GamepadButtonTriggerType;

  constructor(name?: string, buttonName?: string, frequency?: number, triggerType?: GamepadButtonTriggerType) {
    this.name = name;
    this.buttonName = buttonName;
    this.frequency = frequency !== undefined ? frequency : 100;
    this.triggerType = triggerType ? triggerType : GamepadButtonTriggerType.ONCE;
  }
}

export class ScreenControlPayloadDef {
  action?: ScreenButtonActionDef;
  actionName: string;
  destination: string;
  template: string;
  /** Generated message with controls definition */
  message: string = '';
  previousMessage: string = '';

  constructor(actionName?: string, destination?: string, template?: string, action?: ScreenButtonActionDef) {
    this.actionName = actionName;
    this.destination = destination;
    this.template = template;
    this.action = action;
  }

  updateMessageWithDefinitions(defs: ScreenButtonDef[]) {
    if (defs && this.template) {
      let message = this.template;
      defs.forEach((def) => {
        if (def?.currentValue) {
          const variableName = def.name;
          const value = def.currentValue.value;

          message = message.replace(`$${variableName}`, value);
        }
      });

      this.message = message;
    }
  }
}
