<div class="widget-audio">
  <app-widget-base
    [heading]="heading"
    [type]="type"
    [dataURI]="dataURI"
    [editing]="editing"
    (deleted)="onDelete()"
    [height]="height"
    [width]="width"
    [isGroupWidget]="isGroupWidget"
    (editClicked)="onEdit()"
    (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
    [layerIndex]="layerIndex"
    [status]="status"
    [theme]="theme"
    [toolbarItems]="toolbarItems"
    (toolbarItemClick)="onToolbarItemClick($event)"
  >
    <!-- Value in widget-audio -->
    <!-- {{ value | json }} -->
    <div class="audio-widget-wrapper" [class.profile-widget]="isGroupWidget">
      <app-audio-widget
        [value]="extractedValue"
        [sampleFormat]="sampleFormat"
        [sampleRate]="sampleRate"
        [encodingFormat]="encodingFormat"
        [numberOfChannel]="numberOfChannel"
        [shouldConnect]="shouldConnect"
        (audioStatusChanged)="onAudioStatusChanged($event)"
      >
      </app-audio-widget>
    </div>
    <ng-container *ngIf="currentConnectionStatus === connectionStatus.CONNECTING">
      <div class="center-loading-spinner">
        <app-loading-spinner [isLoading]="true" [theme]="'light'" [size]="'x2'"></app-loading-spinner>
      </div>
    </ng-container>
  </app-widget-base>
</div>
