import type { IUserInfo } from '../../interfaces';
import { UserPermission } from './user-permission';
import { Account } from '../account';

export class User implements IUserInfo {
  fullId?: string;
  firstName: string;
  lastName: string;
  accounts?: Account[];
  token?: string;
  activeAccount?: Account;
  defaultAccount?: Account;

  email: string;
  emailVerified: boolean;
  updatedAt: Date;
  name: string;
  picture: string;
  userId: string;
  nickname: string;
  createdAt: Date;
  sub: string;

  /**
   * Admin permission
   */
  isAdmin: boolean;

  permissions: UserPermission;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Checks if user is a legacy user (non-DD users).
   */
  get isLegacyUser(): boolean {
    return !this.fullId?.startsWith('dd|');
  }

  /**
   * Returns ID without the DD prefix if it exists.
   */
  get id(): string | undefined {
    return this.fullId?.replace(/^dd\|/, '');
  }

  /**
   * Convert the Auth0 payload to User class
   *
   * @param payload Auth0 payload response content
   */
  public static fromPayload(payload: any): User {
    const user = new User();

    if (payload) {
      user.email = payload.email;
      user.emailVerified = payload.email_verified;
      user.updatedAt = payload.updated_at;
      user.name = payload.name;
      user.picture = payload.picture;
      user.userId = payload.user_id;
      user.nickname = payload.nickname;
      user.createdAt = payload.created_at;
      user.sub = payload.sub;

      if (payload.app_metadata) {
        user.permissions = UserPermission.fromMetadata(payload.app_metadata);
      }
    }

    return user;
  }

  public static fromModel(model: IUserInfo): User {
    const user = new User();

    if (!model) return user;

    const activeAccountId = model.activeAccount?.id ? model.activeAccount.id : null;

    user.firstName = model.firstName;
    user.lastName = model.lastName;
    user.email = model.email;
    user.isAdmin = model.isAdmin;

    if (model.id) user.fullId = model.id;

    if (!model.accounts) return user;

    user.accounts = model.accounts.map((account) => {
      const accountModel = Account.fromModel(account);

      // Set active account if found.
      if (activeAccountId && account.id === activeAccountId) {
        user.activeAccount = accountModel;
      }

      return accountModel;
    });

    if (user.activeAccount) {
      // Make the active account as default account.
      user.defaultAccount = user.activeAccount;
    } else if (user.accounts?.length > 0) {
      // Or make the first account which contains at least one project as default account.
      const accountsContainProjects = user.accounts.filter((account) => {
        return account.projects?.length > 0;
      });

      if (accountsContainProjects?.length > 0) {
        user.defaultAccount = accountsContainProjects[0];
      } else {
        user.defaultAccount = user.accounts[0];
      }
    } else {
      user.defaultAccount = null;
    }

    return user;
  }

  getAnAvailableAccount(ignoredProjectIds: string[] = []): Account {
    let account = null;

    const isAccountValid = (acc: Account): boolean => {
      const projects = acc.projects;

      if (projects?.length > 0) {
        const filtered = projects.filter((proj) => {
          return !ignoredProjectIds.includes(proj.id);
        });

        if (filtered?.length > 0) {
          return true;
        }
      }

      // If current user is the owener of account, then it's a valid account.
      return acc.isOwner;
    };

    // Check the default account first.
    if (this.defaultAccount && isAccountValid(this.defaultAccount)) {
      account = this.defaultAccount;
    }

    if (account) return account;

    account = this.accounts?.find((acc) => isAccountValid(acc));

    return account;
  }
}
