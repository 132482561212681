<div class="rs-card">
  <div class="head">
    <div class="title">
      Button Log
      <span *ngIf="pressedKeyLog && pressedKeyLog.length > 0">
        <!-- [{{pressedKeyLog.length}}] -->
      </span>
    </div>
    <div class="right-buttons">
      <button mat-icon-button color="primary" matTooltip="Clear All" tabindex="-1" (click)="onClear()">
        <span class="ri-refresh icon-button grey-icon"></span>
      </button>
    </div>
  </div>

  <div class="body">
    <!-- <div class="log-list" #scrollMe [scrollTop]="pressedKeyLog.length > 0 ? scrollMe.scrollHeight : 0"> -->
    <div class="log-list">
      <div *ngFor="let key of pressedKeyLog" class="list-item">
        <span class="time">{{ key.timeStamp | json }}</span>
        {{ key.key | json }}
      </div>
    </div>
  </div>
</div>
