import type { ActivatedRouteSnapshot, Params } from '@angular/router';

export class RouterUtils {
  public static collectParams(root: ActivatedRouteSnapshot): Params {
    const params: Params = {};

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
      Object.assign(params, snapshot.params);
      snapshot.children.forEach((child) => mergeParamsFromSnapshot(child));
    })(root);

    return params;
  }

  public static firstChildrenPaths(root: ActivatedRouteSnapshot): string[] {
    const paths = [];

    const getFirstChildPath = (snapshot: ActivatedRouteSnapshot) => {
      if (snapshot?.firstChild) {
        const url = snapshot.firstChild.url;
        if (url?.length > 0) {
          const firstUrl = url[0];
          const path = firstUrl.path;

          paths.push(path);
        }

        getFirstChildPath(snapshot.firstChild);
      }
    };

    getFirstChildPath(root);
    return paths;
  }

  /**
   * Open an external url
   *
   * @param url External URL
   * @param target '_blank': for new tab, '_self' for current tab
   */
  public static openExternalUrl(url: string, target: string = '_blank') {
    const isTrusted = this.isTrustedDomain(url);

    if (window) {
      window.open(url, target, isTrusted ? '' : 'noopener noreferrer');
    }
  }

  public static isTrustedDomain(address: string) {
    return this.isDroneDeployDomain(address);
  }

  public static isDroneDeployDomain(address: string) {
    const url = new URL(address, location.href);
    const hostname = url.hostname;

    return hostname.endsWith('dronedeploy.com');
  }

  public static isRocosDomain(address: string, regexPattern: RegExp = /.*\.rocos\.io$|rocos\.io$/) {
    const url = new URL(address, location.href);
    const hostname = url.hostname;

    return regexPattern.test(hostname);
  }

  public static isPortalPath(path: string, regexPattern: RegExp = /^\/.*$/) {
    return regexPattern.test(path);
  }
}
