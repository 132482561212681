import type { Subject } from 'rxjs';

export interface VideoSource {
  playoutDelayHint: string;
  videoId: string;
  isVideoStreaming: Subject<boolean>;
  isVideoConnecting: Subject<boolean>;
  videoServerSuccess: Subject<boolean>;
  videoSourceStatus: Subject<VideoSourceStatus>;
  stream: Subject<MediaStream>;
  videoCommand: string;
  allowDebug: boolean;
  message: string;

  initConnection(videoServer: string): void;
  startStream(projectId: string): void;
  stopStream(): void;
  dispose(): void;
  setStatusUpdateFrequency(internal: number): void;
}

export interface RoomStatus {
  operators: number;
  robots: number;
}

export interface VideoSourceStatus {
  webrtcPeerConnectionStatus: boolean;
  webrtcIceStateConnected: boolean;
  webrtcIceConnectionState: string;
  webrtcStreamStarted: boolean;
  webrtcBitrate: string;
  realInterval: number;
  webrtcAudioIn: boolean;
  webrtcVideoIn: boolean;
  webrtcAudioOut: boolean;
  webrtcVideoOut: boolean;
  webrtcSlow: boolean;
  webrtcRoundTripTimeMs: number;
  webrtcPacketLossPercent: number;
  webrtcFramesDropped: number;
  webrtcFramesDecoded: number;
  webrtcFramesReceived: number;
  jitterBufferDelay: number;
  jitterBufferEmittedCount: number;
  avgFramePlayout: number;
}

export enum VideoSourceStatusEnum {
  webrtcPeerConnectionStatus = 'webrtcPeerConnectionStatus',
  webrtcIceStateConnected = 'webrtcIceStateConnected',
  webrtcIceConnectionState = 'webrtcIceConnectionState',
  webrtcStreamStarted = 'webrtcStreamStarted',
  webrtcBitrate = 'webrtcBitrate',
  realInterval = 'realInterval',
  webrtcAudioIn = 'webrtcAudioIn',
  webrtcVideoIn = 'webrtcVideoIn',
  webrtcAudioOut = 'webrtcAudioOut',
  webrtcVideoOut = 'webrtcVideoOut',
  webrtcSlow = 'webrtcSlow',
  webrtcRoundTripTimeMs = 'webrtcRoundTripTimeMs',
  webrtcPacketLossPercent = 'webrtcPacketLossPercent',
  webrtcFramesDropped = 'webrtcFramesDropped',
  webrtcFramesDecoded = 'webrtcFramesDecoded',
  webrtcFramesReceived = 'webrtcFramesReceived',
  jitterBufferDelay = 'jitterBufferDelay',
  jitterBufferEmittedCount = 'jitterBufferEmittedCount',
  avgFramePlayout = 'avgFramePlayout',
}

export interface StatsResult {
  encryption: string;
  audio: {
    send: {
      tracks: string[];
      codecs: string[];
      availableBandwidth: number;
      streams: number;
      framerateMean: number;
      bitrateMean: number;
    };
    recv: {
      tracks: string[];
      codecs: string[];
      availableBandwidth: number;
      streams: number;
      framerateMean: number;
      bitrateMean: number;
    };
    bytesSent: number;
    bytesReceived: number;
    latency: number;
    packetsReceived: number;
    packetsLost: number;
  };
  video: {
    send: {
      tracks: string[];
      codecs: string[];
      availableBandwidth: number;
      streams: number;
      framerateMean: number;
      bitrateMean: number;
    };
    recv: {
      tracks: string[];
      codecs: string[];
      availableBandwidth: number;
      streams: number;
      framerateMean: number;
      bitrateMean: number;
      frameWidth: number;
      frameHeight: number;
      framesReceived: number;
      framesDecoded: number;
      framesDropped: number;
      jitterBufferDelay: number;
      jitterBufferEmittedCount: number;
      avgFramePlayout: number;
    };
    bytesSent: number;
    bytesReceived: number;
    // latency: number,
    packetsReceived: number;
    packetsLost: number;
  };
  bandwidth: {
    systemBandwidth: number;
    sentPerSecond: number;
    encodedPerSecond: number;
    helper: {
      audioBytesSent: number;
      videoBytestSent: number;
    };
    speed: number;
    currentRoundTripTime: number;
    totalRoundTripTime: number;
  };
  connectionType: {
    local: {
      candidateType: '';
      ipAddress: '';
      port: number;
      priority: number;
      networkType: '';
    };
    remote: {
      candidateType: '';
      ipAddress: '';
      port: number;
      priority: number;
      networkType: '';
    };
  };
  resolutions: {
    send: {
      width: number;
      height: number;
    };
    recv: {
      width: number;
      height: number;
    };
  };
}

export const parseStats = (stats: any): StatsResult => {
  const result: StatsResult = {
    encryption: '',
    audio: {
      send: {
        tracks: [],
        codecs: [],
        availableBandwidth: 0,
        streams: 0,
        framerateMean: 0,
        bitrateMean: 0,
      },
      recv: {
        tracks: [],
        codecs: [],
        availableBandwidth: 0,
        streams: 0,
        framerateMean: 0,
        bitrateMean: 0,
      },
      bytesSent: 0,
      bytesReceived: 0,
      latency: 0,
      packetsReceived: 0,
      packetsLost: 0,
    },
    video: {
      send: {
        tracks: [],
        codecs: [],
        availableBandwidth: 0,
        streams: 0,
        framerateMean: 0,
        bitrateMean: 0,
      },
      recv: {
        tracks: [],
        codecs: [],
        availableBandwidth: 0,
        streams: 0,
        framerateMean: 0,
        bitrateMean: 0,
        frameWidth: 0,
        frameHeight: 0,
        framesReceived: 0,
        framesDecoded: 0,
        framesDropped: 0,
        jitterBufferDelay: 0,
        jitterBufferEmittedCount: 0,
        avgFramePlayout: 0,
      },
      bytesSent: 0,
      bytesReceived: 0,
      // latency: 0,
      packetsReceived: 0,
      packetsLost: 0,
    },
    bandwidth: {
      systemBandwidth: 0,
      sentPerSecond: 0,
      encodedPerSecond: 0,
      helper: {
        audioBytesSent: 0,
        videoBytestSent: 0,
      },
      speed: 0,
      currentRoundTripTime: 0,
      totalRoundTripTime: 0,
    },
    connectionType: {
      local: {
        candidateType: '',
        ipAddress: '',
        port: 0,
        priority: 0,
        networkType: '',
      },
      remote: {
        candidateType: '',
        ipAddress: '',
        port: 0,
        priority: 0,
        networkType: '',
      },
    },
    resolutions: {
      send: {
        width: 0,
        height: 0,
      },
      recv: {
        width: 0,
        height: 0,
      },
    },
  };

  let candidatePair: any;
  stats.forEach((r) => {
    if (r.type === 'candidate-pair' && r.nominated === true) {
      candidatePair = r;
    }
  });
  if (candidatePair) {
    let localCandidate: any;
    stats.forEach((r) => {
      if (r.type === 'local-candidate' && r.id === candidatePair.localCandidateId) {
        localCandidate = r;
      }
    });
    if (localCandidate) {
      result.connectionType.local.candidateType = localCandidate.candidateType;
      result.connectionType.local.ipAddress = localCandidate.ip;
      result.connectionType.local.port = localCandidate.port;
      // result.connectionType.local.protocol = localCandidate.protocol;
      result.connectionType.local.priority = localCandidate.priority;
      result.connectionType.local.networkType = localCandidate.networkType;
    }
    let remoteCandidate: any;
    stats.forEach((r) => {
      if (r.type === 'remote-candidate' && r.id === candidatePair.remoteCandidateId) {
        remoteCandidate = r;
      }
    });
    if (remoteCandidate) {
      result.connectionType.remote.candidateType = remoteCandidate.candidateType;
      result.connectionType.remote.ipAddress = remoteCandidate.ip;
      result.connectionType.remote.port = remoteCandidate.port;
      // result.connectionType.remote.protocol = remoteCandidate.protocol;
      result.connectionType.remote.priority = remoteCandidate.priority;
      result.connectionType.remote.networkType = remoteCandidate.networkType;
    }

    result.bandwidth.currentRoundTripTime = candidatePair.currentRoundTripTime;
    result.bandwidth.totalRoundTripTime = candidatePair.totalRoundTripTime;
  }

  stats.forEach((videoRtp) => {
    if (videoRtp.type !== 'inbound-rtp' || videoRtp.kind !== 'video') {
      return;
    }

    result.video.recv.tracks.push(videoRtp.id);
    result.video.bytesReceived += videoRtp.bytesReceived;
    result.video.packetsReceived += videoRtp.packetsReceived;
    result.video.packetsLost += videoRtp.packetsLost;

    stats.forEach((codecReport) => {
      if (codecReport.type !== 'codec' || codecReport.id !== videoRtp.codecId) {
        return;
      }

      result.video.recv.codecs.push(codecReport.mimeType);
    });

    stats.forEach((trackReport) => {
      if (trackReport.type !== 'track' || trackReport.id !== videoRtp.trackId) {
        return;
      }

      result.video.recv.frameWidth = trackReport.frameWidth;
      result.video.recv.frameHeight = trackReport.frameHeight;
      result.video.recv.framesReceived = trackReport.framesReceived;
      result.video.recv.framesDecoded = trackReport.framesDecoded;
      result.video.recv.framesDropped = trackReport.framesDropped;

      if (trackReport.type === 'track') {
        result.video.recv.jitterBufferDelay = trackReport.jitterBufferDelay;
        result.video.recv.jitterBufferEmittedCount = trackReport.jitterBufferEmittedCount;
      }
    });
  });

  return result;
};
