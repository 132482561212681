<div class="rs-card">
  <div class="head">
    <div class="title with-spinner">
      Buttons
      <span class="ml-2"> </span>
    </div>
    <div class="right-buttons">
      <button mat-flat-button class="left-icon-button" color="primary" (click)="onAddNew()">
        <span class="ri-add"></span>
        <span>Add Button</span>
      </button>
    </div>
  </div>

  <app-loading-content [isLoading]="isLoading">
    <div class="body p-0">
      <div class="components" *ngIf="items">
        <div>
          <div>
            <mat-checkbox
              [(ngModel)]="isDirectlyExecuteCommands"
              [matTooltip]="shouldEnableActivateToggle ? '' : 'Please select a preview robot first.'"
              >Show buttons for testing.</mat-checkbox
            >
          </div>
        </div>
        <div class="row command-buttons" *ngIf="isDirectlyExecuteCommands">
          <ng-container *ngFor="let item of items">
            <ng-container [ngSwitch]="item?.value?.controlType">
              <ng-container *ngSwitchCase="'button'">
                <div class="command-button">
                  <app-command-with-value-button
                    [loading]="buttonStatus && buttonStatus[item.id] && buttonStatus[item.id].isLoading"
                    (clicked)="onClick(item)"
                    [configGroupItem]="item.value"
                    [projectId]="projectId"
                  >
                    <span>{{ item?.value?.label }}</span>
                  </app-command-with-value-button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div>
        <table mat-table matSort [dataSource]="dataSource" #componentsTable>
          <!-- Label Column -->
          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Label </mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item?.value?.label }}
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item?.value?.description }}
            </mat-cell>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="command">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item.value | commandAction }}
            </mat-cell>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="editControls">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div class="row-buttons">
                <!-- Edit -->
                <ng-container *ngIf="item._isOverridden || (!item._isOverridden && currentModule !== 'robot')">
                  <button mat-icon-button (click)="onEdit(item)">
                    <span class="ri-edit"></span>
                  </button>
                  <button mat-icon-button (click)="onDelete(item)">
                    <span class="ri-delete"></span>
                  </button>
                </ng-container>

                <!-- Read-only -->
                <ng-container *ngIf="currentModule === 'robot' && !item._isOverridden">
                  <div matTooltip="Defined in Profile">
                    <span class="rs-grey-icon rs-icon-button-padding ri-robot-definition"></span>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="hover-row"></mat-row>
        </table>
      </div>
    </div>
  </app-loading-content>
</div>

<!-- <pre *appDeveloping>{{components | json}}</pre> -->
