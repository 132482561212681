import { ToastService } from '../../services/toast/toast.service';
import { RobotControlService } from '../../services/robot/robot-control.service';
import type { WidgetImage } from '../../models/widget/widget-image';
import type { WidgetVideo } from '../../models/widget/widget-video';
import type { ICoordinates } from '../../directives/coordinate-picker.directive';
import type { OnInit } from '@angular/core';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import type { CommandV2, ConfigGroupItem } from '../../models';
import { ExpressionEval } from '../../utils';
import { RobotDefinitionService } from '../../services';
import { first } from 'rxjs/operators';

export interface IPickerCoordinates {
  picker: ICoordinates;
}

@Component({
  selector: 'app-widget-pick-coordinates',
  templateUrl: './widget-pick-coordinates.component.html',
  styleUrls: ['./widget-pick-coordinates.component.scss'],
})
export class WidgetPickCoordinatesComponent implements OnInit {
  @Input() public widget: WidgetVideo | WidgetImage;
  @Input() public projectId: string;
  @Input() public robotCallsign: string;
  @Output() public lastCoordinatePicked: EventEmitter<ICoordinates> = new EventEmitter();
  public commandV2Items: ConfigGroupItem<CommandV2>[];

  public constructor(
    private robotControlService: RobotControlService,
    private toast: ToastService,
    private robotDefinitionService: RobotDefinitionService,
  ) {}

  public ngOnInit(): void {
    this.robotDefinitionService
      .getCommandsForRobotV2(this.projectId, this.robotCallsign)
      .pipe(first())
      .subscribe((commandsV2) => {
        if (commandsV2?.items) {
          this.commandV2Items = commandsV2.items;
        } else {
          this.commandV2Items = [];
        }
      });
  }

  public coordinatesPicked(event: IPickerCoordinates): void {
    this.sendCommand(event);
  }

  public sendCommand(event: IPickerCoordinates): void {
    this.lastCoordinatePicked.emit({ x: event.picker.x, y: event.picker.y });

    if (this.widget.pickerCommandId) {
      const commandId = this.widget.pickerCommandId;
      const commandParams = this.formatCommandParams(event);
      const commandV2 = this.commandV2Items.find((x) => x.value.id === commandId)?.value;

      if (!commandV2) {
        this.toast.short(`Command '${commandId}' does not exist.`, null, 'failure');
        return;
      }
      const timeoutMs = parseInt(commandV2.settings?.['timeoutMs'], 10) || undefined;

      const commandRequestSub = this.robotControlService.sendCommandV2WithAck(
        this.projectId,
        this.robotCallsign,
        commandId,
        commandParams,
        timeoutMs,
      );
      this.toast.short(`Coordinates [${event.picker.x}, ${event.picker.y}] sent.`, null, 'success');

      commandRequestSub.subscribe(
        () => {
          this.toast.short(`Coordinates [${event.picker.x}, ${event.picker.y}] accepted.`, null, 'success');
        },
        () => {
          this.toast.short(`Failed to send coordinates [${event.picker.x}, ${event.picker.y}].`, null, 'failure');
        },
      );
    }
  }

  public formatCommandParams(coordinates: IPickerCoordinates): Record<string, string> {
    const parameters: Record<string, string> = {
      ...this.widget.pickerCommandParameters,
    };

    const values = { '$widget': { ...coordinates } };

    Object.keys(parameters).forEach((key) => {
      try {
        const paramTemplate = parameters[key];

        const evaluatedParam = ExpressionEval.evaluate(paramTemplate as string, values);

        parameters[key] = `${evaluatedParam}`;
      } catch {
        this.toast.short(`Picker parameter failed at formatting`, null, 'failure');
      }
    });

    return parameters;
  }
}
