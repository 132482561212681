<div class="robot-select-container">
  <mat-form-field appearance="standard" floatLabel="never" [hideRequiredMarker]="true">
    <!-- Robot Selection -->
    <mat-select
      [formControl]="robot"
      [placeholder]="placeholder"
      [matTooltip]="robots?.length > 0 ? '' : notFoundTooltip"
      (selectionChange)="onOptionSelected($event.value)"
    >
      <mat-select-trigger>
        <div class="robot-option">
          <span *ngIf="robot.value?.name">{{ robot.value?.name }} ({{ robot.value?.callsign }})</span>
          <span *ngIf="!robot.value?.name">{{ robot.value?.callsign }}</span>
          <app-robot-status-dot
            *ngIf="robot.value?.callsign && includeStatus"
            [projectId]="projectId"
            [callsign]="robot.value?.callsign"
          ></app-robot-status-dot>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let robot of robots" [value]="{ name: robot.name, callsign: robot.callsign }">
        <div class="robot-option">
          <span *ngIf="robot.name">{{ robot.name }} ({{ robot.callsign }})</span>
          <span *ngIf="!robot.name">{{ robot.callsign }}</span>
          <app-robot-status-dot
            *ngIf="includeStatus"
            [projectId]="projectId"
            [callsign]="robot.callsign"
          ></app-robot-status-dot>
        </div>
      </mat-option>
    </mat-select>

    <mat-error *ngIf="robot?.errors?.['required']">Required</mat-error>
    <mat-hint *ngIf="!isRobotSelected && hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
