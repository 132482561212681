import type { Chart } from '@amcharts/amcharts4/charts';
import { CircleBullet, DateAxis, LineSeries, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import { color, create, time, unuseTheme } from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { filter } from 'lodash';

unuseTheme(am4themes_animated);

@Component({
  selector: 'app-preview-line-graph',
  templateUrl: './preview-line-graph.component.html',
  styleUrls: ['./preview-line-graph.component.scss'],
})
export class PreviewLineGraphComponent implements OnDestroy, AfterViewInit {
  @Input() public data: any;

  @ViewChild('chartDiv', { read: ElementRef }) public chartDiv: ElementRef;

  private chart: Chart;
  private chartId: string;
  private animationInterval: any;
  private cachedArray: any[] = [];
  private newDataExpiresInSeconds: number = 30; // Keep data in that time range
  private graphUpdateInterval = 500; // in milliseconds

  constructor(private zone: NgZone) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.graphInit();
      this.loadBlankPlaceHolderData();
      this.startAnimationInterval();
    });
  }

  ngOnDestroy() {
    clearInterval(this.animationInterval);

    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public addNewValue(value: any, date = new Date()) {
    this.cachedArray.push({
      date,
      value,
    });
  }

  private graphInit() {
    this.updateChartId();

    this.chartDiv.nativeElement.style.height = '400px';
    this.chartDiv.nativeElement.style.width = '100%';

    const chart = create(this.chartId, XYChart);
    chart.fontSize = '12px';
    chart.hiddenState.properties.opacity = 0;
    chart.zoomOutButton.disabled = true;
    chart.colors.list = [color('#3f48e9')]; // $color-blue

    const dateAxis = chart.xAxes.push(new DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.dateFormats.setKey('second', 'ss');
    dateAxis.periodChangeDateFormats.setKey('second', '[bold]h:mm a');
    dateAxis.periodChangeDateFormats.setKey('minute', '[bold]h:mm a');
    dateAxis.periodChangeDateFormats.setKey('hour', '[bold]h:mm a');
    dateAxis.renderer.inside = true;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;
    dateAxis.renderer.minLabelPosition = 0.05;
    dateAxis.renderer.maxLabelPosition = 0.95;

    // this makes date axis labels which are at equal minutes to be rotated
    dateAxis.renderer.labels.template.adapter.add('rotation', (rotation, target) => {
      const dataItem: any = target.dataItem;
      if (
        dataItem.date &&
        dataItem.date.getTime() === time.round(new Date(dataItem.date.getTime()), 'minute', 1).getTime()
      ) {
        target.verticalCenter = 'middle';
        target.horizontalCenter = 'left';
        return -90;
      } else {
        target.verticalCenter = 'bottom';
        target.horizontalCenter = 'middle';
        return 0;
      }
    });

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    const series = chart.series.push(new LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';

    const bullet = series.createChild(CircleBullet);
    bullet.circle.radius = 5;
    bullet.fillOpacity = 1;
    bullet.fill = chart.colors.getIndex(0);
    bullet.isMeasured = false;

    series.events.on('validated', () => {
      if (series.dataItems.length > 0) {
        bullet.moveTo(series.dataItems.last.point);
        bullet.validatePosition();
      }
    });

    chart.events.on('datavalidated', () => {
      dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
    });

    this.chart = chart;
  }

  private updateChartId() {
    if (!this.chartId) {
      const guid = crypto.randomUUID();
      this.chartId = 'chart-div' + guid;
    }

    this.chartDiv.nativeElement.id = this.chartId;
  }

  private loadBlankPlaceHolderData(seconds: number = this.newDataExpiresInSeconds) {
    const data = [];
    const now = Date.now();

    for (let i = 0; i <= seconds; i++) {
      data.push({ date: new Date(now + (i - seconds) * 1000), value: null });
    }

    if (this.chart) this.chart.data = data;
  }

  private startAnimationInterval() {
    this.animationInterval = setInterval(() => {
      const cachedArrayClone = JSON.parse(JSON.stringify(this.cachedArray));
      if (cachedArrayClone?.length > 0) {
        cachedArrayClone.forEach((item) => {
          item.date = new Date(item.date);
        });
      }

      const getNumToRemove = this.getExpiredDataCount();
      this.chart.addData(cachedArrayClone, getNumToRemove);

      this.cachedArray = [];
    }, this.graphUpdateInterval);
  }

  private getExpiredDataCount() {
    if (!this.chart) return 0;
    const expiredLine = new Date(Date.now() - this.newDataExpiresInSeconds * 1000);
    return filter(this.chart.data, (o) => {
      return o.date < expiredLine;
    }).length;
  }
}
