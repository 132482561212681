import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import type { IWorkflowLog } from '../models/workflow-log';
import { ELogLevel } from '../models/workflow-log';

@Injectable({
  providedIn: 'root',
})
export class WorkflowLoggerService {
  private _logs$: BehaviorSubject<IWorkflowLog[]> = new BehaviorSubject([]);
  private _lastLog$: Subject<IWorkflowLog> = new Subject();

  public get logs0$(): Observable<IWorkflowLog[]> {
    return this._logs$.asObservable();
  }

  public get lastLog$(): Observable<IWorkflowLog> {
    return this._lastLog$.asObservable();
  }

  public addLog(log: IWorkflowLog): void {
    const current = this._logs$.getValue();
    current.push(log);
    this._logs$.next(current);

    this._lastLog$.next(log);
  }

  public addLogMsg(msg: string): void {
    const log: IWorkflowLog = {
      content: msg,
      id: '',
      level: ELogLevel.debugLevel,
      file: '',
      kind: 'string',
    };
    this.addLog(log);
  }

  public clearLogs(): void {
    this._logs$.next([]);
  }
}
