import type { InputConnectionData, NodeData, OutputConnectionData } from 'rete/types/core/data';
import type { Point } from '../threeD/transform';
import { EPickerHintModes, EPickerHintNames } from '../threeD/primitives/visualizer/interface/IWaypointPicker';
import type { MaterialTypes } from '../threeD/primitives/materialPrimitives';
import type { Color4, Quaternion, Vector3 } from '@babylonjs/core';
import type { RcNode } from 'src/app/workflow/modules/graph-editor/models/node';
import type { ISocketUnit } from 'src/app/workflow/modules/graph-editor/models/socket';
import { ESocketKind } from 'src/app/workflow/modules/graph-editor/models/socket';
import type { RcInputData } from 'src/app/workflow/modules/graph-editor/models/types';
import type { ResourceNav } from 'src/app/workflow/modules/resources-management/models/resource-nav';

export interface IWorkflowWaypoint {
  id: string | number;
  label: string;
  pos: number[];
  frame?: string;
  prev: number[];
  prevId: number;
  possiblePath: boolean;
  material?: MaterialTypes;
  diameter?: number;
}
export interface IMarker {
  id: string;
  transform: ITransform;
  settings: IMarkerSettings;
}
export interface ITransform {
  id: string;
  parent: string;
  position: Vector3;
  rotation: Quaternion;
  timestamp: number;
}
export interface IMarkerSettings {
  label: boolean;
  color: Color4;
  shape: string;
}

export const getFrame = (node: NodeData): string => {
  const bi = (node?.data?.['config'] as RcNode)?.bi;
  if (!bi) return null;
  return Object.values(bi).find((socket: ISocketUnit) => socket['hints']?.[EPickerHintNames.frame])?.['hints']?.[
    EPickerHintNames.frame
  ];
};

export const getNodeById = (selected: ResourceNav, nodeId: number): NodeData => {
  const graph = selected.currentValueAsGraphs.uiGraph;
  return Object.values(graph.nodes).find((n) => n.id === nodeId);
};

export class WaypointParser {
  public static computeWaypoints(selected: ResourceNav): IWorkflowWaypoint[] {
    const graph = selected.currentValueAsGraphs.uiGraph;
    const nodes = Object.keys(graph.nodes).map((k) => graph.nodes[k]);
    return nodes
      .filter((node) => {
        return this.isWaypointCompatible(node);
      })
      .flatMap((node) => {
        // Traverse input connections until we find all gotoPose nodes
        return this.getPrevPose(selected, node, null, []);
      });
  }

  private static getPrevPose(
    selected,
    targetNode: NodeData,
    previousNode: NodeData,
    waypoints: IWorkflowWaypoint[] = [],
  ): IWorkflowWaypoint[] {
    const input = targetNode.inputs as unknown as RcInputData;
    const position = this.getWaypointPosValueByNode(targetNode);

    let inputConnections: InputConnectionData[];
    if (previousNode) {
      // Recursion; find backwards linked input connections
      const previousInput = previousNode.inputs as unknown as RcInputData;
      inputConnections = Object.values(previousInput).flatMap((socket) => {
        return socket.connections as InputConnectionData[];
      });
    } else {
      // First iteration; find own input connections
      inputConnections = Object.values(input).flatMap((socket) => {
        return socket.connections as InputConnectionData[];
      });
    }

    // Iterate over all connections until we find a gotoPose node
    if (inputConnections?.length) {
      inputConnections.flatMap((connection) => {
        const prevNode = getNodeById(selected, connection.node);

        if (this.isWaypointCompatible(prevNode)) {
          const prevConnectionIn = this.getPrevConnectionsIn(prevNode);
          const prevConnectionOut = this.getPrevConnectionsOut(prevNode);
          const prevPosition = this.getWaypointPosValueByNode(prevNode);
          const waypointLabel = input['label']?.defaultValue || targetNode.id.toString();

          waypoints.push({
            id: targetNode.id,
            label: waypointLabel,
            pos: position,
            frame: getFrame(targetNode),
            prev: prevPosition,
            prevId: connection.node,
            possiblePath: prevConnectionIn?.length > 1 || prevConnectionOut?.length > 1,
          } as IWorkflowWaypoint);
        } else {
          this.getPrevPose(selected, targetNode, prevNode, waypoints);
        }
      });
    } else {
      // Add orphaned gotoNodes to localOps
      if (this.isWaypointCompatible(targetNode)) {
        const waypointLabel = input['label']?.defaultValue || targetNode.id.toString();
        waypoints.push({
          id: targetNode.id,
          label: waypointLabel,
          pos: position,
          frame: getFrame(targetNode),
          prev: null, // don't render arrows for orphans
          prevId: targetNode.id,
          possiblePath: false,
        } as IWorkflowWaypoint);
      }
    }

    return waypoints;
  }

  private static isWaypointCompatible(targetNode: NodeData): boolean {
    const bi = (targetNode.data['config'] as RcNode).bi;
    if (!bi) return false;
    return !!Object.values(bi).find(
      (socket: ISocketUnit) => socket.hints?.[EPickerHintNames.mode] === EPickerHintModes.waypoint,
    );
  }

  private static getPrevConnectionsIn(node: NodeData): InputConnectionData[] {
    return node['in']?.connections as InputConnectionData[];
  }

  // Note: Only a single input Transform socket is supported pr Node at the moment
  private static getWaypointPosValueByNode(node: NodeData): Point {
    const bi = (node?.data?.['config'] as RcNode)?.bi;
    if (!bi) return [0, 0, 0];

    const socket = Object.values(bi).find((s: ISocketUnit) => s.kind === ESocketKind.Transform) as ISocketUnit;
    if (!socket) return [0, 0, 0];

    const output = node.inputs[socket.name] as RcInputData;
    const values = (output.defaultValue as { pos: { x: number; y: number; z: number } }).pos;
    if (!values) return [0, 0, 0];

    return Object.values(values).map(Number) as Point;
  }

  private static getPrevConnectionsOut(node: NodeData): OutputConnectionData[] {
    return Object.values(node.outputs)
      .map((output) => output.connections)
      .filter((connections) => {
        return connections.filter((c) => c.input === 'in');
      })
      .flat(2);
  }
}
