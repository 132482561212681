import type { Scene, StandardMaterial } from '@babylonjs/core';
import { Color4, PolygonMeshBuilder, Vector2 } from '@babylonjs/core';
import earcut from 'earcut';
import type { WaypointLocation } from '../visualizer/interface/IWaypointPicker';
import { getGizmoRoot, gizmoId } from './gizmo-utils';

export const POLYGON_ROOT_ID = 'polygon-root';
export const renderPolygonGizmo = ({
  scene,
  id,
  material,
  locations,
  height = 0.7,
}: {
  scene: Scene;
  id: string;
  material: StandardMaterial;
  locations: WaypointLocation[];
  height?: number;
}) => {
  if (!locations.length) return;
  const gid = gizmoId(POLYGON_ROOT_ID, id);
  const polyId = `${gid}-polygon`;
  scene.getMeshById(polyId)?.dispose();
  const corners = locations.map((position) => {
    return new Vector2(position.pos.x, position.pos.y);
  });
  const polygonExtrusion = new PolygonMeshBuilder(polyId, corners, undefined, earcut);
  const polygon = polygonExtrusion.build(false, height);
  polygon.position.z = locations.at(-1).pos.z;
  polygon.rotation.x = -Math.PI / 2;
  polygon.material = material;
  polygon.enableEdgesRendering();
  polygon.edgesWidth = 2.0;
  polygon.edgesColor = new Color4(0, 0.74, 0.84, 1);
  polygon.parent = getGizmoRoot(scene, POLYGON_ROOT_ID);
};

export const deletePolygonGizmo = ({ scene, id }: { scene: Scene; id: string }) => {
  const gid = gizmoId(POLYGON_ROOT_ID, id);
  const polyId = `${gid}-polygon`;
  scene.getMeshById(polyId)?.dispose();
};

export const deleteAllPolygonGizmos = ({ scene }: { scene: Scene }) => {
  getGizmoRoot(scene, POLYGON_ROOT_ID)?.dispose();
};
