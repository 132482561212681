import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { IUserInfo } from '@shared/interfaces';
import { AuthService, GotoService } from '@shared/services';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  template: ` <app-dd-loading-spinner [subtext]="errorMsg$ | async"></app-dd-loading-spinner> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthOneTimeCodeComponent implements OnInit {
  errorMsg$: Subject<string> = new Subject<string>();

  constructor(private authService: AuthService, private route: ActivatedRoute, private goto: GotoService) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        map((params) => params['code']),
        filter((code) => Boolean(code)),
        mergeMap((code: string) => this.authService.loginWithOneTimeToken(code)),
        tap((res) => this.handleLogin(res.data as IUserInfo)),
      )
      .subscribe({
        error: () => this.errorMsg$.next('Invalid session'),
      });
  }

  private handleLogin(userInfo: IUserInfo) {
    this.authService.handleLogin(userInfo).subscribe(
      () => {
        const redirect = this.goto.getRedirectUrl() ?? this.route.snapshot.queryParams['redirect'];

        if (redirect) {
          this.goto.clearRedirectUrl();
          return this.goto.url(redirect);
        }

        this.goto.defaultAccountPage();
      },
      () => this.errorMsg$.next('Invalid account'),
    );
  }
}
