import { ButtonDef } from './gamepad';

export class GamepadButtonConfig {
  name: string;
  source: ButtonDef;
  value: string;
  defaultValue?: string = '';

  constructor(name?: string, source?: ButtonDef, value?: string, defaultValue?: string) {
    this.name = name;
    this.source = source;
    this.value = value;
    this.defaultValue = defaultValue;
  }

  static fromJSON(json: any): GamepadButtonConfig {
    return new GamepadButtonConfig(json?.name, ButtonDef.fromJSON(json?.source), json?.value, json?.defaultValue);
  }

  static fromJSONToList(json: any[]): GamepadButtonConfig[] {
    const list: GamepadButtonConfig[] = [];

    if (json?.length > 0) {
      json.forEach((item) => {
        const obj = GamepadButtonConfig.fromJSON(item);
        list.push(obj);
      });
    }

    return list;
  }

  toJSON() {
    return {
      name: this.name,
      source: this.source.toJSON(),
      value: this.value,
      defaultValue: this.defaultValue,
    };
  }
}

export enum GamepadButtonTriggerType {
  ONCE = 'once',
  CONTINUOUSLY = 'continuously',
}

export enum GamepadButtonActionCommandVersionName {
  COMMANDS = 'commands',
  CONTROLS = 'controls',
  COMMANDS_V2 = 'commands-v2',
  RUN_JS = 'run-js',
}

export class GamepadButtonAction {
  name: string;
  source: ButtonDef;
  frequency: number;
  triggerType: GamepadButtonTriggerType;
  commandId: string;
  commandVersion: GamepadButtonActionCommandVersionName = GamepadButtonActionCommandVersionName.CONTROLS;

  constructor(
    name?: string,
    source?: ButtonDef,
    frequency?: number,
    triggerType?: GamepadButtonTriggerType,
    commandId?: string,
  ) {
    this.name = name;
    this.source = source;
    this.frequency = frequency !== undefined ? frequency : 100;
    this.triggerType = triggerType ? triggerType : GamepadButtonTriggerType.ONCE;
    this.commandId = commandId;
  }

  static fromJSON(json: any): GamepadButtonAction {
    const action = new GamepadButtonAction(
      json.name,
      ButtonDef.fromJSON(json.source),
      json.frequency,
      json.triggerType,
      json.commandId,
    );
    action.commandVersion = json.commandVersion;
    return action;
  }

  static fromJSONToList(json: any[]): GamepadButtonAction[] {
    const list: GamepadButtonAction[] = [];

    if (json?.length > 0) {
      json.forEach((item) => {
        const action = GamepadButtonAction.fromJSON(item);
        list.push(action);
      });
    }

    return list;
  }

  toJSON() {
    return {
      name: this.name,
      source: this.source.toJSON(),
      frequency: this.frequency,
      triggerType: this.triggerType as any,
      commandId: this.commandId,
      commandVersion: this.commandVersion,
    };
  }
}

export class GamepadControlPayload {
  action?: GamepadButtonAction;
  actionName: string;
  destination: string;
  template: string;

  constructor(actionName?: string, destination?: string, template?: string, action?: GamepadButtonAction) {
    this.actionName = actionName;
    this.destination = destination;
    this.template = template;
    this.action = action;
  }
}

export class GamepadPayloadContext {
  callsign: string;
}

export class GamepadExecuteActionParametersObj {
  [name: string]: {
    id: string;
    value: any;
  };
}

export class GamepadExecuteAction {
  actions: any[];
  parametersObj: GamepadExecuteActionParametersObj;
}
