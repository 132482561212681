import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueEvalPipe } from './value-eval.pipe';
import { HtmlPipe } from './html.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { CronToNextDatePipe } from './cron-to-next-date.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { DDThumbnailPipe } from './dd-thumbnail.pipe';
import { PrimitiveIconPipe } from './primitive-icons/primitive-icons.pipe';
import { ObjectFilterKeysPipe } from './object-filter-keys/object-filter-keys.pipe';
import { FormCastPipe } from './form-cast.pipe';
import { FileSizePipe } from './filesize';

const pipes = [
  ValueEvalPipe,
  HtmlPipe,
  DDThumbnailPipe,
  EnumToArrayPipe,
  TruncateTextPipe,
  CronToNextDatePipe,
  HighlightPipe,
  FormCastPipe,
  ObjectFilterKeysPipe,
  PrimitiveIconPipe,
  FileSizePipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class PipesModule {}
