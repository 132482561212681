import { Injectable } from '@angular/core';
import { BabylonRendererService } from '../babylon-renderer.service';
import type { Material, StandardMaterial } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';
import type { MaterialTextureTypes, MaterialValueType } from '../primitives/materialPrimitives';
import { materialPropertyValueTypes } from '../primitives/materialPrimitives';
import { boolHasValue, boolHasValueAndTrue } from '../primitives/validation';

@Injectable()
export class MaterialFactoryService {
  public constructor(private babylonRenderer: BabylonRendererService) {}

  public addMaterialToRenderer(materialId, matData) {
    const applyMaterialProp = (prop) => {
      const propValue = matData[prop];
      if (propValue) this.setMaterialValue(materialId, prop, propValue);
    };

    switch (matData.type) {
      case 'standard': {
        this.babylonRenderer.createMaterial(materialId);
        [
          'diffuseColor',
          'specularColor',
          'ambientColor',
          'wireframe',
          'alpha',
          'diffuseTexture',
          'bumpTexture',
          'pointsCloud',
          'pointSize',
        ].forEach((prop) => {
          applyMaterialProp(prop);
        });
        break;
      }
      case 'grid': {
        this.babylonRenderer.createGridMaterial(materialId);
        ['gridRatio', 'mainColor', 'lineColor', 'alphaMode', 'alpha'].forEach((prop) => {
          applyMaterialProp(prop);
        });
        break;
      }
      case 'shadow-receiver': {
        this.babylonRenderer.createShadowOnlyMaterial(materialId);
        break;
      }
      case 'custom-shader': {
        this.babylonRenderer.createCustomShaderMaterial(materialId, matData.commonShaderName);
        break;
      }
      default: {
        console.warn('Unknown material type: ' + matData.type);
        break;
      }
    }
  }

  /**
   * Updates Babylon from Scene Manager
   *
   * @param materialId key of the material
   * @param property the property to sync
   * @param newValue the value you want it to be
   * @param valueType the type of value ('string' | 'number' | 'vector3' | 'color3' | 'color4' | 'boolean' | 'texture')
   */
  public setMaterialValue(materialId: string, property: string, newValue: any) {
    const babylonRendererMaterial = this.babylonRenderer.getMaterial(materialId);
    const valueType: MaterialValueType = materialPropertyValueTypes[property];

    switch (valueType) {
      case 'string': {
        babylonRendererMaterial[property] = newValue;
        break;
      }
      case 'number': {
        babylonRendererMaterial[property] = parseFloat(newValue);
        break;
      }
      case 'vector3': {
        const vec3Value = this.babylonRenderer.getVector(newValue);
        babylonRendererMaterial[property] = vec3Value;
        break;
      }
      case 'color3': {
        const col3Value = this.babylonRenderer.getColor3(newValue);
        babylonRendererMaterial[property] = col3Value;
        break;
      }
      case 'color4': {
        const col4Value = this.babylonRenderer.getColor4(newValue);
        babylonRendererMaterial[property] = col4Value;
        break;
      }
      case 'boolean': {
        const boolValue = JSON.parse(newValue);
        babylonRendererMaterial[property] = boolValue;
        break;
      }
      case 'texture': {
        babylonRendererMaterial[property] = new Texture(newValue.texture, this.babylonRenderer.scene);
        babylonRendererMaterial[property].vScale = newValue.vScale;
        babylonRendererMaterial[property].uScale = newValue.uScale;

        this.setTextureProps(babylonRendererMaterial, property as MaterialTextureTypes, newValue);

        if (boolHasValue(newValue.level)) babylonRendererMaterial[property].level = newValue.level;
        if (boolHasValueAndTrue(newValue.hasAlpha)) babylonRendererMaterial[property].hasAlpha = true;

        break;
      }
    }
  }

  private setTextureProps(babylonRendererMaterial: Material, property: MaterialTextureTypes, newValue: any) {
    switch (property) {
      case 'diffuseTexture': {
        (babylonRendererMaterial as StandardMaterial).diffuseTexture = new Texture(
          newValue,
          this.babylonRenderer.scene,
        );
        break;
      }
      case 'bumpTexture': {
        babylonRendererMaterial[property].invertNormalMapX = newValue.invertNormalMapX;
        babylonRendererMaterial[property].invertNormalMapY = newValue.invertNormalMapY;
        (babylonRendererMaterial as StandardMaterial).bumpTexture = new Texture(newValue, this.babylonRenderer.scene);
        break;
      }
    }
  }
}
