import type { CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthService, GotoService } from '../services';
import { inject } from '@angular/core';

export const AuthGuard: CanActivateFn = (_, state: RouterStateSnapshot) => {
  const auth = inject(AuthService);
  const goto = inject(GotoService);

  const isAuthenticated = auth.isAuthenticated();
  const hasAccount = auth.hasAccount();

  if (!isAuthenticated) {
    // store the redirect url
    if (state.url) {
      goto.setRedirectUrl(state.url);
    }

    // navigate to login page
    goto.login();

    return false;
  }

  if (!hasAccount) {
    goto.noAccountPage();
    return false;
  }

  return true;
};
