<app-widget-base
  [heading]="heading"
  [type]="type"
  [dataURI]="dataURI"
  [editing]="editing"
  (deleted)="onDelete()"
  [floorHeading]="true"
  [height]="height"
  [width]="width"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
  [menuItems]="menuItems"
  (menuItemClick)="onMenuItemClick($event)"
>
  <ng-container *ngIf="!listMode">
    <div class="js-editor-wrapper">
      <div class="js-editor-top-bar">
        <div>
          <mat-checkbox [(ngModel)]="showPanelButton" class="checkbox" (change)="onTogglePanelButton()"
            >Panel Button
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox [(ngModel)]="showToolbarButton" class="checkbox" (change)="onToggleToolbarButton()"
            >Toolbar Button
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox [(ngModel)]="autoRun" class="checkbox" (change)="onToggleAutoRun()">Autorun </mat-checkbox>
        </div>
      </div>
      <div class="js-editor-body">
        <ng-container>
          <ngx-monaco-editor
            [style.height]="'100%'"
            [options]="editorOptions"
            [ngModel]="code"
            (ngModelChange)="onCodeChanged($event)"
          >
          </ngx-monaco-editor>
        </ng-container>
      </div>
      <div class="js-editor-footer">
        <div class="d-flex justify-content-between align-items-center">
          <div *ngIf="false" class="intro">
            More information on using scripts
            <span class="icon">
              <span class="ri-external-link"></span>
            </span>
          </div>
          <div class="spacer"></div>
          <button mat-flat-button color="primary" (click)="onRunCode($event)">
            <span class="ri-play"></span>
            <span>Run</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="listMode" class="script-list-item">
    <div class="d-flex align-items-center">
      <div class="script-icon">
        <div class="script-icon-svg-wrap">
          <span class="ri-js-script"></span>
        </div>
      </div>
      <div class="script-name">
        <span class="script-name-text"> {{ heading }} </span>
      </div>
      <span class="script-play-button right-buttons">
        <button *ngIf="showPanelButton" mat-icon-button (click)="onRunCode($event)">
          <span class="ri-play2"></span>
        </button>
      </span>
    </div>
  </div>
</app-widget-base>
