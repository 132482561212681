import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { CommandV2 } from '@shared/models';
import { CommandV2Types } from '@shared/models';

@Pipe({
  name: 'commandType',
})
export class CommandTypePipe implements PipeTransform {
  transform(command: CommandV2): string {
    const commandType = command?.type;
    switch (commandType) {
      case CommandV2Types.sendMessage:
        return `Send Message - ${command?.settings?.['target'] ?? ''}`;
      case CommandV2Types.callService:
        return `Call Service - ${command?.settings?.['target'] ?? ''}`;
      default:
        return command?.settings?.['target'] ?? '';
    }
  }
}
