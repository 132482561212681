<app-loading-content [isLoading]="isLoading">
  <div #carousel [ngClass]="{ 'fs-enabled': (fsEnabled$ | async) === true }" class="carousel-container">
    <div *ngIf="(fsEnabled$ | async) === true" class="fs-header">
      <div class="fs-header-icon">
        <span class="icon-header ri-image-widget-heading"></span>
      </div>
      <div class="fs-header-title">{{ selected?.title }}</div>
      <div class="fs-control">
        <span (click)="disableFs()" class="ri-exit-fullscreen"></span>
      </div>
    </div>

    <!-- Content -->
    <div class="carousel-content" [ngClass]="{ 'fs-enabled': (fsEnabled$ | async) === true }">
      <!-- Header -->
      <div class="carousel-header">
        <div class="header-timestamp">
          <span>{{ selected?.date | date : 'short' }}</span>
        </div>
        <div *ngIf="data" class="count">{{ selectedIndex }} / {{ data?.length }}</div>
        <div *ngIf="(fsEnabled$ | async) !== true" (click)="fullscreen()" class="fs-control">
          <span class="ri-enter-fullscreen"></span>
        </div>
      </div>

      <div (click)="selectNext()" class="control-prev">
        <span class="icon ri-chevron-left"></span>
      </div>
      <div [ngClass]="{ 'fs-enabled': (fsEnabled$ | async) === true }" class="img-container">
        <img [src]="selected?.imageData" />
      </div>
      <div (click)="selectPrev()" class="control-next">
        <span class="icon right ri-chevron-right"></span>
      </div>
    </div>

    <!-- Footer -->
    <div class="carousel-footer" #footerContainer>
      <ng-container *ngFor="let img of data; let i = index">
        <div
          #footerItems
          (click)="selectImage(img, i)"
          class="img-footer-container"
          [ngClass]="{ 'selected': selected === img }"
        >
          <img [src]="img.imageData" />

          <div class="ts-container">
            <span class="ts">{{ img.date | date : 'short' }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</app-loading-content>
