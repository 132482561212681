import type { ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  public fullScreenEnabled$ = new BehaviorSubject(false);

  public fullScreenChangeEventHandler() {
    const fs = this.fullScreenEnabled$.getValue();
    this.fullScreenEnabled$.next(!fs);
  }

  public triggerFullscreen(element: ElementRef): void {
    if (!element) {
      return;
    }

    const elem = element.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  public exitFullscreen(): void {
    if (document?.fullscreenElement) {
      document.exitFullscreen();
    }
  }
}
