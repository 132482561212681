import { Injectable } from '@angular/core';
import { AppService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  private scope: string;

  public constructor(private appService: AppService) {
    this.scope = appService.projectId;

    this.appService.projectIdChange.subscribe((projectId) => {
      this.scope = projectId;
    });
  }

  public get length(): number {
    return this.getAllScopedKeys().length;
  }

  public clear(): void {
    this.getAllScopedKeys().forEach((element) => {
      localStorage.removeItem(element);
    });
  }

  public getItem(key: string): string | null {
    const scopedKey = this.makeKeyScoped(key);
    return localStorage.getItem(scopedKey);
  }

  public key(index: number): string | null {
    return this.getAllScopedKeys()[index] ?? null;
  }

  public removeItem(key: string): void {
    const scopedKey = this.makeKeyScoped(key);
    return localStorage.removeItem(scopedKey);
  }

  public setItem(key: string, value: string | null): void {
    const scopedKey = this.makeKeyScoped(key);
    return localStorage.setItem(scopedKey, value);
  }

  private makeKeyScoped(key: string): string {
    return this.scope + '-' + key;
  }

  private isScoped(key: string) {
    return key.startsWith(this.scope);
  }

  private getAllScopedKeys(): string[] {
    return Object.keys(localStorage).filter((key) => this.isScoped(key));
  }
}
