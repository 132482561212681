<div class="gridster-wrapper preview">
  <div class="header">
    <div class="title">Preview</div>

    <div class="controls">
      <mat-slide-toggle
        [labelPosition]="'before'"
        [ngModel]="widgetEditMode"
        (ngModelChange)="onWidgetEditModeChange($event)"
        >Move & Resize Widget</mat-slide-toggle
      >
    </div>
  </div>
  <gridster class="rs-gridster" [options]="gridOptions">
    <gridster-item [item]="$any(gridItem)">
      <app-custom-widget
        [editMode]="widgetEditMode"
        [previewMode]="true"
        [htmlCode]="updatedHtmlCode"
        [cssCode]="updatedCssCode"
        [jsCode]="updatedJsCode"
      >
      </app-custom-widget>
    </gridster-item>
  </gridster>
</div>

<div class="code-editors rocos-dark-theme" (focusout)="applyCode()">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="HTML">
      <div class="editor-wrapper" [style.height]="editorWrapperHeight">
        <ngx-monaco-editor
          class="editor-class"
          [style.height]="editorHeight"
          [options]="htmlEditorOptions"
          [ngModel]="htmlCode"
          (ngModelChange)="onCodeChanged($event, 'html')"
        >
        </ngx-monaco-editor>
      </div>
    </mat-tab>
    <mat-tab label="CSS">
      <div class="editor-wrapper" [style.height]="editorWrapperHeight">
        <ngx-monaco-editor
          class="editor-class"
          [style.height]="editorHeight"
          [options]="cssEditorOptions"
          [ngModel]="cssCode"
          (ngModelChange)="onCodeChanged($event, 'css')"
        >
        </ngx-monaco-editor>
      </div>
    </mat-tab>
    <mat-tab label="JS">
      <div class="editor-wrapper" [style.height]="editorWrapperHeight">
        <ngx-monaco-editor
          class="editor-class"
          [style.height]="editorHeight"
          [options]="jsEditorOptions"
          [ngModel]="jsCode"
          (ngModelChange)="onCodeChanged($event, 'js')"
        >
        </ngx-monaco-editor>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="top-right-corner-buttons">
    <button type="button" class="left-icon-button" mat-button color="primary" (click)="onUpdatePreview()">
      <span class="ri-refresh"></span>
      <span>Refresh Preview</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <div class="background-layer-option">
      <div class="background-layer-option-checkbox">
        <mat-checkbox [formControl]="inBackground" (change)="onCheckboxChanged($event)">
          Place on Background Layer
        </mat-checkbox>
      </div>
      <div class="background-layer-option-text">
        Useful for creating a teleoperation dashboard, where you want to make the video widget as large as possible
        while still having telemetry visible over the top of it.
      </div>
    </div>
  </div>
</div>
