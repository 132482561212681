import { Utils } from '../../utils';
import { FullscreenService } from './../../services/fullscreen/fullscreen.service';
import { CoordinatePickerDirective } from './../../directives/coordinate-picker.directive';
import { WidgetVideoService } from './services/widget-video.service';
import { WidgetVideo } from '../../models';
import type { ICoordinates } from '@shared/directives/coordinate-picker.directive';
import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import type { WidgetMenuItem, WidgetToolbarItem } from '../shared';
import { WidgetBaseComponent } from '../shared';
import { P2PVideoSource } from './models/p2p-video-source';
import type { VideoSource, VideoSourceStatus } from './models/video-source';
import { FileVideoSource } from './models/file-video-source';
import type { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { DialogService } from '../../dialogs';
import { RocosClientService } from '../../services';
import { DebugInfos } from './models/debug-infos';
@Component({
  selector: 'app-widget-video',
  templateUrl: './widget-video.component.html',
  styleUrls: ['../shared/styles.scss', './widget-video.component.scss'],
  providers: [WidgetVideoService],
})
export class WidgetVideoComponent extends WidgetBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public testPattern: string =
    'videotestsrc ! video/x-raw,format=I420 !' +
    ' x264enc bframes=0 speed-preset=veryfast key-int-max=60 !' +
    ' video/x-h264,stream-format=byte-stream';
  @Input() public projectId: string;
  @Input() public demoMode: boolean = false;
  @Input() public widgetMenuItems: WidgetMenuItem[] = [];
  @Input() public videoSourceType = 'server';
  @Input() public callsign: string;
  @Input() public override widget: WidgetVideo;

  // Set static to true as this variable will be used in ngOnInit.
  @ViewChild('remoteVideoElement', { static: true })
  public remoteVideoElement: ElementRef<HTMLVideoElement>;
  @ViewChild(WidgetBaseComponent) public baseWidget: WidgetBaseComponent;
  @ViewChild('videoContainer') public videoContainer: ElementRef;

  public override toolbarItems: WidgetToolbarItem[] = [];
  public lastCoordinatePicked: ICoordinates;
  public spinner = null;
  public debugOn = false;
  public fullScreenEnabled: BehaviorSubject<boolean>;
  public videoServerSuccess: boolean = false;
  public videoSource: VideoSource;
  public isAdvancedMode = false;
  public commandSource: 'camera' | 'testPattern' = 'camera';
  public currentVideoCommand: string;
  public debugInfos: DebugInfos = new DebugInfos();
  public videoStreaming: boolean = false;
  public videoConnecting: boolean = false;
  public videoSourceStatus: VideoSourceStatus;
  public pickerPosition: {
    width: string | number;
    height: string | number;
    top: string;
    left: string;
  };

  private sub = new Subscription();

  public get isConnecting(): boolean {
    return this.widget?.videoSourceType === 'p2p' && this.videoConnecting;
  }

  public constructor(
    protected dialogService: DialogService,
    protected rocosClientService: RocosClientService,
    private widgetVideoService: WidgetVideoService,
    private fsService: FullscreenService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentVideoCommand = this.widget ? this.widget.videoCommand : null;
    this.fullScreenEnabled = this.fsService.fullScreenEnabled$;

    this.handleVideoSource();

    this.updateToolbarItems();

    // Update Icons On Full Screen
    this.sub.add(
      this.fullScreenEnabled.subscribe(() => {
        this.updateToolbarItems();
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (this.videoSource) {
      this.sub.add(
        this.videoSource.stream.subscribe((stream) => {
          this.remoteVideoElement.nativeElement.srcObject = stream;
          // HACK(matej): assign a peer connection reference to video object for stats and debugging
          this.remoteVideoElement.nativeElement['peerConnection'] = this.videoSource?.['peerConnection'];
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.videoSource) {
      this.videoSource.dispose();
    }
  }

  public handleVideoSource(): void {
    const videoSourceType = this.widget ? this.widget.videoSourceType : this.videoSourceType;
    const videoId = this.widget ? this.videoIDWithCallsignContext : '8004';
    const playoutDelayHint = this.widget ? this.widget.playoutDelayHint : '100';
    const videoServer = this.widget ? this.widget.videoServer : 'https://video.rocos.io:8089/janus';

    if (videoSourceType === 'p2p') {
      this.videoSource = new P2PVideoSource(this.dialogService, this.rocosClientService);
    } else if (videoSourceType === 'file') {
      const source = new FileVideoSource();
      source.remoteVideoElement = this.remoteVideoElement;
      this.videoSource = source;
    }

    if (!this.videoSource) {
      return;
    }

    this.videoSource.videoId = videoId;
    this.videoSource.playoutDelayHint = playoutDelayHint;

    this.sub.add(
      this.videoSource.videoServerSuccess.subscribe((v) => {
        this.videoServerSuccess = v;

        if (v === null) {
          this.status = 'unknown';
        }
        if (v === true) {
          this.status = 'alive';
        }
        if (v === false) {
          this.status = 'dead';
        }
      }),
    );

    this.videoSource.initConnection(videoServer);

    this.sub.add(
      this.videoSource.videoSourceStatus.subscribe((status) => {
        this.debugInfos.updateWebrtcDataFrames(this.videoSourceStatus);
        this.widgetVideoService.updateVideoStats(this.toolbarItems, status);
        this.videoSourceStatus = status;
      }),
    );

    this.sub.add(
      this.videoSource.isVideoStreaming.subscribe((streaming) => {
        this.videoStreaming = streaming;

        this.updateToolbarItems();
      }),
    );

    this.sub.add(
      this.videoSource.isVideoConnecting.subscribe((connecting) => {
        this.videoConnecting = connecting;

        this.updateToolbarItems();
      }),
    );

    if (this.videoSource.allowDebug) {
      this.widgetMenuItems = [
        {
          text: 'Debug',
          value: 'debug',
        },
      ];
    }

    this.remoteVideoElement.nativeElement.onloadedmetadata = () => {
      this.computePickerPositionNDimension();
    };
  }

  public get videoIDWithCallsignContext(): string {
    return Utils.simpleStringReplace(this.widget.videoId, '$object', {
      callsign: this.callsign,
    });
  }

  public startStream(): void {
    if (this.videoSource) {
      this.videoSource.videoCommand = this.currentVideoCommand;
      this.videoSource.startStream(this.projectId);
    }
  }

  public stopStream(): void {
    if (this.videoSource) {
      this.videoSource.stopStream();
    }
  }

  public onReset(): void {
    this.resetCommand();
  }

  public onFullscreen(): void {
    this.baseWidget.requestFullScreen();
  }

  public override exitFullScreen(): void {
    this.baseWidget.exitFullScreen();
  }

  public override onMenuItemClick(item: WidgetMenuItem): void {
    switch (item.value) {
      case 'debug':
        this.debugOn = true;
        this.videoSource.setStatusUpdateFrequency(100);

        this.widgetMenuItems = [
          {
            text: 'Close Debug',
            value: 'close-debug',
          },
        ];
        break;
      case 'close-debug':
        this.debugOn = false;
        this.videoSource.setStatusUpdateFrequency(1000);

        this.widgetMenuItems = [
          {
            text: 'Debug',
            value: 'debug',
          },
        ];
        break;
    }
  }

  public updateToolbarItems(): void {
    this.toolbarItems = this.widgetVideoService.getToolbarItems(
      this.widget,
      this.videoStreaming,
      this.isConnecting,
      this.fullScreenEnabled.getValue(),
      this.viewSwitchable,
    );
  }

  public override onToolbarItemClick(event: WidgetToolbarItem): void {
    if (event.text === 'CONNECT') {
      this.startStream();
    } else if (event.text === 'DISCONNECT' || event.text === 'CANCEL') {
      this.stopStream();
    } else if (event.icon && event.icon === 'ri-enter-fullscreen') {
      this.onFullscreen();
    } else if (event.icon && event.icon === 'ri-exit-fullscreen') {
      this.exitFullScreen();
    }
  }

  public onResized(): void {
    this.computePickerPositionNDimension();
  }

  public computePickerPositionNDimension(): void {
    if (this.videoStreaming && this.remoteVideoElement && this.videoContainer) {
      this.pickerPosition = CoordinatePickerDirective.computePickerPositionNDimension(
        this.remoteVideoElement.nativeElement.videoHeight,
        this.remoteVideoElement.nativeElement.videoWidth,
        this.videoContainer.nativeElement.offsetHeight,
        this.videoContainer.nativeElement.offsetWidth,
      );
    }
  }

  private resetCommand() {
    const value = this.commandSource;
    switch (value) {
      case 'camera':
        this.currentVideoCommand = this.widget.videoCommand;
        break;
      case 'testPattern':
        this.currentVideoCommand = this.testPattern;
        break;
      default:
        this.currentVideoCommand = this.widget.videoCommand;
        break;
    }
  }
}
