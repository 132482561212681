import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Widget } from '../../models';
import {
  WidgetMetric,
  WidgetDonutGauge,
  WidgetLineGraph,
  WidgetTable,
  WidgetVideo,
  WidgetHTML,
  WidgetImage,
  WidgetOps,
  WidgetCustom,
} from '../../models';
import { RocosClientService } from '../rocos-client';
import { WidgetTableV2 } from '../../models/widget/widget-table-v2';
import { WidgetGamepad } from '../../models/widget/widget-gamepad';
import { WidgetAudio } from '../../models/widget/widget-audio';
import { WidgetButtonGroup } from '../../models/widget/widget-button-group';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public constructor(private rocosClientService: RocosClientService) {}

  public list(projId: string, dashboardId: string): Observable<Widget[]> {
    return this.rocosClientService.rocosClient.dashboard.info(projId, dashboardId).pipe(
      map((res) => {
        const dashboard = res.data as any;

        const widgets = dashboard.widgets as Widget[];

        return widgets.map((widget) => {
          switch (widget.widgetType) {
            case 'metric':
              return WidgetMetric.fromModel(widget);
            case 'donut-gauge':
              return WidgetDonutGauge.fromModel(widget);
            case 'line-graph':
            case 'line-graph-v2':
              return WidgetLineGraph.fromModel(widget);
            case 'audio':
              return WidgetAudio.fromModel(widget);
            case 'gamepad':
              return WidgetGamepad.fromModel(widget);
            case 'button-group':
              return WidgetButtonGroup.fromModel(widget);
            case 'tableV2':
              return WidgetTableV2.fromModel(widget);
            case 'table':
              return WidgetTable.fromModel(widget);
            case 'video':
              return WidgetVideo.fromModel(widget as WidgetVideo);
            case 'html':
              return WidgetHTML.fromModel(widget);
            case 'image':
              return WidgetImage.fromModel(widget);
            case 'ops':
              return WidgetOps.fromModel(widget);
            case 'custom':
              return WidgetCustom.fromModel(widget);
            default:
              return undefined;
          }
        });
      }),
    );
  }

  public createWidget(projId: string, dashboardId: string, widget: Widget): Observable<any> {
    return this.rocosClientService.rocosClient.dashboard.createWidget(projId, dashboardId, widget as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public deleteWidgets(projId: string, dashboardId: string, widgetIds: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.dashboard.deleteWidgets(projId, dashboardId, widgetIds).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }
}
