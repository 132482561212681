import type { Route } from '@angular/router';

type BreadcrumbIcon = 'robotStatus' | undefined;
export class Breadcrumb {
  displayName: string;
  url: string;
  terminal: boolean;
  route: Route | null;
  icon?: BreadcrumbIcon;

  constructor(displayName: string, url: string, terminal: boolean = false, route?: Route, icon?: BreadcrumbIcon) {
    this.displayName = displayName;
    this.url = url;
    this.terminal = terminal;
    this.route = route;
    this.icon = icon;
  }
}

export enum BreadcrumbPageType {
  // Level 1
  STREAM_LIST,
  ROBOTS_LIST,
  OPERATIONS_LIST,
  FUNCTIONS_LIST,
  STORAGE_EXPLORER,
  ROBOT_DEFS_LIST,
  STORAGE_QUERY,
  AUTOMATE_LIST,
  AUTOMATE_INFO,

  // Level 2
  STREAM_INFO,
  ROBOT_INFO,
  OPERATION_INFO,
  FUNCTION_INFO,
  ROBOT_DEF_INFO,
  PROJECT_SETTINGS,
}

export class BreadcrumbHelper {
  static robotInfoRegex = /\/project\/.+\/robots\/.+\/.+/;
  static automateListRegex = /(\/project\/.+\/automate)$/;
  static automateInfoRegex = /\/project\/.+\/automate\/.+/;
  static streamInfoRegex = /\/project\/.+\/streams\/.+/;
  static operationInfoRegex = /\/project\/.+\/operations\/(local|global|custom)\/.+/;
  static functionInfoRegex = /\/project\/.+\/functions\/.+\/.+/;
  static robotDefInfoRegex = /\/project\/.+\/robot-profiles\/.+\/.+/;
  static streamListRegex = /(\/project\/.+\/streams)/;
  static robotsListRegex = /(\/project\/.+\/robots)/;
  static operationsListRegex = /(\/project\/.+\/operations)\/list/;
  static functionsListRegex = /(\/project\/.+\/functions)\/list/;
  static storageExplorerRegex = /\/project\/.+\/analyze\/data-explorer/;
  static robotDefsListRegex = /(\/project\/.+\/robot-profiles)\/list/;
  static storageQueryRegex = /\/project\/.+\/playground\/elasticsearch/;
  static projectSettingsRegex = /(\/project\/.+)\/settings\/.+/;

  private static operationInfoListPartRegex = /(\/project\/.+\/operations)/;
  private static functionInfoListPartRegex = /(\/project\/.+\/functions)/;
  private static automateInfoPartRegex = /(\/project\/.+\/automate)\/([^/\n\r]+)/;

  private static robotDefInfoListPartRegex = /(\/project\/.+\/robot-profiles)/;
  private static projectPartRegex = /(\/project\/.+)\/settings/;
  private static projectSettingsPartRegex = /(\/project\/.+\/settings)/;

  static getBreadcrumbByUrl(url: string) {
    const type = this.getTypeByUrl(url);

    return this.getBreadcrumb(type, url);
  }

  static getTypeByUrl(url: string) {
    if (this.robotInfoRegex.test(url)) {
      return BreadcrumbPageType.ROBOT_INFO;
    } else if (this.streamInfoRegex.test(url)) {
      return BreadcrumbPageType.STREAM_INFO;
    } else if (this.operationInfoRegex.test(url)) {
      return BreadcrumbPageType.OPERATION_INFO;
    } else if (this.functionInfoRegex.test(url)) {
      return BreadcrumbPageType.FUNCTION_INFO;
    } else if (this.robotDefInfoRegex.test(url)) {
      return BreadcrumbPageType.ROBOT_DEF_INFO;
    } else if (this.streamListRegex.test(url)) {
      return BreadcrumbPageType.STREAM_LIST;
    } else if (this.robotsListRegex.test(url)) {
      return BreadcrumbPageType.ROBOTS_LIST;
    } else if (this.operationsListRegex.test(url)) {
      return BreadcrumbPageType.OPERATIONS_LIST;
    } else if (this.functionsListRegex.test(url)) {
      return BreadcrumbPageType.FUNCTIONS_LIST;
    } else if (this.storageExplorerRegex.test(url)) {
      return BreadcrumbPageType.STORAGE_EXPLORER;
    } else if (this.robotDefsListRegex.test(url)) {
      return BreadcrumbPageType.ROBOT_DEFS_LIST;
    } else if (this.storageQueryRegex.test(url)) {
      return BreadcrumbPageType.STORAGE_QUERY;
    } else if (this.projectSettingsRegex.test(url)) {
      return BreadcrumbPageType.PROJECT_SETTINGS;
    } else if (this.automateInfoRegex.test(url)) {
      return BreadcrumbPageType.AUTOMATE_INFO;
    } else if (this.automateListRegex.test(url)) {
      return BreadcrumbPageType.AUTOMATE_LIST;
    }
    return undefined;
  }

  static getTargetUrl(type: BreadcrumbPageType, url: string, level: number = 0): string {
    let targetUrl = '';
    let match: RegExpMatchArray;
    switch (type) {
      case BreadcrumbPageType.STREAM_LIST:
      case BreadcrumbPageType.ROBOTS_LIST:
      case BreadcrumbPageType.OPERATIONS_LIST:
      case BreadcrumbPageType.FUNCTIONS_LIST:
      case BreadcrumbPageType.STORAGE_EXPLORER:
      case BreadcrumbPageType.ROBOT_DEFS_LIST:
      case BreadcrumbPageType.STORAGE_QUERY:
      case BreadcrumbPageType.AUTOMATE_LIST:
        targetUrl = url;
        break;
      case BreadcrumbPageType.STREAM_INFO:
        if (level === 1) {
          targetUrl = url;
        } else {
          match = url.match(this.streamListRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        }
        break;
      case BreadcrumbPageType.ROBOT_INFO:
        if (level === 1) {
          targetUrl = url;
        } else {
          match = url.match(this.robotsListRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        }
        break;
      case BreadcrumbPageType.OPERATION_INFO:
        if (level === 1) {
          targetUrl = url;
        } else {
          match = url.match(this.operationInfoListPartRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        }
        break;
      case BreadcrumbPageType.FUNCTION_INFO:
        if (level === 1) {
          targetUrl = url;
        } else {
          match = url.match(this.functionInfoListPartRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        }
        break;

      case BreadcrumbPageType.AUTOMATE_INFO:
        match = url.match(this.automateInfoPartRegex);
        if (level === 1) {
          if (match?.[2]) {
            targetUrl = match[2];
          } else {
            targetUrl = url;
          }
        } else {
          if (match?.[1]) {
            targetUrl = match[1];
          }
        }
        break;

      case BreadcrumbPageType.ROBOT_DEF_INFO:
        if (level === 1) {
          targetUrl = url;
        } else {
          match = url.match(this.robotDefInfoListPartRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        }
        break;
      case BreadcrumbPageType.PROJECT_SETTINGS:
        if (level === 1) {
          match = url.match(this.projectSettingsPartRegex);
          if (match?.[0]) {
            targetUrl = match[0];
          }
        } else {
          match = url.match(this.projectPartRegex);
          if (match?.[1]) {
            targetUrl = match[1];
          }
        }
        break;
    }

    return targetUrl;
  }

  static getBreadcrumb(type: BreadcrumbPageType, url: string) {
    const breadcrumbs: Breadcrumb[] = [];
    let bc: Breadcrumb;

    switch (type) {
      case BreadcrumbPageType.STREAM_LIST:
        bc = new Breadcrumb('Storage Streams', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;
      case BreadcrumbPageType.STREAM_INFO:
        bc = new Breadcrumb('Storage Streams', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb('', this.getTargetUrl(type, url, 1), true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.ROBOTS_LIST:
        bc = new Breadcrumb('Robots', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;
      case BreadcrumbPageType.ROBOT_INFO:
        bc = new Breadcrumb('Robots', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb('', this.getTargetUrl(type, url, 1), true, null, 'robotStatus');
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.OPERATIONS_LIST:
        bc = new Breadcrumb('Operations', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;
      case BreadcrumbPageType.OPERATION_INFO:
        bc = new Breadcrumb('Operations', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb('', this.getTargetUrl(type, url, 1), true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.STORAGE_EXPLORER:
        bc = new Breadcrumb('Storage Explorer', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.ROBOT_DEFS_LIST:
        bc = new Breadcrumb('Robot Profiles', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;
      case BreadcrumbPageType.ROBOT_DEF_INFO:
        bc = new Breadcrumb('Robot Profiles', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb('', this.getTargetUrl(type, url, 1), true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.STORAGE_QUERY:
        bc = new Breadcrumb('Storage Query', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.AUTOMATE_LIST:
        bc = new Breadcrumb('Automate', this.getTargetUrl(type, url), true);
        breadcrumbs.push(bc);
        break;
      case BreadcrumbPageType.AUTOMATE_INFO:
        bc = new Breadcrumb('Automate', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb(this.getTargetUrl(type, url, 1), url, true);
        breadcrumbs.push(bc);
        break;

      case BreadcrumbPageType.PROJECT_SETTINGS:
        bc = new Breadcrumb('Project', this.getTargetUrl(type, url, 0), false);
        breadcrumbs.push(bc);
        bc = new Breadcrumb('Settings', this.getTargetUrl(type, url, 1), true);
        breadcrumbs.push(bc);
        break;
      default:
        break;
    }

    return breadcrumbs;
  }
}
