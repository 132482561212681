import { Injectable } from '@angular/core';
import { BabylonRendererService } from './babylon-renderer.service';
import { Axis, Mesh, Vector3, Path3D, Space, Color4 } from '@babylonjs/core';
import { GuiFactoryService } from './factories/gui-factory.service';
import { evaluateToNumberArray } from '../../utils/evaluate-to-number';
import { WaypointBridgeService } from '@shared/services/waypoint-bridge/waypoint-bridge.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RendererService } from './renderer.service';
import type { IWorkflowWaypoint } from '../waypoint-bridge';

const WAYPOINT_GUI_PARENT_NAME = 'waypoint-gui-parent';
@Injectable()
export class WaypointRendererService {
  private workflowWaypoints: IWorkflowWaypoint[];
  private destroy$ = new Subject<void>();

  public constructor(
    private renderer: RendererService,
    private babylonRenderer: BabylonRendererService,
    private guiFactoryService: GuiFactoryService,
    private waypointBridge: WaypointBridgeService,
  ) {}

  public init(): void {
    combineLatest([this.renderer.isReady$, this.babylonRenderer.isReady$, this.waypointBridge.waypoints$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([rendererReady, babylonReady, waypoints]: [boolean, boolean, IWorkflowWaypoint[]]) => {
          return rendererReady && babylonReady && Boolean(waypoints?.length);
        }),
      )
      .subscribe({
        next: ([_, __, waypoints]: [boolean, boolean, IWorkflowWaypoint[]]) => {
          this.renderWayPoints(waypoints);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  public destroy(): void {
    this.destroy$.next();
  }

  private renderWayPoints(waypoints: IWorkflowWaypoint[]): void {
    this.removeWaypoints();
    this.transformWaypoints(waypoints);
    this.workflowWaypoints = waypoints;

    waypoints.forEach((wp) => {
      this.addWorkflowWaypoint(wp);
    });

    waypoints.forEach((wp) => {
      if (wp.prev) this.addWaypointVector(wp);
    });
  }

  private transformWaypoints(waypoints: IWorkflowWaypoint[]): void {
    waypoints.forEach((wp) => {
      const transformedVector = this.babylonRenderer.pointFromFrame(
        wp.frame,
        new Vector3(...evaluateToNumberArray(wp.pos)),
      );
      wp.pos = [transformedVector.x, transformedVector.y, transformedVector.z];
    });
  }

  private addWorkflowWaypoint(waypoint: IWorkflowWaypoint): void {
    const id = `waypoint-${waypoint.id}`;

    const existingMarker = this.babylonRenderer.getMesh(id);
    if (existingMarker) existingMarker.dispose();

    const marker = this.babylonRenderer.createSphere(id, {
      diameter: waypoint.diameter || 0.2,
      segments: 32,
    });

    marker.position = new Vector3(...evaluateToNumberArray(waypoint.pos));
    marker.material = this.babylonRenderer.getMaterial(waypoint.material || 'green');
    this.guiFactoryService.addGuiLabel(`${waypoint.label}`, marker, WAYPOINT_GUI_PARENT_NAME);
  }

  private removeWaypoints(): void {
    if (!this.workflowWaypoints) return;

    this.workflowWaypoints.forEach((wp) => {
      this.babylonRenderer.deleteMesh(`waypoint-${wp.id}`);

      // Delete connections
      if (wp.prev?.length > 0) {
        this.babylonRenderer.deleteMesh(`${wp.prevId}-${wp.id}`);
        this.babylonRenderer.deleteMesh(`arrow-${wp.id}`);
      }
    });
    this.guiFactoryService.removeAllGuiControlsOfParentName(WAYPOINT_GUI_PARENT_NAME);
  }

  private addWaypointVector(waypoint: IWorkflowWaypoint): void {
    const mesh = this.babylonRenderer.getMesh(`waypoint-${waypoint.id}`);
    if (!mesh) return;
    const prevMesh = this.babylonRenderer.getMesh(`waypoint-${waypoint.prevId}`);
    if (!prevMesh) return;

    const a = prevMesh.position;
    const b = mesh.position;

    const triangleMesh = this.babylonRenderer.createCylinder(`arrow-${waypoint.id}`, {
      tessellation: 20,
      diameterTop: 0,
      diameterBottom: 0.15,
      height: 0.25,
    });
    const path = new Path3D([a, b]);
    triangleMesh.position = b;
    triangleMesh.rotation = Vector3.RotationFromAxis(
      path.getNormals()[0],
      path.getBinormals()[0],
      path.getTangents()[0],
    );
    triangleMesh.addRotation(Math.PI / 2, 0, 0);
    triangleMesh.translate(Axis.Y, -0.3, Space.LOCAL);
    triangleMesh.addRotation(0, Math.PI / 4, 0);
    triangleMesh.material = this.babylonRenderer.getMaterial('green');

    const lineDistance = Vector3.Distance(a, b);
    const dashSize = 0.5;
    const dashGap = 0.5;
    const dashNb = Math.min(50, lineDistance / dashSize);

    const lineId = `${waypoint.prevId}-${waypoint.id}`;
    const newLines = waypoint.possiblePath
      ? this.babylonRenderer.createDashLines(lineId, {
          points: [a, triangleMesh.position],
          dashGap,
          dashNb,
          dashSize,
        })
      : this.babylonRenderer.createTube(lineId, {
          path: [a, triangleMesh.position],
          radius: 0.025,
          cap: Mesh.CAP_ALL,
        });

    if (newLines && waypoint.possiblePath) {
      newLines.enableEdgesRendering();
      newLines.edgesWidth = 8.0;
      newLines.edgesColor = new Color4(0.05, 0.92, 0.075, 1);
    } else if (newLines) {
      newLines.material = this.babylonRenderer.getMaterial('green');
    }
  }
}
