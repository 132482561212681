import type { OnInit, OnDestroy } from '@angular/core';
import { Component, NgZone } from '@angular/core';
import { RocosClientService } from '../../services';
import type { FastLaneManager, ISubscribersInfo } from '@team-rocos/rocos-js';
import type { Subscription } from 'rxjs';
import type { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-widget-apm',
  templateUrl: './widget-apm.component.html',
  styleUrls: ['./widget-apm.component.scss'],
})
export class WidgetApmComponent implements OnInit, OnDestroy {
  public subscribersInfo: ISubscribersInfo;

  public windowSize: number = 0;

  public subIds: string[] = [];

  private fastLaneManager: FastLaneManager;
  private subscription: Subscription;

  constructor(private rocosClientService: RocosClientService, private ngZone: NgZone) {
    this.fastLaneManager = this.rocosClientService.fastLaneManager;
  }

  ngOnInit() {
    if (this.fastLaneManager) {
      this.subscription = this.fastLaneManager.subscriptionsChanged.subscribe((info) => {
        this.ngZone.run(() => {
          this.subscribersInfo = info;

          this.updateSubIdsByInfo();
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClickBackground() {
    const windowSize = this.windowSize;
    this.windowSize = (windowSize + 1) % 3;
  }

  onDebugLevelChange(change: MatButtonToggleChange) {
    const level = change.value;
    this.rocosClientService.changeDebugLevel(level);
  }

  private updateSubIdsByInfo() {
    if (this.subscribersInfo) {
      this.subIds = Object.keys(this.subscribersInfo);
    }
  }
}
