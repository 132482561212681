import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { ConfirmDialogComponent } from './confirm-dialog';
import { UnpublishedChangesDialogComponent } from './unpublished-changes-dialog';
import { TranslocoModule } from '@ngneat/transloco';

const components = [ConfirmDialogComponent, UnpublishedChangesDialogComponent];

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, TranslocoModule],
  declarations: [...components],
  exports: [...components],
})
export class DialogsModule {}
