import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { AudioStatus } from '@shared-modules/widgets/audio-widget/audio-widget.component';
import type { WidgetStatus } from '../../models';
import { ValueEvalPipe } from '../../pipes';
import type { SubscribeResult } from '../../services';
import { RocosClientService } from '../../services';
import { Utils } from '../../utils';
import type { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WidgetBaseComponent, WidgetToolbarItem } from '../shared';

enum EConnectionStatus {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Connecting',
}
@Component({
  selector: 'app-widget-audio',
  templateUrl: './widget-audio.component.html',
  styleUrls: ['./widget-audio.component.scss'],
})
export class WidgetAudioComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  @Input() public sampleFormat: string;
  @Input() public sampleRate: number;
  @Input() public encodingFormat: string;
  @Input() public numberOfChannel: number;
  @Input() public callsign: string;
  @Input() public projectId: string;
  @Input() public source: string;

  public connectionStatus = EConnectionStatus;
  public currentConnectionStatus = EConnectionStatus.DISCONNECTED;
  public shouldConnect: boolean = false;
  public value: string;
  public valueEvalPipe: ValueEvalPipe;
  public extractedValue: string;
  private dataSource: SubscribeResult;
  private subscription: Subscription;
  private lastValueUpdatedAt: Date;
  private widgetStatusTimer: any;

  public constructor(private rocosClientService: RocosClientService) {
    super();
  }

  public ngOnDestroy(): void {
    if (this.widgetStatusTimer) {
      clearInterval(this.widgetStatusTimer);
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onAudioStatusChanged($event: AudioStatus): void {
    if ($event === AudioStatus.disconnected) {
      this.shouldConnect = false;
      this.currentConnectionStatus = EConnectionStatus.DISCONNECTED;
      this.disconnect();
    }
  }

  public ngOnInit(): void {
    this.valueEvalPipe = new ValueEvalPipe();
    this.updateToolbarItems();
  }

  public override onToolbarItemClick(event: WidgetToolbarItem): void {
    if (event.text === 'CONNECT') {
      this.shouldConnect = true;
      this.connect();
    } else if (event.text === 'DISCONNECT' || event.text === 'CANCEL') {
      this.shouldConnect = false;
      this.disconnect();
    }
  }

  public dataSourceHandler(): Observable<string> {
    const targetField = Utils.getTargetFieldByDataURI(this.dataURI, this.source);
    const targetSource = Utils.getTargetSourceWithQueryByDataURI(this.dataURI, this.source);

    const dataSource = this.rocosClientService.subscribe(this.projectId, [this.callsign], [targetSource]);

    this.dataSource = dataSource;

    // Source Observable
    return dataSource.observable.pipe(
      map((msg) => Utils.getTargetData<string>(msg, targetField)),
      tap((_) => (this.lastValueUpdatedAt = new Date())),
    );
  }

  public dataObserver(): Subscription {
    return this.dataSourceHandler().subscribe((audioData) => {
      this.value = audioData;
      this.extractedValue = this.valueEvalPipe.transform(this.value, this.valueExpression);
      if (this.currentConnectionStatus !== EConnectionStatus.CONNECTED) {
        this.currentConnectionStatus = EConnectionStatus.CONNECTED;
        this.updateToolbarItems();
      }
    });
  }

  public connect(): void {
    this.currentConnectionStatus = EConnectionStatus.CONNECTING;
    this.subscription = this.dataObserver();

    this.createWidgetStatusTimer();
    this.updateToolbarItems();
  }

  public disconnect(): void {
    this.subscription.unsubscribe();
    this.rocosClientService.unsubscribe(this.dataSource);
    this.currentConnectionStatus = EConnectionStatus.DISCONNECTED;
    this.value = null;

    if (this.widgetStatusTimer) {
      clearInterval(this.widgetStatusTimer);
    }

    this.status = 'alive';
    this.updateToolbarItems();
  }

  private createWidgetStatusTimer() {
    // Clear first.
    if (this.widgetStatusTimer) {
      clearInterval(this.widgetStatusTimer);
    }

    this.lastValueUpdatedAt = new Date();

    this.widgetStatusTimer = setInterval(() => {
      this.updateToolbarItems();
      const now = new Date();
      if ((now.getTime() - this.lastValueUpdatedAt.getTime()) / 1000 > this.widget.widgetTimeout) {
        // Timeout
        this.status = 'dead' as WidgetStatus;
      } else {
        this.status = 'alive' as WidgetStatus;
      }
    }, 1000);
  }

  private updateToolbarItems() {
    const items = [];
    if (this.currentConnectionStatus === EConnectionStatus.DISCONNECTED) {
      items.push(
        new WidgetToolbarItem('CONNECT', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button'),
      );
    } else if (this.currentConnectionStatus === EConnectionStatus.CONNECTING) {
      items.push(
        new WidgetToolbarItem('CANCEL', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button'),
      );
    } else if (this.currentConnectionStatus === EConnectionStatus.CONNECTED) {
      items.push(
        new WidgetToolbarItem(
          'DISCONNECT',
          '',
          'primary',
          '',
          'button',
          [],
          'right',
          'button',
          'stream-control-button',
        ),
      );
    }
    this.toolbarItems = items;
  }
}
