<div class="graphs">
  <div class="canvas-stats">
    <div>Latest message: {{ payloadSize.toFixed(3) }} KB</div>
    <div>Total transferred: {{ payloadSizeSum.toFixed(3) }} KB</div>
    <div>Total messages: {{ payloadCount }}</div>
  </div>
  <div>
    <div #canvasWrapMessage class="canvas-wrapper">
      <canvas #canvasMessage></canvas>
    </div>
    <div class="canvas-stats">
      <div>Interval between messages: {{ lastInterval }} ms</div>
    </div>
    <div #canvasWrapInterval class="canvas-wrapper">
      <canvas #canvasInterval></canvas>
    </div>
  </div>
  <!-- <div #canvasWrapSize class="canvas-wrapper">
    <canvas #canvasSize></canvas>
  </div> -->
  <!-- <canvas #renderCanvas 
    [height]="canvasWrapper.offsetHeight" 
    [width]="canvasWrapper.offsetWidth">
  </canvas> -->
</div>
