import { Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-horizon',
  templateUrl: './widget-horizon.component.html',
  styleUrls: ['../shared/styles.scss', './widget-horizon.component.scss'],
})
export class WidgetHorizonComponent extends WidgetBaseComponent {
  @Input() rollValue;
  @Input() pitchValue;
  @Input() yawValue;

  constructor() {
    super();
  }

  public radianToDegrees(radians: number): number {
    return Math.round((radians * 180) / Math.PI);
  }
}
