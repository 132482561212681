<dialog #commandPaletteDialog>
  <div class="command-palette">
    <div class="header">
      <div class="breadcrumbs">
        <ng-container *ngFor="let crumb of breadcrumb$ | async">
          <span
            ><strong>{{ crumb }}</strong></span
          ><span> / </span>
        </ng-container>
      </div>
      <form [formGroup]="searchForm">
        <input
          autofocus
          type="text"
          placeholder="Search or jump to..."
          #searchInput
          formControlName="search"
          (keydown.tab)="handleTab()"
          (keydown.backspace)="handleBackspace(searchInput.value)"
          (keydown.enter)="handleEnter()"
        />
      </form>
    </div>
    <hr />
    <div class="body">
      <ng-container *ngIf="(showLoading$ | async) === false; else loadingSpinner">
        <ng-container *ngFor="let action of filteredSuggestions; let index = index">
          <p class="suggestion" [ngClass]="{ 'selected': index === selectionIndex }" #listItem>{{ action.name }}</p>
        </ng-container>
        <ng-container *ngIf="filteredSuggestions.length === 0">
          <p><small>No suggestions found. Press enter to jump to the current location</small></p>
        </ng-container>
      </ng-container>
    </div>
    <div class="footer">
      <div class="hint">
        <p>
          <small
            ><kbd>↑</kbd>/<kbd>↓</kbd> to select. <kbd>Tab</kbd> to complete from selection. <kbd>Enter</kbd> to
            navigate to current level. <kbd>Backspace</kbd> to go up a level.</small
          >
        </p>
      </div>
    </div>
  </div>
</dialog>

<ng-template #loadingSpinner>
  <div class="indent">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
