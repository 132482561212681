import type { VideoSourceStatus } from './video-source';

export class DebugInfos {
  lastWebrtcFramesDecoded: number = -1;
  lastWebrtcFramesDecodedChanged: boolean = true;
  lastWebrtcFramesReceived: number = -1;
  lastWebrtcFramesReceivedChanged: boolean = true;

  updateWebrtcDataFrames(videoSourceStatus: VideoSourceStatus): void {
    if (videoSourceStatus) {
      this.lastWebrtcFramesDecodedChanged =
        this.lastWebrtcFramesDecoded !== videoSourceStatus.webrtcFramesDecoded ||
        videoSourceStatus.webrtcFramesDecoded === 0;

      this.lastWebrtcFramesReceivedChanged =
        this.lastWebrtcFramesReceived !== videoSourceStatus.webrtcFramesReceived ||
        videoSourceStatus.webrtcFramesReceived === 0;
      this.lastWebrtcFramesDecoded = videoSourceStatus.webrtcFramesDecoded;
      this.lastWebrtcFramesReceived = videoSourceStatus.webrtcFramesReceived;
    }
  }
}
