import type { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { OpsPageType } from '../../models';
import { WidgetBaseComponent, WidgetToolbarItem } from '../shared';
import { WidgetOperationGlobalComponent } from './widget-operation-global';
import { WidgetOperationLocalComponent } from './widget-operation-local';

@Component({
  selector: 'app-widget-operation',
  templateUrl: './widget-operation.component.html',
  styleUrls: ['./widget-operation.component.scss'],
})
export class WidgetOperationComponent extends WidgetBaseComponent implements OnInit, OnChanges {
  @Input() public demoMode: boolean = false;
  @Input() public pageId: string;
  @Input() public pageType: OpsPageType;
  @Input() public projectId: string;
  @Input() public override toolbarItems: WidgetToolbarItem[] = [];
  @Input() public gridComponentHeight: number;
  @Input() public gridComponentWidth: number;
  @Input() public callsign: string;
  @Input() public gridSizeChangedCount: number = 0;

  @Output() public dataChanged: EventEmitter<void> = new EventEmitter();

  @ViewChild('localOps')
  public localOps: WidgetOperationLocalComponent;

  @ViewChild('globalOps')
  public globalOps: WidgetOperationGlobalComponent;

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.updateToolbarItems();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['gridComponentHeight'] || changes['gridComponentWidth']) {
      this.localOps?.resizeComponent();
    }

    if (changes['gridSizeChangedCount']) {
      this.globalOps?.resizeComponent();
    }
  }

  private updateToolbarItems(): void {
    const items = [];

    const link = this.getOpsLink();

    items.push(
      new WidgetToolbarItem(
        '',
        'ri-internal-link',
        'grey',
        'Open as operation page',
        this.pageType,
        link,
        'left',
        'link',
      ),
    );

    this.toolbarItems = items;
  }

  private getOpsLink() {
    return ['/project', this.projectId, 'operations', this.pageType, this.pageId];
  }
}
