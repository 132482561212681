import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export type StatusDotType = 'alive' | 'dead' | 'unknown' | 'error';

@Component({
  selector: 'app-status-dot',
  template: `<div class="dot" [ngClass]="this.cssClass"></div>`,
  styleUrls: ['./status-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusDotComponent {
  @Input() public status: StatusDotType;
  @Input() public size: 'large' | 'medium' | 'small' = 'medium';
  @Input() public dark: boolean = false;

  public get cssClass(): string {
    const sizeClass = this.size ?? 'medium';
    const darkClass = this.dark ? 'dark' : '';

    let name: string;
    switch (this.status) {
      case 'alive':
        name = 'live';
        break;
      case 'dead':
        name = 'offline';
        break;
      case 'error':
        name = 'error';
        break;
      default:
        name = 'unknown';
        break;
    }

    return `${name} ${sizeClass} ${darkClass}`;
  }
}
