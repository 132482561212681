import { Injectable } from '@angular/core';
import { AppService, RocosSdkClientService } from '@shared/services';
import type { MapService } from '@dronedeploy/rocos-js-sdk';
import type { Observable, Subscription } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { Subject, lastValueFrom, throwError, interval, takeWhile } from 'rxjs';
import { distinctUntilChanged, finalize, first, map, switchMap, takeUntil, timeout } from 'rxjs/operators';
import type { FeatureCollection } from 'geojson';
import { isEqual } from 'lodash';

export interface AgentMap {
  cloud: boolean;
  edges: number;
  nodes: number;
  id: string;
  hash: string;
  ready: boolean;
  timestamp: number;
}

export interface RobotMap {
  id: string;
  hash?: string;
  description?: string;
  nodes: number;
  edges: number;
  sizeBytes?: number;
  isDeployed: boolean;
  deployedCount?: number;
  isInCloud: boolean;
  isAgentReady?: boolean;
  isUploading?: boolean;
  deploymentCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class RobotMapsService {
  private mapService: MapService;

  constructor(private rocosSDKClient: RocosSdkClientService, private appService: AppService) {
    this.mapService = this.rocosSDKClient.client.getMapService();
  }

  public getCloudMaps$(refreshWhenUploading = false): Observable<RobotMap[]> {
    const output$ = new Subject<RobotMap[]>();

    this.mapService.list(this.appService.projectId, this.appService.callsign).then((cloudMaps) => {
      output$.next(
        cloudMaps.map((cloudMap) => ({
          id: cloudMap.id,
          hash: cloudMap.hash,
          deployedCount: cloudMap.deployedCount,
          isDeployed: cloudMap.deployed,
          isUploading: cloudMap.isUploading,
          isInCloud: cloudMap.isInCloud,
          edges: cloudMap.edges,
          nodes: cloudMap.nodes,
          sizeBytes: cloudMap.sizeBytes,
          description: cloudMap.description,
        })),
      );

      const someMapsUploading = cloudMaps.some((cloudMap) => cloudMap.isUploading);
      if (someMapsUploading && refreshWhenUploading) {
        // TODO: RT-1361 - this should not be called inside a service
        // as there's no way to close/complete it if you navigate away
        this.pollMapsWhileUploading().subscribe({
          next: (polledMaps) => output$.next(polledMaps),
          error: (err) => output$.error(err),
          complete: () => output$.complete(),
        });
      } else {
        output$.complete();
      }
    });

    return output$.asObservable();
  }

  public getAgentMaps$(): Observable<RobotMap[]> {
    return this.rocosSDKClient.client
      .getTelemetryService()
      .subscribe({
        projectId: this.appService.projectId,
        callsigns: [this.appService.callsign],
        sources: ['/map/staticMaps'],
      })
      .pipe(
        map((res) => res.payload as Record<string, AgentMap>),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map((res) =>
          Object.entries(res).map(([_, agentMap]) => ({
            id: agentMap.id,
            hash: agentMap.hash,
            isDeployed: agentMap.cloud,
            isInCloud: agentMap.cloud,
            edges: agentMap.edges,
            nodes: agentMap.nodes,
            isAgentReady: agentMap.ready,
          })),
        ),
      );
  }

  /** Returns a list of maps that are either in the cloud or on the agent
   *
   * The cloud maps are only queried once. Use getCloudMaps() to get the latest cloud maps.
   *
   * @see getCloudMaps()
   * @see getAgentMaps$()
   */
  public getCombinedMaps$(): Observable<RobotMap[]> {
    const out = new Subject<RobotMap[]>();

    let cloudMaps: RobotMap[] = [];
    let agentMaps: RobotMap[] = [];

    this.getCloudMaps$().subscribe({
      next: (res) => {
        cloudMaps = res;
        out.next(this.mergeMapLists(cloudMaps, agentMaps));

        if (!this.appService.callsign) {
          out.complete();
        }
      },
      error: (err) => {
        out.error(err);
      },
    });

    let staticMapsSub: Subscription | undefined;
    if (this.appService.callsign) {
      staticMapsSub = this.getAgentMaps$().subscribe({
        next: (res) => {
          agentMaps = res;
          out.next(this.mergeMapLists(cloudMaps, res));
        },
        error: (err) => {
          out.error(err);
        },
      });
    }

    return out.pipe(
      finalize(() => {
        if (staticMapsSub) staticMapsSub.unsubscribe();
      }),
    );
  }

  public async saveToAgent(dynamicMapId: string, saveAsId: string): Promise<{ hash: string }> {
    const caller = this.rocosSDKClient.client.getCallerService().call<{ hash: string }>({
      callsign: this.appService.callsign,
      projectId: this.appService.projectId,
      source: '/map/staticMaps/save',
      payload: {
        dynamicMapId,
        saveAsId,
      },
    });

    return await lastValueFrom(caller.return$);
  }

  public getAdapters$(): Observable<Record<string, { nodes: number; edges: number }>> {
    if (!this.appService.callsign) {
      return throwError(() => new Error('No callsign selected'));
    }

    return this.rocosSDKClient.client
      .getTelemetryService()
      .subscribe({
        projectId: this.appService.projectId,
        callsigns: [this.appService.callsign],
        sources: ['/map/dynamicMaps'],
      })
      .pipe(
        timeout(10000),
        first(),
        map((res) => res.payload as any),
      );
  }

  public async deleteMapFromRobot(mapId: string, hash: string) {
    return this.mapService.delete(this.appService.projectId, mapId, this.appService.callsign, hash);
  }
  public async deleteMap(mapId: string) {
    return this.mapService.delete(this.appService.projectId, mapId);
  }

  public async updateMap(
    mapId: string,
    payload: {
      description?: string;
      callsign?: string;
    },
  ) {
    return this.mapService.update(this.appService.projectId, mapId, payload);
  }

  public getGeoJSON(mapId: string, frameId?: string): Promise<FeatureCollection> {
    return this.mapService.getGeoJSON(this.appService.projectId, mapId, frameId) as Promise<FeatureCollection>;
  }

  public sendToCloud(mapId: string, hash: string, callsign: string): Promise<void> {
    return this.mapService.sendToCloud(this.appService.projectId, mapId, callsign, hash);
  }

  /**
   * Uploads a map to the cloud
   *
   * @returns observable which emits true when the map is ready in the cloud
   */
  public uploadMapToCloud(id: string, hash: string): Observable<boolean> {
    const finished$ = new Subject<void>();
    const out$ = new Subject<boolean>();

    this.sendToCloud(id, hash, this.appService.callsign).then(() => {
      out$.next(false);

      // TODO: RT-1361 - this should not be called inside a service as
      // there's no way to close/complete it if you navigate away
      // this whole observable should be returned instead of just the out$ subject
      this.pollMapsWhileUploading()
        .pipe(
          takeUntil(finished$),
          map((maps) => maps.find((i) => i.id === id)),
        )
        .subscribe({
          next: (cloudMap) => {
            if (cloudMap && !cloudMap.isUploading) {
              finished$.next();
              out$.next(true);
              finished$.complete();
              out$.complete();
            }
          },
          complete: () => {
            finished$.next();
            out$.next(true);
            finished$.complete();
            out$.complete();
          },
          error: (err) => {
            finished$.next();
            out$.error(err);
            finished$.complete();
            out$.complete();
          },
        });
    });

    return out$.asObservable();
  }

  /** Merges the cloud and agent maps into a single list
   *
   * If a map exists in both lists, the isDeployed flag is set to true
   *
   * @param cloudMaps list of maps from the cloud
   * @param agentMaps list of maps from the agent
   * @returns merged list of maps
   */
  public mergeMapLists<T extends RobotMap>(cloudMaps: T[], agentMaps: T[]): T[] {
    const maps: Array<T> = [];

    for (const robotMap of cloudMaps) {
      maps.push(robotMap);
    }

    for (const agentMap of agentMaps) {
      const idx = maps.findIndex((m) => m.hash === agentMap.hash);
      const cloudMap = maps[idx];
      if (cloudMap) {
        if (!cloudMap.isInCloud) {
          // newly saved maps before they have been uploaded to the cloud
          maps[idx].edges = agentMap.edges;
          maps[idx].nodes = agentMap.nodes;
          maps[idx].hash = agentMap.hash;
        }

        maps[idx].isAgentReady = agentMap.isAgentReady;
      } else {
        maps.unshift(agentMap);
      }
    }

    return maps;
  }

  public async loadMap(mapId: string, adaptor: string, hash: string) {
    await lastValueFrom(
      this.rocosSDKClient.callService('/map/staticMaps/load', {
        dynamicMapId: adaptor,
        sourceId: mapId,
        sourceHash: hash,
      }),
    );
  }

  public async callService(projectId: string, callsign: string, source: string) {
    const { return$ } = this.rocosSDKClient.client.getCallerService().call({ projectId, callsign, source });
    return await firstValueFrom(return$);
  }

  public callServiceStream(projectId: string, callsign: string, source: string, payload: unknown) {
    const { return$ } = this.rocosSDKClient.client.getCallerService().call({ projectId, callsign, source, payload });
    return return$;
  }

  public async deployMap(mapId: string) {
    return this.rocosSDKClient.client
      .getMapService()
      .deploy(this.appService.projectId, mapId, this.appService.callsign);
  }

  private pollMapsWhileUploading(): Observable<RobotMap[]> {
    return interval(5000).pipe(
      switchMap(() => this.getCloudMaps$(false)),
      takeWhile((maps) => maps.some((robotMap) => robotMap.isUploading)),
    );
  }
}
