import { WidgetBase } from './widget-base';

export class WidgetHTML extends WidgetBase {
  html?: string;

  public static fromModel(model: any): WidgetHTML {
    const widget = new WidgetHTML();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.minItemCols = model.minItemCols ? model.minItemCols : 10;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;

    this.html = model.html;
  }
}
