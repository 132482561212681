import { Injectable } from '@angular/core';
import { AppService } from './app.service';

const contextVariableName = 'context';

@Injectable({
  providedIn: 'root',
})
export class JsContextService {
  private projectId: string;
  private accountId: string;
  private callsign: string;

  constructor(private appService: AppService) {
    this.appService.projectIdChange.subscribe((projectId) => {
      this.projectId = projectId;
      this.updateRocosContextVariablesValues('projectId');
    });
    this.appService.accountIdChange.subscribe((accountId) => {
      this.accountId = accountId;
      this.updateRocosContextVariablesValues('accountId');
    });
    this.appService.callsignChange.subscribe((callsign) => {
      this.callsign = callsign;
      this.updateRocosContextVariablesValues('callsign');
    });
  }

  public updateCallsign(callsign: string): void {
    this.callsign = callsign;
    this.updateRocosContextVariablesValues('callsign');
  }

  private updateRocosContextVariablesValues(variableName: string) {
    if (!window?.['rocos']) window['rocos'] = {};
    if (!window['rocos']?.[contextVariableName]) window['rocos'][contextVariableName] = {};

    switch (variableName) {
      case 'accountId':
        window['rocos'][contextVariableName]['accountId'] = this.accountId;
        break;
      case 'projectId':
        window['rocos'][contextVariableName]['projectId'] = this.projectId;
        break;
      case 'callsign':
        window['rocos'][contextVariableName]['callsign'] = this.callsign;
        break;
      default:
        break;
    }
  }
}
