export const numberHasValue = (numberValue: number): boolean => {
  return numberValue !== undefined && numberValue != null;
};

export const boolHasValue = (boolValue): boolean => {
  return boolValue !== undefined && boolValue != null;
};

export const boolHasValueAndTrue = (boolValue): boolean => {
  return !!(boolValue !== undefined && boolValue != null && boolValue);
};
