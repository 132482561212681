import type { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmptyInputValue } from './shared';

const isValidJson = (jsonStr: string): boolean => {
  // Ref: https://stackoverflow.com/a/8431765/4288118
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (e) {
    return false;
  }
};

export class JSONValidator {
  public static json(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return isValidJson(control.value) ? null : { 'json': true };
  }

  public static isArray(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    if (isValidJson(control.value) && Array.isArray(JSON.parse(control.value))) {
      return null;
    } else {
      return { 'isJSONArray': 'must be an array' };
    }
  }
}
