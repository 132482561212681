<app-widget-base
  [heading]="heading"
  [type]="type"
  [dataURI]="dataURI"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div class="center-container" [ngClass]="[theme]">
    <div [innerHTML]="html | html"></div>
  </div>
</app-widget-base>
