import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-robot-clock',
  template: `
    <app-loading-spinner [isLoading]="true" theme="light-grey" *ngIf="isLoading; else icon"></app-loading-spinner>
    <ng-template #icon><span class="icon ri-robots"></span></ng-template>
    <div>
      <h4>
        Robot Time <ng-container *ngIf="!isOffline">({{ robotTimeZone }})</ng-container>
      </h4>
      <p *ngIf="!isOffline; else offline">{{ roundedTime | date : 'E, d MMM y H:mm:ss' : timeZoneOffset }}</p>
      <ng-template #offline>
        <p *ngIf="isLoading; else offlineText">-</p>
        <ng-template #offlineText>Robot Offline</ng-template>
      </ng-template>
    </div>
  `,
  styleUrls: ['../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RobotClockComponent implements OnInit {
  @Input() robotTimeZone?: string;

  @Input()
  set robotTime(val: number) {
    this._robotTime = val;
    this.lastValueReceived = new Date();
  }

  get robotTime() {
    return this._robotTime;
  }

  private _robotTime?: number;
  private timeoutPassed: boolean;
  private lastValueReceived?: Date;

  @HostBinding('class.offline')
  get isOffline(): boolean {
    if (!this.robotTime || !this.robotTimeZone || this.isLoading) return true;

    // ensure we don't have a stale time, else show offline state
    return this.lastValueReceived?.getTime() + 5000 < new Date().getTime();
  }

  get isLoading() {
    return !this.timeoutPassed && (!this.robotTime || !this.robotTimeZone);
  }

  get roundedTime(): number {
    if (!this.robotTime) return this.robotTime;

    return Math.round(this.robotTime / 1000 / 1000);
  }

  get timeZoneOffset() {
    if (!this.robotTimeZone) return undefined;

    return this.robotTimeZone.substring(this.robotTimeZone.indexOf(':') + 1);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    interval(5000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.timeoutPassed = true;
        this.cdr.markForCheck();
      });
  }
}
