<div class="rs-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 class="title" mat-dialog-title>
      <!-- Title -->
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'add'">
          <span>Script Name</span>
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
          <span>Script Name</span>
        </ng-container>
      </ng-container>

      <!-- Right buttons -->
      <span class="right-buttons">
        <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div>
      <mat-form-field>
        <input matInput formControlName="javascriptName" placeholder="Script Name" cdkFocusInitial />
        <mat-error *ngIf="javascriptName?.errors?.['required']">Enter Script Name.</mat-error>
        <mat-error *ngIf="javascriptName?.errors?.['pattern']">Enter a valid Script name.</mat-error>
      </mat-form-field>
    </div>
    <div class="footer">
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'add'">
          <app-loading-button [loading]="isLoading" (clicked)="onSubmit()">
            <span>Save</span>
          </app-loading-button>
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
          <app-loading-button [loading]="isLoading" (clicked)="onSubmit()">
            <span>Save</span>
          </app-loading-button>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
