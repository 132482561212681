import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthStateService } from '@core/shared';
import type { IsLoading } from '@shared/interfaces';
import { UserService, AuthService, ToastService, GotoService, AccountService } from '@shared/services';
import type { InvitationStatus } from '@shared/models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-no-account',
  templateUrl: './no-account.component.html',
  styleUrls: ['./no-account.component.scss'],
})
export class NoAccountComponent implements OnInit, IsLoading {
  invitations: InvitationStatus[] = [];
  hasInvites: boolean = true;
  isLoading: boolean = false;

  form: UntypedFormGroup;
  isCreatingAccount: boolean = false;

  public get accountName() {
    return this.form?.get('accountName');
  }

  displayedColumnsForReceived = ['sentFrom', 'created', 'type', 'acceptedStatus', 'accepted'];

  constructor(
    private authStateService: AuthStateService,
    private userService: UserService,
    private authService: AuthService,
    private toast: ToastService,
    private goto: GotoService,
    private accountService: AccountService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    setTimeout(() => {
      this.authStateService.warningMsg.next("There's no account or project associated with your user profile.");

      this.loadInvitationsList();
    }, 0);
  }

  onAccept(invitation: InvitationStatus) {
    invitation.isLoading = true;

    const errorCallback = (err: any) => {
      this.toast.short(`Failed. ${err.message}`, null, 'failure');
      invitation.isLoading = false;
      this.loadInvitationsList();
    };

    this.userService.acceptInvitation(invitation.id).subscribe(
      () => {
        this.refreshToken((err) => {
          if (!err) {
            this.openInvite(invitation);
          } else {
            errorCallback(err);
          }
        });
      },
      (err) => {
        errorCallback(err);
      },
    );
  }

  onCreateAccount() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    if (this.isCreatingAccount) {
      return;
    }

    this.isCreatingAccount = true;

    const accountName = this.form.value['accountName'];

    this.accountService.createAccount(accountName).subscribe(
      () => {
        this.refreshToken((err, accounts: any[]) => {
          if (!err) {
            if (accounts?.[0]?.id) {
              const accountId = accounts[0].id;
              this.goto.accountIndexPage(accountId);
            } else {
              this.goto.defaultAccountPage();
            }
          } else {
            this.isCreatingAccount = false;
            this.toast.short(`Failed. ${err.message}`, null, 'failure');
          }
        });
      },
      () => (this.isCreatingAccount = false),
    );
  }

  isExpired(invite: InvitationStatus) {
    return new Date(invite.expiry) < new Date();
  }

  private loadInvitationsList() {
    this.isLoading = true;

    this.userService.listInvitations().subscribe(
      (invitations) => {
        const sortDescFunc = (a: InvitationStatus, b: InvitationStatus) => {
          return a.createdAt < b.createdAt ? 1 : -1;
        };

        const received = (invitations.received || [])
          .filter((i) => {
            const isExpired = this.isExpired(i);
            return !isExpired && i.accepted === false && (i.type === 'PROJECT' || i.type === 'ACCOUNT');
          })
          .sort(sortDescFunc);

        this.invitations = received;
      },
      () => {
        /* noop */
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  private refreshToken(callback: any) {
    this.userService.getNewToken().subscribe(
      (token) => {
        this.accountService.list().subscribe(
          (accounts) => {
            if (accounts) {
              this.authService.updateAccountsToUserInfo(accounts);
            }
            this.authService.updateNewToken(token);

            callback(null, accounts);
          },
          (err) => callback(err),
        );
      },
      (err) => callback(err),
    );
  }

  private openInvite(invite: InvitationStatus) {
    const type = invite.type;

    const list = invite.accountInviteList;
    const projectId = list[0].projectId;
    const accountId = list[0].accountId;

    switch (type) {
      case 'ACCOUNT':
        this.goto.accountIndexPage(accountId);
        break;
      case 'PROJECT':
        this.goto.projectIndex(projectId);
        break;
    }
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      accountName: new UntypedFormControl(null, [Validators.required]),
    });
  }
}
