import { Injectable } from '@angular/core';
import { Utils } from '@shared/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import type { IAccount, IUserInfo } from '../../interfaces';
import { RocosClientService } from '../rocos-client';

export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_ID_TOKEN = 'id_token';
export const KEY_TOKEN = 'token';
export const KEY_USER_INFO = 'user_info';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userUpdated = new Subject<IUserInfo>();
  public loggedOut = new Subject<IUserInfo>();

  public userInfo: IUserInfo;

  public token$ = new BehaviorSubject<string | null>(null);

  public constructor(private rocosClientService: RocosClientService) {
    this.retrievePayload();
    this.token$.next(this.userInfo?.token);
    this.token$.pipe(Utils.rxjsNullFilter).subscribe((token) => {
      this.rocosClientService.updateToken(token);
    });
  }

  /**
   * Check if user is authenticated
   */
  public isAuthenticated(): boolean {
    return !!this.userInfo?.token;
  }

  public hasAccount(): boolean {
    return this.userInfo?.accounts?.length > 0;
  }

  /**
   * Logout
   */
  public logout(): Observable<boolean> {
    return new Observable((observer) => {
      this.loggedOut.next(this.userInfo);

      this.clearSession();

      observer.next(true);
      observer.complete();
    });
  }

  /**
   * Callback for handling login
   *
   * @param userInfo Logged in user info
   */
  public handleLogin(userInfo: IUserInfo): Observable<void> {
    return new Observable((observer) => {
      if (!userInfo?.token) {
        this.clearSession();

        observer.error(userInfo);
      } else {
        this.updateSessionByUserInfo(userInfo);

        observer.next();
        observer.complete();
      }
    });
  }

  public updateNewToken(token: string): void {
    localStorage.setItem(KEY_TOKEN, token);
    if (this.userInfo) {
      this.userInfo.token = token;
    }
    localStorage.setItem(KEY_USER_INFO, JSON.stringify(this.userInfo));

    this.token$.next(token);

    this.retrievePayload();
  }

  /**
   * Update accounts of userInfo
   *
   * @param accounts Accounts
   */
  public updateAccountsToUserInfo(accounts: IAccount[]): void {
    if (this.userInfo) {
      this.userInfo.accounts = accounts;
    }
  }

  /**
   * Update the session by user info
   *
   * @param userInfo Logged in user info
   */
  public updateSessionByUserInfo(userInfo: IUserInfo): void {
    localStorage.setItem(KEY_TOKEN, userInfo.token);
    localStorage.setItem(KEY_USER_INFO, JSON.stringify(userInfo));

    this.token$.next(userInfo.token);

    this.retrievePayload();

    this.userUpdated.next(this.userInfo);
  }

  public loginWithOneTimeToken(token: string) {
    this.clearSession();
    return this.rocosClientService.rocosClient.user.loginWithOnetimeToken(token);
  }

  private clearSession(): void {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_ID_TOKEN);
    localStorage.removeItem(KEY_TOKEN);
    localStorage.removeItem(KEY_USER_INFO);

    // Remove all local storages.
    const redirect = localStorage.getItem('redirect_url');
    localStorage.clear();
    if (redirect) {
      localStorage.setItem('redirect_url', redirect);
    }

    // Remove the token from rocos client
    this.rocosClientService.rocosClient.removeToken();

    this.retrievePayload();
  }

  private retrievePayload(): void {
    try {
      const userInfoString = localStorage.getItem(KEY_USER_INFO);
      if (userInfoString?.length) {
        this.userInfo = JSON.parse(userInfoString);
      } else {
        this.userInfo = null;
      }
    } catch (e) {
      console.error('Error while parsing user info', e);
      throw e;
    }
  }
}
