export enum IHeartbeatStatus {
  Alive = 'alive',
  Disconnected = 'disconnected',
}
export interface IHeartbeat {
  ts: number;
  status: IHeartbeatStatus;
  error: string;
}
export class Heartbeat implements IHeartbeat {
  public ts: number;
  public status: IHeartbeatStatus;
  public error: string;

  constructor(status: IHeartbeatStatus = IHeartbeatStatus.Alive, error: string = null) {
    this.status = status;
    this.error = error;
    this.ts = Date.now();
  }

  get isConnected(): boolean {
    return this.status === IHeartbeatStatus.Alive;
  }

  get textStatus(): string {
    return this.status === IHeartbeatStatus.Alive ? 'Online' : 'Offline';
  }
}
