import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@shared/directives';
import { MaterialModule } from '@shared/material';
import { SharedComponentsModule } from '@shared/components';
import { NavbarComponent } from './navbar';
import { NavbarUserItemComponent } from './navbar-user-item';
import { SidebarComponent } from './sidebar';
import { WidgetsModule } from '@shared/widgets';
import { NavbarLogoComponent } from './navbar-logo';
import { BreadcrumbModule } from '@shared-modules/breadcrumb';
import { TranslocoModule } from '@ngneat/transloco';
import { InvitationsDialogModule } from '@shared-modules/invitations-dialog';
import { NavbarConnectionComponent } from './navbar-connection/navbar-connection.component';
import { NavbarDDLinkComponent } from './navbar-dd-link';

const components = [NavbarComponent, NavbarDDLinkComponent, NavbarUserItemComponent, SidebarComponent];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedComponentsModule,
    RouterModule,
    DirectivesModule,
    WidgetsModule,
    BreadcrumbModule,
    TranslocoModule,
    InvitationsDialogModule,
  ],
  declarations: [...components, NavbarLogoComponent, NavbarConnectionComponent],
  exports: [...components],
})
export class CoreSharedComponentsModule {}
