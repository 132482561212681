import { BabylonRendererService } from './babylon-renderer.service';
import { GuiFactoryService } from './factories/gui-factory.service';
import { MaterialFactoryService } from './factories/material-factory.service';
import { MeshFactoryService } from './factories/mesh-factory.service';
import { FeaturePickerService } from './feature-picker.service';
import { GazeboWorldService } from './gazebo-world.service';
import { GeoJsonService } from './geo-json.service';
import { PointCloudImporter } from './nodes/pointcloud/pointcloud-importer';
import { PointPickerService } from './point-picker.service';
import { PolygonRendererService } from './polygon-renderer.service';
import { RendererModelService } from './renderer-model.service';
import { RendererSelectionService } from './renderer-selection.service';
import { RendererService } from './renderer.service';
import { WaypointRendererService } from './waypoint-renderer.service';

// Note: this is separated out from root to contain LocalOps state its
// instances and prevent state from being persisted in root modules
// This is in use her:
// - WidgetOperationLocalComponent
// - LocalOperationComponent
// Every other import is in tests

export const localOperationServices = [
  RendererService,
  RendererModelService,
  RendererSelectionService,
  BabylonRendererService,
  MeshFactoryService,
  GuiFactoryService,
  GazeboWorldService,
  MaterialFactoryService,
  WaypointRendererService,
  PolygonRendererService,
  FeaturePickerService,
  PointPickerService,
  GeoJsonService,
  PointCloudImporter,
];
