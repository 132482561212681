export class DataUriUtils {
  /**
   * Get string without query string.
   *
   * @param uri Raw Uri
   */
  static getMainUri(uri: string) {
    if (!uri) {
      return '';
    }

    // No question mark
    if (uri.indexOf('?') === -1) {
      return uri;
    }

    return uri.substring(0, uri.indexOf('?'));
  }

  static getQueryString(uri: string) {
    if (!uri) {
      return '';
    }

    if (uri.indexOf('?') === -1) {
      return '';
    }

    return uri.substring(uri.indexOf('?') + 1);
  }

  static getURLParams(
    uri: string,
    queryString?: string,
  ): {
    [key: string]: any;
  } {
    if (!queryString) {
      queryString = DataUriUtils.getQueryString(uri);
    }

    if (!queryString) {
      return {};
    }

    const search: any = new URLSearchParams(queryString);
    const result = {};

    search.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }

  static getUnsupportedURLParams(supportedParams: string[], uri: string, queryString?: string): string[] {
    const unsupportedParams: string[] = [];

    const urlParams = DataUriUtils.getURLParams(uri, queryString);
    const keys = Object.keys(urlParams);

    if (keys?.length > 0) {
      keys.forEach((key) => {
        if (supportedParams && !supportedParams.includes(key)) {
          unsupportedParams.push(key);
        }
      });
    }

    return unsupportedParams;
  }
}
