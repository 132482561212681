import type { Scene } from '@babylonjs/core';
import { Mesh } from '@babylonjs/core';
import type { RobotMapsService } from '@robot/shared/robot-maps.service';
import type { FeatureCollection } from 'geojson';
import { MapMeshFactory } from '../factories/map-mesh-factory';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import type { PrimitiveMetaData } from '../primitives/primitives';
import { parentDefault } from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';

export const robotMapMetaData: PrimitiveMetaData = {
  key: 'robotMap',
  label: 'Robot Map',
  icon: 'ri-3d-box',
  editorPanels: {
    parent: parentDefault,
    properties: {
      name: true,
      position: false,
      rotation: false,
      scaling: false,
      material: false,
    },
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    robotMap: true,
  },
};

export class RobotMap extends Mesh {
  public readonly key: string;
  public readonly mapId: string;

  constructor(name: string, scene: Scene, meshData: IMesh, private robotMapService: RobotMapsService) {
    super(name, scene);
    this.key = meshData.key;
    this.mapId = meshData.options?.mapId;
  }

  public loadGeoJSON(): void {
    if (!this.mapId) return;
    this.robotMapService
      .getGeoJSON(this.mapId, this.parent?.['displayName'])
      .then((geoJson) => this.renderGeoJson(geoJson))
      .catch((err) => console.warn(`Failed to load map ID: ${this.mapId}`, err));
  }

  private renderGeoJson(collection: FeatureCollection): void {
    const mapMeshFactory: MapMeshFactory = new MapMeshFactory(this.getScene());
    for (const feature of collection.features) {
      const mesh = mapMeshFactory.fromGeoJsonFeature(this.name, feature);
      if (mesh) mesh.parent = this;
    }
  }
}
