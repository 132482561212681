import type * as mapboxgl from 'mapbox-gl';
import type { GeometryShapeLineString, GeometryShapeMultiPoint } from '../../models/mapping/geometry.model';

export const mapThemePrefix = 'mapbox://styles/nickponline/';

export enum MAPTHEMES {
  SATELLITE = 'mapbox://styles/nickponline/cj9oj1h5g0nb22so4jprraw1m',
}

export enum MapThumbs {
  mapThumbSatelliteStreets = 'assets/images/map-widget/map_thumb_satellite_streets.png',
}

export const mapThemeThumbDict = {};
mapThemeThumbDict[MAPTHEMES.SATELLITE] = MapThumbs.mapThumbSatelliteStreets;

export class MapBoxHelper {
  private _currentTheme: MAPTHEMES = MAPTHEMES.SATELLITE;
  private layerPrefix = 'rocos-';
  private sourcePrefix = 'rocos-';

  constructor(public mapboxMap: mapboxgl.Map) {}

  /// adds a path to the map
  public addPathLayer(layerId, pathGeometryLineString: GeometryShapeLineString, color) {
    const geoJsonLineString = pathGeometryLineString.getGeoJSONLineString();

    this.mapboxMap.addLayer({
      id: layerId,
      type: 'line',
      source: {
        'type': 'geojson',
        'data': geoJsonLineString,
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 5,
        'line-opacity': 0.8,
      },
    });
  }

  /**  replaces the layer paths with the provided, if the layer doesn't exist based on the id then it is created */
  public updatePath(layerId, lineString: GeometryShapeLineString) {
    layerId = this.checkLayerId(layerId);
    const geoJsonLineString = lineString.getGeoJSONLineString();

    if (!this.mapboxMap.getSource(layerId)) {
      this.addPathLayer(layerId, lineString, 'rgba(191,255,0,0.5)');
    }
    (this.mapboxMap.getSource(layerId) as mapboxgl.GeoJSONSource).setData(geoJsonLineString);
  }

  public addDotsLayer(layerId, points: GeometryShapeMultiPoint, color?: string) {
    layerId = this.checkLayerId(layerId);

    if (color === null) {
      color = '#FFBE2F';
    }

    const geoJsonMultiPoint = points.toGeoJSONMultiPoint();

    this.mapboxMap.addLayer({
      id: layerId,
      source: {
        'type': 'geojson',
        'data': geoJsonMultiPoint,
      },
      type: 'circle',
      paint: {
        'circle-radius': 4,
        'circle-color': color,
      },
    });
  }

  public updateDotsLayer(layerId: string, multiPoint: GeometryShapeMultiPoint) {
    layerId = this.checkLayerId(layerId);
    const geoJsonMultiPoint = multiPoint.toGeoJSONMultiPoint();

    if (!this.mapboxMap.getSource(layerId)) {
      this.addDotsLayer(layerId, multiPoint, 'rgba(191,255,0,0.5)');
    }

    (this.mapboxMap.getSource(layerId) as mapboxgl.GeoJSONSource).setData(geoJsonMultiPoint);
  }

  /** adds a new markers layer to the map, if path layer doesn't exist based on the id then it is created */
  public addMarkersLayer(layerId, points: GeometryShapeMultiPoint, _color) {
    layerId = this.checkLayerId(layerId);

    const geoJsonMultiPoint = points.toGeoJSONMultiPoint();

    this.mapboxMap.addLayer({
      id: layerId,
      type: 'symbol',
      source: {
        'type': 'geojson',
        'data': geoJsonMultiPoint,
      },
      layout: {
        'icon-image': '{icon}-15',
        'text-field': '{title}',
        // 'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [0, 1.5],
        // 'text-anchor': 'top'
      },
      paint: {
        'text-color': '#FFFFFF',
        'text-halo-color': '#000000',
        'text-halo-width': 2,
      },
    });
  }

  /**  replaces the layer markers with the provided, if the layer doesn't exist based on the id then it is created */
  public updateMarkers(layerId: string, multiPoint: GeometryShapeMultiPoint) {
    layerId = this.checkLayerId(layerId);
    const geoJsonMultiPoint = multiPoint.toGeoJSONMultiPoint();

    if (!this.mapboxMap.getSource(layerId)) {
      this.addMarkersLayer(layerId, multiPoint, 'rgba(191,255,0,0.5)');
    }

    (this.mapboxMap.getSource(layerId) as mapboxgl.GeoJSONSource).setData(geoJsonMultiPoint);
  }

  /** Fixes layer naming to ensure it has the prefix the helper requires */
  public checkLayerId(layerId: string) {
    if (layerId.startsWith(this.layerPrefix)) {
      return layerId;
    } else {
      // console.warn('layerId should start with '' + this.layerPrefix + '' prefix. It has been automatically added');
      return this.layerPrefix + layerId;
    }
  }

  /** Fixes source naming to ensure it has the prefix the helper requires */
  public checkSourceId(sourceId: string) {
    if (sourceId.startsWith(this.sourcePrefix)) {
      return sourceId;
    } else {
      // console.warn('layerId should start with '' + this.layerPrefix + '' prefix. It has been automatically added');
      return this.layerPrefix + sourceId;
    }
  }

  // public addPoi() {}

  /** removes labels from the default map layers to make it look cleaner */
  public removeLabels() {
    const layers = this.mapboxMap.getStyle().layers;
    for (const layer of layers) {
      if (layer.type === 'symbol' && layer.layout['text-field']) {
        this.mapboxMap.removeLayer(layer.id);
      }
    }
  }

  /** renders 3D buildings at close zoom  */
  public add3DBuildings() {
    this.mapboxMap.addLayer({
      'id': '3d-buildings',
      'source': 'composite',
      'source-layer': 'building',
      'filter': ['==', 'extrude', 'true'],
      'type': 'fill-extrusion',
      'minzoom': 15,
      'paint': {
        'fill-extrusion-color': '#aaa',

        // use an 'interpolate' expression to add a smooth transition effect to the
        // buildings as the user zooms in
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
        'fill-extrusion-opacity': 0.6,
      },
    });
  }

  public get currentTheme(): MAPTHEMES {
    return this._currentTheme;
  }
}

export enum MOUSE_PICKER_MODE {
  PAN = 'all-scroll',
  SELECT = 'pointer',
  PATH = 'default',
  NONE = '',
}

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  SPACE = 32,
  V = 86,
  Z = 90,
  TAB = 9,
  P = 90,
}
