<div class="video-debug">
  <div class="line">
    <div class="name">Peer Connection Status:</div>
    <div class="value">
      <span class="status_bad" *ngIf="!videoSourceStatus?.webrtcPeerConnectionStatus">Disconnected</span>
      <span class="status_good" *ngIf="videoSourceStatus?.webrtcPeerConnectionStatus">Connected</span>
    </div>
  </div>
  <div class="line" *ngIf="false">
    <div class="name">Stream Status:</div>
    <div class="value">
      <span class="status_none" *ngIf="!videoSourceStatus?.webrtcStreamStarted">None</span>
      <span class="status_good" *ngIf="videoSourceStatus?.webrtcStreamStarted">Started</span>
    </div>
  </div>
  <div class="line">
    <div class="name">Network ICE Status:</div>
    <div class="value">
      <span
        class="status_good"
        *ngIf="
          videoSourceStatus?.webrtcIceConnectionState === 'Connected' ||
          videoSourceStatus?.webrtcIceConnectionState === 'Completed'
        "
      >
        {{ videoSourceStatus?.webrtcIceConnectionState }}
      </span>
      <span
        class="status_bad"
        *ngIf="
          videoSourceStatus?.webrtcIceConnectionState === 'Starting' ||
          videoSourceStatus?.webrtcIceConnectionState === 'New' ||
          videoSourceStatus?.webrtcIceConnectionState === 'Checking' ||
          videoSourceStatus?.webrtcIceConnectionState === 'Failed' ||
          videoSourceStatus?.webrtcIceConnectionState === 'Closed'
        "
      >
        {{ videoSourceStatus?.webrtcIceConnectionState }}
      </span>
      <span class="status_warn" *ngIf="videoSourceStatus?.webrtcIceConnectionState === 'Disconnected'">
        {{ videoSourceStatus?.webrtcIceConnectionState }}
      </span>
    </div>
  </div>
  <div class="line">
    <div class="name">Inbound Video:</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="videoSourceStatus?.webrtcVideoIn === null || videoSourceStatus?.webrtcVideoIn === undefined"
        >None</span
      >
      <span
        class="status_good"
        *ngIf="
          videoSourceStatus?.webrtcVideoIn !== null &&
          videoSourceStatus?.webrtcVideoIn !== undefined &&
          videoSourceStatus?.webrtcVideoIn
        "
        >Yes</span
      >
      <span
        class="status_bad"
        *ngIf="
          videoSourceStatus?.webrtcVideoIn !== null &&
          videoSourceStatus?.webrtcVideoIn !== undefined &&
          !videoSourceStatus?.webrtcVideoIn
        "
        >No</span
      >
    </div>
  </div>
  <div class="line" *ngIf="false">
    <div class="name">Network ICE Status:</div>
    <div class="value">
      <span class="status_none" *ngIf="!videoSourceStatus?.webrtcIceStateConnected">Not Connected</span>
      <span class="status_good" *ngIf="videoSourceStatus?.webrtcIceStateConnected">Connected</span>
    </div>
  </div>
  <div class="line" *ngIf="false">
    <div class="name">Speed:</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="videoSourceStatus?.webrtcSlow === null || videoSourceStatus?.webrtcSlow === undefined"
        >No issues</span
      >
      <span
        class="status_bad"
        *ngIf="
          videoSourceStatus?.webrtcSlow !== null &&
          videoSourceStatus?.webrtcSlow !== undefined &&
          videoSourceStatus?.webrtcSlow
        "
        >Slow</span
      >
    </div>
  </div>
  <div class="line">
    <div class="name">Round Trip Time (ms):</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="
          videoSourceStatus?.webrtcRoundTripTimeMs !== null && videoSourceStatus?.webrtcRoundTripTimeMs !== undefined
        "
        >{{ videoSourceStatus?.webrtcRoundTripTimeMs }}</span
      >
    </div>
  </div>
  <div class="line">
    <div class="name">Stream Bitrate (kb/s):</div>
    <div class="value">
      <span class="status_bad" *ngIf="videoSourceStatus?.webrtcBitrate === '0'">{{
        videoSourceStatus?.webrtcBitrate
      }}</span>
      <span class="status_good" *ngIf="videoSourceStatus?.webrtcBitrate !== '0'">{{
        videoSourceStatus?.webrtcBitrate
      }}</span>
    </div>
  </div>
  <div class="line">
    <div class="name">Stats Interval (ms):</div>
    <div class="value">
      <span class="status_none">{{ videoSourceStatus?.realInterval | number : '1.0-0' }}</span>
    </div>
  </div>
  <div class="line">
    <div class="name">Packet Loss (%):</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="
          videoSourceStatus?.webrtcPacketLossPercent !== null &&
          videoSourceStatus?.webrtcPacketLossPercent !== undefined
        "
        >{{ videoSourceStatus?.webrtcPacketLossPercent }}</span
      >
    </div>
  </div>
  <div class="line">
    <div class="name">Frames Received:</div>
    <div class="value">
      <span class="status_none" *ngIf="lastWebrtcFramesReceivedChanged">{{
        videoSourceStatus?.webrtcFramesReceived
      }}</span>
      <span class="status_bad" *ngIf="!lastWebrtcFramesReceivedChanged">{{
        videoSourceStatus?.webrtcFramesReceived
      }}</span>
    </div>
  </div>
  <div class="line">
    <div class="name">Frames Decoded:</div>
    <div class="value">
      <span class="status_none" *ngIf="lastWebrtcFramesDecodedChanged">{{
        videoSourceStatus?.webrtcFramesDecoded
      }}</span>
      <span class="status_bad" *ngIf="!lastWebrtcFramesDecodedChanged">{{
        videoSourceStatus?.webrtcFramesDecoded
      }}</span>
    </div>
  </div>
  <div class="line">
    <div class="name">Frames Dropped:</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="videoSourceStatus?.webrtcFramesDropped !== null && videoSourceStatus?.webrtcFramesDropped !== undefined"
        >{{ videoSourceStatus?.webrtcFramesDropped }}</span
      >
    </div>
  </div>
  <div class="line">
    <div class="name">Avg Frame Playout (ms):</div>
    <div class="value">
      <span
        class="status_none"
        *ngIf="videoSourceStatus?.avgFramePlayout !== null && videoSourceStatus?.avgFramePlayout !== undefined"
        >{{ videoSourceStatus?.avgFramePlayout * 1000 | number : '1.0-0' }}</span
      >
    </div>
  </div>
  <div class="line" *ngIf="lastCoordinatePicked">
    <div class="name">Last Coordinates Picked:</div>
    <div class="value">
      <span>x: {{ lastCoordinatePicked?.x }} - y: {{ lastCoordinatePicked?.y }}</span>
    </div>
  </div>
</div>
