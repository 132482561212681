import type { IStreamSource as RocosJSIStreamSource } from '@team-rocos/rocos-js';

export class StreamSource {
  public id: string;
  public sourceId: string;
  public dataId: string;
  public type: string;
  public frequency: number;

  public color?: string;
  public enabledChart = false;

  public get path(): string {
    return `/${this.sourceId}/${this.dataId}`;
  }

  constructor(sourceId?: string, dataId?: string) {
    this.sourceId = sourceId ? sourceId : '';
    this.dataId = dataId ? dataId : '';
  }

  public static fromModel(model: RocosJSIStreamSource): StreamSource {
    const source = new StreamSource();

    source.id = model.id;
    source.sourceId = model.sourceId;
    source.dataId = model.dataId;
    source.type = model.type;
    source.frequency = model.frequency;

    return source;
  }
}
