<div class="headingWidget fade-in">
  <div class="circleContainer">
    <div
      class="boundingBox"
      [style.transform]="'scaleY(' + (1 - mapPitch / 120) + ')'"
      [style.padding-top]="mapPitch / 2 + 'px'"
    >
      <div class="outerCompass" [style.transform]="'rotate(' + (360 - mapHeading) + 'deg)'"></div>
      <div class="innerHeading-shadow" [style.transform]="'rotate(' + (360 - mapHeading + robotHeading) + 'deg)'"></div>
      <div
        class="innerHeading"
        [style.top]="-mapPitch / 16 + 'px'"
        [style.transform]="'rotate(' + (360 - mapHeading + robotHeading) + 'deg)'"
      ></div>
    </div>
  </div>
</div>
