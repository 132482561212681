import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() public panelTitle: string;
  @Input() public indentTitle: boolean = false;
  @Input() public expanded: boolean = false;
  @Input() public expandable: boolean = true;
  @Output() public expansionChangedEvent = new EventEmitter<boolean>();

  public onClickHeading(): void {
    if (this.expandable) this.expanded = !this.expanded;
  }
}
