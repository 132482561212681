import { Injectable } from '@angular/core';
import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import type { ButtonType, Gamepad, GamepadButtonAction } from '../../models';
import { ButtonDefWithValue, GamepadButtonTriggerType } from '../../models';
import { GamepadConnectionService, gamepadDeadbandThreshold } from './gamepad-connection.service';

export class GamepadActionsConfig {
  list: GamepadButtonAction[];

  constructor(list: GamepadButtonAction[]) {
    this.list = list ? list : [];
  }

  findTargetButtonActions(buttonType: ButtonType) {
    return this.list?.filter((item) => {
      return item.source.type === buttonType;
    });
  }
}

export class GamepadActionEvent {
  action: GamepadButtonAction;
  button: ButtonDefWithValue;
  gamepadSnapshot: Gamepad;

  constructor(action: GamepadButtonAction, button: ButtonDefWithValue, gamepadSnapshot: Gamepad) {
    this.action = action;
    this.button = button;
    this.gamepadSnapshot = gamepadSnapshot;
  }
}

class FiredButtons {
  [buttonType: string]: {
    createdAt: Date;
    updatedAt: Date;
    lastFiredAt?: Date;
    count: number;
    firedCount?: number; // How many times fired.
    source: ButtonDefWithValue;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GamepadActionService {
  actionsConfig: GamepadActionsConfig;
  firedButtons: FiredButtons = {} as FiredButtons;
  newActionEvent = new Subject<GamepadActionEvent>();
  gamepadPressedSub: Subscription;
  lastGamePadValues: any = {};
  lastGamepadPressedTimeStamp: number;
  safetyGamepadValuesSent: boolean = true;

  private gamepadEnabled: boolean;

  constructor(private gamepadConnectionService: GamepadConnectionService) {
    this.gamepadConnectionService.gamepadEnabled$.asObservable().subscribe((x) => {
      if (x) {
        // gamepad enable state changed, reset some properties
        this.lastGamepadPressedTimeStamp = null;
        this.safetyGamepadValuesSent = false;
      }
      this.gamepadEnabled = x;
    });
  }

  setActionsConfigList(list: GamepadButtonAction[]) {
    const config = new GamepadActionsConfig(list);
    this.actionsConfig = config;

    if (!this.gamepadPressedSub) {
      this.subscribeGamepadPressEvent();
    }
  }

  subscribeGamepadPressEvent() {
    this.gamepadPressedSub = this.gamepadConnectionService.gamepadPressed$.subscribe((gamepad) => {
      if (gamepad?.pressedValues) {
        if (!this.gamepadEnabled && this.safetyGamepadValuesSent) {
          // gamepad is disabled, and safety gamepad value is already sent, don't process gamepad event
          return;
        }

        gamepad = this.gamepadSafetyGuard(gamepad);
        if (gamepad.pressedValues.length > 0) {
          this.onGamepadPressed(gamepad);
        }
      }
    });
  }

  gamepadSafetyGuard(gamepad) {
    const threshold = gamepadDeadbandThreshold;

    // The gamepad is disabled, we will send the 0 values once
    if (!this.gamepadEnabled) {
      if (this.safetyGamepadValuesSent) {
        gamepad.pressedValues = [];
        return gamepad;
      }

      this.safetyGamepadValuesSent = true;

      // the gamepad is never been used, don't need to sent 0
      if (!this.lastGamepadPressedTimeStamp) {
        gamepad.pressedValues = [];
        return gamepad;
      }

      // set all received gamepad button values to 0
      gamepad.pressedValues.forEach((element) => {
        element.value = 0;
      });
    }

    gamepad.pressedValues = gamepad.pressedValues.filter((x) => {
      // axies are keeping sending data even not touched, buttons and triggers only send data when they are pressed.
      if (Math.abs(x.value) >= threshold) {
        // we only send axes value when its value greater than threshold
        return true;
      } else if (Math.abs(this.lastGamePadValues[String(x.type)]) >= threshold) {
        // a button only fire value 1, button will never reach here. only trigger and axis will do
        // trigger has less precision, it may never send a value < threshold nor reach here
        // the value is dropped within the deadband, set it to 0
        x.value = 0;
        x.isSessionEndEvent = true;
        return true;
      }

      return false;
    });

    // save current value into cache
    gamepad.pressedValues.forEach((x) => {
      this.lastGamePadValues[String(x.type)] = x.value;
    });
    return gamepad;
  }

  onGamepadPressed(gamepad: Gamepad) {
    const pressedValues: ButtonDefWithValue[] = gamepad.pressedValues;

    if (pressedValues) {
      // Update the firedButton object
      this.updateFiredButtonsObject(pressedValues);

      if (pressedValues.length > 0) {
        this.lastGamepadPressedTimeStamp = Date.now();

        // For each pressed button, check the action and fire an event.
        pressedValues.forEach((item) => {
          this.onNewButtonPressed(item, this.actionsConfig, gamepad);
        });
      }
    }
  }

  onNewButtonPressed(button: ButtonDefWithValue, config: GamepadActionsConfig, gamepad: Gamepad) {
    if (!config) {
      throw new Error('Should set actions config first, please use `setActionsConfigList` to set.');
    }

    // Get target actions according to this pressed button
    const targetActions = config.findTargetButtonActions(button.type);

    if (targetActions?.length > 0) {
      // Got actions
      // For each action, check should fire an event out or not.
      targetActions.forEach((action) => {
        const shouldFire = this.shouldFireAnEvent(button, action);

        if (shouldFire) {
          const event: GamepadActionEvent = new GamepadActionEvent(action, button, gamepad);

          this.newActionEvent.next(event);
        }
      });
    }
  }

  /**
   * Update the firedButtons object to handle pressed button events.
   */
  updateFiredButtonsObject(pressedValues: ButtonDefWithValue[]) {
    const updatedFiredButtons: FiredButtons = {};

    if (pressedValues) {
      pressedValues.forEach((item) => {
        const key = item.typeAsString();

        if (this.firedButtons?.[key]) {
          this.firedButtons[key].updatedAt = new Date();
          this.firedButtons[key].count++;
          updatedFiredButtons[key] = this.firedButtons[key];
        } else {
          // Add a new one
          updatedFiredButtons[key] = {
            createdAt: new Date(),
            updatedAt: new Date(),
            count: 1,
            source: item,
          };
        }
      });
    }

    this.firedButtons = updatedFiredButtons;
  }

  shouldFireAnEvent(button: ButtonDefWithValue, action: GamepadButtonAction): boolean {
    const firedButton = this.firedButtons[button.typeAsString()];
    if (!firedButton) {
      throw new Error('Should get firedButton object, but have not found');
    }

    let shouldFire = false;

    const firedAt = new Date();
    const triggerType = action.triggerType;
    const frequency = action.frequency;

    switch (triggerType) {
      case GamepadButtonTriggerType.ONCE:
        // For firing an event, the `count` must be 1
        // which means just got 1 message now
        // We don't send the isSessionEndEvent value for trigger type 'once'
        if (firedButton.count === 1 && !button.isSessionEndEvent) {
          firedButton.lastFiredAt = firedAt;
          shouldFire = true;
        }
        break;
      case GamepadButtonTriggerType.CONTINUOUSLY:
        if (!firedButton.firedCount) {
          firedButton.firedCount = 0;
        }

        // If have not fired yet, should fire once.
        if (!firedButton.lastFiredAt || button.isSessionEndEvent) {
          firedButton.lastFiredAt = firedAt;
          firedButton.firedCount = 1;

          shouldFire = true;
        } else if (firedAt.getTime() - firedButton.lastFiredAt.getTime() >= frequency) {
          firedButton.lastFiredAt = firedAt;
          firedButton.firedCount++;

          shouldFire = true;
        }
        break;
    }

    return shouldFire;
  }
}
