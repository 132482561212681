import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { supportedCameraTypes } from '@shared/services/threeD/primitives/cameraPrimitives';
import { supportedLightTypes } from '@shared/services/threeD/primitives/lightPrimitives';
import type { RendererDefaultObjectTypes } from '@shared/services/threeD/primitives/objectPrimitives';
import { allObjectTypes } from '@shared/services/threeD/primitives/objectPrimitives';
import { supportedSceneTypes } from '@shared/services/threeD/primitives/scenePrimitives';

@Pipe({ name: 'primitiveIcon' })
export class PrimitiveIconPipe implements PipeTransform {
  private supported = {
    ...allObjectTypes,
    ...supportedCameraTypes,
    ...supportedLightTypes,
    ...supportedSceneTypes,
  };

  transform(type: RendererDefaultObjectTypes): string {
    return this.supported[type]?.icon || 'ri-cube';
  }
}
