<button
  [ngClass]="[buttonStyle]"
  [type]="buttonType"
  mat-flat-button
  [color]="color"
  (click)="onButtonClick($event)"
  [disabled]="disabled"
  [style.width]="fullWidth ? '100%' : 'auto'"
  [style.background-color]="disabled && disabledColor.background ? disabledColor.background : undefined"
  [style.color]="disabled && disabledColor.text ? disabledColor.text : undefined"
>
  <span class="button-spinner" *ngIf="loading" [ngClass]="{ 'loading': loading }">
    <app-loading-spinner [theme]="spinnerColorTheme" [isLoading]="true"></app-loading-spinner>
  </span>
  <span class="original-content" [ngClass]="{ 'loading': loading }">
    <ng-content></ng-content>
  </span>
</button>
