<ng-container *transloco="let tCommon; read: 'common'">
  <div class="dialog">
    <h2 mat-dialog-title class="title">
      <span>{{ title }}</span>
      <span class="right-buttons">
        <button mat-icon-button (click)="onCancelButtonClick()" matTooltip="{{ tCommon('cancel') }}" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>

    <div class="dialog-content" mat-dialog-content>
      <div>
        <div>{{ getMessage() }}</div>
      </div>
    </div>

    <div class="footer">
      <button mat-stroked-button color="accent" class="cancel-button" (click)="onCancelButtonClick()" tabindex="-1">
        {{ data?.buttons?.cancel ?? 'Cancel' }}
      </button>
      <div class="action-buttons">
        <button mat-stroked-button color="warn" class="discard-button" (click)="onDiscardButtonClick()" tabindex="-1">
          {{ data?.buttons?.discard ?? 'Discard' }}
        </button>
        <button *ngIf="data.canPublish" mat-flat-button color="primary" (click)="onPublishButtonClick()" tabindex="-1">
          {{ data?.buttons?.publish ?? 'Publish Changes' }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
