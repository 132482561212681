<app-widget-base
  [heading]="heading"
  [type]="type"
  [dataURI]="dataURI"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div class="center-container" [ngClass]="[theme]" [class.full-width]="!isNumber">
    <!-- String -->
    <div *ngIf="!isNumber">
      <div
        class="string-value"
        [ngClass]="stringStyleClasses"
        [ngStyle]="{ 'font-size.px': widget?.['fontSize']?.value }"
      >
        <span *ngIf="validString; else invalidValue">
          {{ stringDisplayValue | truncateText : maxStringLength : 'right' }}
        </span>
      </div>
    </div>

    <!-- Number -->
    <div *ngIf="isNumber">
      <div class="value" [ngStyle]="{ 'font-size.px': widget?.['fontSize']?.value }">
        <span *ngIf="validNumber; else invalidValue">
          {{ value | valueEval : valueExpression | number : numberFormat }}
        </span>
      </div>
      <div class="data-name" [ngStyle]="{ 'font-size.px': widget?.['fontSize']?.dataName }">
        {{ dataName }}
      </div>
      <div class="unit" [ngStyle]="{ 'font-size.px': widget?.['fontSize']?.unit }">
        {{ unit }}
      </div>
    </div>
  </div>
</app-widget-base>

<!-- Invalid Value-->
<ng-template #invalidValue> -- </ng-template>
