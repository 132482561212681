import { StreamConfig } from './stream-config';

export class StreamConfigUDP extends StreamConfig {
  public sampleRate = 5000;

  constructor() {
    super();

    this.type = 'udp';
    this.name = 'UDP';
  }

  public static override fromModel(model: any): StreamConfigUDP {
    const config = new StreamConfigUDP();

    config.name = model.name;
    config.type = model.type;
    config.logoURL = model.logoURL;
    config.enabled = model.enabled;

    if (model.topic) {
      config.topic = model.topic;
    }
    if (model.sampleRate) {
      config.sampleRate = model.sampleRate;
    }

    return config;
  }
}
