import type { IWidgetExternalContent } from './widget-base';
import { WidgetBase } from './widget-base';

export interface ICustomWidgetContent {
  htmlCode: string;
  jsCode: string;
  cssCode: string;
}

export class WidgetCustom extends WidgetBase {
  public override externalContent: IWidgetExternalContent<ICustomWidgetContent>;

  public static fromModel(model: Partial<WidgetCustom>): WidgetCustom {
    const widget = new WidgetCustom();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: Partial<WidgetCustom>): void {
    super.readValuesFromModel(model);

    this.minItemCols = model.minItemCols ? model.minItemCols : 2;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;
  }
}
