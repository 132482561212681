export class FunctionConfig {
  id: string;
  name: string;
  handler?: string;
  runtime: string;
  description?: string;
  deps?: string[];
  function?: string;
  payload?: string;

  static fromModel(model: FunctionConfig): FunctionConfig {
    const config = new FunctionConfig();

    config.id = model.id;
    config.name = model.name;
    config.handler = model.handler;
    config.runtime = model.runtime;
    config.description = model.description;
    if (model.deps) {
      config.deps = model.deps;
    }
    config.function = model.function;
    config.payload = model.payload;

    return config;
  }
}
