import PointcloudPLY from '../../utils/pointcloud-ply-utils';
import type { LaserRay } from './laserscan.service';

export class PointcloudService {
  public pointArray: Float32Array;

  /** Can contain null points */
  public coordArray = [];
  public numPoints = 0;

  private static base64ToArrayBuffer(base64) {
    const binaryString = atob(base64); // window.atob(base64);
    const len = binaryString.length;
    const uint8 = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      uint8[i] = binaryString.charCodeAt(i);
    }
    return uint8.buffer;
    // return uint8ArrayToArrayBuffer(uint8);
  }

  private static uint8ArrayToArrayBuffer(typedArray: ArrayBuffer): ArrayBuffer {
    const uint8 = new Uint8Array(typedArray);
    return uint8.buffer;
  }

  private static getPointCloudCoordArray(f32Array: Float32Array, width: number, pointStep: number) {
    const coordArray = [];
    const arraySize = pointStep / 4; // worked with old example
    let i = 0;

    for (let p = 0; i < width; p += arraySize) {
      const coord = f32Array.slice(p, p + arraySize);
      coordArray.push(coord);
      i++;
    }
    return coordArray;
  }

  public loadFromLaserScanRays(laserRays: LaserRay[]): void {
    laserRays.forEach((ray) => {
      if (ray !== null && !ray.maxRange) {
        this.coordArray.push([ray.endPoint.x, ray.endPoint.y, ray.endPoint.z]);
      } else {
        this.coordArray.push(null);
      }
      this.numPoints++;
    });
  }

  /** From the ROS PointCloud2 format
   *  http://docs.ros.org/melodic/api/sensor_msgs/html/msg/PointCloud2.html
   */
  public loadFromPointCloud2(
    dataFieldPayload: string | ArrayBuffer,
    payloadWidth: number,
    payloadPointStep: number,
    dataBase64Encoded: boolean,
  ): void {
    const b64StringPayload = dataFieldPayload;
    const width = payloadWidth;
    const pointStep = payloadPointStep;

    this.numPoints = width;

    if (dataBase64Encoded) {
      const buffer = PointcloudService.base64ToArrayBuffer(b64StringPayload);
      this.pointArray = new Float32Array(buffer);
    } else {
      // assumes the array is UInt8Array not in base64
      const buffer = PointcloudService.uint8ArrayToArrayBuffer(dataFieldPayload as ArrayBuffer);
      this.pointArray = new Float32Array(buffer);
    }
    //  packages into an array of coord (x, y, z, +, +)
    this.coordArray = PointcloudService.getPointCloudCoordArray(this.pointArray, width, pointStep);
  }

  public loadFromPLY(data: string | ArrayBuffer): void {
    const geometry = PointcloudPLY.parse(data);
    const points = geometry.data['vertex'];

    this.numPoints = geometry.header.elements.find((i) => i.name === 'vertex').count;
    points.forEach((vertex) => {
      this.coordArray.push([vertex.x, vertex.y, vertex.z]);
    });
  }
}
