import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appSaveKeys]',
})
export class SaveDirective {
  @Output()
  public save: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('keydown.control.s', ['$event'])
  @HostListener('keydown.meta.s', ['$event'])
  public keyPressed(event: KeyboardEvent): void {
    if (event.key === 's' && (event.metaKey || event.ctrlKey)) {
      this.save.emit();
      event.preventDefault();
    }
  }
}
