import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioWidgetComponent } from './audio-widget/audio-widget.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedComponentsModule } from '@shared/components';
import { MaterialModule } from '@shared/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AudioWidgetComponent],
  exports: [AudioWidgetComponent],
  imports: [SharedComponentsModule, CommonModule, MatButtonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class AudioWidgetModule {}
