<div class="rs-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 class="title" mat-dialog-title>
      Add Action
      <span class="right-buttons">
        <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <input matInput type="text" formControlName="name" placeholder="Name" />
            <mat-error *ngIf="form && form?.get('name')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Source</mat-label>
            <mat-select formControlName="source">
              <mat-option *ngFor="let source of sources" [value]="source.type">
                {{ source.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form && form?.get('source')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input matInput type="text" formControlName="value" placeholder="Value" />
            <mat-error *ngIf="form && form?.get('value')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input matInput type="text" formControlName="defaultValue" placeholder="Default value" />
            <mat-error *ngIf="form && form?.get('defaultValue')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-flat-button color="primary" (clicked)="onSubmit()">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>
