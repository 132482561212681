<app-widget-base
  [heading]="heading"
  [type]="type"
  [dataURI]="null"
  [editing]="editing"
  [isGroupWidget]="isGroupWidget"
  [floorHeading]="widget?.pickerCommandId !== undefined"
  [height]="height"
  [width]="width"
  [status]="status"
  [viewSwitchable]="viewSwitchable"
  [toolbarItems]="toolbarItems"
  [theme]="theme"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  (deleted)="onDelete()"
  (toolbarItemClick)="onToolbarItemClick($event)"
>
  <div
    #imgContainer
    class="content image-container"
    [ngClass]="{
      'rotate90': widget?.rotation === 90,
      'rotate-90': widget?.rotation === -90,
      'rotate180': widget?.rotation === 180 || widget?.rotation === -180
    }"
  >
    <!-- Pick Coordinates Widget -->
    <ng-container *ngIf="currentConnectionStatus === connectionStatus.connected">
      <ng-container *ngIf="pickerPosition">
        <div
          [ngStyle]="{
            'width': pickerPosition.width,
            'height': pickerPosition.height,
            'top': pickerPosition.top,
            'left': pickerPosition.left
          }"
          class="pick-coordinates-widget"
        >
          <app-widget-pick-coordinates [projectId]="projectId" [robotCallsign]="callsign" [widget]="widget">
          </app-widget-pick-coordinates>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!demoMode">
      <ng-container *ngIf="imageSource">
        <img class="img" #img [src]="imageSource" />
      </ng-container>
      <ng-container *ngIf="!imageSource"> NO IMAGE </ng-container>
    </ng-container>

    <ng-container *ngIf="currentConnectionStatus === connectionStatus.connecting">
      <div class="center-loading-spinner">
        <app-loading-spinner [isLoading]="true" [theme]="'light'" [size]="'x2'"></app-loading-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="demoMode">
      <div class="demo-image-placeholder">DEMO</div>
    </ng-container>
  </div>
</app-widget-base>
