import { Injectable } from '@angular/core';
import { BabylonRendererService } from './babylon-renderer.service';
import { Matrix } from '@babylonjs/core';
import { Subject, take } from 'rxjs';
import type { Feature, Point } from 'geojson';
import { MapMeshFactoryFeatureTypes } from './factories/map-mesh-factory';

@Injectable()
export class FeaturePickerService {
  private _selectedFeature$ = new Subject<Feature<Point>>();
  public get selectedFeature$() {
    return this._selectedFeature$.asObservable();
  }

  private get scene() {
    return this.babylonRenderer.scene;
  }

  public constructor(private babylonRenderer: BabylonRendererService) {}

  public init() {
    this.babylonRenderer.scene$.pipe(take(1)).subscribe(() => {
      this.scene.onPointerDown = () => {
        this.selectFeature();
      };
    });
  }

  public destroy(): void {
    this.scene.onPointerDown = null;
  }

  private selectFeature() {
    const ray = this.scene.createPickingRay(
      this.scene.pointerX,
      this.scene.pointerY,
      Matrix.Identity(),
      this.scene.activeCamera,
    );

    const hit = this.scene.pickWithRay(ray);
    const feature = hit.pickedMesh?.metadata?.feature as Feature<Point> | undefined;
    if (!feature) return;

    switch (feature.properties?.['type']) {
      case MapMeshFactoryFeatureTypes.PANORAMA:
        this._selectedFeature$.next(feature);
        break;
      default:
        break;
    }
  }
}
