import { Component, Input } from '@angular/core';
import type { IsLoading } from '@shared/interfaces';

@Component({
  selector: 'app-loading-content',
  templateUrl: './loading-content.component.html',
  styleUrls: ['./loading-content.component.scss'],
})
export class LoadingContentComponent implements IsLoading {
  @Input() isLoading: boolean = false;
  @Input() fullHeight: boolean = false;
}
