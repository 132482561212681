import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import type { Observable } from 'rxjs';

const SHORT_DURATION = 3 * 1000; // Milliseconds

export type ToastType = 'success' | 'failure' | 'error' | 'warning' | 'info';

export interface ToastRef {
  afterDismissed(): Observable<{ dismissedByAction: boolean }>;
  afterOpened(): Observable<void>;
  onAction(): Observable<void>;

  dismiss(): void;
  dismissWithAction(): void;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  public short(message: string, action: string | null = null, type: ToastType = 'success'): ToastRef {
    return this.open(message, action, SHORT_DURATION, type);
  }

  private open(
    message: string,
    action: string | null = null,
    duration: number = SHORT_DURATION,
    type?: ToastType,
  ): ToastRef {
    const panelClass = type ? `toast-${type}` : '';

    return this.snackBar.open(message, action ?? '', {
      duration,
      panelClass,
    });
  }
}
