import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '../shared';
import { ValueEvalPipe } from '../../pipes';

@Component({
  selector: 'app-widget-number',
  templateUrl: './widget-number.component.html',
  styleUrls: ['../shared/styles.scss', './widget-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetNumberComponent extends WidgetBaseComponent implements OnChanges {
  @Input() public value: unknown;
  @Input() public valueType: string;
  @Input() public stringDisplayValue: string;

  public validNumber: boolean = false;
  public validString: boolean = false;

  public isNumber: boolean = false;

  public stringStyleClasses: string[] = [];

  public maxStringLength: number = 180;

  public valueEvalPipe: ValueEvalPipe = new ValueEvalPipe();

  public constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['valueType']) {
      this.isNumber = this.valueType === 'number';
    }
    if (changes['value']) {
      this.updateStringValue();
      this.updateClasses();

      if (this.valueType === 'number') {
        this.updateValidNumber();
      } else if (this.valueType === 'string') {
        this.updateValidString();
      }
    }
  }

  private updateStringValue() {
    const getConvertedStringValue = () => {
      const value = this.value;

      if (value === null || value === undefined) {
        return ``;
      }

      const converted = this.valueEvalPipe.transform(value, this.valueExpression);

      return converted !== null && converted !== undefined ? `${converted}` : ``;
    };

    this.stringDisplayValue = getConvertedStringValue();
  }

  private updateClasses() {
    const classes = [];
    const value = this.stringDisplayValue;
    const stringLength = Math.min(value.length, this.maxStringLength);

    classes.push('force-wrap');
    classes.push(`string-value-${Math.floor(stringLength / 10) * 10}`);

    this.stringStyleClasses = classes;
  }

  private updateValidNumber() {
    this.validNumber = !!(this.value || this.value === 0);
  }

  private updateValidString() {
    this.validString = !!this.stringDisplayValue;
  }
}
