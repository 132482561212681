import type { ScreenButtonDef, ScreenControlPayloadDef } from './screen-button';

export class ControlConfig {
  controlsInfo: {
    [name: string]: ScreenButtonDef;
  } = {};

  private _controlDefs: ScreenButtonDef[];
  get controlDefs(): ScreenButtonDef[] {
    return this._controlDefs;
  }
  set controlDefs(defs: ScreenButtonDef[]) {
    this._controlDefs = defs;

    const info = {};
    if (defs?.length > 0) {
      defs.forEach((def) => {
        info[def.name] = def;
      });
    }
    this.controlsInfo = info;
  }

  private _payloads: ScreenControlPayloadDef[];
  get payloads(): ScreenControlPayloadDef[] {
    return this._payloads;
  }
  set payloads(payloads: ScreenControlPayloadDef[]) {
    this._payloads = payloads;
  }

  updateControlInfo(ctrl: ScreenButtonDef) {
    if (!this.controlsInfo) {
      this.controlsInfo = {};
    }

    this.controlsInfo[ctrl.name] = ctrl;
  }

  getControlInfo(ctrl: ScreenButtonDef) {
    if (!this.controlsInfo || !ctrl || !this.controlsInfo[ctrl.name]) {
      return null;
    }

    return this.controlsInfo[ctrl.name];
  }

  getActivePayloads(): ScreenControlPayloadDef[] {
    const activePayloads = [];
    if (this.payloads?.length > 0) {
      let defs = Object.values(this.controlsInfo);
      if (!defs || defs.length === 0) {
        defs = this.controlDefs;
      }
      this.payloads.forEach((payload) => {
        payload.updateMessageWithDefinitions(defs);

        // Message should not be null and should not contains '$' -- No unreplaced variables;
        if (payload.message && payload.message !== 'null' && payload.message.indexOf('$') === -1) {
          activePayloads.push(payload);
        }
      });
    }

    return activePayloads;
  }
}
