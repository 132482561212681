import type { ResourceNav } from 'src/app/workflow/modules/resources-management/models/resource-nav';
import type {
  EPickerHintFrames,
  WaypointLocation,
  WaypointPolygonData,
} from '../threeD/primitives/visualizer/interface/IWaypointPicker';
import { EPickerHintModes, EPickerHintNames } from '../threeD/primitives/visualizer/interface/IWaypointPicker';
import type { NodeComponent } from 'src/app/workflow/modules/graph-editor/components/node-component/node.component';
import type { IGraph } from 'src/app/workflow/modules/graph-editor/models/types';
import { getFrame, getNodeById } from './waypoint-parser.service';

export class PolygonParser {
  public static filterComponentsOnHint(
    components: NodeComponent[],
    hintName: EPickerHintNames,
    hintValue: EPickerHintModes | EPickerHintFrames,
  ): NodeComponent[] {
    return components.filter((comp) => {
      if (!comp?.config?.bi) return false;
      return Object.values(comp.config.bi).some((bi) => bi.hints?.[hintName] === hintValue);
    });
  }

  public static getNodeKeys(graph: IGraph, components: NodeComponent[]): string[] {
    const componentNames = components.map((comp) => comp.name);
    return Object.keys(graph.nodes).filter((key) => componentNames.includes(graph.nodes[key]));
  }

  public static collectPolygons(availableNodes: NodeComponent[], resource: ResourceNav): WaypointPolygonData[] {
    const polygonComponents = this.filterComponentsOnHint(
      availableNodes,
      EPickerHintNames.mode,
      EPickerHintModes.polygon,
    );

    const graph = Object.values(resource.currentValueAsGraphs.graphs)[0];
    const polygonNodesKeys = this.getNodeKeys(graph, polygonComponents);
    const polygonSockets = graph.defaults.filter((def) => polygonNodesKeys.includes(def.node));
    return polygonSockets
      .filter((data) => typeof data.default === 'object')
      .map((data) => {
        const wp = data.default as WaypointLocation[];
        const node = getNodeById(resource, parseInt(data.node, 10));
        const frame = getFrame(node);
        return {
          type: EPickerHintModes.polygon,
          id: data.node,
          waypoints: wp,
          pickerMeta: {
            ...wp?.[0]?.pickerMeta,
            frame,
          },
        } as WaypointPolygonData;
      });
  }
}
