import { Component, Input } from '@angular/core';
import type { ICustomWidgetContent } from '../../models';
import { IWidgetExternalContent, WidgetCustom } from '../../models';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-custom',
  templateUrl: './widget-custom.component.html',
  styleUrls: ['./widget-custom.component.scss'],
})
export class WidgetCustomComponent extends WidgetBaseComponent {
  @Input() externalContent: IWidgetExternalContent<ICustomWidgetContent>;
  @Input() override widget: WidgetCustom;

  constructor() {
    super();
  }
}
