import type { Position, RotationQuaternion } from '../../primitives';

export enum EPickerHintNames {
  mode = 'local_ops_picker',
  frame = 'local_ops_frame',
}

export enum EPickerHintModes {
  'waypoint' = 'xy_y',
  'waypointArray' = 'xyz[]_waypoints',
  'polygon' = 'xyz[]',
}

export enum EPickerHintFrames {
  default = '',
  wgs84 = 'wgs84',
}

export interface WaypointLocation {
  pos: Position;
  rot?: RotationQuaternion;
  pickerMeta?: PickerMeta;
}

export interface PickerMeta {
  height?: number;
  altitude?: number;
  frame?: string;
}

export interface WaypointBaseData {
  id?: string;
  parent?: EPickerHintFrames;
  tim?: number;
  pickerMeta?: PickerMeta;
}

export interface WaypointData extends WaypointBaseData, WaypointLocation {
  type: EPickerHintModes.waypoint;
}

export interface WaypointArrayData extends WaypointBaseData {
  type: EPickerHintModes.waypointArray;
  waypoints: WaypointLocation[];
}

export interface WaypointPolygonData extends WaypointBaseData {
  type: EPickerHintModes.polygon;
  waypoints: WaypointLocation[];
}

export interface WaypointCancelData {
  type: 'cancel';
}

export type WaypointActionResult = WaypointData | WaypointArrayData | WaypointPolygonData | WaypointCancelData;

export interface WaypointAction {
  type: 'get' | 'result';
  id: string;
  mode: EPickerHintModes;
  frame?: EPickerHintFrames | string;
  name: string;
  result?: WaypointActionResult;
}
