import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
@Pipe({ name: 'objectFilterKeys' })
export class ObjectFilterKeysPipe implements PipeTransform {
  transform(value: object, keys: string[]): object {
    if (!keys) return value;
    const asArray = Object.entries(value);
    const filtered = asArray.filter(([key]) => keys.indexOf(key) === -1);
    return Object.fromEntries(filtered);
  }
}
