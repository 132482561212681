import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { GamepadUxComponent } from '@shared/models';

@Pipe({
  name: 'commandAction',
})
export class CommandActionPipe implements PipeTransform {
  transform(gamepadUxComponent: GamepadUxComponent): string {
    switch (gamepadUxComponent.commandVersion) {
      case 'commands':
      case 'controls':
        return `Control: ${gamepadUxComponent.commandName}`;
      case 'commands-v2':
        return `Command: ${gamepadUxComponent.commandName}`;
      case 'run-js':
        return 'Run Javascript';
      default:
        return '';
    }
  }
}
