import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { DataExplorerModule } from '@shared/data-explorer';
import { LoadingButtonsModule } from '@shared-modules/loading-buttons';
import { SharedComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { GamepadCommandDialogComponent } from '../gamepad/gamepad-command-dialog';
import { MaterialModule } from '../material';
import { GamepadPreviewComponent } from './gamepad-preview';
import { GamepadValueBarComponent } from './gamepad-value-bar';
import { GamepadButtonConfigComponent } from './gamepad-button-config';
import { GamepadControlPayloadComponent } from './gamepad-control-payload';
import { GamepadButtonActionComponent } from './gamepad-button-action';
import { ScreenButtonConfigComponent } from './screen-button-config';
import { ScreenButtonActionComponent } from './screen-button-action';
import { ScreenButtonPayloadComponent } from './screen-button-payload';
import { ScreenButtonPreviewComponent } from './screen-button-preview';
import { ScreenButtonsComponent } from './screen-buttons';
import { GamepadControlsComponent } from './gamepad-controls';
import { GamepadCommandsV2Component } from './gamepad-commands-v2';
import { GamepadControlComponent } from './gamepad-control';
import { ScreenComponentsComponent } from './screen-components';
import { ScreenComponentEditDialogComponent } from './screen-component-edit-dialog';
import { ScreenSimpleTriggersComponent } from './screen-simple-triggers';
import { GamepadPressLogComponent } from './gamepad-press-log';
import { GamepadButtonActionDialogComponent } from './gamepad-button-action-dialog';
import { PipesModule } from '../pipes';
import { GamepadButtonVariableDialogComponent } from './gamepad-button-variable-dialog';
import { GamepadConfigWrapperComponent } from './gamepad-config-wrapper';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { MonacoEditorModule } from '@shared-modules/monaco-editor';
import { CommandActionPipe } from './command-version.pipe';
import { CommandParametersPipe } from './command-parameters.pipe';
import { CommandTypePipe } from './command-type.pipe';
import { GamepadCommandParamPipe } from './gamepad-command-param.pipe';

const components = [
  GamepadPreviewComponent,
  GamepadValueBarComponent,
  GamepadButtonConfigComponent,
  GamepadControlPayloadComponent,
  GamepadButtonActionComponent,
  ScreenButtonConfigComponent,
  ScreenButtonActionComponent,
  ScreenButtonPayloadComponent,
  ScreenButtonPreviewComponent,
  ScreenButtonsComponent,
  GamepadControlsComponent,
  GamepadCommandsV2Component,
  GamepadControlComponent,
  ScreenComponentsComponent,
  ScreenComponentEditDialogComponent,
  ScreenSimpleTriggersComponent,
  GamepadPressLogComponent,
  GamepadButtonActionDialogComponent,
  GamepadButtonVariableDialogComponent,
  GamepadConfigWrapperComponent,
  GamepadCommandDialogComponent,
];

const pipes = [CommandActionPipe, CommandParametersPipe, CommandTypePipe, GamepadCommandParamPipe];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    SharedComponentsModule,
    PipesModule,
    LoadingContentModule,
    MonacoEditorModule,
    LoadingButtonsModule,
    DataExplorerModule,
  ],
  declarations: [...components, ...pipes],
  exports: [...components],
})
export class GamepadModule {}
