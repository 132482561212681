import type { AbstractMesh, TransformNode } from '@babylonjs/core';
import { Injectable } from '@angular/core';
import type { Control } from '@babylonjs/gui';
import { AdvancedDynamicTexture, Rectangle, TextBlock } from '@babylonjs/gui';

@Injectable()
export class GuiFactoryService {
  private rendererGui: AdvancedDynamicTexture;
  private guiControls: { [parentName: string]: Control[] } = {};

  public setup() {
    this.rendererGui = AdvancedDynamicTexture.CreateFullscreenUI('GUI');
  }

  public cleanUp() {
    if (this.rendererGui) this.rendererGui.dispose();
  }

  public removeAllGuiControlsOfParentName(parentName: string) {
    if (!this.guiControls?.[parentName]?.length) return;
    this.guiControls[parentName].forEach((control) => {
      control.dispose();
    });
    this.guiControls[parentName] = [];
  }

  public setVisibleForGuiControls(meshName: string, visible: boolean) {
    const control = this.rendererGui.getControlByName(meshName);
    if (control) {
      control.isVisible = visible;
      control.isEnabled = visible;
    }
  }

  public setVisibleForAllGuiControlsOfParentName(parentName: string, visible: boolean) {
    if (this.guiControls?.[parentName] && this.guiControls[parentName].length > 0) {
      this.guiControls[parentName].forEach((control) => {
        control.isVisible = visible;
        control.isEnabled = visible;
      });
    }
  }

  public addGuiLabel(text: string, mesh: AbstractMesh | TransformNode, parentId: string, visible: boolean = true) {
    if (!text || !mesh || !parentId) return;

    const existingControl = this.rendererGui.getControlByName(mesh.id);
    if (existingControl) {
      existingControl.linkWithMesh(mesh);

      const labelTextBlock = existingControl.getDescendants()[0] as TextBlock;
      labelTextBlock.text = text;
      existingControl.isVisible = visible;

      return;
    }

    const rect = new Rectangle();
    rect.name = mesh.id;
    rect.height = '24px';
    rect.cornerRadius = 4;
    rect.color = '#F5F6F6';
    rect.background = '#232B34';
    rect.adaptWidthToChildren = true;
    rect.adaptHeightToChildren = true;
    rect.thickness = 1;
    rect.alpha = 0.9;
    rect.isVisible = visible;
    this.addGuiControl(rect, parentId);

    const label = new TextBlock();
    label.text = text;
    label.fontSize = 14;
    label.paddingTop = '4px';
    label.paddingLeft = '8px';
    label.paddingRight = '8px';
    label.resizeToFit = true;
    rect.addControl(label);

    // Must link with mesh after adding control to GUI system. i.e. after the function addGuiControl above.
    rect.linkWithMesh(mesh);
    rect.linkOffsetY = -45;
  }

  private addGuiControl(control: Control, parentName: string): void {
    this.rendererGui.addControl(control);
    if (!this.guiControls) this.guiControls = {};
    if (!this.guiControls[parentName]) this.guiControls[parentName] = [];
    this.guiControls[parentName].push(control);
  }
}
