import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import type {
  GamepadButtonAction,
  GamepadButtonConfig,
  GamepadControlPayload,
  GamepadExecuteAction,
  GamepadPayloadContext,
  ScreenControlPayloadDef,
} from '../../models';
import { ButtonDefWithValue, Gamepad } from '../../models';
import type { ControlConfig } from '../../models/gamepad/control-config';
import type { GamepadControl, GamepadUxComponent } from '../../models/gamepad/gamepad-control';
import type { ScreenButtonDef, ScreenControlValue } from '../../models/gamepad/screen-button';
import { GamepadPayloadHelper } from './gamepad-payload-helper';

@Injectable({
  providedIn: 'root',
})

// TODO: The GamepadService and GamepadConnectionService has duplicate code and should be refactor to one class
// https://app.clubhouse.io/rocos/story/7424/refactor-gamepadservice-and-gamepadconnectionservice
export class GamepadService implements OnDestroy {
  public gamepadUpdated = new BehaviorSubject<Gamepad[]>([]);
  public gamepadPressed = new Subject<Gamepad>();
  public controlPayloadsUpdated = new BehaviorSubject<ScreenControlPayloadDef[]>([]);

  private gamepads: Gamepad[] = [];

  private subs: Subscription[] = [];

  private frameIntervalDelayMs = 100;
  private frameRefreshInterval;

  private gamepadPressInfo: {
    [gamepadId: string]: {
      // If there are no pressed buttons, no need send updates
      // Make sure just send empty pressed message once.
      isNoPressed: boolean;
    };
  } = {};

  private pressedButtons: ButtonDefWithValue[] = [];
  private actions: GamepadButtonAction[] = [];
  private payloads: GamepadControlPayload[] = [];
  private variables: GamepadButtonConfig[] = [];
  private activeConfig: ControlConfig;

  private components: GamepadUxComponent[] = [];
  private triggers: any[] = [];
  private controls: GamepadControl[] = [];
  private lastGamepads = {};

  constructor() {
    this.listenGamepadConnections();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  updateActions(actions: GamepadButtonAction[]) {
    this.actions = actions;
  }

  updatePayloads(payloads: GamepadControlPayload[]) {
    this.payloads = payloads;
  }

  updateVariables(variables: GamepadButtonConfig[]) {
    this.variables = variables;
  }

  updateControlConfig(config: ControlConfig) {
    this.activeConfig = config;

    this.updateFrameRefreshInterval();
  }

  updateUxComponents(components: GamepadUxComponent[]) {
    this.components = components;
  }

  updateTriggers(triggers: any[]) {
    this.triggers = triggers;
  }

  updateControls(controls: GamepadControl[]) {
    this.controls = controls;
  }

  getPayloadsFromComponentEvent(
    component: GamepadUxComponent,
    event: any,
    controls: GamepadControl[],
    triggers: any[],
    context?: GamepadPayloadContext,
  ) {
    const executeControls = this.getExecuteControlsByComponentEvent(component, event, triggers);

    let shouldOverrideParas = false;
    const commandParams = [];
    if (component.commandParams) {
      // remove _showInputBox properties as they are not part of command paras
      for (const param of component.commandParams) {
        if (param.id.indexOf('_showInputBox') >= 0) {
          if (param.value === true) {
            shouldOverrideParas = true;
          }
        } else {
          commandParams.push(param);
        }
      }
    }

    // we only override the command paras at least one of the input has showInputBox value true
    if (shouldOverrideParas) {
      // use the input field value to override the orginal command paras
      executeControls.forEach((executeControl) => {
        executeControl.parameters = commandParams;
      });
    }

    const executeActions = this.getExecuteActionsByControls(executeControls, controls);
    return GamepadPayloadHelper.getExecutePayloadsByActions(executeActions, context);
  }

  getExecuteControlsByComponentEvent(component: GamepadUxComponent, event: any, triggers?: any[]): any[] {
    const commands = [];
    triggers = triggers ? triggers : this.triggers;

    // Find which trigger got fired.
    if (triggers?.length > 0) {
      triggers.forEach((trigger) => {
        if (trigger?.widgetConditions && trigger.widgetConditions.length > 0) {
          const conditions = trigger.widgetConditions;
          const conditionsOperator = trigger.conditionsOperator;
          let matchedAll = conditionsOperator === 'and';

          conditions.forEach((condition) => {
            // Condition should be matched.
            let matched = false;
            if (
              condition.controlType === component.controlType &&
              condition.id === component.id &&
              condition.event === event
            ) {
              matched = true;
            }

            if (conditionsOperator === 'and') {
              matchedAll = matchedAll && matched;
            } else {
              matchedAll = matchedAll || matched;
            }
          });

          if (matchedAll) {
            commands.push(...trigger.commands);
          }
        }
      });
    }

    return commands;
  }

  getExecuteActionsByControls(executingControls: any[], controls?: any[]): GamepadExecuteAction[] {
    controls = controls ? controls : this.controls;

    const executeActions: GamepadExecuteAction[] = [];
    const controlsObj = GamepadPayloadHelper.getObjectFromList(controls);

    if (executingControls?.length > 0) {
      executingControls.forEach((command) => {
        if (controlsObj[command.id]) {
          const parametersObj = GamepadPayloadHelper.getObjectFromList(command.parameters);

          const actions = controlsObj[command.id].actions;

          executeActions.push({
            actions,
            parametersObj,
          });
        }
      });
    }

    return executeActions;
  }

  setScreenControlActionValue(btnDef: ScreenButtonDef, btnVal: ScreenControlValue) {
    if (this.activeConfig) {
      btnDef.currentValue = btnVal;

      this.activeConfig.updateControlInfo(btnDef);
    }
  }

  private listenGamepadConnections() {
    const gamepadConnectedSub = fromEvent(window, 'gamepadconnected').subscribe(() => {
      this.reloadGamepads();
    });

    this.subs.push(gamepadConnectedSub);

    const gamepadDisconnectedSub = fromEvent(window, 'gamepaddisconnected').subscribe(() => {
      this.reloadGamepads();
    });

    this.subs.push(gamepadDisconnectedSub);

    // Reload gamepads by default.
    this.reloadGamepads();
  }

  private reloadGamepads(createRefreshInterval = true) {
    const browserGamePads = navigator.getGamepads();
    const gamepads = [];

    for (const gamepad of browserGamePads) {
      if (gamepad?.id != null) {
        Gamepad.fromGamepadModel(gamepad);
        gamepads.push(Gamepad.fromGamepadModel(gamepad));
      }
    }

    this.gamepads = gamepads;

    if (createRefreshInterval) {
      this.gamepadUpdated.next(this.gamepads);
    }

    if (this.gamepads?.length > 0) {
      this.gamepads.forEach((gamepad) => {
        // Initialize gamepadPressInfo if it doesn't exist.
        if (!this.gamepadPressInfo[gamepad.id]) {
          this.gamepadPressInfo[gamepad.id] = {
            isNoPressed: false,
          };
        }

        // detect gamepad release event
        // when a key is released from the gamepad, the key will not be returned from the gamepad
        // we detect a key released by checking the last state of the key value
        if (this.lastGamepads?.[gamepad.id]?.pressedValues) {
          this.lastGamepads[gamepad.id].pressedValues.forEach((x) => {
            if (x.value === 0) {
              return;
            }
            const foundKeys = gamepad.pressedValues.filter((y) => {
              return y.type === x.type;
            });
            if (!foundKeys || foundKeys.length === 0) {
              // Axis control does not require release event.

              // The button is missing from the gamepad new frame, which means the button is released
              // we treat a button release as a button pressed with value 0
              const button = new ButtonDefWithValue(x.type, x.text, 0);
              gamepad.pressedValues.push(button);
            }
          });
        }

        // record the last gamepad state
        this.lastGamepads[gamepad.id] = gamepad;

        if (gamepad.pressedValues?.length > 0) {
          this.gamepadPressed.next(gamepad);
          this.pressedButtons = gamepad.pressedValues;

          // We got pressed buttons
          this.gamepadPressInfo[gamepad.id].isNoPressed = false;
        } else {
          if (this.gamepadPressInfo[gamepad.id]) {
            const pressedInfo = this.gamepadPressInfo[gamepad.id];
            if (!pressedInfo.isNoPressed) {
              this.gamepadPressed.next(gamepad);
              pressedInfo.isNoPressed = true;
            }
          }
        }
      });
    }

    if (createRefreshInterval && this.gamepads) {
      this.updateFrameRefreshInterval();
    }
  }

  private refreshScreenControls() {
    if (this.activeConfig) {
      const payloads = this.activeConfig.getActivePayloads();

      if (payloads?.length > 0) {
        this.controlPayloadsUpdated.next(payloads);
      }
    }
  }

  private updateFrameRefreshInterval() {
    if (this.gamepads?.length > 0 || this.activeConfig) {
      clearInterval(this.frameRefreshInterval);

      this.frameRefreshInterval = setInterval(() => {
        this.reloadGamepads(false);
        this.refreshScreenControls();
      }, this.frameIntervalDelayMs);
    } else {
      clearInterval(this.frameRefreshInterval);
    }
  }

  private checkGamepadActionsAndGenerateMessages() {
    if (this.actions?.length > 0 && this.pressedButtons?.length > 0) {
      // TODO
      // For each pressed button, get target actions.
      // For each target action, get target payloads
      // For each payloads, generate message by template and variables.
      // Send message output to subscribers.
    }
  }
}
