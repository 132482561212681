import { Injectable } from '@angular/core';
import type { OperationPage } from '../../models';
import { RocosSdkClientService } from '@shared/services/rocos-sdk-client';
import type { IOperation } from '@dronedeploy/rocos-js-sdk';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  constructor(private rocosClientService: RocosSdkClientService) {}

  /**
   * Strip out any extra properties that may have been added to the operation object.
   */
  public static sanitiseOperation<T extends IOperation>(operation: T): IOperation {
    return {
      name: operation.name,
      pageType: operation.pageType,
      payload: operation.payload,
      description: operation.description,
    };
  }

  async create(projectId: string, op: IOperation): Promise<any> {
    const payload = await this.injectLocation(projectId, op);
    op = {
      ...op,
      payload,
    };

    return this.rocosClientService.client.getProjectService().createOperation(projectId, op);
  }

  list(projectId: string): Promise<OperationPage[]> {
    return this.rocosClientService.client.getProjectService().getOperationsList(projectId);
  }

  delete(projectId: string, operationIds: string | string[]): Promise<any> {
    const ids = Array.isArray(operationIds) ? operationIds : [operationIds];
    return this.rocosClientService.client.getProjectService().deleteOperationsForProject(projectId, ids);
  }

  getOne(projectId: string, operationId: string): Promise<OperationPage> {
    return this.rocosClientService.client.getProjectService().getOperation(projectId, operationId);
  }

  update(projectId: string, operationId: string, op: IOperation): Promise<any> {
    return this.rocosClientService.client.getProjectService().updateOperation(projectId, operationId, op);
  }

  /**
   * Injects the project location into the payload
   */
  private async injectLocation(
    projectId: string,
    operation: Pick<IOperation, 'pageType' | 'payload'>,
  ): Promise<string> {
    const payload = operation.payload;

    const project = (await this.rocosClientService.client.getProjectService().info(projectId)) as unknown;
    const location = project?.['location'] as { lat: number; lng: number } | undefined;
    if (location) {
      const parsedPayload = JSON.parse(payload ?? '{}');
      if (operation.pageType === 'local') {
        return JSON.stringify({
          projectLocation: {
            latitude: location.lat,
            longitude: location.lng,
            altitude: 0,
          },
          ...parsedPayload,
        });
      } else if (operation.pageType === 'global') {
        return JSON.stringify({
          ...parsedPayload,
          mapbox: {
            startZoom: 16,
            startLocation: [location.lng, location.lat],
            ...parsedPayload?.['mapbox'],
          },
        });
      }
    }

    return payload;
  }
}
