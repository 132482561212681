<ng-container *ngIf="item">
  <div
    class="item"
    *ngIf="item.layerLevel > 0"
    [style.margin-left]="(item.layerLevel - 1) * (24 + 8) + 'px'"
    [class.selectable]="item.isSelectable"
    (click)="item.isSelectable && onItemSelect(item)"
  >
    <div class="left">
      <div (click)="$event.stopPropagation()">
        <button
          mat-icon-button
          class="trigger-button"
          *ngIf="item.hasChildren && !item.hiddenChildren"
          (click)="onShowHideChildren(item)"
          data-testid="openButton"
        >
          <span [class.ri-add]="item.isCollapsed" [class.ri-minus]="item.isExpanded"></span>
        </button>
      </div>

      <div class="trigger-button-place-holder" *ngIf="!item.hasChildren || item.hiddenChildren">
        <span style="width: 32px"></span>
      </div>

      <!-- Icon -->
      <div class="item-icon" [class.no-children]="!item.hasChildren" [ngClass]="[item.iconClass]"></div>

      <!-- Text -->
      <div class="text" [matTooltip]="item.text" matTooltipClass="rs-text-tooltip">{{ item.text }}</div>

      <!-- Selected Children Count -->
      <div *ngIf="showSelectedChildCount && item.selectedItems && item.selectedItems.length > 0" class="selected-count">
        ({{ item.selectedItems.length }})
      </div>
    </div>

    <div class="right" (click)="$event.stopPropagation()">
      <div *ngIf="item.isInsertable && insertDataURIMode">
        <button
          matTooltip="Insert"
          mat-icon-button
          color="primary"
          (click)="onInsertDataURI(item)"
          data-testid="inserButton"
        >
          <span class="ri-copy-link"></span>
        </button>
      </div>
      <div *ngIf="item.isSelectable && item.itemType !== 'attribute'">
        <button
          matTooltip="Copy URI"
          mat-icon-button
          color="primary"
          (click)="onCopyURI(item)"
          data-testid="copyButton"
        >
          <span class="ri-copy"></span>
        </button>
      </div>
      <div>
        <button
          type="button"
          class="item-selection-toggle-button"
          [class.selected]="item.selected"
          mat-icon-button
          (click)="onItemSelect(item)"
          *ngIf="item.isSelectable"
          data-testid="selectButton"
        >
          <span class="ri-view"></span>
        </button>
      </div>
    </div>
  </div>

  <!-- Children Items -->
  <!-- Hide children for data stream object -->
  <ng-container *ngIf="!item.hiddenChildren">
    <ng-container *ngIf="item.isExpanded || item.itemType === 'root'">
      <div *ngFor="let child of item.children">
        <app-data-sources-tree-item
          [item]="child"
          (selectionChange)="onChildrenSelectionChange(child)"
          [insertDataURIMode]="insertDataURIMode"
          (dataURIChange)="onDataURIChange($event)"
          (dataItemChange)="onDataItemChange($event)"
          [showSelectedChildCount]="showSelectedChildCount"
        >
        </app-data-sources-tree-item>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
