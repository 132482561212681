import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CourierWrapperComponent } from './courier-wrapper.component';

@NgModule({
  declarations: [CourierWrapperComponent],
  exports: [CourierWrapperComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CourierWrapperModule {}
