import { DatePipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { parseExpression } from 'cron-parser';

@Pipe({
  name: 'cronToNextDate',
})
export class CronToNextDatePipe implements PipeTransform {
  transform(cronValue: string, options?: { offset?: string; showInLocal?: boolean }): Date | undefined {
    const datePipe = new DatePipe('en-US', options?.offset);
    const currentDate = datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const parsed = parseExpression(cronValue, { currentDate });

    if (!parsed.hasNext()) return undefined;

    const parsedDate = parsed.next().toDate();

    if (options?.showInLocal) {
      // subtracts the timezone offset of the local user and the robot then applies it to the parsed date
      const requestedTimezoneOffset = offsetToMins(options.offset);
      const localTimezoneOffset = new Date().getTimezoneOffset(); // difference in minutes between UTC and local time
      parsedDate.setMinutes(parsedDate.getMinutes() + (requestedTimezoneOffset - localTimezoneOffset));
    }

    return parsedDate;
  }
}

// Gets the time difference in minutes from the UTC timezone
// e.g. GMT+1 -> -60, GMT-4 -> 240
export const offsetToMins = (offset: string) => {
  if (!offset) return 0;
  if (offset.indexOf(':') === -1) offset = `${offset}:00`; // add minutes if not present

  // Deal with Z or z
  if (/z/i.test(offset)) return 0;

  // Deal +/-HH:mm, flips it because we want the difference relative to UTC
  const sign = /-/.test(offset) ? 1 : -1;
  const [h, m] = offset
    .match(/(\d+):(\d+)/)
    .slice(1) // remove the full match
    .map(Number);

  const offsetInMins = h * 60 + m;
  return sign * offsetInMins;
};
