import { Component, Input } from '@angular/core';
import { ExternalTargetType } from '@shared/components/external-link';

@Component({
  selector: 'app-documentation-button',
  templateUrl: './documentation-button.component.html',
  styleUrls: ['./documentation-button.component.scss'],
})
export class DocumentationButtonComponent {
  @Input() targetType: ExternalTargetType = 'docs';
}
