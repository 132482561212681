import { Injectable } from '@angular/core';
import { AppService } from '@shared/services';
import { RocosSdkClientService } from '@shared/services/rocos-sdk-client';
import type { Overlay, Plan } from '@dronedeploy/rocos-js-sdk';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, combineLatest, from, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, expand, filter, map, reduce, startWith, tap } from 'rxjs/operators';

export type KnownLayerName = 'Tiled 3D Point Cloud' | 'Tiled 3D Mesh Layer' | 'Orthomosaic';

@Injectable({ providedIn: 'root' })
export class PlansService {
  private pagesLoaded = new BehaviorSubject<boolean>(false);
  private plans = new BehaviorSubject<{ items: Plan[]; loading: boolean }>({ items: [], loading: true });
  private overlays = new BehaviorSubject<{ items: Overlay[]; loading: boolean }>({ items: [], loading: true });

  public get allPlansLoaded$(): Observable<boolean> {
    return combineLatest([this.plans, this.overlays]).pipe(
      map(([plans, overlays]) => !plans.loading && !overlays.loading),
      filter((res) => !!res),
      distinctUntilChanged(),
    );
  }

  constructor(private sdk: RocosSdkClientService, private appService: AppService) {
    this.appService.projectIdChange
      .pipe(
        startWith(this.appService.projectId),
        filter((val) => !!val),
        distinctUntilChanged(),
      )
      .subscribe((projectId) => {
        this.loadAllPlans(projectId)
          .then((plans) => this.plans.next({ items: plans, loading: false }))
          .catch(() => this.plans.next({ items: [], loading: false }));

        this.loadAllOverlays(projectId)
          .then((overlays) => this.overlays.next({ items: overlays, loading: false }))
          .catch(() => this.overlays.next({ items: [], loading: false }));
      });
  }

  public selectPlans(...layerNameFilters: KnownLayerName[]): Observable<Plan[]> {
    if (!layerNameFilters.length) return this.plans.pipe(map((p) => p.items));

    return this.plans.pipe(
      map((plans) =>
        plans.items.filter((p) =>
          p.layer?.layers?.find((layer) =>
            layerNameFilters.map((layerName) => layerName.toLocaleLowerCase()).includes(layer.name.toLocaleLowerCase()),
          ),
        ),
      ),
    );
  }

  public selectOverlays(): Observable<Overlay[]> {
    return this.overlays.pipe(map((overlays) => overlays.items.filter((o) => !!o.tile_layer?.url)));
  }

  private loadAllPlans(projectId: string): Promise<Plan[]> {
    const service = this.sdk.client.getIntegrationService();

    this.pagesLoaded.next(false);

    // Keep loading pages until there are no more pages to load (pageNext is null)
    return lastValueFrom(
      from(service.getPlansPaged(projectId)).pipe(
        expand((res) => (res.pageNext ? service.getPlansPaged(projectId, res.pageNext) : EMPTY)),
        reduce((acc, res) => [...acc, ...res.plans], [] as Plan[]),
        tap(() => this.pagesLoaded.next(true)),
      ),
    );
  }

  private loadAllOverlays(projectId: string): Promise<Overlay[]> {
    const service = this.sdk.client.getIntegrationService();
    return service.getOverlays(projectId);
  }
}
