import { StreamConfig } from './stream-config';

export class StreamConfigROSBridge extends StreamConfig {
  public sampleRate = 1000;

  constructor() {
    super();

    this.type = 'ros-bridge';
    this.name = 'ROSBridge';
    this.logoURL = 'assets/images/stream-sources/ros_logo.png';
  }

  public static override fromModel(model: any): StreamConfigROSBridge {
    const config = new StreamConfigROSBridge();

    config.name = model.name;
    config.type = model.type;
    config.logoURL = model.logoURL;
    config.enabled = model.enabled;

    if (model.topic) {
      config.topic = model.topic;
    }
    if (model.sampleRate) {
      config.sampleRate = model.sampleRate;
    }

    return config;
  }
}
