import { GeometryShapeMultiPoint } from '../mapping';

/**
 *  Specifications as per https://uavionix.com/downloads/pingstation/pingStation-ICD.pdf
 */

/*
    Example Payload
    {
    "icaoAddress":"39C812",
    "trafficSource":0,
    "latDD":47.538528,
    "lonDD":-115.133696,
    "altitudeMM":13106400,
    "headingDE2":203,
    "horVelocityCMS":23149,
    "verVelocityCMS":0,
    "squawk":1362,
    "altitudeType":0,
    "callsign":"LEA022H ",
    "emitterType":2,
    "pingStationGuid":"7541622b4f4c2e59",
    "utcSync":1,
    "timeStamp":"2017-02-13T14:42:00.111Z",
    "detail": {
        "navIntegrity":8,
        "navAccuracy":2,
        "verVelocitySrc":1,
        "emergencyStatus":0,
        "surveilStatus":0,
        "baroaltDiffMM":0,
        "sysIntegrityLevel":3,
        "airGroundState":0,
        "svHeadingType":0,
        "verticalVelType":1,
        "navPositionAccuracy":10,
        "navVelocityAccuracy":2,
        "navIntegrityBaro":1,
        "tcasAcasOperating":1,
        "tcasAcasAdvisory":0,
        "identSwActive":0,
        "magHeading":0,
        "utcCoupledCondition":0
    }
*/

export class Traffic {
  public aircraft: Aircraft[] = [];

  public get geometryShapeMultiPoint(): GeometryShapeMultiPoint {
    const mp = new GeometryShapeMultiPoint();
    this.aircraft.forEach((item, _idx) => {
      mp.addGeoPoint(item.latDD, item.lonDD, item.altitudeMM / 1000);
    });
    return mp;
  }
}

export class TrafficStatus {
  public status: Status;
}

export class Status {
  public pingStationGuid: string;
  public pingStationVersionMajor: number;
  public pingStationVersionMinor: number;
  public pingStationVersionBuild: number;
  public timeStamp: string;
  public pingStationLatDD: number;
  public pingStationLonDD: number;
  public gpsStatus: number;
  public receiverStatus: number;
}

export class Aircraft {
  /** ICAO of the aircraft */
  public icaoAddress: string;

  /**
   * 0 = 1090ES
   * 1 = UAT
   */
  public trafficSource: number;

  /** Latitude expressed as decimal degrees */
  public latDD: number;

  /** Longitude expressed as decimal degrees */
  public lonDD: number;

  /** Geometric altitude or barometric pressure altitude in millimeters */
  public altitudeMM: number;

  /** Course over ground in centi-degrees */
  public headingDE2: number;

  /** Horizontal velocity in centimeters/sec  */
  public horVelocityCMS: number;

  /** Vertical velocity in centimeters/sec with positive being up */
  public verVelocityCMS: number;

  /** Squawk code */
  public squawk: number;

  /** Altitude Source
   * 0 = Pressure
   * 1 = Geometric
   */
  public altitudeType: number;

  /** Callsign */
  public callsign: string;

  /** Category type of the emitter
        0 = No aircraft type information
        1 = Light (ICAO) < 15,500 lbs
        2 = Small - 15,500 to 75,000 lbs
        3 = Large - 75,000 to 300,000 lbs
        4 = High Vortex Large (e.g., B757)
        5 = Heavy (ICAO) - > 300,000 lbs
        6 = Highly Maneuverable > 5G acceleration and high speed
        7 = Rotorcraft
        8 = (Unassigned)
        9 = Glider/sailplane
        10 = Lighter than air
        11 = Parachutist/sky diver
        12 = Ultralight/hang glider/paraglider
        13 = (Unassigned)
        14 = Unmanned aerial vehicle
        15 = Space/trans-atmospheric vehicle
        16 = (Unassigned)
        17 = Surface vehicle-emergency vehicle
        18 = Surface vehicle-service vehicle
        19 = Point Obstacle (includes tethered balloons)
        20 = Cluster Obstacle
        21 = Line Obstacle
        22-39 = (Reserved)
   */
  public emitterType: number;

  /** Auto incrementing packet sequence number */
  public sequenceNumber: number;

  /** Unique pingStation identifier
   * string format: %02x%02x%02x%02x%02x%02x%02x%02x
   */
  public pingStationGuid: string;

  /** UTC time flag */
  public utcSync: number;

  /** Time packet was received at the pingStation
   * ISO 8601 format:YYYY-MM-DDTHH:mm:ss:ffffffffZ
   */
  public timeStamp: string;

  /** Aircraft detail */
  public detail: AircraftDetail;
}

export class AircraftDetail {
  /** Navigation integrity category (NIC)
        0 = RC >=37.04 km (20 NM) Unknown Integrity
        1 = RC < 37.04 km (20 NM) RNP-10 containment radius
        2 = RC < 14.816 km (8 NM) RNP-4 containment radius
        3 = RC < 7.408 km (4 NM) RNP-2 containment radius
        4 = RC < 3.704 km (2 NM) RNP-1 containment radius
        5 = RC < 1852 m (1 NM) RNP-0.5 containment radius
        6 = RC < 1111.2 m (0.6 NM) RNP-0.3 containment radius
        7 = RC < 370.4 m (0.2 NM) RNP-0.1 containment radius
        8 = RC < 185.2 m (0.1 NM) RNP-0.05 containment radius
        9 = RC < 75 m and VPL < 112 m e.g., SBAS, HPL, VPL
        10 = RC < 25 m and VPL < 37.5 m e.g., SBAS, HPL, VPL
        11 = RC < 7.5 m and VPL < 11 m e.g., GBAS, HPL, VPL
        12 = (Reserved) (Reserved)
        13 = (Reserved) (Reserved)
        14 = (Reserved) (Reserved)
        15 = (Reserved) (Reserved)
   */
  public navIntegrity: number;

  /**
   * Navigation accuracy category (NACv)
        0 = Unknown or >= 10 m/s Unknown >= 50 feet (15.24 m) per
        second
        1 = < 10 m/s < 50 feet (15.24 m) per second
        2 = < 3 m/s < 15 feet (4.57 m) per second
        3 = < 1 m/s < 5 feet (1.52 m) per second
        4 = < 0.3 m/s < 1.5 feet (0.46 m) per second
        5 = (Reserved) (Reserved)
        6 = (Reserved) (Reserved)
        7 = (Reserved) (Reserved)
   */
  public navAccuracy: number;

  /** Vertical velocity source
        0 = Pressure
        1 = Geometric
   */
  public verVelocitySrc: number;

  /** Emergency status
        0 = No-Emergency
        1 = General Emergency
        2 = Lifeguard/Medical
        3 = Min Fuel
        4 = No Comm
        5 = Unlawful Interference
        6 = Downed Aircraft
   */
  public emergencyStatus: number;

  /** 1090ES Specific
   * Surveillance status
        0 = No Condition
        1 = permanent alert
        2 = temp alert
        3 = SPI
   */
  public surveilStatus: number;

  /** 1090ES Specific
   * Difference between the pressure altitude and the gnss altitude in mm
   */
  public baroaltDiffMM: number;

  /** System Integrity Level (SIL) */
  public sysIntegrityLevel: number;

  /** Airborne or ground
        0 = Airborne subsonic condition
        1 = Airborne supersonic condition
        3 = On ground condition
   */
  public airGroundState: number;

  /** Track angle from heading
        0 = Data Not Available
        1 = True Track Angle
        2 = Magnetic Headin
   */
  public svHeadingType: number;

  /** Vertical rate information
        0 = Pressure
        1 = Geometric
   */
  public verticalVelType: number;

  /** The reported State Vector has sufficient position accuracy for
        the intended use (NACp)
        0 = EPU >= 18.52 km (10 NM)
        1 = EPU < 18.52 km (10 NM)
        2 = EPU < 7.408 km (4 NM)
        3 = EPU < 3.704 km (2 NM)
        4 = EPU < 1852 m (1NM)
        5 = EPU < 926 m (0.5 NM)
        6 = EPU < 555.6 m (0.3 NM)
        7 = EPU < 185.2 m (0.1 NM)
        8 = EPU < 92.6 m (0.05 NM)
        9 = EPU < 30 m and VEPU < 45
        10 = EPU < 10 m and VEPU < 15
        11 = EPU < 3 m and VEPU < 4 m
        12 = (Reserved)
        13 = (Reserved)
        14 = (Reserved)
        15 = (Reserved)
   */
  public navPositionAccuracy: number;

  /** The least accurate velocity component being transmitted (NACv)
        0 = Unknown or >= 10 m/s Unknown or >= 50 feet (15.24 m)
        per second
        1 = < 10 m/s < 50 feet (15.24 m) per second
        2 = < 3 m/s < 15 feet (4.57 m) per second
        3 = < 1 m/s < 5 feet (1.52 m) per second
        4 = < 0.3 m/s < 1.5 feet (0.46 m) per second
        5 = (Reserved) (Reserved)
        6 = (Reserved) (Reserved)
        7 = (Reserved) (Reserved)
   */
  public navVelocityAccuracy: number;

  /** Barometer checked (NICbaro)
        0 = Barometric Pressure Altitude has NOT been cross checked
        1 = Barometric Pressure Altitude has been cross checked
   */
  public navIntegrityBaro: number;

  /** Aircraft is fitted with a TCAS (ACAS) computer and that
   * computer is turned on and operating in a mode that can
   * generate Resolution Advisory (RA) alerts
   */
  public tcasAcasOperating: number;

  /** TCAS II or ACAS computer is currently issuing a Resolution
   *  Advisory
   */
  public tcasAcasAdvisory: number;

  /** Ident switch is activated */
  public identSwActive: number;

  /** ATC pilot message mode setting
        0 = Not receiving ATC messages
        1 = Receiving ATC messages
   */
  public atcServicesRecvd: number;

  /** True north or magnetic north
        0 = True north
        1 = Magnetic north
   */
  public magHeading: number;

  /** Represents if the Ground Station is UTC-Coupled
        0 = Ground Station is not UTC coupled
        1 = Ground Station is UTC coupled
   */
  public utcCoupledCondition: number;

  /*
        -- UAT Specific Fields --

        secondaryAltType
        %d
        Altitude source
        0 = Pressure
        1 = Geometric

        secondaryAltitudeMM
        %ld
        Geometric altitude or barometric pressure altitude in millimeters

        tisBSiteId
        %d
        The tisBSiteId is unit-less and is from the a transmitted TISb
        UAT message signifies which uplink tower transmitted the
        TISb frame

        transmitMSO
        &d
        the transmitMSO is the 6bit field from the transmitted UAT
        message which should signify which MSO the message was
        transmitted in. MSO's can range from 0 to 3951 but only
        transmit the 6 LSB's of the actual MSO if transmitted.
        Received range is from 0 - 63.

        addressQualifier
        %d
        Defines the type of target that delivered the data
        0 = ADS-B target with ICAO 24-bit
        1 = Reserved for National use
        2 = TIS-B target with ICAO 24-bit address
        3 = TIS-B target with track file identifier
        4 = Surface Vehicle
        5 = Fixed ADS-B Beacon
        6 = (Reserved)
        7 = (Reserved

        uatMopsVersion
        %d
        1 = DO-282A
        2 = DO-282B
        callSignID %d 0 = Fightplan
        1 = CallSign

        RID Specific Fields
        uvin
        %02x%02x%02x%02x-
        %02x%02x-02x%02x-
        %02x%02x%02x%02x-
        %02x%02x%02x%02x
        %02x%02x
        UVIN that contains the hex values of the UVIN identifier
        I.E. 5129d5c8-a300-464e-8614-a678ccb26f80

        toLatDD
        %f
        Takeoff latitude expressed as decimal degrees

        toLonDD
        %f
        Takeoff longitude expressed as decimal degrees

        missionType
        %d
        Numeric representation of the type of mission being flown
        0 = recreational
        1 = photography/videography
        2 = media
        3 = point/structure inspection
        4 = agriculture
        5 = mapping
        6 = surveillance
        7 = law enforcement / first responder
        8 = search and rescue
        9 = delivery
        10 = urgent delivery
        11 = other

        phoneNumber
        %d
        Phone number attached to the mission

        fltplanFiled
        %d
        NOTAM/UOA flight plan filed flag
    */
}
