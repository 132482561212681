import { Injectable } from '@angular/core';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { RobotService } from '../robot/robot.service';
import { AppService } from '../app';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { combineLatest, concat, defer, of } from 'rxjs';
import { RocosSdkClientService } from '@shared/services';
import type { IRobot } from '@dronedeploy/rocos-js-sdk';

@Injectable({
  providedIn: 'root',
})
export class RobotInfoService {
  public agentVersion$ = new BehaviorSubject<string | undefined>(undefined);
  public robot$: Observable<IRobot>;

  constructor(private appService: AppService, private robotService: RobotService, private sdk: RocosSdkClientService) {
    const callsign$ = concat(
      defer(() => of(this.appService.callsign)), // Needed to start with the current value at the time of subscription
      this.appService.callsignChange,
    ).pipe(filter((callsign) => !!callsign));

    const projectId$ = concat(
      defer(() => of(this.appService.projectId)), // Needed to start with the current value at the time of subscription
      this.appService.projectIdChange,
    ).pipe(filter((projectId) => !!projectId));

    const robotId$ = combineLatest([callsign$, projectId$]).pipe(
      map(([callsign, projectId]) => ({
        callsign,
        projectId,
      })),
    );

    robotId$
      .pipe(switchMap(({ callsign, projectId }) => this.robotService.getAgentVersionFromCloud(projectId, callsign)))
      .subscribe((agentVersion) => {
        this.agentVersion$.next(agentVersion);
      });

    this.robot$ = robotId$.pipe(
      switchMap(({ callsign, projectId }) => this.sdk.client.getRobotService().info(projectId, callsign)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  }
}
