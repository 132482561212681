import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingStrokedButtonComponent } from './loading-stroked-button';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner';
import { LoadingFlatButtonComponent } from './loading-flat-button';
import { LoadingBaseButtonComponent } from './loading-base-button';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule, LoadingSpinnerModule],
  declarations: [LoadingBaseButtonComponent, LoadingStrokedButtonComponent, LoadingFlatButtonComponent],
  exports: [LoadingStrokedButtonComponent, LoadingFlatButtonComponent],
})
export class LoadingButtonsModule {}
