import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ButtonDefWithValue } from '../../models';
import { Gamepad } from '../../models';

export class PressedKeyLogItem {
  key: ButtonDefWithValue;
  timeStamp: Date;

  constructor(key: ButtonDefWithValue) {
    this.key = key;
    this.timeStamp = new Date();
  }
}

@Component({
  selector: 'app-gamepad-press-log',
  templateUrl: './gamepad-press-log.component.html',
  styleUrls: ['./gamepad-press-log.component.scss'],
})
export class GamepadPressLogComponent implements OnChanges {
  @Input()
  pressingGamepad: Gamepad;
  @Input()
  maxLogCount: number = 10;

  pressedKeyLog: PressedKeyLogItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pressingGamepad']) {
      this.addLogFromGamepad(this.pressingGamepad);
    }
  }

  onClear() {
    this.pressedKeyLog = [];
  }

  private addLogFromGamepad(gamepad: Gamepad) {
    if (gamepad?.pressedValues) {
      const list: PressedKeyLogItem[] = [];

      gamepad.pressedValues.forEach((val) => {
        list.push(new PressedKeyLogItem(val));
      });

      let pressedKeyLog = [];

      if (this.pressedKeyLog) {
        this.pressedKeyLog.forEach((item) => {
          pressedKeyLog.push(item);
        });
      }
      if (list) {
        list.forEach((item) => {
          pressedKeyLog.push(item);
        });
      }

      pressedKeyLog = pressedKeyLog.slice(-1 * this.maxLogCount);

      this.pressedKeyLog = pressedKeyLog;
    }
  }
}
