export class GamepadNewControlAction {
  name: string;
  type: string;
  metadata: any;
  adminOnly: boolean;
  uri: string;

  static fromModel(model: any): GamepadNewControlAction {
    const controlAction = new GamepadNewControlAction();

    controlAction.name = model.name;
    controlAction.type = model.type;
    controlAction.metadata = model.metadata;
    controlAction.adminOnly = model.adminOnly;
    controlAction.uri = model.uri;

    // Rename payloadTemplate to payload
    if (controlAction.metadata?.payloadTemplate && !controlAction.metadata.payload) {
      controlAction.metadata.payload = controlAction.metadata.payloadTemplate;
    }

    return controlAction;
  }

  static fromOldAction(oldAction: any, controlActions: GamepadNewControlAction[]): GamepadNewControlAction {
    const controlAction = new GamepadNewControlAction();

    controlAction.uri = oldAction.type;
    controlAction.metadata = oldAction.metadata;

    const cloneOne = controlActions.find((action) => {
      return action.uri === oldAction.type;
    });

    if (cloneOne) {
      controlAction.name = cloneOne.name;
      controlAction.type = cloneOne.type;
      controlAction.adminOnly = cloneOne.adminOnly;
    }

    return controlAction;
  }
}
