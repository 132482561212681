import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import type { IsLoading } from '../../interfaces';
import type { FunctionConfig, GamepadNewControlAction } from '../../models';
import { ConfigGroupItem, GamepadControl, GamepadControlAction } from '../../models';
import { FunctionService, RobotDefinitionService } from '../../services';

@Component({
  selector: 'app-gamepad-controls',
  templateUrl: './gamepad-controls.component.html',
  styleUrls: ['./gamepad-controls.component.scss'],
})
export class GamepadControlsComponent implements OnInit, IsLoading {
  @Input()
  isLoading: boolean = false;

  @Input()
  projectId: string;

  @Input()
  controlActions: GamepadNewControlAction[] = [];

  @Input()
  currentModule: 'robot' | 'robot-definition' = 'robot';

  // New way that works with ConfigGroupItem
  @Input()
  items: ConfigGroupItem<GamepadControl>[] = [];
  @Output()
  itemsChange: EventEmitter<ConfigGroupItem<GamepadControl>[]> = new EventEmitter<ConfigGroupItem<GamepadControl>[]>();

  displayedColumns: string[] = [
    'id',
    'actions',
    'parameters',
    // 'editControls',
  ];

  functions: FunctionConfig[] = [];

  constructor(
    private dialogService: DialogService,
    private functionService: FunctionService,
    private robotDefinitionService: RobotDefinitionService,
  ) {}

  ngOnInit() {
    this.loadFunctions();
  }

  onControlChange(item: ConfigGroupItem<GamepadControl>, updateControl: GamepadControl, index: number) {
    item.value = updateControl;

    this.items.splice(index, 1, item);

    this.itemsChange.next(this.items);
  }

  /**
   * Callback for removing command
   */
  onRemoveControl(index: number) {
    const title = 'Delete Control?';
    const message = '<p>Are you sure you want to delete this control?</p>';

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          this.items.splice(index, 1);

          this.itemsChange.next(this.items);
        }
      });
  }

  /**
   * Callback for adding new command.
   */
  onAddNewControl() {
    const control = new GamepadControl(null, [new GamepadControlAction()], []);

    const item = new ConfigGroupItem<GamepadControl>(control);

    // Set the `_isOverridden` to true if creating command under *robot* module.
    if (this.currentModule === 'robot') {
      item._isOverridden = true;
    }

    this.items.push(item);
  }

  private loadFunctions() {
    this.functionService
      .list(this.projectId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.functions = res;
        },
        () => {
          // do nothing
        },
      );
  }
}
