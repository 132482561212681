// json-path is a simple JSON path selector parser.
// This is a copy of the source code that is no longer maintained and its dependency no longer works
// Original source from here:
// https://github.com/flitbit/json-path/blob/master/index.js
// Allows us to traverse a JSON object using a JSON Pointer.

// disable eslint for this file as it is a copy of an un-maintained library
/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { JsonPointer } from 'json-ptr';

export const resolve = (data, selector, fn?) => {
  const path = parseSelector(selector);
  return pipedSelect(data, path, fn);
};

function parseSelector(source) {
  const state = {
    result: [],
    stack: [],
    cursor: -1,
    offset: 0,
  };
  performParse(source, state);
  return state.result;
}

function pipedSelect(datum, steps, fn) {
  let s = -1;
  let data = Array.isArray(datum) ? datum : [datum];
  const slen = steps.length;
  let accum;
  let i;
  let len;
  while (++s < slen && data.length > 0) {
    i = -1;
    len = data.length;
    accum = [];
    while (++i < len) {
      accum = steps[s](data[i], accum, fn);
    }
    data = accum;
  }
  return data;
}

function dbc(requirements, description) {
  requirements = Array.isArray(requirements) ? requirements : [requirements];
  let i;
  let disposition;
  for (i = 0; i < requirements.length; i++) {
    const req = requirements[i];
    disposition = typeof req === 'function' ? req() : req;
    if (!disposition) {
      description = description || 'Failed contract requirement:'.concat(req);
      throw new Error(typeof description === 'function' ? description() : description);
    }
  }
}

function seekAny(source, cursor, chars) {
  chars = Array.isArray(chars) ? chars : [chars];
  let i = cursor;
  let j;
  const len = source.length;
  const clen = chars.length;
  while (++i < len) {
    j = -1;
    while (++j < clen) {
      if (source[i] === chars[j]) {
        return i;
      }
    }
  }
  return -1;
}

function expectSequence(source, cursor, end, sequence) {
  let c = cursor - 1;
  let i = -1;
  const seqlen = sequence.length;
  if (end - cursor < seqlen) {
    throw new Error('Expected `'.concat(sequence, '` beginning at character ', cursor, '.'));
  }
  while (++c < end && ++i < seqlen) {
    if (source[c] !== sequence[i]) {
      throw new Error(
        'Unexpected character at position '.concat(
          c + '',
          ' expected `',
          sequence,
          '` beginning at position ',
          cursor,
          '.',
        ),
      );
    }
  }
}

function expectMatchingClose(source, cursor, closeCh) {
  const openCh = source[cursor];
  let i = cursor;
  const len = source.length;
  const stack = [];
  stack.push(cursor);
  while (++i < len) {
    if (source[i] === openCh) {
      stack.push(cursor);
    } else if (source[i] === closeCh) {
      stack.pop();
      if (stack.length === 0) {
        break;
      }
    }
  }
  if (stack.length > 0) {
    throw new Error('Expected `'.concat(source[0], '` to have a matching `', closeCh, '`.'));
  }
  return i;
}

function fromJsonPointer(source, state) {
  const cursor = state.cursor;
  const selectors = state.result;
  const len = source.length;
  let end = seekAny(source, cursor, [']', '[']);
  if (end < cursor) {
    end = len;
  }
  const p = JsonPointer.create(source.substring(cursor, end));
  selectors.push(function (obj, accum) {
    accum = accum || [];
    const it = p.get(obj);
    if (typeof it !== 'undefined') {
      accum.push(it);
    }
    return accum;
  });
  state.cursor = end - 1;
}

function expect(source, state, expected) {
  expectSequence(source, state.cursor, source.length, expected);
}

function descent(obj, steps, accum, fn) {
  accum = accum || [];
  let i = -1;
  let keys;
  let len;
  let data;
  if (typeof obj === 'object' && obj !== null) {
    data = pipedSelect(obj, steps, fn);
    if (data.length > 0) {
      accum = accum.concat(data);
    }
    if (!Array.isArray(obj)) {
      keys = Object.keys(obj);
      len = keys.length;
      while (++i < len) {
        accum = descent(obj[keys[i]], steps, accum, fn);
      }
    }
  }
  return accum;
}

function prepareExhaustiveDescent(source, state) {
  const res = state.result;
  const lift = [];
  state.result = lift;
  res.push(function (obj, _, fn) {
    return descent(obj, lift, _, fn);
  });
  performParse(source, state);
  state.result = res;
}

function selectAny(obj, accum) {
  accum = accum || [];
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      accum = accum.concat(obj);
    } else {
      let i = -1;
      const keys = Object.keys(obj);
      const len = keys.length;
      while (++i < len) {
        accum.push(obj[keys[i]]);
      }
    }
  }
  return accum;
}

function compilePredicate(expression, _invert, _offset): void {
  let i = -1;
  const len = expression.length;
  let ch;
  const variables = {};
  let la;
  let v;
  const infix = [];
  while (++i < len) {
    ch = expression[i];
    dbc([false], 'Expressions are not implemented in this version.');
    if (ch === '#') {
      {
        la = expression.indexOf(' ', i);
        if (la < i) {
          la = len;
        }
        v = expression.substring(i, la);
        if (!variables[v]) {
          variables[v] = JsonPointer.create(v);
        }
        infix.push({ kind: 'v', ref: variables[v] });
        i = la;
        break;
      }
    }
  }
}

function preparePredicate(source, state) {
  const invert = source[state.cursor] === '!';
  if (invert) {
    state.cursor++;
  }
  expect(source, state, '{');
  const end = expectMatchingClose(source, state.cursor, '}');
  const expression = source.substring(state.cursor + 1, end);
  state.result.push(compilePredicate(expression, invert, state.offset));
  state.cursor = end;
}

function parseUserSelector(source, state) {
  const cursor = state.cursor;
  const len = source.length;
  let end = source.indexOf(']', cursor);
  if (end < cursor) {
    end = len;
  }
  const n = source.substring(cursor, end);
  state.result.push(function (data, accum, sel) {
    let target;
    if (data) {
      if (n.length === 0 && typeof sel === 'function') {
        target = sel;
      } else if (typeof sel === 'object' && sel) {
        if (!sel[n] && sel.RESOLVER) {
          target = sel.RESOLVER(n);
        } else {
          target = sel[n];
        }
      }
      if (!target) {
        throw new Error('Missing user-supplied function: `'.concat(n.length > 0 ? n : '@', '`.'));
      }
      return target(data, accum, sel);
    }
    return accum;
  });
  state.cursor = end - 1;
}

function parseTake(source, state) {
  let cursor = state.cursor;
  const end = source.indexOf(')', cursor);
  expectSequence(source, cursor, end, 'take(');
  cursor += 5;
  const them = source.slice(cursor, end).split(',');
  let i = -1;
  const len = them.length;
  let it;
  while (++i < len) {
    it = them[i].split('=');
    if (it.length === 1) {
      it = JsonPointer.create(it[0]);
      it = { name: it.path[it.path.length - 1], ptr: it };
    } else if (it.length === 2) {
      it = { name: it[0], ptr: JsonPointer.create(it[1]) };
    } else {
      throw new Error('Invalid `take` expression');
    }
    cursor += them[i].length;
    them[i] = it;
  }
  state.result.push(function (obj, accum) {
    accum = accum || [];

    const it = {};

    let i = -1;

    const len = them.length;
    while (++i < len) {
      it[them[i].name] = them[i].ptr.get(obj);
    }
    accum.push(it);
    return accum;
  });
  state.cursor = end;
}

function expectInteger(source, cursor, _end) {
  let c = cursor;
  while (source[c] >= '0' && source[c] <= '9') {
    c = c + 1;
  }
  if (c === cursor) {
    throw new Error('Expected an integer at position '.concat(c, '.'));
  }
  return c - cursor;
}

function parseArrayVerb(source, cursor, end, verb, thems) {
  let index = 1;
  let len;
  expectSequence(source, cursor, end, verb);
  cursor += verb.length - 1;
  if (source[cursor + 1] === '(') {
    cursor += 2;
    len = expectInteger(source, cursor, end);
    index = parseInt(source.substring(cursor, cursor + len), 10);
    cursor += len;
    expectSequence(source, cursor, end, ')');
    ++cursor;
  }
  thems.push({ kind: verb[0], index });
  return cursor;
}

function parseSelectByIndex(source, state) {
  let cursor = state.cursor - 1;
  const len = source.length;
  let end = source.indexOf(']', state.cursor);
  let num = null;
  let punct = false;
  const thems = [];
  if (end < cursor) {
    end = len;
  }
  while (++cursor < end) {
    switch (source[cursor]) {
      case ' ':
        if (num !== null) {
          thems.push({ kind: 'i', index: parseInt(source.substring(num, cursor), 10) });
          num = null;
          punct = true;
        }
        break;
      case ',':
        {
          if (num !== null) {
            thems.push({ kind: 'i', index: parseInt(source.substring(num, cursor), 10) });
            num = null;
          }
          if (punct) {
            punct = false;
          }
        }
        break;
      case '.':
        {
          expectSequence(source, cursor, end, '..');
          if (num !== null) {
            thems.push({ kind: 's', index: parseInt(source.substring(num, cursor), 10) });
            num = null;
          }
          cursor++;
          if (punct) {
            punct = false;
          }
        }
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        {
          if (punct) {
            throw new Error('Unexpected numeral at position '.concat(cursor + '', ' expected punctuation.'));
          }
          if (num === null) {
            num = cursor;
          }
        }
        break;
      case 'l':
        {
          if (punct) {
            throw new Error('Unexpected numeral at position '.concat(cursor + '', ' expected punctuation.'));
          }
          cursor = parseArrayVerb(source, cursor, end, 'last', thems);
        }
        break;
      case 'f':
        {
          if (punct) {
            throw new Error('Unexpected numeral at position '.concat(cursor + '', ' expected punctuation.'));
          }
          cursor = parseArrayVerb(source, cursor, end, 'first', thems);
        }
        break;
      case 'c': {
        if (punct) {
          throw new Error('Unexpected numeral at position '.concat(cursor + '', ' expected punctuation.'));
        }
        cursor = parseArrayVerb(source, cursor, end, 'count', thems);
        break;
      }
    }
  }
  if (num !== null) {
    thems.push({ kind: 'i', index: parseInt(source.substring(num, cursor), 10) });
  }
  state.result.push(function (obj, accum) {
    accum = accum || [];
    if (Array.isArray(obj)) {
      let i = -1;

      const len = thems.length;
      const alen = obj.length;
      let j;
      let last;
      while (++i < len) {
        const it = thems[i];
        switch (it.kind) {
          case 'c':
            accum.push(alen);
            break;
          case 'f':
            {
              j = -1;
              while (++j < it.index && j < alen) {
                accum.push(obj[j]);
              }
            }
            break;
          case 'l':
            {
              j = alen;
              last = alen - it.index;
              while (--j >= last && j > 0) {
                accum.push(obj[j]);
              }
            }
            break;
          case 'i':
          case 's': {
            if (it.index < alen) {
              accum.push(obj[it.index]);
              if (it.kind === 's') {
                j = it.index;
                last = ++i < len ? thems[i].index : alen - 1;
                while (++j <= last) {
                  accum.push(obj[j]);
                }
              }
            }
          }
        }
      }
    }
    return accum;
  });
  state.cursor = end - 1;
}

function performParse(source, state) {
  dbc([typeof source === 'string'], 'Selector must be a string.');
  if (source.length === 0) {
    return [];
  }
  const len = source.length;
  let ch;

  while (++state.cursor < len) {
    ch = source[state.cursor];
    switch (ch) {
      case '/':
      case '#':
        {
          fromJsonPointer(source, state);
        }
        break;
      case '[':
        {
          state.stack.push(state.cursor);
        }
        break;
      case ']':
        {
          if (state.stack.length > 0) {
            state.stack.pop();
          } else {
            throw new Error('Unexpected `]` at cursor position '.concat(state.cursor, '.'));
          }
        }
        break;
      case '.':
        {
          expect(source, state, '..');
          ++state.cursor;
          prepareExhaustiveDescent(source, state);
        }
        break;
      case '*':
        {
          expect(source, state, '*]');
          state.result.push(selectAny);
        }
        break;
      case '{':
      case '!':
        {
          preparePredicate(source, state);
        }
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        {
          parseSelectByIndex(source, state);
        }
        break;
      case 'l':
        {
          parseSelectByIndex(source, state);
        }
        break;
      case 'f':
        {
          parseSelectByIndex(source, state);
        }
        break;
      case 'c':
        {
          parseSelectByIndex(source, state);
        }
        break;
      case 't':
        {
          parseTake(source, state);
        }
        break;
      case '@':
        {
          state.cursor += 1;
          parseUserSelector(source, state);
        }
        break;
      default: {
        throw new Error('Unexpected character at position '.concat(state.cursor, ': ', ch, '.'));
      }
    }
  }
  dbc([state.stack.length === 0], function () {
    return 'Unexpected end; unclosed scope beginning at cursor position '.concat(state.stack.pop(), '.');
  });

  return undefined;
}
