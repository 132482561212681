import { environment } from '@env/environment';
import type { IScene } from '../primitives/visualizer/interface/IScene';

export const migrateSceneJson = (sceneJson: IScene): { sceneJson: IScene; migrations: string[] } => {
  const migrations = [];
  sceneJson = JSON.parse(JSON.stringify(sceneJson));

  if (sceneJson.meshes) {
    Object.values(sceneJson.meshes).forEach((mesh) => {
      if (!mesh) return;

      // Migrate mesh.ops.listChidren to mesh.ops.listChildren
      if (mesh.ops && 'listChidren' in mesh.ops) {
        migrations.push(`Migrating mesh.ops.listChidren to mesh.ops.listChildren for mesh ${mesh.key}`);
        mesh.ops.listChildren = mesh.ops['listChidren'] as boolean;
        delete mesh.ops['listChidren'];
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (mesh.lineSize) {
        migrations.push(`Migrating mesh.lineSize to mesh.geoJSON.lineSize for mesh ${mesh.key}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mesh.geoJSON = { ...mesh.geoJSON, lineSize: mesh.lineSize };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete mesh.lineSize;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (mesh.pointSize) {
        migrations.push(`Migrating mesh.pointSize to mesh.geoJSON.pointSize for mesh ${mesh.key}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mesh.geoJSON = { ...mesh.geoJSON, pointSize: mesh.pointSize };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete mesh.pointSize;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (mesh.lineMaterial) {
        migrations.push(`Migrating mesh.lineMaterial to mesh.geoJSON.lineMaterial for mesh ${mesh.key}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mesh.geoJSON = { ...mesh.geoJSON, lineMaterial: mesh.lineMaterial };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete mesh.lineMaterial;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (mesh.pointMaterial) {
        migrations.push(`Migrating mesh.pointMaterial to mesh.geoJSON.pointMaterial for mesh ${mesh.key}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mesh.geoJSON = { ...mesh.geoJSON, pointMaterial: mesh.pointMaterial };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete mesh.pointMaterial;
      }

      if (mesh.controlPoint?.id && mesh.controlPoint.id !== mesh.displayName) {
        migrations.push(`Migrating mesh.controlPoint.id to mesh.displayName for mesh ${mesh.key}`);
        mesh.displayName = mesh.controlPoint.id;
      }

      // Migrate old base url to use environment.api.url
      ['https://dev-api.rocos.io', 'https://stage-api.rocos.io', 'https://api2.rocos.io'].forEach((oldBaseUrl) => {
        if (mesh.options?.rootUrl?.startsWith(oldBaseUrl)) {
          migrations.push(`Migrating legacy mesh.options.rootUrl to for mesh ${mesh.key}`);
          mesh.options.rootUrl = mesh.options.rootUrl.replace(oldBaseUrl, environment.api.url);
        }
      });
    });
  }

  return { sceneJson, migrations };
};
