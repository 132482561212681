import {
  ColumnSeries,
  DateAxis,
  LineSeries,
  ValueAxis,
  XYChart,
  XYChartScrollbar,
  XYCursor,
} from '@amcharts/amcharts4/charts';
import { color, create, options } from '@amcharts/amcharts4/core';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { filter } from 'lodash';
import { WidgetTimeInterval } from '../../models';
import type { ChartSeries } from '../shared';
import { ChartColor, WidgetBaseComponentTheme } from '../shared';
options.commercialLicense = true;

@Component({
  selector: 'app-widget-multiple-lines-chart',
  templateUrl: './widget-multiple-lines-chart.component.html',
  styleUrls: ['./widget-multiple-lines-chart.component.scss'],
})
export class WidgetMultipleLinesChartComponent implements OnInit, OnDestroy, OnChanges {
  // Set static to true as this variable will be used in ngOnInit.
  @ViewChild('chartdiv', { static: true, read: ElementRef })
  chartdiv: ElementRef;

  @Input() enableScrollbar = false;
  @Input() height = '300px';
  @Input() fontSize = 12;
  @Input() baseInterval: WidgetTimeInterval = new WidgetTimeInterval('millisecond', 200);
  @Input() theme: WidgetBaseComponentTheme = 'light';
  @Input() liveDataMode = false;
  @Input() newDataExpiresInSeconds: number = 30;
  @Input() rangeMinimumValue: number;
  @Input() rangeMaximumValue: number;
  @Input() batchedDataMode = false;
  @Input() showValueAsColumn = false; // Display chart as a column chart instead of a line chart.
  /**
   * For some chart, we do not want to use interval settings. (e.g. data without fixed intervals.)
   */
  @Input() applyBaseInterval: boolean = true;

  @Output() minMaxUpdated = new EventEmitter<any>();

  public isChartInitialized: boolean = false;

  private chart: XYChart;
  private chartId: string;
  private dateAxis: DateAxis;
  private valueAxis: ValueAxis;
  private seriesCount: number = 0;
  private groupValue: Array<any> = [];
  private animationInterval: any;
  private animationFrameRateInMilliseconds: number = 500;
  private rangeChangeDuration: number = 0;
  private batchedArray: any[] = [];

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.graphInit();
    });

    this.updateTheme();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.ngZone.runOutsideAngular(() => {
        this.chart.dispose();
      });
    }

    clearInterval(this.animationInterval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['baseInterval'] || changes['applyBaseInterval']) this.updateBaseInterval();
    if (changes['theme']) this.updateTheme();
  }

  public clearAllData() {
    const clearFunc = () => {
      NgZone.assertNotInAngularZone();

      if (!this.chart) return;
      this.chart.data = [];
      const seriesLength = this.chart.series.length;
      for (let i = seriesLength; i > 0; i--) {
        this.chart.series.removeIndex(i - 1);
      }
    };

    this.ngZone.runOutsideAngular(clearFunc);
  }

  public loadInitData(data: any[], series: ChartSeries[]) {
    const loadDataFunc = () => {
      NgZone.assertNotInAngularZone();
      if (!this.chart) throw Error('Should initialize the chart first.');

      series.forEach((item, idx) => {
        item.index = idx;
        this.addSeries(item);
        this.seriesCount++;
      });
      this.chart.data = data;

      if (this.liveDataMode) this.loadBlankHistoricData();
    };

    this.ngZone.runOutsideAngular(loadDataFunc);
  }

  public loadBlankHistoricData() {
    const blankData = [];
    for (let i = this.newDataExpiresInSeconds * 1000; i > 0; i = i - this.animationFrameRateInMilliseconds) {
      let theDate = Date.now();
      theDate -= i;
      const value: any = {
        'date': new Date(theDate),
        'date-0': theDate,
      };
      blankData.push(value);
    }
    this.chart.addData(blankData);
  }

  /** Does not add straight away, sets the value and makes it available for the next render */
  public addNewValue(value: any) {
    if (this.chart) this.groupValue[value.groupIndex] = value;
  }

  public addNewValueDirectly(value: any) {
    if (this.chart) this.chart.addData(value);
  }

  public addNewBatchedValue(value: any) {
    if (this.batchedArray) this.batchedArray.push(value);
  }

  public removeDataByGroupIndex(index: number) {
    if (!this.chart?.data) return;
    const filtered = this.chart.data.filter((item) => {
      return item.groupIndex !== index;
    });

    this.chart.data = filtered;
  }

  private graphInit() {
    this.updateChartId();

    this.chartdiv.nativeElement.style.height = this.height;

    const chart = create(this.chartId, XYChart);
    chart.fontSize = this.fontSize;

    this.dateAxis = chart.xAxes.push(new DateAxis());
    this.dateAxis.tooltip.fontSize = this.fontSize;

    if (this.liveDataMode) {
      this.dateAxis.renderer.grid.template.disabled = true;
      this.dateAxis.rangeChangeDuration = 0;
      this.dateAxis.visible = false;
      this.dateAxis.tooltipDateFormat = '[bold]HH:mm:ss';
      if (this.batchedDataMode) {
        this.dateAxis.renderer.grid.template.disabled = false;
        this.dateAxis.renderer.minGridDistance = 60;
        if (this.applyBaseInterval) {
          this.dateAxis.baseInterval = this.baseInterval;
        }
        this.dateAxis.visible = true;
      }
    } else {
      // Slow lane charts
      this.dateAxis.renderer.grid.template.location = 0;
      this.dateAxis.renderer.minGridDistance = 50;
    }

    this.dateAxis.events.on(
      'rangechangeended',
      (_ev) => {
        if (this.dateAxis.minZoomed && this.dateAxis.maxZoomed) {
          const divisibleBy1000: boolean = this.dateAxis.minZoomed % 1000 === 0 && this.dateAxis.maxZoomed % 1000 === 0;

          // If both min and max date can be divided by 1000, then
          // the selection is not triggered by a human, then we do
          // not send event out.
          if (!divisibleBy1000) {
            this.ngZone.run(() => {
              this.minMaxUpdated.next({
                min: new Date(this.dateAxis.minZoomed),
                max: new Date(this.dateAxis.maxZoomed),
              });
            });
          }
        }
      },
      this,
    );

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.tooltip.disabled = false;
    valueAxis.tooltip.fontSize = this.fontSize;
    valueAxis.renderer.opposite = true; // moves the axis labels to the right hand side
    valueAxis.rangeChangeDuration = this.rangeChangeDuration;
    valueAxis.interpolationDuration = 0;

    if (this.rangeMinimumValue !== undefined && this.rangeMinimumValue != null) {
      valueAxis.min = this.rangeMinimumValue;
    }
    if (this.rangeMaximumValue !== undefined && this.rangeMaximumValue != null) {
      valueAxis.max = this.rangeMaximumValue;
    }

    this.valueAxis = valueAxis;

    chart.cursor = new XYCursor();

    if (this.enableScrollbar) {
      const scrollbarX = new XYChartScrollbar();
      chart.scrollbarX = scrollbarX;
      chart.scrollbarX.parent = chart.bottomAxesContainer;
    }

    this.chart = chart;
    this.isChartInitialized = true;

    this.updateBaseInterval();

    if (!this.liveDataMode) return;

    // start the animation to include data
    this.animationInterval = setInterval(() => {
      if (this.batchedDataMode) {
        const expiredDataCount = this.getExpiredDataCount();
        this.chart.addData(this.batchedArray, expiredDataCount);
        this.batchedArray = [];
      } else {
        this.chart.addData(this.groupValue, this.seriesCount);
        this.groupValue = [];
        for (let i = 0; i < this.seriesCount; i++) {
          let theDate = Date.now();
          theDate += this.animationFrameRateInMilliseconds;

          const value: any = {
            'date': new Date(theDate),
            ['date-' + i]: new Date(theDate),
          };
          this.groupValue.push(value);
        }
      }
    }, this.animationFrameRateInMilliseconds);
  }

  private addSeries(seriesInfo: ChartSeries) {
    if (!this.chart) throw Error('Should initialize the chart first.');

    const field = seriesInfo.field;
    const name = seriesInfo.name;
    const index = seriesInfo.index;
    const minValueField = seriesInfo.minValueField;
    const maxValueField = seriesInfo.maxValueField;

    let dateField = 'date';
    if (!this.liveDataMode) dateField = seriesInfo.dateField;

    const lineColor = seriesInfo.color ? seriesInfo.color : ChartColor.getColor(index);

    const newSeries = this.showValueAsColumn ? new ColumnSeries() : new LineSeries();
    const series = this.chart.series.push(newSeries);
    series.dataFields.dateX = dateField;
    series.dataFields.valueY = field;
    series.strokeWidth = 2;
    series.stroke = color(lineColor);
    series.name = name;

    series.tooltipText = '{valueY.value}';
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = color(lineColor);
    series.tooltip.fontSize = this.fontSize;

    series.defaultState.transitionDuration = 0;
    series.interpolationDuration = 0;

    if (this.showValueAsColumn) {
      series.stroke = color(lineColor);
      series.fill = color(lineColor);
      series.strokeWidth = 0;
    } else {
      (series as LineSeries).connect = seriesInfo.connect;
    }

    if (minValueField && maxValueField) {
      series.tooltipText = 'avg: {valueY.value}';

      const rangeSeries = this.chart.series.push(new LineSeries());
      rangeSeries.dataFields.dateX = dateField;
      rangeSeries.dataFields.openValueY = minValueField;
      rangeSeries.dataFields.valueY = maxValueField;

      rangeSeries.fillOpacity = 0.2;
      rangeSeries.fill = color(lineColor);
      rangeSeries.strokeWidth = 0;
      rangeSeries.stroke = color(lineColor);

      rangeSeries.tooltipText = 'min: {openValueY.value}, max: {valueY.value}';
      rangeSeries.tooltip.getFillFromObject = false;
      rangeSeries.tooltip.background.fill = color(lineColor);
      rangeSeries.tooltip.background.fillOpacity = 0.5;
      rangeSeries.tooltip.fontSize = this.fontSize;

      rangeSeries.defaultState.transitionDuration = 0;
      rangeSeries.interpolationDuration = 0;
      rangeSeries.connect = seriesInfo.connect;
    }

    if (this.enableScrollbar && seriesInfo.isAddToScrollbar && this.chart.scrollbarX) {
      const scrollbar = this.chart.scrollbarX as XYChartScrollbar;
      scrollbar.series.push(series);
    }
  }

  private updateChartId() {
    if (!this.chartId) {
      const guid = crypto.randomUUID();
      this.chartId = 'chartdiv' + guid;
    }

    this.chartdiv.nativeElement.id = this.chartId;
  }

  private updateBaseInterval() {
    if (this.dateAxis && this.baseInterval) {
      if (this.applyBaseInterval) {
        this.dateAxis.baseInterval = this.baseInterval;
      } else {
        this.dateAxis.baseInterval = new WidgetTimeInterval('millisecond', 1);
      }
    }
  }

  private updateTheme() {
    if (!this.isChartInitialized) return;

    let colorVar = '#000';
    switch (this.theme) {
      case 'light':
        colorVar = '#6A7380';
        break;
      case 'dark':
        colorVar = '#DFE1E3';
        break;
    }

    this.dateAxis.renderer.labels.template.fill = color(colorVar);
    this.valueAxis.renderer.labels.template.fill = color(colorVar);
  }

  private getExpiredDataCount() {
    if (!this.chart) return 0;

    const expiredLine = new Date(Date.now() - this.newDataExpiresInSeconds * 1000);
    return filter(this.chart.data, (o) => {
      return o?.date < expiredLine;
    }).length;
  }
}
