import { WidgetBase } from './widget-base';

export class WidgetJavascript extends WidgetBase {
  code: string = '';
  autoRun?: boolean = false;
  showPanelButton?: boolean = false;
  showToolbarButton?: boolean = false;

  public static fromModel(model: any): WidgetJavascript {
    const widget = new WidgetJavascript();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.code = model.code ? model.code : 'alert("ROCOS!");';
    this.autoRun = !!model.autoRun;
  }
}
