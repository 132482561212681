import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'a[appExternalLink]',
})
export class ExternalLinkDirective {
  constructor(private el: ElementRef<HTMLAnchorElement>) {
    this.el.nativeElement?.relList?.add('noreferrer', 'noopener', 'nofollow');
  }
}
