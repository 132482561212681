<div class="box">
  <div class="header">
    <div class="left">
      <div class="icon">
        <span class="ri-sendMessage"></span>
      </div>
      <div class="info">
        <div class="prefix">
          <span [matTooltip]="prefix">{{ prefix }}</span>
        </div>
        <div class="title">
          <span [matTooltip]="suffix">{{ suffix }}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <button type="button" class="left-icon-button" mat-stroked-button color="primary" (click)="onCopyURI()">
        <span class="icon ri-copy"></span>
        <span> Copy URI </span>
      </button>

      <div class="more">
        <button mat-icon-button matTooltip="more" [matMenuTriggerFor]="moreInfoMenu" tabindex="-1">
          <span class="ri-more icon-button grey-icon"></span>
        </button>
        <mat-menu #moreInfoMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="onClosePreview()">
            <span class="label"> Close preview </span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="preview-container">
    <div class="request-payload-header">Payload</div>
    <div class="payload">
      <app-code-editor language="text/plain" class="editor" [formControl]="payload"> </app-code-editor>
    </div>
    <div class="preview-buttons">
      <div class="status-text has-error" *ngIf="status?.success === false">
        {{ status?.msg ?? 'Failed to send message' }}
      </div>
      <div class="status-text" *ngIf="status?.success">
        <span class="success-result">
          {{ status?.msg ?? 'Success' }}
        </span>
      </div>

      <div class="spacer"></div>
      <app-loading-button class="loading-button" [loading]="isSending" (click)="sendMessage()">
        <span>Send Message</span>
      </app-loading-button>
    </div>
  </div>
</div>
