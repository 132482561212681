export class AccountUser {
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  isOwner: boolean;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static fromModel(model: any): AccountUser {
    const user = new AccountUser();

    user.email = model.email;
    user.firstName = model.firstName;
    user.lastName = model.lastName;
    user.id = model.id;
    user.isOwner = model.isOwner;

    return user;
  }
}
