import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-colour-picker',
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss'],
})
export class ColourPickerComponent implements OnInit {
  @Input() public values: string[];
  @Input() public selectedValue: string;
  @Output() public valueChange = new EventEmitter<string>();

  public isOpen = false;
  public overlayPositions = [
    new ConnectionPositionPair(
      {
        originX: 'end',
        originY: 'bottom',
      },
      {
        overlayX: 'end',
        overlayY: 'top',
      },
      0,
      4,
    ),
    new ConnectionPositionPair(
      {
        originX: 'end',
        originY: 'top',
      },
      {
        overlayX: 'end',
        overlayY: 'bottom',
      },
      0,
      -4,
    ),
  ];

  public ngOnInit(): void {
    if (!this.selectedValue) {
      this.selectedValue = this.values?.[0];
    }
  }

  public toggle($event: MouseEvent): void {
    $event.preventDefault();
    this.isOpen = !this.isOpen;
  }

  public change(value: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.selectedValue = value;
    this.isOpen = false;
    this.valueChange.emit(value);
  }
}
