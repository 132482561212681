import { Injectable } from '@angular/core';
import type { Scene } from '@babylonjs/core';

@Injectable({
  providedIn: 'root',
})
export class BabylonInspectorService {
  showInspector(
    scene: Scene,
    _ = {
      enablePopup: true,
      overlay: true,
      embedMode: true,
    },
  ) {
    console.warn('Babylon Inspector is only included in local builds');
  }
}
