import type { ICommandV2TypeSettingItemsCollection, ICommandV2Type } from './command-v2-type';
import { CommandV2Types, CommandV2TypeTemplateItemTypes } from './command-v2-type';

export class CommandV2PayloadParameter {
  // id: string;
  public type: string = 'string';
  public default: string;

  public static fromModel(model: { default: string }): CommandV2PayloadParameter {
    const param = new CommandV2PayloadParameter();

    // param.id = model.id;
    param.default = model.default;

    return param;
  }
}

export interface IAvailableCommandV2TypesCollection {
  [commandType: string]: ICommandV2Type;
}

const commonCommandTypeFromModel = (model, command: CommandV2): CommandV2 => {
  if (model.settings) {
    if (typeof model.settings.payloadMeta === 'string') {
      // Known issue: the model can be sent from server for form field.
      // There is an edge case user might enter "" inside the form field. Then, the logic
      // underneath will have an issue to differentiate the value be object and be string.
      // NOTE THAT: If user complaining why "" entered in the form being replaced with empty
      // value on the form field, it's by design.

      // Try parsing the payloadMeta from string to object
      try {
        model.settings.payloadMeta = JSON.parse(model.settings.payloadMeta);
      } catch {
        model.settings.payloadMeta = undefined;
      }
    } else if (!model.settings.payloadMeta) {
      model.settings.payloadMeta = undefined;
    }

    // Check if payloadTemplate surronded with `
    const backtickTester = /`.*`/gms;
    if (model.settings.payloadTemplate && !backtickTester.test(model.settings.payloadTemplate)) {
      model.settings.payloadTemplate = `\`${model.settings.payloadTemplate.trim()}\``;
    }

    command.setCommandSettings(model.settings);
  }

  return command;
};

export const sendMessageCommandV2Type: ICommandV2Type = {
  id: CommandV2Types.sendMessage,
  label: 'Send Message',
  templateItems: [
    { id: 'target', label: 'Target', required: true, type: CommandV2TypeTemplateItemTypes.string },
    {
      id: 'timeoutMs',
      label: 'Default Timeout in Milliseconds',
      required: true,
      type: CommandV2TypeTemplateItemTypes.number,
    },
    {
      id: 'requiresClockSync',
      label: 'Requires Clock Sync',
      required: true,
      type: CommandV2TypeTemplateItemTypes.boolean,
    },
    {
      id: 'payloadTemplate',
      label: 'Payload Template',
      required: false,
      type: CommandV2TypeTemplateItemTypes.jsTemplateLiterals,
    },
    {
      id: 'payloadMeta',
      label: 'Metadata',
      required: false,
      type: CommandV2TypeTemplateItemTypes.json,
    },
  ],
  defaultSettingItemsCollection: {
    target: '/ros/cmd_vel',
    timeoutMs: 30000,
    payloadTemplate: `{
      "linear": {
        "x": 0,
        "y": 0,
        "z": 0
      },
      "angular": {
        "x": 0,
        "y": 0,
        "z": 0
      }
}`,
    payloadMeta: {
      'ros.type': 'geometry_msgs/Twist',
    },
    requiresClockSync: true,
  },
  fromModel: (model, command: CommandV2): CommandV2 => commonCommandTypeFromModel(model, command),
};

export const callServiceCommandV2Type: ICommandV2Type = {
  id: CommandV2Types.callService,
  label: 'Call Service',
  templateItems: [
    { id: 'target', label: 'Target', required: true, type: CommandV2TypeTemplateItemTypes.string },
    {
      id: 'timeoutMs',
      label: 'Default Timeout in Milliseconds',
      required: true,
      type: CommandV2TypeTemplateItemTypes.number,
    },
    {
      id: 'requiresClockSync',
      label: 'Requires Clock Sync',
      required: true,
      type: CommandV2TypeTemplateItemTypes.boolean,
    },
    {
      id: 'payloadTemplate',
      label: 'Payload Template',
      required: false,
      type: CommandV2TypeTemplateItemTypes.jsTemplateLiterals,
    },
    {
      id: 'payloadMeta',
      label: 'Metadata',
      required: false,
      type: CommandV2TypeTemplateItemTypes.json,
    },
  ],
  defaultSettingItemsCollection: {
    target: '/ros/cmd_vel',
    timeoutMs: 30000,
    payloadTemplate: `{
      "linear": {
        "x": 0,
        "y": 0,
        "z": 0
      },
      "angular": {
        "x": 0,
        "y": 0,
        "z": 0
      }
}`,
    payloadMeta: {
      'ros.type': 'geometry_msgs/Twist',
    },
    requiresClockSync: true,
  },
  fromModel: (model, command: CommandV2): CommandV2 => commonCommandTypeFromModel(model, command),
};

export const AvailableCommandV2Types: IAvailableCommandV2TypesCollection = {
  sendMessage: sendMessageCommandV2Type,
  callService: callServiceCommandV2Type,
};

export class CommandV2 {
  public schemaVersion: number = 1;
  public id: string;
  public name: string;
  public type: CommandV2Types;
  public settings: ICommandV2TypeSettingItemsCollection;
  public parameters: {
    [paramId: string]: CommandV2PayloadParameter;
  };

  public constructor(id?: string, name?: string, type?: CommandV2Types) {
    this.parameters = {};
    this.id = id ? id : '';
    this.name = name ? name : '';

    this.setCommandType(type);
  }

  public static fromModel(model: {
    id: string;
    name?: string;
    type: CommandV2Types;
    parameters: { id: string; default: string }[] | { [p: string]: CommandV2PayloadParameter };
  }): CommandV2 {
    const foundCommandAvailableType = AvailableCommandV2Types[model.type];

    if (!foundCommandAvailableType) {
      return null;
    }

    let command = new CommandV2(model.id, model.name, model.type);

    if (model.parameters && Array.isArray(model.parameters)) {
      model.parameters.forEach((param) => {
        if (param.id) {
          command.parameters[param.id] = CommandV2PayloadParameter.fromModel(param);
        }
      });
    } else if (model.parameters) {
      command.parameters = model.parameters as { [p: string]: CommandV2PayloadParameter };
    }

    command = foundCommandAvailableType.fromModel(model, command);

    return command;
  }

  public setCommandSettings(commandTypeSettingItemsCollection: ICommandV2TypeSettingItemsCollection): void {
    this.settings = commandTypeSettingItemsCollection;
  }

  public setCommandType(type: CommandV2Types): void {
    this.type = type;

    // Use the default value if current command doesn't hold any data for the corresponding command type
    if (!this.settings && AvailableCommandV2Types[type]) {
      this.settings = AvailableCommandV2Types[type].defaultSettingItemsCollection;
    }
  }

  public getParamsObject() {
    return Object.entries(this.parameters).reduce((acc, curr) => {
      const [key, value] = curr;
      acc[key] = value.default;
      return acc;
    }, {});
  }
}
