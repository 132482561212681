export enum NotificationType {
  notification = 'NOTIFICATION',
  warning = 'WARNING',
  error = 'ERROR',
}

export interface INotification {
  id: string;
  page: string;
  message: string;
  title?: string;
  actionButtonLabel?: string;
  type: NotificationType;
  isRead: boolean;
}

export class Notification implements INotification {
  public id: string;
  public page: string;
  public message: string;
  public title?: string;
  public actionButtonLabel?: string = 'Got It';
  public type: NotificationType = NotificationType.notification;
  public isRead: boolean;

  public get isWarning(): boolean {
    return this.type === NotificationType.warning;
  }

  public get isError(): boolean {
    return this.type === NotificationType.error;
  }

  constructor(
    id?: string,
    page?: string,
    message?: string,
    isRead?: boolean,
    title?: string,
    actionButtonLabel?: string,
    type?: NotificationType,
  ) {
    this.id = id;
    this.page = page;
    this.message = message;
    this.isRead = isRead;
    this.title = title;
    this.actionButtonLabel = actionButtonLabel;
    this.type = type;
  }

  public static fromModel(model: Notification): Notification {
    const notification = new Notification();

    notification.id = model.id;
    notification.page = model.page;
    notification.message = model.message;
    notification.isRead = model.isRead;
    notification.title = model.title;
    notification.actionButtonLabel = model.actionButtonLabel;
    notification.type = model.type ? model.type : notification.type;

    return notification;
  }
}
