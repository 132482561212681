import type { OnInit, TrackByFunction } from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { first, map } from 'rxjs/operators';
import type { Account, Project } from '../../models';
import { AccountService, AuthService, ProjectService, UserService } from '../../services';
import { RouterUtils } from '../../utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-project-picker',
  templateUrl: './project-picker.component.html',
  styleUrls: ['./project-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPickerComponent implements OnInit {
  public currentProject: Project;
  public projects: Project[] = [];
  public accounts: Account[] = [];
  public accountButtonLabel: string;
  public currentAccountId: string;

  private projectId: string;
  private accountId: string;
  private viewingType: 'account' | 'project';

  public get buttonLabel(): string {
    const project = this.viewingType === 'project' ? this.getProjectById(this.projectId) : undefined;

    return project ? project.name : this.translocoService.translate('common.projects');
  }

  constructor(
    private accountService: AccountService,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private authService: AuthService,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouterParams();
      }
    });

    this.projectService.projectsUpdated.pipe(untilDestroyed(this)).subscribe(() => {
      this.onProjectsChanged();
    });
  }

  public trackByAccountId: TrackByFunction<Account> = (_, item) => item.id;
  public trackByProjectId: TrackByFunction<Project> = (_, item) => item.id;

  public ngOnInit() {
    this.loadData().subscribe(() => {
      this.onAccountIdChanged();
      this.onProjectIdChanged();
    });

    this.checkRouterParams();
  }

  public onClickAccount(account: Account) {
    this.activateAccountAndRefreshToken(account);
  }

  public onMenuOpened() {
    this.loadData().subscribe();
  }

  private loadData() {
    return this.accountService.list().pipe(
      first(),
      map((accounts) => {
        this.accounts = accounts;
        this.cdr.markForCheck();
      }),
    );
  }

  private onAccountIdChanged() {
    this.viewingType = 'account';
    const targetAccount = this.filterAccountById(this.accountId, null);

    if (targetAccount?.name) {
      this.accountButtonLabel = targetAccount.name;
      this.currentAccountId = targetAccount.id;
    }

    this.cdr.markForCheck();
  }

  private onProjectsChanged() {
    this.loadData().subscribe();
  }

  private onProjectIdChanged() {
    this.viewingType = 'project';
    const targetAccount = this.filterAccountById(null, this.projectId);

    if (targetAccount?.name) {
      this.accountButtonLabel = targetAccount.name;
      this.currentAccountId = targetAccount.id;
    }

    this.cdr.markForCheck();
  }

  private getProjectById(projectId: string): Project {
    let targetProject = null;
    if (this.accounts?.length > 0) {
      this.accounts.forEach((account) => {
        if (account?.projects && account.projects.length > 0) {
          account.projects.forEach((project) => {
            if (project.id === projectId) {
              targetProject = project;
            }
          });
        }
      });
    }

    return targetProject;
  }

  private filterAccountById(accountId: string, projectId: string): Account {
    let targetAccount = null;
    if (this.accounts?.length > 0) {
      this.accounts.forEach((account) => {
        // By account id
        if (account?.id && account.id === accountId) {
          targetAccount = account;
        }

        // By project id
        if (account?.projects && account.projects.length > 0) {
          account.projects.forEach((project) => {
            if (project.id === projectId) {
              targetAccount = account;
            }
          });
        }
      });
    }

    return targetAccount;
  }

  private activateAccountAndRefreshToken(account: Account) {
    this.userService.activateAccount(account.id).subscribe(() => {
      this.userService.getNewToken().subscribe((token) => {
        this.authService.updateNewToken(token);
      });
    });
  }

  private checkRouterParams() {
    const params = RouterUtils.collectParams(this.router.routerState.snapshot.root);

    if (params['projectId']) {
      this.projectId = params['projectId'];

      this.onProjectIdChanged();
    }
    if (params['accountId']) {
      this.accountId = params['accountId'];

      this.onAccountIdChanged();
    }
  }
}
