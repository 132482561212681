<ng-container *ngIf="notification">
  <div
    class="rs-notice"
    [class.sticky-left]="stickyLeft"
    [class.top-left-right-margin]="topLeftRightMargin"
    [class.bottom-margin]="bottomMargin"
    [class.closing]="isNotificationClosing"
    [class.warning-box]="notification.isWarning"
    [class.error-box]="notification.isError"
  >
    <div class="d-flex align-items-start">
      <div class="warning-icon warning-box d-flex" *ngIf="notification.isWarning">
        <span class="background-dot">
          <span class="ri-exclamation-mark"></span>
        </span>
      </div>
      <div class="error-icon warning-box d-flex" *ngIf="notification.isError">
        <span class="background-dot">
          <span class="ri-exclamation-mark"></span>
        </span>
      </div>

      <!-- Classic Display -> title -->
      <div class="d-flex flex-column">
        <div class="d-flex title">
          {{ notification.title }}
        </div>
        <div class="desc">
          <span [innerHTML]="notification.message"></span>
        </div>
        <div class="actions" *ngIf="isActionButtonVisible && !inlineActionButton">
          <a mat-flat-button color="primary" (click)="onNotificationMessageGot()">
            {{ notification.actionButtonLabel }}
          </a>
        </div>
      </div>

      <div class="actions flex-grow-1 justify-content-end" *ngIf="isActionButtonVisible && inlineActionButton">
        <a mat-stroked-button color="primary" (click)="onNotificationMessageGot()">
          {{ notification.actionButtonLabel }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
