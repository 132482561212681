import type { OnInit } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource,
} from '@angular/material/legacy-table';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-json-data-table',
  templateUrl: './json-data-table.component.html',
  styleUrls: ['./json-data-table.component.scss'],
})
export class JsonDataTableComponent implements OnInit {
  @ViewChild('logTableContainer')
  public logTableContainer: ElementRef;

  @ViewChild('logTable')
  public logTable: MatTable<any>;

  @Input() public isApendingPaused = false;
  @Input() public isApendingMode = false;
  @Input() public theme = '';
  @Input() public maxHeight = '400px';
  @Input() public boldFirstColumn = false;
  @Input() public contentObservable: Observable<any>;

  public inputDisplayedColumns: any;
  public inputDisplayedColumnsNames: any;
  public preparedDisplayedColumns: any[] = [];
  @Input()
  public set displayedColumns(columns) {
    this.inputDisplayedColumns = columns;
    this.prepareDisplayedColumns();
  }
  @Input()
  public set displayedColumnsNames(columnNames) {
    this.inputDisplayedColumnsNames = columnNames;
    this.prepareDisplayedColumns();
  }

  @Input() public set hideHeader(input) {
    this._hideHeader = input;
    // parent ask to reload, clean everything
    this.clearData();
  }
  public _hideHeader = false;

  public dataSource: any;

  public tempTableDatasource = [];
  public scrollDirectionCache: any;

  public lastScrollTop: number;
  public notDetectScrollPeroid = false;

  public constructor(private formBuilder: UntypedFormBuilder) {
    this.dataSource = new MatTableDataSource();
  }

  public clearData(): void {
    this.dataSource = new MatTableDataSource();
    this.inputDisplayedColumns = [];
    this.inputDisplayedColumnsNames = [];
    this.prepareDisplayedColumns();
  }

  public prepareDisplayedColumns(): void {
    if (!this.inputDisplayedColumnsNames) {
      return;
    }

    const keys = Object.keys(this.inputDisplayedColumnsNames);

    // when there is not displayed columns passed in, we display everything.
    if (!this.inputDisplayedColumns || this.inputDisplayedColumns.length === 0) {
      this.preparedDisplayedColumns = keys.filter((x) => x !== 'time');
      return;
    }

    // we passed in data mismtached, we fix it.
    this.preparedDisplayedColumns = [];
    for (const column of this.inputDisplayedColumns) {
      if (keys.includes(column)) {
        this.preparedDisplayedColumns.push(column);
      }
    }
  }

  public ngOnInit(): void {
    if (this.contentObservable) {
      this.contentObservable.subscribe((content) => {
        this.prepareDataForTable(content);
      });
    }
  }

  public getLevelClass(element: Record<string, string>, column: string): string {
    let theClass = 'logCell ';
    if (column === 'level' && element[column]) {
      const firstFourLetter = element[column].substr(0, 4).toLowerCase();
      switch (firstFourLetter) {
        case 'fata':
          theClass += 'fatal';
          break;
        case 'crit':
          theClass += 'critical';
          break;
        case 'erro':
          theClass += 'error';
          break;
        case 'warn':
          theClass += 'warning';
          break;
        case 'info':
          theClass += 'info';
          break;
        case 'debu':
          theClass += 'debug';
          break;
        case 'trac':
          theClass += 'trace';
          break;
        case '':
          theClass += 'fatal';
          break;
      }
    }
    return theClass;
  }

  public scrollToBottom(nativeElement) {
    // set a delay for the table to render the rows
    setTimeout(() => {
      nativeElement.scrollTop = nativeElement.scrollHeight;
    }, 100);
  }

  public hasReachScrollEnd(nativeElement) {
    return nativeElement && nativeElement.scrollTop + nativeElement.offsetHeight === nativeElement.scrollHeight;
  }

  public onJumpButtonClicked() {
    this.scrollDirectionCache = [];
    this.logTable.renderRows();
    setTimeout(() => {
      this.isApendingPaused = false;
    }, 500);
    this.scrollToBottom(this.logTableContainer.nativeElement);
  }

  public onScroll(event) {
    if (!this.scrollDirectionCache) {
      this.scrollDirectionCache = [];
    }

    if (
      this.isApendingMode &&
      !this.isApendingPaused &&
      !this.hasReachScrollEnd(this.logTableContainer.nativeElement)
    ) {
      if (this.lastScrollTop && event.target.scrollTop) {
        this.scrollDirectionCache.push(this.lastScrollTop - event.target.scrollTop);
        if (this.scrollDirectionCache.length > 10) {
          const offSet = this.scrollDirectionCache.length - 10;
          this.scrollDirectionCache = this.scrollDirectionCache.slice(offSet);
        }
      }
      if (!this.notDetectScrollPeroid) {
        let count = 0;
        let checkedItemsCount = 0;
        // A relatively reliable way to detect scroll up event
        for (let i = this.scrollDirectionCache.length - 1; i >= 0; i--) {
          checkedItemsCount++;
          if (this.scrollDirectionCache[i] > 0) {
            count++;
          }
          if (checkedItemsCount > 2) {
            break;
          }
        }

        if (this.scrollDirectionCache.length === 10 && count > 1 && !this.hasReachScrollEnd(event.target)) {
          this.isApendingPaused = true;
          this.notDetectScrollPeroid = true;
          // In 500 ms, we will not detect scroll event again
          setTimeout(() => {
            this.notDetectScrollPeroid = false;
          }, 500);
        }
      }
    }
    this.lastScrollTop = event.target.scrollTop;
  }

  private prepareDataForTable(arrayValue: unknown[]): void {
    if (!arrayValue?.length) return;

    let firstItem = arrayValue[0];

    // this is  primitive type, convert it to object
    if (firstItem !== Object(firstItem)) {
      arrayValue = arrayValue.map((x) => {
        return { 'value': x };
      });
      firstItem = arrayValue[0];
    }

    const keys = Object.keys(firstItem);

    const rows = arrayValue.map((v) => {
      const o = {};
      keys.forEach((k) => {
        const value = v[k];
        // timestamp can have different key names in different data, use time everywhere
        if (k === 'tim' || k === 'time') {
          o['time'] = typeof value === 'number' ? new Date(value / 1000000).toUTCString() : value;
        } else if (typeof v[k] === 'string') {
          o[k] = JSON.stringify(value, null, 2)
            .replace(/^"+|"+$/g, '')
            .replaceAll('\\n', '\n');
        } else {
          o[k] = JSON.stringify(value, null, 2).replace(/^{|}$/gs, '').replace(/^ {2}/gm, '').trim();
        }
      });
      return o;
    });
    const maxRows = 1000;
    if (this.isApendingMode) {
      if (!Array.isArray(this.dataSource)) {
        this.dataSource = rows;
      } else {
        if (!this.isApendingPaused) {
          // concat the saved messages
          this.dataSource.push(...this.tempTableDatasource);
          // clear it.
          this.tempTableDatasource = [];
          this.dataSource.push(...rows);
        } else {
          // save the message to a temp queue when apending paused
          this.tempTableDatasource.push(...rows);
          if (this.tempTableDatasource.length > maxRows) {
            const offset = this.tempTableDatasource.length - maxRows;
            this.tempTableDatasource = this.tempTableDatasource.slice(offset);
          }
        }
      }
    } else {
      this.dataSource = rows;
    }

    // take only the last 4000
    if (this.dataSource.length > maxRows) {
      const offset = this.dataSource.length - maxRows;
      this.dataSource = this.dataSource.slice(offset);
    }

    if (this.logTable) {
      if (this.isApendingMode && !this.isApendingPaused) {
        this.scrollToBottom(this.logTableContainer.nativeElement);
        this.logTable.renderRows();
      }
      if (!this.isApendingMode) {
        this.logTable.renderRows();
      }
    }
  }
}
