<div class="tree">
  <ng-container *ngIf="showLoading">
    <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
  </ng-container>

  <app-data-sources-tree-item
    #treeItem
    (selectionChange)="onSelectionChange()"
    (dataItemChange)="onDataItemChange($event)"
    (dataURIChange)="onDataURIChange($event)"
    [insertDataURIMode]="insertDataURIMode"
    [item]="treeData"
  >
  </app-data-sources-tree-item>
</div>
