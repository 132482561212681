// src/lib/components/automate/utils/agentGraph/combineGraphs.ts
var combineGraphs = (graphs, nodes) => {
  return {
    graphs: graphs.reduce((acc, graph) => {
      return {
        ...acc,
        ...graph.graphs
      };
    }, {}),
    nodes: {
      ...graphs.reduce((acc, graph) => {
        return {
          ...acc,
          ...graph.nodes
        };
      }, {}),
      ...nodes
    }
  };
};

// src/lib/components/automate/types/AgentGraphTypes.ts
var isAgentGraphSocket = (socket) => {
  return "node" in socket && "port" in socket;
};
var isAgentGraphSocketFi = (socket) => {
  return "fi" in socket;
};
var isAgentGraphSocketFo = (socket) => {
  return "fo" in socket;
};

// src/lib/components/automate/types/NodeDefinitionTypes.ts
var isGraphNodeDefinition = (node) => {
  return "graph" in node;
};
var isNodeDefinition = (node) => {
  return "name" in node;
};

// src/lib/components/automate/utils/nodeDefinition/checkDefinitionHint.ts
var checkDefinitionHint = (definition, hint, value) => {
  if (!isNodeDefinition(definition))
    return void 0;
  if (!definition.bi)
    return void 0;
  const key = Object.keys(definition.bi).find((key2) => {
    const input = definition.bi[key2];
    return value ? input.hints?.[hint] === value : input.hints?.[hint];
  });
  return key ? { socket: key, hints: definition.bi[key].hints } : void 0;
};

// src/lib/components/automate/utils/nodeDefinition/getNodeByName.ts
var getNodeByName = (definitions, nodeName) => {
  return definitions.nodes[nodeName];
};

// src/lib/components/automate/utils/fc/getForwardConnectionsWithHint.ts
var getConnectionFromSocket = (socket, graphId, masterGraph, hint, value) => {
  const agentGraph = masterGraph.graphs[graphId];
  let nodeName;
  let defaults;
  if (isAgentGraphSocketFi(socket)) {
    nodeName = socket.fi;
  } else if (isAgentGraphSocketFo(socket)) {
    nodeName = socket.fo;
  } else {
    nodeName = agentGraph.n[socket.node].name;
    defaults = agentGraph.n[socket.node].defaults;
  }
  const nodeDefinition = getNodeByName(masterGraph, nodeName);
  const isVirtual = nodeDefinition && isGraphNodeDefinition(nodeDefinition);
  const port = isVirtual && isAgentGraphSocket(socket) ? socket.port : void 0;
  const node = port || (isAgentGraphSocketFi(socket) ? socket.fi : isAgentGraphSocketFo(socket) ? socket.fo : socket.node);
  const hints = nodeDefinition ? checkDefinitionHint(nodeDefinition, hint, value) : void 0;
  return {
    graphId: isVirtual ? nodeName : graphId,
    node,
    label: defaults?.["label"]?.toString(),
    nodeName: port || nodeName,
    socket: hints?.socket,
    hint: !!hints,
    hints: hints?.hints
  };
};
var getForwardConnectionsWithHint = (graphId, masterGraph, hint, value) => {
  const agentGraph = masterGraph.graphs[graphId];
  return agentGraph.fc.reduce((acc, connection) => {
    return [
      ...acc,
      {
        output: getConnectionFromSocket(connection.o[0], graphId, masterGraph, hint, value),
        input: getConnectionFromSocket(connection.i, graphId, masterGraph, hint, value),
        blocking: connection.b
      }
    ];
  }, []);
};

// src/lib/components/automate/utils/fc/getAllForwardConnectionsWithHint.ts
var getAllForwardConnectionsWithHint = (masterGraph, hint, value) => {
  return Object.keys(masterGraph.graphs).reduce((acc, graphId) => {
    const connections = getForwardConnectionsWithHint(graphId, masterGraph, hint, value);
    return [...acc, ...connections];
  }, []);
};

// src/lib/components/automate/utils/fc/getGeoJsonFromConnections.ts
var isLineString = (geometry) => {
  return geometry.type === "LineString";
};
var isPoint = (geometry) => {
  return geometry.type === "Point";
};
var hasPointFeature = (geoJson, feature) => {
  return !!geoJson.find((f) => {
    return isPoint(f.geometry) && f.geometry.coordinates.toString() === feature.geometry.coordinates.toString();
  });
};
var getCrs = (connectionNode) => {
  return {
    crs: {
      properties: {
        frame: connectionNode.hints?.["local_ops_frame"] || "seed"
      },
      type: "rocos"
    }
  };
};
var getFeaturePoint = (connectionNode) => {
  if (!connectionNode.position)
    return;
  const { x: x1, y: y1, z: z1 } = connectionNode.position;
  const coordinates = [x1, y1, z1];
  return {
    type: "Feature",
    id: connectionNode.node,
    geometry: {
      type: "Point",
      coordinates
    },
    properties: {
      node: connectionNode.node,
      socket: connectionNode.socket,
      label: connectionNode.label
    },
    ...getCrs(connectionNode)
  };
};
var getFeatureLineString = (connection) => {
  const { input, output } = connection;
  if (!input.position || !output.position)
    return void 0;
  const { x: x1, y: y1, z: z1 } = output.position;
  const { x: x2, y: y2, z: z2 } = input.position;
  const start = [x1, y1, z1];
  const end = [x2, y2, z2];
  return {
    type: "Feature",
    id: `${input.node}-${output.node}`,
    geometry: {
      type: "LineString",
      coordinates: [start, end]
    },
    properties: {
      type: "ARROW",
      input_socket: input.socket,
      input_node: input.node,
      output_socket: output.socket,
      output_node: output.node
    },
    ...getCrs(input)
  };
};
var getGeoJsonFromConnections = (connections) => {
  return {
    type: "FeatureCollection",
    features: connections.reduce((acc, connection) => {
      const { input, output } = connection;
      if (!input.position || !output.position)
        return acc;
      const feat = [getFeatureLineString(connection)];
      const inputPoint = getFeaturePoint(input);
      if (inputPoint && !hasPointFeature(acc, inputPoint))
        feat.push(inputPoint);
      const outputPoint = getFeaturePoint(output);
      if (outputPoint && !hasPointFeature(acc, outputPoint))
        feat.push(outputPoint);
      return [...acc, ...feat];
    }, [])
  };
};

// src/lib/components/automate/utils/fc/getConnectionCoordinates.ts
var getConnectionCoordinates = (connections, masterGraph) => {
  return connections.map((connection) => {
    const outputNode = masterGraph.graphs[connection.output.graphId].n[connection.output.node];
    const outputPose = outputNode.defaults?.["pose"];
    const inputNode = masterGraph.graphs[connection.input.graphId].n[connection.input.node];
    const inputPose = inputNode.defaults?.["pose"];
    return {
      output: {
        ...connection.output,
        hint: void 0,
        nodeName: void 0,
        position: outputPose.pos
      },
      input: {
        ...connection.input,
        hint: void 0,
        nodeName: void 0,
        position: inputPose.pos
      }
    };
  });
};

// src/lib/components/automate/utils/fc/filterConnectionsWithHint.ts
var filterConnectionsWithHint = (simpleConnections) => {
  return simpleConnections.reduce((acc, connection) => {
    if (!connection.output.hint && !connection.input.hint)
      return acc;
    if (connection.output.hint && connection.input.hint)
      return [...acc, connection];
    if (!connection.output.hint) {
      const next = getNextConnection(connection, simpleConnections);
      if (!next)
        return acc;
      return [
        ...acc,
        {
          output: next.output,
          input: connection.input,
          blocking: connection.blocking
        }
      ];
    }
    return acc;
  }, []);
};
var getNextConnection = (connection, connections) => {
  if (connection.output.hint)
    return connection;
  const next = connections.find((conn) => {
    return conn.input.node === connection.output.node && conn.input.graphId === connection.output.graphId;
  });
  if (!next)
    return;
  return getNextConnection(next, connections);
};

// src/lib/components/automate/utils/fc/agentGraphsToGeoJson.ts
var agentGraphsToGeoJson = (hint, value, agentGraphs, nodes) => {
  const masterGraphs = combineGraphs(agentGraphs, nodes);
  const simpleFc = getAllForwardConnectionsWithHint(masterGraphs, hint, value);
  const pruned = filterConnectionsWithHint(simpleFc);
  const coordinates = getConnectionCoordinates(pruned, masterGraphs);
  return getGeoJsonFromConnections(coordinates);
};
export {
  agentGraphsToGeoJson,
  combineGraphs,
  isLineString,
  isPoint
};
