<ng-container *transloco="let tCommon; read: 'common'">
  <div class="user-buttons">
    <div class="user-button initials-container">
      <button mat-icon-button [matTooltip]="user.email">
        <div class="user-initials-icon">
          <app-user-initials [user]="user"></app-user-initials>
        </div>
      </button>
    </div>
  </div>
</ng-container>
