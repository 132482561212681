import type { Scene } from '@babylonjs/core';
import { Color3, Color4, StandardMaterial } from '@babylonjs/core';

export const POLYGON_MATERIAL_ID = 'PointPickerService-polygonMat';
export const POLYGON_COLOR = new Color3(0, 0.74, 0.84);
export const getPolygonMaterial = ({ scene }: { scene: Scene }): StandardMaterial => {
  let polyMat = scene.getMaterialByName(POLYGON_MATERIAL_ID) as StandardMaterial;
  if (polyMat) return polyMat;

  polyMat = new StandardMaterial(POLYGON_MATERIAL_ID, scene);
  polyMat.diffuseColor = Color3.Blue();
  polyMat.ambientColor = Color3.Blue();
  polyMat.alpha = 0.25;
  polyMat.emissiveColor = POLYGON_COLOR;
  polyMat.backFaceCulling = false;
  return polyMat;
};

export const FRUSTUM_MATERIAL_ID = 'frustrumMat';
export const FRUSTUM_COLOR = new Color3(0, 0.74, 0.0);
export const POLYGON_EDGES_COLOR = new Color4(0, 0.74, 0.84, 1);
export const getFrustumMaterial = ({ scene }: { scene: Scene }): StandardMaterial => {
  let polyMat = scene.getMaterialByName(FRUSTUM_MATERIAL_ID) as StandardMaterial;
  if (polyMat) return polyMat;

  polyMat = new StandardMaterial(FRUSTUM_MATERIAL_ID, scene);
  polyMat.diffuseColor = Color3.Blue();
  polyMat.ambientColor = Color3.Blue();
  polyMat.alpha = 0.01;
  polyMat.emissiveColor = FRUSTUM_COLOR;
  polyMat.backFaceCulling = false;
  return polyMat;
};
