import type { IBoundSourceBinding } from './interface/IBinding';

export class VizBoundSource {
  /** The robot Callsign this source is mapped to e.g. robo1 */
  robotCallSign: string;
  /** dataUri on the robot e.g. /rosbridge/pose/linear/x */
  dataUri: string;
  /** the full source e.g. /robo1/rosbridge/pose/linear/x */
  boundSourceKey: string;
  bindings: Record<string, IBoundSourceBinding>;

  /**
   *
   * @param boundSourceKey must include robot callsign + dataUri e.g. /robo1/rosbridge/pose/linear/x
   */
  constructor(boundSourceKey) {
    if (boundSourceKey.indexOf('/') !== 0) {
      boundSourceKey = '/' + boundSourceKey;
    }

    this.boundSourceKey = boundSourceKey;
    this.robotCallSign = boundSourceKey.substr(1, boundSourceKey.indexOf('/', 2) - 1);
    this.dataUri = boundSourceKey.replace('/' + this.robotCallSign, '');
  }
}
