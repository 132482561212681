import { GamepadButtonConfig, GamepadButtonAction } from './gamepad-button';
import { Gamepad } from './gamepad';

export class GamepadConfig {
  gamepad: Gamepad;
  actions: GamepadButtonAction[] = [];
  variables: GamepadButtonConfig[] = [];
  _globalItem = [];
  robotDefinitionId: string;

  static fromJSON(json: any): GamepadConfig {
    const config = new GamepadConfig();

    config.gamepad = json.gamepad && Gamepad.fromJSON(json.gamepad);
    config.actions = json.actions && GamepadButtonAction.fromJSONToList(json.actions);
    config.variables = json.variables && GamepadButtonConfig.fromJSONToList(json.variables);

    return config;
  }

  toJSON(): any {
    return {
      gamepad: this.gamepad?.toJSON(),
      actions: this.actions?.map((x) => x.toJSON()),
      variables: this.variables?.map((x) => x.toJSON()),
    };
  }
}
