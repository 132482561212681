import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { CanComponentDeactivate } from '../../guards';
import { DialogService } from '../../dialogs';
import type { UnpublishedChangesDialogData } from '@shared/dialogs/unpublished-changes-dialog';
import { UnpublishedChangesResult } from '@shared/dialogs/unpublished-changes-dialog';

export enum StopDialogType {
  DEFAULT = 'default',
  SHELL = 'shell',
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-can-deactivate-base',
  templateUrl: './can-deactivate-base.component.html',
  styleUrls: ['./can-deactivate-base.component.scss'],
})
export class CanDeactivateBaseComponent implements CanComponentDeactivate {
  isPublishable: boolean = false;
  isSavable: boolean = false;
  shouldStopQuit: boolean = false;

  // subclass should override this value to display different message
  dialogType: StopDialogType = StopDialogType.DEFAULT;

  dialogs: Record<string, UnpublishedChangesDialogData> = {
    default: {
      canPublish: false,
    },
    shell: {
      title: 'Are your sure you want to leave?',
      message: 'It looks like you are still connected. Leaving will end current shell session.',
      canPublish: false,
      buttons: {
        discard: 'Disconnect & Leave',
      },
    },
  };

  constructor(protected dialogService: DialogService) {}

  async canDeactivate(): Promise<boolean> {
    if (this.dialogType === StopDialogType.SHELL) {
      if (!this.shouldStopQuit) return true;
      return await this.confirmUnpublishedChanges(this.dialogs[StopDialogType.SHELL]);
    }

    if (!this.isPublishable && !this.isSavable && !this.shouldStopQuit) return true;
    return await this.confirmUnpublishedChanges(this.dialogs[StopDialogType.DEFAULT]);
  }

  private async confirmUnpublishedChanges(data: UnpublishedChangesDialogData): Promise<boolean> {
    const decision = await this.dialogService.confirmUnpublishedChanges(data);
    return decision === UnpublishedChangesResult.discard;
  }
}
