import type { GridsterConfig } from 'angular-gridster2';
import { CompactType, DisplayGrid, GridType } from 'angular-gridster2';

export const defaultGridsterOptions: GridsterConfig = {
  allowMultiLayer: true,
  gridType: GridType.Fixed,
  compactType: CompactType.None,
  margin: 0,
  outerMargin: true,
  outerMarginTop: 0,
  outerMarginRight: 0,
  outerMarginBottom: 0,
  outerMarginLeft: 0,
  useTransformPositioning: true,
  mobileBreakpoint: 640,
  setGridSize: false,
  minCols: 1,
  maxCols: 500,
  minRows: 1,
  maxRows: 500,
  maxItemCols: 1000,
  minItemCols: 10,
  maxItemRows: 1200,
  minItemRows: 12,
  maxItemArea: 25000,
  minItemArea: 1,
  defaultItemCols: 10,
  defaultItemRows: 12,
  fixedColWidth: 16,
  fixedRowHeight: 16,
  keepFixedHeightInMobile: false,
  keepFixedWidthInMobile: false,
  scrollSensitivity: 10,
  scrollSpeed: 20,
  enableEmptyCellClick: false,
  enableEmptyCellContextMenu: false,
  enableEmptyCellDrop: false,
  enableEmptyCellDrag: false,
  enableOccupiedCellDrop: false,
  emptyCellDragMaxCols: 50,
  emptyCellDragMaxRows: 50,
  ignoreMarginInRow: false,
  draggable: {
    enabled: false,
  },
  resizable: {
    enabled: false,
  },
  swap: false,
  pushItems: true,
  disablePushOnDrag: false,
  disablePushOnResize: false,
  pushDirections: {
    north: true,
    east: true,
    south: true,
    west: true,
  },
  pushResizeItems: false,
  displayGrid: DisplayGrid.None,
  disableWindowResize: false,
  disableWarnings: false,
  scrollToNewItems: true,
  defaultLayerIndex: 0,
  baseLayerIndex: 500,
  maxLayerIndex: 499,
};
