import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GamepadUxComponent } from '../../models';
import { SpinnerColorTheme } from '../loading-spinner';

@Component({
  selector: 'app-command-with-value-button',
  templateUrl: './command-with-value-button.component.html',
  styleUrls: ['./command-with-value-button.component.scss'],
})
export class CommandWithValueButtonComponent implements OnInit {
  @Output() clicked = new EventEmitter<any>();
  @Input() defaultValue: string;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() configGroupItem: GamepadUxComponent;
  @Input() layout: string = 'horizontal';
  @Input() projectId: string;
  @Input() spinnerColorTheme: SpinnerColorTheme = 'light';
  commandWithValueButtonForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}
  onClickButton(event) {
    this.clicked.emit(event);
  }

  get showInputs() {
    if (this.configGroupItem) {
      return this.configGroupItem.showInputField;
    }
    return false;
  }

  onValueChanged(_event, _index) {
    this.formArrayParas.controls.forEach((element: AbstractControl) => {
      const id = element.value.label;
      const value = element.value.value;
      this.setConfigGroupItemValue(id, value);
    });
  }

  setConfigGroupItemValue(id: any, value: any) {
    if (this.configGroupItem?.commandParams) {
      for (const param of this.configGroupItem.commandParams) {
        if (param.id === id) {
          param.value = value;
          break;
        }
      }
    }
  }

  get formArrayParas() {
    return this.commandWithValueButtonForm.get('parameters') as UntypedFormArray;
  }

  ngOnInit() {
    this.commandWithValueButtonForm = this.formBuilder.group({
      commandWithValueButtonInput: [this.defaultValue || '20', Validators.required],
      commandWithValueButtonButton: '10',
      parameters: this.formBuilder.array([]),
    });

    if (this.configGroupItem) {
      const paras = this.configGroupItem.commandParams;
      if (paras) {
        let parasArray = paras.map((p) => {
          if (p.id.indexOf('_showInputBox') < 0) {
            return this.createParaGroup(p, paras);
          } else {
            return null;
          }
        });

        // remove showInputBox checkbox from the controls
        parasArray = parasArray.filter((p: any) => {
          return p != null;
        });

        this.commandWithValueButtonForm.setControl('parameters', this.formBuilder.array(parasArray));
      }
    }
  }

  createParaGroup(para: any, paras: any): UntypedFormGroup {
    const showInputBoxName = para.id + '_showInputBox';
    let showInputBox = false;

    for (const p of paras) {
      if (p.id === showInputBoxName && p.value === true) {
        showInputBox = true;
        break;
      }
    }

    return this.formBuilder.group({
      label: para.id,
      value: para.value,
      showInputBox,
    });
  }
}
