import type { GamepadExecuteAction, GamepadPayloadContext } from '../../models';
import { GamepadControlActionType } from '../../models';
import type { EvalResponse } from '../../utils';
import { ExpressionEval } from '../../utils';

export class GamepadPayloadHelper {
  static getExecutePayloadsByActions(executeActions: GamepadExecuteAction[], context?: GamepadPayloadContext) {
    const payloads = [];

    const replaceContextValues = (rawPayload: string, payloadContext: GamepadPayloadContext): string => {
      let payload = rawPayload;

      const evalResponse: EvalResponse = ExpressionEval.evalObjectString(rawPayload, '$object', payloadContext);

      if (evalResponse.evalSuccess) {
        payload = JSON.stringify(evalResponse.evaluatedResponseObject);
      }
      return payload;
    };

    const replaceParameterKeys = (rawPayload: string, parameterKeys: string[], parametersInfo: any) => {
      let payload = rawPayload;
      parameterKeys.forEach((key) => {
        const re = new RegExp(`\\$${key}`, 'g');
        payload = payload.replace(re, parametersInfo[key].value);
      });

      return payload;
    };

    if (executeActions?.length > 0) {
      executeActions.forEach((executeAction) => {
        const actions = executeAction.actions;
        const parametersInfo = executeAction.parametersObj;

        if (actions?.length > 0) {
          const parameterKeys = Object.keys(parametersInfo);

          let payload;
          let slackPayload;
          let functionPayload;
          actions.forEach((action) => {
            switch (action.type) {
              case '/robot/agent/plugin/ros-control':
              case '/robot/agent/plugin/ros':
              case '/robot/agent/plugin/rosbridge-control':
              case '/robot/agent/plugin/rosbridge':
              case '/robot/agent/plugin/mavlink-control':
              case '/robot/agent/plugin/mavlink':
              case '/robot/agent/plugin/http-control':
              case '/robot/agent/plugin/http':
              case '/robot/agent/plugin/tcp-control':
              case '/robot/agent/plugin/tcp':
                payload = action.metadata.payload;
                payload = replaceParameterKeys(payload, parameterKeys, parametersInfo);
                payload = replaceContextValues(payload, context);

                payloads.push({
                  type: GamepadControlActionType.fromURI(action.type),
                  payload,
                  destination: action.metadata.destination,
                  meta: action.metadata.meta,
                });
                break;
              case '/server/slack/message':
                slackPayload = action.metadata.message;
                slackPayload = replaceParameterKeys(slackPayload, parameterKeys, parametersInfo);
                slackPayload = replaceContextValues(slackPayload, context);

                payloads.push({
                  type: GamepadControlActionType.fromURI(action.type),
                  webhook: action.metadata.webhook,
                  payload: slackPayload,
                });
                break;
              case '/server/function':
                functionPayload = action.metadata.payload;
                functionPayload = replaceParameterKeys(functionPayload, parameterKeys, parametersInfo);
                functionPayload = replaceContextValues(functionPayload, context);

                try {
                  functionPayload = JSON.parse(functionPayload);
                } catch {
                  // Do nothing
                }

                payloads.push({
                  type: GamepadControlActionType.fromURI(action.type),
                  functionId: action.metadata.functionId,
                  payload: functionPayload,
                });
                break;
              case '/client/delay':
                payloads.push({
                  type: GamepadControlActionType.fromURI(action.type),
                  waitForMilliseconds: action.metadata.waitForMilliseconds,
                });
                break;
              default:
                console.error(`Action type [${action.type}] hasn't implemented yet.`);
                break;
            }
          });
        }
      });
    }

    return payloads;
  }

  static getObjectFromList(list: any[], primaryKey = 'id') {
    // Get Object
    const obj = {};
    if (list?.length > 0) {
      list.forEach((item) => {
        obj[item[primaryKey]] = item;
      });
    }

    return obj;
  }
}
