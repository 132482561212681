<div class="bar">
  <!-- Negative values -->
  <ng-container *ngFor="let item of items">
    <span
      class="item"
      [class.active]="value < 0 && value * 10 <= -(10.5 - item)"
      [ngClass]="'item-' + (11 - item)"
    ></span>
  </ng-container>
  <!-- Positive values -->
  <ng-container *ngFor="let item of items">
    <span class="item" [class.active]="value > 0 && value * 10 >= item - 0.5" [ngClass]="'item-' + item"></span>
  </ng-container>
</div>
