import type { IPickerPositionNDimension } from './../../directives/coordinate-picker.directive';
import { CoordinatePickerDirective } from './../../directives/coordinate-picker.directive';
import { WidgetImage } from '../../models';
import type { WidgetStatus } from '../../models/widget/widget-status';
import { Utils } from '../../utils/utils';
import { map, tap } from 'rxjs/operators';
import type { SubscribeResult } from '../../services/rocos-client/rocos-client.service';
import { RocosClientService } from '../../services/rocos-client/rocos-client.service';
import type { OnInit, OnDestroy } from '@angular/core';
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { WidgetBaseComponent, WidgetToolbarItem } from '../shared';
import { ValueEvalPipe } from '../../pipes';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { FullscreenService } from '../../services/fullscreen/fullscreen.service';

export type WidgetImageType = 'jpeg' | 'png';

enum EConnectionStatus {
  connected = 'Connected',
  disconnected = 'Disconnected',
  connecting = 'Connecting',
}
@Component({
  selector: 'app-widget-image',
  templateUrl: './widget-image.component.html',
  styleUrls: ['./widget-image.component.scss'],
})
export class WidgetImageComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  @Input() public value: unknown;
  @Input() public base64: string;
  @Input() public imageType: WidgetImageType = 'jpeg';
  @Input() public demoMode: boolean = false;
  @Input() public override widget: WidgetImage;
  @Input() public callsign: string;
  @Input() public projectId: string;

  @ViewChild('img') public img: ElementRef;
  @ViewChild('imgContainer') public imgContainer: ElementRef;
  @ViewChild(WidgetBaseComponent) public baseWidget: WidgetBaseComponent;

  // Connection status
  public connectionStatus = EConnectionStatus;
  public currentConnectionStatus = EConnectionStatus.disconnected;

  public imageSource: string;
  public pickerPosition: IPickerPositionNDimension;

  // Structural condition
  // Interval - Dead / Alive status
  private lastValueUpdatedAt: Date;
  private widgetStatusTimer: NodeJS.Timeout;

  private fullScreenEnabled: boolean;

  private subscription: Subscription;
  private dataSource: SubscribeResult;

  private sub = new Subscription();
  private evalPipe = new ValueEvalPipe();

  public constructor(
    private rocosClient: RocosClientService,
    private rocosClientService: RocosClientService,
    private fsService: FullscreenService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updateToolbarItems();
    // Update Icons On Full Screen
    this.sub.add(
      this.fsService.fullScreenEnabled$.subscribe((x) => {
        this.fullScreenEnabled = x;
        this.updateToolbarItems();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.currentConnectionStatus !== EConnectionStatus.disconnected) {
      this.disconnect();
    }
  }

  public resize(): void {
    if (this.img && this.imgContainer) {
      this.pickerPosition = CoordinatePickerDirective.computePickerPositionNDimension(
        this.img.nativeElement.naturalHeight,
        this.img.nativeElement.naturalWidth,
        this.imgContainer.nativeElement.offsetHeight,
        this.imgContainer.nativeElement.offsetWidth,
      );
    }
  }

  public dataSourceHandler(): Observable<string> {
    const targetField = Utils.getTargetFieldByDataURI(this.widget.dataURI, this.widget.source);
    const targetSource = Utils.getTargetSourceWithQueryByDataURI(this.widget.dataURI, this.widget.source);

    const dataSource = this.rocosClientService.subscribe(this.projectId, [this.callsign], [targetSource]);

    this.dataSource = dataSource;

    // Source Observable
    return dataSource.observable.pipe(
      // Get Data
      map((msg) => Utils.getTargetData(msg, targetField)),
      // Format Data
      map((v) => (v ? this.evalPipe.transform(v, this.valueExpression) : null)),
      map((v) => (v && Object.prototype.hasOwnProperty.call(v, 'data') ? v.data : v)),
      // Format Image Source
      map((v) => (v ? `data:image/${this.imageType};base64,${v}` : null)),
      // Interval Last Update
      tap(() => (this.lastValueUpdatedAt = new Date())),
    ) as Observable<string>;
  }

  public dataObserver(): Subscription {
    return this.dataSourceHandler().subscribe((imageSrc) => {
      this.imageSource = imageSrc;
      this.resize(); // Resize the coordinator picker when new image coming
      if (this.currentConnectionStatus !== EConnectionStatus.connected) {
        this.updateToolbarItems();
      }
      this.currentConnectionStatus = EConnectionStatus.connected;
    });
  }

  public connect(): void {
    this.currentConnectionStatus = EConnectionStatus.connecting;
    this.subscription = this.dataObserver();

    this.createWidgetStatusTimer();
    this.updateToolbarItems();
  }

  public disconnect(): void {
    this.subscription.unsubscribe();
    this.rocosClient.unsubscribe(this.dataSource);
    this.currentConnectionStatus = EConnectionStatus.disconnected;
    this.imageSource = null;

    if (this.widgetStatusTimer) {
      clearInterval(this.widgetStatusTimer);
    }
    this.widget.status = 'alive';
    this.updateToolbarItems();
  }

  public onFullscreen(): void {
    this.baseWidget.requestFullScreen();
  }

  public override exitFullScreen(): void {
    this.baseWidget.exitFullScreen();
  }

  public override onToolbarItemClick(event: WidgetToolbarItem): void {
    if (event.text === 'CONNECT') {
      this.connect();
    } else if (event.text === 'DISCONNECT' || event.text === 'CANCEL') {
      this.disconnect();
    } else if (event.icon && event.icon === 'ri-enter-fullscreen') {
      this.onFullscreen();
    } else if (event.icon && event.icon === 'ri-exit-fullscreen') {
      this.exitFullScreen();
    }
  }

  private updateToolbarItems(): void {
    const items = [];

    if (this.viewSwitchable) {
      items.push(
        new WidgetToolbarItem(
          '',
          'ri-switch-view',
          'light',
          'Switch View',
          'switch-view-icon',
          null,
          'right',
          'button',
        ),
      );
    }

    items.push(
      new WidgetToolbarItem(
        '',
        'ri-camera_icon',
        this.currentConnectionStatus === EConnectionStatus.connected ? 'success' : 'dark',
      ),
    );

    if (this.currentConnectionStatus === EConnectionStatus.disconnected) {
      items.push(
        new WidgetToolbarItem('CONNECT', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button'),
      );
    } else if (this.currentConnectionStatus === EConnectionStatus.connecting) {
      items.push(
        new WidgetToolbarItem('CANCEL', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button'),
      );
    } else if (this.currentConnectionStatus === EConnectionStatus.connected) {
      items.push(
        new WidgetToolbarItem(
          'DISCONNECT',
          '',
          'primary',
          '',
          'button',
          [],
          'right',
          'button',
          'stream-control-button',
        ),
      );
    }

    // FS controls
    items.push(this.getFullscreenControlIcon(this.fullScreenEnabled));

    this.toolbarItems = items;
  }

  private getFullscreenControlIcon(fullScreenEnabled: boolean): WidgetToolbarItem {
    return !fullScreenEnabled
      ? new WidgetToolbarItem('', 'ri-enter-fullscreen', 'light', '', 'button', [], 'right', 'button')
      : new WidgetToolbarItem('', 'ri-exit-fullscreen', 'light', '', 'button', [], 'right', 'button');
  }

  private createWidgetStatusTimer(): void {
    // Clear first.
    if (this.widgetStatusTimer) {
      clearInterval(this.widgetStatusTimer);
    }

    this.lastValueUpdatedAt = new Date();

    this.widgetStatusTimer = setInterval(() => {
      this.updateToolbarItems();
      const now = new Date();
      if ((now.getTime() - this.lastValueUpdatedAt.getTime()) / 1000 > this.widget.widgetTimeout) {
        // Timeout
        this.widget.status = 'dead' as WidgetStatus;
      } else {
        this.widget.status = 'alive' as WidgetStatus;
      }
    }, 1000);
  }
}
