export class DataExplorerPreviewMethod {
  public static table: DataExplorerPreviewMethod = new DataExplorerPreviewMethod('table', 'Table');
  public static json: DataExplorerPreviewMethod = new DataExplorerPreviewMethod('json', 'JSON');
  public static lineGraph: DataExplorerPreviewMethod = new DataExplorerPreviewMethod('lineGraph', 'Line Graph');
  public static service: DataExplorerPreviewMethod = new DataExplorerPreviewMethod('service', 'Service');

  public name: string;
  public text: string;

  constructor(name: string, text: string) {
    this.name = name;
    this.text = text;
  }
}
