import type { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TRANSLOCO_LOADER } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { default as enJson } from '../assets/i18n/en.json';
import { default as zhCnJson } from '../assets/i18n/zh-CN.json';

@Injectable({ providedIn: 'root' })
export class StaticTranslocoLoader implements TranslocoLoader {
  getTranslation(langPath: string) {
    let res: Translation = enJson;
    switch (langPath) {
      case 'en':
        res = enJson;
        break;
      case 'zh-CN':
        res = zhCnJson;
        break;
      default:
        res = enJson;
        break;
    }

    return of(res);
  }
}

export const translocoLoader = {
  provide: TRANSLOCO_LOADER,
  useClass: StaticTranslocoLoader,
};
