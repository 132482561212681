import { Injectable } from '@angular/core';
import { BabylonRendererService } from './babylon-renderer.service';

@Injectable()
export class GazeboWorldService {
  maxModels: number = 1000;

  public constructor(private babylonRenderer: BabylonRendererService) {}

  /**
   *
   * @param xml XML string content of the World data
   * @param parentNodeKey The name of the Scene mesh object to load the world file inside
   * @param materialKey Optional name of the material to apply to each object
   */
  loadWorld(xml: string, parentNodeKey: string, materialKey?: string) {
    const parser = new DOMParser();
    const sdfXML = parser.parseFromString(xml, 'text/xml');

    this.getRendererNodeByKey(parentNodeKey);

    let modelCount = 0;

    Array.from(sdfXML.querySelector('world').querySelectorAll('world > model')).forEach((model) => {
      modelCount++;

      if (modelCount < this.maxModels) {
        const modelPose = this.getPose(model);
        const modelName = model.getAttribute('name');

        this.createHiddenParent(modelName, modelPose);
        this.setParent(modelName, parentNodeKey);

        let linkCount = 0;
        // Can have multiple link elements
        Array.from(model.querySelectorAll('link')).forEach((link) => {
          linkCount++;

          if (linkCount < this.maxModels) {
            const linkPose = this.getPose(link);
            const linkName = link.getAttribute('name');

            // add parent object representing this link
            this.createHiddenParent(linkName, linkPose);
            this.setParent(linkName, modelName);

            let visualCount = 0;

            Array.from(link.querySelectorAll('visual')).forEach((visual) => {
              visualCount++;
              if (visualCount < this.maxModels) {
                const visualPose = this.getPose(visual);
                const visualName = visual.getAttribute('name');

                const geometry = visual.querySelectorAll('geometry')[0];
                const shape = geometry.firstElementChild;
                const faceColors = new Array(6);
                faceColors[0] = this.babylonRenderer.getColor4([0, 0, 0, 255]);

                let boxSize;
                let boxMeshData: any;
                let box;
                switch (shape.tagName) {
                  case 'box':
                    boxSize = shape.querySelectorAll('size')[0].textContent.split(' ');
                    boxMeshData = {
                      options: {
                        width: boxSize[0],
                        depth: boxSize[2],
                        height: boxSize[1],
                        faceColors,
                      },
                    };

                    box = this.babylonRenderer.createBox(visualName, boxMeshData.options);
                    box.position = this.babylonRenderer.getVector(visualPose.position);
                    box.rotation = this.babylonRenderer.getVector(visualPose.rotation);

                    if (materialKey) {
                      box.material = this.babylonRenderer.getMaterial(materialKey);
                    }

                    break;

                  default:
                    console.warn('GazeboWorldService does not support this shape: ', shape.tagName);
                }
                this.setParent(visualName, linkName);
              }
            });
          }
        });
      }
    });
  }

  private createHiddenParent(objectKey, pose) {
    const tfNode = this.babylonRenderer.createTransformNode(objectKey);
    tfNode.position = this.babylonRenderer.getVector(pose.position);
    tfNode.rotation = this.babylonRenderer.getVector(pose.rotation);
  }

  private getPose(element) {
    const position = [0, 0, 0];
    const rotation = [0, 0, 0];
    const pose = element.querySelector(element.tagName + ' > pose');
    if (pose == null) {
      console.warn('pose element could not be found for ' + element.tagName);
    } else {
      const poseVal = pose.textContent.split(' ');
      position[0] = poseVal[0];
      position[1] = poseVal[1];
      position[2] = poseVal[2];
      rotation[0] = poseVal[3];
      rotation[1] = poseVal[4];
      rotation[2] = poseVal[5];
    }

    const poseResponse: any = {};
    poseResponse.position = position;
    poseResponse.rotation = rotation;

    return poseResponse;
  }

  private setParent(childKey, parentKey) {
    const child = this.getRendererNodeByKey(childKey);
    const parent = this.getRendererNodeByKey(parentKey);

    if (child && parent) {
      child.parent = parent;
    }
  }

  private getRendererNodeByKey(key) {
    return this.babylonRenderer.getNode(key);
  }
}
