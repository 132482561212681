import type { EmbeddedViewRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import type { Subscription } from 'rxjs';
import { DevelopmentService, UserService } from '../services';
import { environment } from '@env/environment';

@Directive({
  selector: '[appAdminOnly]',
})
export class AdminOnlyDirective implements OnDestroy, OnChanges {
  @Input() public appAdminOnly: boolean = true;

  public highlight = false;
  public hideAdminContent = false;
  public embeddedViewRef: EmbeddedViewRef<any>;

  private hasView = false;
  private subscriptions: Subscription[] = [];

  private get borderColor(): string {
    return 'fuchsia';
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private developmentService: DevelopmentService,
    private userService: UserService,
  ) {
    const hideSub = this.developmentService.hideAdminContent.subscribe((hide) => {
      this.hideAdminContent = hide;

      this.statusUpdated();
    });

    const highlightSub = this.developmentService.highlightMode.subscribe((highlight) => {
      this.highlight = highlight;

      this.statusUpdated();
    });

    this.subscriptions.push(hideSub);
    this.subscriptions.push(highlightSub);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['appAdminOnly']) {
      this.statusUpdated();
    }
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  /**
   * Callback when development status updated
   */
  private statusUpdated() {
    let hideContent = this.appAdminOnly !== false && this.isProd && !this.isAdmin;
    hideContent = hideContent || (this.appAdminOnly !== false && this.hideAdminContent);

    // Listen the development content hide or show event
    if (!hideContent && !this.hasView) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (hideContent && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    const isHighlight = !this.isProd && this.highlight && this.appAdminOnly !== false;
    // Listen the highlight mode event
    if (this.embeddedViewRef?.rootNodes) {
      const firstNode = this.embeddedViewRef.rootNodes[0];
      if (firstNode?.style !== undefined) {
        if (isHighlight) {
          firstNode.style.border = `1px solid ${this.borderColor}`;
        } else {
          firstNode.style.border = 'none';
        }
      }
    }
  }

  private get isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  private get isProd(): boolean {
    return environment.production;
  }
}
