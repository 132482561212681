import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { WidgetMessageFormat } from '../../models';
import { ValueEvalPipe } from '../../pipes';
import { WidgetBaseComponent } from '../shared';
@Component({
  selector: 'app-widget-table-v2',
  templateUrl: './widget-table-v2.component.html',
  styleUrls: ['../shared/styles.scss', './widget-table-v2.component.scss'],
})
export class WidgetTableV2Component extends WidgetBaseComponent implements OnChanges {
  @Input()
  content: any;

  @Input()
  messageFormat: WidgetMessageFormat;

  @Input()
  appendRows: string = 'true';

  @ViewChild('mainContent')
  mainContent: ElementRef;

  @Output()
  dataChanged = new EventEmitter();

  @Input()
  displayedColumns: string[] = [];

  _tableLayout: string = 'columns';

  table: {
    header: string[];
    content: any[];
  } = {} as any;

  hasData = false;

  displayedColumnsNames: any;

  logFilters: any[] = [];

  isApendingPaused: boolean = false;
  dataForTableBehaviourSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataForTableObservable: Observable<any>;

  private cachedDisplayedColumnsNames: any;
  private cachedDisplayedVerticleColumnsNames: boolean;
  private cachedDisplayedVerticleColumns: string[];
  private cachedDisplayedColumns: string[];

  @Input()
  set tableLayout(input) {
    this._tableLayout = input;
  }

  private evalPipe: ValueEvalPipe;

  constructor() {
    super();

    this.evalPipe = new ValueEvalPipe();
    this.dataForTableObservable = this.dataForTableBehaviourSubject.asObservable();
  }

  getMaxTableHeight() {
    if (this.mainContent) {
      return this.mainContent.nativeElement.offsetHeight + 'px';
    }
    return this.height;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      if (!this.content) {
        return;
      }

      if (this.valueExpression) {
        this.content = this.evalPipe.transform(this.content, this.valueExpression);
      }

      if (changes['displayedColumns']) {
        this.cachedDisplayedColumns = this.displayedColumns;
      }

      this.hasData = true;

      switch (this.messageFormat) {
        case 'table':
          this.prepareDataForTable(this.content, 'array');
          break;

        case 'raw-json':
          // Do nothing here.
          break;

        default:
          break;
      }
    }
  }

  private prepareDataForTable(value, _dataType) {
    const arrayValue = this.getArrayValueFromJsonObject(value);

    if (arrayValue?.length > 0) {
      const firstItem = arrayValue[0];
      const keys = Object.keys(firstItem);
      this.prepareFilter(keys);

      this.dataForTableBehaviourSubject.next(arrayValue);
    }
  }
  private prepareFilter(keys) {
    if (this._tableLayout === 'rows' && this.cachedDisplayedVerticleColumnsNames) {
      this.displayedColumnsNames = this.cachedDisplayedVerticleColumnsNames;
      this.displayedColumns = this.cachedDisplayedVerticleColumns;
      return;
    }

    if (this._tableLayout === 'columns' && this.cachedDisplayedColumnsNames) {
      this.displayedColumnsNames = this.cachedDisplayedColumnsNames;
      this.displayedColumns = this.cachedDisplayedColumns;
      return;
    }

    this.displayedColumnsNames = {};

    this.logFilters = keys
      .map((k) => {
        const newKey = k[0].toUpperCase() + k.substr(1);
        this.displayedColumnsNames[k] = newKey;
        return newKey;
      })
      .filter((k) => {
        return k !== 'Time' && k !== 'Tim';
      });

    // If displayedColumns is passed in, we don't update it
    if (!this.displayedColumns || this.displayedColumns.length === 0) {
      this.displayedColumns = keys.filter((k) => {
        return k !== 'time' && k !== 'tim';
      });
    }

    const data = {
      filterUpdated: true,
      displayedColumns: this.displayedColumns,
      displayedColumnsNames: this.displayedColumnsNames,
    };

    if (this._tableLayout === 'rows') {
      this.cachedDisplayedVerticleColumnsNames = this.displayedColumnsNames;
      this.cachedDisplayedVerticleColumns = this.displayedColumns;
    } else {
      this.cachedDisplayedColumnsNames = this.displayedColumnsNames;
      this.cachedDisplayedColumns = this.displayedColumns;
    }

    this.dataChanged.next(data);
  }

  private getArrayValueFromJsonObject(jsonObject) {
    let arrayValue = null;
    let isPrimitive = false;
    if (!Array.isArray(jsonObject)) {
      // this is  primitive type, convert it to object
      if (jsonObject !== Object(jsonObject)) {
        jsonObject = { 'value': jsonObject };
        isPrimitive = true;
      }
      const keys = Object.keys(jsonObject);
      if (keys) {
        if (keys.length === 1) {
          const firstItem = jsonObject[keys[0]];
          if (Array.isArray(firstItem)) {
            arrayValue = firstItem;
          } else if (isPrimitive) {
            // this is primitive type
            arrayValue = [jsonObject];
          }
        }

        if (keys.length > 1) {
          arrayValue = [jsonObject];
        }
      }
    } else {
      arrayValue = jsonObject;
    }

    // vertical mode, transpose the first element
    if (this.messageFormat === 'table' && this._tableLayout === 'rows') {
      // vertical mode doesn't have appending mode
      this.appendRows = 'false';
      this.isApendingPaused = false;
      if (arrayValue.length > 0) {
        const firstRow = arrayValue[0];
        const keys = Object.keys(firstRow);
        const transposeData = [];
        if (keys?.length > 0) {
          for (const k of keys) {
            const transposeRow = {};
            transposeRow['column' + 1] = k;
            transposeRow['column' + 2] = firstRow[k];
            transposeData.push(transposeRow);
          }
        } else {
          // can't find key, add a key to it
          const transposeRow = {};
          transposeRow['column' + 1] = 'data';
          transposeRow['column' + 2] = firstRow;
          transposeData.push(transposeRow);
        }

        arrayValue = transposeData;
      }
    }

    return arrayValue;
  }
}
