import type { Matrix, Scene } from '@babylonjs/core';
import { Color3, Mesh, StandardMaterial, Texture, Vector3, VertexData } from '@babylonjs/core';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import type { GpsLocation } from '../transform';
import { worldToCrs } from '../transform';
import { SlippyTransformer } from '../transform';
import type { RocosSdkClientService } from '@shared/services';
import type { IMeshOptions } from '../primitives/visualizer/interface/IMeshOptions';
import { getOrthomosaicTilesUrl } from '../../../utils/plan-utils';
import { environment } from '@env/environment';

export interface Tile2D {
  index: [number, number];
  positions: number[];
  indices: number[];
  uvs: number[];
  center: [number, number, number];
  width: number;
  height: number;
}

abstract class Tiles2D extends Mesh {
  private readonly zoom: number;
  private readonly slippyTransformer: SlippyTransformer;
  private tileQueue: Tile2D[];
  private urlTemplate: string;
  private maxRequests = 32;
  private _transparent = true;

  constructor(
    name: string,
    scene: Scene,
    protected meshData: IMesh,
    protected projectLocation: GpsLocation,
    protected projectId: string,
    protected sdk: RocosSdkClientService,
  ) {
    super(name, scene);
    this.zoom = Math.max(0, Math.min(24, meshData.options?.zoom ?? 0));
    this._transparent = meshData.transparent ?? true;

    this.slippyTransformer = new SlippyTransformer(projectLocation);

    this.init().catch((err) => {
      console.error(err);
    });

    this.onEnabledStateChangedObservable.add((enabled) => {
      if (enabled) {
        this.loadTiles();
      }
    });
  }

  public set transparent(value: boolean) {
    this._transparent = value;
    this.getChildren().forEach((child) => {
      const mesh = child as Mesh;
      const material = mesh.material as StandardMaterial;
      material.diffuseTexture.hasAlpha = this._transparent;
      material.markDirty(true);
    });
  }

  public get transparent(): boolean {
    return this._transparent;
  }

  private async init() {
    const res = await this.getTiles(this.meshData.options);

    if (!res?.geometry || !res?.url) {
      console.warn('No geometry or url found for tileset');
      return;
    }

    this.urlTemplate = res.url;

    this.populateTileQueue(res.geometry);
    await this.loadTiles();
  }

  private populateTileQueue(geometry: GpsLocation[]) {
    this.tileQueue = this.slippyTransformer.getTilesInLocalFrame(geometry, this.zoom);

    // Sort tiles by distance to project center
    this.tileQueue.sort((a, b) => {
      const aDistance = Vector3.DistanceSquared(new Vector3(a.center[0], a.center[1], 0), Vector3.Zero());
      const bDistance = Vector3.DistanceSquared(new Vector3(b.center[0], b.center[1], 0), Vector3.Zero());
      return aDistance - bDistance;
    });
  }

  private async loadTiles() {
    const parentMatrix = this.getWorldMatrix().clone();
    this.getScene().blockMaterialDirtyMechanism = true;

    while (this.tileQueue.length && this.isEnabled()) {
      const batch = this.tileQueue.splice(0, this.maxRequests);
      await Promise.all(batch.map((tile) => this.loadTile(tile, parentMatrix)));
    }

    this.getScene().blockMaterialDirtyMechanism = false;
  }

  private createTileMaterial(name: string, texture: Texture): StandardMaterial {
    const material = new StandardMaterial(name, this.getScene());
    material.diffuseTexture = texture;
    material.backFaceCulling = false;
    material.diffuseTexture.hasAlpha = this._transparent;
    material.specularColor = new Color3(0, 0, 0);
    return material;
  }

  private async loadTile(tile: Tile2D, parentMatrix: Matrix): Promise<void> {
    return new Promise((resolve) => {
      const url = this.urlTemplate
        .replace('{x}', tile.index[0].toString())
        .replace('{y}', tile.index[1].toString())
        .replace('{z}', this.zoom.toString());

      const texture = new Texture(url, this.getScene(), true, false, Texture.NEAREST_SAMPLINGMODE);
      texture.onLoadObservable.addOnce(() => {
        const tileMesh = this.createTileMesh(tile, texture, parentMatrix);
        tileMesh.material = this.createTileMaterial(tileMesh.name, texture);
        tileMesh.parent = this;
        resolve();
      });
    });
  }

  private createTileMesh(tile: Tile2D, texture: Texture, parentMatrix: Matrix): Mesh {
    const tileName = `${this.id}-${tile.index[0]}-${tile.index[1]}`;
    const tileMesh = new Mesh(tileName, this.getScene());
    const normals = [];
    VertexData.ComputeNormals(tile.positions, tile.indices, normals);

    const vertexData = new VertexData();
    vertexData.positions = tile.positions;
    vertexData.indices = tile.indices;
    vertexData.normals = normals;
    vertexData.uvs = tile.uvs;
    vertexData.applyToMesh(tileMesh);

    const meshMatrix = tileMesh.getWorldMatrix();
    meshMatrix.multiplyToRef(parentMatrix, meshMatrix);
    tileMesh.bakeCurrentTransformIntoVertices();

    return tileMesh;
  }

  /**
   * Must implement a method to retrieve the Tiles URL (with template of /{z}/{x}/{y}.png) to be able to load tiles
   * and the geometry (array of GpsLocation) to define perimeter to load
   *
   * @param options mesh options
   * @returns a promise with the url and perimeter geometry
   */
  protected abstract getTiles(options: IMeshOptions): Promise<{ url: string; geometry: GpsLocation[] } | null>;
}

export class MapTiles2D extends Tiles2D {
  protected async getTiles(options: IMeshOptions) {
    if (!options.ddPlanId) {
      console.warn('No plan id provided');
      return undefined;
    }

    const response = await this.sdk.client.getIntegrationService().getPlanById(this.projectId, options.ddPlanId);
    if (!response) {
      console.warn('Plan not found');
      return undefined;
    }

    const url = getOrthomosaicTilesUrl(response);

    if (!response.geometry?.length) {
      console.warn('Tile URL not found');
      return undefined;
    }

    if (this.projectLocation.latitude === 0 && this.projectLocation.longitude === 0) {
      this.projectLocation.latitude = response.location.lat;
      this.projectLocation.longitude = response.location.lng;
      this.projectLocation.altitude = 0.0;
    }

    if (!response.geometry?.length) {
      console.warn('No geometry found');
      return undefined;
    }

    const geometry = response.geometry.map((x) => ({ latitude: x.lat, longitude: x.lng, altitude: 0.0 }));

    return {
      geometry,
      url,
    };
  }
}

export class OverlayTiles2D extends Tiles2D {
  protected async getTiles(options: IMeshOptions) {
    if (!options?.ddOverlayId) {
      console.warn('No overlay id provided');
      return undefined;
    }

    const response = await this.sdk.client.getIntegrationService().getOverlays(this.projectId);
    const overlay = response?.find((x) => x.id === options.ddOverlayId);

    if (!overlay?.tile_layer?.url) {
      console.warn('Tile layer for overlay not found', { overlay });
      return undefined;
    }

    const host = `${environment.droneDeploy.tilesApi}filters:color_to_alpha(FFFFFF,-1,false,0)`;
    const url = `${host}/${overlay.tile_layer.url}?jwt_token=${overlay.tile_layer.jwt_token}`;

    const geometry = overlay.geometry?.coordinates?.[0]?.map((x) => ({
      latitude: x[1],
      longitude: x[0],
      altitude: 0.0,
    }));

    if (!geometry?.length) {
      console.warn('No geometry found for overlay');
      return undefined;
    }

    return {
      geometry,
      url,
    };
  }
}

export class BasemapTiles2D extends Tiles2D {
  protected async getTiles(_: IMeshOptions) {
    const host = `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.webp`;
    const url = `${host}?access_token=${environment.mapbox.accessToken}`;

    const bboxHalfLength = 500; // meters
    const corners = [
      new Vector3(-bboxHalfLength, -bboxHalfLength, 0),
      new Vector3(bboxHalfLength, bboxHalfLength, 0),
      new Vector3(-bboxHalfLength, bboxHalfLength, 0),
      new Vector3(bboxHalfLength, -bboxHalfLength, 0),
    ];
    const corners_wgs84 = corners.map((v) => worldToCrs(this.projectLocation, 'WGS84', v));
    const geometry = corners_wgs84.map((v) => ({ latitude: v.y, longitude: v.x, altitude: 0.0 }));

    return {
      geometry,
      url,
    };
  }
}
