import { WidgetBase } from './widget-base';

export class WidgetGamepad extends WidgetBase {
  public keepGamepadAlive? = false;

  public static fromModel(model: any): WidgetGamepad {
    const widget = new WidgetGamepad();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any): void {
    super.readValuesFromModel(model);
    this.keepGamepadAlive = model.keepGamepadAlive;
  }
}
