import type { EmbeddedViewRef } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { DevelopmentService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { satisfies, coerce, validRange } from 'semver';
import { RobotInfoService } from '@shared/services/robot-info/robot-info.service';

@UntilDestroy()
@Directive({
  selector: '[appRequireAgentVersion]',
})
export class RequireAgentVersionDirective {
  private highlight = false;
  private embeddedViewRef: EmbeddedViewRef<any>;
  private version: string;
  private showOnUndefined = false;

  @Input() set appRequireAgentVersion(version: string) {
    if (version !== this.version) {
      this.version = version;
      this.statusUpdated();
    }
  }

  @Input() set appRequireAgentVersionShowOnUndefined(showOnUndefined: boolean) {
    if (showOnUndefined !== this.showOnUndefined) {
      this.showOnUndefined = showOnUndefined;
      this.statusUpdated();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private developmentService: DevelopmentService,
    private robotInfoService: RobotInfoService,
  ) {
    this.developmentService.highlightMode.pipe(untilDestroyed(this)).subscribe((highlight) => {
      if (highlight !== this.highlight) {
        this.highlight = highlight;
        if (this.version) this.statusUpdated();
      }
    });
  }

  private shouldShow(agentVersion?: string): boolean {
    if (!validRange(this.version)) {
      console.warn(`Required agent version (${this.version}) is not semver compatible`);
      return false;
    }

    if (this.showOnUndefined && agentVersion === undefined) return true;
    return satisfies(agentVersion, this.version);
  }

  private statusUpdated() {
    this.viewContainer.clear();
    const agentVersion = this.robotInfoService.agentVersion$.getValue();
    const agentVersionSemver = coerce(agentVersion)?.version;

    const satisfiesVersion = this.shouldShow(agentVersionSemver);
    if (satisfiesVersion) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    }

    const showHighlight = this.highlight && !this.developmentService.isProd;
    if (!satisfiesVersion && this.embeddedViewRef?.rootNodes) {
      const firstNode = this.embeddedViewRef.rootNodes[0];
      if (firstNode) {
        if (showHighlight) {
          firstNode.style.border = `1px solid hotpink`;
        } else {
          firstNode.style.border = 'none';
        }
      }
    }
  }
}
