<ng-container *ngIf="(fsEnabled$ | async) === false">
  <!-- Full screen disabled -->
  <button (click)="triggerFullScreen()" mat-icon-button>
    <span class="ri-enter-fullscreen"></span>
  </button>
</ng-container>

<ng-container *ngIf="(fsEnabled$ | async) === true">
  <!-- Full screen enabled -->
  <button (click)="exitFullscreen()" mat-icon-button>
    <span class="ri-exit-fullscreen"></span>
  </button>
</ng-container>
