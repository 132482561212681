import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockService<T> {
  private _list: T[] = [];

  info(projectId: string, id: string): Observable<T> {
    return Observable.create((observer) => {
      const item = this.getItem(id);
      observer.next(item);
      observer.complete();
    });
  }

  create(config?: T): Observable<any> {
    return Observable.create((observer) => {
      this._list.push(config);
      observer.next(true);
      observer.complete();
    });
  }

  update(config?: T): Observable<any> {
    return Observable.create((observer) => {
      this._list.forEach((item) => {
        if ((item as any).name === (config as any).name) {
          item = config;
        }
      });

      observer.next(true);
      observer.complete();
    });
  }

  delete(config?: T): Observable<any> {
    return Observable.create((observer) => {
      const filtered = this._list.filter((item) => {
        return (item as any).name === (config as any).name;
      });

      if (filtered?.length > 0) {
        this._list.splice(this._list.indexOf(filtered[0]), 1);
      }

      observer.next(true);
      observer.complete();
    });
  }

  list(): Observable<T[]> {
    return Observable.create((observer) => {
      // Create a new instance.
      const temp = [];
      this._list.forEach((item) => {
        temp.push(item);
      });
      observer.next(temp);
      observer.complete();
    });
  }

  private getItem(id: string) {
    const filtered = this._list.filter((item) => {
      return (item as any).id === id || (item as any).name === id;
    });

    if (filtered?.length > 0) {
      return filtered[0];
    }

    return null;
  }
}
