import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import type { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import type { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import type { TreeItemDataType } from '../../models';
import { DataExplorerPreviewMethod, TreeItem } from '../../models';
import { RocosClientService, ToastService } from '../../services';
import { Utils } from '../../utils';
import type { IRocosTelemetryMessage } from '@team-rocos/rocos-js';
import { ResultStatus } from '@team-rocos/rocos-js/dist/grpc/serviette';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { PreviewLineGraphComponent } from '../preview-line-graph';

@Component({
  selector: 'app-data-source-preview',
  templateUrl: './data-source-preview.component.html',
  styleUrls: ['./data-source-preview.component.scss'],
})
export class DataSourcePreviewComponent implements OnInit, OnDestroy {
  @Input()
  public projectId: string;

  @Input()
  public callsign: string;

  @Input()
  public pageFrom: number;
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public topic: string;
  @Input()
  public attribute: unknown;
  @Input()
  public sources: string[];
  @Input()
  public callsigns: string[];

  @Input()
  public set sourceInfo(val: TreeItem) {
    this._sourceInfo = val;

    if (this._sourceInfo.itemType !== 'service') {
      this.needToRestartSubscriptionFastLane();
      this.getContentMediaType();
    } else {
      this.prepareRequestPayloadData();
    }
  }

  public get sourceInfo(): TreeItem {
    return this._sourceInfo;
  }

  @Input()
  public sourceQuerystring: string;

  @Input()
  public isPaused: boolean = false;

  @Input()
  public insertDataURIMode: boolean;

  @Input()
  public dataStreamMode: boolean;

  @Output()
  public dataURIChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public dataItemChange: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();

  @Output()
  public previewClosed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('lineGraphComponent')
  public lineGraphComponent: PreviewLineGraphComponent;

  @ViewChild('preContainer')
  public preContainer: ElementRef;

  public serviceRequestPayload: string;
  public serviettteServicesCache: unknown;
  public serviceForm: UntypedFormGroup;

  public isLoading: boolean = false;
  public responsePayloads: { payload: unknown; createdTime: number }[] = [];
  public callServiceSubscriptions: Subscription[] = [];
  public currentStatus: { status?: number; message?: string; responseType?: string } = {};
  public successStatus: number;

  public contentMediaType: string = '';

  public dataForTableBehaviourSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);
  public dataForTableObservable: Observable<unknown>;

  public pageSize: number = 50;

  public displayedColumns: string[] = [];
  public displayedColumnsNames: unknown;
  public filterForm: UntypedFormGroup;
  public logFilters: string[] = [];
  public isApendingPaused: boolean = false;
  public isApendingMode: boolean = false;

  public rawOutput: unknown;

  /**
   * Output value: for line graph or other preview components to render content.
   */
  public outputValue: unknown;

  public currentPreviewMethod: DataExplorerPreviewMethod = DataExplorerPreviewMethod.json;
  public previewMethods: DataExplorerPreviewMethod[] = [];

  // For refreshing the app-data-performance
  public latestMessageReceivedAt: Date;

  public editorOption = {
    contextmenu: false,
    language: 'json',
    minimap: {
      enabled: false,
    },
    automaticLayout: true,
    scrollBeyondLastLine: false,
    lineDecorationsWidth: 0,
    hideCursorInOverviewRuler: true,
    wordWrap: 'on',
  };

  private _sourceInfo: TreeItem;

  private _output: unknown;
  public get output(): unknown {
    return this._output;
  }

  public set output(val: unknown) {
    this._output = val;

    // Update latestMessageReceivedAt
    this.latestMessageReceivedAt = new Date();
  }

  private subscriptions: Subscription[] = [];

  public constructor(
    private rocosClientService: RocosClientService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private toast: ToastService,
  ) {
    this.rocosClientService.serviettteServicesCache.subscribe((x) => {
      this.serviettteServicesCache = x;
    });

    this.successStatus = ResultStatus.COMPLETE_SUCCESS;
    this.dataForTableObservable = this.dataForTableBehaviourSubject.asObservable();
  }

  public ngOnInit(): void {
    if (this.dataStreamMode) {
      this.loadChildrenSchema();
    } else {
      this.loadPreviewMethods();
      this.createForm();
    }
  }
  public createFilterGroup(t: string): UntypedFormGroup {
    return this.formBuilder.group({
      label: t,
      value: t[0].toLowerCase() + t.substr(1),
      checkedStatus: true,
    });
  }

  public get formArrayFilters(): UntypedFormArray {
    return this.filterForm.get('filters') as UntypedFormArray;
  }

  public createFilterForm(): void {
    let mode = 'refresh';
    if (this.isApendingMode) {
      mode = 'append';
    }
    this.filterForm = this.formBuilder.group({
      displayOption: mode,
      filters: this.formBuilder.array([]),
    });
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public onTableDisplayOptionChanged(change: MatRadioChange): void {
    this.filterForm.controls['displayOption'].setValue(change.value);
    if (this.filterForm.value.displayOption === 'append') {
      this.isApendingMode = true;
      this.isApendingPaused = false;
    } else {
      this.isApendingMode = false;
      this.isApendingPaused = true;
    }
  }

  public onInsertDataURI(): void {
    this.dataURIChange.next(this.sourceInfo.path);
    this.dataItemChange.next(this.sourceInfo);
  }

  public onSelectPreviewMethod(method: DataExplorerPreviewMethod): void {
    this.currentPreviewMethod = method;
  }

  public onClosePreview(): void {
    this.previewClosed.emit();
  }

  public sourceChanged(newSourceInfo: { path: string; dataType: TreeItemDataType; querystring: string }): void {
    this.loadPreviewMethods();
    this._sourceInfo.path = newSourceInfo.path;
    this._sourceInfo.dataType = newSourceInfo.dataType;
    this.sourceQuerystring = newSourceInfo.querystring;
    this.needToRestartSubscriptionFastLane();
  }

  public defaultErrorMessage(status: number, msg: string): string {
    if (!msg) {
      msg = '';
    }
    switch (status) {
      case ResultStatus.COMPLETE_SUCCESS:
        return `Success`;
      case ResultStatus.CANCELLED:
        return `Cancelled`;
      case ResultStatus.COMPLETE_ERROR:
        return `Service returned error: ${msg}`;
      case ResultStatus.TIMED_OUT:
        return `Service timed out: ${msg}`;
      case ResultStatus.REJECTED_ID:
        return `Could not call service: Invalid service URI: ${msg}`;
      case ResultStatus.REJECTED_AUTH:
        return `Could not call service: Access denied: ${msg}`;
      case ResultStatus.REJECTED_PAYLOAD:
        return `Could not call service: Malformed request payload: ${msg}`;
      case ResultStatus.REJECTED_NO_RECEIVER:
        return `Could not call service: Receiver not connected: ${msg}`;
      case ResultStatus.FATAL:
        return `Could not call service: Unknown error: ${msg}`;
      default:
        return msg;
    }
  }

  public getContentMediaType(): void {
    this.contentMediaType = this.rocosClientService.getContentMediaType(this.sourceInfo.path);
    if (this.contentMediaType === 'application/x.rocos.logs+json') {
      this.currentPreviewMethod = DataExplorerPreviewMethod.table;
      this.isApendingMode = true;
      if (this.filterForm?.controls['displayOption']) {
        this.filterForm.controls['displayOption'].setValue('append');
      }
    }
  }

  public prepareRequestPayloadData(): void {
    this.serviceRequestPayload = this.sourceInfo.querystring;
    this.serviceRequestPayload += this.rocosClientService.getServiceRequestSchema(this.sourceInfo.path);
    if (this.serviceForm) {
      this.serviceForm.controls['serviceRequest'].setValue(this.serviceRequestPayload);
    }
  }

  public callService(): void {
    this.currentStatus = {};

    this.callServiceSubscriptions.forEach((x) => {
      x.unsubscribe();
    });

    this.callServiceSubscriptions = [];

    let payload = this.serviceForm.value.serviceRequest;
    if (!payload) {
      payload = '{}';
    }

    this.isLoading = true;

    this.responsePayloads = [];

    const callServiceSubscription = this.rocosClientService
      .callService(this.projectId, this.callsign, this.sourceInfo.path, payload)
      .subscribe((x) => {
        if (x?.length > 0) {
          const serviceResponse = [];
          for (const y of x) {
            if (y.payload) {
              serviceResponse.push({
                payload: typeof y.payload === 'string' ? y.payload : JSON.stringify(y.payload, null, 2),
                createdTime: y.createdTime,
              });
            }

            if (y.result) {
              this.isLoading = false;
              this.currentStatus = y.result;
              this.currentStatus['responseType'] = 'result';
            }

            if (y.ack) {
              this.currentStatus = y.ack;
              this.currentStatus['responseType'] = 'ack';
            }
          }

          this.responsePayloads.push(...serviceResponse);
        }
      });

    this.callServiceSubscriptions.push(callServiceSubscription);

    setTimeout(() => {
      let allClosed = true;
      this.callServiceSubscriptions.forEach((x) => {
        if (!x.closed) {
          allClosed = false;
        }
      });

      if (allClosed) {
        this.isLoading = false;
      }
    }, 10000);
  }

  public cancelService(): void {
    this.rocosClientService
      .cancelService(this.projectId, this.callsign, this.sourceInfo.path)
      .subscribe(() => undefined);
  }

  public onFilterChanged(): void {
    this.displayedColumns = this.formArrayFilters.value
      .filter((x) => {
        // keep what we can to display
        return x.checkedStatus;
      })
      .map((x) => x.value);
  }

  public async saveToClipboard(payload: any) {
    if ('navigator' in window) {
      if (typeof payload == 'object') {
        payload = JSON.stringify(payload, undefined, 2);
      }
      await navigator.clipboard.writeText(payload);
    }
  }

  public onCopyURI() {
    navigator.clipboard.writeText(this.sourceWithQuerystring).then(() => {
      this.toast.short('Copied to clipboard');
    });
  }

  private prepareFilter(keys) {
    if (this.displayedColumnsNames) {
      return;
    }
    this.displayedColumnsNames = {};

    this.logFilters = keys
      .map((k) => {
        const newKey = k[0].toUpperCase() + k.substr(1);
        this.displayedColumnsNames[k] = newKey;
        return newKey;
      })
      .filter((k) => {
        return k !== 'Time' && k !== 'Tim';
      });

    const filterArray = this.logFilters.map((t) => {
      return this.createFilterGroup(t);
    });

    if (filterArray?.length > 0) {
      this.filterForm.setControl('filters', this.formBuilder.array(filterArray));
    }

    this.displayedColumns = keys.filter((k) => {
      return k !== 'time' && k !== 'tim';
    });
  }

  private createForm() {
    this.serviceForm = this.formBuilder.group({
      'serviceRequest': this.serviceRequestPayload,
    });

    this.createFilterForm();
  }

  private needToRestartSubscriptionFastLane() {
    this.ngZone.runOutsideAngular(() => {
      this.restartSubscriptionFastLane();
    });
  }

  private get sourceWithQuerystring(): string {
    let subscriptionUri = this.sourceInfo.source;
    if (this.sourceQuerystring) {
      subscriptionUri += '?' + this.sourceQuerystring;
    }

    return subscriptionUri;
  }

  private restartSubscriptionFastLane() {
    NgZone.assertNotInAngularZone();

    const source = this.sourceInfo.source;
    const fullPath = this.sourceInfo.path;
    const dataType = this.sourceInfo.dataType;

    let subscriptionUri = this.sourceWithQuerystring;

    if (!subscriptionUri) {
      subscriptionUri = '/rocos/agent/logs';
    }

    this.clearSubscriptions();

    const subscription = this.rocosClientService
      .subscribeV2(this.projectId, [this.callsign], [subscriptionUri])
      .subscribe((msg) => {
        if (!msg || this.isPaused) return;

        const res = this.getOutputFromMessage(msg, fullPath, source, dataType);
        this.output = res.output;
        this.rawOutput = res.rawOutput;

        if (this.lineGraphComponent && (this.output || this.output === 0)) {
          this.lineGraphComponent.addNewValue(this.output, msg.createdAt);
        }

        this.cdr.detectChanges();
      });

    this.subscriptions.push(subscription);
  }

  private getArrayValueFromJsonObject(jsonObject: unknown, dataType: string): unknown[] {
    let arrayValue: unknown[] = null;
    if (dataType === 'object') {
      const keys = Object.keys(jsonObject);
      if (keys?.length === 1) {
        const firstItem = jsonObject[keys[0]];
        if (Array.isArray(firstItem)) {
          arrayValue = firstItem;
        }
      }
    }

    if (dataType === 'array') {
      arrayValue = jsonObject as unknown[];
    }

    return arrayValue;
  }

  private prepareDataForPre(value) {
    return JSON.stringify(value, null, 2);
  }

  private prepareDataForTable(value, dataType) {
    const arrayValue = this.getArrayValueFromJsonObject(value, dataType);

    if (arrayValue?.length > 0) {
      const firstItem = arrayValue[0];
      const keys = Object.keys(firstItem);
      this.prepareFilter(keys);

      this.dataForTableBehaviourSubject.next(arrayValue);
    }
  }

  private getOutputFromMessage(
    msg: IRocosTelemetryMessage,
    fullPath,
    source,
    dataType,
  ): {
    output: unknown;
    rawOutput: unknown;
  } {
    let output = null;
    let rawOutput = null;

    if (msg?.payload !== undefined) {
      const targetField = Utils.getTargetFieldByDataURI(fullPath, source);

      const value = Utils.getTargetData(msg, targetField);

      rawOutput = value;

      switch (dataType) {
        case 'boolean':
        case 'integer':
        case 'number':
        case 'string':
          output = value;
          break;
        case 'object':
        case 'array':
        case 'unknown':
        case 'malformed':
          this.prepareDataForTable(value, dataType);
          output = this.prepareDataForPre(value);
          break;
        default:
          output = value;
          break;
      }
    }

    return {
      output,
      rawOutput,
    };
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private loadPreviewMethods() {
    const methods = [];

    if (this.sourceInfo.itemType !== 'service') {
      // Must have JSON at least
      methods.push(DataExplorerPreviewMethod.json);
      const sourceDataType = this.sourceInfo.dataType;
      switch (sourceDataType) {
        case 'integer':
        case 'number':
          methods.push(DataExplorerPreviewMethod.lineGraph);
          break;
        case 'array':
          methods.push(DataExplorerPreviewMethod.table);
          break;
        case 'object':
          if (this.sourceInfo?.children?.[0]?.dataType === 'array') {
            methods.push(DataExplorerPreviewMethod.table);
          }
          break;
        default:
          // Default is JSON already.
          break;
      }
    } else {
      methods.push(DataExplorerPreviewMethod.service);
      this.currentPreviewMethod = DataExplorerPreviewMethod.service;
    }

    this.previewMethods = methods;
  }

  private loadChildrenSchema() {
    const schema = this.sourceInfo.getChildrenSchema();
    this.output = JSON.stringify(schema, null, 2);
  }
}
