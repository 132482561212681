import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { NgxMonacoEditorConfig } from './config';
import { NGX_MONACO_EDITOR_CONFIG } from './config';
import { EditorComponent } from './editor.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EditorComponent],
  exports: [EditorComponent],
})
export class MonacoEditorModule {
  public static forRoot(config: NgxMonacoEditorConfig = {}): ModuleWithProviders<MonacoEditorModule> {
    return {
      ngModule: MonacoEditorModule,
      providers: [{ provide: NGX_MONACO_EDITOR_CONFIG, useValue: config }],
    };
  }
}
