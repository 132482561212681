import { WidgetBase } from './widget-base';
import type { WidgetMessageFormat } from './widget-message-format';

export class WidgetTableV2 extends WidgetBase {
  messageFormat: WidgetMessageFormat;
  tableLayout: 'rows' | 'columns';
  appendRows: 'true' | 'false';
  override columnFilters: any;

  public static fromModel(model: any): WidgetTableV2 {
    const widget = new WidgetTableV2();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.messageFormat = model.messageFormat;
    this.tableLayout = model.tableLayout;
    this.appendRows = model.appendRows;
    this.columnFilters = model.columnFilters;
  }
}
