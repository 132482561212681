<div class="rs-dialog large">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>
      Send Message
      <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
        <span class="ri-cancel icon-button grey-icon"></span>
      </button>
    </h2>

    <div>
      <!-- Target -->
      <mat-form-field>
        <mat-label>Target</mat-label>
        <input matInput type="text" formControlName="target" placeholder="e.g. /ros/cmd_vel" cdkFocusInitial />
        <mat-error *ngIf="form.controls.target?.errors?.['required']">Enter target.</mat-error>
      </mat-form-field>

      <!-- Payload -->
      <h4 class="label">Payload</h4>
      <div class="editor" [style.height]="'240px'">
        <ngx-monaco-editor
          [style.height]="'240px'"
          [options]="{
            contextmenu: false,
            language: 'text/plain',
            minimap: { enabled: false }
          }"
          formControlName="payload"
        >
        </ngx-monaco-editor>
      </div>

      <!-- Metadata -->
      <h4 class="label">Metadata</h4>
      <div class="editor" [style.height]="'120px'">
        <ngx-monaco-editor
          [style.height]="'120px'"
          formControlName="metadata"
          [options]="{
            contextmenu: false,
            language: 'json',
            minimap: { enabled: false }
          }"
        >
        </ngx-monaco-editor>
      </div>
    </div>
    <div class="footer">
      <app-loading-button (clicked)="onSubmit()">
        <span>Send</span>
      </app-loading-button>
    </div>
  </form>
</div>
