import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerColorTheme } from '../loading-spinner';

export type ButtonType = 'submit' | 'button';

@Component({
  selector: 'app-loading-button,[appLoadingButton]',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Input()
  public loading: boolean;
  @Input()
  public buttonType: ButtonType = 'submit';
  @Input()
  public disabled: boolean = false;
  @Input()
  public buttonStyle: 'normal' | 'flat' = 'normal';
  @Input()
  public fullWidth: boolean = false;
  @Input()
  public color: string = 'primary';
  @Input()
  public spinnerColorTheme: SpinnerColorTheme = 'light';
  @Input()
  public disabledColor: {
    text?: string;
    background?: string;
  } = {};

  @Output()
  public clicked: EventEmitter<any> = new EventEmitter<any>();

  public onButtonClick(event: any): void {
    if (!this.loading && !this.disabled) {
      this.clicked.next(event);
    }
  }
}
