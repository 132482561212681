<div
  class="command-with-value-button rocos-dark-theme"
  [class.horizontal]="layout !== 'vertical'"
  [class.vertical]="layout === 'vertical'"
>
  <div class="command-with-value-button-wrapper">
    <app-loading-button
      [color]="null"
      [fullWidth]="layout === 'vertical'"
      class="loading-button"
      [disabled]="disabled"
      [spinnerColorTheme]="spinnerColorTheme"
      [loading]="loading"
      (clicked)="onClickButton($event)"
    >
      <ng-content></ng-content>
    </app-loading-button>

    <ng-container *ngIf="!disabled">
      <ng-container>
        <form [formGroup]="commandWithValueButtonForm" class="value-inputs">
          <div formArrayName="parameters" class="value-input">
            <div *ngFor="let item of formArrayParas.controls; let i = index" [formGroupName]="i" class="input-array">
              <div class="command-with-value-button-input" *ngIf="item.value.showInputBox">
                <div class="input-label">{{ item.value.label }}</div>
                <input
                  matInput
                  placeholder="Enter value"
                  formControlName="value"
                  (change)="onValueChanged($event, i)"
                />
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
  </div>
</div>
