import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService, GotoService } from '@shared/services';

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentLoginComponent implements OnInit {
  constructor(private authService: AuthService, private goto: GotoService) {}

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.goto.login();
      return;
    }

    this.goto.switchAccountPage();
  }
}
