import type { Material, Mesh, Scene, TransformNode } from '@babylonjs/core';
import { Color3, StandardMaterial } from '@babylonjs/core';
import type { Feature, LineString, Point } from 'geojson';
import { createArrow, createCylinder, createRay, createSphere, createTube } from '../primitives/meshPrimitives';

export enum MapMeshFactoryFeatureTypes {
  FRAME = 'FRAME',
  PANORAMA = 'PANORAMA',
  TARGET = 'TARGET',
  SPOT_WAYPOINT = 'bd.spot.waypoint.v1',
  SPOT_OBJECT = 'bd.spot.object.v1',
}

export class MapMeshFactory {
  private readonly GREEN = 'map-green';
  private readonly YELLOW = 'map-yellow';
  private readonly TEAL = 'map-teal';
  private readonly WHITE = 'map-white';
  private readonly RED = 'map-red';

  public constructor(private scene: Scene) {
    this.initMaterials();
  }

  public fromGeoJsonFeature(parentMeshName: string, feature: Feature): Mesh | TransformNode {
    const meshName = `${parentMeshName}-${feature.id}`;
    switch (feature.geometry.type) {
      case 'Point':
        return this.pointToMesh(feature as Feature<Point>, meshName);
      case 'LineString':
        return this.lineStringToMesh(feature as Feature<LineString>, meshName);
      default:
        throw new Error('Unsupported feature type: ' + feature.geometry.type);
    }
  }

  private initMaterials(): void {
    this.createMaterial(this.scene, this.GREEN, Color3.Green());
    this.createMaterial(this.scene, this.YELLOW, Color3.Yellow());
    this.createMaterial(this.scene, this.TEAL, Color3.Teal());
    this.createMaterial(this.scene, this.WHITE, Color3.White());
    this.createMaterial(this.scene, this.RED, Color3.Red());
  }

  private createMaterial(scene: Scene, materialName: string, color: Color3): Material {
    const existingMaterial = scene.getMaterialByName(materialName);
    if (existingMaterial) return existingMaterial;
    const material = new StandardMaterial(materialName, scene);
    material.diffuseColor = color;
    material.ambientColor = color;
    material.emissiveColor = color;
    material.alpha = 0.25;
    material.backFaceCulling = false;
    material.freeze();
    return material;
  }

  private pointToMesh(feature: Feature<Point>, meshName: string): Mesh {
    const nodeType = feature.properties['type'] || 'default';
    let mesh: Mesh;
    switch (nodeType) {
      case MapMeshFactoryFeatureTypes.FRAME:
        mesh = null;
        break;
      case MapMeshFactoryFeatureTypes.PANORAMA:
        mesh = createCylinder(this.scene, feature, meshName, this.scene.getMaterialByName(this.WHITE), 1, 0.5);
        break;
      case MapMeshFactoryFeatureTypes.TARGET:
        mesh = createRay(this.scene, feature, meshName, this.scene.getMaterialByName(this.RED), 0.01, 0.5);
        break;
      case MapMeshFactoryFeatureTypes.SPOT_WAYPOINT:
        mesh = createSphere(this.scene, feature, meshName, this.scene.getMaterialByName(this.YELLOW), 0.2);
        break;
      case MapMeshFactoryFeatureTypes.SPOT_OBJECT:
        mesh = createSphere(this.scene, feature, meshName, this.scene.getMaterialByName(this.TEAL), 0.1);
        break;
      default:
        mesh = createSphere(this.scene, feature, meshName, this.scene.getMaterialByName(this.GREEN), 0.2);
        break;
    }

    if (mesh) mesh.metadata = { feature };
    return mesh;
  }

  private lineStringToMesh(feature: Feature<LineString>, meshName: string): Mesh | TransformNode {
    const edgeType = feature.properties['type'] || 'default';
    switch (edgeType) {
      case 'TRANSFORM':
        return null;
      case 'bd.spot.traversable.v1':
        return createTube(this.scene, feature, meshName, this.scene.getMaterialByName(this.YELLOW), 0.05);
      case 'bd.spot.observation.v1':
        return createTube(this.scene, feature, meshName, this.scene.getMaterialByName(this.TEAL), 0.025);
      case 'ARROW':
        return createArrow(this.scene, feature, meshName, this.scene.getMaterialByName(this.GREEN), 0.01, 0.15);
      default:
        return createTube(this.scene, feature, meshName, this.scene.getMaterialByName(this.GREEN), 0.01);
    }
  }
}
