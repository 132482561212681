<app-widget-base
  [type]="type"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <app-custom-widget
    [callsign]="widget && widget.callsign ? widget.callsign : null"
    [htmlCode]="externalContent?.content?.htmlCode"
    [cssCode]="externalContent?.content?.cssCode"
    [jsCode]="externalContent?.content?.jsCode"
  >
  </app-custom-widget>
</app-widget-base>
