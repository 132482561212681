import type { WidgetVideo } from './../../../models/widget/widget-video';
import { WidgetToolbarItem } from './../../shared/widget-base/widget-base.component';
import type { VideoSourceStatus } from './../models/video-source';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WidgetVideoService {
  public getToolbarItems(
    widget: WidgetVideo,
    videoStreaming: boolean,
    isConnecting: boolean,
    fullScreenEnabled: boolean,
    viewSwitchable: boolean,
  ): WidgetToolbarItem[] {
    const items = [];

    // Video Icon
    items.push(new WidgetToolbarItem('', 'ri-video', videoStreaming ? 'success' : 'dark'));

    // Connect / Disconnect / Cancel buttons
    items.push(this.getStreamControlButton(videoStreaming, isConnecting));

    // FS controls
    items.push(this.getFullscreenControlIcon(fullScreenEnabled));

    // Latency
    items.push(new WidgetToolbarItem(null, '', 'light', null, 'trip-time-ms', []));

    // Bitrate
    items.push(new WidgetToolbarItem(null, '', 'light', null, 'bit-rate'));
    if (widget?.videoSourceType && videoStreaming) {
      items[4].asyncText = new BehaviorSubject('0Mb/s');
    }

    if (viewSwitchable) {
      items.push(
        new WidgetToolbarItem(
          '',
          'ri-switch-view',
          'light',
          'Switch View',
          'switch-view-icon',
          null,
          'right',
          'button',
        ),
      );
    }

    return items;
  }

  public updateVideoStats(items: WidgetToolbarItem[], videoSourceStatus: VideoSourceStatus): void {
    if (!items[3].asyncText) {
      items[3].asyncText = new BehaviorSubject(null);
    }

    if (!items[4].asyncText) {
      items[4].asyncText = new BehaviorSubject(null);
    }

    items[3].asyncText.next(this.computeLatency(videoSourceStatus));
    items[4].asyncText.next(this.computeWebRTCBitRate(videoSourceStatus));
  }

  private computeLatency(videoSourceStatus: VideoSourceStatus): string {
    if (!videoSourceStatus) {
      return null;
    }

    // The estimatedLatency is calculated by half the RTT + playout delay
    const latency = videoSourceStatus.avgFramePlayout
      ? videoSourceStatus.webrtcRoundTripTimeMs / 2 + videoSourceStatus.avgFramePlayout * 1000
      : videoSourceStatus.webrtcRoundTripTimeMs / 2;

    return `${Math.round(latency)}ms`;
  }

  private computeWebRTCBitRate(videoSourceStatus: VideoSourceStatus): string {
    return videoSourceStatus.webrtcBitrate
      ? `${(Number(videoSourceStatus.webrtcBitrate) / 1000).toFixed(1)}Mb/s`
      : null;
  }

  private getStreamControlButton(videoStreaming: boolean, isConnecting: boolean): WidgetToolbarItem {
    return videoStreaming && !isConnecting
      ? new WidgetToolbarItem('DISCONNECT', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button')
      : isConnecting
      ? new WidgetToolbarItem('CANCEL', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button')
      : new WidgetToolbarItem('CONNECT', '', 'primary', '', 'button', [], 'right', 'button', 'stream-control-button');
  }

  private getFullscreenControlIcon(fullScreenEnabled: boolean): WidgetToolbarItem {
    return !fullScreenEnabled
      ? new WidgetToolbarItem('', 'ri-enter-fullscreen', 'light', '', 'button', [], 'right', 'button')
      : new WidgetToolbarItem('', 'ri-exit-fullscreen', 'light', '', 'button', [], 'right', 'button');
  }
}
