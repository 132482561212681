import type { IProject, IUserInfo, IAccount } from '../../interfaces';

export class Project implements IProject {
  public id?: string;
  public name: string;
  public description?: string;
  public linkedProjectId?: string;
  public linkedProjectName?: string;
  public linkedProjectThumbnail?: string;
  public robotCount?: number;
  public userCount?: number;
  public users: IUserInfo[];
  public account: IAccount;
  public isOwner: boolean;

  constructor(name?: string, description?: string, linkedProjectId?: string, robotCount?: number, userCount?: number) {
    this.name = name ? name : '';
    this.description = description ? description : '';
    this.linkedProjectId = linkedProjectId?.length > 0 ? linkedProjectId : undefined;
    this.robotCount = robotCount;
    this.userCount = userCount;
  }

  public static fromModel(model: IProject): Project {
    const project = new Project();

    project.id = model.id;
    project.name = model.name;
    project.description = model.description;
    project.linkedProjectId = model.linkedProjectId;
    project.linkedProjectName = model.linkedProjectName;
    project.linkedProjectThumbnail = model.linkedProjectThumbnail;
    project.robotCount = model.robotCount;
    project.userCount = model.userCount;

    if (model.users) {
      project.users = model.users;
    }

    if (model.account) {
      project.account = model.account;
    }

    if (model.permissions?.['isOwner']) {
      project.isOwner = model.permissions['isOwner'];
    }

    return project;
  }
}
