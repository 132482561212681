<div class="rs-dialog large">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>
      Button
      <span class="right-buttons">
        <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div>
      <div class="row form-field">
        <!-- ID -->

        <div class="col-6">
          <!-- Label -->
          <mat-form-field>
            <input matInput type="text" formControlName="label" placeholder="Name" (change)="onLabelChange()" />
            <mat-error *ngIf="label?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-6">
          <!-- Description -->
          <mat-form-field>
            <input matInput type="text" formControlName="description" placeholder="Description" />
          </mat-form-field>
        </div>

        <div class="col-6">
          <!-- Control Type -->
          <mat-form-field>
            <mat-select
              placeholder="Control Type"
              formControlName="controlType"
              (selectionChange)="onControlTypeChange()"
            >
              <ng-container *ngFor="let type of types">
                <mat-option *appExperimental="type.featureFlag; projectId: projectId" [value]="type.value">
                  {{ type.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container formGroupName="pickerHint" *ngIf="controlType.value === 'buttonAndPose'">
          <div class="col-3">
            <mat-form-field>
              <mat-select placeholder="Mode" formControlName="mode">
                <mat-option [value]="'xy_y'"> Position (xy_y) </mat-option>
                <mat-option [value]="'xyz[]'"> Polygon (xyz[]) </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <input matInput type="text" formControlName="frame" placeholder="Frame" />
            </mat-form-field>
          </div>
        </ng-container>

        <div class="col-12 command-version" [hidden]="!isRunJsVisible && !isControlsVisible">
          <h3>Action</h3>
          <mat-radio-group formControlName="commandVersion" (change)="onSwitchcommandVersion($event)">
            <mat-radio-button class="mr-4" value="commands-v2">Command</mat-radio-button>
            <ng-container *ngIf="isControlsVisible">
              <mat-radio-button class="mr-4" value="controls">Control</mat-radio-button>
            </ng-container>
            <mat-radio-button *ngIf="isRunJsVisible" value="run-js">Run JavaScript</mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="commandVersion.value === 'controls'" class="col-12">
          <ng-container>
            <div class="control-warning-box">
              We recommend using a Command if possible, as Controls are deprecated. For more information contact
              <a href="mailto:support@dronedeploy.com" appExternalLink>support&#64;dronedeploy.com</a>
            </div>
          </ng-container>
        </div>

        <div class="col-6" *ngIf="isControlsVisible && commandVersion.value === 'controls'">
          <!-- Controls -->
          <mat-form-field>
            <mat-select
              placeholder="Select a Control"
              formControlName="commandName"
              (selectionChange)="onControlSelectionChange($event)"
            >
              <mat-option *ngFor="let control of controls" [value]="control.id">
                {{ control.id }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commandName?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-6" *ngIf="commandVersion.value === 'commands-v2'">
          <!-- Command V2 -->
          <mat-form-field>
            <mat-select
              placeholder="Select a Command"
              formControlName="commandName"
              (selectionChange)="onCommandV2SelectionChange($event)"
            >
              <mat-option *ngFor="let command of commandsV2" [value]="command.id">
                {{ command.id }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commandName?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12" *ngIf="commandVersion.value === 'run-js'">
          <ngx-monaco-editor [style.height]="'200px'" [options]="jsEditorOptions" formControlName="jsPayload">
          </ngx-monaco-editor>
        </div>
      </div>

      <!-- Control Parameters -->
      <div
        class="button-parameters"
        formGroupName="commandParameters"
        *ngIf="
          (commandVersion.value === 'controls' || commandVersion.value === 'commands') &&
          commandParameters &&
          currentControl &&
          currentControl.parameters &&
          currentControl.parameters.length > 0
        "
      >
        <h3>Button Parameters</h3>
        <div class="col">
          <ng-container *ngFor="let param of currentControl.parameters; let idx = index">
            <div class="row align-items-center button-parameter">
              <div class="col-6">
                <mat-form-field>
                  <input matInput type="text" [formControlName]="param.name" [placeholder]="param.name" />
                  <mat-hint>Enter default value.</mat-hint>
                  <mat-error *ngIf="commandParameters.get(param.name)?.errors?.['required']">Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-checkbox [formControlName]="param.name + '_showInputBox'"> Enable input field. </mat-checkbox>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Command Parameters -->
      <div
        class="button-parameters"
        formGroupName="commandParameters"
        *ngIf="
          commandVersion.value === 'commands-v2' &&
          commandParameters &&
          currentCommandV2 &&
          currentCommandV2ParametersLength > 0
        "
      >
        <h3>Button Parameters</h3>
        <div class="col">
          <ng-container *ngFor="let item of currentCommandV2.parameters | keyvalue; let idx = index">
            <div class="row align-items-center button-parameter">
              <div class="col-6">
                <mat-form-field>
                  <input matInput type="text" [formControlName]="item.key" [placeholder]="item.key" />
                  <mat-hint>Enter default value.</mat-hint>
                  <mat-error *ngIf="commandParameters.get(item.key)?.errors?.['required']">Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-checkbox [formControlName]="item.key + '_showInputBox'"> Enable input field. </mat-checkbox>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="button-visibility">
      <h3>Button Visibility</h3>

      <div>
        <mat-checkbox formControlName="showButtonOnDashboard" *ngIf="controlType.value === 'button'">
          Dashboards
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox formControlName="showButtonOnGlobalOperations"> Global Operations </mat-checkbox>
      </div>

      <div>
        <mat-checkbox formControlName="showButtonOnLocalOperations"> Local Operations </mat-checkbox>
      </div>
    </div>

    <div class="footer">
      <app-loading-button [loading]="isLoading">
        <span>Save</span>
      </app-loading-button>
    </div>
  </form>
</div>
