import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostListener, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import {
  AuthService,
  BrowserService,
  CourierService,
  GotoService,
  JsContextService,
  ProjectService,
  RobotInfoService,
  StateService,
  UserService,
} from '@shared/services';
import { I18nService } from '@shared/services/i18n';
import { Utils } from '@shared/utils';
import { Angulartics2GoogleTagManager, Angulartics2Segment } from 'angulartics2';
import type { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FullscreenService } from './shared/services/fullscreen/fullscreen.service';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'rocos-console';

  version = environment.version;

  private subscriptions: Subscription[] = [];

  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private projectService: ProjectService,
    private userService: UserService,
    private courierService: CourierService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2Segment: Angulartics2Segment,
    private i18nService: I18nService,
    private browserService: BrowserService,
    private ngZone: NgZone,
    private fullscreenService: FullscreenService,
    private goto: GotoService,
    private analyticsService: AnalyticsService,
    // Do not remove jsContextService and robotInfoService
    private jsContextService: JsContextService,
    private robotInfoService: RobotInfoService,
  ) {
    const statusErrorSub = this.stateService.statusError.subscribe((err) => {
      if (err?.statusCode) {
        switch (err.statusCode) {
          case 401:
            this.logout();
            break;
          case 403:
            this.refreshToken();
            break;
        }
      }
    });

    const projectUpdatedSub = this.projectService.projectsUpdated.subscribe(() => {
      this.refreshToken();
    });

    const userUpdatedSub = this.authService.userUpdated.subscribe(() => {
      const newUser = this.userService.user;
      // Has a default account but no active account.
      if (newUser.defaultAccount && !newUser.activeAccount) {
        this.userService.activateAccount(newUser.defaultAccount.id).subscribe((_res) => {
          this.refreshToken();
        });
      }
    });

    this.authService.token$.pipe(Utils.rxjsNullFilter, take(1)).subscribe((token) => {
      this.courierService.initialiseCourier(token).catch((err) => {
        console.error('Error while loading courier', err);
      });
    });

    const loggedOutSub = this.authService.loggedOut.subscribe((_user: any) => {
      // Empty here fore now.
    });

    this.subscriptions.push(statusErrorSub);
    this.subscriptions.push(projectUpdatedSub);
    this.subscriptions.push(userUpdatedSub);
    this.subscriptions.push(loggedOutSub);

    this.i18nService.init(environment.i18n.autoDetectLanguageAndSwitch).subscribe();

    this.angulartics2GoogleTagManager.startTracking();
    this.angulartics2Segment.startTracking();
    this.analyticsService.init();
  }

  @HostListener('document:fullscreenchange', []) fsChange() {
    this.fullscreenService.fullScreenChangeEventHandler();
  }

  ngOnInit() {
    const version = this.browserService.detectIE();
    if (
      this.browserService.detectIE() !== false &&
      version <= 11 &&
      document.location.href.indexOf('ie-not-supported') === -1
    ) {
      window.location = '/ie-not-supported' as any;
    }
    this.ngZone.runOutsideAngular(() => {
      this.globalSetDialogOverrides();
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private logout() {
    this.authService.logout().subscribe(() => this.goto.logout(true));
  }

  private refreshToken() {
    this.userService.getNewToken().subscribe((token) => {
      this.authService.updateNewToken(token);
    });
  }

  private globalSetDialogOverrides() {
    let eventIsSet = false;
    setInterval(() => {
      const el: any = document.querySelector('.cdk-overlay-backdrop');
      const container = document.querySelector('.cdk-overlay-container');
      const dialog: any = document.querySelector('.dialog,.rs-dialog');
      const content: any = document.querySelector('.cdk-overlay-pane');

      if (el !== null && dialog !== null) {
        const body = document.body;
        const html = document.documentElement;
        const dialogHeight = dialog.offsetHeight + 120;
        const height = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight,
          dialogHeight,
        );
        el.style.height = height + 'px';
        container.className = 'cdk-overlay-container modal-open';

        if (eventIsSet === false) {
          setTimeout(() => {
            content.addEventListener('mousedown', (e) => {
              if (typeof el.click === 'function' && e.target.className === 'cdk-overlay-pane') {
                el.click.apply(el);
              }
            });
          }, 200);
          eventIsSet = true;
        }
      } else {
        if (container) {
          container.className = 'cdk-overlay-container';
          eventIsSet = false;
        }
      }
    }, 60);
  }
}
