import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MonacoEditorModule } from '@shared-modules/monaco-editor';
import { GridsterModule } from 'angular-gridster2';
import { CustomWidgetBuilderComponent } from './custom-widget-builder.component';
import { CustomWidgetModule } from '../custom-widget';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MaterialModule } from '@shared/material';

@NgModule({
  declarations: [CustomWidgetBuilderComponent],
  exports: [CustomWidgetBuilderComponent],
  imports: [
    CommonModule,
    MonacoEditorModule,
    MatTabsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    GridsterModule,
    CustomWidgetModule,
    MatSlideToggleModule,
    MatButtonModule,
  ],
})
export class CustomWidgetBuilderModule {}
