import { WidgetBase } from './widget-base';
import type { SimpleBinding } from '../../services/threeD/primitives/visualizer';

export class WidgetHorizon extends WidgetBase {
  // position the map is in moves outer ring
  public rollBinding: SimpleBinding; // binding used for Roll
  public yawBinding: SimpleBinding;
  public pitchBinding: SimpleBinding;

  public static fromModel(model: any): WidgetHorizon {
    const widget = new WidgetHorizon();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.rollBinding = model.rollBinding;
    this.yawBinding = model.yawBinding;
    this.pitchBinding = model.pitchBinding;
  }
}
