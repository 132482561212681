import { WorkflowStateService } from 'src/app/workflow/services/workflow-state/workflow-state.service';
import type { IWorkflowWaypoint } from './waypoint-parser.service';
import { WaypointParser } from './waypoint-parser.service';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import type { IEditorEvent } from '../../../workflow/modules/graph-editor/services/editor-state.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ResourcesStateService } from '../../../workflow/modules/resources-management/services/resources-state.service';
import { Utils } from '@shared/utils';
import type { ResourceNav } from '../../../workflow/modules/resources-management/models/resource-nav';
import type { WaypointAction, WaypointPolygonData } from '../threeD/primitives/visualizer/interface/IWaypointPicker';
import { PolygonParser } from './polygon-parser';
import type { NodeComponent } from 'src/app/workflow/modules/graph-editor/components/node-component/node.component';

@Injectable({
  providedIn: 'root',
})
export class WaypointBridgeService {
  private _waypointMode$ = new BehaviorSubject<boolean>(false);
  public get waypointMode$() {
    return this._waypointMode$.asObservable();
  }

  private _graphChange$: Subject<IEditorEvent> = new Subject();
  public set graphChange(change: IEditorEvent) {
    this._graphChange$.next(change);
  }
  public get graphChange$(): Observable<IEditorEvent> {
    return this._graphChange$.asObservable();
  }

  private _waypoints$: Subject<IWorkflowWaypoint[]> = new Subject();
  public get waypoints$(): Observable<IWorkflowWaypoint[]> {
    return this._waypoints$.asObservable();
  }

  private _polygons$: Subject<WaypointPolygonData[]> = new Subject();
  public get polygons$(): Observable<WaypointPolygonData[]> {
    return this._polygons$.asObservable();
  }

  private _waypointsAction$: Subject<WaypointAction> = new Subject();
  public get waypointsAction$(): Observable<WaypointAction> {
    return this._waypointsAction$.asObservable();
  }

  private _registeredComponents: NodeComponent[] = [];
  public set registeredComponents(components: NodeComponent[]) {
    this._registeredComponents = components;
  }

  private destroy$: Subject<void> = new Subject();
  private selectedResource: ResourceNav;

  public constructor(
    private workflowStateService: WorkflowStateService,
    private resourcesState: ResourcesStateService,
  ) {}

  public init(): void {
    this.resourcesState.selected$
      .pipe(
        takeUntil(this.destroy$),
        Utils.rxjsNullFilter,
        filter((resource) => resource.isAgentGraph),
      )
      .subscribe((resource: ResourceNav) => {
        this.selectedResource = resource;
      });

    this.graphChange$.pipe(takeUntil(this.destroy$)).subscribe((_graphChanges) => {
      if (!this.selectedResource) return;
      const waypoints = WaypointParser.computeWaypoints(this.selectedResource);
      this._waypoints$.next(waypoints);
      const polygons = PolygonParser.collectPolygons(this._registeredComponents, this.selectedResource);
      this._polygons$.next(polygons);
    });
  }

  public destroy(): void {
    this.workflowStateService.destroy();
    this.destroy$.next();
  }

  public nextWaypointMode(mode: boolean) {
    this._waypointMode$.next(mode);
  }

  public nextWaypointsAction$(input: WaypointAction): Observable<WaypointAction> {
    this._waypointsAction$.next(input);
    return this.waypointsAction$;
  }
}
