import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeItem } from '../../models';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-data-sources-tree-item',
  templateUrl: './data-sources-tree-item.component.html',
  styleUrls: ['./data-sources-tree-item.component.scss'],
})
export class DataSourcesTreeItemComponent {
  @Input()
  public item: TreeItem;

  @Input()
  public insertDataURIMode: boolean;

  @Input()
  public showSelectedChildCount: boolean = true;

  @Output()
  public selectionChange: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public dataURIChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public dataItemChange: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();

  constructor(private cdr: ChangeDetectorRef, private toast: ToastService) {}

  public onItemSelect(_item: TreeItem) {
    this.item.toggleSelected();

    this.selectionChanged();
  }

  public onInsertDataURI(item: TreeItem) {
    this.dataItemChange.next(item);
    this.dataURIChange.next(item.path);
  }

  public onDataURIChange(dataURI: string) {
    this.dataURIChange.next(dataURI);
  }

  public onDataItemChange(item: TreeItem) {
    this.dataItemChange.next(item);
  }

  public onChildrenSelectionChange(_item: TreeItem) {
    this.selectionChanged();
  }

  public onShowHideChildren(item: TreeItem) {
    item.toggleStatus();
    this.cdr.detectChanges();
  }

  public onCopyURI(item: TreeItem) {
    navigator.clipboard.writeText(item.path).then(() => {
      this.toast.short('Copied to clipboard');
    });
  }

  private selectionChanged() {
    this.selectionChange.next();
  }
}
