import { Injectable } from '@angular/core';
import { RocosSdkClientService } from '../rocos-sdk-client';
import type * as mapboxgl from 'mapbox-gl';
import { AppService } from '../app';
import { PlanLayerType, getLatestPlanByType, getOrthomosaicTilesUrl } from '@shared/utils/plan-utils';
import { environment } from '@env/environment';
import { PlansService } from '../plans/plans.service';
import { first, mergeMap, tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

const DEFAULT_TILE_SIZE = 256;
const ORTHO_LAYER_ID = 'ortho_layer';
const OVERLAY_LAYER_ID = 'overlay_layer';
const ROUTE_LAYER_ID = 'route';

@Injectable({ providedIn: 'root' })
export class MapboxLayersService {
  constructor(private appService: AppService, private sdk: RocosSdkClientService, private plansService: PlansService) {}

  public async addLatestOrthoLayer(map: mapboxgl.Map) {
    return lastValueFrom(
      this.plansService.allPlansLoaded$.pipe(
        mergeMap(() => this.plansService.selectPlans(PlanLayerType.Orthomosaic)),
        first(),
        tap((plans) => {
          const plan = getLatestPlanByType(plans, PlanLayerType.Orthomosaic);
          const url = getOrthomosaicTilesUrl(plan);

          if (!url) {
            console.warn('No ortho tiles found');
            return;
          }

          this.addSourceLayer(map, ORTHO_LAYER_ID, [url]);
        }),
      ),
    );
  }

  public async addLatestOverlayLayer(map: mapboxgl.Map) {
    const overlays = await this.sdk.client.getIntegrationService().getOverlays(this.appService.projectId);

    if (!overlays?.length) return;

    const firstOverlay = overlays.sort((a, b) => b.date_creation - a.date_creation)?.[0];

    if (!firstOverlay?.tile_layer?.url) return;

    const host = `${environment.droneDeploy.tilesApi}filters:color_to_alpha(FFFFFF,-1,false,0)`;
    const url = `${host}/${firstOverlay.tile_layer.url}?jwt_token=${firstOverlay.tile_layer.jwt_token}`;

    this.addSourceLayer(map, OVERLAY_LAYER_ID, [url]);
  }

  public removeAllLayers(map: mapboxgl.Map) {
    map.removeLayer(OVERLAY_LAYER_ID);
    map.removeSource(OVERLAY_LAYER_ID);
    map.removeLayer(ORTHO_LAYER_ID);
    map.removeSource(ORTHO_LAYER_ID);
  }

  private addSourceLayer(map: mapboxgl.Map, id: string, urls: string[]) {
    map.addSource(id, {
      'type': 'raster',
      'tiles': urls,
      DEFAULT_TILE_SIZE,
      'layer': id,
    });

    map.addLayer({
      id,
      'type': 'raster',
      'source': id,
      'visibility': 'visible',
      'source-layer': id,
    });

    // Check if a route layer exists and move ortho/map below route layer
    if (map.getLayer(ROUTE_LAYER_ID)) {
      map.moveLayer(id, ROUTE_LAYER_ID);
    }
  }
}
