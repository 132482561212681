import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MonacoEditorModule } from '@shared-modules/monaco-editor';
import { SharedComponentsModule } from '../components';
import { MaterialModule } from '../material';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { WidgetSharedModule } from './shared';
import { WidgetDonutGaugeComponent } from './widget-donut-gauge';
import { WidgetTableComponent } from './widget-table';
import { WidgetMultipleLinesChartComponent } from './widget-multiple-lines-chart';
import { WidgetNumberComponent } from './widget-number';
import { WidgetHorizonComponent } from './widget-horizon';
import { WidgetHeadingComponent } from './widget-heading';
import { WidgetRobotControlComponent } from './widget-robot-control';
import { WidgetApmComponent } from './widget-apm';
import { WidgetLineGraphComponent } from './widget-line-graph';
import { WidgetLineGraphBatchedComponent } from './widget-line-graph-batched';
import { WidgetRobotstatusComponent } from './widget-robotstatus';
import { WidgetHtmlComponent } from './widget-html';
import { WidgetJavascriptComponent } from './widget-javascript';
import { WidgetImageComponent } from './widget-image';
import { WidgetOperationComponent } from './widget-operation';
import { WidgetMapModule } from './widget-map';
import { WidgetCustomComponent } from './widget-custom';
import { CustomWidgetModule } from '@shared-modules/custom-widget';
import { WidgetPickCoordinatesComponent } from './widget-pick-coordinates/widget-pick-coordinates.component';
import { WidgetTableV2Component } from './widget-table-v2';
import { DebugInfosComponent } from './widget-video/components/debug-infos/debug-infos.component';
import { WidgetVideoComponent } from './widget-video';
import { WidgetGamepadComponent } from './widget-gamepad/widget-gamepad.component';
import { WidgetButtonGroupComponent } from './widget-button-group/widget-button-group.component';
import { WidgetAudioComponent } from './widget-audio/widget-audio.component';
import { AudioWidgetModule } from '@shared-modules/widgets/audio-widget.module';
import { GamepadWidgetModule } from '@shared-modules/widgets/gamepad-widget.module';
import { WidgetOperationLocalComponent } from './widget-operation/widget-operation-local';
import { WidgetOperationGlobalComponent } from './widget-operation/widget-operation-global';
import { AddJavascriptDialogComponent } from './widget-javascript/add-javascript-dialog/add-javascript-dialog.component';
import { WidgetWebComponentComponent } from './widget-web-component/widget-web-component.component';

const components = [
  WidgetDonutGaugeComponent,
  WidgetMultipleLinesChartComponent,
  WidgetNumberComponent,
  WidgetRobotstatusComponent,
  WidgetLineGraphComponent,
  WidgetHorizonComponent,
  WidgetHeadingComponent,
  WidgetRobotControlComponent,
  WidgetApmComponent,
  WidgetVideoComponent,
  WidgetTableComponent,
  WidgetTableV2Component,
  WidgetHtmlComponent,
  WidgetJavascriptComponent,
  WidgetImageComponent,
  WidgetLineGraphBatchedComponent,
  WidgetCustomComponent,
  WidgetGamepadComponent,
  WidgetButtonGroupComponent,
  WidgetAudioComponent,
  WidgetPickCoordinatesComponent,
  DebugInfosComponent,
  WidgetOperationComponent,
  WidgetOperationLocalComponent,
  WidgetOperationGlobalComponent,
  WidgetWebComponentComponent,
  AddJavascriptDialogComponent,
];
@NgModule({
  imports: [
    CommonModule,
    NgCircleProgressModule,
    MaterialModule,
    PipesModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MonacoEditorModule,
    WidgetSharedModule,
    WidgetMapModule,
    LoadingContentModule,
    DirectivesModule,
    CustomWidgetModule,
    GamepadWidgetModule,
    AudioWidgetModule,
    OverlayModule,
  ],
  declarations: [...components],
  exports: [...components, WidgetMapModule],
})
export class WidgetsModule {}
