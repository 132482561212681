import { Parser } from 'expr-eval';

export class VariableValuePair {
  variable: string;
  value: any;

  constructor(variable: string, value: any) {
    this.variable = variable;
    this.value = value;
  }
}

export class EvalValue {
  static eval(value: any, expression?: string): any {
    let val: any = value;

    try {
      const expr = Parser.parse(expression);
      val = expr.evaluate({
        '$value': value,
        '$msg': value,
      });
    } catch (e) {
      // do nothing
    }
    return val;
  }

  static replace(template: string, variableString: string, targetValue: any): string {
    let replacedString = template;

    const reStr = `\\${variableString}`;

    const re = new RegExp(reStr, 'g');
    replacedString = replacedString.replace(re, targetValue);

    return replacedString;
  }

  static replaceAll(template: string, variableValuePairs: VariableValuePair[]): string {
    let targetString = template;
    if (variableValuePairs?.length > 0) {
      variableValuePairs.forEach((item) => {
        targetString = EvalValue.replace(targetString, item.variable, item.value);
      });
    }

    return targetString;
  }
}
