import type { ButtonDef } from '../../models/gamepad/gamepad';
import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ButtonDefWithValue } from '../../models';
import { Gamepad } from '../../models';

@Component({
  selector: 'app-gamepad-preview',
  templateUrl: './gamepad-preview.component.html',
  styleUrls: ['./gamepad-preview.component.scss'],
})
export class GamepadPreviewComponent implements OnChanges {
  @Input()
  public gamepadTemplate: Gamepad;

  @Input()
  public pressedValues: ButtonDefWithValue[];

  public pressedButtonsObject: {
    [name: string]: ButtonDefWithValue;
  } = {};

  public get buttons(): ButtonDef[] {
    if (this.gamepadTemplate?.mapping) {
      return [...this.gamepadTemplate.mapping.axes, ...this.gamepadTemplate.mapping.buttons];
    }

    return [];
  }

  ngOnChanges(changes: SimpleChanges) {
    const obj = {};
    if (changes['pressedValues'] && this.pressedValues) {
      this.pressedValues.forEach((value) => {
        obj[value.type] = value;
      });
    }

    this.pressedButtonsObject = obj;
  }
}
