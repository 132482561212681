<app-widget-base
  [heading]="heading"
  [type]="type"
  [editing]="editing"
  [dataURI]="dataURI"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div class="main-content" [ngClass]="theme">
    <!-- Display as Columns -->
    <ng-container *ngIf="tableFormat === 'columns'">
      <table class="table">
        <ng-container *ngIf="table.header && table.header.length >= 1 && table.header[0] !== '_NO_HEADER_'">
          <thead>
            <tr>
              <th *ngFor="let colName of table.header" [width]="100 / table.header.length + '%'">
                <span>{{ colName }}</span>
              </th>
            </tr>
          </thead>
        </ng-container>
        <tbody>
          <tr *ngFor="let item of table.content">
            <td *ngFor="let colName of table.header" class="value-cell">
              <span>{{ item[colName] | json }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <!-- Display as Rows -->
    <ng-container *ngIf="tableFormat === 'rows'">
      <table class="table">
        <tr *ngFor="let colName of table.header">
          <ng-container *ngIf="table.header && table.header.length === 1 && table.header[0] === '_NO_HEADER_'">
            <td *ngFor="let item of table.content" class="value-cell" [width]="100 / table.content.length + '%'">
              <span>{{ item[colName] | json }}</span>
            </td>
          </ng-container>
          <ng-container *ngIf="table.header && table.header.length >= 1 && table.header[0] !== '_NO_HEADER_'">
            <th [width]="100 / (table.content.length + 1) + '%'">{{ colName }}</th>

            <td *ngFor="let item of table.content" class="value-cell" [width]="100 / (table.content.length + 1) + '%'">
              <span>{{ item[colName] | json }}</span>
            </td>
          </ng-container>
        </tr>
      </table>
    </ng-container>

    <!-- Display as Rows -->
    <ng-container *ngIf="tableFormat === 'raw-json'">
      <pre class="raw-json-block">{{ content | json }}</pre>
    </ng-container>
  </div>
</app-widget-base>
