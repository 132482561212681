import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-widget',
  templateUrl: './custom-widget.component.html',
  styleUrls: ['./custom-widget.component.scss'],
})
export class CustomWidgetComponent implements OnChanges, OnDestroy {
  @ViewChild('iframe', {
    static: true,
  })
  iframe: ElementRef;

  @Input()
  htmlCode: string;

  @Input()
  cssCode: string;

  @Input()
  jsCode: string;

  @Input()
  editMode: boolean = false;

  @Input()
  previewMode: boolean = false;

  @Input() callsign: string = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['htmlCode'] || changes['cssCode'] || changes['jsCode']) {
      this.onCodeChange();
    }
  }

  ngOnDestroy() {
    if (this.iframe.nativeElement) {
      const ele = this.iframe.nativeElement;

      // backward compatibility
      if (ele?.contentWindow?.__onDestory) {
        ele.contentWindow.__onDestory();
      }

      if (ele?.contentWindow?.__onDestroy) {
        ele.contentWindow.__onDestroy();
      }
    }
  }

  private onCodeChange() {
    this.applyCode();
  }

  private applyCode(): void {
    if (this.iframe?.nativeElement) {
      const ele = this.iframe.nativeElement;

      const newContent = `
        <style>
          body {
            margin: 0px;
          }
          ${this.cssCode}
        </style>
        <script>
          window.rocos = parent.rocos;
        </script>
        ${this.htmlCode}
        <script>
          ${this.jsCode}
        </script>
      `;
      try {
        ele.contentDocument.location.reload(true);
        setTimeout(() => {
          ele.contentDocument.head.innerHTML = '';
          ele.contentDocument.body.innerHTML = '';
          ele.contentDocument.write(newContent);
          ele.contentWindow.postMessage(this.callsign, '*');
        }, 0);
      } catch (e) {
        console.log('Got an error when parsing codes', e);
      }
    }
  }
}
