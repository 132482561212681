import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { User } from '@shared/models';
import { UserService } from '@shared/services';

@Component({
  selector: 'app-navbar-user-item',
  templateUrl: './navbar-user-item.component.html',
  styleUrls: ['./navbar-user-item.component.scss'],
})
export class NavbarUserItemComponent implements OnInit {
  public user: User;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.user = this.userService.user;
  }
}
