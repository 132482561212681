import { Directive, EventEmitter, Output, HostListener, Input, ElementRef, Renderer2 } from '@angular/core';

export interface ICoordinates {
  x: string;
  y: string;
}

export interface IPickerCoordinates {
  picker: ICoordinates;
}

export interface IResolution {
  height: number;
  width: number;
}

export interface IPickerPositionNDimension {
  height: string;
  width: string;
  top: string;
  left: string;
}
@Directive({
  selector: '[appCoordinatePicker]',
})
export class CoordinatePickerDirective {
  @Input() public animation: boolean = true;
  @Input() public enable = false;
  @Input() public resolution: IResolution;
  @Output()
  public coordinates: EventEmitter<IPickerCoordinates> = new EventEmitter<IPickerCoordinates>();

  private idClickEffectContainer = 'clickEffectContainer';

  public constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  /**
   * @description Because we want to scale up the video as the widget grow / resize
   * We have to compute manually the video dimensions since the <video></video>
   * width & height will include the video padding (due to the aspect/ratio).
   * We, obviously don't want the padding to be included on the picker dimensions
   * Otherwise, good luck to get accurate outputs.
   *
   * Also, the browser only give us the original video size
   * and not the video size once scaled, unfortunately.
   */
  public static computePickerPositionNDimension(
    targetHeight: number,
    targetWidth: number,
    containerHeight: number,
    containerWidth: number,
  ): IPickerPositionNDimension {
    // Ratio at which the browser is resizing the video
    const ratio =
      containerHeight / targetHeight < containerWidth / targetWidth
        ? containerHeight / targetHeight
        : containerWidth / targetWidth;

    // Picker Video & Height
    const ph = targetHeight * ratio;
    const pw = targetWidth * ratio;

    // Picker Position
    const pTop = containerHeight - ph > 0 ? (containerHeight - ph) / 2 : 0;
    const pLeft = containerWidth - pw > 0 ? (containerWidth - pw) / 2 : 0;

    return {
      height: ph + 'px',
      width: pw + 'px',
      top: pTop + 'px',
      left: pLeft + 'px',
    };
  }

  @HostListener('mouseup', ['$event'])
  public onListenerTriggered(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.id === this.idClickEffectContainer) {
      return;
    }

    if (this.enable) {
      this.coordinates.emit(this.formatValues(event));

      if (this.animation) {
        this.handleAnimation(event);
      }
    }
  }

  public handleAnimation(event: MouseEvent): void {
    const el = this.renderer.createElement('div');
    this.renderer.setAttribute(el, 'id', this.idClickEffectContainer);
    this.renderer.addClass(el, 'clickEffect');
    this.renderer.setStyle(el, 'top', event.offsetY - 5 + 'px');
    this.renderer.setStyle(el, 'left', event.offsetX - 5 + 'px');
    this.renderer.appendChild(this.elRef.nativeElement, el);
    this.renderer.listen(el, 'animationend', () => this.renderer.removeChild(this.elRef.nativeElement, el));
  }

  public formatValues(mouseEvent: MouseEvent): IPickerCoordinates {
    const output = {
      picker: {},
    } as IPickerCoordinates;

    // Pixel Picking - Ratio 0 -> 1
    output.picker.x = ((mouseEvent.offsetX * (100 / this.elRef.nativeElement.offsetWidth)) / 100).toFixed(3);
    output.picker.y = ((mouseEvent.offsetY * (100 / this.elRef.nativeElement.offsetHeight)) / 100).toFixed(3);

    return output;
  }
}
