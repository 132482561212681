import { Injectable } from '@angular/core';
import { capitalize } from 'lodash';
import type { ControlAckMessage } from '@team-rocos/rocos-js';
import { ResponseLevel, ResultStatus } from '@team-rocos/rocos-js';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FunctionService } from '../function';
import { RocosClientService } from '../rocos-client';
@Injectable({
  providedIn: 'root',
})
export class RobotControlService {
  private ackTimeoutInSeconds = 10;

  constructor(private rocosClientService: RocosClientService, private functionService: FunctionService) {}

  sendMessagesByPayloads(payloads: any[], projectId: string, callsign: string) {
    const sendCommandWithPayload = (payload: any) => {
      switch (payload.type.id) {
        case 'agentRos':
        case 'agentRosBridge':
        case 'agentMavlink':
        case 'agentHttp':
        case 'agentTcp':
          this.sendAgentCommand(projectId, callsign, payload.destination, payload.payload, payload.meta);
          break;
        case 'slackMessage':
          this.sendSlackMessage(payload.webhook, payload.payload);
          break;
        case 'serverFunction':
          this.runServerFunction(projectId, payload.functionId, payload.payload);
          break;
        default:
          break;
      }
    };

    if (payloads?.length > 0) {
      let timeout = 0;
      payloads.forEach((payload) => {
        const milliseconds = payload?.waitForMilliseconds ? payload.waitForMilliseconds : 0;

        setTimeout(() => {
          sendCommandWithPayload(payload);
        }, timeout);

        timeout += milliseconds;
      });
    }
  }

  sendMessagesByPayloadsWithAck(payloads: any[], projectId: string, callsign: string): Observable<any> {
    let timer;
    const sendCommandWithPayload = (payload: any) => {
      return new Observable((observer: Observer<any>) => {
        switch (payload.type.id) {
          case 'agentRos':
          case 'agentRosBridge':
          case 'agentMavlink':
          case 'agentHttp':
          case 'agentTcp':
            timer = setTimeout(() => {
              observer.error(new Error('Timeout'));
              observer.complete();
            }, this.ackTimeoutInSeconds * 1000);

            this.sendAgentCommandWithAck(
              projectId,
              callsign,
              payload.destination,
              payload.payload,
              payload.meta,
            ).subscribe(
              (msg) => {
                const message = capitalize(msg.message);
                if (msg.stage === 'final') {
                  if (msg.success) {
                    observer.next(`${message}`);
                    observer.complete();
                  } else {
                    const errorMessage = message ? message : 'Failed.';
                    observer.error(new Error(errorMessage));
                    observer.complete();
                  }
                }
                clearTimeout(timer);
              },
              (_err) => {
                observer.error(new Error());
                observer.complete();
              },
            );
            break;
          case 'slackMessage':
            this.sendSlackMessage(payload.webhook, payload.payload);
            observer.next('Message sent.');
            observer.complete();
            break;
          case 'serverFunction':
            this.runServerFunction(projectId, payload.functionId, payload.payload);
            observer.next('Message sent.');
            observer.complete();
            break;
          case 'clientDelay':
            setTimeout(
              () => {
                observer.next('Delayed.');
                observer.complete();
              },
              payload.waitForMilliseconds ? payload.waitForMilliseconds : 0,
            );
            break;
          default:
            break;
        }
      });
    };

    return Observable.create((observer: Observer<any>) => {
      const func = async () => {
        let error = null;
        let message = null;

        if (payloads?.length > 0) {
          for (let i = 0; i < payloads.length && !error; i++) {
            const payload = payloads[i];
            await sendCommandWithPayload(payload)
              .toPromise()
              .then((res) => {
                message = res;
              })
              .catch((err) => {
                error = err;
              });
          }
        } else {
          error = new Error('Payloads are empty.');
        }

        if (!error) {
          observer.next(message);
          observer.complete();
        } else {
          observer.error(error);
          observer.complete();
        }
      };

      func();
    });
  }

  sendSlackMessage(webhookURL: string, message: string) {
    this.rocosClientService.rocosClient.http.post(webhookURL, message).pipe(first()).subscribe();
  }

  sendAgentCommand(projectId: string, callsign: string, destination: string, payload: string, metaStr?: string) {
    let meta = {};
    try {
      meta = JSON.parse(metaStr);
    } catch {
      meta = {};
    }

    this.rocosClientService.sendCommand(projectId, callsign, destination, payload, meta);
  }

  runServerFunction(projectId: string, functionId: string, payload: any) {
    return this.functionService.run(projectId, functionId, payload).pipe(first()).subscribe();
  }

  sendAgentCommandWithAck(
    projectId: string,
    callsign: string,
    destination: string,
    payload: string,
    metaStr?: string,
  ): Observable<ControlAckMessage> {
    let meta = {};
    try {
      meta = JSON.parse(metaStr);
    } catch {
      meta = {};
    }

    return this.rocosClientService.sendCommandWithAck(projectId, callsign, destination, payload, meta);
  }

  public sendCommandV2WithAck(
    projectId: string,
    callsign: string,
    commandId: string,
    parameters: {
      [name: string]: string;
    },

    // Hard code the absolute command timeout to 10 minutes if not timeoutMs passed in.
    // The main timeout watcher should rely on the ack timeout watcher.
    timeoutMs: number = 60 * 10 * 1000,
  ): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      // Requires agent to keep sending ack back to client to avoid time out when executing the long running command
      const startAckTimeoutWatcher = () => {
        return setTimeout(() => {
          observer.error(new Error('Command acknowledgement timeout'));
          observer.complete();
        }, this.ackTimeoutInSeconds * 1000);
      };

      let ackTimeoutWatcher = startAckTimeoutWatcher();

      this.rocosClientService.ramboService
        .commandInvokeRequest(projectId, callsign, commandId, parameters, timeoutMs, ResponseLevel.ALL)
        .subscribe((res) => {
          if (res?.commandResponse) {
            // TODO: [ROCOS-1995] More granular handling for the command ack
            // Cancel the ack timeout watcher if response returned
            clearTimeout(ackTimeoutWatcher);

            if (res.commandResponse.result) {
              const result = res.commandResponse.result;
              const message = capitalize(result.message);
              if (result.status === ResultStatus.COMPLETE_SUCCESS) {
                observer.next(message);
              } else {
                observer.error(new Error(message));
              }
              observer.complete();
            } else {
              // Start the ack timeout watcher again if the response is not result
              ackTimeoutWatcher = startAckTimeoutWatcher();
            }
          }
        });
    });
  }

  sendCommandV2WithoutAck(
    projectId: string,
    callsign: string,
    commandId: string,
    parameters: {
      [name: string]: string;
    },

    // Hard code the absolute command timeout to 10 minutes if not timeoutMs passed in.
    // The main timeout watcher should rely on the ack timeout watcher.
    timeoutMs: number = 60 * 10 * 1000,
  ) {
    this.rocosClientService.ramboService
      .commandInvokeRequest(projectId, callsign, commandId, parameters, timeoutMs, ResponseLevel.NONE)
      .pipe(first())
      .subscribe(() => {
        // do nothing
      });
  }
}
