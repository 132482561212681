<div class="scale-buttons">
  <button class="decrease-button" (click)="this.decrementScale()">
    <span class="ri-minus"></span>
  </button>
  <span class="scale-value"
    ><span>{{ this.scale$ | async }}% </span></span
  >
  <button class="increase-button" (click)="this.incrementScale()">
    <span class="ri-add"></span>
  </button>
</div>
