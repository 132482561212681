import { NgModule } from '@angular/core';
import { SaveDirective } from '../directives/save.directive';
import { TestIdDirective } from '../directives/test-id.directive';
import { DevelopingDirective } from './developing/developing.directive';
import { AdminOnlyDirective } from './admin-only.directive';
import { ExperimentalDirective } from './experimental.directive';
import { DoubleClickDirective } from './double-click.directive';
import { CoordinatePickerDirective } from './coordinate-picker.directive';
import { DragDropDirective } from './drag-and-drop.directive';
import { ExternalLinkDirective } from './external-link/external-link.directive';
import { LegacyUsersOnlyDirective } from './legacy-users-only/legacy-users-only.directive';
import { RequireAgentVersionDirective } from './require-agent-version/require-agent-version.directive';
import { ResizedDirective } from './resize-event.directive';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';
import { CopyTextDirective } from './copy-text.directive';

const directives = [
  AdminOnlyDirective,
  CoordinatePickerDirective,
  CopyTextDirective,
  DevelopingDirective,
  DoubleClickDirective,
  DragDropDirective,
  ExperimentalDirective,
  ExternalLinkDirective,
  FeatureFlagDirective,
  LegacyUsersOnlyDirective,
  RequireAgentVersionDirective,
  ResizedDirective,
  SaveDirective,
  TestIdDirective,
];

@NgModule({
  imports: [],
  declarations: [...directives],
  exports: [...directives],
})
export class DirectivesModule {}
