import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { GotoService, UserService } from '../services';

export const AdminGuard: CanActivateFn = () => {
  const currentLoggedInUser = inject(UserService).user;

  if (currentLoggedInUser?.isAdmin) {
    return true;
  }

  inject(GotoService).defaultAccountPage();
  return false;
};
