import { Color3, Matrix, Mesh, MeshBuilder, type Scene, StandardMaterial } from '@babylonjs/core';

export interface VoxbloxData {
  id: string;
  origin: [number, number, number];
  voxelSize: number;
  voxelsPerSide: number;
  voxelCount: number;
  blockSize: number;
  voxelIndices: number[];
  voxelColors: number[];
  frameNumber: number;
}

export class Voxblox extends Mesh {
  private readonly voxelMaterial: StandardMaterial;
  private voxels?: Mesh;

  constructor(name: string, scene: Scene) {
    super(name, scene);
    this.voxelMaterial = this.initMaterial(scene);
  }

  public renderBoundUpdate(update: VoxbloxData): void {
    this.voxels?.dispose();
    if (!update.voxelIndices.length) return;

    this.voxels = MeshBuilder.CreateBox('voxel', { size: update.voxelSize }, this.getScene());
    this.voxels.parent = this;
    this.voxels.material = this.voxelMaterial;

    const matrixBuffer = new Float32Array((update.voxelIndices.length / 3) * 16);
    const colorBuffer = new Float32Array((update.voxelColors.length / 3) * 4);

    for (let i = 0; i < update.voxelIndices.length; i += 3) {
      const offset = i / 3;
      const matrix = Matrix.Translation(
        update.voxelIndices[i] * update.voxelSize - update.voxelSize / 2,
        update.voxelIndices[i + 1] * update.voxelSize - update.voxelSize / 2,
        update.voxelIndices[i + 2] * update.voxelSize - update.voxelSize / 2,
      );
      matrix.copyToArray(matrixBuffer, offset * 16);
      colorBuffer.set([update.voxelColors[i], update.voxelColors[i + 1], update.voxelColors[i + 2], 1.0], offset * 4);
    }

    this.voxels.thinInstanceSetBuffer('matrix', matrixBuffer, 16, true);
    this.voxels.thinInstanceSetBuffer('color', colorBuffer, 4, true);
  }

  private initMaterial(scene: Scene): StandardMaterial {
    let material = scene.getMaterialByName('voxbloxMaterial') as StandardMaterial;
    if (!material) {
      material = new StandardMaterial('voxbloxMaterial', scene);
      material.diffuseColor = new Color3(1, 1, 1);
      material.specularColor = new Color3(0, 0, 0);
    }
    return material;
  }
}
