<div class="rs-card">
  <div class="head">
    <div class="title">Button Actions</div>
    <div class="right-buttons">
      <button mat-flat-button class="left-icon-button" color="primary" (click)="onAddNew()">
        <span class="ri-add"></span>
        <span>Add Action</span>
      </button>
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="actions" #actionsTable>
      <!-- Action Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.name }}
        </mat-cell>
      </ng-container>

      <!-- Source Column -->
      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <!-- {{item?.source?.text}} -->
          <div class="source-name" [class.active]="pressedButtonsObject[item?.source?.type]">
            <div class="d-flex align-items-center">
              <span>{{ item?.source?.text }}</span>
              <span class="icon active-content ri-controller"></span>
            </div>

            <div class="bar">
              <ng-container
                *ngIf="pressedButtonsObject[item?.source?.type] && pressedButtonsObject[item?.source?.type].pressed"
              >
                <app-gamepad-value-bar [value]="pressedButtonsObject[item?.source?.type].value"></app-gamepad-value-bar>
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Frequency Column -->
      <ng-container matColumnDef="frequency">
        <mat-header-cell *matHeaderCellDef> Interval </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.frequency }}
        </mat-cell>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.triggerType }}
        </mat-cell>
      </ng-container>

      <!-- Control ID Column -->
      <ng-container matColumnDef="commandId">
        <mat-header-cell *matHeaderCellDef> Command </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.commandId }}
        </mat-cell>
      </ng-container>

      <!-- Editing Column -->
      <ng-container matColumnDef="editing">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let idx = index">
          <div (click)="$event.stopPropagation()" class="row-buttons">
            <button mat-icon-button (click)="onEdit(item, idx)">
              <span class="ri-edit"></span>
            </button>
          </div>
          <div (click)="$event.stopPropagation()" class="row-buttons">
            <button mat-icon-button (click)="onRemove(item, idx)">
              <span class="ri-delete"></span>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" class="hover-row"></mat-row>
    </table>
  </div>
</div>
<pre *appDeveloping>{{ json | json }}</pre>
