import { Injectable } from '@angular/core';
import type { Project, User } from '../../models';
import { Angulartics2 } from 'angulartics2';

@Injectable({
  providedIn: 'root',
})
export class AppTrackingService {
  constructor(private angulartics2: Angulartics2) {}

  setUser(user: User) {
    this.angulartics2.setUserProperties.next({
      userId: user.id,
      name: user.fullName,
      email: user.email,
    });
  }

  newProjectCreated(proj: Project) {
    this.angulartics2.eventTrack.next({
      action: 'New Project Created',
      properties: {
        title: proj?.name,
        description: proj?.description,
        id: proj?.id,
      },
    });
  }

  projectRemoved(proj: Project) {
    this.angulartics2.eventTrack.next({
      action: 'Project Removed',
      properties: {
        title: proj?.name,
        description: proj?.description,
        id: proj?.id,
      },
    });
  }
}
