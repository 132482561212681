import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonType } from '../shared';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-base-button.component.html',
  styleUrls: ['./loading-base-button.component.scss'],
})
export class LoadingBaseButtonComponent {
  @Input()
  public isLoading: boolean;
  @Input()
  public buttonType: ButtonType = 'submit';
  @Input()
  public disabled: boolean = false;
  @Input()
  public fullWidth: boolean = false;
  @Input()
  public color: string = 'primary';

  @Output()
  public clicked: EventEmitter<any> = new EventEmitter<any>();

  public onButtonClick(event: any) {
    if (!this.isLoading) {
      this.clicked.next(event);
    }
  }
}
