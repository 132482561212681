import { UITabLinks } from './ui-tab-links';

export const EXPERIMENTAL_FEATURE = {
  DeployVirtualRobots: 'deployVirtualRobots',
  OperationEditMode: 'operationEditMode',
  OperationMissionsPanel: 'operationMissionsPanel',
  OperationsList: 'operationList',
  OperationPageJSWidgets: 'operationPageJSWidgets',
  OperationCustomPage: 'operationCustomPage',
  SidebarAdminTools: 'sidebarAdminTools',
  LineGraphV2Widget: 'lineGraphV2Widget',
  StorageStreamWidgetsTab: 'storageStreamWidgetsTab',
  RobotSettingsTab: `Robot.Tabs.${UITabLinks.Settings}`,
  ControlsTab: `Robot.Tabs.${UITabLinks.Controls}`,
  AutomateTab: `Robot.Tabs.${UITabLinks.Automate}`,
  MapTab: `Robot.Tabs.${UITabLinks.RobotMaps}`,
  CustomOperationEdit: 'customOperationEdit',
  MapEditor: 'mapEditor',
  CallService: 'callService',
  Automate: 'Automate',
  ButtonRunJs: 'buttonRunJs',
  CloudFlow: 'cloudFlow',
};
