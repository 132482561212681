import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '@shared/services';
import { ScriptService } from '@shared/services/script';
import { format } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourierService {
  public courierLoaded$ = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private userService: UserService,
    private scriptService: ScriptService,
  ) {}

  public async initialiseCourier(token: string) {
    if (window.courierConfig) {
      if (isDevMode()) console.warn('Courier already configured, skipping courier init');
      return;
    }

    const clientKey = environment.courier.clientKey;
    if (!clientKey) {
      if (isDevMode()) console.warn('Courier key not provided. Not loading courier');
      return;
    }

    // const courierToken = await this.getUserToken(token);
    const userSignature = await this.getUserSignature(token);

    window.courierConfig = {
      clientKey,
      // authorization: courierToken,
      userSignature,
      userId: this.userService.user.id,
      components: {
        inbox: {
          title: `Inbox`,
          autoMarkAsRead: false,
          formatDate: (date: Date) => {
            return format(new Date(date), 'dd MMM h:mma');
          },
        },
      },
    };

    window.courierAsyncInit = () => {
      this.courierLoaded$.next(true);
    };

    return this.loadCourier();
  }

  // GRP-1142 - Leaving this here as HMAC signature is deprecated in favour of JWT but DD is still using HMAC for now
  public async getUserToken(token: string) {
    const res = await this.http
      .get<{ token: string }>(`${environment.api.url}/notification/token`, {
        headers: new HttpHeaders({ 'Authorization': `bearer ${token}` }),
      })
      .toPromise();

    return res.token;
  }

  public async getUserSignature(token: string) {
    const res = await this.http
      .get<{ hmac: string }>(`${environment.api.url}/notification/signature`, {
        headers: new HttpHeaders({ 'Authorization': `bearer ${token}` }),
      })
      .toPromise();

    return res.hmac;
  }

  private loadCourier(): Promise<void> {
    return this.scriptService.loadScript('https://courier-components-xvdza5.s3.amazonaws.com/v3.1.3.js');
  }
}
