import type { SimpleBinding } from '../../services/threeD/primitives/visualizer';
import { WidgetBase } from './widget-base';

export class WidgetHeading extends WidgetBase {
  public mapHeading: number;
  public robotHeading: number;
  public headingBinding: SimpleBinding;

  public static fromModel(model: any): WidgetHeading {
    const widget = new WidgetHeading();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    if (model.robotHeading !== undefined) {
      this.robotHeading = model.robotHeading;
    } else if (model.heading !== undefined) {
      this.robotHeading = model.heading;
    }
    this.mapHeading = model.mapHeading;
    this.headingBinding = model.headingBinding;
  }
}
