import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomWidgetComponent } from './custom-widget.component';

@NgModule({
  declarations: [CustomWidgetComponent],
  exports: [CustomWidgetComponent],
  imports: [CommonModule],
})
export class CustomWidgetModule {}
