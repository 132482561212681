import type { Plan } from '@dronedeploy/rocos-js-sdk';

export enum PlanLayerType {
  Orthomosaic = 'Orthomosaic',
  Tiled3DMesh = 'Tiled 3D Mesh',
  Tiled3DPointCloud = 'Tiled 3D Point Cloud',
}

export const getLatestPlanByType = (plans: Plan[], type: PlanLayerType): Plan | undefined => {
  return plans?.find((p) => p.layer?.layers?.find((pl) => pl.name === type));
};

export const getOrthomosaicTilesUrl = (plan: Plan): string | undefined => {
  const layer = getPlanLayerByType(plan, PlanLayerType.Orthomosaic);
  return layer?.host
    .replace(`/filters:crop('{setting_crop_polygon}')`, ``)
    .replace(`{url}`, layer.url)
    .replace(`nickponline.`, '');
};

export const getPlanLayerByType = (plan: Plan, type: PlanLayerType) => {
  return plan?.layer?.layers?.find((pl) => pl.name === type);
};
