<ng-container *ngIf="linkType === 'link'">
  <a [href]="link" target="_blank" appExternalLink>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="linkType === 'primary-button'">
  <a [href]="link" mat-flat-button color="primary" target="_blank" appExternalLink>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="linkType === 'stroked-button'">
  <a [href]="link" mat-stroked-button color="primary" target="_blank" appExternalLink>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="linkType === 'button'">
  <a [href]="link" mat-button color="basic" target="_blank" appExternalLink>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
