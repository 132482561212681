import { UserService } from '@shared/services';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-agent-callback',
  templateUrl: './agent-callback.component.html',
  styleUrls: ['../styles.scss', './agent-callback.component.scss'],
})
export class AgentCallbackComponent implements OnInit {
  public code: string;
  public codeChallenge: string;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['code']) {
        this.code = params['code'];
      }
      if (params['code_challenge']) {
        this.codeChallenge = params['code_challenge'];

        this.onCodeChallengeChanged();
      }
    });
  }

  private onCodeChallengeChanged() {
    this.userService
      .createInvitation({
        type: 'AGENTINSTALL',
        codeChallenge: this.codeChallenge,
      } as any)
      .pipe(first())
      .subscribe((res) => {
        if (res?.inviteCode) {
          this.code = res.inviteCode;
        }
      });
  }
}
