<span *ngIf="isLoading">
  <svg *ngIf="icon === 'spinner'" class="spinner" viewBox="0 0 50 50" [ngClass]="[theme, size]">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>

  <svg *ngIf="icon === 'arrows'" class="arrows" viewBox="0 0 50 50" [ngClass]="[theme, size]">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.1007 18.679C42.3778 18.4248 42.8159 18.4754 43.0278 18.786L49.1139 27.7085C49.3045 27.9879 49.2347 28.3687 48.9574 28.5623L46.1834 30.499C45.907 30.692 45.527 30.6275 45.3297 30.3543L44.2522 28.8618C42.5546 35.8398 37.3073 41.3451 30.5187 43.2706C23.9725 45.1273 16.9705 43.3781 12.0107 38.693L11.4681 38.1604L14.9207 34.6332C18.6361 38.4279 24.0597 39.8993 29.1314 38.4885C33.9917 37.1365 37.8141 33.3362 39.2806 28.4506L39.4585 27.8073L37.8541 29.2794C37.5994 29.5131 37.2024 29.492 36.9739 29.2327L34.5232 26.4515C34.301 26.1994 34.3214 25.8158 34.569 25.5887L42.1007 18.679Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9789 6.75041C26.5431 4.8596 33.5788 6.60156 38.5584 11.3038L39.1031 11.8384L35.6489 15.364C31.9314 11.5694 26.5057 10.0999 21.4334 11.5138C16.5724 12.8687 12.7513 16.6725 11.2878 21.5604L11.1104 22.204L12.8788 20.6654C13.1396 20.4385 13.5358 20.47 13.7574 20.7352L16.1366 23.5819C16.3521 23.8397 16.3217 24.2226 16.0682 24.4432L8.38489 31.1287C8.11384 31.3645 7.69941 31.3199 7.4848 31.0317L1.00407 22.3298C0.787292 22.0387 0.867265 21.6243 1.17679 21.4348L4.20987 19.5778C4.47416 19.416 4.81745 19.4753 5.01219 19.7163L6.28491 21.2917C7.93403 14.2728 13.1715 8.71126 19.9789 6.75041Z"
    />
  </svg>
</span>
