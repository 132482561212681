import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import type { AccountSettings } from '../../models';
import { Account, User } from '../../models';
import { RocosClientService } from '../rocos-client';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  accounts$ = new BehaviorSubject<Account[] | undefined>(undefined);

  constructor(private rocosClientService: RocosClientService) {}

  public list(): Observable<Account[]> {
    return this.rocosClientService.rocosClient.user.info().pipe(
      map((res) => {
        const user = User.fromModel(res.data);
        const accounts = user?.accounts ? user.accounts : [];
        // Saving this since this API is slow
        this.accounts$.next(accounts);
        return accounts;
      }),
    );
  }

  public createAccount(accountName: string): Observable<Account> {
    return this.rocosClientService.rocosClient.user.createAccount(accountName).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public accountDetails(accountId: string): Observable<Account> {
    return this.rocosClientService.rocosClient.user.accountDetails(accountId).pipe(
      map((res) => {
        return Account.fromModel(res.data) as Account;
      }),
    );
  }

  public getAccountSettings(accountId: string): Observable<AccountSettings> {
    return this.rocosClientService.rocosClient.user.accountSettings(accountId).pipe(
      map((res) => {
        return res.data as AccountSettings;
      }),
    );
  }
}
