import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedComponentsModule } from '@shared/components';
import { DirectivesModule } from '@shared/directives';
import { MaterialModule } from '@shared/material';
import { AuthService } from '@shared/services';
import { DialogsModule } from '@shared/dialogs';
import { CoreRoutingModule } from './core-routing.module';
import { CoreHostingComponent } from './core-hosting';
import { ConsoleHostingComponent } from './console-hosting';
import { NotFoundComponent } from './not-found';
import { AgentCallbackComponent } from './agent-callback';
import { SwitchAccountComponent } from './switch-account';
import { CoreSharedComponentsModule } from './shared';
import { AgentLoginComponent } from './agent-login';
import { IENotSupportedComponent } from './ie-not-supported';
import { NoAccountComponent } from './no-account';
import { LoadingContentModule } from '@shared-modules/loading-content';
import { LoadingButtonsModule } from '@shared-modules/loading-buttons';
import { AuthOneTimeCodeComponent } from './auth-onetime-code/auth-onetime-code.component';
import { CommandPaletteModule } from '@shared-modules/command-palette';

@NgModule({
  declarations: [
    CoreHostingComponent,
    ConsoleHostingComponent,
    NotFoundComponent,
    AgentCallbackComponent,
    SwitchAccountComponent,
    AgentLoginComponent,
    IENotSupportedComponent,
    NoAccountComponent,
    AuthOneTimeCodeComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    CoreRoutingModule,
    MaterialModule,
    SharedComponentsModule,
    CoreSharedComponentsModule,
    LoadingContentModule,
    LoadingButtonsModule,
    NgCircleProgressModule.forRoot({
      showTitle: false,
      showUnits: false,
      showSubtitle: false,
      showInnerStroke: false,
      outerStrokeLinecap: 'butt',
      outerStrokeWidth: 4,
      outerStrokeColor: '#3f48e9', // $color-blue
      animation: true,
      animationDuration: 300,
      renderOnClick: false,
      startFromZero: false,
    }),
    DialogsModule,
    TranslocoModule,
    CommandPaletteModule,
  ],
  exports: [],
  providers: [AuthService],
})
export class CoreModule {}
