<div class="audio-widget">
  <div class="audio-widget-content">
    <div class="audio-widget-wave">
      <span class="ri-icon-audio icon-button active" [class.active]="active"></span>
    </div>

    <div class="invisible-audio">
      <audio #myaudio2 controls="controls" autoplay="autoplay"></audio>
    </div>

    <div class="audio-widget-volume">
      <div class="audio-widget-volume-mute" (click)="onMuteClick()">
        <span *ngIf="muted" class="ri-audio-muted" [class.muted]="muted"></span>
        <span *ngIf="!muted" class="ri-audio-on"></span>
      </div>
      <div class="audio-widget-volume-slider">
        <mat-slider min="0" max="100" step="1" [disableRipple]="true"
          ><input matSliderThumb [value]="muted ? 0 : volume" (valueChange)="onVolumeChanged($event)"
        /></mat-slider>
      </div>
    </div>
  </div>
</div>
