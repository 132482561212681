<div class="rs-dialog">
  <h2 mat-dialog-title class="title">
    Dev Debug
    <span class="right-buttons">
      <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
        <span class="ri-cancel icon-button grey-icon"></span>
      </button>
    </span>
  </h2>

  <div>
    <mat-tab-group>
      <mat-tab label="TOKEN">
        <div class="scrollable">
          <pre
            class="rs-copy-code-wrapper">{{authInfo?.token}}<app-copy-code class="copy-code-button" [copy]="authInfo?.token"></app-copy-code></pre>
        </div>
      </mat-tab>
      <mat-tab label="USER">
        <div class="scrollable">
          <pre>{{ user | json }}</pre>
        </div>
      </mat-tab>
      <mat-tab label="AUTH">
        <div class="scrollable">
          <pre>{{ authInfo | json }}</pre>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
