import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CommandPaletteService } from './command-palette.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Suggestion } from './comand-palette.types';
import { combineLatest, merge, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

enum KeyPress {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

@UntilDestroy()
@Component({
  selector: 'app-command-palette',
  templateUrl: './command-palette.component.html',
  styleUrls: ['./command-palette.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CommandPaletteComponent implements OnInit {
  @ViewChild('commandPaletteDialog', { static: true }) commandPaletteDialog: ElementRef<HTMLDialogElement>;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChildren('listItem') listItems: QueryList<ElementRef<HTMLParagraphElement>>;

  searchForm = new FormGroup({
    search: new FormControl(''),
  });

  selectionIndex = 0;

  searchChanges$ = this.searchForm.controls.search.valueChanges.pipe(distinctUntilChanged(), startWith(''));
  showLoading$ = combineLatest([this.commandPaletteService.loading$, this.commandPaletteService.initialising$]).pipe(
    map(([loading, initialising]) => loading || initialising),
  );
  suggestions$ = this.commandPaletteService.suggestions$;
  filteredSuggestions: Suggestion[] = [];
  breadcrumb$ = this.commandPaletteService.breadcrumb$;

  arrowPressed$ = new Subject<KeyPress>();

  constructor(private commandPaletteService: CommandPaletteService, private cdr: ChangeDetectorRef) {}

  @HostListener('window:keydown', ['$event'])
  listenKeyDown(event: KeyboardEvent) {
    const cmd = event.metaKey || event.ctrlKey;
    const kKey = event.key === 'K' || event.key === 'k';

    if (cmd && kKey) {
      this.commandPaletteService.open();
    }

    // handle up and down arrow
    if (event.key === KeyPress.ArrowDown || event.key === KeyPress.ArrowUp) {
      this.arrowPressed$.next(event.key as KeyPress);
    }
  }

  ngOnInit() {
    this.commandPaletteService.open$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.commandPaletteDialog.nativeElement.open) {
        return;
      }

      this.commandPaletteDialog.nativeElement.showModal();
    });

    combineLatest([this.searchChanges$, this.suggestions$])
      .pipe(
        map(([search, suggestions]) => {
          return suggestions.filter((suggestion) => suggestion.name.toLowerCase().includes(search.toLowerCase()));
        }),
        untilDestroyed(this),
      )
      .subscribe((filteredSuggestions) => {
        this.filteredSuggestions = filteredSuggestions;
        this.cdr.markForCheck();
      });

    // Reset selection index when suggestions change, or when the search term changes
    merge(this.suggestions$, this.searchChanges$)
      .pipe(
        untilDestroyed(this),
        filter(() => this.selectionIndex !== 0),
      )
      .subscribe(() => {
        this.selectionIndex = 0;
        this.cdr.markForCheck();
      });

    this.arrowPressed$.pipe(untilDestroyed(this)).subscribe((key) => {
      this.handleKeyDown(key);
    });
  }

  handleTab() {
    if (this.filteredSuggestions.length === 0) {
      // Don't stop propagation if there are no results
      return true;
    }

    const selectedSuggestion = this.filteredSuggestions[this.selectionIndex];
    if (!selectedSuggestion) {
      // Don't stop propagation if we somehow don't have a selected suggestion
      return true;
    }

    this.commandPaletteService.handleSuggestion(selectedSuggestion).then(() => {
      this.searchForm.controls.search.setValue('');
    });

    // stop propagation so we don't lose focus
    return false;
  }

  handleBackspace(value: string) {
    if (value !== '') {
      return true;
    }

    this.commandPaletteService.handleDeselection().then(() => {
      console.log('handled deselection');
    });
    return false;
  }

  handleEnter() {
    const shouldClose = this.commandPaletteService.handleAction();

    if (shouldClose) {
      this.close();
    }

    return false;
  }

  private close() {
    this.commandPaletteDialog.nativeElement.close();
  }

  private handleKeyDown(key: KeyPress) {
    switch (key) {
      case KeyPress.ArrowDown:
        if (this.selectionIndex < this.filteredSuggestions.length - 1) {
          this.selectionIndex++;
        } else {
          this.selectionIndex = 0;
        }
        break;
      case KeyPress.ArrowUp:
        if (this.selectionIndex > 0) {
          this.selectionIndex--;
        } else {
          this.selectionIndex = this.filteredSuggestions.length - 1;
        }
        break;
    }

    this.scrollIntoView();

    this.cdr.markForCheck();
  }

  private scrollIntoView() {
    const listItem = this.listItems.toArray()[this.selectionIndex];
    listItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
