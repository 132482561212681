export type TLogKind = string;

export enum ELogLevel {
  panicLevel = 0,
  fatalLevel = 1,
  errorLEvel = 2,
  warnLevel = 3,
  infoLevel = 4,
  debugLevel = 5,
  traceLevel = 6,
}

export interface IWorkflowLog extends Record<string, any> {
  content: any;
  id: string;
  level: ELogLevel;
  file: string;
  kind: TLogKind;
  tim?: string;
}
