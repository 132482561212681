import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import type { GamepadActionEvent } from '@shared/gamepad';
import {
  GamepadActionPayloadService,
  GamepadActionService,
  GamepadConnectionService,
  GamepadNotificationName,
  GamepadVariableService,
} from '@shared/gamepad';
import type { ButtonDefWithValue, CommandV2, Gamepad, GamepadControl, GamepadPayloadContext } from '@shared/models';
import { ConfigGroupItem, GamepadButtonActionCommandVersionName } from '@shared/models';
import { RobotControlService, RobotDefinitionService, ToastService } from '@shared/services';
import type { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-gamepad-widget',
  templateUrl: './gamepad-widget.component.html',
  styleUrls: ['./gamepad-widget.component.scss'],
})
export class GamepadWidgetComponent implements OnInit, OnDestroy {
  @Input() projectId: string = '';
  @Input() keepAlive: boolean = false;
  @Input() callsign: string = '';
  @Input() headingSubject$: BehaviorSubject<string>;

  public isLoading: boolean = false;
  public pressedValues: ButtonDefWithValue[] = [];
  public active: boolean = false;
  public gamepadEnabled: boolean = false;
  public gamepadName: string;
  public commands: CommandV2[] = [];

  private connectedGamepads: Gamepad[] = [];
  private gamepadPressedSub: Subscription;
  private gamepadUpdatedSub: Subscription;
  private newActionEventSub: Subscription;
  private allSubscriptions: Subscription[] = [];
  private gamepadConfigItems: any[];
  private selectedGamepadConfig: any;

  public constructor(
    private gamepadConnectionService: GamepadConnectionService,
    private gamepadActionService: GamepadActionService,
    private gamepadVariableService: GamepadVariableService,
    private gamepadActionPayloadService: GamepadActionPayloadService,
    private robotControlService: RobotControlService,
    private robotDefinitionService: RobotDefinitionService,
    private toast: ToastService,
  ) {
    this.gamepadConnectionService.resetBehaviour();
    this.gamepadConnectionService.gamepadEnabled$.asObservable().subscribe((x) => {
      this.gamepadEnabled = x;
    });
    // Button pressed messages
    this.gamepadPressedSub = this.gamepadConnectionService.gamepadPressed$.subscribe((gamepad) => {
      if (this.gamepadEnabled) {
        this.pressedValues = gamepad.pressedValues;
      } else {
        this.pressedValues = [];
      }
    });

    this.allSubscriptions.push(this.gamepadPressedSub);

    // Gamepad Updated (connected) messages
    this.gamepadUpdatedSub = this.gamepadConnectionService.gamepadUpdated$.subscribe((gamepads) => {
      this.connectedGamepads = gamepads;
      if (this.connectedGamepads.length > 0) {
        this.gamepadName = this.connectedGamepads[0].name;
        if (this.headingSubject$) {
          this.headingSubject$.next(this.gamepadName);
        }
        this.active = true;
      } else {
        this.gamepadName = 'No Gamepad';
        if (this.headingSubject$) {
          this.headingSubject$.next(this.gamepadName);
        }
        if (this.active) {
          this.toast.short('Controller has been disconnected.', null, 'warning');
        }
        this.active = false;
        // turn off the control if gamepad disconnected
      }
    });
    this.allSubscriptions.push(this.gamepadUpdatedSub);

    this.newActionEventSub = this.gamepadActionService.newActionEvent.subscribe((event: GamepadActionEvent) => {
      this.newEventCallback(event);
    });
    this.allSubscriptions.push(this.newActionEventSub);

    const gamepadNotificationSub = this.gamepadConnectionService.gamepadNotification$.subscribe(
      (notification: GamepadNotificationName) => {
        switch (notification) {
          case GamepadNotificationName.IDLE_TIMEOUT_WHEN_GAMEPAD_ENABLED:
            this.toast.short(
              'The Gamepad Control has been deactivated. Your gamepad has been inactive for more than 5min.',
              null,
              'warning',
            );
            break;
          case GamepadNotificationName.IDLE_TIMEOUT_WHEN_GAMEPAD_CANBE_RESUMED:
            this.toast.short(
              'The Gamepad Control has been deactivated. The browser window has been out of focus for more than 30sec.',
              null,
              'warning',
            );
            break;
          case GamepadNotificationName.LOSE_FOCUS_WHEN_GAMEPAD_ENABLED:
            this.toast.short(
              'The Gamepad Widget has been deactivated. You have navigated away from the dashboard.',
              null,
              'warning',
            );
            break;
          case GamepadNotificationName.GAIN_FOCUS_WHEN_GAMEPAD_CANBE_RESUMED:
            this.toast.short('The Gamepad Control has been reactivated.', null, 'info');
            break;
        }
      },
    );

    this.allSubscriptions.push(gamepadNotificationSub);
  }

  @HostListener('window:blur', ['$event'])
  private onWindowBlur(_event): void {
    this.gamepadConnectionService.checkGamepadActiveState();
  }

  @HostListener('window:focus', ['$event'])
  private onWindowFocus(_event): void {
    this.gamepadConnectionService.checkGamepadActiveState();
  }

  public ngOnDestroy(): void {
    this.gamepadConnectionService.toggleGamepad(false, true);
    this.gamepadConnectionService.resetBehaviour();
    this.allSubscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }

  public ngOnInit(): void {
    if (this.headingSubject$) {
      this.headingSubject$.next(this.gamepadName);
    }
    this.loadControlsForRobot();
    this.loadGamepadConfigs();
    this.loadCommandsForRobot();

    // this widget will keep gamepad alive
    if (this.keepAlive) {
      this.gamepadConnectionService.setKeepAlive(true);
    }
  }

  public onToggleChanged($event): void {
    this.gamepadConnectionService.toggleGamepad($event.checked, true);
  }

  public onSwitchGamepadConfig(): void {
    if (this.gamepadConfigItems?.length > 0) {
      this.selectedGamepadConfig = this.gamepadConfigItems[0].value;

      const actions = this.selectedGamepadConfig.actions;
      const variables = this.selectedGamepadConfig.variables;

      this.gamepadActionService.setActionsConfigList(actions);
      this.gamepadVariableService.setVariablesConfigList(variables);
    }
  }

  private newEventCallback(event: GamepadActionEvent) {
    // the enable variable is only used to control the GUI indication,
    // we don't need to check whether the gamepad is enabled here
    // as the GamepadActionService has done it.

    const action = event.action;
    const gamepadSnapshot = event.gamepadSnapshot;
    const context = {
      callsign: this.callsign,
    } as GamepadPayloadContext;

    const variables = this.gamepadVariableService.getVariablesFromGamepadSnapshot(gamepadSnapshot);
    const payloads = this.gamepadActionPayloadService.getPayloadsWithActionAndVariables(action, variables, context);

    if (this.projectId && this.callsign) {
      if (action.commandVersion === GamepadButtonActionCommandVersionName.COMMANDS_V2) {
        const commandId = action.commandId;
        const commandParams = {};

        const commandV2 = this.commands.find((x) => x.id === action.commandId);
        if (!commandV2) {
          this.toast.short(`Command '${commandId}' does not exist.`, null, 'failure');
          return;
        }

        const timeoutMs = parseInt(commandV2.settings?.['timeoutMs'], 10) || undefined;

        // map variables to command parameters
        Object.keys(variables).forEach((k) => {
          const v = variables[k];

          // verify if this variable is required by this command
          if (!Object.keys(commandV2.parameters).includes(v.name)) {
            return;
          }

          if (!v.value && v.value !== 0 && v.variableDef?.defaultValue) {
            commandParams[v.name] = v.variableDef.defaultValue;
          } else {
            commandParams[v.name] = String(v.value);
          }
        });

        this.robotControlService.sendCommandV2WithoutAck(
          this.projectId,
          this.callsign,
          commandId,
          commandParams,
          timeoutMs,
        );
      } else {
        this.robotControlService.sendMessagesByPayloads(payloads, this.projectId, this.callsign);
      }
    }
  }

  private loadCommandsForRobot(): void {
    this.robotDefinitionService
      .getCommandsForRobotV2(this.projectId, this.callsign)
      .pipe(first())
      .subscribe((commandsConfig) => {
        if (commandsConfig?.items) {
          this.commands = ConfigGroupItem.getValues<CommandV2>(commandsConfig.items);
        }
      });
  }

  private loadControlsForRobot(): void {
    this.robotDefinitionService
      .getControlsForRobot(this.projectId, this.callsign)
      .pipe(first())
      .subscribe((commandsConfig) => {
        if (commandsConfig?.items) {
          const controls: GamepadControl[] = ConfigGroupItem.getValues<GamepadControl>(commandsConfig.items);

          this.gamepadActionPayloadService.setControls(controls);
        }
      });
  }

  private loadGamepadConfigs() {
    this.isLoading = true;

    this.gamepadConfigItems = [];

    this.robotDefinitionService
      .getGamepadsForRobot(this.projectId, this.callsign)
      .pipe(first())
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res?.items) {
            this.gamepadConfigItems = res.items;

            this.onSwitchGamepadConfig();
          }
        },
        () => {
          this.isLoading = false;
        },
      );
  }
}
