import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { map } from 'rxjs/operators';
import { GotoService, ProjectPermissionService, UserService } from '../services';

export const ExperimentalFeatureGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const goto = inject(GotoService);
  const userService = inject(UserService);

  const isAdmin = userService.isAdmin();
  const featureName = route.data['experimentalFeature'];
  const projectId = route.params['projectId'];

  if (!featureName || !projectId) {
    goto.defaultAccountPage();
    return false;
  }

  const projectPermissionService = inject(ProjectPermissionService);
  return projectPermissionService.checkPermission(projectId, featureName).pipe(
    map((hasPermission) => {
      if (!hasPermission && !isAdmin) {
        goto.defaultAccountPage();
      } else {
        hasPermission = true;
      }
      return hasPermission;
    }),
  );
};
