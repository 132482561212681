<app-unsupported-browser></app-unsupported-browser>

<div class="box">
  <div>
    <div class="invite-messages" *ngIf="showInviteMessages && inviteCode && inviteFrom">
      <div class="title">{{ inviteFrom }} invited you to a DroneDeploy Robotics {{ inviteType }}!</div>
      <div class="subtitle">Sign In or Create an Account to accept invite.</div>
    </div>
    <div class="main" [class.wider]="wider">
      <div class="header">
        <button mat-icon-button *ngIf="showPrevBtn" (click)="onGoSignIn()" class="go-prev-btn position-absolute m-2">
          <span class="ri-indicator-left"></span>
        </button>

        <div class="logo text-center">
          <img class="logo" src="assets/images/logo/drone-deploy-logo-white.svg" />
        </div>
      </div>

      <!-- Message -->
      <div class="message" [ngClass]="messageType" *ngIf="message">
        <ng-container *ngIf="messageType === 'error' || messageType === 'success'">
          <div class="message-icon">
            <span *ngIf="messageType === 'success'" class="ri-checkmark"></span>
            <span *ngIf="messageType === 'error'" class="ri-exclamation-mark"></span>
          </div>
        </ng-container>
        <span>
          {{ message }}
        </span>
      </div>

      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
