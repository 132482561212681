import { WidgetBase } from './widget-base';

export class WidgetAudio extends WidgetBase {
  public sampleFormat: string;
  public sampleRate: number;
  public encodingFormat: string;
  public numberOfChannel: string;

  public static fromModel(model: any): WidgetAudio {
    const widget = new WidgetAudio();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);
    this.sampleFormat = model.sampleFormat;
    this.sampleRate = model.sampleRate;
    this.encodingFormat = model.encodingFormat;
    this.numberOfChannel = model.numberOfChannel;
  }
}
