export class UITab {
  public text: string;
  public link: string;
  public hidden: boolean;
  public adminOnly: boolean = false;
  public experimental: boolean = false;

  /**
   * UITab
   *
   * @param text Title
   * @param link Link
   * @param hidden Developing feature
   * @param adminOnly Admin only feature
   * @param experimental Experimental feature
   */
  constructor(text?: string, link?: string, hidden?: boolean, adminOnly?: boolean, experimental?: boolean) {
    this.text = text ? text : '';
    this.link = link ? link : '';
    this.hidden = !!hidden;
    this.adminOnly = !!adminOnly;
    this.experimental = !!experimental;
  }
}
