export const controlActionsSampleData = {
  items: [
    {
      id: '3dad17c9-4e22-11ea-bf01-7742efc9fe6b',
      value: {
        enabled: true,
        id: 'command-actions',
        actions: [
          {
            name: 'Agent Plugin - MAVLink',
            type: 'agent-mavlink',
            metadata: {
              destination: '/mavlink/',
              payloadTemplate: '{}',
            },
            adminOnly: false,
            uri: '/robot/agent/plugin/mavlink',
            agentAction: true,
          },
          // // ROC-1365 - Hide this for now.
          // {
          //   name: 'Agent Plugin - ROSBridge',
          //   type: 'agent-rosbridge',
          //   metadata: {
          //     destination: '/rosbridge-control/',
          //     payloadTemplate: '{}',
          //   },
          //   adminOnly: false,
          //   uri: '/robot/agent/plugin/rosbridge-control',
          //   agentAction: true,
          // },
          {
            name: 'Agent Plugin - ROS',
            type: 'agent-ros',
            metadata: {
              destination: '/ros/cmd_vel',
              payloadTemplate: '{}',
            },
            adminOnly: false,
            uri: '/robot/agent/plugin/ros',
            agentAction: true,
          },
          {
            name: 'Agent Plugin - HTTP',
            type: 'agent-http',
            metadata: {
              destination: '/http/',
              payloadTemplate: '{"address": "","method": "POST","body": "","headers"{"Content-Type": "text/plain"}}',
            },
            adminOnly: false,
            uri: '/robot/agent/plugin/http',
            agentAction: true,
          },
          {
            name: 'Agent Plugin - TCP',
            type: 'agent-tcp',
            metadata: {
              destination: '/tcp/',
              payloadTemplate: '{}',
            },
            adminOnly: false,
            uri: '/robot/agent/plugin/tcp',
            agentAction: true,
          },
          {
            name: 'Slack Message',
            type: 'server-slack',
            metadata: {
              webhookURL: '',
              message: '{"text":"Hello robot"}',
            },
            adminOnly: false,
            uri: '/server/slack/message',
            agentAction: false,
          },
          {
            name: 'Function',
            type: 'server-function',
            metadata: {
              actionType: '',
              payload: '{}',
            },
            adminOnly: true,
            uri: '/server/function',
            agentAction: false,
          },
          {
            name: 'Delay',
            type: 'client-delay',
            metadata: {
              delayMs: '',
            },
            adminOnly: true,
            uri: '/client/delay',
            agentAction: false,
          },
        ],
      },
    },
  ],
  version: 0,
  type: 'command-actions',
};
