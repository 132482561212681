<div class="gamepad-body">
  <div class="gamepad-buttons">
    <div class="gamepad-layer1">
      <span class="ri-gamepad-layer1 gamepad-layer1-size"></span>
    </div>
    <div class="gamepad-layer2" [class.active]="active">
      <span class="ri-gamepad-layer2 gamepad-layer2-size"></span>
    </div>

    <div class="d-pad" [class.pressed]="dPadPressed">
      <span class="ri-d-pad d-pad-size"></span>
    </div>

    <div class="stick-button-left" [class.pressed]="stickButtonLeftPressed" [style]="stickButtonLeftStyle">
      <span class="ri-circle stick-button-left-size"></span>
    </div>

    <div class="stick-button-left-shadow">
      <span class="ri-circle stick-button-left-shadow-size"></span>
    </div>

    <div class="stick-button-right" [class.pressed]="stickButtonRightPressed" [style]="stickButtonRightStyle">
      <span class="ri-circle stick-button-right-size"></span>
    </div>

    <div class="stick-button-right-shadow">
      <span class="ri-circle stick-button-right-shadow-size"></span>
    </div>

    <div class="d-pad-up" [class.pressed]="dPadUpPressed">
      <span class="ri-arrow-up d-pad-up-size"></span>
    </div>
    <div class="d-pad-down" [class.pressed]="dPadDownPressed">
      <span class="ri-arrow-down d-pad-down-size"></span>
    </div>
    <div class="d-pad-left" [class.pressed]="dPadLeftPressed">
      <span class="ri-arrow-left d-pad-left-size"></span>
    </div>
    <div class="d-pad-right" [class.pressed]="dPadRightPressed">
      <span class="ri-arrow-right d-pad-right-size"></span>
    </div>

    <div class="a-button" [class.pressed]="aButtonPressed">
      <span class="ri-circle a-button-size"></span>
    </div>
    <div class="b-button" [class.pressed]="bButtonPressed">
      <span class="ri-circle b-button-size"></span>
    </div>
    <div class="x-button" [class.pressed]="xButtonPressed">
      <span class="ri-circle x-button-size"></span>
    </div>
    <div class="y-button" [class.pressed]="yButtonPressed">
      <span class="ri-circle y-button-size"></span>
    </div>
    <div class="bumper-left" [class.pressed]="bumperLeftPressed || triggerLeftPressed">
      <span class="ri-left-bumper bumper-left-size"></span>
    </div>
    <div class="bumper-right" [class.pressed]="bumperRightPressed || triggerRightPressed">
      <span class="ri-right-bumper bumper-right-size"></span>
    </div>
    <!-- <div class='trigger-left' [class.pressed]="triggerLeftPressed">
      <span class="ri-trigger-left trigger-left-size"></span>
    </div>
    <div class='trigger-right' [class.pressed]="triggerRightPressed">
      <span class="ri-trigger-right trigger-right-size"></span>
    </div> -->
    <div class="back" [class.pressed]="backPressed">
      <span class="ri-circle back-size"></span>
    </div>
    <div class="start" [class.pressed]="startPressed">
      <span class="ri-circle start-size"></span>
    </div>
  </div>
</div>
