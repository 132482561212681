const MESH_KEY_SEPARATOR = '|';
// NOTE: We need to remove dots from names, otherwise binding sources breaks.

export const meshKeyEncode = (path: string[]): string => {
  return path
    .map((item) => item ?? '')
    .map((item) => item.replace('.', ':'))
    .join(MESH_KEY_SEPARATOR);
};

export const meshKeyDecode = (key: string): string[] => {
  if (!key) return [];

  return key.split(MESH_KEY_SEPARATOR).map((item) => item.replace(':', '.'));
};
