import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { BrowserService } from '../../services';

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
})
export class UnsupportedBrowserComponent implements OnInit {
  public show = false;

  public constructor(private browserService: BrowserService) {}

  public ngOnInit(): void {
    this.show = !this.browserService.isSupportedBrowser();
  }
}
