import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLinkComponent } from './external-link.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DirectivesModule } from '@shared/directives';

@NgModule({
  imports: [CommonModule, MatButtonModule, DirectivesModule],
  declarations: [ExternalLinkComponent],
  exports: [ExternalLinkComponent],
})
export class ExternalLinkModule {}
