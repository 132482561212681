import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appCopyText]',
})
export class CopyTextDirective {
  @Input('appCopyText') public copy: string;
  @Output() public copied = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public async onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(this.copy);
      this.copied.emit();
    } catch (err) {
      // Ignore
    }
  }
}
