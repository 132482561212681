import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IsLoading } from '../../interfaces';
import type { ConfigGroupItem } from '../../models';

@Component({
  selector: 'app-screen-simple-triggers',
  templateUrl: './screen-simple-triggers.component.html',
  styleUrls: ['./screen-simple-triggers.component.scss'],
})
export class ScreenSimpleTriggersComponent implements IsLoading {
  @Input()
  isLoading: boolean = false;
  @Input()
  items: ConfigGroupItem<any>[] = [];
  @Input()
  currentModule: 'robot' | 'robot-definition' = 'robot';

  @Output()
  triggersChange: EventEmitter<ConfigGroupItem<any>[]> = new EventEmitter<ConfigGroupItem<any>[]>();

  displayedColumns: string[] = ['id', 'conditions', 'commands', 'description', 'editControls'];
}
