import { DataUriUtils } from '../../utils';
import { SampleInterval } from './sample-interval';

export class DataUri {
  uri: string;

  /** URI without query string */
  mainUri?: string;
  /** Query string without question mark (?) */
  queryString?: string;

  sampleInterval?: SampleInterval;

  constructor(uri: string) {
    this.update(uri);
  }

  static getSampleIntervalFromUri(uri: string): SampleInterval | null {
    const queryString = DataUriUtils.getQueryString(uri);

    return DataUri.getSampleIntervalFromQueryString(queryString);
  }

  static getSampleIntervalFromQueryString(queryString: string): SampleInterval | null {
    let interval = null;

    if (queryString) {
      const urlParams = DataUriUtils.getURLParams(null, queryString);

      const intParam = urlParams['int'];

      if (intParam) {
        interval = SampleInterval.fromIntervalString(intParam);
      }
    }

    return interval;
  }

  update(uri: string) {
    this.uri = uri;

    this.mainUri = this.getMainUri();
    this.queryString = this.getQueryString();
    this.sampleInterval = this.getSampleInterval();
  }

  /**
   * Get string without query string.
   */
  getMainUri() {
    return DataUriUtils.getMainUri(this.uri);
  }

  getQueryString() {
    return DataUriUtils.getQueryString(this.uri);
  }

  getSampleInterval(): SampleInterval | null {
    const queryString = this.queryString ? this.queryString : this.getQueryString();

    return DataUri.getSampleIntervalFromQueryString(queryString);
  }
}
