<div class="mapContainer">
  <!-- MAP -->
  <div #mapContainer class="mapboxContainer" name="mapboxContainer"></div>

  <!-- DECK -->
  <canvas #deckContainer class="deckContainer" name="deckContainer" oncontextmenu="return false;"></canvas>

  <div class="map-control">
    <div class="robot-locator" *ngIf="showBotLocator" [class.active]="isFollowingBot">
      <button mat-icon-button (click)="toggleFollowBotState()">
        <span class="ri-location"></span>
      </button>
    </div>

    <div class="map-changer">
      <div class="zoom-in">
        <button mat-icon-button (click)="zoomIn()">
          <span class="ri-plus"></span>
        </button>
      </div>
      <div class="zoom-out">
        <button mat-icon-button (click)="zoomOut()">
          <span class="ri-minus2"></span>
        </button>
      </div>
    </div>
  </div>
</div>
