import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, HostListener, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FullscreenService } from '../../services/fullscreen/fullscreen.service';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

interface IData {
  date: Date;
  imageData: string;
  title: string;
}
@Component({
  selector: 'app-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss'],
})
export class ImagesCarouselComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public data: IData[];
  @Input() public isLoading = false;
  @Input() public fsHeaderTitle = 'Header title';

  @ViewChild('footerContainer') public footerContainer: ElementRef;
  @ViewChild('carousel') public carouselContainer: ElementRef;
  @ViewChildren('footerItems') public footerItems: QueryList<ElementRef>;

  public fsEnabled$: Observable<boolean>;
  public fsEnabled: boolean = false;
  public selected: IData;
  public selectedIndex: number;

  private destroy$ = new Subject<void>();

  public constructor(private fs: FullscreenService) {}

  @HostListener('document:keyup', ['$event']) public keyboardUp(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight') {
      this.selectPrev(event.shiftKey);
    } else if (event.key === 'ArrowLeft') {
      this.selectNext(event.shiftKey);
    }
  }

  @HostListener('document:keydown', ['$event']) public keyboardDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public ngOnInit(): void {
    this.fsEnabled$ = this.fs.fullScreenEnabled$;
    this.fsEnabled$.subscribe((fs) => (this.fsEnabled = fs));
  }

  public ngOnChanges(): void {
    if (this.data?.length > 0) {
      this.selected = this.data[0];
      this.selectedIndex = 1;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public selectImage(img: IData, index: number): void {
    this.selected = img;
    this.selectedIndex = index + 1;
  }

  public selectPrev(last = false): void {
    if (last) {
      this.selected = this.data[0];
      this.selectedIndex = 1;
      this.footerContainer.nativeElement.scrollLeft = 0;
      return;
    }

    if (this.selectedIndex - 2 >= 0 && this.data?.length) {
      this.selected = this.data[this.selectedIndex - 2];
      this.selectedIndex -= 1;
      this.updateFooterScrollPosition('right');
    }
  }

  public selectNext(last = false): void {
    if (last) {
      this.selected = this.data[this.data.length - 1];
      this.selectedIndex = this.data.length;
      this.footerContainer.nativeElement.scrollLeft = -this.footerContainer.nativeElement.scrollWidth;
      return;
    }

    if (this.selectedIndex < this.data?.length) {
      this.selected = this.data[this.selectedIndex];
      this.selectedIndex += 1;
      this.updateFooterScrollPosition('left');
    }
  }

  public fullscreen(): void {
    if (this.carouselContainer) {
      this.fs.triggerFullscreen(this.carouselContainer);
    }
  }

  public disableFs(): void {
    this.fs.exitFullscreen();
  }

  private updateFooterScrollPosition(direction: 'left' | 'right'): void {
    const selected = this.footerItems.find((item: ElementRef) =>
      (item.nativeElement.classList as DOMTokenList).contains('selected'),
    );

    if (selected) {
      const parentBoundings = this.footerContainer.nativeElement.getBoundingClientRect();
      const itemBoundings = selected.nativeElement.getBoundingClientRect();
      const offset = this.fsEnabled ? 1 : 0; // somehow fs is slighty offset
      if (parentBoundings.left - itemBoundings.left < itemBoundings.width + 8 && direction === 'left') {
        this.footerContainer.nativeElement.scrollLeft -= itemBoundings.width + 8 - offset;
      }
      if (parentBoundings.right - itemBoundings.right < itemBoundings.width + 8 && direction === 'right') {
        this.footerContainer.nativeElement.scrollLeft += itemBoundings.width + 9 - offset;
      }
    }
  }
}
