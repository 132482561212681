import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { urls } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService, ProjectService } from '@shared/services';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-navbar-logo',
  templateUrl: './navbar-logo.component.html',
  styleUrls: ['./navbar-logo.component.scss'],
})
export class NavbarLogoComponent implements OnInit {
  public projectId: string;
  public accountId: string;

  get link() {
    if (this.accountId) {
      return [`/${urls.account.index(this.accountId)}`];
    }
    return ['/'];
  }

  constructor(private projectService: ProjectService, private appService: AppService) {
    this.appService.projectIdChange.pipe(untilDestroyed(this)).subscribe((projId: string) => {
      this.projectId = projId;
      this.onProjectIdChanged();
    });
    this.appService.accountIdChange.pipe(untilDestroyed(this)).subscribe((accountId: string) => {
      this.accountId = accountId;
    });
  }

  ngOnInit() {
    this.accountId = this.appService.accountId;
    this.projectId = this.appService.projectId;

    if (this.projectId) {
      this.onProjectIdChanged();
    }
  }

  private onProjectIdChanged() {
    this.projectService
      .info(this.projectId)
      .pipe(untilDestroyed(this), first())
      .subscribe((info) => {
        if (info?.account?.id) {
          this.accountId = info.account.id;
        }
      });
  }
}
