import type { PipeTransform } from '@angular/core';
import { Pipe, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitiser: DomSanitizer) {}

  private static escapeRegExp(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public transform(value: string, search: string | RegExp, caseSensitive: boolean = false): string {
    if (search === '') return this.sanitise(value);

    const regex =
      typeof search === 'string' ? new RegExp(HighlightPipe.escapeRegExp(search), caseSensitive ? 'g' : 'gi') : search;
    const result = value.replace(regex, `<mark>$&</mark>`);

    return this.sanitise(result);
  }

  private sanitise(content: string) {
    return this.sanitiser.sanitize(SecurityContext.HTML, content);
  }
}
