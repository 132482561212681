import type { OnChanges, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ButtonGroupColour } from '@shared/enums';

interface IParamsArray {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-widget-button-group-params',
  templateUrl: './widget-button-group-params.component.html',
  styleUrls: ['./widget-button-group-params.component.scss'],
})
export class WidgetButtonGroupParamsComponent implements OnInit, OnChanges {
  @Input() public parameters: Record<string, string | number> = {};
  @Input() public buttonColour: ButtonGroupColour = ButtonGroupColour.slate;
  @Input() public commandCallback?: (params: Record<string, string | number>) => void;
  @Input() public closeCallback?: () => void;

  public paramsArray: IParamsArray[] = [];

  public ngOnInit(): void {
    this.generateArray();
  }

  public ngOnChanges(): void {
    this.generateArray();
  }

  public submit($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
    }
    if (this.commandCallback) {
      this.commandCallback(this.parameters);
    }
  }

  public cancel($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
    }
    if (this.closeCallback) {
      this.closeCallback();
    }
  }

  private generateArray() {
    if (this.parameters) {
      this.paramsArray = Object.keys(this.parameters).map((key) => {
        return {
          label: key,
          value: this.parameters[key],
        };
      }, {});
    } else {
      this.paramsArray = [];
    }
  }
}
