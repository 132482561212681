import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { Account, Invitation, InvitationGroup, InvitationType } from '../../models';
import { PersonalAccessToken, User } from '../../models';
import { AuthService } from '../auth';
import { RocosClientService } from '../rocos-client';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: User;

  public userUpdated: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public get defaultAccount(): Account {
    // Try to reload the user info
    if (!this.user?.email) {
      this.loadUser();
    }

    if (this.user?.defaultAccount) {
      return this.user.defaultAccount;
    }

    return null;
  }

  public constructor(private authService: AuthService, private rocosClientService: RocosClientService) {
    this.loadUser();

    this.authService.userUpdated.subscribe(() => {
      this.loadUser();
    });
  }

  public isAdmin(): boolean {
    return this.user?.isAdmin;
  }

  public createInvitation(invitation: Invitation): Observable<any> {
    return this.rocosClientService.rocosClient.user.createInvitation(invitation as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public listInvitations(): Observable<InvitationGroup> {
    return this.rocosClientService.rocosClient.user.listInvitations().pipe(
      map((res) => {
        return res.data as InvitationGroup;
      }),
    );
  }

  /**
   * Delete invitations by email and invite type
   *
   * @param email Email
   * @param inviteType Invite type, e.g. PROJECT, ACCOUNT
   */
  public deleteInvitations(email: string, inviteType: InvitationType): Observable<any> {
    return this.rocosClientService.rocosClient.user.deleteInvitations(email, inviteType).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public acceptInvitation(invitationId: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.acceptInvitation(invitationId).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public checkInvitation(invitationId: string): Observable<boolean> {
    return this.rocosClientService.rocosClient.user.checkInvitation(invitationId).pipe(
      map((res) => {
        return res.data.accountExists;
      }),
      catchError(() => {
        // catch the http error and return false, as if account does not exist.
        return of(false);
      }),
    );
  }

  public getNewToken(): Observable<string> {
    return this.rocosClientService.rocosClient.user.getToken().pipe(
      map((res) => {
        const data = res.data;

        return data?.token ? data.token : null;
      }),
    );
  }

  public activateAccount(accountId: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.activateAccount(accountId).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public resendVerificationEmail(email: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.resendVerificationEmail(email).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public sendForgottenPasswordEmail(email: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.sendForgottenPasswordEmail(email).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Check the creating project permission under a specified account id
   */
  public hasPermissionToCreateProject(accountId: string): boolean {
    return this.isTheOwnerOfAccount(accountId);
  }

  /**
   * Check current user has permission to view account people content
   *
   * @param accountId Account Id
   */
  public hasPermissionToViewAccountPeopleContent(accountId: string): boolean {
    return this.isTheOwnerOfAccount(accountId);
  }

  /**
   * Check the deleting project permission.
   *
   * @param accountId Account Id
   */

  public hasPermissionToDeleteProject(accountId: string): boolean {
    // User this checker for now. Should implement logic with project id in the future.
    return this.isTheOwnerOfAccount(accountId);
  }

  public createPAT(pat: PersonalAccessToken): Observable<any> {
    return this.rocosClientService.rocosClient.user.createPAT(pat as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public getAllPATs(projectId: string, robotDefinitionId: string): Observable<any[]> {
    return this.rocosClientService.rocosClient.user.getAllPATs(projectId, robotDefinitionId).pipe(
      map((res) => {
        let data = res.data;

        if (data?.length > 0) {
          data = data.map((item) => {
            return PersonalAccessToken.fromModel(item);
          });
        }

        return data;
      }),
    );
  }

  public getOnePAT(token: string): Observable<any[]> {
    return this.rocosClientService.rocosClient.user.getOnePAT(token).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public removePATById(id: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.removePATById(id).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Create a new application
   *
   * @param accountId The account ID
   * @param projectId The project ID
   * @param model Payload
   */

  public createApplication(accountId: string, projectId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.user.createApplication(accountId, projectId, model).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * List all applications
   *
   * @param accountId The account ID
   * @param projectId The project ID
   */
  public getAllApplications(accountId: string, projectId: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.getAllApplications(accountId, projectId).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Get application information
   *
   * @param accountId The account ID
   * @param projectId The project ID
   * @param applicationId The application ID
   */
  public getApplication(accountId: string, projectId: string, applicationId: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.getApplication(accountId, projectId, applicationId).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Update application information
   *
   * @param accountId The account ID
   * @param projectId The project ID
   * @param applicationId The application ID
   * @param model Payload
   */

  public updateApplication(accountId: string, projectId: string, applicationId: string, model: any): Observable<any> {
    return this.rocosClientService.rocosClient.user.updateApplication(accountId, projectId, applicationId, model).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Delete application
   *
   * @param accountId The account ID
   * @param projectId The project ID
   * @param applicationId The application ID
   */
  public deleteApplication(accountId: string, projectId: string, applicationId: string): Observable<any> {
    return this.rocosClientService.rocosClient.user.deleteApplication(accountId, projectId, applicationId).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  private loadUser() {
    this.user = User.fromModel(this.authService.userInfo);

    this.userUpdated.next(this.user);
  }

  private isTheOwnerOfAccount(accountId: string): boolean {
    let isOwner = false;
    const accounts = this.user.accounts;
    if (accounts?.length > 0) {
      accounts.forEach((account) => {
        if (account.id === accountId) {
          isOwner = account.isOwner;
        }
      });
    }

    return isOwner;
  }
}
