import type { NodeDefinition } from '../models/node';
import { EControlType } from '../models/socket';

export const getNodeIcon = (componentName: string): string[] => {
  if (Object.prototype.hasOwnProperty.call(CNodesIcons, componentName)) {
    return CNodesIcons[componentName];
  }
  return ['ri-automate-faded', 'path1', 'path2'];
};

export const CNodesIcons = {
  // interfaces
  'dataIn': ['ri-data-in', 'path1', 'path2'],
  'dataOut': ['ri-data-out', 'path1', 'path2'],
  'triggerIn': ['ri-trigger-in', 'path1', 'path2'],
  'triggerOut': ['ri-trigger-out', 'path1', 'path2'],
  'graph': ['ri-graph-node', 'path1', 'path2'],
  'missing': ['ri-graph-node', 'path1', 'path2'],

  'goto_pose': ['ri-goto', 'path1', 'path2'],
  'if': ['ri-if', 'path1', 'path2', 'path3'],
  'for': ['ri-for-loop', 'path1', 'path2'],
  'while': ['ri-while-loop', 'path1', 'path2'],
  'print': ['ri-print', 'path1', 'path2'],
  'subscribe': ['ri-subscribe', 'path1', 'path2'],
  'callServiceAsync': ['ri-callService', 'path1', 'path2', 'path3'],
  'callServiceSync': ['ri-callService', 'path1', 'path2', 'path3'],
  'callCommandSync': ['ri-callCommand', 'path1', 'path2', 'path3'],
  'callCommandAsync': ['ri-callCommand', 'path1', 'path2', 'path3'],
  'sendControlSync': ['ri-sendControls', 'path1', 'path2', 'path3', 'path4'],
  'sendControlAsync': ['ri-sendControls', 'path1', 'path2', 'path3', 'path4'],
  'getFlowID': ['ri-getWorkflowId', 'path1', 'path2', 'path3'],
  'getFlowInstanceID': ['ri-getWorkflowId', 'path1', 'path2', 'path3'],
  'getProjectID': ['ri-getProjectId', 'path1', 'path2', 'path3'],
  'getCallsign': ['ri-getCallSign', 'path1', 'path2'],
  'getSubsystem': ['ri-getSubSystem', 'path1', 'path2', 'path3'],
  'publish': ['ri-publish', 'path1', 'path2'],
  'sequence': ['ri-sequence', 'path1', 'path2', 'path3'],
  'delay': ['ri-delay', 'path1', 'path2'],
};

export const CDefaultNodeEntryKey: string = 'entry'; // keep for upgrading

export const CDefaultNodeDataInKey: string = 'dataIn';
export const CDefaultNodeDataOutKey: string = 'dataOut';
export const CDefaultNodeDataKind: string = 'kind';
export const CDefaultNodeDataOut: string = 'out';
export const CDefaultNodeDataIn: string = 'in';

export const CDefaultNodeTriggerInKey: string = 'triggerIn';
export const CDefaultNodeTriggerOutKey: string = 'triggerOut';
export const CDefaultNodeTriggerOutLabel: string = 'label';

export const CNamespaceGraph: string = 'other-graphs';
export const CNamespaceMissing: string = 'missing';
export const CNamespaceInterface: string = 'interface';

export const CDefaultNodes: NodeDefinition = {
  nodes: {
    [CDefaultNodeDataInKey]: {
      name: 'Data In',
      namespace: CNamespaceInterface,
      hidden: false,
      bi: {
        [CDefaultNodeDataKind]: {
          kind: EControlType.SocketKindSelect,
          default: 'string',
          hidden: true,
        },
        label: {
          kind: 'string',
          default: 'Data Name',
          hidden: true,
        },
      },
      bo: {
        [CDefaultNodeDataOut]: {
          kind: 'string',
          default: 'string',
          hints: {
            kind: EControlType.SocketKindSelect,
          },
        },
      },
    },

    [CDefaultNodeDataOutKey]: {
      name: 'Data Out',
      namespace: CNamespaceInterface,
      hidden: false,
      bi: {
        [CDefaultNodeDataIn]: {
          kind: 'string',
          default: 'string',
          hints: {
            kind: EControlType.SocketKindSelect,
          },
        },
        [CDefaultNodeDataKind]: {
          kind: 'socketKindSelect',
          default: 'string',
          hidden: true,
        },
        label: {
          kind: 'string',
          default: 'Data Name',
          hidden: true,
        },
      },
    },

    [CDefaultNodeTriggerInKey]: {
      name: 'Trigger In',
      namespace: CNamespaceInterface,
      fo: {
        out: {},
      },
      bi: {
        label: {
          kind: 'string',
          default: 'Entry',
          hidden: true,
        },
      },
    },

    [CDefaultNodeTriggerOutKey]: {
      name: 'Trigger Out',
      namespace: CNamespaceInterface,
      fi: {
        in: {},
      },
      bi: {
        label: {
          kind: 'string',
          default: 'Exit',
          hidden: true,
        },
      },
    },
  },
};
