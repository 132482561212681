import type { Scene } from '@babylonjs/core';
import { SceneLoader } from '@babylonjs/core';
import type { IMeshImporterAsyncResult } from '../primitives/visualizer/interface/IMeshImporterAsyncResult';
import type { IMeshImporterParams } from '../primitives/visualizer/interface/IMeshImporterParams';
import { environment } from '@env/environment';

export const importMesh = async (params: IMeshImporterParams, scene: Scene): Promise<IMeshImporterAsyncResult> => {
  try {
    const { rootUrl, sceneFileName } = params.meshData.options;
    const headers: HeadersInit = {};

    // authenticate our requests to the same domain as the API
    if (new URL(rootUrl).hostname === new URL(environment.api.url).hostname) {
      headers['Authorization'] = `bearer ${params.authorizationToken}`;
    }

    const httpResult = await fetch(`${rootUrl}${sceneFileName}`, { headers });
    if (!httpResult.ok) {
      throw new Error(`Failed to fetch scene file: ${httpResult.statusText} - ${httpResult.url}`);
    }

    const blob = await httpResult.blob();
    const file = new File([blob], sceneFileName);
    const { meshes, transformNodes } = await SceneLoader.ImportMeshAsync(null, '', file, scene);

    return {
      ...params,
      meshes,
      transformNodes,
    };
  } catch (error) {
    console.error('Failed to import mesh', error);
    return {
      ...params,
      meshes: [],
      transformNodes: [],
    };
  }
};
