import { Component, Input, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AssetStorageService } from '../../services';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';

export interface FileUploadDialogData {
  projectId: string;
  allowedExtensions: string[];
  dialogMode: 'upload' | 'fileContent';
}

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent {
  @Input() projectId: string;
  @Input() allowedExtensions: string[];
  @Input() dialogMode: 'upload' | 'fileContent' = 'upload';

  isLoading$ = new BehaviorSubject<boolean>(false);
  error$: BehaviorSubject<string> = new BehaviorSubject('');
  mode$ = new BehaviorSubject<'upload' | 'update'>('upload');
  override$ = new Subject<'cancel' | 'override' | 'existing'>();
  fileName: string;

  constructor(
    private assetStorageService: AssetStorageService,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: FileUploadDialogData,
  ) {
    if (data?.projectId) this.projectId = data.projectId;
    if (data?.allowedExtensions) this.allowedExtensions = data.allowedExtensions;
    if (data?.dialogMode) this.dialogMode = data.dialogMode;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public handleFile(file: File): void {
    if (this.allowedExtensions?.length) {
      const extension = file?.name?.split('.').pop();
      if (!this.allowedExtensions.includes(extension)) {
        this.error$.next(`File type not allowed. Allowed file types: (${this.allowedExtensions.join(', ')})`);
        return;
      }
    }

    switch (this.dialogMode) {
      case 'upload':
        this.fileUpload(file);
        break;
      case 'fileContent':
        this.readFile(file);
        break;
      default:
        // noop
        break;
    }
  }

  private readFile(file: File) {
    this.isLoading$.next(true);
    this.error$.next('');
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onerror = () => {
      this.error$.next('Failed to read file');
    };
    reader.onload = (evt) => {
      this.dialogRef.close(evt.target.result);
    };
  }

  private fileUpload(file: File): void {
    this.isLoading$.next(true);
    this.fileName = file?.name;
    this.error$.next('');
    let existingFile = null;

    from(this.assetStorageService.list(this.projectId))
      .pipe(
        switchMap((msg) => {
          // if the file already exists, ask for permission to overwrite it
          existingFile = msg?.find((item) => item?.name === file.name);
          if (existingFile) {
            this.isLoading$.next(false);
            this.mode$.next('update');
            return this.override$;
          } else {
            // otherwise upload it directly
            return this.assetStorageService.create(this.projectId, file.name, file);
          }
        }),
        switchMap((msg) => {
          this.isLoading$.next(true);
          this.mode$.next('upload');
          // if the message is true, it means that the previous operation was confirmation
          // therefore, update the file
          if (msg === 'override') {
            return this.assetStorageService.update(this.projectId, file.name, file);
          }
          // if the message is 'option', it means that the previous operation was confirmation
          // with a use existing selected, therefore return the existing file
          if (msg === 'existing') {
            return of(existingFile);
          }
          // if the message is an api response and contains data, it means the file was created in previous operation
          // therefore pass on the result of create operation
          if (msg?.path) {
            return of(msg);
          }
          // if none of the above rules match, then the user clicked cancel
          // therefore return null
          return of(null);
        }),
        map((msg) => {
          if (msg?.path) {
            return msg.path;
          }
          // otherwise return null
          return null;
        }),

        finalize(() => {
          this.isLoading$.next(false);
          this.fileName = undefined;
        }),
      )
      .subscribe({
        next: (msg) => {
          this.dialogRef.close(msg ? `${environment.api.url}${msg}` : null);
        },
        error: (error: Error) => {
          let errorMessage = 'File could not be uploaded.';
          if (error?.message.indexOf('404') >= 0) {
            errorMessage = 'File already exists.';
          }
          this.error$.next(errorMessage);
        },
      });
  }
}
