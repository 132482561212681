import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first, finalize, filter, take } from 'rxjs/operators';
import type { IsLoading } from '../../interfaces';
import type {
  CodeEvalEnvironment,
  CommandV2,
  GamepadControl,
  GamepadPayloadContext,
  GamepadUxComponent,
} from '../../models';
import { ConfigGroupItem, OperationPageContext, OpsPageType } from '../../models';
import {
  CoordinatePositionRequest,
  GamepadService,
  GotoService,
  OperationCommunicationService,
  RobotControlService,
  RobotDefinitionService,
  ToastService,
  WaypointBridgeService,
} from '../../services';
import { ExpressionEval } from '../../utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EPickerHintModes } from '../../services/threeD/primitives/visualizer/interface/IWaypointPicker';

@UntilDestroy()
@Component({
  selector: 'app-widget-robot-control',
  templateUrl: './widget-robot-control.component.html',
  styleUrls: ['./widget-robot-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetRobotControlComponent implements OnChanges, IsLoading {
  @Input() robotDefinitionId: string;
  @Input() projectId: string;
  @Input() callsign: string;
  @Input() uxComponents: GamepadUxComponent[];
  @Input() uxTriggers: any[];
  @Input() controls: GamepadControl[] = [];
  @Input() currentPageMode: OpsPageType;
  @Input() operationPageContext?: OperationPageContext;

  isLoading: boolean = false;
  robotDefinition: any;

  // Buttons' loading status
  public buttonStatus = {};
  commandV2Items: ConfigGroupItem<CommandV2>[];

  get codeEvalEnvironment(): CodeEvalEnvironment {
    return this.operationPageContext?.codeEvalEnvironment;
  }

  constructor(
    private gamepadService: GamepadService,
    private robotControlService: RobotControlService,
    private robotDefinitionService: RobotDefinitionService,
    private toast: ToastService,
    private goto: GotoService,
    private operationCommunicationService: OperationCommunicationService,
    private waypointBridge: WaypointBridgeService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['robotDefinitionId'] || changes['projectId'] || changes['callsign']) {
      this.loadRobotDefinitionDetails();
      this.cdr.markForCheck();
    }
  }

  hasInput(component: GamepadUxComponent) {
    if (component?.commandParams) {
      for (const param of component.commandParams) {
        if (param.id.indexOf('_showInputBox') > 0 && param.value === true) {
          return true;
        }
      }
    }
    return false;
  }

  onClick(component: GamepadUxComponent) {
    if (this.buttonStatus[component.id].isLoading) return;
    this.buttonStatus[component.id].isLoading = true;

    const sendCommand = (compoId, payloads: any[], projectId, callsign) => {
      this.robotControlService
        .sendMessagesByPayloadsWithAck(payloads, projectId, callsign)
        .pipe(
          untilDestroyed(this),
          first(),
          finalize(() => {
            this.buttonStatus[compoId].isLoading = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(
          (msg) => {
            this.toast.short(`Success! ${msg}`, null, 'success');
          },
          (err) => {
            this.toast.short(`Failed. ${err.message}`, null, 'failure');
          },
        );
    };

    const sendCommandV2 = (commandId = component.commandName, commandParams = component.commandParamsAsObject) => {
      const commandV2 = this.commandV2Items.find((x) => x.value.id === commandId)?.value;

      if (!commandV2) {
        this.toast.short(`Command '${commandId}' does not exist.`, null, 'failure');
        return;
      }
      const timeoutMs = parseInt(commandV2.settings?.['timeoutMs'], 10) || undefined;
      this.robotControlService
        .sendCommandV2WithAck(this.projectId, this.callsign, commandId, commandParams, timeoutMs)
        .pipe(
          untilDestroyed(this),
          first(),
          finalize(() => {
            this.buttonStatus[component.id].isLoading = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(
          (msg) => {
            this.toast.short(`Success! ${msg}`, null, 'success');
          },
          (err) => {
            this.toast.short(`Failed. ${err.message}`, null, 'failure');
          },
        );
    };

    const runJSCode = (code: string) => {
      const codeEnv = this.codeEvalEnvironment;

      if (codeEnv) {
        const error = codeEnv.runJavascriptCode(code);

        if (error) {
          this.toast.short(`Can't execute code. ${error.message}`, null, 'failure');
        } else {
          this.toast.short('Code is executing.', null, 'success');
        }
      } else {
        this.toast.short('Environment error, unable to run code.', null, 'failure');
      }

      this.buttonStatus[component.id].isLoading = false;
      this.cdr.markForCheck();
    };

    const evalParams = (commandParams: { [key: string]: string }, controlContext: any) => {
      if (commandParams && controlContext) {
        controlContext = {
          $msg: controlContext,
        };

        Object.keys(commandParams).forEach((key) => {
          const paramTemplate = commandParams[key];
          const evaluatedParam = ExpressionEval.evaluate(paramTemplate, controlContext);
          commandParams[key] = `${evaluatedParam}`;
        });
      }

      return commandParams;
    };

    const context = {
      callsign: this.callsign,
    } as GamepadPayloadContext;

    let rawPayloads;
    let jsPayload;
    let request;

    switch (component.commandVersion) {
      // this case is for backward compability for old version of command
      case 'controls':
      case 'commands':
        switch (component.controlType) {
          case 'button':
            rawPayloads = this.gamepadService.getPayloadsFromComponentEvent(
              component,
              'click',
              this.controls,
              this.uxTriggers,
              context,
            );

            sendCommand(component.id, rawPayloads, this.projectId, this.callsign);
            break;

          case 'buttonAndPose':
            switch (this.currentPageMode) {
              case 'global':
                this.buttonStatus[component.id].isLoading = false;
                this.toast.short(
                  'This button type is not supported in the global operation page yet.',
                  null,
                  'warning',
                );
                break;
              default:
                break;
            }
            break;
          case 'buttonAndGetMission':
            this.buttonStatus[component.id].isLoading = false;
            this.toast.short('This button type is not supported for commands yet.', null, 'warning');
            break;
        }
        break;
      case 'commands-v2':
        switch (component.controlType) {
          case 'button':
            sendCommandV2();
            break;
          case 'buttonAndPose':
            switch (this.currentPageMode) {
              case 'global':
                this.buttonStatus[component.id].isLoading = false;
                this.cdr.markForCheck();
                this.toast.short(
                  'This button type is not supported in the global operation page yet.',
                  null,
                  'warning',
                );
                break;
              case 'local':
                this.waypointBridge
                  .nextWaypointsAction$({
                    type: 'get',
                    id: 'buttonAndPose',
                    mode: (component.pickerHint?.mode as EPickerHintModes) ?? EPickerHintModes.waypoint,
                    frame: component.pickerHint?.frame,
                    name: 'buttonAndPose',
                  })
                  .pipe(
                    untilDestroyed(this),
                    first(),
                    filter((action) => action.type === 'result' && action.id === 'buttonAndPose'),
                  )
                  .subscribe((action) => {
                    const result = action.result;
                    if (result.type === 'cancel') {
                      this.buttonStatus[component.id].isLoading = false;
                      this.cdr.markForCheck();
                      return;
                    }

                    let commandParams: { [key: string]: string } = {};

                    switch (result.type) {
                      case EPickerHintModes.waypoint:
                        commandParams = evalParams(component.commandParamsAsObject, {
                          position: result.pos,
                        });
                        break;
                      case EPickerHintModes.polygon:
                        commandParams = evalParams(component.commandParamsAsObject, {
                          altitude: result.pickerMeta.altitude,
                          height: result.pickerMeta.height,
                          positions: JSON.stringify(result.waypoints), // command values must be a string
                        });
                        break;
                      default:
                        break;
                    }

                    try {
                      sendCommandV2(component.commandName, commandParams);
                      this.buttonStatus[component.id].isLoading = false;
                      this.cdr.markForCheck();
                    } catch (e) {
                      this.toast.short('Error trying to build and send the command', null, 'failure');
                    }
                  });

                break;
              default:
                break;
            }
            break;
          case 'buttonAndGetMission':
            this.buttonStatus[component.id].isLoading = false;
            this.cdr.markForCheck();
            this.toast.short('This button type is not supported for commands yet.', null, 'warning');
            break;
        }
        break;
      case 'run-js':
        jsPayload = component.jsPayload;

        switch (component.controlType) {
          case 'button':
            runJSCode(jsPayload);
            break;
          case 'buttonAndPose':
            switch (this.currentPageMode) {
              case 'global':
                request = new CoordinatePositionRequest('global');
                request.robotCallsign = this.callsign;
                this.operationCommunicationService.coordinatePositionResponse$
                  .pipe(untilDestroyed(this), take(1))
                  .subscribe((res) => {
                    if (res.id === request.id) {
                      this.buttonStatus[component.id].isLoading = false;

                      // Coordinate info has been updated into the JS environment,
                      // so no need to get the coordinate here.
                      // Just run js code should be fine.
                      runJSCode(jsPayload);
                      this.cdr.markForCheck();
                    }
                  });
                this.operationCommunicationService.coordinatePositionRequest$.next(request);
                break;
              case 'local':
                this.buttonStatus[component.id].isLoading = false;
                this.cdr.markForCheck();
                this.toast.short('This button type is not supported in the local operation page yet.', null, 'warning');
                break;
              default:
                break;
            }
            break;
          case 'buttonAndGetMission':
            runJSCode(jsPayload);
            break;
        }
        break;
    }
  }

  onConfigureButtons() {
    this.goto.robotDetailsButtons(this.projectId, this.callsign, true);
  }

  shouldDisplay(comp: GamepadUxComponent) {
    let shouldDisplay: boolean = false;

    switch (this.currentPageMode) {
      case 'global':
        if (comp.showButtonOnGlobalOperations !== null && comp.showButtonOnGlobalOperations !== false) {
          shouldDisplay = true;
        }
        break;
      case 'local':
        if (comp.showButtonOnLocalOperations !== false && comp.showButtonOnLocalOperations !== null) {
          shouldDisplay = true;
        }
        break;
      default:
        break;
    }

    this.cdr.markForCheck();
    return shouldDisplay;
  }

  private loadRobotDefinitionDetails() {
    this.isLoading = true;

    forkJoin(
      this.robotDefinitionService.getControlsForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getButtonsForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getTriggersForRobot(this.projectId, this.callsign),
      this.robotDefinitionService.getCommandsForRobotV2(this.projectId, this.callsign),
    )
      .pipe(untilDestroyed(this), first())
      .subscribe(
        ([controls, components, triggers, commandsV2]) => {
          if (controls?.items) {
            this.controls = ConfigGroupItem.getValues<any>(controls.items);
          } else {
            this.controls = [];
          }

          if (components?.items) {
            this.uxComponents = ConfigGroupItem.getValues<any>(components.items);
          } else {
            this.uxComponents = [];
          }

          if (triggers?.items) {
            this.uxTriggers = ConfigGroupItem.getValues<any>(triggers.items);
          } else {
            this.uxTriggers = [];
          }

          if (commandsV2?.items) {
            this.commandV2Items = commandsV2.items;
          } else {
            this.commandV2Items = [];
          }

          this.afterButtonsUpdated();

          this.isLoading = false;
          this.cdr.markForCheck();
        },
        (_err) => {
          this.controls = [];
          this.uxComponents = [];
          this.uxTriggers = [];
          this.afterButtonsUpdated();
          this.isLoading = false;
        },
      );
  }

  private afterButtonsUpdated() {
    const status = {};
    if (this.uxComponents) {
      this.uxComponents.forEach((btn) => {
        status[btn.id] = {
          isLoading: false,
        };
      });
    }
    this.buttonStatus = status;
  }
}
