import { Component, Input } from '@angular/core';
import { ValueEvalPipe } from '../../pipes';
import { WidgetBaseComponent } from '../shared';
import { WidgetMinGridSize } from '../../models';

@Component({
  selector: 'app-widget-donut-gauge',
  templateUrl: './widget-donut-gauge.component.html',
  styleUrls: ['../shared/styles.scss', './widget-donut-gauge.component.scss'],
})
export class WidgetDonutGaugeComponent extends WidgetBaseComponent {
  @Input() value: unknown;
  @Input() minimumValue: number = 0;
  @Input() maximumValue: number = 100;

  public get validValue(): boolean {
    return !!(this.value || this.value === 0);
  }

  public get percent(): number {
    if (!this.value) {
      return 0;
    }

    const value = this.evalPipe.transform(this.value, this.valueExpression);

    const diff = this.maximumValue - this.minimumValue;
    if (!diff || diff <= 0) {
      return 0;
    }

    const gt = value - this.minimumValue;
    if (!gt || gt <= 0) {
      return 0;
    }

    return (gt * 100) / diff;
  }

  public get radius(): number {
    let radius = 60;
    if (this.widget?.gridMinSize) {
      switch (this.widget.gridMinSize) {
        case WidgetMinGridSize.small:
          radius = 60; // Please also update ../shared/styles.scss.
          break;
        case WidgetMinGridSize.min150:
          radius = 60;
          break;
        case WidgetMinGridSize.min300:
          radius = 145;
          break;
        case WidgetMinGridSize.min400:
          radius = 165;
          break;
        case WidgetMinGridSize.min500:
          radius = 230;
          break;
        case WidgetMinGridSize.min600:
          radius = 290;
          break;
        default:
          break;
      }
    }

    return radius;
  }

  private evalPipe: ValueEvalPipe;

  constructor() {
    super();

    this.evalPipe = new ValueEvalPipe();
  }
}
