import { Injectable } from '@angular/core';
import type { KeyboardInfo, LinesMesh, Observer, PickingInfo, PointerInfo } from '@babylonjs/core';
import { Color3, Mesh, MeshBuilder, Plane, PointerEventTypes, Vector3 } from '@babylonjs/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { BabylonRendererService } from './babylon-renderer.service';
import type { Position } from './primitives/primitives';
import {
  distanceBetweenPoints,
  positionToVector3,
  roundPositionToDecimals,
  vector3ToPosition,
} from './utils/position-utils';
import type {
  WaypointAction,
  WaypointActionResult,
  WaypointLocation,
} from './primitives/visualizer/interface/IWaypointPicker';
import { EPickerHintModes } from './primitives/visualizer/interface/IWaypointPicker';
import { WaypointBridgeService } from '../waypoint-bridge';
import { Utils } from '@shared/utils';
import { getPolygonMaterial } from './primitives/gizmos/gizmo-materials';
import { deletePolygonGizmo, renderPolygonGizmo } from './primitives/gizmos/polygon-gizmo';
import {
  deleteArrowGizmo,
  renderArrowGizmo,
  setArrowGizmoAltitude,
  startArrowGizmoSnapAnim,
  stopArrowGizmoSnapAnim,
} from './primitives/gizmos/arrow-gizmo';
import { GridMaterial } from '@babylonjs/materials';
import { WaypointArrayGizmo } from './primitives/gizmos/waypoint-array-gizmo';

const MARKER_ID = 'PointPickerService-markerId';
const WAYPOINT_ARRAY_ID = 'PointPickerService-waypointArrayId';
const POLYGON_ID = 'PointPickerService-polygonId';
const SELECTION_COLOR = new Color3(0, 0.74, 0.84);

type ModifierKeyCodes = 'KeyA' | 'KeyH';

interface ModifierKeys {
  type?: number;
  code?: ModifierKeyCodes;
}

export interface PickerData {
  waypoints: WaypointLocation[];
  height: number;
  altitude: number;
}

export class Picker {
  public updated$ = new Subject<PickerData>();

  public get waypoints() {
    return this.pickerData.waypoints;
  }

  public get height() {
    return this.pickerData.height;
  }
  public set height(value: number) {
    this.pickerData.height = value;
    this.updated$.next(this.pickerData);
  }

  public get altitude() {
    return this.pickerData.altitude;
  }
  public set altitude(value: number) {
    this.pickerData.altitude = value;
    this.updateAltitude(value);
    this.updated$.next(this.pickerData);
  }

  private pickerData: PickerData = {
    waypoints: [],
    height: 0.1,
    altitude: 0.1,
  };

  constructor(positions: WaypointLocation[], height: number, altitude: number) {
    this.pickerData.waypoints = positions;
    this.pickerData.height = height;
    this.pickerData.altitude = altitude;
  }

  addPosition(position: WaypointLocation) {
    this.pickerData.waypoints.push(position);
    this.updateAltitude(this.altitude);
    this.updated$.next(this.pickerData);
  }

  private updateAltitude(altitude: number) {
    this.pickerData.waypoints = this.waypoints.map((p) => {
      p.pos.z = altitude;
      return p;
    });
  }
}

@Injectable()
export class PointPickerService {
  private _picker$ = new Subject<Picker>();
  public get picker$() {
    return this._picker$.asObservable();
  }

  private gizmo: {
    xLine: LinesMesh;
    yLine: LinesMesh;
    plane: Mesh;
    altitude: Mesh;
  };

  private get scene() {
    return this.babylonRenderer.scene;
  }

  private waypointArrayGizmo: WaypointArrayGizmo;

  private cancelGoalPositionFunc: undefined | (() => void);
  private destroy$ = new Subject<void>();

  public constructor(private babylonRenderer: BabylonRendererService, private waypointBridge: WaypointBridgeService) {}

  public init() {
    this.waypointBridge.waypointsAction$.pipe(takeUntil(this.destroy$)).subscribe((action: WaypointAction) => {
      if (action.type !== 'get' || !action.mode) return;
      this.addNewWaypointToWorkflow(action, action.mode);
    });

    this.babylonRenderer.scene$.pipe(take(1)).subscribe((scene) => {
      this.waypointArrayGizmo = new WaypointArrayGizmo(WAYPOINT_ARRAY_ID, scene);
    });
  }

  public destroy(): void {
    this.destroy$.next();
    this.cancelGoalPositionFunc?.();
  }

  public pickPositions(
    positionObserver: Subject<WaypointActionResult>,
    type: EPickerHintModes,
    snapDistance: number = 1,
  ) {
    let currentPosition: Position;
    let isSnapping: boolean = false;
    const picker = new Picker([], 0.1, 0.1);
    this._picker$.next(picker);

    const stopPicker$ = new Subject<void>();

    this.createPickerGizmo();
    this.updatePickerPlane(picker.height);

    const stopFunc = () => {
      // HACK: Babylon's position property doesn't reflect the world matrix so we need to recompute
      // it for unknown reasons. This seems to only affect meshes with a parent
      this.scene.meshes.filter((m) => !!m.parent).forEach((mesh) => mesh.computeWorldMatrix(true));

      document.removeEventListener('keyup', keyboardEventHandler);
      this.removePickerGizmos();
      deleteArrowGizmo({ scene: this.scene, id: MARKER_ID });
      deletePolygonGizmo({ scene: this.scene, id: POLYGON_ID });
      this.waypointArrayGizmo?.clear();
      this.scene.onPointerObservable.remove(pointerObserver);
      this.scene.onKeyboardObservable.remove(keyboardObserver);
      stopPicker$.next();
    };

    picker.updated$.pipe(takeUntil(stopPicker$)).subscribe((curr) => {
      this.updatePickerPlane(curr.altitude);

      switch (type) {
        case EPickerHintModes.waypointArray:
          this.waypointArrayGizmo.updateWaypoints(curr.waypoints);
          break;
        case EPickerHintModes.polygon:
          renderPolygonGizmo({
            scene: this.scene,
            id: POLYGON_ID,
            material: getPolygonMaterial({ scene: this.scene }),
            locations: curr.waypoints,
            height: curr.height,
          });
          if (curr.waypoints.length === 1) {
            renderArrowGizmo({
              id: MARKER_ID,
              location: curr.waypoints[0].pos,
              material: getPolygonMaterial({ scene: this.scene }),
              scene: this.scene,
            });
          }
          setArrowGizmoAltitude({
            id: MARKER_ID,
            scene: this.scene,
            altitude: curr.altitude + curr.height,
          });
          break;
      }
    });

    const modifierKeys = new BehaviorSubject<ModifierKeys>({});
    const keyboardObserver = this.getKeyboardObservable(modifierKeys);

    const keyboardEventHandler = (e: KeyboardEvent) => {
      this.handleKeyboardEvent(type, e.code, positionObserver, picker, stopFunc);
    };

    document.addEventListener('keyup', keyboardEventHandler);

    const pointerObserver = this.scene.onPointerObservable.add((pointerInfo) => {
      let moveResult: { position: Position; isSnapping: boolean };
      let pickingCompleted: boolean;
      const isAtStart: boolean =
        !!picker.waypoints[0] &&
        !!currentPosition &&
        distanceBetweenPoints(picker.waypoints[0].pos, currentPosition) < snapDistance;

      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERMOVE:
          moveResult = this.handlePointerMove(pointerInfo, isAtStart, isSnapping);
          if (moveResult.position) currentPosition = moveResult.position;
          isSnapping = moveResult.isSnapping;
          break;

        case PointerEventTypes.POINTERWHEEL:
          if (modifierKeys.getValue().type !== 1) break;
          this.updateModifiers(modifierKeys.getValue().code, picker, pointerInfo.event['deltaY']);
          break;

        case PointerEventTypes.POINTERUP:
          if (pointerInfo.event.button !== 0) break;
          pickingCompleted = this.handlePointerUp(positionObserver, currentPosition, picker, isAtStart, type);
          if (pickingCompleted) stopFunc();
          break;
      }
    });

    return stopFunc;
  }

  private handleKeyboardEvent(
    type: EPickerHintModes,
    code: KeyboardEvent['code'],
    positionObserver: Subject<WaypointActionResult>,
    picker: Picker,
    stopFunc: () => void,
  ) {
    switch (code) {
      case 'Enter':
        if (type !== EPickerHintModes.waypointArray) break;
        positionObserver.next({
          type,
          waypoints: picker.waypoints,
          pickerMeta: {
            height: picker.height,
            altitude: picker.altitude,
          },
        });
        stopFunc();
        break;
      case 'Escape':
        positionObserver.next({
          type: 'cancel',
        });
        stopFunc();
        break;
    }
  }

  private handlePointerMove(pointerInfo: PointerInfo, isAtStart: boolean, isSnapping: boolean) {
    if (isAtStart !== isSnapping) {
      if (isAtStart) {
        startArrowGizmoSnapAnim({
          id: MARKER_ID,
          scene: this.scene,
        });
      } else {
        stopArrowGizmoSnapAnim({
          id: MARKER_ID,
          scene: this.scene,
        });
      }
      isSnapping = isAtStart;
    }

    let position: Position;
    const pickInfo: PickingInfo = pointerInfo.pickInfo.ray.intersectsMesh(this.gizmo.plane as any, true);
    if (pickInfo.hit) {
      position = pickInfo.pickedPoint;
      this.updatePickerGizmo(position);
    }
    return { position, isSnapping };
  }

  private handlePointerUp(
    positionObserver: Subject<WaypointActionResult>,
    currentPosition: Position,
    picker: Picker,
    isAtStart: boolean,
    type: EPickerHintModes,
  ) {
    switch (type) {
      case EPickerHintModes.waypoint:
        positionObserver.next({
          type: EPickerHintModes.waypoint,
          pos: roundPositionToDecimals(currentPosition),
          rot: { x: 0, y: 0, z: 0, w: 1 },
          pickerMeta: {
            altitude: picker.altitude,
          },
        });
        return true;
      case EPickerHintModes.waypointArray:
      case EPickerHintModes.polygon:
        if (isAtStart) {
          positionObserver.next({
            type,
            waypoints: picker.waypoints,
            pickerMeta: {
              height: picker.height,
              altitude: picker.altitude,
            },
          });
          return true;
        }
        picker.addPosition({
          pos: roundPositionToDecimals(currentPosition),
          rot: { x: 0, y: 0, z: 0, w: 1 },
        });
        break;
    }
    return false;
  }

  private addNewWaypointToWorkflow(action: WaypointAction, type: EPickerHintModes) {
    const positionObserver = new Subject<WaypointActionResult>();
    this.cancelGoalPositionFunc = this.pickPositions(positionObserver, type);
    positionObserver
      .pipe(takeUntil(this.destroy$), Utils.rxjsNullFilter, first())
      .subscribe((result: WaypointActionResult) => {
        this.waypointBridge.nextWaypointsAction$({
          ...action,
          type: 'result',
          frame: action.frame,
          result: {
            ...action.result,
            ...this.transformWaypointActionResult(action.frame, result),
          },
        });
      });
  }

  private transformWaypointActionResult(frame: string, result: WaypointActionResult): WaypointActionResult {
    if (!frame) return result;
    switch (result.type) {
      case EPickerHintModes.waypoint:
        result.pos = this.transformPosition(frame, result);
        break;
      case EPickerHintModes.waypointArray:
      case EPickerHintModes.polygon:
        result.waypoints = result.waypoints.map((p) => ({
          pos: this.transformPosition(frame, p),
          rot: p.rot,
          pickerMeta: {
            frame,
          },
        }));
        break;
      default:
        return result;
    }
    return result;
  }

  private transformPosition(frame: string, position: WaypointLocation): Position {
    return vector3ToPosition(this.babylonRenderer.pointToFrame(frame, positionToVector3(position.pos)));
  }

  private getKeyboardObservable(modifierKeys: BehaviorSubject<ModifierKeys>): Observer<KeyboardInfo> {
    return this.scene.onKeyboardObservable.add((kbInfo) => {
      if (
        modifierKeys.getValue().type !== kbInfo.type &&
        (kbInfo.event.code === 'KeyA' || kbInfo.event.code === 'KeyH')
      ) {
        modifierKeys.next({
          type: kbInfo.type,
          code: kbInfo.event.code,
        });

        const camera = this.scene.activeCamera;
        if (kbInfo.type === 1) {
          camera.inputs.attached['mousewheel'].detachControl();
        } else {
          camera.inputs.attachInput(camera.inputs.attached['mousewheel']);
        }
      }
    });
  }

  private updateModifiers(code: ModifierKeyCodes, picker: Picker, deltaY: number) {
    switch (code) {
      case 'KeyH':
        picker.height = picker.height + deltaY * 0.005;
        break;
      case 'KeyA':
        picker.altitude = picker.altitude + deltaY * 0.005;
        break;
    }
  }

  // PICKER GIZMOS

  private createPickerGizmo() {
    if (this.gizmo) return;

    const planeMat = new GridMaterial('pickerPlaneMat', this.scene);
    planeMat.opacity = 0.5;
    planeMat.lineColor = SELECTION_COLOR;
    planeMat.mainColor = SELECTION_COLOR;

    const sourcePlane = new Plane(0, 0, 1, 0);
    sourcePlane.normalize();
    const plane = MeshBuilder.CreatePlane('goalPlane', {
      height: Number.MAX_SAFE_INTEGER,
      width: Number.MAX_SAFE_INTEGER,
      sourcePlane,
      sideOrientation: Mesh.DOUBLESIDE,
    });
    plane.position = new Vector3(0, 0, 0.1);

    const altitude = MeshBuilder.CreatePlane('altitudePlane', { height: 100, width: 100 }, this.scene);
    altitude.material = planeMat;
    altitude.rotation.x = Math.PI;
    altitude.rotation.z = Math.PI / 3;
    altitude.position.z = 0.01;

    const xLine = MeshBuilder.CreateLines('xLine', {
      points: [new Vector3(-20, 0, 0.02), new Vector3(20, 0, 0.02)],
      updatable: true,
    });
    xLine.color = SELECTION_COLOR;

    const yLine = MeshBuilder.CreateLines('xLine', {
      points: [new Vector3(0, -20, 0.02), new Vector3(0, 20, 0.02)],
      updatable: true,
    });
    yLine.color = SELECTION_COLOR;

    this.gizmo = {
      plane,
      xLine,
      yLine,
      altitude,
    };
  }

  private updatePickerPlane(altitude: number) {
    if (!this.gizmo) return;
    this.gizmo.altitude.position.z = altitude;
    this.gizmo.plane.position.z = altitude;
    this.gizmo.xLine.position.z = altitude + 0.01;
    this.gizmo.yLine.position.z = altitude + 0.01;
  }

  private updatePickerGizmo(position: Position) {
    if (!this.gizmo) return;
    this.gizmo.xLine.position.x = position.x;
    this.gizmo.xLine.position.y = position.y;
    this.gizmo.yLine.position.x = position.x;
    this.gizmo.yLine.position.y = position.y;
  }

  private removePickerGizmos() {
    if (!this.gizmo) return;
    this.scene.removeMesh(this.gizmo.xLine);
    this.scene.removeMesh(this.gizmo.yLine);
    this.scene.removeMesh(this.gizmo.plane);
    this.scene.removeMesh(this.gizmo.altitude);
    this.gizmo = undefined;
  }
}
