import type { AfterContentInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { MatCheckboxChange } from '@angular/material/checkbox';
import { defaultGridsterOptions } from '@shared-modules/dashboard-v2/services';
import { Widget, WidgetDefaultSize } from '@shared/models';
import type { ICustomWidgetContent } from '@shared/models/widget/widget-custom';
import type { GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DisplayGrid } from 'angular-gridster2';
import defaultStrings from './default-strings.json';
import type { EditorOptions } from '@shared-modules/monaco-editor';

const defaultEditorOptions: EditorOptions = {
  wordWrap: 'on',
  minimap: {
    enabled: false,
  },
};

@Component({
  selector: 'app-custom-widget-builder',
  templateUrl: './custom-widget-builder.component.html',
  styleUrls: ['./custom-widget-builder.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomWidgetBuilderComponent),
    },
  ],
})
export class CustomWidgetBuilderComponent implements OnInit, AfterContentInit, ControlValueAccessor, OnChanges {
  @Input()
  public defaultRows: number = WidgetDefaultSize.rows * 2;
  @Input()
  public defaultCols: number = WidgetDefaultSize.cols * 3;
  @Input()
  public projectWidget = false;
  @Input() public demoWidget: Widget;

  @Output()
  public rowsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public colsChange: EventEmitter<number> = new EventEmitter<number>();

  public _widgetRows: number = this.defaultRows;
  public _widgetCols: number = this.defaultCols;
  public inBackground: UntypedFormControl;
  public set widgetRows(rows: number) {
    this._widgetRows = rows;
    this.rowsChange.emit(rows);
    if (this.demoWidget) {
      this.demoWidget.rows = rows;
    }
  }
  public set widgetCols(cols: number) {
    this._widgetCols = cols;
    this.colsChange.emit(cols);
    if (this.demoWidget) {
      this.demoWidget.cols = cols;
    }
  }

  public widgetEditMode: boolean = false;

  public editorHeight: string = '330px';
  public editorWrapperHeight: string = '350px';

  public htmlCode: string = defaultStrings.html;
  public cssCode: string = defaultStrings.css;
  public jsCode: string = defaultStrings.js;

  public updatedHtmlCode: string;
  public updatedCssCode: string;
  public updatedJsCode: string;

  public htmlEditorOptions: EditorOptions = {
    language: 'html',
    ...defaultEditorOptions,
  };

  public jsEditorOptions: EditorOptions = {
    language: 'javascript',
    ...defaultEditorOptions,
  };

  public cssEditorOptions: EditorOptions = {
    language: 'css',
    ...defaultEditorOptions,
  };

  public gridOptions: GridsterConfig = {};
  public gridItem = {};

  public get content(): ICustomWidgetContent {
    return {
      jsCode: this.jsCode,
      htmlCode: this.htmlCode,
      cssCode: this.cssCode,
    };
  }

  public constructor() {
    this.inBackground = new UntypedFormControl();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['demoWidget']) {
      this.widgetChanged();
    }
  }

  public widgetChanged(): void {
    if (this.demoWidget?.externalContent) {
      this.jsCode = this.demoWidget.externalContent.content.jsCode;
      this.htmlCode = this.demoWidget.externalContent.content.htmlCode;
      this.cssCode = this.demoWidget.externalContent.content.cssCode;
      this.applyCode();
    } else if (this.demoWidget) {
      this.demoWidget.externalContent = {
        type: '',
        content: {
          jsCode: this.jsCode,
          htmlCode: this.htmlCode,
          cssCode: this.cssCode,
        },
      };
    }

    if (this.demoWidget?.layerIndex === -1) {
      this.inBackground.setValue(true);
    }
  }

  public ngOnInit(): void {
    this.jsCode = this.projectWidget ? defaultStrings.projectJs : defaultStrings.js;
    this.htmlCode = this.projectWidget ? defaultStrings.projectHtml : defaultStrings.html;
    this.cssCode = this.projectWidget ? defaultStrings.projectCss : defaultStrings.css;

    this.gridOptions = {
      ...defaultGridsterOptions,

      itemResizeCallback: this.itemResize.bind(this),

      displayGrid: DisplayGrid.None,
      draggable: {
        enabled: this.widgetEditMode,
      },
      resizable: {
        enabled: this.widgetEditMode,
      },
      outerMarginTop: 0,
      defaultItemCols: this.defaultCols,
      defaultItemRows: this.defaultRows,
    };
  }

  public ngAfterContentInit(): void {
    this.applyCode();
    this.propagateChange(this.content);
  }

  //#region ControlValueAccessor Implementation
  public writeValue(content: ICustomWidgetContent): void {
    if (content) {
      this.htmlCode = content.htmlCode;
      this.cssCode = content.cssCode;
      this.jsCode = content.jsCode;
    }
  }

  public registerOnChange(fn: (unknown) => void): void {
    this.propagateChange = fn;
  }

  // Empty function required by ControlValueAccessor

  public registerOnTouched(): void {
    // no-op - required for ControlValueAccessor implementation
  }
  //#endregion

  public onCodeChanged(code: string, type: string): void {
    switch (type) {
      case 'html':
        this.htmlCode = code;
        break;
      case 'css':
        this.cssCode = code;
        break;
      case 'js':
        this.jsCode = code;
        break;
    }

    this.propagateChange(this.content);
    this.saveWidget();
  }

  public saveWidget(): void {
    this.demoWidget.externalContent = {
      type: '',
      content: {
        jsCode: this.jsCode,
        htmlCode: this.htmlCode,
        cssCode: this.cssCode,
      },
    };
  }

  public onWidgetEditModeChange(edit: boolean): void {
    this.widgetEditMode = edit;

    this.gridOptions.draggable.enabled = edit;
    this.gridOptions.resizable.enabled = edit;
    this.gridOptions.displayGrid = edit ? DisplayGrid.Always : DisplayGrid.None;

    this.gridOptionsHaveBeenUpdated();
  }

  public onUpdatePreview(): void {
    this.applyCode();
  }

  public onCheckboxChanged($event: MatCheckboxChange): void {
    if ($event.checked) {
      this.demoWidget.layerIndex = -1;
    } else {
      this.demoWidget.layerIndex = 0;
    }
  }

  public applyCode(): void {
    this.updatedHtmlCode = this.htmlCode;
    this.updatedCssCode = this.cssCode;
    this.updatedJsCode = this.jsCode;
    this.propagateChange(this.content);
  }

  private gridOptionsHaveBeenUpdated(): void {
    if (this.gridOptions.api) {
      this.gridOptions.api.optionsChanged();
    }
  }

  private itemResize(_item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
    if (itemComponent?.$item) {
      this.widgetCols = itemComponent.$item.cols;
      this.widgetRows = itemComponent.$item.rows;
    }
  }

  private propagateChange = (_: unknown) => {
    // no-op - required for ControlValueAccessor implementation
  };
}
