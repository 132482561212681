import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  convertNumberEnum(value: any): { value: number; name: string }[] {
    return Object.keys(value)
      .filter((e) => !isNaN(+e))
      .map((o) => {
        return {
          name: value[o],
          value: +o,
        };
      });
  }

  convertStringEnum(value: any): { value: string; name: string }[] {
    return Object.keys(value).map((o) => {
      return {
        name: o,
        value: value[o],
      };
    });
  }

  transform(value: any, args?: any): { value: number | string; name: string }[] {
    let isStringValue = false;
    if (args && args === 'string') {
      isStringValue = true;
    }

    return isStringValue ? this.convertStringEnum(value) : this.convertNumberEnum(value);
  }
}
