export class UserPermission {
  public activeTenant: string;
  public tenants: string[];

  public static fromMetadata(metadata: any): UserPermission {
    const userPermission = new UserPermission();

    if (metadata?.permissions) {
      const permissions = metadata.permissions;

      userPermission.activeTenant = permissions.activeTenant;
      userPermission.tenants = permissions.tenants;
    }

    return userPermission;
  }
}
