import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Utils } from '../../utils';
import { RocosClientService } from '../rocos-client';

export class PGMFile {
  rawText: string;
  width: number;
  height: number;
  maxValue: number; // Black: 0, White: MaxValue
  data: number[] = [];

  // Stats
  stats: {
    [code: string]: number;
  } = {};

  constructor(text: string) {
    this.rawText = text;
    this.convertTextToObject(this.rawText);
  }

  private convertTextToObject(text: string) {
    if (text) {
      const lines = text.split('\n');

      // 5 Lines:
      // 0) Type: P5
      // 1) Comment: # CREATOR: Map_generator.cpp 0.050 m/pix
      // 2) Width Height: 439 570
      // 3) Max Value: 255
      // 4) Data: [1,2] [3,4] [5,6] ...
      if (lines?.length === 5) {
        const line2 = lines[2].trim();
        const line3 = lines[3].trim();
        const line4 = lines[4].trim();

        this.parseWidthHeightLine(line2);
        this.parseMaxValueLine(line3);
        this.parseDataLine(line4);
      }
    }
  }

  private parseWidthHeightLine(data: string) {
    const arr = data.split(' ');
    if (arr.length === 2) {
      this.width = +arr[0];
      this.height = +arr[1];
    }
  }

  private parseMaxValueLine(data: string) {
    this.maxValue = +data;
  }

  private parseDataLine(data: string) {
    const arr = [];
    const stats = {};

    for (let i = 0; i < data.length; i++) {
      const code = data.charCodeAt(i);

      arr.push(code);

      // Update stats
      if (stats[code]) {
        stats[code] = stats[code] + 1;
      } else {
        stats[code] = 1;
      }
    }

    if (arr?.length > 0) {
      this.data = arr;
    }
    this.stats = stats;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OccupancymapService {
  constructor(private clientService: RocosClientService) {}

  public getImageDataFromTopic(occMapData) {
    const data = occMapData.data;
    const height = occMapData.info.height;
    const width = occMapData.info.width;

    return this.getImageData(data, width, height);
  }

  public getImageDataFromFile(projectId: string, callsign: string, filename: string, metaFilename: string): any {
    // Download files together
    return forkJoin(
      // Download PGM file
      this.clientService.downloadFile(projectId, callsign, filename),
      // Download Metadata (yaml) file
      this.clientService.downloadFile(projectId, callsign, metaFilename),
    ).pipe(
      flatMap(([imageFileBlob, metaFileBlob]) => {
        const readFileAsBinaryString = (file): Observable<string> => {
          return Observable.create((observable) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              observable.next(fileReader.result);
              observable.complete();
            };
            fileReader.readAsBinaryString(file);
          });
        };

        return forkJoin(readFileAsBinaryString(imageFileBlob), readFileAsBinaryString(metaFileBlob));
      }),
      map(([imageFile, metaFile]) => {
        const metaData = Utils.getMetaDataFromLines(metaFile);
        const pgmFile = new PGMFile(imageFile);
        const imageData = this.getImageData(pgmFile.data, pgmFile.width, pgmFile.height, true);

        // yaml file has origin and resolution as string, so convert
        const origin = JSON.parse(metaData['origin']);
        const resolution = +metaData['resolution'];

        return {
          imageData,
          width: pgmFile.width,
          height: pgmFile.height,
          resolution,
          position: {
            x: origin[0],
            y: origin[1],
            z: origin[2],
          },
        };
      }),
    );
  }

  public getImageData(data: number[], width: number, height: number, pgmFormat: boolean = false) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    const img = ctx.getImageData(0, 0, width, height);

    let pixelNum = 0;

    const colorUnexplored = [230, 230, 230, 0];
    const colorNoBarrier = [255, 255, 255, 255];
    const colorBarrier = [0, 0, 0, 255];

    if (pgmFormat) {
      for (let row = height - 1; row >= 0; row--) {
        for (let col = 0; col < width; col++) {
          const pos = (row * width + col) * 4;

          const num = data[pixelNum];
          {
            img.data[pos] = num;
            img.data[pos + 1] = num;
            img.data[pos + 2] = num;
            img.data[pos + 3] = 255;
          }
          pixelNum++;
        }
      }
      // num contains the greyscale color between 0 and 254 with no alpha
    } else {
      for (let row = 0; row < height; row++) {
        for (let col = 0; col < width; col++) {
          const pos = (row * width + col) * 4;

          const num = data[pixelNum];
          // in ROS format, expecting -1 for unexplored, 0 for no barrier and 100 for barrier
          switch (num) {
            case -1:
              img.data[pos] = colorUnexplored[0];
              img.data[pos + 1] = colorUnexplored[1];
              img.data[pos + 2] = colorUnexplored[2];
              img.data[pos + 3] = colorUnexplored[3];
              break;
            case 0:
              img.data[pos] = colorNoBarrier[0];
              img.data[pos + 1] = colorNoBarrier[1];
              img.data[pos + 2] = colorNoBarrier[2];
              img.data[pos + 3] = colorNoBarrier[3];
              break;
            case 100:
              img.data[pos] = colorBarrier[0];
              img.data[pos + 1] = colorBarrier[1];
              img.data[pos + 2] = colorBarrier[2];
              img.data[pos + 3] = colorBarrier[3];
              break;
          }
          pixelNum++;
        }
      }
    }

    return img;
  }
}
