import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gamepad-value-bar',
  templateUrl: './gamepad-value-bar.component.html',
  styleUrls: ['./gamepad-value-bar.component.scss'],
})
export class GamepadValueBarComponent {
  @Input()
  public value: number;

  public readonly items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
}
