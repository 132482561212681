import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services';
import { initialize } from 'launchdarkly-js-client-sdk';
import type { LDClient, LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from '@env/environment';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { FeatureFlags, defaultFeatureFlags } from './feature-flags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public featureFlags$: Observable<Record<FeatureFlags, boolean>>;
  private _featureFlags$ = new BehaviorSubject<Record<FeatureFlags, boolean>>(defaultFeatureFlags);

  private client: LDClient;

  public constructor(private authService: AuthService) {
    const userId = this.authService.userInfo.id.split('|').at(-1);

    this.client = initialize(environment.launchDarkly.clientId, {
      kind: 'user',
      key: userId,
      email: this.authService.userInfo.email,
      firstName: this.authService.userInfo.firstName,
      lastName: this.authService.userInfo.lastName,
    });

    this.client.on('change', (flags: LDFlagChangeset) => {
      this.updateFlags(flags);
    });

    this.client.on('ready', () => {
      this.updateFlags(this.client.allFlags());
    });

    this.featureFlags$ = this._featureFlags$.asObservable();
  }

  private updateFlags = (flags: LDFlagSet | LDFlagChangeset) => {
    const newFlags = {};
    Object.values(FeatureFlags).forEach((feature) => {
      const flagValue: boolean =
        flags[feature] && isLDFlagChangeset(flags[feature])
          ? flags[feature].current
          : flags[feature] ?? defaultFeatureFlags[feature];
      newFlags[feature] = flagValue;
    });

    this._featureFlags$.next({ ...this._featureFlags$.getValue(), ...newFlags });
  };
}

const isLDFlagChangeset = (flags: LDFlagSet | LDFlagChangeset): flags is LDFlagChangeset => {
  return Object.prototype.hasOwnProperty.call(flags, 'current');
};
