import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource,
} from '@angular/material/legacy-table';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DataExplorerPreviewMethod, TreeItem } from '../../models';
import type { SubscribeResult } from '../../services';
import { RocosClientService, ToastService } from '../../services';
import { Utils } from '../../utils';
import { PreviewLineGraphComponent } from '../preview-line-graph';

@Component({
  selector: 'app-slowlane-data-source-preview',
  templateUrl: './slowlane-data-source-preview.component.html',
  styleUrls: ['./slowlane-data-source-preview.component.scss'],
})
export class SlowlaneDataSourcePreviewComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('lineGraphComponent') public lineGraphComponent: PreviewLineGraphComponent;
  @ViewChild('logTableContainer') logTableContainer: ElementRef;
  @ViewChild('preContainer') preContainer: ElementRef;
  @ViewChild('logTable') logTable: MatTable<any>;

  @Input() public projectId: string;
  @Input() public callsign: string;
  @Input() public slowlaneMode: boolean;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() topic: string;
  @Input() attribute: any;
  @Input() sources: any[];
  @Input() callsigns: any[];

  @Input() public sourceQuerystring: string;
  @Input() public isPaused: boolean = false;
  @Input() public insertDataURIMode: boolean;
  @Input() public dataStreamMode: boolean;
  @Output() public dataURIChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public dataItemChange: EventEmitter<any> = new EventEmitter<any>();

  totalCount: any;
  payloads: any[];
  filterGenerated: boolean;
  pageSize: number = 50;
  displayedColumns: string[] = [];
  displayedColumnsNames: any;
  dataSource: MatTableDataSource<any>;
  pageFrom: number;
  isLoading: boolean;
  isLoadingMore: boolean;
  slowlaneDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  slowlaneDataObservable: Observable<any>;

  public filterForm: UntypedFormGroup;

  /**
   * Output value: for line graph or other preview components to render content.
   */
  public outputValue: any;

  public currentPreviewMethod: DataExplorerPreviewMethod = DataExplorerPreviewMethod.json;
  public previewMethods: DataExplorerPreviewMethod[] = [];
  public logFilters: any[] = [];

  // For refreshing the app-data-performance
  public latestMessageReceivedAt: Date;

  private _sourceInfo: TreeItem;

  @Input()
  public set sourceInfo(val: TreeItem) {
    this._sourceInfo = val;
  }

  public get sourceInfo(): TreeItem {
    return this._sourceInfo;
  }

  private _output: any;
  public get output(): any {
    return this._output;
  }

  public set output(val: any) {
    this._output = val;
    this.latestMessageReceivedAt = new Date();
  }

  private subscriptions: SubscribeResult[] = [];

  constructor(
    private rocosClientService: RocosClientService,
    private formBuilder: UntypedFormBuilder,
    private toast: ToastService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  get formArrayFilters() {
    return this.filterForm.get('filters') as UntypedFormArray;
  }

  projectAttributeQuery(query) {
    const dataId = Utils.getDataIdBySourceString(this.topic);
    const attributePath = `payload_${dataId}.${this.attribute.attribute}`;
    const dataIdPath = `dataId`;
    query._source = {
      includes: [attributePath, dataIdPath, 'payload'],
    };
  }

  reloadPayloads() {
    // Reset page from
    this.pageFrom = 0;

    const query = this.generateQuery();
    this.isLoading = true;

    this.rocosClientService
      .slowLaneQueryData(this.projectId, JSON.stringify(query))
      .pipe(first())
      .subscribe(
        (res) => {
          const json = this.tryConvertToJSON(res);

          this.payloads = this.getPayloadsFromJSONResponse(json);

          const data = this.extractSlowlaneData();

          this.slowlaneDataSubject.next(data);

          this.isLoading = false;
        },
        (err) => {
          if (err?.message) {
            this.toast.short(err.message, null, 'failure');
          }

          this.isLoading = false;
        },
      );
  }

  extractSlowlaneData() {
    const dataType = 'array';
    const childKey: string = this.attribute.attribute;

    const data = {
      dataType,
      value: [],
    };

    this.payloads.forEach((result) => {
      if (!result?.['payload']) return;

      const parsedPayload = JSON.parse(result['payload']);
      const childAttribute = parsedPayload?.[childKey];

      if (!Array.isArray(childAttribute)) return;

      data.value = data.value.concat(childAttribute);
    });

    if (data.value.length) {
      this.onSelectPreviewMethod(DataExplorerPreviewMethod.table);
    }

    return data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.slowlaneMode) return;

    if (changes['startDate'] || changes['endDate']) {
      this.loadSlowlaneData();
    }
  }

  ngOnInit() {
    if (this.dataStreamMode) {
      this.loadChildrenSchema();
    } else {
      if (this.slowlaneMode) {
        this.setSourceInfo();
        this.slowlaneDataObservable = this.slowlaneDataSubject.asObservable();
        if (this.slowlaneDataObservable) {
          this.slowlaneDataObservable.subscribe((data) => {
            if (data) {
              this.loadSlowlanePreviewMethods(data.dataType);
              this.prepareDataForTable(data.value, data.dataType);
              this.output = this.prepareDataForPre(data.value);
            }
          });
        }
      } else {
        this.loadPreviewMethods();
      }
    }

    this.createFilterForm();
  }
  setSourceInfo() {
    const filteredSources = this.sources.filter((x) => {
      return x.path === this.topic;
    });

    if (filteredSources.length > 0) {
      this.sourceInfo = this.sources[0];
    }
  }

  createFilterForm() {
    this.filterForm = this.formBuilder.group({
      filters: this.formBuilder.array([]),
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public onInsertDataURI() {
    this.dataURIChange.next(this.sourceInfo.path);
    this.dataItemChange.next(this.sourceInfo);
  }

  public onSelectPreviewMethod(method) {
    this.currentPreviewMethod = method;
  }

  getLevelClass(element, column) {
    let theClass = 'logCell ';

    if (column !== 'level') return theClass;

    if (element[column]) {
      const firstFourLetter = element[column].substr(0, 4).toLowerCase();
      switch (firstFourLetter) {
        case 'fata':
          theClass += 'fatal';
          break;
        case 'crit':
          theClass += 'critical';
          break;
        case 'erro':
          theClass += 'error';
          break;
        case 'warn':
          theClass += 'warning';
          break;
        case 'info':
          theClass += 'info';
          break;
        case 'debu':
          theClass += 'debug';
          break;
        case 'trac':
          theClass += 'trace';
          break;
        case '':
          theClass += 'fatal';
          break;
      }
    }
    return theClass;
  }

  scrollToBottom(nativeElement) {
    if (!nativeElement) return;

    if (nativeElement.scrollTop + nativeElement.offsetHeight === nativeElement.scrollHeight) {
      setTimeout(() => {
        nativeElement.scrollTop = nativeElement.scrollHeight;
      }, 100);
    }
  }

  onLoadMore() {
    // Should not loading more when loading.
    if (this.isLoadingMore) {
      return;
    }
    this.loadMore();
  }

  onFilterChanged() {
    const filters = this.formArrayFilters.value
      .filter((x) => {
        // keep what we can to display
        return x.checkedStatus;
      })
      .map((x) => x.value);

    this.displayedColumns = filters;
  }

  createFilterGroup(t: any): any {
    return this.formBuilder.group({
      label: t,
      value: t[0].toLowerCase() + t.substr(1),
      checkedStatus: true,
    });
  }

  private generateQuery() {
    const callsigns = this.callsigns || [];
    const startDate = this.startDate || new Date();
    const endDate = this.endDate || new Date();

    const query = {
      size: this.pageSize,
      from: this.pageFrom,
      query: {
        bool: {
          must: [
            {
              range: {
                dateReceived: {
                  gte: startDate.getTime(),
                  lt: endDate.getTime(),
                },
              },
            },
          ],
        },
      },
      sort: [
        {
          dateCreated: {
            order: 'asc',
          },
        },
      ],
    };

    if (callsigns?.length > 0) {
      query.query.bool['minimum_should_match'] = 1;
      query.query.bool['should'] = callsigns.map((callsign) => {
        return {
          match_phrase: {
            'callsign.keyword': callsign,
          },
        };
      });
    }

    this.insertSourcesQuery(query, [this.topic]);
    this.projectAttributeQuery(query);

    return query;
  }

  private insertSourcesQuery(query: any, sources: string[]) {
    if (sources?.length > 0) {
      const shouldQuery = [];
      sources.forEach((source) => {
        const sourceId = Utils.getSourceIdBySourceString(source);
        const dataId = Utils.getDataIdBySourceString(source);

        shouldQuery.push({
          bool: {
            must: [
              {
                match: {
                  'dataId.keyword': {
                    query: dataId,
                    // type: 'phrase'
                  },
                },
              },
              {
                match: {
                  sourceId: {
                    query: sourceId,
                    // type: 'phrase'
                  },
                },
              },
            ],
          },
        });
      });

      query.query.bool.must.push({
        bool: {
          should: shouldQuery,
        },
      });
    }
    return query;
  }
  private tryConvertToJSON(input: string) {
    let output;
    try {
      output = JSON.parse(input);
    } catch {
      output = null;
    }

    return output;
  }
  private getPayloadsFromJSONResponse(json: any) {
    let payloads = [];
    if (json?.hits?.hits) {
      const list = json.hits.hits;

      payloads = list.map((item) => {
        return item._source;
      });
    }

    if (json?.hits?.total !== undefined) {
      this.totalCount = json.hits.total;
    } else {
      this.totalCount = 0;
    }

    return payloads;
  }

  private loadSlowlaneData() {
    // reset the data
    this.dataSource.data = [];
    this.reloadPayloads();
  }

  private getArrayValueFromJsonObject(jsonObject, dataType) {
    let arrayValue = null;
    if (dataType === 'object') {
      const keys = Object.keys(jsonObject);
      if (keys?.length === 1) {
        const firstItem = jsonObject[keys[0]];
        if (Array.isArray(firstItem)) {
          arrayValue = firstItem;
        }
      }
    }

    if (dataType === 'array') {
      arrayValue = jsonObject;
    }

    return arrayValue;
  }

  private prepareDataForPre(value) {
    const output = JSON.stringify(value, null, 2);
    if (this.preContainer) {
      this.scrollToBottom(this.preContainer.nativeElement);
    }
    return output;
  }

  private getDisplayedColumns(keys) {
    if (this.filterGenerated) {
      return;
    }

    this.displayedColumns = keys.filter((k) => {
      return k !== 'time' && k !== 'tim';
    });

    this.displayedColumnsNames = {};

    this.logFilters = keys
      .map((k) => {
        const newKey = k[0].toUpperCase() + k.substr(1);
        this.displayedColumnsNames[k] = newKey;
        return newKey;
      })
      .filter((k) => {
        return k !== 'Time' && k !== 'Tim';
      });

    const filterArray = this.logFilters.map((t) => {
      return this.createFilterGroup(t);
    });

    if (filterArray?.length > 0 && !this.filterGenerated) {
      this.filterGenerated = true;
      this.filterForm.setControl('filters', this.formBuilder.array(filterArray));
    }
  }

  private prepareDataForTable(value, dataType) {
    const arrayValue = this.getArrayValueFromJsonObject(value, dataType);

    if (!arrayValue?.length) return;

    const firstItem = arrayValue[0];
    const keys = Object.keys(firstItem);

    this.getDisplayedColumns(keys);

    const rows = arrayValue.map((v) => {
      const o = {};
      keys.forEach((k) => {
        // timestamp can have different key names in different data, use time everywhere
        let newKeyName = k;
        if (k === 'tim') {
          newKeyName = 'time';
        }
        if (typeof v[k] === 'string') {
          o[newKeyName] = JSON.stringify(v[k], null, 2).replace(/^"+|"+$/g, '');
        } else {
          o[newKeyName] = JSON.stringify(v[k], null, 2).replace(/^{|}$/gs, '').replace(/^ {2}/gm, '').trim();
        }
      });
      return o;
    });

    this.dataSource.data = rows;

    // for slowlane, we don't have limit
    // take only the last 4000
    // const maxRows = 4000;
    // if (this.dataSource.data.length > maxRows) {
    //   const offset = this.dataSource.data.length - maxRows;
    //   this.dataSource.data = this.dataSource.data.slice(offset);
    // }

    if (this.logTable) {
      this.scrollToBottom(this.logTableContainer.nativeElement);
      this.logTable.renderRows();
    }
  }

  private loadMore() {
    // Add offset
    this.pageFrom += this.pageSize;

    const query = this.generateQuery();
    this.isLoadingMore = true;

    this.rocosClientService
      .slowLaneQueryData(this.projectId, JSON.stringify(query))
      .pipe(first())
      .subscribe(
        (res) => {
          const json = this.tryConvertToJSON(res);

          const payloads = this.getPayloadsFromJSONResponse(json);

          // append new data to already fetched data
          this.payloads.push(...payloads);

          const data = this.extractSlowlaneData();
          // notify subscriber data has been updated
          this.slowlaneDataSubject.next(data);

          this.isLoadingMore = false;
        },
        (err) => {
          if (err?.message) {
            this.toast.short(err.message, null, 'failure');
          }
          this.isLoadingMore = false;
        },
      );
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((sub) => {
      this.rocosClientService.unsubscribe(sub);
    });
  }

  private loadPreviewMethods() {
    const methods = [];

    // Must have JSON at least
    methods.push(DataExplorerPreviewMethod.json);

    const sourceDataType = this.sourceInfo.dataType;
    switch (sourceDataType) {
      case 'integer':
      case 'number':
        methods.push(DataExplorerPreviewMethod.lineGraph);
        break;
      case 'array':
        methods.push(DataExplorerPreviewMethod.table);
        break;
      case 'object':
        if (this.sourceInfo.children?.[0]?.dataType === 'array') {
          methods.push(DataExplorerPreviewMethod.table);
        }
    }

    this.previewMethods = methods;
  }

  private loadSlowlanePreviewMethods(dataType) {
    const methods = [];

    // Must have JSON at least
    methods.push(DataExplorerPreviewMethod.json);

    const sourceDataType = dataType;
    switch (sourceDataType) {
      case 'array':
        methods.push(DataExplorerPreviewMethod.table);
    }

    this.previewMethods = methods;
  }

  private loadChildrenSchema() {
    const schema = this.sourceInfo.getChildrenSchema();
    this.output = JSON.stringify(schema, null, 2);
  }
}
