import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'gamepadCommandParam',
})
export class GamepadCommandParamPipe implements PipeTransform {
  transform(value: string): string {
    return `$\{$params.${value}}`;
  }
}
