import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';
import { UserService } from '../user';
import { AppService } from '../app';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private initialized = false;

  constructor(private userService: UserService, private appService: AppService) {}

  public init() {
    if (this.initialized) {
      console.warn('Analytics already initialized, skipping');
      return;
    }

    this.initialized = true;

    datadogRum.init({ ...environment.datadog });

    this.userService.userUpdated.subscribe((user) => {
      datadogRum.stopSessionReplayRecording();

      if (!user) return;

      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.fullName,
        activeOrganizationId: user.activeAccount?.id,
        activeOrganizationName: user.activeAccount?.name,
      });

      datadogRum.startSessionReplayRecording();
    });

    this.appService.callsignChange.subscribe((callsign) => datadogRum.setUserProperty('callsign', callsign));
    this.appService.accountIdChange.subscribe((accountId) => datadogRum.setUserProperty('accountId', accountId));
    this.appService.projectIdChange.subscribe((projectId) => datadogRum.setUserProperty('projectId', projectId));
  }
}
