import type { Account, Project } from '@shared/models';

export interface CommandPaletteState {
  account?: Account;
  project?: Project;
  projectId?: string;
  robot?: {
    callsign: string;
    profileId?: string;
  };
  robotDashboard?: {
    id: string;
    name: string;
  };
  profile?: { name: string; id: string };
  profileDashboard?: { name: string; id: string };
  operation?: {
    id: string;
    name: string;
    type: string;
  };
  workflow?: { name: string; id: string };
}

export interface Suggestion<T = any> {
  id: string;
  name: string;
  meta?: T;
}

export enum SuggestionLevel {
  PROJECTS = 'Projects',
  PROJECT = 'Project',
  ROBOTS = 'Robots',
  ROBOT = 'Robot',
  ROBOT_DASHBOARDS = 'Robot Dashboards',
  ROBOT_DASHBOARD = 'Robot Dashboard',
  ROBOT_SETTINGS = 'Robot Settings',
  ROBOT_EVENTS = 'Robot Events',
  ROBOT_COMMANDS = 'Robot Commands',
  ROBOT_CONTROLS = 'Robot Controls',
  ROBOT_BUTTONS = 'Robot Buttons',
  ROBOT_GAMEPADS = 'Robot Gamepads',
  ROBOT_STORAGE_STREAMS = 'Robot Storage Streams',
  ROBOT_LDV = 'Robot LDV',
  ROBOT_FILES = 'Robot Files',
  ROBOT_SHELL = 'Robot Shell',
  ROBOT_AUTOMATE = 'Robot Automate',
  ROBOT_ROBOT_MAPS = 'Robot Robot Maps',
  PROFILES = 'Profiles',
  PROFILE = 'Profile',
  PROFILE_DASHBOARDS = 'Profile Dashboards',
  PROFILE_DASHBOARD = 'Profile Dashboard',
  PROFILE_SETTINGS = 'Profile Settings',
  PROFILE_EVENTS = 'Profile Events',
  PROFILE_COMMANDS = 'Profile Commands',
  PROFILE_CONTROLS = 'Profile Controls',
  PROFILE_BUTTONS = 'Profile Buttons',
  PROFILE_GAMEPADS = 'Profile Gamepads',
  PROFILE_STORAGE_STREAMS = 'Profile Storage Streams',
  PROFILE_PROVISIONING = 'Profile Provisioning',
  OPERATIONS = 'Operations',
  OPERATION = 'Operation',
  AUTOMATES = 'Automates',
  AUTOMATE = 'Automate',
  STORAGE_STREAMS = 'Storage Streams',
  STORAGE_STREAM = 'Storage Stream',
  STORAGE_EXPLORER = 'Storage Explorer',
}

export interface SuggestionNode {
  level: SuggestionLevel;
  children?: SuggestionNode[];
  getBreadcrumb?: () => string | undefined;
  getSuggestions?: () => Promise<Suggestion[]>;
  selectionHandler?: (suggestion: Suggestion) => SuggestionLevel;
  deselectionHandler?: () => void;
  runAction: () => boolean;
}
