import { Component } from '@angular/core';
import type { User } from '@shared/models';
import { UserService, GotoService } from '@shared/services';

@Component({
  selector: 'app-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.scss'],
})
export class SwitchAccountComponent {
  user: User;

  constructor(private userService: UserService, private goto: GotoService) {
    this.user = this.userService.user;
  }

  onContinue() {
    this.goto.agentCallback();
  }

  onLogin() {
    this.goto.login();
  }
}
