import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { gRPCSource } from '@team-rocos/rocos-js';
import type { Subscription } from 'rxjs';
import { merge } from 'rxjs';
import { first } from 'rxjs/operators';
import type { IsLoading } from '../../interfaces';
import { RichSchemaItem, TreeItem } from '../../models';
import type { SubscribeResult } from '../../services';
import { DevelopmentService, RobotService, RocosClientService, UserService } from '../../services';
import { Utils } from '../../utils';

@Component({
  selector: 'app-data-sources-tree',
  templateUrl: './data-sources-tree.component.html',
  styleUrls: ['./data-sources-tree.component.scss'],
})
export class DataSourcesTreeComponent implements OnChanges, OnDestroy, IsLoading {
  @Input()
  public insertDataURIMode: boolean;

  @Input()
  public projectId: string;

  @Input()
  public callsign: string;

  @Input()
  public dataStreamMode: boolean;

  @Input()
  public showServices: boolean = false;

  @Input()
  public showMessages: boolean = true;

  @Input()
  public insertSourceOnly: boolean = false;

  @Input() showLoading = true;

  @Output()
  public selectionChange: EventEmitter<TreeItem[]> = new EventEmitter<TreeItem[]>();

  @Output()
  public dataURIChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public dataItemChange: EventEmitter<TreeItem> = new EventEmitter<any>();

  @Output()
  public treeDataChange: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();

  @Output()
  public getCallablesFailed: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = false;
  public timedOut = false;

  bothSourcesSubscription: Subscription;
  public sources: string[] = [];

  private _treeData: TreeItem;

  public get treeData(): TreeItem {
    return this._treeData;
  }

  public set treeData(data: TreeItem) {
    this._treeData = data;

    this.treeDataChange.emit(this._treeData);
  }

  private sub: Subscription;

  /**
   * Load tree by fast lane sources message.
   */
  private sourcesSubscription: SubscribeResult;

  private jsonSchema: TreeItem;

  private richSchemaItems: RichSchemaItem[] = [];

  constructor(
    private robotService: RobotService,
    private rocosClientService: RocosClientService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private devService: DevelopmentService,
  ) {}

  ngOnDestroy() {
    this.tryUnsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['callsign']) {
      this.loadTreeData();
    }
  }

  public onDataURIChange(dataURI: string) {
    this.dataURIChange.next(dataURI);
  }

  public onDataItemChange(item: TreeItem) {
    this.dataItemChange.next(item);
  }

  public onSelectionChange() {
    const list = this.treeData.getSelectedItems();

    this.selectionChange.next(list);

    this.cdr.detectChanges();
  }

  public clearAllSelection() {
    if (this.treeData) {
      this.treeData.clearAllSelection();

      const list = this.treeData.getSelectedItems();

      this.selectionChange.next(list);
    }
  }

  /**
   * Load tree data.
   */
  private loadTreeData() {
    // Try to unsubscribe first
    this.tryUnsubscribe();

    this.isLoading = true;
    this.timedOut = false;

    if (this.dataStreamMode && !this.callsign) {
      // Load schema without callsign
      this.robotService
        .dataSourcesJsonSchema()
        .pipe(first())
        .subscribe((res) => {
          if (res) {
            this.isLoading = false;
            this.timedOut = false;

            const treeData = TreeItem.fromJSONSchema(res, null, true);

            this.treeData = treeData;
          }
        });
    } else {
      // Load fast lane sources
      this.ngZone.runOutsideAngular(async () => {
        await this.loadSources();
      });

      // Load schema
      this.loadSchema();
    }
  }

  private async loadSources() {
    NgZone.assertNotInAngularZone();

    let isAdmin = false;
    isAdmin = this.userService.isAdmin() && !this.devService.isHideAdminOnlyContent;
    let serviceCallerObservable = null;
    if (this.showServices) {
      serviceCallerObservable = await this.rocosClientService.getServiceCallerObservable(
        this.projectId,
        this.callsign,
        false,
        isAdmin,
      );
    }

    let telemetryObservable = null;
    telemetryObservable = await this.rocosClientService.getServiceCallerObservable(
      this.projectId,
      this.callsign,
      true,
      isAdmin,
    );

    if (!telemetryObservable) {
      // fallback to use fastlane
      this.sourcesSubscription = this.rocosClientService.subscribe(
        this.projectId,
        [this.callsign],
        [gRPCSource.types.sources],
      );

      const fastlaneSourcesObservable = this.sourcesSubscription.observable;
      telemetryObservable = fastlaneSourcesObservable;
    }

    let bothObservable;
    if (this.showMessages === true && this.showServices === true && serviceCallerObservable != null) {
      bothObservable = merge(...[telemetryObservable, serviceCallerObservable]);
    } else if (this.showMessages === true) {
      bothObservable = telemetryObservable;
    } else if (this.showServices === true && serviceCallerObservable != null) {
      bothObservable = serviceCallerObservable;
    }

    if (this.showServices && serviceCallerObservable === null) {
      this.getCallablesFailed.next(true);
    }

    if (bothObservable) {
      this.bothSourcesSubscription = bothObservable.subscribe((msg: any) => {
        if (msg) {
          this.ngZone.run(() => {
            this.receivedMessage(msg);
          });
        }
      });
    }
  }

  private tryUnsubscribe() {
    if (this.sourcesSubscription) {
      this.rocosClientService.unsubscribe(this.sourcesSubscription);
    }

    if (this.bothSourcesSubscription) {
      this.bothSourcesSubscription.unsubscribe();
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private loadSchema() {
    this.robotService
      .dataSourcesJsonSchema()
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          this.jsonSchema = TreeItem.fromJSONSchema(res);

          this.applySchema();
        }
      });
  }

  private applySchema() {
    if (this.jsonSchema && this.treeData) {
      this.applySchemaToTree(this.jsonSchema, this.treeData);
    }
  }

  private applySchemaToTree(schema: TreeItem, tree: TreeItem) {
    const findTargetSchema = (sch: TreeItem, id: string): TreeItem => {
      let targetSchema = null;
      if (sch?.hasChildren) {
        sch.children.forEach((child) => {
          if (child.id === id) {
            targetSchema = child;
          }
        });
      }

      return targetSchema;
    };

    if (tree?.hasChildren) {
      tree.children.forEach((child) => {
        const targetSchema = findTargetSchema(schema, child.id);

        if (targetSchema && child) {
          this.applySchemaToTree(targetSchema, child);
        }
      });
    }
    if (
      tree &&
      tree.itemType === 'object' &&
      !tree.hasChildren &&
      schema &&
      schema.itemType === 'object' &&
      schema.hasChildren
    ) {
      tree.children = schema.children;
    }
  }

  private receivedMessage(msg: any) {
    if (msg.payload?.length > 0) {
      this.isLoading = false;
      this.timedOut = false;

      // Check if it's Rich's new sources format
      const isRichJSONSchema = Utils.isRichJSONSchema(msg.payload);

      if (isRichJSONSchema) {
        const rawData = msg.payload;
        const sources = [];
        if (rawData?.length > 0) {
          rawData.forEach((data) => {
            const item = RichSchemaItem.fromModel(data);
            sources.push(item);
          });
        }

        const mergedSources = [...this.richSchemaItems, ...sources];

        const newSources = [];
        const map = new Map();
        for (const item of mergedSources) {
          if (!map.has(item.source)) {
            map.set(item.source, true); // set any value to Map
            newSources.push(item);
          }
        }

        this.richSchemaItems = newSources;

        let showAttributes = false;

        if (this.dataStreamMode || this.insertSourceOnly || msg.type === 'service') {
          showAttributes = true;
        }

        if (this.treeData) {
          // Already have a tree, update it will be ok.
          this.treeData = TreeItem.fromRichJSONSchemaSources(sources, this.treeData, msg.type, showAttributes);
        } else {
          this.treeData = TreeItem.fromRichJSONSchemaSources(this.richSchemaItems, null, msg.type, showAttributes);
        }
      } else {
        const newSources = Utils.getSourcesList(msg.payload);

        // Merge the new sources with the existing sources and just keep
        // the unique values by convert the list to set and reconvert back to array.
        const mergedSources = [...this.sources, ...newSources];

        // Array -> Set -> Array : Remove the duplicated values
        this.sources = Array.from(new Set(mergedSources)).sort();

        this.treeData = TreeItem.fromPathList(this.sources, msg.type);

        // Load schema and apply to existing tree data.
        this.applySchema();
      }
    }
  }
}
