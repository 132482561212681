<button
  mat-flat-button
  [color]="color"
  (click)="onButtonClick($event)"
  [disabled]="disabled"
  [style.width]="fullWidth ? '100%' : 'auto'"
>
  <span class="button-spinner" *ngIf="isLoading" [ngClass]="{ 'loading': isLoading }">
    <app-loading-spinner
      [isLoading]="isLoading"
      [theme]="color === 'primary' ? 'light' : 'primary'"
    ></app-loading-spinner>
  </span>
  <span class="original-content" [ngClass]="{ 'loading': isLoading }">
    <ng-content></ng-content>
  </span>
</button>
