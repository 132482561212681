import { Injectable } from '@angular/core';
import { CodeEvalEnvironment } from '../../models';
import { ToastService } from '../toast';

@Injectable({
  providedIn: 'root',
})
export class CodeEvalService {
  private environments = {};
  private uiUtils: any;

  constructor(private toastService: ToastService) {
    this.uiUtils = {
      toast: (message: string, action: string, type: any) => {
        this.toastService.short(message, action, type);
      },
    };
  }

  createNewEnvironment() {
    const env = new CodeEvalEnvironment();
    env.setUIUtils(this.uiUtils);

    this.environments[env.id] = env;

    return env;
  }

  getEnvironmentById(id: string): CodeEvalEnvironment | undefined {
    return this.environments[id];
  }
}
