import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService, AuthService } from '../../services';
import type { User } from '../../models';

@Component({
  selector: 'app-dev-debug-dialog',
  templateUrl: './dev-debug-dialog.component.html',
  styleUrls: ['./dev-debug-dialog.component.scss'],
})
export class DevDebugDialogComponent implements OnInit {
  user: User;
  authInfo: any;

  constructor(
    public dialogRef: MatDialogRef<DevDebugDialogComponent>,

    private userService: UserService,
    private authService: AuthService,
  ) {
    //
  }

  ngOnInit() {
    this.loadUserInfo();
  }

  public onCancel() {
    this.dialogRef.close();
  }

  private loadUserInfo() {
    this.user = this.userService.user;
    this.authInfo = this.authService.userInfo;
  }
}
