import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { environment } from '@env/environment';
import { AuthGuard } from '@shared/guards';
import { CoreHostingComponent } from './core-hosting';
import { ConsoleHostingComponent } from './console-hosting';
import { NotFoundComponent } from './not-found';
import { AgentCallbackComponent } from './agent-callback';
import { AgentLoginComponent } from './agent-login';
import { SwitchAccountComponent } from './switch-account';
import { IENotSupportedComponent } from './ie-not-supported';
import { NoAccountComponent } from './no-account';
import { AuthOneTimeCodeComponent } from './auth-onetime-code/auth-onetime-code.component';

const routes: Routes = [
  {
    path: '',
    component: CoreHostingComponent,
    children: [
      {
        path: 'agent',
        component: AgentCallbackComponent,
      },
      {
        path: 'agent-login',
        component: AgentLoginComponent,
      },
      {
        path: 'switch-account',
        component: SwitchAccountComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ie-not-supported',
        component: IENotSupportedComponent,
      },
      {
        path: 'no-account',
        component: NoAccountComponent,
      },
      {
        path: '',
        redirectTo: '/account',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'auth',
    component: AuthOneTimeCodeComponent,
  },
  {
    path: '',
    component: ConsoleHostingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'account',
        loadChildren: () => import('../account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'project',
        loadChildren: () => import('../project/project.module').then((m) => m.ProjectModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('../admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

/**
 * Use the hash in the URL?
 */
const useHash = environment.routing.useHash;
const enableTracing = environment.routing.enableTracing;

let preloadingStrategy = PreloadAllModules;
if (!environment.production) {
  preloadingStrategy = NoPreloading;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash,
      enableTracing,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
