<app-widget-base
  [heading]="headingObservable | async"
  [type]="type"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [theme]="theme"
>
  <div class="gamepad-widget-wrapper" [class.profile-widget]="isGroupWidget">
    <app-gamepad-widget
      [keepAlive]="keepAlive"
      [headingSubject$]="headingSubject$"
      [projectId]="projectId"
      [callsign]="callsign"
    >
    </app-gamepad-widget>
  </div>
</app-widget-base>
