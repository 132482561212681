import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GeometryCoordinate } from '../../models';

export type CoordinatePositionPageType = 'global' | 'local';

export class CoordinatePositionRequest {
  id: string;
  type: CoordinatePositionPageType;
  robotCallsign: string;

  constructor(type: CoordinatePositionPageType, id?: string) {
    this.type = type;
    this.id = id ? id : crypto.randomUUID();
  }
}

export class CoordinatePositionResponse {
  id: string;
  coordinate: GeometryCoordinate;
  request?: CoordinatePositionRequest;

  constructor(id?: string, coordinate?: GeometryCoordinate, request?: CoordinatePositionRequest) {
    if (id) this.id = id;
    if (coordinate) this.coordinate = coordinate;
    if (request) this.request = request;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OperationCommunicationService {
  public coordinatePositionRequest$ = new Subject<CoordinatePositionRequest>();
  public coordinatePositionResponse$ = new Subject<CoordinatePositionResponse>();

  sendlngLatBack(request: CoordinatePositionRequest, longitude: number, latitude: number, altitude: number = 0) {
    const coord = new GeometryCoordinate();
    coord.latitude = latitude;
    coord.longitude = longitude;
    coord.altitude = altitude;
    const response = new CoordinatePositionResponse(request.id, coord, request);
    this.coordinatePositionResponse$.next(response);
  }
}
