import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-code',
  templateUrl: './copy-code.component.html',
  styleUrls: ['./copy-code.component.scss'],
})
export class CopyCodeComponent {
  @Input()
  public copy: string;

  public isCopied: boolean = false;

  private statusResetDelaySeconds = 0.2;

  /**
   * Reset status as mouse leaved trigger area.
   */
  public resetStatus() {
    setTimeout(() => {
      this.isCopied = false;
    }, this.statusResetDelaySeconds * 1000);
  }

  public onClick() {
    const value = this.copy;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.isCopied = true;
  }
}
