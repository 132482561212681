import { WidgetBase } from './widget-base';
import type { SimpleBinding } from '../../services/threeD/primitives/visualizer';
import { WidgetDefaultSize } from './widget-base';

export class WidgetMetric extends WidgetBase {
  public dataBinding?: SimpleBinding;
  public fontSize?: number;

  public static fromModel(model: Partial<WidgetMetric>): WidgetMetric {
    const widget = new WidgetMetric();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: Partial<WidgetMetric>): void {
    super.readValuesFromModel(model);

    this.rows = model.rows ? model.rows : WidgetDefaultSize.rows;
    this.cols = model.cols ? model.cols : WidgetDefaultSize.cols;

    this.dataBinding = model.dataBinding;

    // Set a default valueType to 'number'. (Another type is "string")
    this.valueType = model.valueType ? model.valueType : 'number';

    this.fontSize = model.fontSize ?? undefined;
  }
}
