import { Injectable } from '@angular/core';
import type { ButtonDefWithValue, Gamepad, GamepadButtonConfig, GamepadExecuteActionParametersObj } from '../../models';
import { EvalValue, VariableValuePair } from '../../utils';

export class GamepadVariablesConfig {
  list: GamepadButtonConfig[];

  constructor(list: GamepadButtonConfig[]) {
    this.list = list ? list : [];
  }
}

export class GamepadVariablesResult {
  [name: string]: GamepadVariable;

  static toVariableValuePairsList(result: GamepadVariablesResult, prefix: string = ''): VariableValuePair[] {
    const pairs: VariableValuePair[] = [];

    const keys = Object.keys(result);

    if (keys?.length > 0) {
      keys.forEach((key) => {
        const gamepadVariable = result[key];
        const variableName = `${prefix}${gamepadVariable.name}`;
        const value = gamepadVariable.value;

        const pair = new VariableValuePair(variableName, value);

        pairs.push(pair);
      });
    }

    return pairs;
  }

  /**
   * Convert GamepadVariablesResult to an object that contains multiple {id, value}
   *
   * @param result GamepadVariablesResult
   */
  static toParametersObj(result: GamepadVariablesResult): GamepadExecuteActionParametersObj {
    const obj = {} as GamepadExecuteActionParametersObj;

    const keys = Object.keys(result);

    if (keys?.length > 0) {
      keys.forEach((key) => {
        const variable = result[key];

        obj[key] = variable.toIdValueObject();
      });
    }

    return obj;
  }
}

export class GamepadVariable {
  name: string;
  value: any;
  variableDef?: GamepadButtonConfig;
  button?: ButtonDefWithValue;

  constructor(name: string, value: any, variableDef?: GamepadButtonConfig, button?: ButtonDefWithValue) {
    this.name = name;
    this.value = value;
    this.variableDef = variableDef;
    this.button = button;
  }

  static fromVariableDefAndButton(variableDef: GamepadButtonConfig, button: ButtonDefWithValue): GamepadVariable {
    const variableName = variableDef.name;
    const expression = variableDef.value;
    const buttonValue = button.value;

    const transformedValue = EvalValue.eval(buttonValue, expression);

    return new GamepadVariable(variableName, transformedValue, variableDef, button);
  }

  static fromVariableDef(variableDef: GamepadButtonConfig): GamepadVariable {
    const variableName = variableDef.name;

    return new GamepadVariable(variableName, variableDef.defaultValue, variableDef);
  }

  toIdValueObject(): {
    id: string;
    value: any;
  } {
    return {
      id: this.name,
      value: this.value,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class GamepadVariableService {
  variablesConfig: GamepadVariablesConfig;

  setVariablesConfigList(list: GamepadButtonConfig[]): void {
    const config = new GamepadVariablesConfig(list);
    this.variablesConfig = config;
  }

  getVariablesFromGamepadSnapshot(snapshot: Gamepad): GamepadVariablesResult {
    if (!this.variablesConfig) {
      throw new Error('Should set variables config first, please use `setVariablesConfigList` to set.');
    }

    const result: GamepadVariablesResult = {};

    if (this.variablesConfig?.list && this.variablesConfig.list.length > 0) {
      const list = this.variablesConfig.list;

      // For each item, get current value from gamepad snapshot
      list.forEach((item) => {
        const buttonType = item.source.type;
        const variableName = item.name;
        let generatedVariable: GamepadVariable;

        const button = snapshot.getPressedButtonByButtonType(buttonType);

        if (button) {
          generatedVariable = GamepadVariable.fromVariableDefAndButton(item, button);
        } else {
          // Use default value
          generatedVariable = GamepadVariable.fromVariableDef(item);
        }
        // Add to final result
        result[variableName] = generatedVariable;
      });
    }

    return result;
  }
}
