import { TimeUtils } from '../../utils';

export type SampleIntervalUnitValue = 'ms' | 's' | 'm' | 'h' | 'd' | string;

export class SampleIntervalUnit {
  value: SampleIntervalUnitValue;
  label: string;

  static getUnitList(): SampleIntervalUnit[] {
    return [
      {
        value: 'ms',
        label: 'milliseconds',
      },
      {
        value: 's',
        label: 'seconds',
      },
      {
        value: 'm',
        label: 'minutes',
      },
      {
        value: 'h',
        label: 'hours',
      },
      {
        value: 'd',
        label: 'days',
      },
    ];
  }
}

export class SampleInterval {
  interval: number;
  intervalUnit: SampleIntervalUnitValue;

  constructor(interval?: number, intervalUnit?: SampleIntervalUnitValue) {
    this.interval = interval;
    this.intervalUnit = intervalUnit;
  }

  static fromMilliseconds(ms: number) {
    const si = new SampleInterval();

    if (ms < 1000) {
      si.interval = Math.round(ms);
      si.intervalUnit = 'ms';
    } else if (ms < 1000 * 60) {
      si.interval = Math.round(ms / 1000);
      si.intervalUnit = 's';
    } else if (ms < 1000 * 60 * 60) {
      si.interval = Math.round(ms / 1000 / 60);
      si.intervalUnit = 'm';
    } else if (ms < 1000 * 60 * 60 * 24) {
      si.interval = Math.round(ms / 1000 / 60 / 60);
      si.intervalUnit = 'h';
    } else {
      si.interval = Math.round(ms / 1000 / 60 / 60 / 24);
      si.intervalUnit = 'd';
    }

    return si;
  }

  /**
   * Create a Sample Interval instance from an interval string.
   *
   * @param str Interval string. e.g. 100ms, 1s, 15m.
   */
  static fromIntervalString(str: string): SampleInterval {
    const ms = TimeUtils.parseDurationToMilliseconds(str);

    if (ms) {
      return SampleInterval.fromMilliseconds(ms);
    }

    return null;
  }

  getMilliseconds(): number {
    let ms = 0;

    switch (this.intervalUnit) {
      case 'ms':
        ms = this.interval;
        break;
      case 's':
        ms = this.interval * 1000;
        break;
      case 'm':
        ms = this.interval * 1000 * 60;
        break;
      case 'h':
        ms = this.interval * 1000 * 60 * 60;
        break;
      case 'd':
        ms = this.interval * 1000 * 60 * 60 * 24;
        break;
    }

    return ms;
  }

  getReadableString(): string {
    return `${this.interval}${this.intervalUnit}`;
  }
}
