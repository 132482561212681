import type { KeyValue } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getInputError,
  getInputType,
  getValidatorsForType,
} from '../../../workflow/modules/resources-management/utils/flow-parameter-utils';
import type { IManifestArgument } from '../../../workflow/services/workflow-gateway/workflow-gateway.type';

interface IDisplayedArgument extends IManifestArgument {
  placeholder: string;
  inputType: string;
}

@UntilDestroy()
@Component({
  selector: 'app-flow-inputs',
  templateUrl: './flow-inputs.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FlowInputsComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowInputsComponent implements OnChanges, ControlValueAccessor {
  @Input() arguments?: Record<string, IManifestArgument>;
  public calculatedArguments?: Record<string, IDisplayedArgument> = undefined;

  public form: UntypedFormGroup = new UntypedFormGroup({});

  private touched = false;
  private disabled = false;

  public constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['arguments']) {
      this.recalculateArguments();
      this.createForm();
      this.cdr.markForCheck();
    }
  }

  public writeValue(obj?: Record<string, string>) {
    if (obj) this.form.setValue(obj);
    this.cdr.markForCheck();
  }
  public registerOnChange(onChanged: typeof this.onChange): void {
    this.onChange = onChanged;
    this.onChange(this.form.value);
  }
  public registerOnTouched(onTouched: typeof this.onTouched) {
    this.onTouched = onTouched;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) this.form.disable();
    else this.form.enable();
  }

  public argTrackBy(index: number, arg: KeyValue<string, IDisplayedArgument>) {
    return arg.key;
  }

  public getArgumentErrorMessage(argumentId: string): string {
    const error = Object.entries(this.form.get(argumentId).errors)?.[0];

    if (error) {
      return getInputError(error[0], error[1]);
    }

    return '';
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private onChange = (_value: Record<string, string>) => {
    /* noop - is replaced when registerOnChange is called */
  };

  private onTouched = () => {
    /* noop - is replaced when registerOnChange is called */
  };

  private recalculateArguments(): void {
    if (this.arguments === undefined) {
      this.calculatedArguments = undefined;
      return;
    }

    this.calculatedArguments = Object.entries(this.arguments).reduce((acc, [key, value]) => {
      const { type } = value;

      const inputType = getInputType(type);
      const placeholder = `${key} (${type})`;

      return {
        ...acc,
        [key]: {
          ...value,
          inputType,
          placeholder,
        },
      };
    }, {});
  }

  private createForm(): void {
    const formGroup = new UntypedFormGroup({});
    for (const [key, arg] of Object.entries(this.calculatedArguments ?? {})) {
      const control = new UntypedFormControl(arg.default, [Validators.required, ...getValidatorsForType(arg.type)]);
      formGroup.addControl(key, control);
    }

    this.form = formGroup;
    this.onChange(this.form.value);

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value: Record<string, string>) => {
      this.markAsTouched();
      this.onChange(value);
    });
  }
}
