import { Injectable } from '@angular/core';
import type { WaypointPolygonData } from './primitives/visualizer/interface/IWaypointPicker';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BabylonRendererService } from './babylon-renderer.service';
import { WaypointBridgeService } from '../waypoint-bridge';
import type { Scene } from '@babylonjs/core';
import { Vector3 } from '@babylonjs/core';
import { deleteAllPolygonGizmos, renderPolygonGizmo } from './primitives/gizmos/polygon-gizmo';
import { getPolygonMaterial } from './primitives/gizmos/gizmo-materials';

@Injectable()
export class PolygonRendererService {
  private destroy$ = new Subject<void>();
  private scene: Scene;

  public constructor(private babylonRenderer: BabylonRendererService, private waypointBridge: WaypointBridgeService) {}

  public init(): void {
    combineLatest([this.babylonRenderer.scene$, this.waypointBridge.polygons$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([scene, polygons]: [Scene, WaypointPolygonData[]]) => {
        this.scene = scene;
        this.renderPolygons(polygons);
      });
  }

  public destroy(): void {
    this.destroy$.next();
  }

  private renderPolygons(polygons: WaypointPolygonData[]): void {
    deleteAllPolygonGizmos({ scene: this.scene });
    polygons.forEach((poly) => {
      const polyProps = {
        scene: this.scene,
        id: poly.id,
        material: getPolygonMaterial({ scene: this.scene }),
        locations: poly.waypoints,
        height: poly.pickerMeta.height,
      };
      polyProps.locations = polyProps.locations.map((loc) => {
        const transformed = this.babylonRenderer.pointFromFrame(
          poly.pickerMeta.frame,
          new Vector3(loc.pos.x, loc.pos.y, loc.pos.z),
        );
        return { pos: { x: transformed.x, y: transformed.y, z: transformed.z } };
      });

      renderPolygonGizmo(polyProps);
    });
  }
}
