import { Component, Input } from '@angular/core';
import type { IsLoading } from '../../interfaces';

export type SpinnerColorTheme = 'primary' | 'light' | 'dark' | 'grey' | 'light-grey';
export type SpinnerColorIcon = 'spinner' | 'arrows';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements IsLoading {
  @Input() public isLoading: boolean = true;
  @Input() public theme: SpinnerColorTheme = 'primary';
  @Input() public icon: SpinnerColorIcon = 'spinner';
  @Input() public size: 'xs' | 'x1' | 'x2' | 'x3' = 'x1';
}
