<ng-container *ngFor="let bc of breadcrumbs">
  <button mat-button [routerLink]="[bc.url]">
    <div class="button-content">
      <div *ngIf="bc.icon === 'robotStatus'" class="icon">
        <app-robot-status-dot [projectId]="projectId" [callsign]="callsign"></app-robot-status-dot>
      </div>
      <span>{{ bc.displayName }}</span>
    </div>
  </button>
  <ng-container *ngIf="!bc.terminal">
    <div class="ri-chevron-right"></div>
  </ng-container>
</ng-container>
