import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Deck, FlyToInterpolator, TRANSITION_EVENTS } from '@deck.gl/core';
import { environment } from '@env/environment';
import * as mapboxgl from 'mapbox-gl';
import type { GeometryCoordinate } from '../../../models';
import { MapBoxHelper, MAPTHEMES, MapThumbs } from '../mapbox-helper';

@Component({
  selector: 'app-widget-map',
  templateUrl: './widget-map.component.html',
  styleUrls: ['./widget-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetMapComponent implements OnInit, OnDestroy, AfterViewInit {
  // Set static to true as this variable will be used in ngOnInit.
  @ViewChild('mapContainer', { static: true })
  public mapContainer;

  // Set static to true as this variable will be used in ngOnInit.
  @ViewChild('deckContainer', { static: true })
  public deckContainer;

  @ViewChild('mapStyleOptions')
  public mapStyleOptions;

  @ViewChild('miniMapStyleOptions')
  public miniMapStyleOptions;

  /** Renders 3D Buildings layer in Mapbox when zoomed in close enough */
  @Input()
  public show3DBuildings: boolean = false;

  /** Removes the layer of the map that has location labels like cities, cleaner map, but less info */
  @Input()
  public hideLabelsMapLayer: boolean = false;

  /** Width of the component either in px or %, e.g. '200px' and '100%'  */
  @Input()
  public width: string = '100%';

  /** Height of the component either in px or %, e.g. '200px' and '100%'  */
  @Input()
  public height: string = '100%';

  @Input()
  public mapStyle: MAPTHEMES = MAPTHEMES.SATELLITE;

  @Input()
  public startLocation: [number, number] = [170, 20];

  @Input()
  public startZoom: number = 1;

  @Input()
  public showBotLocator: boolean = false;

  @Input()
  public isFollowingBot: boolean = false;

  @Input()
  public mapCursor: string = 'grab';

  @Output()
  public followBotStateChanged = new EventEmitter<boolean>();

  @Output()
  public mapDragged = new EventEmitter<unknown>();

  @Output()
  public styleChanged = new EventEmitter<unknown>();

  @Output()
  public viewUpdated = new EventEmitter<unknown>();

  @Output()
  public mapClicked = new EventEmitter<unknown>();

  @Output()
  public mapZoomedByUser = new EventEmitter<unknown>();

  @Output()
  public mapInitialized = new EventEmitter<void>();

  public initialViewState = {
    latitude: 20,
    longitude: 170,
    zoom: 1,
    bearing: 0,
    pitch: 10,
    transitionInterpolator: new FlyToInterpolator(),
    transitionDuration: 1000,
    transitionInterruption: TRANSITION_EVENTS.IGNORE,
  };

  public colorRange = [
    [1, 152, 189],
    [73, 227, 206],
    [216, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [209, 55, 78],
  ];
  public map: mapboxgl.Map;
  public deck: Deck;
  public mapInTransition: boolean = false; // if DeckGL is currently moving the map
  public mapThumbs = MapThumbs;

  public mapThemes = MAPTHEMES; // : SVGAnimatedEnumeration; = MAPTHEMES;
  public activeMapThemeImageUrl = MapThumbs.mapThumbSatelliteStreets;

  public mapHelper: MapBoxHelper;
  public currentViewState = this.initialViewState;

  private mapLoaded: boolean = false;
  private flyToZoom: number = 15;

  public constructor(private ngZone: NgZone) {}

  public showHideMiniMapStyleOptions(): void {
    if (this.miniMapStyleOptions.nativeElement.style.display !== 'flex') {
      this.miniMapStyleOptions.nativeElement.style.display = 'flex';
    } else {
      this.miniMapStyleOptions.nativeElement.style.display = 'none';
    }
  }

  public showHideMapStyleOptions(): void {
    if (this.mapStyleOptions.nativeElement.style.display !== 'block') {
      this.mapStyleOptions.nativeElement.style.display = 'block';
    } else {
      this.mapStyleOptions.nativeElement.style.display = 'none';
    }
  }

  public toggleFollowBotState(): void {
    this.isFollowingBot = !this.isFollowingBot;
    this.followBotStateChanged.emit(this.isFollowingBot);
  }

  public setCursor(cursor: string = 'grab'): void {
    this.mapCursor = cursor;
  }

  public ngOnInit(): void {
    if (this.startLocation) {
      this.initialViewState.longitude = this.startLocation[0];
      this.initialViewState.latitude = this.startLocation[1];
    }
    if (this.startZoom) {
      this.initialViewState.zoom = this.startZoom;
    }
    this.ngZone.runOutsideAngular(() => {
      this.initializeMap();
    });
  }

  public ngAfterViewInit(): void {
    this.resizeMap();
  }

  public ngOnDestroy(): void {
    this.deck.finalize();
    this.map.remove();
  }

  public updateLayers(layers: unknown): void {
    this.deck.setProps({ layers });
  }

  public zoomIn(): void {
    if (this.deck.props?.viewState) {
      const zoomLevel = this.deck.props.viewState.zoom;
      if (zoomLevel > 21) {
        return;
      }
      this.zoomTo(zoomLevel === 22 ? 22 : zoomLevel + 1);
    }
  }

  public zoomOut(): void {
    if (this.deck.props?.viewState) {
      const zoomLevel = this.deck.props.viewState.zoom;
      // zoomlevel can be a tiny floating number closing to zero but not zero
      if (zoomLevel < 1) {
        return;
      }
      this.zoomTo(zoomLevel === 0 ? 0 : zoomLevel - 1);
    }
  }

  public zoomTo(zoomLevel: number): void {
    if (!this.deck) {
      return;
    }

    if (this.deck.props?.viewState) {
      const currentLongitude = this.deck.props.viewState.longitude;
      const currentLatitude = this.deck.props.viewState.latitude;

      try {
        this.deck.setProps({
          viewState: {
            longitude: currentLongitude,
            latitude: currentLatitude,
            zoom: zoomLevel,
            transitionDuration: 200,
            transitionInterruption: TRANSITION_EVENTS.BREAK,
            onTransitionStart: () => {
              this.mapInTransition = true;
            },
            onTransitionInterrupt: () => {
              this.mapInTransition = false;
            },
            onTransitionEnd: () => {
              this.mapInTransition = false;
            },
          },
        });
      } catch (e) {
        console.log('catch a strange setProps null issue', { e });
      }
    }
  }

  public flyTo(coordinate: GeometryCoordinate, zoomLevel: number = this.flyToZoom): void {
    if (!this.deck) {
      return;
    }

    if (this.mapInTransition) {
      return;
    }

    if (this.deck.props?.viewState) {
      const currentLongitude = this.deck.props.viewState.longitude;
      const currentLatitude = this.deck.props.viewState.latitude;
      const currentZoomLevel = this.deck.props.viewState.zoom;

      // Don't do anything if coordinate and zoom level neither changed
      if (
        currentLatitude === coordinate.latitude &&
        currentLongitude === coordinate.longitude &&
        currentZoomLevel === zoomLevel
      ) {
        return;
      }
    }

    if (!coordinate?.longitude || !coordinate.latitude || (!zoomLevel && zoomLevel !== 0)) {
      return;
    }

    try {
      this.deck.setProps({
        viewState: {
          longitude: coordinate.longitude,
          latitude: coordinate.latitude,
          zoom: zoomLevel,
          transitionDuration: 1000,
          transitionInterruption: TRANSITION_EVENTS.BREAK,
          onTransitionStart: () => {
            this.mapInTransition = true;
          },
          onTransitionInterrupt: () => {
            this.mapInTransition = false;
          },
          onTransitionEnd: () => {
            this.mapInTransition = false;
          },
        },
      });
    } catch (e) {
      console.log('catch a strange setProps null issue', { e });
    }
  }

  // Manually resize map
  public resizeMap(): void {
    // Use timer to fix the empty right side vertical bar problem.
    if (this.map) {
      let resizeCount = 10;
      const timer = setInterval(() => {
        this.map.resize();

        if (resizeCount-- <= 0) {
          clearInterval(timer);
        }
      }, 50);
    }
  }

  private initializeMap(): void {
    // Set mapbox access token
    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(environment.mapbox.accessToken);

    // Change the width and height
    this.mapContainer.nativeElement.style.width = this.width;
    this.mapContainer.nativeElement.style.height = this.height;

    // Create mapbox map
    this.map = new mapboxgl.Map({
      container: this.mapContainer.nativeElement,
      style: this.mapStyle || MAPTHEMES.SATELLITE,
      zoom: this.startZoom || this.initialViewState.zoom,
      pitch: this.initialViewState.pitch,
      bearing: this.initialViewState.bearing,
      center: this.startLocation || [this.initialViewState.longitude, this.initialViewState.latitude],
      repeat: true,
      attributionControl: false,
    });

    this.map.addControl(new mapboxgl.AttributionControl({ compact: false }), 'bottom-left');

    // used to simplify assigning rocos geometry shapes to the mapbox map
    this.mapHelper = new MapBoxHelper(this.map);
    // gl: this.map.painter.context.gl,
    // this would be used if I was using the MapBox integration,
    // but it's experimental and doesn't support WebGL2 yet as of 13 Dec 2018
    // mapStyle: this.mapStyle,
    //

    this.deck = new Deck({
      canvas: this.deckContainer.nativeElement,
      width: this.width,
      height: this.height,
      initialViewState: this.initialViewState,
      controller: true,
      layers: [],
      onViewStateChange: ({ viewState }) => {
        // Save current view state
        this.currentViewState = viewState; // watched by external widgets

        this.map.jumpTo({
          center: [viewState.longitude, viewState.latitude],
          zoom: viewState.zoom,
          bearing: viewState.bearing,
          pitch: viewState.pitch,
        });
        this.deck.setProps({ viewState });

        this.ngZone.run(() => {
          this.viewUpdated.emit(viewState);
        });

        return viewState;
      },
      onClick: (object: unknown) => {
        this.ngZone.run(() => {
          this.mapClicked.emit(object);
        });
      },
      onDrag: (object: unknown) => {
        this.ngZone.run(() => {
          this.mapDragged.emit(object);
        });
      },
      getCursor: (interactionState: { isDragging: boolean }) => {
        if (interactionState.isDragging) {
          return 'grabbing';
        } else {
          return this.mapCursor ? this.mapCursor : 'grab';
        }
      },
    });

    this.map.on('load', () => {
      if (this.mapLoaded) {
        return;
      }
      this.onMapLoad();
      this.mapLoaded = true;
      this.mapInitialized.next();

      // ROC-270 - Robot in wrong GPS location before you zoom in Global Ops
      // This is a hacking way to fix bug.
      const updateViewStateInUndetectableWay = () => {
        const currentViewState = this.currentViewState ? this.currentViewState : this.initialViewState;
        const bearing = currentViewState.bearing + 0.0000001; // Tiny change which is undetectable from users.
        this.deck.setProps({
          viewState: {
            ...currentViewState,
            bearing,
          },
        });
      };
      updateViewStateInUndetectableWay();
    });

    this.map.on('zoom', (e) => {
      if (!this.mapInTransition) {
        this.mapZoomedByUser.emit(e);
      }
    });

    this.map.on('styledata', (e) => {
      if (e?.style?._changed) {
        this.styleChanged.emit(e);
      }
    });
  }

  private onMapLoad(): void {
    if (this.hideLabelsMapLayer) {
      this.mapHelper.removeLabels();
    }

    if (this.show3DBuildings) {
      this.mapHelper.add3DBuildings();
    }
  }
}
