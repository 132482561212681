<div class="data-explorer-wrapper">
  <as-split unit="percent" direction="horizontal">
    <as-split-area class="panel left-panel" [size]="40" [minSize]="20" [maxSize]="70">
      <div>
        <div class="panel-header">
          <div class="subtitle">
            <div class="left">
              <div>Sources</div>
            </div>
          </div>

          <ng-container *ngIf="callsign">
            <div class="actions">
              <button
                class="left-icon-button mr-2"
                mat-stroked-button
                color="primary"
                *appFeatureFlag="FeatureFlags.SendMessageLDV"
                (click)="openSendMessageDialog()"
              >
                <span class="icon ri-sendMessage"></span>
                Send Message
              </button>
              <button mat-button color="primary" (click)="onClearAllSelection()">Clear All</button>
              <button
                mat-icon-button
                color="primary"
                matTooltip="Refresh"
                (click)="onRefreshTree()"
                data-testid="refresh"
              >
                <span class="ri-refresh"></span>
              </button>
            </div>
          </ng-container>
        </div>
        <div class="robot-search" *ngIf="showRobotSearch">
          <span class="header">Robot</span>

          <app-robot-search
            [includeStatus]="true"
            [defaultCallsign]="callsign"
            [projectId]="projectId"
            (callsignSelected)="onCallsignSelected($event)"
            (robotCountUpdated)="onRobotCountUpdated($event)"
            [filledRobots]="robotsForPicker"
          >
          </app-robot-search>
        </div>
        <ng-container *ngIf="callsign">
          <div *ngIf="showServiceMode" class="checkbox-filter">
            <mat-checkbox [formControl]="showMessages" class="checkbox" (change)="checkBoxChanged()"
              >Telemetry
            </mat-checkbox>
            <mat-checkbox [formControl]="showServices" class="checkbox" (change)="checkBoxChanged()"
              >Services</mat-checkbox
            >
          </div>

          <div *ngIf="!showServiceMode" class="choosing-hint-message">
            <ng-container *ngIf="insertSourceOnly || dataStreamMode"> Choose a message </ng-container>
            <ng-container *ngIf="!insertSourceOnly && !dataStreamMode"> Choose a property </ng-container>
          </div>

          <div class="sources-tree" [class.limited-height]="insertDataURIMode">
            <!-- A fake refresh effect for tree component -->
            <!-- Hide loading if robot-search is there since he handle the UI loading -->
            <ng-container *ngIf="currentTreeComponentIndex === 0">
              <app-data-sources-tree
                #sourcesTree
                (selectionChange)="onTreeSelectionChange($event)"
                (getCallablesFailed)="onGetCallablesFailed()"
                (dataItemChange)="onDataItemChange($event)"
                [showMessages]="showMessages.value"
                [showServices]="showServices.value"
                [insertDataURIMode]="insertDataURIMode"
                [projectId]="projectId"
                [callsign]="callsign"
                [dataStreamMode]="dataStreamMode"
                [insertSourceOnly]="insertSourceOnly"
                [showLoading]="showRobotSearch === false"
              >
              </app-data-sources-tree>
            </ng-container>
            <ng-container *ngIf="currentTreeComponentIndex === 1">
              <app-data-sources-tree
                #sourcesTree
                (selectionChange)="onTreeSelectionChange($event)"
                (getCallablesFailed)="onGetCallablesFailed()"
                (dataItemChange)="onDataItemChange($event)"
                [showMessages]="showMessages.value"
                [showServices]="showServices.value"
                [insertDataURIMode]="insertDataURIMode"
                [projectId]="projectId"
                [callsign]="callsign"
                [dataStreamMode]="dataStreamMode"
                [insertSourceOnly]="insertSourceOnly"
                [showLoading]="showRobotSearch === false"
              >
              </app-data-sources-tree>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </as-split-area>
    <as-split-area [size]="60" class="panel right-panel">
      <div>
        <div class="panel-header">
          <div class="subtitle">
            <div class="left">
              <!-- <div class="index">
                2
              </div> -->
              <div>Preview</div>
            </div>
            <div class="actions" *ngIf="!dataStreamMode || !!callsign">
              <ng-container *ngIf="this.isPausing">
                <button class="left-icon-button" mat-stroked-button color="primary" (click)="onPauseToggle()">
                  <span class="icon ri-play"></span>
                  <span> Resume </span>
                </button>
              </ng-container>
              <ng-container *ngIf="!this.isPausing">
                <button class="left-icon-button" mat-stroked-button color="primary" (click)="onPauseToggle()">
                  <span class="icon ri-pause"></span>
                  <span> Pause </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="preview-boxes" [class.limited-height]="insertDataURIMode">
          <ng-container *ngFor="let message of sentMessages; index as i">
            <div class="preview-card">
              <app-message-preview
                [callsign]="callsign"
                [projectId]="projectId"
                [message]="message"
                (closePreview)="onCloseMessage(i)"
              >
              </app-message-preview>
            </div>
          </ng-container>
          <ng-container *ngFor="let source of sourcesTree?.treeData?.selectedItems">
            <div class="preview-card">
              <app-data-source-preview
                [projectId]="projectId"
                [callsign]="callsign"
                [sourceInfo]="source"
                [insertDataURIMode]="insertDataURIMode"
                [isPaused]="this.isPausing"
                [dataStreamMode]="dataStreamMode && !callsign"
                (dataItemChange)="onDataItemChange($event)"
                (previewClosed)="onClosePreview(source)"
              >
              </app-data-source-preview>
            </div>
          </ng-container>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
