import type { PrimitiveMetaData, ValueTypes } from './primitives';

export type ScenePropertyType = 'clearColor' | 'ambientColor' | 'skybox' | 'shadowsEnabled';
export type SceneValueType = ValueTypes | 'skybox';

export const scenePropertyValueTypes: Record<ScenePropertyType, SceneValueType> = {
  clearColor: 'color4',
  ambientColor: 'color3',
  skybox: 'skybox',
  shadowsEnabled: 'boolean',
};

export type DefaultSceneTypes = 'scene';

export const supportedSceneTypes: Record<DefaultSceneTypes, PrimitiveMetaData> = {
  scene: {
    key: 'scene',
    label: 'Scene',
    icon: 'ri-local-operations',
  },
};
