import {
  Color3 as _Color3,
  MeshBuilder as _MeshBuilder,
  Quaternion as _Quaternion,
  SceneLoader as _SceneLoader,
} from '@babylonjs/core';

/**
 * Important: The following is used by Local Ops Scrips via BABYLON.xxx (Most notably: Diligent)
 * And yes, this is BAD, and we need to find a way to migrate the diligent scripts so they don't
 * depend on the internal workings of portal.
 *
 * @deprecated Just don't use this, it's bad.
 */
export class FAKEBYLON {
  get Color3() {
    return _Color3;
  }

  get MeshBuilder() {
    return _MeshBuilder;
  }

  get Quaternion() {
    return _Quaternion;
  }

  get SceneLoader() {
    return _SceneLoader;
  }
}
