import type { IDataUriConfig } from '../common';

export class WidgetLineGroup {
  public dataURI?: string;
  public source?: string;
  public units: string;
  public dataLabel: string;
  public lineColor: string;
  public decimalPlaces: number;
  public valueExpression?: string;

  public dataUriConfig?: IDataUriConfig;

  /**
   * Group index for identifying which group, like a key for group.
   */
  public groupIndex?: number;

  public static fromModel(model: any, index?: number): WidgetLineGroup {
    const group = new WidgetLineGroup();

    group.dataURI = model.dataURI;
    group.source = model.source;
    group.units = model.units;
    group.dataLabel = model.dataLabel;
    group.lineColor = model.lineColor;
    group.valueExpression = model.valueExpression;
    group.decimalPlaces = model.decimalPlaces ? model.decimalPlaces : 0;

    if (index !== undefined) {
      group.groupIndex = index;
    }

    if (model.dataUriConfig) {
      group.dataUriConfig = model.dataUriConfig;

      // Copy dataURI value from config
      if (!model.dataURI) {
        group.dataURI = group.dataUriConfig.dataURI;
      }

      // Copy valueExpression value from config
      if (!model.valueExpression) {
        group.valueExpression = group.dataUriConfig.valueExpression;
      }
    } else {
      group.dataUriConfig = {
        dataURI: model.dataURI,
        dataUriConfigMode: 'basic',
        valueExpression: model.valueExpression,
      };
    }

    return group;
  }
}
