import { Axis, Path3D, TransformNode } from '@babylonjs/core';
import type { Material, Mesh, Scene } from '@babylonjs/core';
import { MeshBuilder, Quaternion, Space, Vector3 } from '@babylonjs/core';
import type { Feature, LineString, Point } from 'geojson';

export const createSphere = (
  scene: Scene,
  feature: Feature<Point>,
  meshName: string,
  material: Material,
  diameter: number,
): Mesh => {
  const sphere = MeshBuilder.CreateSphere(meshName, { diameter, segments: 8 }, scene);
  sphere.material = material;
  const [x, y, z] = feature.geometry.coordinates;
  sphere.position = new Vector3(x, y, z || 0);
  return sphere;
};

const lineFeatureToPath = (feature: Feature<LineString>): Vector3[] => {
  const path = [];
  for (const coordinate of feature.geometry.coordinates) {
    const z = coordinate[2] || 0;
    path.push(new Vector3(coordinate[0], coordinate[1], z));
  }
  return path;
};

export const createRay = (
  scene: Scene,
  feature: Feature<Point>,
  meshName: string,
  material: Material,
  radius: number,
  length: number,
): Mesh => {
  if (!feature.properties['rotation']) return null;
  const quaternion = new Quaternion().set(
    feature.properties['rotation'].x,
    feature.properties['rotation'].y,
    feature.properties['rotation'].z,
    feature.properties['rotation'].w,
  );
  const [x, y, z] = feature.geometry.coordinates;
  const start = new Vector3(0, 0, 0);
  const end = new Vector3(length, 0, 0);
  const ray = MeshBuilder.CreateTube(meshName, { path: [start, end], radius, tessellation: 16 }, scene);
  ray.position = new Vector3(x, y, z || 0);
  ray.material = material;
  ray.rotationQuaternion = quaternion;
  return ray;
};

export const createTube = (
  scene: Scene,
  feature: Feature<LineString>,
  meshName: string,
  material: Material,
  radius: number,
): Mesh => {
  const path = lineFeatureToPath(feature);
  const originPath = path.map((point) => point.subtract(path[0]));
  const tube = MeshBuilder.CreateTube(meshName, { path: originPath, radius, tessellation: 16 }, scene);
  tube.position = path[0];
  tube.material = material;
  return tube;
};

export const createCylinder = (
  scene: Scene,
  feature: Feature<Point>,
  meshName: string,
  material: Material,
  diameter: number,
  height: number,
): Mesh => {
  if (!feature.properties['rotation']) return null;
  const quaternion = new Quaternion().set(
    feature.properties['rotation'].x,
    feature.properties['rotation'].y,
    feature.properties['rotation'].z,
    feature.properties['rotation'].w,
  );
  const [x, y, z] = feature.geometry.coordinates;
  const cylinder = MeshBuilder.CreateCylinder(meshName, { diameter, height, tessellation: 16, cap: 0 }, scene);
  cylinder.position = new Vector3(x, y, z || 0);
  cylinder.rotationQuaternion = quaternion;
  cylinder.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.LOCAL);
  cylinder.material = material;
  return cylinder;
};

export const createArrow = (
  scene: Scene,
  feature: Feature<LineString>,
  meshName: string,
  material: Material,
  radius: number,
  arrowSize: number,
): TransformNode => {
  const path = lineFeatureToPath(feature);
  const originPath = path.map((point) => point.subtract(path[0]));
  const height = arrowSize * 2;
  const arrow = new TransformNode(meshName, scene);
  arrow.position = path[0];

  const tube = createTube(scene, feature, meshName, material, radius);
  tube.position = new Vector3(0, 0, 0);
  tube.parent = arrow;

  const cone = MeshBuilder.CreateCylinder(
    meshName,
    { diameterTop: arrowSize, diameterBottom: 0, height, tessellation: 16, cap: 0 },
    scene,
  );
  cone.material = material;
  cone.position = originPath[1];
  const path3d = new Path3D(path);
  cone.rotation = Vector3.RotationFromAxis(path3d.getNormals()[0], path3d.getBinormals()[0], path3d.getTangents()[0]);
  cone.addRotation(-Math.PI / 2, 0, 0);
  cone.translate(Axis.Y, height / 2, Space.LOCAL);
  cone.addRotation(0, Math.PI / 4, 0);
  cone.parent = arrow;

  return arrow;
};
