<ngx-monaco-editor
  #editorElement
  [style.min-height]="'18px'"
  [style.height]="'auto'"
  [options]="editorOptions"
  (onInit)="onEditorInit()"
  [ngModel]="code"
  (ngModelChange)="onCodeChange($event)"
>
</ngx-monaco-editor>
