import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(private zone: NgZone) {}

  public loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.zone.runOutsideAngular(() => {
        const s = document.createElement('script');
        s.setAttribute('src', src);
        s.onload = () => {
          resolve();
        };
        s.onerror = () => {
          reject();
        };
        document.body.appendChild(s);
      });
    });
  }
}
