<div class="rocos-dark-theme controls">
  <app-loading-content [isLoading]="isLoading">
    <div class="buttons" *ngIf="uxComponents && uxComponents.length > 0">
      <ng-container *ngFor="let comp of uxComponents">
        <!-- For best backward compatibility, we don't check true here, so that previously created buttons with undefined value will be treated as true -->
        <ng-container *ngIf="shouldDisplay(comp)">
          <ng-container [ngSwitch]="comp.controlType">
            <!-- Normal Button -->
            <ng-container *ngSwitchCase="'button'">
              <div [matTooltip]="comp.longTooltip" class="control-button" [class.has-input]="hasInput(comp)">
                <app-command-with-value-button
                  [loading]="buttonStatus && buttonStatus[comp.id] && buttonStatus[comp.id].isLoading"
                  (clicked)="onClick(comp)"
                  [configGroupItem]="comp"
                  layout="vertical"
                  [projectId]="projectId"
                >
                  <span class="overflow-ellipsis">{{ comp?.label }}</span>
                </app-command-with-value-button>
              </div>
            </ng-container>

            <!-- Button and get Pose -->
            <ng-container *ngSwitchCase="'buttonAndPose'">
              <div [matTooltip]="comp.longTooltip" class="control-button" [class.has-input]="hasInput(comp)">
                <app-command-with-value-button
                  [loading]="buttonStatus && buttonStatus[comp.id] && buttonStatus[comp.id].isLoading"
                  (clicked)="onClick(comp)"
                  [configGroupItem]="comp"
                  layout="vertical"
                  [projectId]="projectId"
                >
                  <div class="align-items-center d-flex justify-content-center">
                    <span class="ri-cube mr-2"></span>
                    <span class="overflow-ellipsis">{{ comp.label }}</span>
                  </div>
                </app-command-with-value-button>
              </div>
            </ng-container>

            <!-- Button and get Mission -->
            <ng-container *ngSwitchCase="'buttonAndGetMission'">
              <!-- Must have active mission-->
              <div
                *appExperimental="'operationMissionsPanel'; projectId: projectId"
                [matTooltip]="!operationPageContext?.activeMission ? 'Not applicable' : comp.longTooltip"
                class="control-button"
                [class.has-input]="hasInput(comp)"
              >
                <app-command-with-value-button
                  [loading]="buttonStatus && buttonStatus[comp.id] && buttonStatus[comp.id].isLoading"
                  [disabled]="!operationPageContext?.activeMission"
                  (clicked)="onClick(comp)"
                  [configGroupItem]="comp"
                  layout="vertical"
                  [projectId]="projectId"
                >
                  <div class="align-items-center d-flex justify-content-center">
                    <span class="ri-local-operations mr-2"></span>
                    <span class="overflow-ellipsis">{{ comp.label }}</span>
                  </div>
                </app-command-with-value-button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!uxComponents || uxComponents.length === 0">
      <div class="no-controls-container">
        <div class="no-controls-message">No buttons configured.</div>

        <button mat-button class="left-icon-button" color="primary" (click)="onConfigureButtons()">
          <span class="ri-internal-link"></span>
          <span>Configure Buttons</span>
        </button>
      </div>
    </ng-container>
  </app-loading-content>
</div>
