<app-widget-base
  [type]="type"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div class="placeholder" #placeholder></div>
</app-widget-base>
