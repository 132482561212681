<div class="gamepad-widget">
  <div class="gamepad-box-indicator">
    <app-gamepad-box-indicator [active]="active" [pressedValues]="pressedValues"></app-gamepad-box-indicator>
  </div>

  <div class="gamepad-gui-indicator">
    <app-gamepad-gui-indicator [active]="active" [pressedValues]="pressedValues"></app-gamepad-gui-indicator>
  </div>

  <div class="enable-toggle">
    <mat-slide-toggle
      [disabled]="!active"
      [color]="'primary'"
      [checked]="gamepadEnabled"
      (change)="onToggleChanged($event)"
    ></mat-slide-toggle>
    <div class="enable-toggle-text" [class.enabled]="gamepadEnabled" [class.deactive]="!active">
      <span>
        {{ gamepadEnabled ? 'On' : 'Off' }}
      </span>
    </div>
  </div>
</div>
