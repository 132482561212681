export enum WidgetTypeName {
  METRIC = 'metric',
  DONUT_GAUGE = 'donut-gauge',
  LINE_GRAPH = 'line-graph',
  LINE_GRAPH_V2 = 'line-graph-v2',
  HORIZON = 'horizon',
  HEADING = 'heading',
  VIDEO = 'video',
  TABLE = 'table',
  TABLEV2 = 'tableV2',
  GAMEPAD = 'gamepad',
  HTML = 'html',
  IMAGE = 'image',
  OPS = 'ops',
  CUSTOM = 'custom',
  JAVASCRIPT = 'javascript',
  BUTTON_GROUP = 'button-group',
}

export type WidgetType =
  | 'metric'
  | 'donut-gauge'
  | 'line-graph'
  | 'line-graph-v2'
  | 'horizon'
  | 'heading'
  | 'video'
  | 'table'
  | 'tableV2'
  | 'gamepad'
  | 'audio'
  | 'html'
  | 'image'
  | 'ops'
  | 'custom'
  | 'button-group'
  | 'javascript'
  | 'web-component';
