import { MatNativeDateModule } from '@angular/material/core';
import { AppInjectorService } from './core/shared/services/app-injector.service';
import { Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import { Angulartics2Module } from 'angulartics2';
import { CoreModule } from './core';
import { DirectivesModule } from '@shared/directives';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { translocoLoader } from './transloco.loader';
import type { TranslocoConfig } from '@ngneat/transloco';
import { TranslocoModule, TRANSLOCO_CONFIG } from '@ngneat/transloco';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ngxMonacoEditorConfig } from '@shared-modules/code-editor-v3/monaco-editor-config';
import { MonacoEditorModule } from '@shared-modules/monaco-editor/editor.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    DirectivesModule,
    CoreModule,
    MatSnackBarModule,
    Angulartics2Module.forRoot(),
    MonacoEditorModule.forRoot(ngxMonacoEditorConfig),
    HttpClientModule,
    TranslocoModule,
    MatNativeDateModule,
  ],
  providers: [
    MatSnackBar,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: environment.i18n.transloco.availableLangs,
        defaultLang: environment.i18n.transloco.defaultLang,
        fallbackLang: environment.i18n.transloco.fallbackLang,
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
    translocoLoader,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Global Injector -> Used to manually load dependencies
  public constructor(injector: Injector) {
    AppInjectorService.injector = injector;
  }
}
