import { Injectable, NgZone } from '@angular/core';
import type { IRocosTelemetryMessage } from '@team-rocos/rocos-js';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import type { WidgetJavascript } from '../../models';
import { SimpleBinding, SimpleBindings, VizBinding, VizBoundSource, SceneManager } from './primitives/visualizer';
import { RobotDefinitionService } from '../robot';
import type { SubscribeResult } from '../rocos-client';
import { RocosClientService } from '../rocos-client';
import { RobotSettingType } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class GlobalMapService {
  public sceneManager: SceneManager;
  public receivedBoundMessage: Subject<VizBinding> = new Subject<VizBinding>();
  private projectId: string;
  private subscription: {
    [key: string]: SubscribeResult;
  } = {};

  /**
   * Default bindings info, will reload every time robots have been updated.
   */
  private defaultBindings: {
    [callsign: string]: SimpleBindings;
  } = {};

  constructor(
    private rocosClientService: RocosClientService,
    private robotDefinitionService: RobotDefinitionService,
    private ngZone: NgZone,
  ) {
    this.sceneManager = new SceneManager('rocos-scene-global-map');
  }

  public setProjectId(projectId: string) {
    this.projectId = projectId;
  }

  public updateSceneJson(jsonString: string) {
    this.sceneManager.loadSceneFromJson(JSON.parse(jsonString));
  }

  public updateRobotsInfo(robots: any) {
    this.sceneManager.robots = robots;
  }

  public getJSWidgets(): WidgetJavascript[] {
    if (this.sceneManager.panels?.['jsPanel']?.widgets) {
      return this.sceneManager.panels['jsPanel'].widgets as WidgetJavascript[];
    }
    return [];
  }

  public updateJSWidgets(widgets: WidgetJavascript[]) {
    if (this.sceneManager.panels && !this.sceneManager.panels['jsPanel']?.widgets) {
      this.sceneManager.panels['jsPanel'] = {
        widgets: [],
        title: 'Javascript Panel',
      };
    }

    if (this.sceneManager.panels?.['jsPanel']?.widgets) {
      this.sceneManager.panels['jsPanel'].widgets = widgets;
    }
  }

  public trySubscribeToBoundSubscriptions() {
    this.ngZone.runOutsideAngular(() => this.cleanSubscribeToBoundSubscriptions());
  }

  public tryStopBoundSubscriptions() {
    this.ngZone.runOutsideAngular(() => this.unsubscribeBoundSubscriptions());
  }

  /**
   * After JSON loaded, try to subscribe based on data source.
   *
   * If "default", then load more settings from back-end.
   * If "custom", then load settings from JSON, and subscribe.
   */
  public trySubscribeBasedOnDataSources() {
    const callsigns = this.sceneManager.getCallsigns();
    if (!callsigns?.length) {
      // If no callsigns, we need to unsubscribe all connections.
      this.tryStopBoundSubscriptions();
      return;
    }

    callsigns.forEach((callsign) => {
      const dataSource = this.sceneManager.getDataSourceOfRobot(callsign);
      switch (dataSource) {
        case 'default':
          // Reset simple bindings.
          this.resetSimpleBindingsOfRobot(callsign);
          // Reset Viz bindings.
          this.resetBindingsOfRobot(callsign);
          // Load settings from backend.
          this.loadGlobalSettingsFromRobotDefinition(callsign, true);
          break;
        case 'custom':
          // Load default settings.
          // We still have to load default settings as user may
          // switch from "custom" to "default" data source.
          this.loadDefaultSettingsFromRobotDefinition(callsign);

          // Load settings from JSON.
          this.trySubscribeToBoundSubscriptions();
          break;
        default:
          // Do nothing now.
          break;
      }
    });
  }

  public getDataSourceOfRobot(callsign: string) {
    return this.sceneManager.getDataSourceOfRobot(callsign);
  }

  // --------------------------------------
  // Robot bindings
  // --------------------------------------

  /**
   * Assigns a value to the object
   */
  public updateBinding(updatedBinding: VizBinding, refreshSubscriptions = true) {
    const meshPropertyKey = updatedBinding.key; // key example is robot1.position.x, this doesn't change

    // remove the key from any existing references
    this.deleteBinding(meshPropertyKey);

    if (
      updatedBinding.valueExpression.trim() === '' ||
      (updatedBinding.valueExpression.trim().indexOf('$object') === -1 &&
        updatedBinding.valueExpression.trim().indexOf('$msg') === -1) ||
      updatedBinding.boundSourceKey.trim() === ''
    ) {
      // None
    } else {
      // add it into a boundSource now (e.g. robot1/rosbridge/pose/)
      if (!this.sceneManager.boundSources[updatedBinding.boundSourceKey]) {
        this.sceneManager.boundSources[updatedBinding.boundSourceKey] = {
          type: 'stream',
          bindings: {},
        };
      }

      this.sceneManager.boundSources[updatedBinding.boundSourceKey].bindings[meshPropertyKey] = {
        type: 'json',
        valueExpression: updatedBinding.valueExpression,
      };

      // refresh the bindings lookups
      this.sceneManager.updateBindingsKeyArray();
    }

    if (refreshSubscriptions) {
      // force the update to the subscribed streams
      this.ngZone.runOutsideAngular(() => {
        this.cleanSubscribeToBoundSubscriptions();
      });
    }
  }

  public addBinding(newBinding: VizBinding, refreshSubscriptions = false) {
    const bindingKey = newBinding.key;
    if (!this.sceneManager.bindingsKeyExists[bindingKey]) {
      this.updateBinding(newBinding, refreshSubscriptions);
    }
  }

  public resetBindingsOfRobot(callsign: string): void {
    this.sceneManager.resetboundSourcesOfRobot(callsign);
  }

  // --------------------------------------
  // Simple bindings (for Widgets)
  // --------------------------------------

  public addSimpleBindingToRobot(callsign: string, binding: SimpleBinding) {
    if (callsign && binding?.name) {
      this.checkRobotBindingAndCreateIfNotExists(callsign);

      if (!this.sceneManager.robots[callsign].bindings[binding.name]) {
        this.sceneManager.robots[callsign].bindings[binding.name] = binding;
      }
      // If binding exist, then will not update.
    }
  }

  public updateSimpleBindingToRobot(callsign: string, binding: SimpleBinding) {
    if (callsign && binding?.name) {
      this.checkRobotBindingAndCreateIfNotExists(callsign);
      this.sceneManager.robots[callsign].bindings[binding.name] = binding;
    }
  }

  public getSimpleBindingsOfRobot(callsign: string): SimpleBindings {
    let bindings = null;
    if (this.sceneManager?.robots?.[callsign]) {
      const bindingsJson = this.sceneManager.robots[callsign].bindings;
      if (bindingsJson) bindings = SimpleBindings.fromJSON(bindingsJson);
    }

    return bindings;
  }

  public resetSimpleBindingsOfRobot(callsign: string): void {
    if (!callsign) return;
    this.checkRobotBindingAndCreateIfNotExists(callsign);
    this.sceneManager.robots[callsign].bindings = {};
  }

  // --------------------------------------
  // Default bindings
  // --------------------------------------
  public getDefaultBindingsOfRobot(callsign: string): SimpleBindings {
    if (callsign && this.defaultBindings?.[callsign]) {
      return this.defaultBindings[callsign];
    }

    return {};
  }

  public addOrUpdateDefaultBindingsToRobot(callsign: string, binding: SimpleBinding) {
    if (!(callsign && binding?.name)) return;
    if (!this.defaultBindings) this.defaultBindings = {};
    if (!this.defaultBindings[callsign]) this.defaultBindings[callsign] = {};
    this.defaultBindings[callsign][binding.name] = binding;
  }

  public resetAllDefaultBindings() {
    this.defaultBindings = {};
  }

  public resetDefaultBindingsForRobot(callsign: string) {
    if (!this.defaultBindings) this.defaultBindings = {};
    this.defaultBindings[callsign] = {};
  }

  /**
   * Switch Settings for robot
   *
   * @param callsign Robot callsign
   * @param dataSource Data source name
   * @param resub Resubscribe data or not
   */
  public switchSettingsDataSourceForRobot(callsign: string, dataSource: string, resub: boolean = false) {
    if (callsign && dataSource) {
      // Set (save) robot data source.
      this.setRobotDataSource(callsign, dataSource);
      let defaultBindings: SimpleBindings;
      switch (dataSource) {
        case 'default':
        case 'custom': // We need to reset and use default settings when switch from "default" to "custom";
          defaultBindings = this.getDefaultBindingsOfRobot(callsign);
          if (!defaultBindings) break;

          // For each default binding, update vizbinding (for robot on map) and simple binding (for widget)
          Object.keys(defaultBindings).forEach((key) => {
            const defaultBinding = defaultBindings[key];
            const vizBinding = this.createVizBindingFromSimpleBinding(callsign, defaultBinding);

            // Update for robot
            this.updateBinding(vizBinding, false);
            // Update for widget
            this.updateSimpleBindingToRobot(callsign, defaultBinding);
          });

          if (resub) this.trySubscribeToBoundSubscriptions();
          break;
      }
    }
  }

  /**
   * Clear unused bound sources
   */
  public clearBoundSources() {
    this.sceneManager.clearBoundSources();
    this.sceneManager.updateBindingsKeyArray();
  }

  /**
   * Check robot data source if not exists, then set a default one.
   *
   * @param callsign Robot callsign
   * @param defaultDataSource Default data source (i.e. "default")
   */
  public checkAndSetDefaultRobotDataSource(callsign: string, defaultDataSource: string = 'default') {
    if (!callsign) return;
    if (!this.sceneManager.robots) this.sceneManager.robots = {};
    if (!this.sceneManager.robots[callsign]) this.sceneManager.robots[callsign] = {};

    // Only set one when it doesn't exist. otherwise, do nothing.
    if (!this.sceneManager.robots[callsign].dataSource) {
      this.sceneManager.robots[callsign].dataSource = defaultDataSource;
    }
  }

  getGlobalSettingsFromResponse(res: any) {
    if (res?.items && res.items.length > 0) {
      const filtered = res.items
        .filter((item) => {
          return item?.value?.settings && item.value.id === RobotSettingType.OpsGlobal;
        })
        .map((item) => {
          return item?.value?.settings;
        });

      if (filtered?.length > 0) {
        return filtered[0];
      }
    }

    return null;
  }

  getBindingsFromRawSettings(settings: any): any[] {
    if (settings.bindings) {
      return settings.bindings;
    } else {
      return [];
    }
  }

  // ------------------------------------------------------------------------------
  // Private Methods
  private deleteBinding(bindingKey: string) {
    if (this.sceneManager.bindingsKeyExists[bindingKey]) {
      const sourceKey = this.sceneManager.bindingsSourceKey[bindingKey];
      delete this.sceneManager.boundSources[sourceKey].bindings[bindingKey];

      // there are no bindings left in this source, so remove it
      if (Object.keys(this.sceneManager.boundSources[sourceKey].bindings).length === 0) {
        delete this.sceneManager.boundSources[sourceKey];
      }

      // refresh the bindings lookups
      this.sceneManager.updateBindingsKeyArray();
    }
  }

  /** Unsubscribes from all Rocos subscriptions on bindings */
  private unsubscribeBoundSubscriptions() {
    Object.keys(this.subscription).forEach((subKey) => {
      if (this.subscription[subKey]) this.rocosClientService.unsubscribe(this.subscription[subKey]);
    });
  }

  private cleanSubscribeToBoundSubscriptions() {
    NgZone.assertNotInAngularZone();
    this.unsubscribeBoundSubscriptions();

    const robotSources: VizBoundSource[] = []; // stores all bindings
    const robotCallsigns: string[] = []; // stores a distinct list of robots that have bindings

    // populate list of distinct robotCallsigns
    Object.keys(this.sceneManager.boundSources).forEach((dsKey) => {
      const bSource = new VizBoundSource(dsKey);
      robotSources.push(bSource);

      if (!robotCallsigns.includes(bSource.robotCallSign)) {
        robotCallsigns.push(bSource.robotCallSign);
      }
    });

    // create a subscription per robot, grouped by Robot for efficiency on the Rocos server side
    robotCallsigns.forEach((robotCallsign) => {
      // build a list of sources unique to this robot to subscribe to
      const robotsSources: string[] = [];
      robotSources
        .filter((rSource) => rSource.robotCallSign === robotCallsign)
        .forEach((rSource) => {
          if (!robotsSources[rSource.robotCallSign]) {
            // if not already loaded (multiple objects bound to same source)
            robotsSources.push(rSource.dataUri);
          }
        });

      // subscribe to the robot with unique sources
      this.subscription[robotCallsign] = this.rocosClientService.subscribe(
        this.projectId,
        [robotCallsign],
        robotsSources,
      );

      // process inbound messages from the suscribed sources
      this.subscription[robotCallsign].subscription = this.subscription[robotCallsign].observable.subscribe(
        (msg: IRocosTelemetryMessage) => {
          if (msg) this.processSubscriptionMessage(msg);
        },
      );
    });
  }

  /**
   * Processes the inbound stream message and creates the data snapshot for storage and apply to binding if live
   *
   * @param msg Rocos Subscription message
   */
  private processSubscriptionMessage(msg: IRocosTelemetryMessage) {
    const boundSourceKey = '/' + msg.callsign + msg.source;
    if (!this.sceneManager) return;

    const boundSource = this.sceneManager.boundSources[boundSourceKey];
    if (!boundSource) return;

    Object.keys(boundSource.bindings).forEach((bindingKey) => {
      const binding = boundSource.bindings[bindingKey];
      this.generateBoundUpdate(bindingKey, boundSourceKey, binding.valueExpression, msg);
    });
  }

  private generateBoundUpdate(bindingKey, boundSourceKey, valueExpression, msg: IRocosTelemetryMessage) {
    // find the $msg reference, swap it out and run enum
    const binding = new VizBinding(bindingKey, boundSourceKey, valueExpression, null);

    // get the value that is to be assigned to the bound binding key (e.g. what will be assigned to robo1.position.x)
    const evalOutcome = binding.getBoundValue(msg.payload);
    if (evalOutcome.evalSuccess) {
      const evalledPayloadValue = evalOutcome.evaluatedResponseObject;
      if (evalledPayloadValue !== undefined) {
        binding.value = evalledPayloadValue;
        this.receivedBoundMessage.next(binding);
      }
    }
  }

  private checkRobotBindingAndCreateIfNotExists(callsign: string) {
    if (!callsign) return;
    if (!this.sceneManager.robots) this.sceneManager.robots = {};
    if (!this.sceneManager.robots[callsign]) this.sceneManager.robots[callsign] = {};
    if (!this.sceneManager.robots[callsign].bindings) this.sceneManager.robots[callsign].bindings = {};
  }

  /**
   * Set data source for robot.
   *
   * @param callsign Robot Callsign
   * @param dataSource Data source ("default" or "custom")
   */
  private setRobotDataSource(callsign: string, dataSource: string = 'default') {
    // Check and set a default one
    this.checkAndSetDefaultRobotDataSource(callsign, dataSource);

    // Set data source.
    this.sceneManager.robots[callsign].dataSource = dataSource;
  }

  private createVizBindingFromSimpleBinding(callsign: string, simpleBinding: SimpleBinding) {
    return new VizBinding(
      callsign + '.' + simpleBinding.name,
      '/' + callsign + simpleBinding.source,
      simpleBinding.valueExpression,
      null,
    );
  }

  /**
   * Load default settings from robot definition for robot
   *
   * @param callsign Robot callsign
   */
  private loadDefaultSettingsFromRobotDefinition(callsign: string) {
    this.robotDefinitionService
      .getSettingsForRobot(this.projectId, callsign)
      .pipe(first())
      .subscribe((res) => {
        // Reset default bindings for robot
        this.resetDefaultBindingsForRobot(callsign);
        // Set default data source.
        this.checkAndSetDefaultRobotDataSource(callsign);
        const settings = this.getGlobalSettingsFromResponse(res);
        if (settings) {
          // Update bindings
          const bindings = this.getBindingsFromRawSettings(settings);

          if (bindings) {
            bindings.forEach((b) => {
              // Add binding to robot.
              const simpleBinding = new SimpleBinding(b.name, b.source, b.valueExpression);
              this.addOrUpdateDefaultBindingsToRobot(callsign, simpleBinding);
            });
          }
        }
      });
  }

  private loadGlobalSettingsFromRobotDefinition(callsign: string, subscribe: boolean = false) {
    this.robotDefinitionService
      .getSettingsForRobot(this.projectId, callsign)
      .pipe(first())
      .subscribe((res) => {
        // Reset default bindings for robot
        this.resetDefaultBindingsForRobot(callsign);

        // Set default data source.
        this.checkAndSetDefaultRobotDataSource(callsign);

        const settings = this.getGlobalSettingsFromResponse(res);
        if (!settings) return;

        const bindings = this.getBindingsFromRawSettings(settings);
        if (!bindings) return;

        bindings.forEach((b) => {
          const binding = new VizBinding(callsign + '.' + b.name, '/' + callsign + b.source, b.valueExpression, null);
          this.updateBinding(binding, false);

          // Add binding to robot.
          const simpleBinding = new SimpleBinding(b.name, b.source, b.valueExpression);
          this.updateSimpleBindingToRobot(callsign, simpleBinding);
          this.addOrUpdateDefaultBindingsToRobot(callsign, simpleBinding);
        });

        if (subscribe) this.trySubscribeToBoundSubscriptions();
      });
  }
}
