import { Injectable } from '@angular/core';
import type { Event } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RouterUtils } from '../../utils';

export type ViewingPageType = 'project' | 'account' | 'robot' | 'unknown';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public projectId: string;
  public accountId: string;
  public callsign: string;

  public projectIdChange: Subject<string> = new Subject<string>();
  public accountIdChange: Subject<string> = new Subject<string>();
  public callsignChange: Subject<string> = new Subject<string>();
  public pageTypeChange: Subject<ViewingPageType> = new Subject<ViewingPageType>();

  private currentPageType: ViewingPageType = 'unknown';

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const params = RouterUtils.collectParams(this.router.routerState.snapshot.root);

        if (params['accountId']) {
          this.onAccountIdChanged(params['accountId']);
        }

        this.onProjectIdChanged(params['projectId']);
        this.onCallsignChanged(params['callsign']);
      }
    });
  }

  updateAccountId(accountId: string, changePageType: boolean = false) {
    if (accountId) {
      this.onAccountIdChanged(accountId, changePageType);
    }
  }

  private onProjectIdChanged(projectId: string, changePageType: boolean = true) {
    if (projectId !== this.projectId) {
      this.projectId = projectId;
      this.projectIdChange.next(this.projectId);
    }

    if (changePageType) {
      this.changeCurrentPageType('project');
    }
  }

  private onCallsignChanged(callsign: string, changePageType: boolean = true) {
    if (callsign !== this.callsign) {
      this.callsign = callsign;
      this.callsignChange.next(this.callsign);
    }

    if (changePageType) {
      this.changeCurrentPageType('robot');
    }
  }

  private onAccountIdChanged(accountId: string, changePageType: boolean = true) {
    if (accountId !== this.accountId) {
      this.accountId = accountId;
      this.accountIdChange.next(this.accountId);
    }

    if (changePageType) {
      this.changeCurrentPageType('account');
    }
  }

  private changeCurrentPageType(newType: ViewingPageType) {
    if (newType !== this.currentPageType) {
      this.pageTypeChange.next(newType);

      this.currentPageType = newType;
    }
  }
}
