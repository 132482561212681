<app-widget-base
  [heading]="heading"
  [type]="type"
  [dataURI]="null"
  [editing]="editing"
  (deleted)="onDelete()"
  [isGroupWidget]="isGroupWidget"
  [height]="height"
  [width]="width"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [toolbarItems]="toolbarItems"
  [theme]="theme"
>
  <div class="content content-container">
    <ng-container *ngIf="!demoMode">
      <ng-container *ngIf="pageType === 'local'">
        <app-widget-operation-local
          [widget]="widget"
          [pageId]="pageId"
          [projectId]="projectId"
          [callsign]="callsign"
          (dataChanged)="dataChanged.emit()"
          class="local-ops"
          #localOps
        >
        </app-widget-operation-local>
      </ng-container>
      <ng-container *ngIf="pageType === 'global'">
        <app-widget-operation-global
          [pageId]="pageId"
          [projectId]="projectId"
          class="global-ops"
          [callsign]="callsign"
          #globalOps
        >
        </app-widget-operation-global>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="demoMode">
      <div class="demo-content-placeholder">
        <!-- OPERATION -->
      </div>
    </ng-container>
  </div>
</app-widget-base>
