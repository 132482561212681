import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import type { Subscription } from 'rxjs';
import { NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from './config';
import type { editor } from 'monaco-editor';
import type { EditorOptions } from './types';

let loadedMonaco = false;
let loadPromise: Promise<void>;

@Component({
  template: '',
})
export abstract class BaseEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('editorContainer', { static: true }) _editorContainer: ElementRef;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onInit = new EventEmitter<editor.IStandaloneCodeEditor>();
  protected _editor: editor.IStandaloneCodeEditor;
  protected _options: EditorOptions;
  protected _windowResizeSubscription: Subscription;

  // if changing this, make sure you update angular.json as well
  private readonly assetPath = '/monaco-editor-0.38.0/min/vs';

  constructor(@Inject(NGX_MONACO_EDITOR_CONFIG) protected config: NgxMonacoEditorConfig) {}

  ngAfterViewInit(): void {
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        this.initMonaco(this._options);
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise<void>((resolve: any) => {
        const baseUrl = (this.config.baseUrl || './assets') + this.assetPath;
        if (typeof window.monaco === 'object') {
          resolve();
          return;
        }
        const onGotAmdLoader: any = () => {
          // Load monaco
          (window as any).require.config({ paths: { 'vs': `${baseUrl}` } });
          (window as any).require([`vs/editor/editor.main`], () => {
            if (typeof this.config.onMonacoLoad === 'function') {
              this.config.onMonacoLoad(window.monaco);
            }
            this.initMonaco(this._options);
            resolve();
          });
        };

        // Load AMD loader if necessary
        if (!(window as any).require) {
          this.addScriptTag(`${baseUrl}/loader.js`).then(() => onGotAmdLoader());
        } else {
          onGotAmdLoader();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }

  private addScriptTag(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.addEventListener('load', () => resolve());
      script.addEventListener('error', (err) => reject(err));
      document.body.appendChild(script);
    });
  }

  protected abstract initMonaco(options: EditorOptions): void;
}
