import type { RobotType } from './robot-type';

export class RobotTypeOption {
  type: RobotType;
  text: string;

  constructor(type: RobotType, text: string) {
    this.type = type;
    this.text = text;
  }

  static default(type: RobotType, text?: string): RobotTypeOption {
    const option = new RobotTypeOption(type, text);

    if (!text) {
      switch (type) {
        case 'virtualRos':
          option.text = 'ROS (Virtual)';
          break;
        case 'ros-turtle3':
          option.text = 'ROS (Turtle3)';
          break;
        default:
          option.text = type ? type.toUpperCase() : 'Unknown';
          break;
      }
    }

    return option;
  }
}
