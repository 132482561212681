import type { Position } from '../primitives';
import type { Material, Scene } from '@babylonjs/core';
import { Animation, Color4, PolygonMeshBuilder, TransformNode, Vector2, Vector3 } from '@babylonjs/core';
import earcut from 'earcut';
import { getGizmoRoot, gizmoId } from './gizmo-utils';

export const ARROW_ROOT_ID = 'polygon-root';

export const renderArrowGizmo = ({
  id,
  location,
  material,
  scene,
}: {
  id: string;
  location: Position;
  material: Material;
  scene: Scene;
}) => {
  const gid = gizmoId(ARROW_ROOT_ID, id);
  const root = getGizmoRoot(scene, ARROW_ROOT_ID);

  let arrow = scene.getTransformNodeByName(gid);
  if (!arrow) arrow = new TransformNode(gid, scene);
  arrow.parent = root;
  arrow.position = new Vector3(location.x, location.y, location.z);

  const corners = [
    new Vector2(0, 0),
    new Vector2(1, 1),
    new Vector2(0.5, 1),
    new Vector2(0.5, 3),
    new Vector2(-0.5, 3),
    new Vector2(-0.5, 1),
    new Vector2(-1, 1),
  ];
  const polygonExtrusion = new PolygonMeshBuilder(`${gid}-shape`, corners, undefined, earcut);
  const arrowShape = polygonExtrusion.build(false, 0.3);
  arrowShape.setPivotPoint(new Vector3(0, -0.15, 0));
  arrowShape.position = new Vector3(0, 0.15, 0);
  arrowShape.scaling = new Vector3(0.15, 0.15, 0.15);
  arrowShape.parent = arrow;
  arrowShape.material = material;
  arrowShape.enableEdgesRendering();
  arrowShape.edgesWidth = 2.0;
  arrowShape.edgesColor = new Color4(0, 0.74, 0.84, 1);

  const arrowAnim = new Animation(
    `${gid}-rotation`,
    'rotation.z',
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE,
  );
  arrowAnim.setKeys([
    { frame: 0, value: 0 },
    { frame: 30, value: Math.PI },
    { frame: 60, value: Math.PI * 2 },
  ]);
  arrow.animations = [arrowAnim];
  scene.beginAnimation(arrow, 0, 60, true);
};

export const setArrowGizmoAltitude = ({
  scene,
  id,
  altitude,
}: {
  scene: Scene;
  id: string;
  altitude: number;
}): void => {
  const gid = gizmoId(ARROW_ROOT_ID, id);
  const arrow = scene.getTransformNodeByName(gid);
  if (!arrow) return;
  arrow.position.z = altitude;
};

export const deleteArrowGizmo = ({ scene, id }: { scene: Scene; id: string }): void => {
  const gid = gizmoId(ARROW_ROOT_ID, id);
  const arrow = scene.getTransformNodeByName(gid);
  if (!arrow) return;
  scene.stopAnimation(arrow);
  arrow.dispose();
};

export const startArrowGizmoSnapAnim = ({ scene, id }: { scene: Scene; id: string }): void => {
  const gid = gizmoId(ARROW_ROOT_ID, id);
  const arrowShape = scene.getMeshById(`${gid}-shape`);
  if (!arrowShape || arrowShape.animations.length > 1) return;
  const arrowAnim = new Animation(
    `${gid}-bounce`,
    'position.z',
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE,
  );
  arrowAnim.setKeys([
    { frame: 0, value: 0 },
    { frame: 15, value: 0.3 },
    { frame: 30, value: 0 },
  ]);
  scene.beginDirectAnimation(arrowShape, [arrowAnim], 0, 30, true);
};

export const stopArrowGizmoSnapAnim = ({ scene, id }: { scene: Scene; id: string }): void => {
  const gid = gizmoId(ARROW_ROOT_ID, id);
  const arrowShape = scene.getMeshById(`${gid}-shape`);
  if (!arrowShape) return;
  scene.stopAnimation(arrowShape, `${gid}-bounce`);
  const arrowAnim = new Animation(
    `${gid}-bounce`,
    'position.z',
    30,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );
  arrowAnim.setKeys([
    { frame: 0, value: arrowShape.position.z },
    { frame: 30, value: 0 },
  ]);
  scene.beginDirectAnimation(arrowShape, [arrowAnim], 0, 30, false);
};
