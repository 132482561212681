import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { FunctionConfig } from '../../models';
import { MockService } from '../mock';
import { RocosClientService } from '../rocos-client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  constructor(private mockService: MockService<FunctionConfig>, private rocosClientService: RocosClientService) {}

  info(projectId: string, functionId: string): Observable<FunctionConfig> {
    return this.rocosClientService.rocosClient.function.info(projectId, functionId).pipe(
      map((res) => {
        const data = res.data as any;

        if (!data) return undefined;

        return FunctionConfig.fromModel(data);
      }),
    );
  }

  create(projectId: string, config?: FunctionConfig): Observable<any> {
    return this.rocosClientService.rocosClient.function.create(projectId, config as any).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  update(projectId: string, functionId: string, config?: FunctionConfig): Observable<any> {
    return this.rocosClientService.rocosClient.function.update(projectId, functionId, config as any).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  delete(projectId: string, functionId: string): Observable<any> {
    return this.rocosClientService.rocosClient.function.delete(projectId, functionId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  list(projectId: string): Observable<FunctionConfig[]> {
    return this.rocosClientService.rocosClient.function.list(projectId).pipe(
      map((res) => {
        const data = res.data as any;

        let list = [];
        if (data?.length > 0) {
          list = data.map((item) => {
            return FunctionConfig.fromModel(item);
          });
        }

        return list;
      }),
    );
  }

  run(projectId: string, functionId: string, body: any): Observable<any> {
    return this.rocosClientService.rocosClient.function.run(projectId, functionId, body).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  pods(projectId: string, functionId: string): Observable<any> {
    return this.rocosClientService.rocosClient.function.pods(projectId, functionId).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }
}
