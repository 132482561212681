<div class="rocos-dark-theme apm" [ngClass]="['size-' + windowSize]" (dblclick)="onClickBackground()">
  <!-- Debug buttons -->
  <div class="buttons block">
    Debug Levels:
    <mat-button-toggle-group (change)="onDebugLevelChange($event)">
      <mat-button-toggle value="silent">Silent</mat-button-toggle>
      <mat-button-toggle value="error">Error</mat-button-toggle>
      <mat-button-toggle value="warn">Warn</mat-button-toggle>
      <mat-button-toggle value="info">Info</mat-button-toggle>
      <mat-button-toggle value="debug">Debug</mat-button-toggle>
      <mat-button-toggle value="trace">Trace</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- Sub Ids -->
  <div class="ids block">
    <div *ngFor="let id of subIds; let idx = index" class="id">
      <span>{{ idx + 1 }} - </span>
      <span>{{ id }}</span>
    </div>
  </div>

  <!-- Detail output -->
  <pre>{{ subscribersInfo | json }}</pre>
</div>
