import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { BabylonRendererService } from './babylon-renderer.service';
import { GuiFactoryService } from './factories/gui-factory.service';
import type { IMesh } from './primitives/visualizer/interface/IMesh';
import type { Feature, FeatureCollection, LineString, Point } from 'geojson';
import { RobotMapsService } from '@robot/shared/robot-maps.service';
import type { Mesh } from '@babylonjs/core';
import { createSphere, createTube } from './primitives/meshPrimitives';

interface GeoJsonReference {
  collection: FeatureCollection;
  meshData: IMesh;
}

@Injectable()
export class GeoJsonService implements OnDestroy {
  private features: Record<string, string> = {};
  private meshObjects: Record<string, string[]> = {};
  private documentVisible = true;

  private get scene() {
    return this.babylonRenderer.scene;
  }

  public constructor(
    private babylonRenderer: BabylonRendererService,
    private guiFactoryService: GuiFactoryService,
    private robotMapService: RobotMapsService,
  ) {
    document.addEventListener('visibilitychange', this.updateVisibilityState);
  }

  // Note: This works as this service is not provided in root.
  // If this ever changes, we will have to revisit this.
  public ngOnDestroy(): void {
    this.features = {};
    this.meshObjects = {};
    document.removeEventListener('visibilitychange', this.updateVisibilityState);
  }

  public loadAndRenderGeoJson(meshId: string, meshData: IMesh) {
    if (!meshData.geoJSON?.mapId || !meshData.ops?.visible) return;
    this.robotMapService
      .getGeoJSON(meshData.geoJSON.mapId)
      .then((geoJson) => {
        if (!geoJson || !meshData?.ops?.visible) return;
        this.renderGeoJson(meshId, meshData, geoJson);
      })
      .catch((err) => {
        console.warn(`Failed to load map for ${meshData.displayName || meshData.key}`, err);
      });
  }

  public renderGeoJson(meshId: string, meshData: IMesh, collection: FeatureCollection) {
    if (!this.documentVisible || !meshData.ops?.visible) return;

    // Check if data has changed.
    const prevJsonStr = this.features[meshId];
    const newFeature: GeoJsonReference = { collection, meshData };
    const newJsonStr = JSON.stringify(newFeature);
    if (prevJsonStr === newJsonStr) return;
    this.features[meshId] = newJsonStr;

    this.clearGeoJson(meshId);
    this.renderFeatures(meshId, meshData, newFeature.collection);
  }

  private updateVisibilityState() {
    this.documentVisible = document.visibilityState !== 'hidden';
  }

  private clearGeoJson(meshId: string) {
    const meshNames = this.getMeshObjectsForSceneLayer(meshId);
    if (meshNames) {
      for (const item of meshNames) {
        this.scene.getMeshByName(item)?.dispose();
      }
    }
    this.removeAllMeshObjectsForSceneLayer(meshId);
    this.scene.getMeshByName(meshId)?.dispose();
    this.guiFactoryService.removeAllGuiControlsOfParentName(meshId);
  }

  private renderFeatures(meshId: string, meshData: IMesh, collection: FeatureCollection) {
    const parentMesh = this.babylonRenderer.getMesh(meshId) as Mesh;
    if (!collection?.features) return;
    for (const feature of collection.features) {
      const mesh = this.featureToMesh(meshId, feature, meshData);
      if (!mesh) continue;
      mesh.parent = parentMesh;
      this.trackMeshObjectsForSceneLayer(meshId, mesh.name);
      const label = feature.properties?.[meshData.geoJSON?.labelProperty] || undefined;
      if (label) this.guiFactoryService.addGuiLabel(label, mesh, meshId);
    }
  }

  private featureToMesh(meshId: string, feature: Feature, meshData: IMesh) {
    const meshName =
      meshId +
      '_' +
      (feature.id || feature.properties['waypoint_number'] || feature.properties['object_id'] || crypto.randomUUID());

    const pointMaterial =
      this.babylonRenderer.getMaterial(meshData?.geoJSON?.pointMaterial) || this.babylonRenderer.getMaterial('green');
    const pointSize = meshData?.geoJSON?.pointSize || 0.2;

    const lineMaterial =
      this.babylonRenderer.getMaterial(meshData?.geoJSON?.lineMaterial) || this.babylonRenderer.getMaterial('green');
    const lineSize = meshData?.geoJSON?.lineSize || 0.1;

    switch (feature.geometry.type) {
      case 'Point':
        return createSphere(this.scene, feature as Feature<Point>, meshName, pointMaterial, pointSize);
      case 'LineString':
        return createTube(this.scene, feature as Feature<LineString>, meshName, lineMaterial, lineSize);
    }
    return null;
  }

  private trackMeshObjectsForSceneLayer(meshId: string, geoJsonObjectMeshName: string): void {
    if (!this.meshObjects[meshId]) this.meshObjects[meshId] = [];
    this.meshObjects[meshId].push(geoJsonObjectMeshName);
  }

  private getMeshObjectsForSceneLayer(meshId: string): string[] {
    return this.meshObjects[meshId];
  }

  private removeAllMeshObjectsForSceneLayer(meshId: string): void {
    if (this.meshObjects[meshId]) this.meshObjects[meshId] = [];
  }
}
