<div class="form">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="formSource" placeholder="Source" cdkFocusInitial />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="formQuerystring" placeholder="Querystring" cdkFocusInitial />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="formPath" placeholder="Path" cdkFocusInitial />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="formDataType" placeholder="Data Type" cdkFocusInitial />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <button mat-stroked-button (click)="resubscribe()">Resubscribe</button>
      </div>
    </div>
  </form>
</div>
