import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

const KEY_HIGHLIGHT_MODE = 'KEY_HIGHLIGHT_MODE';
const KEY_HIDE_DEVELOPING_CONTENT = 'KEY_HIDE_DEVELOPING_CONTENT';
const KEY_HIDE_ADMIN_ONLY_CONTENT = 'KEY_HIDE_ADMIN_ONLY_CONTENT';
const KEY_HIDE_EXPERIMENTAL_CONTENT = 'KEY_HIDE_EXPERIMENTAL_CONTENT';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentService {
  /**
   * Production mode
   */
  public get isProd(): boolean {
    return environment.production;
  }

  /**
   * Developing content highlight mode
   */
  public get isHighlightMode(): boolean {
    const rawValue = localStorage.getItem(KEY_HIGHLIGHT_MODE);
    // Hasn't set yet.
    if (rawValue === null) {
      return !this.isProd;
    }
    const cached = JSON.parse(rawValue);

    return !!cached;
  }
  public set isHighlightMode(val: boolean) {
    localStorage.setItem(KEY_HIGHLIGHT_MODE, JSON.stringify(val));

    this.highlightMode.next(val);
  }

  /**
   * Hide developing content or not.
   */
  public get isHideDevelopingContent(): boolean {
    const rawValue = localStorage.getItem(KEY_HIDE_DEVELOPING_CONTENT);
    // Hasn't set yet.
    if (rawValue === null) {
      return this.isProd;
    }
    const cached = JSON.parse(rawValue);

    return !!cached;
  }
  public set isHideDevelopingContent(val: boolean) {
    localStorage.setItem(KEY_HIDE_DEVELOPING_CONTENT, JSON.stringify(val));

    this.hideDevelopingContent.next(val);
  }

  /**
   * Hide admin only content or not.
   */
  public get isHideAdminOnlyContent(): boolean {
    const rawValue = localStorage.getItem(KEY_HIDE_ADMIN_ONLY_CONTENT);
    // Hasn't set yet.
    if (rawValue === null) {
      return false;
    }
    const cached = JSON.parse(rawValue);

    return !!cached;
  }
  public set isHideAdminOnlyContent(val: boolean) {
    localStorage.setItem(KEY_HIDE_ADMIN_ONLY_CONTENT, JSON.stringify(val));

    this.hideAdminContent.next(val);
  }

  /**
   * Hide admin only content or not.
   */
  public get isHideExperimentalContent(): boolean {
    const rawValue = localStorage.getItem(KEY_HIDE_EXPERIMENTAL_CONTENT);
    // Hasn't set yet.
    if (rawValue === null) {
      return false;
    }
    const cached = JSON.parse(rawValue);

    return !!cached;
  }
  public set isHideExperimentalContent(val: boolean) {
    localStorage.setItem(KEY_HIDE_EXPERIMENTAL_CONTENT, JSON.stringify(val));

    this.hideExperimentalContent.next(val);
  }

  /**
   * Highlight mode subject
   */
  public highlightMode: BehaviorSubject<boolean>;
  /**
   * Hiding the Developing content subject
   */
  public hideDevelopingContent: BehaviorSubject<boolean>;
  /**
   * Hiding the admin only content
   */
  public hideAdminContent: BehaviorSubject<boolean>;
  /**
   * Hiding experimental content
   */
  public hideExperimentalContent: BehaviorSubject<boolean>;

  constructor() {
    this.highlightMode = new BehaviorSubject<boolean>(this.isHighlightMode);
    this.hideDevelopingContent = new BehaviorSubject<boolean>(this.isHideDevelopingContent);
    this.hideAdminContent = new BehaviorSubject<boolean>(this.isHideAdminOnlyContent);
    this.hideExperimentalContent = new BehaviorSubject<boolean>(this.isHideExperimentalContent);
  }
}
