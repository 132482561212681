import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-gamepad',
  templateUrl: './widget-gamepad.component.html',
  styleUrls: ['./widget-gamepad.component.scss'],
})
export class WidgetGamepadComponent extends WidgetBaseComponent implements OnInit {
  @Input() public projectId: string;
  @Input() public callsign: string;
  @Input() public keepAlive: boolean;

  public headingSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public get headingObservable(): Observable<string> {
    return this.headingSubject$.asObservable();
  }

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.headingSubject$.next('No Gamepad');
  }
}
