import type { IBoundSource } from '../../services/threeD/primitives/visualizer/interface/IBinding';
import type { ISceneRobot } from '../../services/threeD/primitives/visualizer/interface/ISceneRobot';

export interface GlobalOperationPageConfigModel {
  robots?: Record<string, ISceneRobot>;
  boundSources?: Record<string, IBoundSource>;
  panels?: unknown;
  mapbox?: Record<string, string | number | number[]>;
  followBot?: Record<string, string>;
}

export class GlobalOperationPageConfig {
  public robots: Record<string, ISceneRobot> = {};
  public boundSources: Record<string, IBoundSource> = {};
  public panels: unknown = {};
  public mapbox: Record<string, string | number | number[]> = {};
  public followBot: Record<string, string> = {};

  public static fromModel(model: GlobalOperationPageConfigModel): GlobalOperationPageConfig {
    const config = new GlobalOperationPageConfig();

    config.robots = model.robots ?? {};
    config.boundSources = model.boundSources ?? {};
    config.panels = model.panels ?? {};
    config.mapbox = model.mapbox ?? {};
    config.followBot = model.followBot ?? {};

    return config;
  }

  public getMapStartZoom(): number {
    if (this.mapbox?.['startZoom']) {
      return this.mapbox['startZoom'] as number;
    }
    return null;
  }

  public getMapStartLocation(): [number, number] {
    if (this.mapbox?.['startLocation']) {
      return this.mapbox['startLocation'] as [number, number];
    }
    return null;
  }

  public getFollowBot(): string | undefined {
    return this.followBot?.['callsign'];
  }

  public getCallsigns(): string[] {
    const callsigns = [];
    Object.keys(this.robots).forEach((key) => {
      callsigns.push(key);
    });

    return callsigns;
  }

  public updateCallsigns(callsigns: string[]): void {
    const obj = {};
    const robots = this.robots ? this.robots : {};

    if (callsigns) {
      callsigns.forEach((callsign) => {
        if (robots[callsign]) {
          // Use the existing info
          obj[callsign] = robots[callsign];
        } else {
          // Insert a new callsign
          obj[callsign] = {};
        }
      });
    }

    this.robots = obj;
  }

  public updateFromJson(json: GlobalOperationPageConfigModel): void {
    if (json.robots) {
      this.robots = json.robots;
    }
    if (json.boundSources) {
      this.boundSources = json.boundSources;
    }
    if (json.panels) {
      this.panels = json.panels;
    }
  }

  public updateMapbox(mapbox: Record<string, number | number[] | string>): void {
    if (mapbox) {
      if (!mapbox['mapStyle']) {
        delete mapbox['mapStyle'];
      }

      if (!mapbox['startZoom']) {
        delete mapbox['startZoom'];
      }

      if (!mapbox['startLocation']) {
        delete mapbox['startLocation'];
      }

      this.mapbox = mapbox;
    }
  }

  public updateFollowBot(followBot: Record<string, string>): void {
    if (followBot) {
      if (!followBot['callsign']) {
        this.followBot = null;
      }

      this.followBot = followBot;
    }
  }

  public toJSON(): GlobalOperationPageConfigModel {
    return {
      robots: this.robots,
      boundSources: this.boundSources,
      panels: this.panels,
      mapbox: this.mapbox,
      followBot: this.followBot,
    };
  }
}
