export enum RocosIcon {
  default = 'ri-place-holder',
  robots = 'ri-robots',
  streams = 'ri-streams',
  operations = 'ri-operation',
  globalOperations = 'ri-global-operations',
  localOperations = 'ri-local-operations',
  environment = 'ri-pre-processed-3D',
  home = 'ri-home',
  project = 'ri-project',
  dataExplorer = 'ri-data-explorer',
  settings = 'ri-settings',
  robotDefinitions = 'ri-robot-definition',
  dashboard = 'ri-dashboard',
  mission = 'ri-mission',
  functions = 'ri-functions',
  mapEditor = 'ri-map-editor',
  workflow = 'ri-automate',
  storageQuery = 'ri-storage-query',
  widgetBuilder = 'ri-widget-builder',
}
