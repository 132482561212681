<div class="map-layer">
  <app-widget-map
    *ngIf="configLoaded"
    class="map"
    #map
    (viewUpdated)="onViewUpdated()"
    (mapDragged)="onMapDragged()"
    (mapInitialized)="onMapInitialized()"
    [startLocation]="startLocation"
    [mapStyle]="mapStyle"
    [startZoom]="startZoom"
    [isFollowingBot]="isFollowingBot"
    [showBotLocator]="true"
    (followBotStateChanged)="onFollowBotStateChanged($event)"
  ></app-widget-map>

  <ng-container *ngIf="mapLoaded$ | async">
    <app-widget-javascript
      *ngFor="let widget of jsWidgets; trackBy: trackByFn"
      #widgetJavascript
      [widget]="widget"
      [code]="widget.code"
      [widgetId]="widget.id"
      [autoRun]="widget.autoRun"
      [operationPageContext]="operationPageContext"
      [heading]="widget.widgetHeading"
      [theme]="'dark'"
      [type]="widget.widgetType"
      [editing]="false"
      [height]="''"
      [width]="''"
    >
    </app-widget-javascript>
  </ng-container>
</div>
