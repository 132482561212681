import { Component } from '@angular/core';
import { UserService, AuthService, AppTrackingService } from '@shared/services';
import Intercom, { shutdown, update } from '@intercom/messenger-js-sdk';
import { environment } from '@env/environment';
import type { ESidebarStatus } from '@shared/services/gui/gui-state.service';
import { GuiStateService } from '@shared/services/gui/gui-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, Router } from '@angular/router';
import { filter, withLatestFrom } from 'rxjs';
import { FeatureFlags } from '@shared/services/feature-flag/feature-flags';

@UntilDestroy()
@Component({
  selector: 'app-console-hosting',
  templateUrl: './console-hosting.component.html',
  styleUrls: ['./console-hosting.component.scss'],
})
export class ConsoleHostingComponent {
  public sidebarStatus: ESidebarStatus;
  public FeatureFlags = FeatureFlags;

  public constructor(
    userService: UserService,
    authService: AuthService,
    appTrackingService: AppTrackingService,
    guistate: GuiStateService,
    router: Router,
  ) {
    guistate.sidebarStatus$.pipe(untilDestroyed(this)).subscribe((status) => {
      this.sidebarStatus = status;
    });

    userService.userUpdated.pipe(untilDestroyed(this)).subscribe((user) => {
      if (environment.intercom.enabled) {
        Intercom({
          app_id: environment.intercom.appId,
          name: user.fullName,
          email: user.email,
          user_id: user.id,
        });
      }

      appTrackingService.setUser(user);
    });

    router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(userService.userUpdated),
      )
      .subscribe(([_, user]) => {
        if (environment.intercom.enabled) {
          update({ user_id: user.id, email: user.email, last_request_at: Math.floor(Date.now() / 1000) });
        }
      });

    authService.loggedOut.pipe(untilDestroyed(this)).subscribe(() => {
      shutdown();
    });
  }
}
