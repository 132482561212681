import type { IRocosTelemetryMessage } from '@team-rocos/rocos-js';
import { cloneDeep, each, isEqual, isEqualWith, keys, sortBy } from 'lodash';
import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import type { IDataUriConfig } from '../models';
import { DataUriUtils } from './data-uri-utils';
import { resolve } from './json-path';

export class Utils {
  public static rxjsNullFilter<T>(source: Observable<T>): Observable<T> {
    return source.pipe(filter((v) => !!v));
  }

  public static rxjsGrpcWaitForResult<T>(source: Observable<T[]>): Observable<T[]> {
    return source.pipe(filter((msgs) => msgs?.some((msg) => Object.hasOwnProperty.call(msg, 'result'))));
  }

  public static rxjsGrpcExtractResult(source: Observable<any[]>): Observable<{ message: string; status: number }> {
    return source.pipe(
      map((msgs) => {
        if (msgs) {
          const msg = msgs.find((m) => Object.hasOwnProperty.call(m, 'result'));
          return msg.result;
        }
        return null;
      }),
    );
  }

  public static tryConvertToJSON<T>(input: string): T {
    let output;
    try {
      output = JSON.parse(input);
    } catch {
      output = null;
    }

    return output;
  }

  public static getPayloadIdByTargetString(target: string): string {
    const split = target.split('/');
    if (split?.length > 1) return split.slice(1).join('.');
    return '';
  }

  public static getSourceIdBySourceString(source: string): string {
    const split = source.split('/');
    if (split?.length > 1) return split[1];
    return '';
  }

  public static getDataIdBySourceString(source: string): string {
    const split = source.split('/');
    if (split?.length > 2) return split.slice(2).join('/');
    return '';
  }

  public static getSourcesList(sources: string[]): string[] {
    let newSources = sources as string[];

    // Convert the new type of sources [{source: string, schema: object}] to [string]
    if (newSources?.length > 0) {
      const firstItem = newSources[0];

      if (firstItem?.['source']) {
        // Convert the new type of sources list to a string array.
        newSources = newSources.map((item) => {
          return item['source'];
        });
      }
    }

    return newSources;
  }

  public static isRichJSONSchema<T>(sources: T[]): boolean {
    if (sources?.length > 0) {
      const firstItem = sources[0];

      if (firstItem?.['source'] && firstItem['schema']) {
        return true;
      }
    }

    return false;
  }

  public static getTargetData<T>(msg: IRocosTelemetryMessage, targetField: string): T | null {
    let payload = msg.payload;

    payload = resolve(payload, targetField);

    if (payload) {
      if (payload.length > 1) return payload;
      return payload[0];
    }

    return null;
  }

  public static getTargetSourceByDataURI(dataURI: string, source: string): string {
    const mainDataURI = DataUriUtils.getMainUri(dataURI);
    if (source && mainDataURI?.startsWith(source)) {
      return source;
    } else {
      const split = mainDataURI.split('/');
      if (split?.length > 1) {
        return split.slice(0, -1).join('/');
      }
    }

    return '';
  }

  public static getTargetSourceWithQueryByDataURI(
    dataURI: string,
    source?: string,
    uriConfig?: IDataUriConfig,
  ): string {
    let query = DataUriUtils.getQueryString(dataURI);
    if (!query && uriConfig?.dataUriConfigMode === 'basic') {
      query = 'int=1s';
    }

    const targetSource = this.getTargetSourceByDataURI(dataURI, source);
    let targetSourceWithQuery = targetSource;

    if (query) targetSourceWithQuery = `${targetSource}?${query}`;
    return targetSourceWithQuery;
  }

  public static getTargetFieldByDataURI(dataURI: string, source?: string): string {
    // Remove string after question mark (?)
    dataURI = DataUriUtils.getMainUri(dataURI);

    if (source && dataURI?.startsWith(source)) {
      return dataURI.replace(source, '');
    }

    const split = dataURI.split('/');
    if (split?.length > 1) {
      const last = split.pop();
      return `/${last}`;
    }

    return '';
  }

  public static getSourceByDataURIAndSourcesList(dataURI: string, sources: string[]): string {
    let sourceId: string;
    if (dataURI) {
      // Give it a default value first.
      const defaultSourceId = dataURI.split('/').slice(0, -1).join('/');

      // Compare
      if (sources?.length > 0) {
        sources.forEach((source) => {
          if (dataURI.startsWith(source)) {
            if (!sourceId) {
              sourceId = source;
            } else {
              // compare length
              if (sourceId.length < source.length) {
                sourceId = source; // Replace with this new one, longer one
              }
            }
          }
        });
      }

      if (!sourceId) sourceId = defaultSourceId;
    }

    return sourceId;
  }

  public static simpleStringReplace<T>(originalString: string, replaceString: string, obj: T): string {
    let result = originalString;

    Object.keys(obj).forEach((key) => {
      const target = `${replaceString}.${key}`;
      if (result.indexOf(target) !== -1) {
        result = result.replace(target, obj[key]);
      }
    });

    return result;
  }

  public static radiansToDegrees(radians: number): number {
    return Math.round(radians * (180 / Math.PI));
  }

  public static degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  /**
   * Operation page - map
   */
  public static calculateShadowSize(baseSize: number, altitude: number): number {
    let scale = 1;
    if (altitude) {
      if (altitude > 10 && altitude < 200) {
        scale = 1 - altitude / 400;
      } else if (altitude > 200) {
        scale = 0.5;
      } else if (altitude === 0) {
        scale = 0;
      }
    } else {
      scale = 0;
    }

    return scale * baseSize;
  }

  public static getMetaDataFromLines(str: string): {
    [key: string]: string;
  } {
    const meta = {};

    const lines = str.split('\n');
    if (lines?.length > 0) {
      lines.forEach((line) => {
        const keyValue = line.split(':').map((x) => x.trim());
        if (keyValue?.length === 2) {
          const key = keyValue[0];
          meta[key] = keyValue[1];
        }
      });
    }

    return meta;
  }

  public static sortObject<T>(targetObject: T): T {
    const sortObjectFunc = (object: T): T => {
      const sortedObj = {};
      let keysList = keys(object);

      keysList = sortBy(keysList, (key) => {
        return key;
      });

      each(keysList, (key) => {
        if (typeof object[key] === 'object' && !(object[key] instanceof Array)) {
          sortedObj[key] = sortObjectFunc(object[key]);
        } else {
          sortedObj[key] = object[key];
        }
      });

      return sortedObj as T;
    };

    return sortObjectFunc(targetObject);
  }

  /**
   * @description Get unique values from array.
   * @param items Items of array
   */
  public static getUniqueValuesFromArray<T>(items: T[]): T[] {
    return Array.from(new Set(items));
  }

  /**
   * @description Compare two ConfigGroupItem arrays.
   * @param a A value
   * @param b B value
   */
  public static isItemsChangedCompareWithOriginal<T>(a: T[], b: T[]): boolean {
    const deleteUnrelatedProperties = (obj: any): void => {
      obj.forEach((item: any) => {
        delete item._globalItem;

        if (item.value) {
          delete item.value.status;
          delete item.value.gridComponentHeight;
          delete item.value.gridComponentWidth;
          delete item.value.gridMinSize;
        }
      });
    };

    const customizer = (objValue, othValue): boolean => {
      // Clone to remove the "_globalItems" for comparing.
      const objClone = cloneDeep(objValue);
      const othClone = cloneDeep(othValue);

      if (objClone) {
        deleteUnrelatedProperties(objClone);
      }

      if (othClone) {
        deleteUnrelatedProperties(othClone);
      }

      return isEqual(objClone, othClone);
    };

    return !isEqualWith(a, b, customizer);
  }

  public static addUriParam(uri: string, key: string, val: string, overwrite = false): string {
    const parts = uri.split('?');
    if (parts?.[1]) {
      const paramParts = parts[1].split('#');
      const urlParams = new URLSearchParams('?' + paramParts[0]);
      if (overwrite || !urlParams.get(key)) {
        urlParams.set(key, val);
      }
      paramParts[0] = urlParams.toString();
      parts[1] = paramParts.join('#');
    } else {
      parts.push(`${key}=${val}`);
    }
    return parts.join('?');
  }

  public static arrayRemove(original: string[], remove: string[]): string[] {
    return original?.filter((v) => remove.indexOf(v) === -1);
  }

  public static getArrayDifference(before: string[], after: string[]): { toRemove: string[]; toAdd: string[] } {
    const toRemove: string[] = Utils.arrayRemove(before, after);
    const toAdd: string[] = Utils.arrayRemove(after, before);

    return {
      toRemove,
      toAdd,
    };
  }

  public static roundBigFloat(num: number, precision: number): number {
    const tmpNum = num.toString().split('.');
    if (tmpNum[1]) {
      tmpNum[1] = tmpNum[1].substring(0, precision + 1);
    }
    const offset = precision === 0 ? 1 : Math.pow(10, precision);
    return Math.round(Number(tmpNum.join('.')) * offset) / offset;
  }

  public static base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const bytes = new Uint8Array(binary_string.length);
    for (let i = 0; i < binary_string.length; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public static arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  public static appendBuffer(buffer1, buffer2) {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
  }

  public static buildWaveHeader(opts: any): ArrayBuffer {
    const numFrames = opts.numFrames;
    const numChannels = opts.numChannels || 1;
    const sampleRate = opts.sampleRate || 16000;
    const bytesPerSample = opts.bytesPerSample || 2;
    const blockAlign = numChannels * bytesPerSample;
    const byteRate = sampleRate * blockAlign;
    const dataSize = numFrames * blockAlign;

    const buffer = new ArrayBuffer(44);
    const dv = new DataView(buffer);

    let p = 0;

    const writeString = (s) => {
      for (let i = 0; i < s.length; i++) {
        dv.setUint8(p + i, s.charCodeAt(i));
      }
      p += s.length;
    };

    const writeUint32 = (d) => {
      dv.setUint32(p, d, true);
      p += 4;
    };

    const writeUint16 = (d) => {
      dv.setUint16(p, d, true);
      p += 2;
    };

    writeString('RIFF'); // ChunkID
    writeUint32(dataSize + 36); // ChunkSize
    writeString('WAVE'); // Format
    writeString('fmt '); // Subchunk1ID
    writeUint32(16); // Subchunk1Size
    writeUint16(1); // AudioFormat
    writeUint16(numChannels); // NumChannels
    writeUint32(sampleRate); // SampleRate
    writeUint32(byteRate); // ByteRate
    writeUint16(blockAlign); // BlockAlign
    writeUint16(bytesPerSample * 8); // BitsPerSample
    writeString('data'); // Subchunk2ID
    writeUint32(dataSize); // Subchunk2Size

    return buffer;
  }
}
