import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-chip',
  template: `<span class="chip" [ngClass]="theme"><ng-content></ng-content></span>`,
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() public theme?: 'success' | 'grey' = 'grey';
}
