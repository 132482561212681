import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import type { DataExplorerResult } from '../data-explorer-wrapper';
import { UnquotedPropertyUtils } from '../../utils';

export interface DataPickerDialogData {
  projectId: string;
  callsign: string;
  /**
   * Include callsign in callback or not.
   *
   * If include, result will be `/${result.callsign}${result.dataUri}`.
   * otherwise, it will be `${result.dataUri}`
   */
  includeCallsignInCallback: boolean;
  hidePicker?: boolean;
  showServices?: boolean;
}

export interface DataPickerDialogResultData {
  source: string;
  valueExpression: string;
}

@Component({
  selector: 'app-data-picker-dialog',
  templateUrl: './data-picker-dialog.component.html',
  styleUrls: ['./data-picker-dialog.component.scss'],
})
export class DataPickerDialogComponent {
  projectId: string;
  callsign: string;
  includeCallsignInCallback: boolean = true;
  hidePicker: boolean;
  showServices: boolean;

  constructor(
    public dialogRef: MatDialogRef<DataPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DataPickerDialogData,
  ) {
    if (data?.projectId) {
      this.projectId = data.projectId;
    }
    if (data?.callsign) {
      this.callsign = data.callsign;
    }
    if (data?.includeCallsignInCallback !== undefined) {
      this.includeCallsignInCallback = data.includeCallsignInCallback;
    }
    if (data?.hidePicker) {
      this.hidePicker = data.hidePicker;
    }
    if (data?.showServices) {
      this.showServices = data.showServices;
    }
  }

  public onCancel() {
    this.dialogRef.close();
  }

  onDataExplorerChange(result: DataExplorerResult) {
    if (result?.treeItem && result.callsign) {
      let source = `${result.treeItem.rootSource}`;
      if (this.includeCallsignInCallback) {
        source = `/${result.callsign}${source}`;
      }

      const ids = result.treeItem.getIdsFromTopicToCurrent();
      const valueExpression = UnquotedPropertyUtils.generateVariablePropertyAccessChain('$msg', ids);

      this.dialogRef.close({
        source,
        valueExpression,
      } as DataPickerDialogResultData);
    }
  }
}
