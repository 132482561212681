import { parseScript } from 'esprima';
import { assign } from 'lodash';
import evaluate from 'static-eval';
import { $lib } from './expression-lib-functions';
import { EvalResponse } from './shared';

export class ExpressionEval {
  static evaluate(input: string, extraValues: { [name: string]: any } = {}, defaultValue = null): any {
    let result = defaultValue;

    const response = this.evaluateWithResponse(input, extraValues);

    if (response.evalSuccess) {
      result = response.evaluatedResponseObject;
    }

    return result;
  }

  static evaluateWithResponse(input: string, extraValues: { [name: string]: any } = {}): EvalResponse {
    let result;
    let evalSuccess = false;

    try {
      const esprimaParseResult = parseScript(input);
      if (esprimaParseResult?.body?.length) {
        const expression = (esprimaParseResult.body[0] as any).expression;

        // Create variables
        const vars = assign(
          {
            $lib,
            Math,
          },
          extraValues,
        );

        if (expression) {
          result = evaluate(expression, vars);
        }

        evalSuccess = true;
      }
    } catch (err) {
      evalSuccess = false;

      console.log('evaluation failed', { err: err.toString() }, input, extraValues);
    }

    const evalResponse: EvalResponse = new EvalResponse();
    evalResponse.evalSuccess = evalSuccess;
    evalResponse.evaluatedResponseObject = result;

    return evalResponse;
  }

  /**
   * Runs an eval on the string, optionally replaces a specific key string like '$msg'
   * with the actual object as part of the eval
   *
   * @param stringToEval The string with the $msg in it
   * @param replaceString Optional string to replace with object, e.g. '$msg'
   * @param replaceWithObject Optional  object used to replace the replaceString
   * @returns JSON object with $msg replaced
   */
  static evalObjectString(stringToEval: string, replaceString?: string, _replaceWithObject?: any): any {
    if (replaceString && stringToEval.indexOf(replaceString) !== -1) {
      stringToEval = stringToEval.split('$object').join('replaceWithObject');
    }
    // run the Eval
    let finalValue: any = null; // what the bound value should be changed to
    let evalSuccess = false;
    try {
      // eslint-disable-next-line no-eval
      finalValue = eval?.(`"use strict";(${stringToEval})`);
      evalSuccess = true;
    } catch {
      console.error('evaluation failed', stringToEval);
    }
    const evalResponse: EvalResponse = new EvalResponse();
    evalResponse.evalSuccess = evalSuccess;
    evalResponse.evaluatedResponseObject = finalValue;

    return evalResponse;
  }
}
