import { WidgetBase } from './widget-base';
import type { WidgetTableFormat } from './widget-table-format';

export class WidgetTable extends WidgetBase {
  tableFormat: WidgetTableFormat;

  public static fromModel(model: any): WidgetTable {
    const widget = new WidgetTable();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any) {
    super.readValuesFromModel(model);

    this.tableFormat = model.tableFormat;
  }
}
