import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * @description Display icons
 *              Some of the icons have multiple path thus making it tedious to display
 *              We leave that logic to this component
 */
@Component({
  selector: 'app-rc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input() public icon: string[];
  @Input() public color: string;
  @Input() public size: number = 24;
  @Input() public clickable: boolean = false;

  @Output() public clicked: EventEmitter<MouseEvent> = new EventEmitter();

  public iconClasses: string[] = [];
  public paths: string[] = [];
  public iconStyle: string = '';

  public ngOnChanges(_changes: SimpleChanges): void {
    this.iconClasses = [];
    if (this.icon instanceof Array) {
      this.paths = [];
      this.icon.forEach((a, i) => {
        if (!i) {
          return this.iconClasses.push(a);
        }
        this.paths.push(a);
        return undefined;
      });
    }

    if (this.clickable) {
      this.iconClasses.push('clickable');
    }

    if (this.color) {
      this.iconClasses.push('color');
    }

    // Bypass Angular safe styles to set --icon-color:
    const styles = { 'font-size': `${this.size}px` };
    if (this.color) {
      styles['color'] = this.color;
      styles['--icon-color'] = this.color;
    }
    this.iconStyle = Object.entries(styles)
      .map(([k, v]) => `${k}:${v}`)
      .join(';');
  }
}
