import { WidgetBase } from './widget-base';

export class WidgetWebComponent extends WidgetBase {
  public elementName: string;
  public config: Record<string, any>;

  public static fromModel(model: Partial<WidgetWebComponent>): WidgetWebComponent {
    const widget = new WidgetWebComponent();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: Partial<WidgetWebComponent>): void {
    super.readValuesFromModel(model);

    this.minItemCols = model.minItemCols ? model.minItemCols : 2;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;
    this.elementName = model.elementName;
    this.config = model.config;
  }
}
