import { Socket } from 'rete';

/**
 * @description Input / Output Definition from Rocos Node definition
 *              Which translate to Socket in rete-js terms
 */
export interface ISocketUnit {
  kind?: ESocketKind | string;
  name?: string;
  entry?: string;
  args?: any[];
  default?: any;
  blocking?: boolean;
  hidden?: boolean;
  hints?: {
    [key: string]: string;
  };
}

export interface ISockets {
  [key: string]: ISocketUnit;
}

// Different html input controls
export enum EControlType {
  Bool = 'bool',
  // eslint-disable-next-line id-blacklist
  Number = 'number',
  Text = 'text',
  Composite = 'composite',
  PoseXY_Y = 'poseXY_Y',
  SocketKindSelect = 'socketKindSelect',
}

// Object helper to retrieve category of specific socket kind
// all int, uint, float fall under 'number' category, thus have same css class, html input etc..
export const CTypeCategory = {
  bool: {
    color: '#fc5555', // $color-markup
    controlType: EControlType.Bool,
    name: 'bool',
    default: false,
  },

  // eslint-disable-next-line id-blacklist
  number: {
    color: '#3f48e9', // $color-blue
    controlType: EControlType.Number,
    name: 'number',
    default: 0,
  },

  // eslint-disable-next-line id-blacklist
  string: {
    color: '#ffcd02', // $color-yellow
    controlType: EControlType.Text,
    name: 'string',
    default: '',
  },
  composite: {
    color: '#e83e8c', // $color-pink
    controlType: EControlType.Composite,
    name: 'composite',
    default: null,
  },
  uri: {
    color: 'rgb(38, 107, 61)', // $color-green-dark
    controlType: EControlType.Text,
    name: 'uri',
    default: '',
  },
  map: {
    color: '#653fb2', // $color-indigo
    controlType: EControlType.Composite,
    name: 'map',
    default: null,
  },
  array: {
    color: '#fd9527', // $color-orange
    controlType: EControlType.Composite,
    name: 'array',
    default: null,
  },
  duration: {
    color: '#17a2b8', // $color-teal
    controlType: EControlType.Number,
    name: 'duration',
    default: '1s',
  },
  poseXY_Y: {
    color: '#06d8ff', // $color-mid-blue
    controlType: EControlType.PoseXY_Y,
    name: 'composite',
    default: null,
  },
  socketKindSelect: {
    color: '#ffffff', // $color-white
    controlType: EControlType.SocketKindSelect,
    name: 'select',
    default: '',
  },

  // eslint-disable-next-line id-blacklist
  any: {
    color: '#22bd66', // $color-green
    controlType: EControlType.Composite,
    name: 'any',
    default: null,
  },
};

// All different socket type available
export enum ESocketKind {
  Bool = 'bool',
  Int8 = 'int8',
  Int16 = 'int16',
  Int32 = 'int32',
  Int64 = 'int64',
  Uint8 = 'uint8',
  Uint16 = 'uint16',
  Uint32 = 'uint32',
  Uint64 = 'uint64',
  Float32 = 'float32',
  Float64 = 'float64',

  // eslint-disable-next-line id-blacklist
  String = 'string',
  ListInterface = '[]interface{}',
  ListString = '[]string',
  MapStringInterface = 'map[string]interface{}',
  MapStringString = 'map[string]string',
  TimeDuration = 'Duration',
  RocosUri = 'RocosURI',
  SharedTelemetryMessage = 'TelemetryMessage',
  ListSharedTelemetryMessage = '[]TelemetryMessage',
  SharedCommandResponse = 'CommandResponse',
  ListSharedCommandResponse = '[]CommandResponse',
  SharedCallableResponse = 'CallableResponse',
  ListSharedCallableResponse = '[]CallableResponse',
  SharedControlACK = 'ControlAck',
  ListSharedControlAck = '[]ControlAck',
  SharedPayloadValue = 'PayloadValue',
  WFTypesJSONString = 'JSONString',
  Interface = 'interface{}',
  Transform = 'Transform',
  ListTransform = '[]Transform',
  Marker = 'Marker',
  SocketKindSelect = 'socketKindSelect',
}

// Compatibility Matrix
// Also defined 'sub-groups' for each king e.g. int,uint,float all fall under sub-groups 'number'
// Ref: https://docs.google.com/spreadsheets/d/15AOMOEgFNUeQa74Y54rcm5q_rZ2c8aZIue-66MRjQSk/edit#gid=2018306120
export const CCompatibilityMatrix = {
  [ESocketKind.Bool]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.bool,
  },
  [ESocketKind.Int8]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Int16]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Int32]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Int64]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Uint8]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Uint16]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Uint32]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Uint64]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Float32]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.Float64]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.number,
  },
  [ESocketKind.String]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.RocosUri]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.string,
  },
  [ESocketKind.ListInterface]: {
    compat: {
      [ESocketKind.ListInterface]: true,
      [ESocketKind.String]: true,
      [ESocketKind.ListString]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.ListString]: {
    compat: {
      [ESocketKind.ListInterface]: true,
      [ESocketKind.String]: true,
      [ESocketKind.ListString]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.MapStringInterface]: {
    compat: {
      [ESocketKind.MapStringInterface]: true,
      [ESocketKind.String]: true,
      [ESocketKind.MapStringString]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.map,
  },
  [ESocketKind.MapStringString]: {
    compat: {
      [ESocketKind.MapStringInterface]: true,
      [ESocketKind.String]: true,
      [ESocketKind.MapStringString]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.map,
  },
  [ESocketKind.TimeDuration]: {
    compat: {
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.duration,
  },
  [ESocketKind.RocosUri]: {
    compat: {
      [ESocketKind.RocosUri]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.uri,
  },
  [ESocketKind.SharedTelemetryMessage]: {
    compat: {
      [ESocketKind.SharedTelemetryMessage]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.ListSharedTelemetryMessage]: {
    compat: {
      [ESocketKind.ListSharedTelemetryMessage]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.SharedCommandResponse]: {
    compat: {
      [ESocketKind.SharedCommandResponse]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.ListSharedCommandResponse]: {
    compat: {
      [ESocketKind.ListSharedCommandResponse]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.SharedCallableResponse]: {
    compat: {
      [ESocketKind.SharedCallableResponse]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.ListSharedCallableResponse]: {
    compat: {
      [ESocketKind.ListSharedCallableResponse]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.SharedControlACK]: {
    compat: {
      [ESocketKind.SharedControlACK]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.ListSharedControlAck]: {
    compat: {
      [ESocketKind.ListSharedControlAck]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.array,
  },
  [ESocketKind.SharedPayloadValue]: {
    compat: {
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.String]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.WFTypesJSONString]: {
    compat: {
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.ListString]: true,
      [ESocketKind.MapStringInterface]: true,
      [ESocketKind.MapStringString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.RocosUri]: true,
      [ESocketKind.SharedTelemetryMessage]: true,
      [ESocketKind.ListSharedTelemetryMessage]: true,
      [ESocketKind.SharedCommandResponse]: true,
      [ESocketKind.ListSharedCommandResponse]: true,
      [ESocketKind.SharedCallableResponse]: true,
      [ESocketKind.ListSharedCallableResponse]: true,
      [ESocketKind.SharedControlACK]: true,
      [ESocketKind.ListSharedControlAck]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.string,
  },
  [ESocketKind.Interface]: {
    compat: {
      [ESocketKind.Interface]: true,
      [ESocketKind.Bool]: true,
      [ESocketKind.Int8]: true,
      [ESocketKind.Int16]: true,
      [ESocketKind.Int32]: true,
      [ESocketKind.Int64]: true,
      [ESocketKind.Uint8]: true,
      [ESocketKind.Uint16]: true,
      [ESocketKind.Uint32]: true,
      [ESocketKind.Uint64]: true,
      [ESocketKind.Float32]: true,
      [ESocketKind.Float64]: true,
      [ESocketKind.String]: true,
      [ESocketKind.ListString]: true,
      [ESocketKind.MapStringInterface]: true,
      [ESocketKind.MapStringString]: true,
      [ESocketKind.TimeDuration]: true,
      [ESocketKind.RocosUri]: true,
      [ESocketKind.SharedTelemetryMessage]: true,
      [ESocketKind.ListSharedTelemetryMessage]: true,
      [ESocketKind.SharedCommandResponse]: true,
      [ESocketKind.ListSharedCommandResponse]: true,
      [ESocketKind.SharedCallableResponse]: true,
      [ESocketKind.ListSharedCallableResponse]: true,
      [ESocketKind.SharedControlACK]: true,
      [ESocketKind.ListSharedControlAck]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Marker]: true,
      [ESocketKind.Transform]: true,
      [ESocketKind.ListTransform]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.Transform]: {
    compat: {
      [ESocketKind.Transform]: true,
      [ESocketKind.String]: true,
      [ESocketKind.MapStringInterface]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.ListTransform]: {
    compat: {
      [ESocketKind.ListTransform]: true,
      [ESocketKind.String]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.Marker]: {
    compat: {
      [ESocketKind.Marker]: true,
      [ESocketKind.SharedPayloadValue]: true,
      [ESocketKind.WFTypesJSONString]: true,
      [ESocketKind.Interface]: true,
    },
    category: CTypeCategory.composite,
  },
  [ESocketKind.SocketKindSelect]: {
    compat: {
      [ESocketKind.SocketKindSelect]: true,
    },
    category: CTypeCategory.socketKindSelect,
  },
};

// All share the same socket
// We don't use the compatibility feature offered by the library as it would be too complex and costly to update
// We use the compatibiliy matrix defined above to determine whether two sockets are compatibile
export const CReferenceSockets = new Socket('any');
