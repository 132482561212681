import { Injectable } from '@angular/core';
import type { Robot, SceneManager } from '@shared/models';
import type { AbstractMesh, Nullable, Observer, PointerInfo, TransformNode, Vector3 } from '@babylonjs/core';
import { Mesh } from '@babylonjs/core';
import { BehaviorSubject } from 'rxjs';
import type { ObjectItemType } from 'src/app/operation/shared';
import { BabylonRendererService } from './babylon-renderer.service';
import { RendererModelService } from './renderer-model.service';
import type { IMesh } from './primitives/visualizer/interface/IMesh';

export type ObjectEditMode = 'move' | 'rotate' | 'scale' | undefined;
interface GizmoObservableTracker {
  moveX?: Observer<GizmoValue>;
  moveY?: Observer<GizmoValue>;
  moveZ?: Observer<GizmoValue>;
  rotateX?: Observer<GizmoValue>;
  rotateY?: Observer<GizmoValue>;
  rotateZ?: Observer<GizmoValue>;
  scaleX?: Observer<GizmoValue>;
  scaleY?: Observer<GizmoValue>;
  scaleZ?: Observer<GizmoValue>;
}

interface GizmoValue {
  delta: Vector3;
  dragPlanePoint: Vector3;
  dragPlaneNormal: Vector3;
  dragDistance: number;
  pointerId: number;
  pointerInfo: Nullable<PointerInfo>;
}

export interface ActiveObject {
  key: string;
  displayName: string;
  type: ObjectItemType;
  fullId?: string;
}

@Injectable()
export class RendererSelectionService {
  public objectEditMode: ObjectEditMode = undefined;

  private selectionMesh: Mesh;
  private rendererGizmoObTracker: GizmoObservableTracker = {};

  private _activeRobot$ = new BehaviorSubject<Robot>(undefined);
  public get activeRobot$(): BehaviorSubject<Robot> {
    return this._activeRobot$;
  }

  private _activeScript$ = new BehaviorSubject<string>(undefined);
  public get activeScript$(): BehaviorSubject<string> {
    return this._activeScript$;
  }

  private _activeObject$ = new BehaviorSubject<ActiveObject>(undefined);
  public get activeObject$(): BehaviorSubject<ActiveObject> {
    return this._activeObject$;
  }
  private get activeObject(): ActiveObject {
    return this._activeObject$.getValue();
  }

  private get sceneManager(): SceneManager {
    return this.rendererModelService.sceneManager;
  }

  private _showSystemObjects$ = new BehaviorSubject<boolean>(false);
  public get showSystemObjects$(): BehaviorSubject<boolean> {
    return this._showSystemObjects$;
  }

  private _editorMode$ = new BehaviorSubject<boolean>(false);
  public get editorMode$(): BehaviorSubject<boolean> {
    return this._editorMode$;
  }

  private get gizmoManager() {
    return this.babylonRenderer.rendererGizmoManager;
  }

  public constructor(
    public babylonRenderer: BabylonRendererService,
    public rendererModelService: RendererModelService,
  ) {}

  public setShowSystemObjects(mode: boolean) {
    this._showSystemObjects$.next(mode);
    this.clearHighlightedMesh();
    this.resetGizmoManager();
  }

  public setEditorMode(mode: boolean) {
    this._editorMode$.next(mode);

    if (mode) {
      this.clearHighlightedMesh();
      this.babylonRenderer.scene.shadowsEnabled = true;
      this.resetGizmoManager();
    }
  }

  public clearHighlightedMesh() {
    if (this.activeObject?.fullId) {
      const previousSelectedMesh = this.babylonRenderer.scene?.getMeshById(this.activeObject?.fullId);
      if (previousSelectedMesh) {
        const meshId = this.babylonRenderer.getBaseKey(previousSelectedMesh);
        this.selectionMesh?.dispose();
        const meshInfo = this.sceneManager.findMesh(meshId);
        if (meshInfo?.boundingBoxLoadedVisibility) {
          previousSelectedMesh.visibility = meshInfo.boundingBoxLoadedVisibility;
        }
      }
    }

    this._activeObject$.next(undefined);
    this.clearGizmoObservables();
    if (this.babylonRenderer.scene) this.babylonRenderer.scene.shadowsEnabled = true;
  }

  // Note: In a complex scene this is very costly! Use sparingly please.
  public refreshCurrentSelection() {
    if (this.activeObject) this.selectHighlightedMeshes(this.activeObject?.key, false);
  }

  public selectHighlightedMeshes(meshKey, attachGizmo: boolean) {
    this.clearHighlightedMesh();
    const mesh: AbstractMesh = this.babylonRenderer.getMesh(meshKey);
    const meshInfo = this.sceneManager.findMesh(meshKey);
    if (mesh) {
      this.babylonRenderer.scene.shadowsEnabled = false;
      if (meshInfo?.boundingBoxLoadedVisibility) mesh.visibility = 0.5;
      this.updateSelectionMesh(mesh);
      if (attachGizmo && this.gizmoManager) this.gizmoManager.attachToMesh(mesh);
    }

    this._activeObject$.next({
      key: meshKey,
      displayName: meshInfo.displayName || meshKey,
      type: 'mesh',
      fullId: mesh?.id,
    });
  }

  /** Sets both the scene list and the actual rendered object as active  */
  public setSceneActiveObject(objectKey: string, type: ObjectItemType) {
    this.unselectActiveRobot();
    this.unselectActiveScript();
    this.resetGizmoManager();
    this.objectEditMode = undefined;

    if (objectKey === this.activeObject?.key) {
      this.clearHighlightedMesh();
      return;
    }

    if (type === 'mesh') {
      this.selectHighlightedMeshes(objectKey, true);
    } else {
      this.clearHighlightedMesh();
      const meshInfo = this.sceneManager.findMesh(objectKey);
      this._activeObject$.next({
        key: objectKey,
        displayName: meshInfo?.displayName || objectKey,
        type,
      });
    }
  }

  public setObjectEditMode(mode: ObjectEditMode) {
    // if we are already in the mode, disable it
    if (this.objectEditMode === mode) {
      this.refreshCurrentSelection();
      return;
    }

    this.resetGizmoManager();
    this.objectEditMode = mode;
    if (!this.gizmoManager) return;

    const babylonMesh =
      this.babylonRenderer.getMesh(this.activeObject.key) ||
      this.babylonRenderer.getTransformNode(this.activeObject.key);
    const sceneMeshId = this.activeObject?.key;
    const sceneMesh = this.sceneManager.findMesh(sceneMeshId);
    if (!sceneMesh || !babylonMesh) return;

    this.selectionMesh?.dispose();
    babylonMesh.computeWorldMatrix(true);

    switch (mode) {
      case 'move': {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.gizmos.positionGizmo.snapDistance = 0.1;
        if (!sceneMesh?.position) sceneMesh.position = babylonMesh.position.asArray();

        const positionGizmo = this.gizmoManager.gizmos.positionGizmo;
        const cb = () => this.updateVector(sceneMesh, 'position', babylonMesh.position);
        this.rendererGizmoObTracker['moveX'] = positionGizmo.xGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['moveY'] = positionGizmo.yGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['moveZ'] = positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => cb());
        break;
      }
      case 'rotate': {
        this.gizmoManager.rotationGizmoEnabled = true;
        this.gizmoManager.gizmos.rotationGizmo.snapDistance = Math.PI / 16;
        this.gizmoManager.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
        if (!sceneMesh?.rotation) sceneMesh.rotation = babylonMesh.rotation.asArray();

        const cb = () => this.updateRotation(babylonMesh, sceneMesh);
        const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo;
        this.rendererGizmoObTracker['rotateX'] = rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['rotateY'] = rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['rotateZ'] = rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(() => cb());
        break;
      }
      case 'scale': {
        this.gizmoManager.scaleGizmoEnabled = true;
        this.gizmoManager.gizmos.scaleGizmo.snapDistance = 0.1;
        if (!sceneMesh?.scaling) sceneMesh.scaling = babylonMesh.scaling.asArray();
        const scaleGizmo = this.gizmoManager.gizmos.scaleGizmo;
        const cb = () => this.updateVector(sceneMesh, 'scaling', babylonMesh.scaling);
        this.rendererGizmoObTracker['scaleX'] = scaleGizmo.xGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['scaleY'] = scaleGizmo.yGizmo.dragBehavior.onDragObservable.add(() => cb());
        this.rendererGizmoObTracker['scaleZ'] = scaleGizmo.zGizmo.dragBehavior.onDragObservable.add(() => cb());
        break;
      }
    }
  }

  public resetGizmoManager() {
    this.objectEditMode = undefined;
    if (!this.gizmoManager) return;
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
  }

  public cleanUp() {
    this.clearHighlightedMesh();
    this.unselectActiveRobot();
    this.unselectActiveScript();
    this.clearGizmoObservables();
    this._showSystemObjects$.next(false);
    this._editorMode$.next(false);

    if (!this.gizmoManager) return;
    try {
      this.gizmoManager.dispose();
    } catch (e) {
      // This is a workaround to deal with a strange @babylonjs/core lib crash issue.
    }
  }

  // SCRIPT SELECTION

  public setActiveScript(scriptId?: string): void {
    const activeScript = this._activeScript$.getValue();
    if (activeScript === scriptId) {
      this._activeScript$.next(null);
    } else {
      this._activeScript$.next(scriptId);
    }

    this.clearHighlightedMesh();
    this.unselectActiveRobot();
  }

  public unselectActiveScript() {
    this._activeScript$.next(null);
  }

  // ROBOT SELECTION

  public setActiveRobot(robot: Robot): void {
    const activeRobot = this._activeRobot$.getValue();
    if (activeRobot === robot) return;
    if (activeRobot) activeRobot.selected = false;
    robot.selected = true;
    this._activeRobot$.next(robot);

    this.clearHighlightedMesh();
    this.unselectActiveScript();
  }

  public unselectActiveRobot() {
    const activeRobot = this._activeRobot$.getValue();
    if (activeRobot) activeRobot.selected = false;
    this._activeRobot$.next(null);
  }

  public isActiveRobotInsideRobotsList(callsigns: string[]): boolean {
    const activeRobot = this._activeRobot$.getValue();
    const filtered = callsigns.filter((callsign) => {
      return activeRobot?.callsign === callsign;
    });

    return !!filtered?.length;
  }

  private updateVector(sceneMesh: IMesh, property: 'position' | 'scaling', value: Vector3) {
    sceneMesh[property] = value.asArray();
    this.babylonRenderer.changeStatus$.next(true);
  }

  private updateRotation(babylonMesh: AbstractMesh | TransformNode, sceneMesh: IMesh) {
    if (babylonMesh.rotationQuaternion) {
      const quaternion = babylonMesh.rotationQuaternion.clone();
      sceneMesh.rotation = quaternion.toEulerAngles().asArray();
    } else {
      sceneMesh.rotation = babylonMesh.rotation.asArray();
    }
    this.babylonRenderer.changeStatus$.next(true);
  }

  private clearGizmoObservables() {
    this.resetGizmoManager();
    const tracker = this.rendererGizmoObTracker;

    if (!this.gizmoManager) return;
    const posGiz = this.gizmoManager.gizmos.positionGizmo;
    const rotGiz = this.gizmoManager.gizmos.rotationGizmo;
    const scaleGiz = this.gizmoManager.gizmos.scaleGizmo;

    if (posGiz) {
      posGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['moveX']);
      posGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['moveY']);
      posGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['moveZ']);
    }

    if (rotGiz) {
      rotGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['rotateX']);
      rotGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['rotateY']);
      rotGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['rotateZ']);
    }

    if (scaleGiz) {
      scaleGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['scaleX']);
      scaleGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['scaleY']);
      scaleGiz.xGizmo.dragBehavior.onDragObservable.remove(tracker['scaleZ']);
    }
    this.rendererGizmoObTracker = {};
  }

  // BOUNDING BOX

  private updateSelectionMesh(mesh: AbstractMesh): void {
    if (!mesh) return;
    const boundingBox = this.babylonRenderer.getBoundingBoxExcludingEmptyParent(mesh);
    this.selectionMesh = new Mesh('selection', mesh.getScene());
    this.selectionMesh.setBoundingInfo(boundingBox);
    this.selectionMesh.showBoundingBox = true;
  }
}
