<div class="rs-dialog large">
  <h2 class="title" mat-dialog-title>
    <span> Invitations - Last 7 Days </span>
    <span class="right-buttons">
      <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
        <span class="ri-cancel icon-button grey-icon"></span>
      </button>
    </span>
  </h2>

  <app-loading-content [isLoading]="isLoading">
    <div class="invitations-lists mb-3" *ngFor="let invitation of invitationsReceived">
      <div class="invitation-item">
        <div class="invitation-item-info">
          <div class="invitation-item-info-name">
            <div class="initials-cell">
              <app-user-initials [user]="invitation.sentFromUser"></app-user-initials>
            </div>
            <div class="invitation-item-info-name-and-email">
              <div class="invitation-item-info-name-fullname">{{ invitation.sentFrom }}</div>
              <div class="invitation-item-info-name-email">{{ invitation.sentFrom }}</div>
            </div>
          </div>

          <div class="invitation-item-info-button">
            <span *ngIf="invitation.accepted">
              <button mat-flat-button color="primary" (click)="onViewLinkClick(invitation)">
                <ng-container [ngSwitch]="invitation.type">
                  <ng-container *ngSwitchCase="'ACCOUNT'">
                    <span>View Account</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'PROJECT'">
                    <span>View Project</span>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <span>View</span>
                  </ng-container>
                </ng-container>
              </button>
            </span>
            <span *ngIf="!invitation.accepted && !isExpired(invitation)">
              <app-loading-stroked-button
                [fullWidth]="true"
                [isLoading]="invitation.isLoading"
                (clicked)="onAccept(invitation)"
              >
                <span>Accept Invite</span>
              </app-loading-stroked-button>
            </span>
            <span *ngIf="!invitation.accepted && isExpired(invitation)">
              <button mat-flat-button disabled>Expired</button>
            </span>
          </div>
        </div>

        <div class="invitation-item-desc">
          <div class="invitation-item-desc-project">
            <ng-container [ngSwitch]="invitation.type">
              <!-- ACCOUNT -->
              <ng-container *ngSwitchCase="'ACCOUNT'">
                <ng-container *ngIf="invitation.defaultAccount?.id">
                  <div *ngIf="invitation.accepted">
                    Invited you to the
                    <a [routerLink]="[]" (click)="onViewLinkClick(invitation)">{{ invitation.defaultAccount.name }}</a>
                    account.
                  </div>
                  <div *ngIf="!invitation.accepted && !isExpired(invitation)">
                    Invited you to the
                    <a [routerLink]="[]" (click)="onAccept(invitation)">{{ invitation.defaultAccount.name }}</a>
                    account.
                  </div>
                  <div *ngIf="!invitation.accepted && isExpired(invitation)">
                    Invited you to the {{ invitation.defaultAccount.name }} account.
                  </div>
                </ng-container>
              </ng-container>

              <!-- PROJECT -->
              <ng-container *ngSwitchCase="'PROJECT'">
                <ng-container *ngIf="invitation.defaultAccount?.project?.name">
                  <div *ngIf="invitation.accepted">
                    Invited you to the
                    <a [routerLink]="[]" (click)="onViewLinkClick(invitation)">{{
                      invitation.defaultAccount.project.name
                    }}</a>
                    project.
                  </div>
                  <div *ngIf="!invitation.accepted && !isExpired(invitation)">
                    Invited you to the
                    <a [routerLink]="[]" (click)="onAccept(invitation)">{{ invitation.defaultAccount.project.name }}</a>
                    project.
                  </div>
                  <div *ngIf="!invitation.accepted && isExpired(invitation)">
                    Invited you to the {{ invitation.defaultAccount.project.name }} project.
                  </div>
                </ng-container>
              </ng-container>

              <!-- DEFAULT -->
              <ng-container *ngSwitchDefault>
                <!-- NONE -->
              </ng-container>
            </ng-container>
          </div>
          <div class="invitation-item-desc-date">
            {{ invitation.createdAt | date : 'd MMM' }} at {{ invitation.createdAt | date : 'HH:mm' }}
          </div>
        </div>
      </div>
    </div>
  </app-loading-content>
</div>
