import { WidgetBase } from './widget-base';

export type OpsPageType = 'global' | 'local';

export class WidgetOps extends WidgetBase {
  public pageId?: string;
  public pageType?: OpsPageType;
  public camera?: string;

  public static fromModel(model: any): WidgetOps {
    const widget = new WidgetOps();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: any): void {
    super.readValuesFromModel(model);

    this.minItemCols = model.minItemCols ? model.minItemCols : 16;
    this.minItemRows = model.minItemRows ? model.minItemRows : 12;

    // Ops
    if (model.pageId) {
      this.pageId = model.pageId;
    }
    if (model.pageType) {
      this.pageType = model.pageType;
    }

    if (model.camera) {
      this.camera = model.camera;
    }
  }
}
