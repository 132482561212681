import type { Feature, FeatureCollection, LineString } from 'geojson';

// http://help.arcgis.com/en/geodatabase/10.0/sdk/arcsde/concepts/geometry/shapes/types.htm

// BASIC for now:
// point: single lat, lng coordinate
// polygon: 2 dimensional surface (a filled area)
// lineString: multiple lat,lng coordinates joined together

// ADVANCED for later:
// multiPoint
// multiLineString: collection of linestrings
// multiPolygon: boolean between multiple polygons
export class GeometryCoordinate {
  public x?: number;
  public y?: number;
  public z?: number;
  public m?: number;

  // these aren't technicaly part of the GIS standards but easy to get going
  public latitude?: number;
  public longitude?: number;
  public altitude?: number;
  public description?: string;
  public frame?: 'global' | 'local' = 'global';

  static fromModel(model: any): GeometryCoordinate {
    const coord = new GeometryCoordinate();
    coord.x = model.x;
    coord.y = model.y;
    coord.z = model.z;
    coord.m = model.m;
    coord.latitude = model.latitude;
    coord.longitude = model.longitude;
    coord.altitude = model.altitude;
    coord.description = model.description;
    coord.frame = model.frame;
    return coord;
  }
}

/// this is not in ArcGIS specs
enum GeometryShapeType {
  Point,

  MultiPoint,

  ShapeLineString,
}
/// this is not in ArcGIS specs

export class GeometryShape {
  public type: GeometryShapeType;
  public name: string;
  public description: string;
}

export class GeometryShapePoint extends GeometryShape {
  private _coordinate: GeometryCoordinate;

  get GeometryPoint(): GeometryCoordinate {
    return this._coordinate;
  }

  constructor(latitude, longitude, altitude?) {
    super();
    this.type = GeometryShapeType.Point;
    this._coordinate = new GeometryCoordinate();
    this._coordinate.latitude = latitude;
    this._coordinate.longitude = longitude;
    if (altitude !== undefined) {
      this._coordinate.altitude = altitude;
    }
  }
}

export class GeometryShapeMultiPoint extends GeometryShape {
  private _coordinates: GeometryCoordinate[];

  constructor() {
    super();
    this.type = GeometryShapeType.MultiPoint;
    this._coordinates = [];
  }

  public addGeoPoint(latitude: number, longitude: number, altitude?: number, description?: string) {
    const newCoord = new GeometryCoordinate();
    newCoord.latitude = latitude;
    newCoord.longitude = longitude;

    if (altitude != null) {
      newCoord.altitude = altitude;
    }

    if (description != null) {
      newCoord.description = description;
    }

    this._coordinates.push(newCoord);
  }

  public toGeoJSONMultiPoint() {
    const geoJSONFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    } as FeatureCollection;

    // assign each point in the linestring to the first feature
    let i = 1;
    this._coordinates.forEach((coord) => {
      let title = i.toString();
      if (coord.description !== undefined && coord.description != null) {
        title = coord.description;
      }

      const geoJSONFeature: Feature<GeoJSON.Point> = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coord.longitude, coord.latitude, 1000],
        },
        properties: {
          title,
          icon: 'marker',
          'marker-color': '#3bb2d0',
          'marker-size': 'large',
          'marker-symbol': 'rocket',
        },
      };

      geoJSONFeatureCollection.features.push(geoJSONFeature);
      i++;
    });
    return geoJSONFeatureCollection;
  }
}

export class GeometryShapeLineString extends GeometryShape {
  private _points: GeometryShapePoint[] = [];

  constructor() {
    super();
    this.type = GeometryShapeType.ShapeLineString;
  }

  get GeometryShapeLineString(): GeometryShapePoint[] {
    return this._points;
  }

  public addGeoPoint(latitude: number, longitude: number, altitude?: number) {
    let newPoint;

    if (altitude !== undefined) {
      newPoint = new GeometryShapePoint(latitude, longitude, altitude);
    } else {
      newPoint = new GeometryShapePoint(latitude, longitude);
    }

    this._points.push(newPoint);
  }

  public addGeoPointWithProperties(
    name: string,
    description: string,
    latitude: number,
    longitude: number,
    altitude?: number,
  ) {
    let newPoint;

    if (altitude !== undefined) {
      newPoint = new GeometryShapePoint(latitude, longitude, altitude);
    } else {
      newPoint = new GeometryShapePoint(latitude, longitude);
    }

    newPoint.name = name;
    newPoint.description = description;

    this._points.push(newPoint);
  }

  /**
   * @param includePoints: if the FeatureCollection should include a Point for each coordinate
   */
  public getGeoJSONLineString(includePoints?: boolean): FeatureCollection {
    const geoJSONFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    } as FeatureCollection;

    const geoJSONFeature: Feature<GeoJSON.LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    };

    // assign first empty feature
    geoJSONFeatureCollection.features.push(geoJSONFeature);

    // assign each point in the linestring to the first feature
    this._points.forEach((point) => {
      const coord: number[] = [];
      coord.push(point.GeometryPoint.longitude);
      coord.push(point.GeometryPoint.latitude);

      if (point.GeometryPoint.altitude !== undefined && point.GeometryPoint.altitude != null) {
        coord.push(point.GeometryPoint.altitude);
      }
      (geoJSONFeatureCollection.features[0].geometry as LineString).coordinates.push(coord);

      if (includePoints) {
        const geoJSONPoint: Feature<GeoJSON.Point> = {
          type: 'Feature',
          properties: {
            name: point.name,
            description: point.description,
          },
          geometry: {
            type: 'Point',
            coordinates: coord,
          },
        };
        geoJSONFeatureCollection.features.push(geoJSONPoint);
      }
    });

    return geoJSONFeatureCollection;
  }

  /** Adds the waypoints from a tab delimeted CSV waypoint file
   *  https://mavlink.io/en/services/mission.html
   */
  public addFromWaypointFile(waypointFileString) {
    // TODO: Move this out to a MAVLink specific library
    /*
        QGC WPL <VERSION>
        <INDEX> <CURRENT WP> <COORD FRAME> <COMMAND> <PARAM1> <PARAM2> <PARAM3> <PARAM4>
        <PARAM5/X/LONGITUDE> <PARAM6/Y/LATITUDE> <PARAM7/Z/ALTITUDE> <AUTOCONTINUE>
    */
    // let allTextLines = waypointFileString.split(/\r|\n|\r/);
    const allTextLines = waypointFileString.split(/\r\n/);

    // let headers = allTextLines[1].split(/\t/);
    const numHeaders = 12;

    // first row is file definition like 'QGC WPL 110', it is not the header

    for (let i = 1; i < allTextLines.length; i++) {
      // for every line other than the first

      // split content based on comma
      const data = allTextLines[i].split(/\t/);

      if (data.length === numHeaders) {
        const rowData = [];
        for (let j = 0; j < numHeaders; j++) {
          rowData.push(data[j]);
        }
        // map to a waypoint format
        const sequence = rowData[0];
        const cmd = parseInt(rowData[3], 10);
        const latitude = rowData[8];
        const longitude = rowData[9];
        let altitude = rowData[10];

        if (i === 1) {
          // TODO: May need to fix how first altitude is set to
          // relative ground leve, assume just at ground level for now
          altitude = 0;
        }

        if (cmd === 16) {
          // command 16 is waypoint
          this.addGeoPointWithProperties(
            sequence,
            'Waypoint',
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(altitude),
          );
        } else if (cmd === 82) {
          // command 82 is Spline Waypoint
          this.addGeoPointWithProperties(
            sequence,
            'Spline Waypoint',
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(altitude),
          );
        } else if (cmd === 22) {
          const lastPoint: GeometryCoordinate = this.endpoint.GeometryPoint;
          // it's a takeoff command, use the previous coordinate for lat and lng but uses altitude for height
          this.addGeoPointWithProperties(
            sequence,
            'Takeoff',
            lastPoint.latitude,
            lastPoint.longitude,
            parseFloat(altitude),
          );
        }
      }
    }
  }

  public get startpoint(): GeometryShapePoint {
    if (this.numPoints > 0) {
      return this._points[0];
    } else {
      return null;
    }
  }

  public get endpoint(): GeometryShapePoint {
    if (this.numPoints > 0) {
      return this._points[this.numPoints - 1];
    } else {
      return null;
    }
  }

  /** Takes a linestring and returns its length as a double-precision number */
  get length(): number {
    // TODO: calculate length (assume GIS means distance in meters)
    // console.error('GeometryShapeLineString.length not implimented yet');
    return null;
  }

  /** Slices the line down to just the points between the start and end points range */
  public splice(startPointNum: number, deleteCount: number) {
    this._points.splice(startPointNum, deleteCount);
  }

  /** Takes a linestring and returns the number of points in its sequence as an integer */
  get numPoints(): number {
    return this._points.length;
  }

  /// should impliment these:
  // startpoint—Takes a linestring and returns its first point
  // endpoint—Takes a linestring and returns its last point
  // pointn—Takes a linestring and an index to nth point and returns that point
  // length—Takes a linestring and returns its length as a double-precision number
  // numpoints—Takes a linestring and returns the number of points in its sequence as an integer
  // isring—Takes a linestring and returns t (TRUE) if the linestring is a ring and f (FALSE) otherwise
  // isclosed—Takes a linestring and returns t (TRUE) if the linestring is closed and f (FALSE) otherwise
}
