<app-loading-content [isLoading]="isLoading">
  <div class="box">
    <div class="header">
      <div class="left">
        <div class="icon">
          <span [ngClass]="sourceInfo.iconClass"></span>
        </div>
        <div class="info">
          <div class="prefix">
            <span>{{ sourceInfo.prefix }}</span>
          </div>
          <div class="title">
            <span>{{ sourceInfo.id }}</span>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="operations" *ngIf="currentPreviewMethod?.name === 'table'">
          <div class="log-filter">
            <form [formGroup]="filterForm" class="">
              <ng-container *ngIf="logFilters && logFilters.length > 1">
                <button
                  type="button"
                  class="right-icon-button compact-icon"
                  mat-stroked-button
                  color="primary"
                  [matMenuTriggerFor]="menuLogFilters"
                  tabindex="-1"
                >
                  <span class="ri-table-settings"></span><span class="ri-nav-down"></span>
                </button>
              </ng-container>

              <div formArrayName="filters" class="">
                <mat-menu #menuLogFilters="matMenu" class="filter-menu">
                  <ng-container
                    *ngFor="let filter of formArrayFilters.controls; let i = index"
                    [formGroupName]="i"
                    class=""
                  >
                    <div mat-menu-item class="filter-item">
                      <mat-checkbox class="" formControlName="checkedStatus" (change)="onFilterChanged()">{{
                        filter.value.label
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </form>
          </div>
        </div>
        <ng-container *ngIf="previewMethods && previewMethods.length > 1">
          <button
            type="button"
            class="right-icon-button"
            mat-stroked-button
            color="primary"
            [matMenuTriggerFor]="menuPreviewMethod"
            tabindex="-1"
          >
            <span>{{ currentPreviewMethod?.text }}</span>
            <span class="ri-nav-down"></span>
          </button>
        </ng-container>

        <button
          type="button"
          class="left-icon-button"
          mat-stroked-button
          color="primary"
          matTooltip="Insert Data URI"
          (click)="onInsertDataURI()"
          *ngIf="sourceInfo.isInsertable && insertDataURIMode"
        >
          <span class="icon ri-copy-link"></span>
          <span> Insert </span>
        </button>

        <div *ngIf="!slowlaneMode" class="more">
          <button mat-icon-button matTooltip="more" [matMenuTriggerFor]="moreInfoMenu" tabindex="-1">
            <span class="ri-more icon-button grey-icon"></span>
          </button>
          <mat-menu #moreInfoMenu="matMenu">
            <button mat-menu-item (click)="sourceInfo.showStreamQuery = !sourceInfo.showStreamQuery">
              <ng-container *ngIf="sourceInfo.showStreamQuery">
                <span class="label"> Edit Stream Query </span>
                <span class="icon">
                  <span class="ri-hidden"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="!sourceInfo.showStreamQuery">
                <span class="label"> Edit Stream Query </span>
                <span class="icon">
                  <span class="ri-view"></span>
                </span>
              </ng-container>
            </button>
            <button mat-menu-item (click)="sourceInfo.showPerfMon = !sourceInfo.showPerfMon">
              <ng-container *ngIf="sourceInfo.showPerfMon">
                <span class="label"> Performance Monitor </span>
                <span class="icon">
                  <span class="ri-hidden"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="!sourceInfo.showPerfMon">
                <span class="label"> Performance Monitor </span>
                <span class="icon">
                  <span class="ri-view"></span>
                </span>
              </ng-container>
            </button>
          </mat-menu>
        </div>

        <!-- Menu -->

        <mat-menu #menuPreviewMethod="matMenu">
          <button mat-menu-item *ngFor="let method of previewMethods" (click)="onSelectPreviewMethod(method)">
            <span class="ri-checkmark check-mark" [class.show]="method.name === currentPreviewMethod.name"></span>
            <span class="label">
              {{ method.text }}
              <!-- Right Padding -->
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div *ngIf="sourceInfo.showStreamQuery">
      <app-data-custom-query
        [callsign]="callsign"
        [source]="sourceInfo.source"
        [path]="sourceInfo.path"
        [dataType]="sourceInfo.dataType"
        [querystring]="sourceInfo.querystring"
      >
      </app-data-custom-query>
    </div>

    <div *ngIf="sourceInfo.showPerfMon">
      <app-data-performance
        [callsign]="callsign"
        [path]="sourceInfo.path"
        [payload]="output"
        [latestMessageReceivedAt]="latestMessageReceivedAt"
      >
      </app-data-performance>
    </div>

    <div>
      <ng-container [ngSwitch]="currentPreviewMethod.name">
        <ng-container *ngSwitchCase="'table'">
          <div class="table-container" #logTableContainer>
            <table mat-table [dataSource]="dataSource" class="logTable" #logTable>
              <ng-container *ngFor="let column of displayedColumns; let idx = index">
                <ng-container [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ displayedColumnsNames[column] }}
                    <div class="cell-bottom-border"></div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div [class]="getLevelClass(element, column)" [class.level]="column === 'level'">
                      <div>{{ element[column] }}</div>
                    </div>
                    <div class="cell-bottom-border"></div>
                    <div class="cell-bottom-time">{{ element.time / 1000000 | date : 'medium' }}</div>
                  </td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" class="my-mat-row"></tr>
            </table>
          </div>

          <!-- Load More Button -->
          <div *ngIf="slowlaneMode && pageFrom + pageSize < totalCount" class="load-more">
            <app-loading-button [loading]="isLoadingMore" (click)="onLoadMore()">
              <span>Load More</span>
            </app-loading-button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'json'">
          <div class="pre-container" #preContainer>
            <pre class="preview"> {{ output }}</pre>
          </div>
          <!-- Load More Button -->
          <div *ngIf="slowlaneMode && pageFrom + pageSize < totalCount" class="load-more">
            <app-loading-button [loading]="isLoadingMore" (click)="onLoadMore()">
              <span>Load More</span>
            </app-loading-button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'lineGraph'">
          <app-preview-line-graph #lineGraphComponent></app-preview-line-graph>
        </ng-container>
      </ng-container>
    </div>
  </div>
</app-loading-content>
