import type { VideoSource, VideoSourceStatus } from './video-source';
import { Subject } from 'rxjs';
import type { ElementRef } from '@angular/core';

export class FileVideoSource implements VideoSource {
  videoServerSuccess: Subject<boolean> = new Subject<boolean>();
  videoSourceStatus: Subject<VideoSourceStatus> = new Subject<VideoSourceStatus>();
  videoServer: string = null;
  isVideoStreaming: Subject<boolean> = new Subject<boolean>();
  isVideoConnecting: Subject<boolean> = new Subject<boolean>();
  videoId: string;
  playoutDelayHint: string;
  stream: Subject<MediaStream> = new Subject<MediaStream>();
  videoCommand: string = '';
  allowDebug: false;
  remoteVideoElement: ElementRef<HTMLVideoElement>;
  message: string;

  peerConnection: RTCPeerConnection = null;
  timer: number;

  private _videoSourceStatus = {
    webrtcPeerConnectionStatus: false,
    webrtcIceStateConnected: false,
    webrtcIceConnectionState: null,
    webrtcStreamStarted: false,
    webrtcBitrate: null,
    realInterval: null,
    webrtcAudioIn: null,
    webrtcVideoIn: null,
    webrtcAudioOut: null,
    webrtcVideoOut: null,
    webrtcSlow: null,
    webrtcRoundTripTimeMs: null,
    webrtcPacketLossPercent: null,
    webrtcFramesDropped: null,
    webrtcFramesDecoded: null,
    webrtcFramesReceived: null,
    jitterBufferDelay: null,
    jitterBufferEmittedCount: null,
    avgFramePlayout: null,
  };
  private _videoStreaming: boolean = false;
  private _videoConnecting: boolean = false;

  constructor() {
    this.resetVideoSourceStatus();
  }

  startStream(): void {
    this.remoteVideoElement.nativeElement.src = this.videoServer;
    this.remoteVideoElement.nativeElement.loop = true;
    this.updateVideoStreaming(true);
    this.updateVideoConnecting(false);
  }

  setStatusUpdateFrequency(_interval): void {
    /** noop */
  }

  initConnection(videoServer: string): void {
    this.videoServer = videoServer;
    this.videoServerSuccess.next(true);
  }
  stopStream(): void {
    this.updateVideoStreaming(false);
    this.updateVideoConnecting(false);

    if (this.remoteVideoElement) {
      this.remoteVideoElement.nativeElement.loop = false;
      this.remoteVideoElement.nativeElement.src = '';
    }
  }
  dispose(): void {
    this.stopStream();
  }

  private resetVideoSourceStatus() {
    this._videoSourceStatus = {
      webrtcPeerConnectionStatus: false,
      webrtcIceStateConnected: false,
      webrtcIceConnectionState: null,
      webrtcStreamStarted: false,
      webrtcBitrate: null,
      realInterval: null,
      webrtcAudioIn: null,
      webrtcVideoIn: null,
      webrtcAudioOut: null,
      webrtcVideoOut: null,
      webrtcSlow: null,
      webrtcRoundTripTimeMs: null,
      webrtcPacketLossPercent: null,
      webrtcFramesDropped: null,
      webrtcFramesDecoded: null,
      webrtcFramesReceived: null,
      jitterBufferDelay: null,
      jitterBufferEmittedCount: null,
      avgFramePlayout: null,
    };
  }

  private updateVideoStreaming(streaming?: boolean) {
    if (streaming !== undefined) {
      this._videoStreaming = streaming;
    }

    this.isVideoStreaming.next(this._videoStreaming);
  }

  private updateVideoConnecting(connecting?: boolean) {
    if (connecting !== undefined) {
      this._videoConnecting = connecting;
    }

    this.isVideoConnecting.next(this._videoConnecting);
  }
}
