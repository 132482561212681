import { Component } from '@angular/core';
import { LoadingBaseButtonComponent } from '../loading-base-button';

@Component({
  selector: 'app-loading-flat-button',
  templateUrl: './loading-flat-button.component.html',
  styleUrls: ['../shared-styles.scss', './loading-flat-button.component.scss'],
})
export class LoadingFlatButtonComponent extends LoadingBaseButtonComponent {
  constructor() {
    super();
  }
}
