import { Injectable } from '@angular/core';
import { AppService, AuthService } from '@shared/services';
import type {
  IRocosCallerMessage,
  IRocosCallerMessageResponseAck,
  IRocosCallerMessageResponseResult,
  IRocosCallerMessageResponseUid,
} from '@dronedeploy/rocos-js-sdk';
import { RocosSDK } from '@dronedeploy/rocos-js-sdk';
import { environment } from '@env/environment';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';

export interface ServiceResult {
  uid?: IRocosCallerMessageResponseUid;
  payload?: unknown;
  ack?: IRocosCallerMessageResponseAck;
  createdTime?: number;
  result?: IRocosCallerMessageResponseResult;
}

@Injectable({
  providedIn: 'root',
})
export class RocosSdkClientService {
  public client: RocosSDK;

  constructor(private authService: AuthService, private appService: AppService) {
    this.login();

    this.authService.userUpdated.subscribe((user) => {
      this.login(user.token);
    });

    this.authService.token$.pipe(filter(Boolean), distinctUntilChanged()).subscribe((token) => {
      this.client.getAuthService().setToken(token);
    });

    this.authService.loggedOut.subscribe(() => {
      this.client.cleanup();
    });
  }

  // TODO: GRP-1509 move to SDK
  public callServiceAndReturnResponse<T = unknown>(
    path: string,
    payload?: unknown,
    callsign?: string,
    projectId?: string,
    uid?: string,
    query?: Record<string, string[]>,
  ) {
    return this.callService(path, payload, callsign, projectId, uid, query).pipe(
      map((res) => res?.[0]?.payload as T),
      filter((res) => res !== undefined),
      first(),
    );
  }

  // TODO: GRP-1509 move to SDK
  public callService(
    path: string,
    payload?: unknown,
    callsign?: string,
    projectId?: string,
    uid?: string,
    query?: Record<string, string[]>,
  ) {
    const pathArray = path.split('/');
    const component = pathArray[1];
    const topic = pathArray.slice(2).join('/');
    const requestPayload = JSON.stringify(payload);

    uid ??= crypto.randomUUID();
    callsign ??= this.appService.callsign;
    projectId ??= this.appService.projectId;

    return this.client
      .getCallerService()
      .invokeRequest({
        projectId,
        callsign,
        component,
        topic,
        uid,
        query,
        payload: requestPayload,
        subSystem: '',
        responseLevelNumber: 0,
      })
      .pipe(
        map((x) => this.getResultFromServiceCallerResponse(x)),
        map((res) => {
          const hasError = !!res?.length && !!res.find((r) => r.result && r.result.status > 10);
          if (hasError) {
            throw new Error(res[0].result?.message);
          }
          return res;
        }),
      );
  }

  private getResultFromServiceCallerResponse(res: IRocosCallerMessage): ServiceResult[] {
    if (res.responses?.responses) {
      return this.getResultFromServiceCallerResponseInternal(res);
    }

    if (res.chunks?.chunks) {
      // chunking unsupported yet
      console.error('response was chunked but there is no implementation for chunking');
    }

    return null;
  }

  private getResultFromServiceCallerResponseInternal(res: IRocosCallerMessage) {
    if (!res.responses?.responses?.length) return undefined;

    const results: ServiceResult[] = [];

    res.responses.responses.forEach((rl) => {
      const result: ServiceResult = {};

      if (rl.uid) {
        result.uid = rl.uid;
      }

      if (rl?.return?.payload) {
        const payloadText = new TextDecoder().decode(rl?.return?.payload);
        result.payload = JSON.parse(payloadText);

        if (rl?.return.header?.createdAt) {
          result.createdTime = rl?.return.header?.createdAt.getTime();
        }
      }

      if (rl.ack) {
        result.ack = rl.ack;
      }

      if (rl.result) {
        result.result = rl.result;
      }

      results.push(result);
    });

    return results;
  }

  private login(token?: string) {
    this.client = new RocosSDK({
      url: new URL(environment.api.url).host,
      token: token ?? this.authService.userInfo?.token,
    });
  }
}
