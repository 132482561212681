import { InjectionToken } from '@angular/core';
import type { Monaco } from './types';

export const NGX_MONACO_EDITOR_CONFIG = new InjectionToken('NGX_MONACO_EDITOR_CONFIG');

export interface NgxMonacoEditorConfig {
  baseUrl?: string;
  defaultOptions?: { [key: string]: any };
  onMonacoLoad?: (monaco: Monaco) => void;
}
