import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../local-storage';

interface DashboardScaleType {
  scale: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardScaleService {
  private static persistenceKey = 'dashboard-scale';
  private settings$: BehaviorSubject<DashboardScaleType>;

  constructor(private localStorage: LocalStorageService) {
    const storedScale = JSON.parse(
      this.localStorage.getItem(DashboardScaleService.persistenceKey),
    ) as DashboardScaleType;
    this.settings$ = new BehaviorSubject<DashboardScaleType>({
      scale: this.limitScale(storedScale?.scale ?? 100),
    });
  }

  public get settings() {
    return this.settings$.asObservable();
  }

  public getScale(): number {
    return this.settings$.getValue().scale;
  }

  public incrementScale(): void {
    this.updateScale({ scale: this.settings$.getValue().scale + 10 });
  }

  public decrementScale(): void {
    this.updateScale({ scale: this.settings$.getValue().scale - 10 });
  }

  private updateScale(nexValue: DashboardScaleType): void {
    const newSettings: DashboardScaleType = { scale: this.limitScale(nexValue.scale) };
    this.localStorage.setItem(DashboardScaleService.persistenceKey, JSON.stringify(newSettings));
    this.settings$.next(newSettings);
  }

  private limitScale(scale: number): number {
    return Math.min(300, Math.max(10, scale));
  }
}
