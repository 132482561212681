import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { ButtonDefWithValue, Gamepad } from '../../models';

enum GamepadActiveStateName {
  LOSE_FOCUS = 'loseFocus',
  GAIN_FOCUS = 'gainFocus',
  USER_IDLE_UNFOCUSED = 'userIdleUnFocused',
  USER_IDLE_FOCUSED = 'userIdleFocused',
  ACTIVE = 'active',
}

export enum GamepadNotificationName {
  LOSE_FOCUS_WHEN_GAMEPAD_ENABLED = 'loseFocusWhenGamepadEnabled',
  GAIN_FOCUS_WHEN_GAMEPAD_CANBE_RESUMED = 'gainFocusWhenGamepadCanbeResumed',
  IDLE_TIMEOUT_WHEN_GAMEPAD_ENABLED = 'idleTimeoutWhenGamepadEnabled',
  IDLE_TIMEOUT_WHEN_GAMEPAD_CANBE_RESUMED = 'idleTimeoutWhenGamepadCanbeResumed',
}

export const gamepadDeadbandThreshold = 0.15;

@Injectable({
  providedIn: 'root',
})

// TODO: The GamepadService and GamepadConnectionService has duplicate code and should be refactor to one class
// https://app.clubhouse.io/rocos/story/7424/refactor-gamepadservice-and-gamepadconnectionservice
export class GamepadConnectionService implements OnDestroy {
  public gamepadUpdated$ = new BehaviorSubject<Gamepad[]>([]);
  public gamepadPressed$ = new Subject<Gamepad>();
  public gamepadNotification$ = new Subject<GamepadNotificationName>();
  public gamepadEnabled$ = new BehaviorSubject<boolean>(false);

  ticker: NodeJS.Timeout;
  threshold: number = gamepadDeadbandThreshold;
  maxIdleTimeToAllowResume: number = 0.5 * 60 * 1000; // 30 seconds
  maxIdleTimeFocused: number = 5 * 60 * 1000; // 5 minutes
  lastGamepadInputTimestamp;
  lastHasFocus: boolean;
  lastIdleState: string;
  lastFocusTimestamp: number = Date.now();
  isIdle: boolean;
  lastUserEnableTimestamp: number;
  lastAutoDisableTimestamp: number;
  lastUserDisableTimestamp: number;
  keepAlive: boolean;

  private gamepadActiveState$ = new Subject<GamepadActiveStateName>();
  private gamepadEnabled: boolean = false;
  private lastGamepads = {};
  private gamepads: Gamepad[] = [];
  private subs: Subscription[] = [];
  private frameIntervalDelayMs = 100;
  private frameRefreshInterval;

  private gamepadPressInfo: {
    [gamepadId: string]: {
      isNoPressed: boolean;
    };
  } = {};

  private pressedButtons: ButtonDefWithValue[] = [];

  constructor() {
    this.listenGamepadConnections();
    this.watchGamepadActiveState();
    this.listenGamepadActiveState();

    this.gamepadEnabled$.asObservable().subscribe((x) => {
      this.gamepadEnabled = x;
    });
  }

  // TODO: there are no two gamepad components can be on the same page at the moment due to the singleton injection
  // Refactor the whole gamepad service to non-singleton  injection
  // each gamepad compoment would have its own copy of gamepad servcie
  public resetBehaviour() {
    this.keepAlive = false;
  }

  public setKeepAlive(alive: boolean): void {
    this.keepAlive = alive;
  }

  public checkGamepadActiveState() {
    const newIdleState = this.newIdleState();
    // we only notify the subscribers when the idlestate changed
    if (newIdleState) {
      this.gamepadActiveState$.next(newIdleState);
    } else if (this.documentFocusStateChanged()) {
      if (document.hasFocus()) {
        this.gamepadActiveState$.next(GamepadActiveStateName.GAIN_FOCUS);
      } else {
        this.gamepadActiveState$.next(GamepadActiveStateName.LOSE_FOCUS);
      }
    }
  }

  public listenGamepadActiveState() {
    this.gamepadActiveState$.subscribe((state) => {
      switch (state) {
        case GamepadActiveStateName.USER_IDLE_FOCUSED:
          if (this.gamepadEnabled) {
            this.isIdle = true;
            this.toggleGamepad(false, false);
            this.gamepadNotification$.next(GamepadNotificationName.IDLE_TIMEOUT_WHEN_GAMEPAD_ENABLED);
          }
          break;
        case GamepadActiveStateName.USER_IDLE_UNFOCUSED:
          if (this.canBeResumed()) {
            this.isIdle = true;
            this.toggleGamepad(false, false);
            this.gamepadNotification$.next(GamepadNotificationName.IDLE_TIMEOUT_WHEN_GAMEPAD_CANBE_RESUMED);
          }
          break;
        case GamepadActiveStateName.LOSE_FOCUS:
          if (this.gamepadEnabled) {
            this.toggleGamepad(false, false);
            this.gamepadNotification$.next(GamepadNotificationName.LOSE_FOCUS_WHEN_GAMEPAD_ENABLED);
          }
          break;
        case GamepadActiveStateName.GAIN_FOCUS:
          if (!this.gamepadEnabled && this.canBeResumed()) {
            this.toggleGamepad(true, false);
            this.gamepadNotification$.next(GamepadNotificationName.GAIN_FOCUS_WHEN_GAMEPAD_CANBE_RESUMED);
          }
          break;
      }
    });
  }

  canBeResumed() {
    if (!this.lastUserEnableTimestamp) {
      // The gamepad is never been enabled, cannot be resumed
      return false;
    }

    if (!this.lastAutoDisableTimestamp) {
      // The widget was not disalbed by ticker, return
      return false;
    }

    if (this.lastUserDisableTimestamp && this.lastAutoDisableTimestamp < this.lastUserDisableTimestamp) {
      // last disable was done by user, don't resume
      return false;
    }

    return !this.isIdle;
  }

  toggleGamepad(enable: boolean, isUser: boolean) {
    const now = Date.now();
    if (enable) {
      if (isUser) {
        this.lastUserEnableTimestamp = now;
      }

      this.resetLastGamepadInputTimestamp();
      this.isIdle = false;
    } else {
      if (isUser) {
        this.lastUserDisableTimestamp = now;
      } else {
        this.lastAutoDisableTimestamp = now;
      }
    }

    this.gamepadEnabled$.next(enable);
  }

  newIdleState() {
    // When keep alive, gamepad never be idle
    if (this.keepAlive) {
      return null;
    }

    const idleState = this.getIdleState();
    if (idleState !== this.lastIdleState) {
      this.lastIdleState = idleState;
      return idleState;
    }

    return null;
  }

  getIdleState(): GamepadActiveStateName {
    if (document.hasFocus()) {
      return this.isIdleFocused() ? GamepadActiveStateName.USER_IDLE_FOCUSED : null;
    } else {
      return this.isIdleUnFocused() ? GamepadActiveStateName.USER_IDLE_UNFOCUSED : null;
    }
  }

  public resetLastFocusTimestamp() {
    this.lastFocusTimestamp = Date.now();
  }

  public resetLastGamepadInputTimestamp() {
    this.lastGamepadInputTimestamp = Date.now();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private watchGamepadActiveState() {
    this.ticker = setInterval(() => {
      this.checkGamepadActiveState();
    }, 100);
  }

  private documentFocusStateChanged() {
    const currentHasFocus = document.hasFocus();
    if (currentHasFocus) {
      this.lastFocusTimestamp = Date.now();
    }

    if (this.lastHasFocus !== currentHasFocus) {
      this.lastHasFocus = currentHasFocus;
      return true;
    }

    this.lastHasFocus = currentHasFocus;
    return undefined;
  }

  private isIdleUnFocused() {
    const idlePeroid = this.getUnFocusPeroid();
    return idlePeroid > this.maxIdleTimeToAllowResume;
  }

  private isIdleFocused() {
    const idlePeroid = this.getIdlePeroid();
    return idlePeroid > this.maxIdleTimeFocused;
  }

  private getIdlePeroid() {
    const now = Date.now();
    return now - this.lastGamepadInputTimestamp;
  }

  private getUnFocusPeroid() {
    const now = Date.now();
    return now - this.lastFocusTimestamp;
  }

  private setLastGamepadInputTimestamp(gamepad) {
    for (const pressValue of gamepad.pressedValues) {
      if (pressValue.value > this.threshold) {
        this.lastGamepadInputTimestamp = Date.now();
        break;
      }
    }
  }

  private listenGamepadConnections() {
    const gamepadConnectedSub = fromEvent(window, 'gamepadconnected').subscribe(() => {
      this.reloadGamepads();
    });

    this.subs.push(gamepadConnectedSub);

    const gamepadDisconnectedSub = fromEvent(window, 'gamepaddisconnected').subscribe(() => {
      this.reloadGamepads();
    });

    this.subs.push(gamepadDisconnectedSub);

    // Reload gamepads by default.
    this.reloadGamepads();
  }

  private reloadGamepads(createRefreshInterval = true) {
    const browserGamePads = navigator.getGamepads();
    const gamepads = [];

    for (const gamepad of browserGamePads) {
      if (gamepad?.id != null) {
        Gamepad.fromGamepadModel(gamepad);
        gamepads.push(Gamepad.fromGamepadModel(gamepad));
      }
    }

    this.gamepads = gamepads;

    if (createRefreshInterval) {
      this.gamepadUpdated$.next(this.gamepads);
    }

    if (this.gamepads?.length > 0) {
      this.gamepads.forEach((gamepad) => {
        // Initialize gamepadPressInfo if it doesn't exist.
        if (!this.gamepadPressInfo[gamepad.id]) {
          this.gamepadPressInfo[gamepad.id] = {
            isNoPressed: false,
          };
        }

        // detect gamepad release event
        // when a key is released from the gamepad, the key will not be returned from the gamepad
        // we detect a key released by checking the last state of the key value
        if (this.lastGamepads[gamepad.id]?.pressedValues) {
          this.lastGamepads[gamepad.id].pressedValues.forEach((x) => {
            if (x.value === 0) {
              return;
            }
            const foundKeys = gamepad.pressedValues.filter((y) => {
              return y.type === x.type;
            });
            if (!foundKeys || foundKeys.length === 0) {
              // Axis control does not require release event.

              // The button is missing from the gamepad new frame, which means the button is released
              // we treat a button release as a button pressed with value 0
              const button = new ButtonDefWithValue(x.type, x.text, 0);
              gamepad.pressedValues.push(button);
            }
          });
        }

        // record the last gamepad state
        this.lastGamepads[gamepad.id] = gamepad;

        if (gamepad.pressedValues?.length > 0) {
          this.gamepadPressed$.next(gamepad);
          this.pressedButtons = gamepad.pressedValues;
          this.setLastGamepadInputTimestamp(gamepad);

          // We got pressed buttons
          this.gamepadPressInfo[gamepad.id].isNoPressed = false;
        } else {
          if (this.gamepadPressInfo[gamepad.id]) {
            const pressedInfo = this.gamepadPressInfo[gamepad.id];
            if (!pressedInfo.isNoPressed) {
              this.gamepadPressed$.next(gamepad);
              pressedInfo.isNoPressed = true;
            }
          }
        }
      });
    } else {
      // all gamepads are removed, we will disable the gamepad
      this.toggleGamepad(false, true);
    }

    if (createRefreshInterval && this.gamepads) {
      this.updateFrameRefreshInterval();
    }
  }

  private updateFrameRefreshInterval() {
    if (this.gamepads?.length > 0) {
      clearInterval(this.frameRefreshInterval);
      this.frameRefreshInterval = setInterval(() => {
        this.reloadGamepads(false);
      }, this.frameIntervalDelayMs);
    } else {
      clearInterval(this.frameRefreshInterval);
    }
  }
}
