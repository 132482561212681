import type { Feature, FeatureCollection, LineString } from 'geojson';
import { GeometryCoordinate } from './geometry.model';

export enum MissionType {
  RawMissionFile,
  UserDefined,
}

export enum WaypointType {
  Waypoint = 16,
  Takeoff = 22,
  ReturnToLaunch = 20,
  SplineWaypoint = 82,
}

export class MissionWaypoint {
  id: string;
  name: string;
  description: string;
  coordinate: GeometryCoordinate;
  type: WaypointType = WaypointType.Waypoint;
  index: number;

  constructor(
    latitude?: number,
    longitude?: number,
    altitude: number = 0,
    name?: string,
    type?: WaypointType,
    description?: string,
  ) {
    this.id = crypto.randomUUID();

    this.coordinate = new GeometryCoordinate();
    this.coordinate.latitude = latitude;
    this.coordinate.longitude = longitude;
    this.coordinate.altitude = altitude;

    if (name) {
      this.name = name;
    }

    if (type) {
      this.type = type;
    }

    if (description) {
      this.description = description;
    }
  }

  static fromXYZ(x: number, y: number, z: number): MissionWaypoint {
    const waypoint = new MissionWaypoint();
    if (waypoint.coordinate) {
      waypoint.coordinate.frame = 'local';
      waypoint.coordinate.x = x;
      waypoint.coordinate.y = y;
      waypoint.coordinate.z = z;
    }

    return waypoint;
  }

  static fromModel(model: any): MissionWaypoint {
    const waypoint = new MissionWaypoint();

    waypoint.coordinate = GeometryCoordinate.fromModel(model.coordinate);

    waypoint.id = model.id;
    waypoint.name = model.name;
    waypoint.description = model.description;

    waypoint.type = model.type;
    waypoint.index = model.index;

    return waypoint;
  }
}

export class Mission {
  id: string;
  /** A random id for resolving conflicting IDs when loading the same file into the portal. */
  randomId: string;
  title: string;
  type: MissionType = MissionType.UserDefined;
  rawData?: string; // specified if the type is RawMissionFile
  // geoPath?: Path;     // used if user defined
  waypoints?: MissionWaypoint[] = [];
  numberOfWaypoints: number = 0;
  constructor() {
    this.id = crypto.randomUUID();

    this.randomId = crypto.randomUUID();
  }

  /**
   * Returns an array of points, each point is an array of Longitude, Latitude, Altitude
   * NOTE the order is Longitude first as per a coordinate array here https://tools.ietf.org/html/rfc7946#section-3.1.4
   */
  public get coordinatesArray(): any[] {
    const _coordinates: any[] = [];
    this.waypoints.forEach((waypoint) => {
      _coordinates.push([waypoint.coordinate.longitude, waypoint.coordinate.latitude, waypoint.coordinate.altitude]);
    });

    return _coordinates;
  }

  /**
   * inserts a new waypoint, if position is specified then insert after that position
   */
  public addWaypoint(waypoint: MissionWaypoint, position?: number) {
    if (position != null) {
      this.waypoints.splice(position, 0, waypoint);
    } else {
      this.waypoints.push(waypoint);
    }

    this.rebuildIndexes();

    return waypoint;
  }

  public addWaypointWithLocalPosition(position: number[], index?: number): MissionWaypoint | null {
    if (position?.length >= 3) {
      const waypoint = MissionWaypoint.fromXYZ(position[0], position[1], position[2]);

      this.addWaypoint(waypoint, index);

      return waypoint;
    }

    return null;
  }

  /**
   *
   * @param includePoints: if the FeatureCollection should include a Point for each coordinate
   * https://tools.ietf.org/html/rfc7946
   */
  public getGeoJSONLineString(includePoints?: boolean): FeatureCollection {
    const geoJSONFeatureCollection = {
      type: 'FeatureCollection',
      id: this.id, // not part of GeoJSON format
      features: [],
    } as FeatureCollection;

    const geoJSONFeature: Feature<GeoJSON.LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    };

    // assign first empty feature
    geoJSONFeatureCollection.features.push(geoJSONFeature);

    const index = 0;
    // assign each point in the linestring to the first feature
    this.waypoints.forEach((waypoint) => {
      const coord: number[] = [];
      coord.push(waypoint.coordinate.longitude);
      coord.push(waypoint.coordinate.latitude);

      if (waypoint.coordinate.altitude !== undefined && waypoint.coordinate.altitude != null) {
        coord.push(waypoint.coordinate.altitude);
      }

      (geoJSONFeatureCollection.features[0].geometry as LineString).coordinates.push(coord);

      if (includePoints) {
        const geoJSONPoint: Feature<GeoJSON.Point> = {
          type: 'Feature',
          properties: {
            id: waypoint.id,
            name: waypoint.name,
            description: waypoint.description,
            index,
          },
          geometry: {
            type: 'Point',
            coordinates: coord,
          },
        };
        geoJSONFeatureCollection.features.push(geoJSONPoint);
      }
    });

    return geoJSONFeatureCollection;
  }

  public createWaypointCSVString() {
    const eol = '\r\n';
    const tab = '\t';
    let csvString = 'QGC WPL 110';

    csvString += eol;
    let sequence = 0;
    this.waypoints.forEach((waypoint) => {
      let currentWp = 0;
      let coordFrame = 3;

      const cmd = waypoint.type;
      const param1 = 0;
      const param2 = 0;
      const param3 = 0;
      const param4 = 0;
      const latitude = waypoint.coordinate.latitude;
      const longitude = waypoint.coordinate.longitude;
      let altitude = waypoint.coordinate.altitude;
      const autoContinue = 1;

      if (sequence === 0) {
        currentWp = 1;
        coordFrame = 0;
        altitude = 0;
      }

      csvString +=
        `${sequence}${tab}` +
        `${currentWp}${tab}` +
        `${coordFrame}${tab}` +
        `${cmd}${tab}` +
        `${param1}${tab}` +
        `${param2}${tab}` +
        `${param3}${tab}` +
        `${param4}${tab}` +
        `${latitude}${tab}` +
        `${longitude}${tab}` +
        `${altitude}${tab}` +
        `${autoContinue}` +
        `${eol}`;

      sequence++;
    });

    return csvString;
  }

  /** Adds the waypoints from a tab delimeted CSV waypoint file
   *  https://mavlink.io/en/services/mission.html
   */
  public addFromWaypointCSVString(waypointFileString) {
    // TODO: Move this out to a MAVLink specific library
    /*
        QGC WPL <VERSION>
        <INDEX> <CURRENT WP> <COORD FRAME> <COMMAND> <PARAM1> <PARAM2> <PARAM3> <PARAM4>
        <PARAM5/X/LONGITUDE> <PARAM6/Y/LATITUDE> <PARAM7/Z/ALTITUDE> <AUTOCONTINUE>
    */
    // let allTextLines = waypointFileString.split(/\r|\n|\r/);
    const allTextLines = waypointFileString.split(/\r\n/);

    // let headers = allTextLines[1].split(/\t/);
    const numHeaders = 12;
    let lastPoint: GeometryCoordinate;

    // first row is file definition like 'QGC WPL 110', it is not the header

    for (let i = 1; i < allTextLines.length; i++) {
      // for every line other than the first

      // split content based on comma
      const data = allTextLines[i].split(/\t/);

      if (data.length === numHeaders) {
        const rowData = [];
        for (let j = 0; j < numHeaders; j++) {
          rowData.push(data[j]);
        }
        // map to a waypoint format

        const cmd = parseInt(rowData[3], 10);
        const latitude = rowData[8];
        const longitude = rowData[9];
        let altitude = rowData[10];
        let waypoint: MissionWaypoint;

        if (i === 1) {
          // TODO: May need to fix how first altitude is set to relative
          // ground level, assume just at ground level for now
          altitude = 0;
        }

        if (cmd === 16) {
          // command 16 is waypoint
          waypoint = new MissionWaypoint(
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(altitude),
            'Waypoint',
            WaypointType.Waypoint,
          );
        } else if (cmd === 82) {
          // command 82 is Spline Waypoint
          waypoint = new MissionWaypoint(
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(altitude),
            'Spline Waypoint',
            WaypointType.SplineWaypoint,
          );
        } else if (cmd === 20) {
          // command 20 is return to launch
          waypoint = new MissionWaypoint(
            parseFloat(latitude),
            parseFloat(longitude),
            parseFloat(altitude),
            'Return to Launch',
            WaypointType.ReturnToLaunch,
          );
        } else if (cmd === 22) {
          // it's a takeoff command, use the previous coordinate for lat and lng but uses altitude for height
          waypoint = new MissionWaypoint(
            lastPoint.latitude,
            lastPoint.longitude,
            parseFloat(altitude),
            'Takeoff',
            WaypointType.Takeoff,
          );
        }
        lastPoint = waypoint.coordinate;

        // log each row to see output
        // lines.push(rowData);
        // this.waypoints.push(waypoint);
        this.addWaypoint(waypoint);
      }
    }
  }

  /** Assigns numeric values to the index field on each waypoint for convenience of rendering etc. */
  private rebuildIndexes() {
    let i = 0;
    this.waypoints.forEach((waypoint) => {
      waypoint.index = i;
      i++;
    });

    this.numberOfWaypoints = i;
  }
}
