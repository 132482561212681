<div class="rs-dialog">
  <ng-container *ngIf="mode$ | async as mode">
    <h2 mat-dialog-title class="title">
      <span *ngIf="mode === 'upload'">Upload File</span>
      <span *ngIf="mode === 'update'">File Already Exists</span>
      <span class="right-buttons">
        <button
          type="button"
          mat-icon-button
          (click)="cancel()"
          matTooltip="Cancel"
          tabindex="-1"
          [disabled]="isLoading$ | async"
        >
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div *ngIf="error$ | async as error" class="error">
      <mat-error>{{ error }}</mat-error>
    </div>
    <div *ngIf="isLoading$ | async as isLoading" class="spinner-holder">
      <app-loading-spinner size="x3" [isLoading]="isLoading"></app-loading-spinner>
      <p>Uploading {{ fileName }}</p>
    </div>
    <app-bag-file-drop
      *ngIf="(isLoading$ | async) === false && mode === 'upload'"
      (fileUploaded)="handleFile($event)"
    ></app-bag-file-drop>

    <ng-container *ngIf="(isLoading$ | async) === false && mode === 'update'">
      <div mat-dialog-content>
        <p>"{{ fileName }}" already exists in this project.</p>
      </div>
      <div class="footer">
        <button mat-stroked-button color="primary" (click)="override$.next('cancel')" tabindex="-1">Cancel</button>
        <button mat-stroked-button color="primary" (click)="override$.next('override')" tabindex="-1">
          Overwrite File
        </button>
        <button mat-flat-button color="primary" (click)="override$.next('existing')" tabindex="-1">Use Existing</button>
      </div>
    </ng-container>
  </ng-container>
</div>
