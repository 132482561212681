export const CHART_COLORS = [
  '#3f48e9', // $color-blue
  '#22bd66', // $color-green
  '#ffcd02', // $color-yellow
  '#d93f3f', // $color-red
  '#e83e8c', // $color-pink
  '#17a2b8', // $color-teal
  '#98f216', // $color-lime
  '#fd9527', // $color-orange
  '#653fb2', // $color-indigo
  '#fc5555', // $color-markup
];

export class ChartColor {
  public static colors = CHART_COLORS;

  public static getColor(num: number) {
    const len = CHART_COLORS.length;
    const idx = num % len;

    return CHART_COLORS[idx];
  }
}
