import { Injectable } from '@angular/core';
import PointcloudPLY, { type PLYElement } from '../../../../utils/pointcloud-ply-utils';
import { AssetStorageService } from '@shared/services/asset-storage';

@Injectable()
export class PointCloudImporter {
  public constructor(private assets: AssetStorageService) {}

  public async importPLYPointcloud(projectId: string, path: string): Promise<PLYElement[]> {
    const asset = await this.assets.getAsset(projectId, path);
    const buffer = await asset.arrayBuffer();
    const geometry = PointcloudPLY.parse(buffer);
    return geometry.data['vertex'];
  }
}
