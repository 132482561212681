<section class="content">
  <div class="stepper-content">
    <ng-container [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>
  </div>

  <footer class="stepper-footer">
    <ng-container *ngFor="let step of steps; let i = index; let first = first; let last = last">
      <app-stepper-button (click)="goToStep(i)" [selected]="i === selectedIndex" [class]="{ 'last-step': last }">
        {{ i + 1 }}
      </app-stepper-button>
      <hr *ngIf="!last" class="stepper-rule" />
    </ng-container>
    <ng-container *ngIf="selectedIndex !== steps.length - 1; else finalButton">
      <button
        class="display-contents"
        appLoadingButton
        [loading]="loading$ | async"
        cdkStepperNext
        [disabled]="disableButton"
      >
        {{ nextButtonLabel() }}
      </button>
    </ng-container>
  </footer>
</section>

<ng-template #finalButton>
  <button
    class="display-contents"
    appLoadingButton
    [loading]="loading$ | async"
    (click)="finished()"
    [disabled]="disableButton"
  >
    {{ nextButtonLabel() }}
  </button>
</ng-template>
