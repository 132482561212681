import type { EmbeddedViewRef, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureFlags, defaultFeatureFlags } from '@shared/services/feature-flag/feature-flags';
import { FeatureFlagService } from '@shared/services/feature-flag/feature-flag.service';

@UntilDestroy()
@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnChanges {
  @Input() public appFeatureFlag: FeatureFlags;

  public embeddedViewRef: EmbeddedViewRef<any>;

  private viewVisible: boolean;
  private flags: Record<FeatureFlags, boolean> = defaultFeatureFlags;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {
    this.viewContainer.clear();
    this.viewVisible = false;

    this.featureFlagService.featureFlags$.pipe(untilDestroyed(this)).subscribe((flags) => {
      this.flags = flags;
      if (this.appFeatureFlag) this.updateView(this.appFeatureFlag === undefined || this.flags[this.appFeatureFlag]);
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['appFeatureFlag'] && this.flags)
      this.updateView(this.appFeatureFlag === undefined || this.flags[this.appFeatureFlag]);
  }

  private updateView(showContent: boolean) {
    if (showContent && !this.viewVisible) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.viewVisible = true;
    } else if (!showContent && this.viewVisible) {
      this.viewContainer.clear();
      this.viewVisible = false;
    }
  }
}
