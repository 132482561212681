import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RocosSdkClientService } from '@shared/services';
import { timeout } from 'rxjs/operators';

export interface Message {
  target: string;
  payload?: string;
  metadata?: string;
}

@Component({
  selector: 'app-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePreviewComponent implements OnChanges, OnInit {
  @Input() public projectId: string;
  @Input() public callsign: string;
  @Input() public message: Message;

  @Output() public closePreview = new EventEmitter<void>();

  protected isSending = false;
  protected status: { success: boolean; msg?: string } = null;
  protected payload = new FormControl('');

  constructor(private sdk: RocosSdkClientService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.payload.setValue(this.message.payload ?? '');
    this.sendMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message']) {
      this.payload.setValue(this.message.payload ?? '');
    }
  }

  public sendMessage() {
    this.isSending = true;
    this.status = null;

    this.sdk.client
      .getControlService()
      .sendCommandWithAck({
        projectId: this.projectId,
        callsign: this.callsign,
        payload: this.payload.value,
        destination: this.message.target,
      })
      .pipe(timeout(10_000))
      .subscribe({
        next: () => {
          this.status = { success: true };
          this.cdr.markForCheck();
        },
        error: (error) => {
          this.status = { success: false, msg: String(error) };
          this.isSending = false;
          this.cdr.markForCheck();
        },
        complete: () => {
          this.isSending = false;
          this.cdr.markForCheck();
        },
      });
  }

  protected onCopyURI() {
    void navigator.clipboard.writeText(this.message.target);
  }

  protected onClosePreview() {
    this.closePreview.emit();
  }

  protected get prefix(): string | undefined {
    return this.getPrefixAndSuffix()[0];
  }

  protected get suffix(): string {
    return this.getPrefixAndSuffix()[1];
  }

  protected getPrefixAndSuffix(): [string | undefined, string] {
    const match = this.message.target.match(/^(\/?[^/]+\/)(.*)/);
    if (match) {
      return [match[1], match[2]];
    }

    return [undefined, this.message.target];
  }
}
