const vizRendererVariableName = 'web3d';
const contextVariableName = 'context';
const uiVariableName = 'ui';

export class CodeEvalEnvironment {
  /**
   * Environment ID.
   */
  private _id: string;
  get id(): string {
    return this._id;
  }

  private vizRenderer: any;
  private context: any;
  private uiUtils: any;

  constructor(id?: string) {
    this._id = id ? id : crypto.randomUUID();
  }

  resetEnvironment() {
    this.vizRenderer = null;
    this.context = {};
  }

  setVizRenderer(vizRenderer: any) {
    this.vizRenderer = vizRenderer;
  }

  setContext(context: any) {
    this.context = context;
  }

  setUIUtils(utils: any) {
    this.uiUtils = utils;
  }

  /**
   * Add or update one property of context.
   *
   * @param name Property name
   * @param value Value of the property
   */
  addToContext(name: string, value: any) {
    if (!this.context) {
      this.context = {};
    }

    this.context[name] = value;
  }

  /**
   * Get value from context by property name.
   *
   * @param name Property name
   */
  getValueFromContext(name: string): any {
    if (this.context?.[name] !== undefined) {
      return this.context[name];
    }

    return null;
  }

  runJavascriptCode(code: string): Error {
    let error: Error = null;

    try {
      if (!window.rocos) window.rocos = {};

      if (this.vizRenderer) {
        window.rocos[vizRendererVariableName] = this.vizRenderer;
      }
      if (this.context) {
        window.rocos[contextVariableName] = Object.assign({}, window.rocos[contextVariableName], this.context);
      }

      if (this.uiUtils) {
        window.rocos[uiVariableName] = this.uiUtils;
      }

      // eslint-disable-next-line no-eval
      eval(code);
    } catch (e) {
      error = e as Error;
    }

    return error;
  }
}
