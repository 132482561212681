<div class="row">
  <div class="col-12 col-lg-5">
    <div class="gamepad-image">
      <img src="assets/images/gamepads/xbox_controller.svg" />
    </div>
  </div>
  <div class="col-12 col-lg-7">
    <div class="row" *ngIf="gamepadTemplate">
      <div class="col col-md-6 col-lg-4 buttons-col" *ngFor="let btn of buttons">
        <div class="gamepad-button" [class.pressed]="pressedButtonsObject[btn.type]">
          <div class="up">
            <div class="name">{{ btn.text }}</div>
            <div class="value" *ngIf="pressedButtonsObject[btn.type] && pressedButtonsObject[btn.type].pressed">
              {{ pressedButtonsObject[btn.type].value | number : '1.0-1' }}
            </div>
          </div>
          <div class="down">
            <div class="bar">
              <ng-container *ngIf="pressedButtonsObject[btn.type] && pressedButtonsObject[btn.type].pressed">
                <app-gamepad-value-bar [value]="pressedButtonsObject[btn.type].value"></app-gamepad-value-bar>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<pre *appDeveloping>{{ gamepadTemplate | json }}</pre>

<!-- <pre>{{pressedButtonsObject | json}}</pre> -->
