import type { OnInit, OnDestroy } from '@angular/core';
import { Component, Input, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import type { Widget } from '../../../models';
import { WidgetOps } from '../../../models';
import { RendererService, OperationService } from '../../../services';
import type { WidgetJavascriptComponent } from '../../../widgets/widget-javascript';
import type { JavascriptWidgetContext } from 'src/app/operation/shared';
import { RendererModelService } from '@shared/services/threeD/renderer-model.service';
import { localOperationServices } from '@shared/services/threeD/local-operation-providers';
import { FAKEBYLON } from '@shared/services/threeD/utils/fakebylon';
import { migrateSceneJson } from '@shared/services/threeD/utils/scene-json-migrations';
// Important: this must be imported from the local-operation-providers.ts file and not from @operations

@Component({
  selector: 'app-widget-operation-local',
  templateUrl: './widget-operation-local.component.html',
  styleUrls: ['./widget-operation-local.component.scss'],
  providers: [...localOperationServices],
})
export class WidgetOperationLocalComponent implements OnInit, OnDestroy {
  @Input() public pageId: string;
  @Input() public projectId: string;
  @Input() public widget: WidgetOps;
  @Input() public callsign: string;

  @Output() public dataChanged: EventEmitter<void> = new EventEmitter();

  @ViewChild('renderContainer') public renderContainer: ElementRef;
  @ViewChildren('widgetJavascript') public jsWidgetComponents: QueryList<WidgetJavascriptComponent>;

  public jsWidgets = [];
  public javascriptContext: JavascriptWidgetContext = {} as any;

  public constructor(
    public vizRenderer: RendererService,
    public rendererModelService: RendererModelService,
    private operationService: OperationService,
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.BABYLON = new FAKEBYLON();
  }

  ngOnDestroy(): void {
    this.operationService = null;
    delete this.operationService;

    if (this.vizRenderer) {
      this.vizRenderer.cleanUp();
      this.vizRenderer = null;
      delete this.vizRenderer;
    }
  }

  public ngOnInit(): void {
    this.reloadOperationPageInfo();
    if (this.callsign) this.javascriptContext.callsigns = [this.callsign];
  }

  public resizeComponent(): void {
    this.vizRenderer.resize();
  }

  public setCamera(cameraKey: string): void {
    this.vizRenderer.setActiveCamera(cameraKey);
    if (this.widget) {
      this.widget.camera = cameraKey;
      this.dataChanged.emit();
    }
  }

  public runWidget(widget: Widget): void {
    const comp = this.jsWidgetComponents.find((c) => c.widget === widget);
    if (comp) comp.runCode();
  }

  public reloadScene(sceneData: any): void {
    this.vizRenderer.loadScene(sceneData);
    this.vizRenderer.generateScene(this.renderContainer.nativeElement);
    this.vizRenderer.cleanSubscribeToBoundSubscriptions();
    if (this.widget?.camera) this.vizRenderer.setActiveCamera(this.widget.camera);
    this.jsWidgets = this.vizRenderer.getJSWidgets() || [];
  }

  private reloadOperationPageInfo() {
    this.operationService.getOne(this.projectId, this.pageId).then((page) => {
      if (page?.payload) {
        const sceneData = JSON.parse(page.payload);
        const migrationResult = migrateSceneJson(sceneData);
        if (migrationResult.migrations.length) {
          console.warn('Migrated scene data:', migrationResult.migrations);
        }
        if (this.vizRenderer) this.reloadScene(migrationResult.sceneJson);
      } else {
        console.warn('No page info or config.');
      }
    });
  }
}
