import { EXPERIMENTAL_FEATURE } from '../../enums';

export class GamepadControlAction {
  /** type is the dataUri */
  type: string;
  metadata: any;
  actionType: GamepadControlActionType;

  static fromModel(model: any): GamepadControlAction {
    const action = new GamepadControlAction();

    action.type = model.type;
    action.metadata = model.metadata;

    return action;
  }
}

export class GamepadControlActionType {
  /** type is the dataUri */
  type: string;
  id:
    | 'agentRos'
    | 'agentMavlink'
    | 'slackMessage'
    | 'serverFunction'
    | 'agentHttp'
    | 'agentTcp'
    | 'clientDelay'
    | 'agentRosBridge';
  label: string;
  isAdminOnly: boolean = false;

  static fromURI(uri: string, isAdminOnly = false): GamepadControlActionType {
    const actionType = new GamepadControlActionType();
    actionType.isAdminOnly = isAdminOnly;

    actionType.type = uri;

    switch (uri) {
      case '/robot/agent/plugin/mavlink-control':
      case '/robot/agent/plugin/mavlink':
        actionType.id = 'agentMavlink';
        actionType.label = 'Agent Plugin - MAVLink';
        break;
      case '/robot/agent/plugin/rosbridge-control':
      case '/robot/agent/plugin/rosbridge':
        actionType.id = 'agentRosBridge';
        actionType.label = 'Agent Plugin - ROSBridge';
        break;
      case '/robot/agent/plugin/ros-control':
      case '/robot/agent/plugin/ros':
        actionType.id = 'agentRos';
        actionType.label = 'Agent Plugin - ROS';
        break;
      case '/robot/agent/plugin/http-control':
      case '/robot/agent/plugin/http':
        actionType.id = 'agentHttp';
        actionType.label = 'Agent Plugin - HTTP';
        break;
      case '/robot/agent/plugin/tcp-control':
      case '/robot/agent/plugin/tcp':
        actionType.id = 'agentTcp';
        actionType.label = 'Agent Plugin - TCP';
        break;
      case '/server/slack/message':
        actionType.id = 'slackMessage';
        actionType.label = 'Slack Message';
        break;
      case '/server/function':
        actionType.id = 'serverFunction';
        actionType.label = 'Function';
        break;
      case '/client/delay':
        actionType.id = 'clientDelay';
        actionType.label = 'Delay';
        break;
    }

    return actionType;
  }
}

export const gamepadControlActionDefaultTypes = [
  GamepadControlActionType.fromURI('/robot/agent/plugin/mavlink-control'),
  // GamepadControlActionType.fromURI('/robot/agent/plugin/rosbridge-control'),
  GamepadControlActionType.fromURI('/robot/agent/plugin/ros-control'),
  GamepadControlActionType.fromURI('/robot/agent/plugin/http-control'),
  GamepadControlActionType.fromURI('/robot/agent/plugin/tcp-control'),
  GamepadControlActionType.fromURI('/server/slack/message'),
  GamepadControlActionType.fromURI('/server/function', true),
  GamepadControlActionType.fromURI('/client/delay', true),
];

export class GamepadControlParameter {
  name: string;
  showInputBox: boolean = false;

  static fromModel(model: any): GamepadControlParameter {
    const param = new GamepadControlParameter();

    param.name = model.name;
    param.showInputBox = model.showInputBox;

    return param;
  }
}

export class GamepadControl {
  actions: GamepadControlAction[];
  id: string;
  parameters: GamepadControlParameter[];

  constructor(id?: string, actions?: GamepadControlAction[], parameters?: GamepadControlParameter[]) {
    this.id = id ? id : '';
    this.actions = actions ? actions : [];
    this.parameters = parameters ? parameters : [];
  }

  static fromModel(model: any): GamepadControl {
    const control = new GamepadControl();

    control.id = model.id;
    if (model.actions?.length > 0) {
      control.actions = model.actions.map((action) => {
        return GamepadControlAction.fromModel(action);
      });
    } else {
      control.actions = [];
    }

    if (model.parameters?.length > 0) {
      control.parameters = model.parameters.map((param) => {
        return GamepadControlParameter.fromModel(param);
      });
    } else {
      control.parameters = [];
    }

    return control;
  }
}

export type GamepadUxComponentControlType = 'button' | 'buttonAndPose' | 'buttonAndGetMission';

export const gamepadUxComponentControlTypes = [
  {
    label: 'Button',
    value: 'button' as GamepadUxComponentControlType,
    adminOnly: false,
  },
  {
    label: 'Button and get pose',
    value: 'buttonAndPose' as GamepadUxComponentControlType,
  },
  {
    label: 'Button and get mission', // This button only visible when both mission panel and button runjs turned on
    value: 'buttonAndGetMission' as GamepadUxComponentControlType,
    featureFlag: [EXPERIMENTAL_FEATURE.OperationMissionsPanel, EXPERIMENTAL_FEATURE.ButtonRunJs],
  },
];

export type GamepadUxComponentCommandVersion = 'commands' | 'controls' | 'commands-v2' | 'run-js';

export class GamepadUxComponent {
  id: string;
  description: string;
  label: string;
  controlType: GamepadUxComponentControlType;
  pickerHint?: {
    mode: string;
    frame: string;
  };

  // Should be removed. -- 11/03/19 Yishi
  commandName: string;
  commandParams: any[];
  showInputField: boolean = false;
  showButtonOnDashboard: boolean = false;
  // default true, otherwise, only people have experimental feature access can create buttons on operation page
  showButtonOnGlobalOperations: boolean = true;
  showButtonOnLocalOperations: boolean = true;

  commandVersion: GamepadUxComponentCommandVersion = 'controls';

  jsPayload?: string;

  constructor(id?: string, description?: string, label?: string, controlType?: GamepadUxComponentControlType) {
    this.id = id ? id : '';
    this.description = description ? description : '';
    this.label = label ? label : '';
    this.controlType = controlType;
  }

  get longTooltip() {
    let description = '';
    if (this.description) {
      description = ': ' + this.description;
    }
    return this.label + description;
  }

  get commandParamsAsObject() {
    const obj = {};

    if (this.commandParams?.length > 0) {
      this.commandParams.forEach((param) => {
        const key: string = param.id;
        if (!key.endsWith('_showInputBox')) {
          obj[param.id] = param.value;
        }
      });
    }

    return obj;
  }

  static fromModel(model: any): GamepadUxComponent {
    const uxComponent = new GamepadUxComponent();

    uxComponent.id = model.id;
    uxComponent.description = model.description;
    uxComponent.label = model.label;
    uxComponent.controlType = model.controlType;
    uxComponent.commandName = model.commandName;
    uxComponent.pickerHint = model.pickerHint;
    uxComponent.showInputField = model.showInputField;
    uxComponent.showButtonOnDashboard = model.showButtonOnDashboard;
    uxComponent.showButtonOnGlobalOperations = model.showButtonOnGlobalOperations;
    uxComponent.showButtonOnLocalOperations = model.showButtonOnLocalOperations;

    if (model.commandParameters) {
      const keys = Object.keys(model.commandParameters);
      const params = keys.map((key) => {
        return {
          id: key,
          value: model.commandParameters[key],
        };
      });

      uxComponent.commandParams = params;
    }

    if (model.commandParams) {
      uxComponent.commandParams = model.commandParams;
    }

    if (model.jsPayload) {
      uxComponent.jsPayload = model.jsPayload;
    }

    // For a model that doesn't have "commandVersion", give it a default value "controls".
    // For backward compatibility, remap commands to controls.
    // For old projects, commandVersion was not there. So we auto assign conrols to the commandVersion
    uxComponent.commandVersion =
      model.commandVersion && model.commandVersion !== 'commands' ? model.commandVersion : 'controls';

    return uxComponent;
  }
}
