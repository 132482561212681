export class ConfigGroup {
  static publishStatus = 'PUBLISHED';
  static draftStatus = 'DRAFT';

  id: string;
  name: string;
  description: string;
  config: any[];
  createdAt: Date;
  updatedAt: Date;
  status: string;

  static fromModel(model: ConfigGroup): ConfigGroup {
    const config = new ConfigGroup();

    config.id = model.id;
    config.name = model.name;
    config.description = model.description;
    config.config = model.config;

    if (model.createdAt) {
      config.createdAt = new Date(model.createdAt);
    }
    if (model.updatedAt) {
      config.updatedAt = new Date(model.updatedAt);
    }

    config.status = model.status;

    return config;
  }
}
