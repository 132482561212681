// Ref: https://github.com/alaingilbert/GamepadJs
export enum ButtonType {
  UNKNOWN = 0,
  UP,
  DOWN,
  LEFT,
  RIGHT,
  LEFT_STICK_X = 5,
  LEFT_STICK_Y,
  LEFT_STICK_BUTTON, // Stick pressed
  RIGHT_STICK_X,
  RIGHT_STICK_Y,
  RIGHT_STICK_BUTTON = 10, // Stick pressed
  LEFT_BUMPER,
  RIGHT_BUMPER,
  LEFT_TRIGGER,
  RIGHT_TRIGGER,
  A = 15, // Xbox
  B, // Xbox
  X, // Xbox
  Y, // Xbox
  BACK, // Xbox
  START = 20, // Xbox
  GUIDE, // Xbox
  SHARE, // PS4
  OPTIONS, // PS4
  PS, // PS4
  CIRCLE = 25, // PS4
  SQUARE, // PS4
  TRIANGLE, // PS4
}

export class ButtonDef {
  type: ButtonType;
  text: string;

  constructor(type?: ButtonType, text?: string) {
    this.type = type;
    this.text = text;
  }

  static defaultDef(type: ButtonType, text?: string): ButtonDef {
    const def = new ButtonDef(type, text);

    if (!text) {
      switch (type) {
        case ButtonType.A:
          def.text = 'A Button';
          break;
        case ButtonType.B:
          def.text = 'B Button';
          break;
        case ButtonType.X:
          def.text = 'X Button';
          break;
        case ButtonType.Y:
          def.text = 'Y Button';
          break;
        case ButtonType.UP:
          def.text = 'D-Pad Up';
          break;
        case ButtonType.DOWN:
          def.text = 'D-Pad Down';
          break;
        case ButtonType.LEFT:
          def.text = 'D-Pad Left';
          break;
        case ButtonType.RIGHT:
          def.text = 'D-Pad Right';
          break;
        case ButtonType.LEFT_BUMPER:
          def.text = 'Bumper (Left)';
          break;
        case ButtonType.RIGHT_BUMPER:
          def.text = 'Bumper (Right)';
          break;
        case ButtonType.LEFT_TRIGGER:
          def.text = 'Trigger (Left)';
          break;
        case ButtonType.RIGHT_TRIGGER:
          def.text = 'Trigger (Right)';
          break;
        case ButtonType.BACK:
          def.text = 'Back';
          break;
        case ButtonType.START:
          def.text = 'Start';
          break;
        case ButtonType.GUIDE:
          def.text = 'Guide';
          break;
        case ButtonType.LEFT_STICK_BUTTON:
          def.text = 'Stick Button (Left)';
          break;
        case ButtonType.RIGHT_STICK_BUTTON:
          def.text = 'Stick Button (Right)';
          break;
        case ButtonType.LEFT_STICK_X:
          def.text = 'Stick Axis X (Left)';
          break;
        case ButtonType.LEFT_STICK_Y:
          def.text = 'Stick Axis Y (Left)';
          break;
        case ButtonType.RIGHT_STICK_X:
          def.text = 'Stick Axis X (Right)';
          break;
        case ButtonType.RIGHT_STICK_Y:
          def.text = 'Stick Axis Y (Right)';
          break;
        default:
          def.text = 'Unknown';
          break;
      }
    }

    return def;
  }

  static fromJSON(json: any): ButtonDef {
    const type = json?.type ? json.type : ButtonType.UNKNOWN;
    const text = json?.text ? json.text : null;
    return ButtonDef.defaultDef(type, text);
  }

  toJSON() {
    return {
      type: this.type,
      text: this.text,
    };
  }
}

export class ButtonDefWithValue extends ButtonDef {
  /** Button index in the buttons mapping of gamepad */
  index: number;
  pressed: boolean = false;
  isSessionEndEvent: boolean = false;
  /** From -1 to 1 */
  value: number = 0;

  constructor(type?: ButtonType, text?: string, value?: number) {
    super(type, text);
    this.value = value;
  }

  // Convert the enum to string
  typeAsString(): string {
    return ButtonType[this.type];
  }
}

export class GamepadMapping {
  buttons: ButtonDef[];
  axes: ButtonDef[];

  static fromJSON(json: any): GamepadMapping {
    const mapping = new GamepadMapping();

    mapping.buttons = json?.buttons?.map((b: any) => ButtonDef.fromJSON(b));
    mapping.axes = json?.axes?.map((a: any) => ButtonDef.fromJSON(a));

    return mapping;
  }

  getButtonAtIndex(index: number): ButtonDef {
    if (this.buttons?.length >= index) {
      return this.buttons[index];
    }

    return null;
  }

  getAxisAtIndex(index: number): ButtonDef {
    if (this.axes?.length >= index) {
      return this.axes[index];
    }

    return null;
  }

  getButtonDefs(): ButtonDef[] {
    return [...this.axes, ...this.buttons];
  }
}

export class GamepadMappingXBox extends GamepadMapping {
  constructor() {
    super();

    this.buttons = [
      ButtonDef.defaultDef(ButtonType.A),
      ButtonDef.defaultDef(ButtonType.B),
      ButtonDef.defaultDef(ButtonType.X),
      ButtonDef.defaultDef(ButtonType.Y),
      ButtonDef.defaultDef(ButtonType.LEFT_BUMPER),
      ButtonDef.defaultDef(ButtonType.RIGHT_BUMPER),
      ButtonDef.defaultDef(ButtonType.LEFT_TRIGGER),
      ButtonDef.defaultDef(ButtonType.RIGHT_TRIGGER),

      ButtonDef.defaultDef(ButtonType.BACK),
      ButtonDef.defaultDef(ButtonType.START),

      ButtonDef.defaultDef(ButtonType.LEFT_STICK_BUTTON),
      ButtonDef.defaultDef(ButtonType.RIGHT_STICK_BUTTON),

      ButtonDef.defaultDef(ButtonType.UP),
      ButtonDef.defaultDef(ButtonType.DOWN),
      ButtonDef.defaultDef(ButtonType.LEFT),
      ButtonDef.defaultDef(ButtonType.RIGHT),

      ButtonDef.defaultDef(ButtonType.GUIDE),
    ];

    this.axes = [
      ButtonDef.defaultDef(ButtonType.LEFT_STICK_X),
      ButtonDef.defaultDef(ButtonType.LEFT_STICK_Y),
      ButtonDef.defaultDef(ButtonType.RIGHT_STICK_X),
      ButtonDef.defaultDef(ButtonType.RIGHT_STICK_Y),
    ];
  }
}

export type GamepadType = 'xbox' | 'ps4' | 'unknown';

export class Gamepad {
  name: string;
  id: string;
  type: GamepadType;
  mapping: GamepadMapping;
  pressedValues: ButtonDefWithValue[];

  static fromGamepadModel(model: any): Gamepad {
    const gamepad = new Gamepad();

    gamepad.id = model.id;
    gamepad.name = gamepad.getNameById();
    gamepad.type = 'xbox';

    const mapping = new GamepadMappingXBox();
    gamepad.mapping = mapping;

    gamepad.updatePressedValues(model.buttons, model.axes);

    return gamepad;
  }

  static fromJSON(json: any): Gamepad {
    const gamepad = new Gamepad();

    gamepad.id = json.id;
    gamepad.name = json.name;
    gamepad.type = json.type;
    gamepad.mapping = GamepadMapping.fromJSON(json.mapping);

    return gamepad;
  }

  toJSON(): any {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      mapping: this.mapping,
    };
  }

  getPressedButtonByButtonType(buttonType: ButtonType): ButtonDefWithValue {
    const buttons = this.pressedValues?.filter((item) => {
      return item.type === buttonType;
    });

    if (buttons?.length > 0) {
      return buttons[0];
    }

    return null;
  }

  getNameById(): string {
    let name = '';

    if (this.id) {
      const parts = this.id.split('(');
      if (parts?.length > 1) {
        name = parts[0].trim();
      }
    }

    return name;
  }

  private updatePressedValues(buttons: any[], axes?: any[]) {
    const pressed: ButtonDefWithValue[] = [];
    if (buttons?.length > 0) {
      buttons.forEach((button, idx) => {
        if (button.pressed) {
          const buttonWithValue = new ButtonDefWithValue();

          buttonWithValue.index = idx;
          buttonWithValue.pressed = button.pressed;
          buttonWithValue.value = button.value;

          // Get Button
          const buttonDef = this.mapping.getButtonAtIndex(idx);
          if (buttonDef) {
            buttonWithValue.text = buttonDef.text;
            buttonWithValue.type = buttonDef.type;
          }

          pressed.push(buttonWithValue);
        }
      });
    }
    if (axes?.length > 0) {
      axes.forEach((axis, idx) => {
        if (axis) {
          const buttonWithValue = new ButtonDefWithValue();

          buttonWithValue.index = idx;
          buttonWithValue.pressed = true;
          buttonWithValue.value = axis;

          // Get Axis
          const buttonDef = this.mapping.getAxisAtIndex(idx);
          if (buttonDef) {
            buttonWithValue.text = buttonDef.text;
            buttonWithValue.type = buttonDef.type;
          }

          pressed.push(buttonWithValue);
        }
      });
    }

    this.pressedValues = pressed;
  }
}
export class GamepadUtils {}
