import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import type { Breadcrumb, BreadcrumbPageType } from './breadcrumb';
import { BreadcrumbHelper } from './breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbChanged = new EventEmitter<Breadcrumb[]>(false);

  currentBreadcrumb: Breadcrumb[];
  currentUrl: string;
  currentBreadcrumbPageType: BreadcrumbPageType;

  isUpdateTitle: boolean = true;
  pageTitlePrefix: string = 'DroneDeploy Robotics';

  constructor(private router: Router, private titleService: Title) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.updateBreadcrumbWithUrl();
      });
  }

  public refreshBreadcrumb() {
    this.updateBreadcrumbWithUrl(true);
  }

  public updateBreadcrumb(label: string, level: number, refUrl?: string) {
    if (this.currentBreadcrumb?.length <= level) return;
    if (!refUrl) refUrl = this.currentUrl;

    const targetBreadcrumb = this.currentBreadcrumb[level];
    if (!refUrl.startsWith(targetBreadcrumb.url)) return;

    targetBreadcrumb.displayName = label;
    this.breadcrumbChanged.next(this.currentBreadcrumb);
    if (this.isUpdateTitle) this.updatePageTitle();
  }

  private updatePageTitle() {
    const breadcrumb = this.currentBreadcrumb;
    if (!breadcrumb) return;

    const labels = breadcrumb.map((bc) => {
      return bc.displayName;
    });

    const titleLabels = [this.pageTitlePrefix, ...labels];
    const title = titleLabels.join(' - ');
    this.titleService.setTitle(title);
  }

  private updateBreadcrumbWithUrl(forceRefresh = false) {
    const url = this.router.url;
    this.currentUrl = url;

    const pageType = BreadcrumbHelper.getTypeByUrl(url);

    // Only change breadcrumb when page type changed.
    if (this.currentBreadcrumbPageType !== pageType || forceRefresh) {
      const breadcrumb = BreadcrumbHelper.getBreadcrumbByUrl(url);
      this.currentBreadcrumb = breadcrumb;

      this.breadcrumbChanged.next(breadcrumb);

      if (this.isUpdateTitle) this.updatePageTitle();
    }

    this.currentBreadcrumbPageType = pageType;
  }
}
