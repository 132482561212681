export interface IGlobalOperationSettingsBackEnd {
  dataSources: string[];
  globalLocationDataURI: {
    [name: string]: string;
  };
  expressions: {
    [name: string]: string;
  };
}

export class GlobalOperationSetting {
  name: string;
  dataURI: string;
  valueExpression?: string;
  source?: string;
  autoSubscribe?: boolean = false;

  public static fromModel(model: any): GlobalOperationSetting {
    const settings = new GlobalOperationSetting();

    settings.name = model.name;
    settings.dataURI = model.dataURI;
    settings.valueExpression = model.valueExpression;

    settings.autoSubscribe = !!model.autoSubscribe;
    settings.source = model.source;

    return settings;
  }
}

export class GlobalOperationSettings {
  public static convertGlobalOperationSettingsObjectToList(settings: IGlobalOperationSettingsBackEnd) {
    const convertToList = (obj: any): any[] => {
      const dataURIsObj = {};

      if (obj) {
        const dataSources: string[] = obj['dataSources'];
        const globalLocationDataURI = obj['globalLocationDataURI'];
        const expressions = obj['expressions'];

        if (globalLocationDataURI) {
          const findSource = (uri: string): string => {
            let source: string = null;
            if (dataSources) {
              dataSources.forEach((dataSource) => {
                if (uri.startsWith(dataSource)) {
                  source = dataSource;
                }
              });
            }

            return source;
          };

          Object.keys(globalLocationDataURI).forEach((key) => {
            const uri = globalLocationDataURI[key];

            const source = findSource(uri);

            dataURIsObj[key] = {
              name: key,
              dataURI: uri,
            };

            if (source) {
              dataURIsObj[key].source = source;
              dataURIsObj[key].autoSubscribe = true;
            } else {
              dataURIsObj[key].autoSubscribe = false;
            }
          });
        }

        if (expressions) {
          Object.keys(expressions).forEach((key) => {
            const expression = expressions[key];

            if (!dataURIsObj[key]) {
              dataURIsObj[key] = {
                name: key,
                valueExpression: expression,
              };
            }

            dataURIsObj[key].name = key;
            dataURIsObj[key].valueExpression = expression;
          });
        }
      }

      return Object.values(dataURIsObj);
    };

    const list = convertToList(settings);

    if (list?.length > 0) {
      return list.map((item) => {
        return GlobalOperationSetting.fromModel(item);
      });
    }

    return [];
  }

  public static convertListToGlobalOperationSettingsObject(
    _list: GlobalOperationSetting[],
  ): IGlobalOperationSettingsBackEnd {
    const obj: IGlobalOperationSettingsBackEnd = {} as any;
    return obj;
  }
}
