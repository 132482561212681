import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-data-custom-query',
  templateUrl: './data-custom-query.component.html',
  styleUrls: ['./data-custom-query.component.scss'],
})
export class DataCustomQueryComponent implements OnInit {
  @Input()
  public source: string;

  @Input()
  public path: string;

  @Input()
  public dataType: string;

  @Input()
  public callsign: string;

  @Input()
  public querystring: string;

  @Output()
  public dataURIChange: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  ngOnInit() {
    this.createForm();
  }

  resubscribe() {
    const changeEvent = {
      source: this.form.get('formSource').value,
      path: this.form.get('formPath').value,
      dataType: this.form.get('formDataType').value,
      querystring: this.form.get('formQuerystring').value,
    };

    this.dataURIChange.next(changeEvent);
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      formSource: new UntypedFormControl({ value: this.source, disabled: false }, []),
      formQuerystring: new UntypedFormControl('', []),
      formPath: new UntypedFormControl({ value: this.path, disabled: false }, []),
      formDataType: new UntypedFormControl({ value: this.dataType, disabled: false }, []),
    });
    this.form.get('formPath').setValue(this.path);
  }
}
