export enum CommandV2Types {
  sendMessage = 'sendMessage',
  callService = 'callService',
}

export enum CommandV2TypeTemplateItemTypes {
  // eslint-disable-next-line id-blacklist
  string = 'string',
  // eslint-disable-next-line id-blacklist
  boolean = 'boolean',
  // eslint-disable-next-line id-blacklist
  number = 'number',
  json = 'json',
  jsTemplateLiterals = 'jsTemplateLiterals',
}

export interface ICommandV2Type {
  id: CommandV2Types;
  label: string;
  templateItems: ICommandV2TypeTemplateItem[];
  defaultSettingItemsCollection: ICommandV2TypeSettingItemsCollection;
  fromModel: (model, command) => any;
}

export interface ICommandV2TypeSettingItemsCollection {
  [id: string]: any;
}

export interface ICommandV2TypeTemplateItem {
  id: string;
  label: string;
  required: boolean;
  forceJson?: boolean;
  type: CommandV2TypeTemplateItemTypes;
}
