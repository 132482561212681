import type { AbstractMesh, Scene, StandardMaterial } from '@babylonjs/core';
import { AxesViewer, Matrix, Mesh, MeshBuilder, Quaternion, Space, TransformNode, Vector3 } from '@babylonjs/core';
import type { RobotMapsService } from '@robot/shared/robot-maps.service';
import type { PrimitiveMetaData } from '../primitives/primitives';
import { parentDefault } from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';
import { Voxels, VoxelsTiler } from './voxels';
import { Voxblox, type VoxbloxData } from './voxblox';
import { initMapMaterials, MapMaterial } from '../primitives/materialPrimitives';
import { DirectedGraph, MultiDirectedGraph } from 'graphology';
import { bidirectional } from 'graphology-shortest-path/unweighted';
import type { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { edgePathFromNodePath } from 'graphology-shortest-path';

export const robotGraphMetaData: PrimitiveMetaData = {
  key: 'robotMapLive',
  label: 'Robot Map Live',
  icon: 'ri-3d-box',
  editorPanels: {
    parent: parentDefault,
    properties: {
      name: true,
      position: false,
      rotation: false,
      scaling: false,
      material: false,
    },
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    bindAdvanced: true,
    robotMap: false,
  },
};

export interface GraphEvent {
  type: string;
  details?: {
    id?: string;
  };
}

export interface GraphNode {
  id: string;
  type: string;
  edges?: Record<string, unknown>;
  data: Record<string, unknown>;
  externalData?: Record<string, unknown>;
}

export interface GraphEdge {
  id: string;
  from: string;
  to: string;
  type: string;
  data: Record<string, unknown>;
  externalData?: Record<string, unknown>;
}

export interface NodeWrapper {
  node: GraphNode;
}

export interface EdgeWrapper {
  edge: GraphEdge;
}

export interface GraphData {
  nodes: Record<string, GraphNode>;
  edges: Record<string, GraphEdge>;
}

const isNodeWrapper = (r: unknown): r is NodeWrapper => (r as NodeWrapper).node !== undefined;
const isEdgeWrapper = (r: unknown): r is EdgeWrapper => (r as EdgeWrapper).edge !== undefined;

export class RobotGraph extends Mesh {
  private meshes: Map<string, Mesh | TransformNode> = new Map<string, Mesh | TransformNode>();
  private nodes: Record<string, GraphNode> = {};
  private edges: Record<string, GraphEdge> = {};
  private transformTree: MultiDirectedGraph = new MultiDirectedGraph();
  private nodeRenderers: Record<string, (nodeId: string) => Mesh | TransformNode | null> = {};
  private edgeRenderers: Record<string, (edgeId: string) => Mesh | null> = {};
  private mapSubscription: Subscription;

  constructor(
    name: string,
    private scene: Scene,
    private robotMapService: RobotMapsService,
    private projectId: string,
    private sourceKey: string,
    private debug: boolean = false,
  ) {
    super(name, scene);
    initMapMaterials(scene);
    this.initNodeRenderers();
    this.initEdgeRenderers();

    if (this.debug) addAxes(this);
  }

  public override setEnabled(value: boolean) {
    super.setEnabled(value);
    this.meshes.forEach((mesh) => mesh.setEnabled(value));
  }

  public override dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
    this.clear();
    this.mapSubscription?.unsubscribe();
    super.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  public get nodeCount(): number {
    return this.transformTree.order;
  }

  public get edgeCount(): number {
    return this.transformTree.size;
  }

  public getMesh(meshName: string): AbstractMesh | TransformNode | null {
    const node = this.meshes.get(meshName);
    if (node) return node as AbstractMesh | TransformNode;
    return null;
  }

  public getGraphFromServiceCall(): void {
    const nodes: Record<string, GraphNode> = {};
    const edges: Record<string, GraphEdge> = {};

    try {
      const { callsign, source } = this.getServiceSourceFromBoundUpdate(this.sourceKey);
      if (!callsign || !source) return;

      this.mapSubscription?.unsubscribe();
      this.mapSubscription = this.robotMapService
        .callServiceStream(this.projectId, callsign, source, { mode: 'progressive' })
        .pipe(
          finalize(() => {
            this.renderGraph({ nodes, edges });
          }),
        )
        .subscribe((response) => {
          if (!response) return;
          if (isNodeWrapper(response)) {
            nodes[response.node.id] = response.node;
          }
          if (isEdgeWrapper(response)) {
            edges[response.edge.id] = response.edge;
          }
        });
    } catch (error) {
      console.error('Error loading dynamic map', error);
    }
  }

  public async renderBoundUpdate(update: GraphEvent[]): Promise<void> {
    if (!this.isEnabled()) return;

    const updateNodes: Record<string, GraphNode> = {};
    const updateEdges: Record<string, GraphEdge> = {};

    update.forEach((event) => {
      switch (event.type) {
        case 'maps.MapClearedEvent':
          this.clear();
          break;
        case 'maps.NodeDataChangedEvent':
        case 'maps.NodeAddedEvent':
          updateNodes[event.details.id] = event.details as GraphNode;
          break;
        case 'maps.EdgeDataChangedEvent':
        case 'maps.EdgeAddedEvent':
          updateEdges[event.details.id] = event.details as GraphEdge;
          break;
        case 'maps.NodeDeletedEvent':
          this.deleteNode(event.details.id);
          break;
        case 'maps.EdgeDeletedEvent':
          this.deleteEdge(event.details.id);
          break;
      }
    });

    this.renderGraph({ nodes: updateNodes, edges: updateEdges });
  }

  private initNodeRenderers(): void {
    this.nodeRenderers = {
      'FRAME': this.renderTransformNode.bind(this),
      'PANORAMA': (nodeId) => this.renderCylinder(nodeId, this.getMaterial(MapMaterial.WHITE), 1, 0.5),
      'bd.spot.waypoint.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.YELLOW), 0.2),
      'bd.spot.object.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.TEAL), 0.1),
      'bd.spot.pose.seed.live.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.YELLOW), 0.1),
      'polygon.node.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.RED), 0.1),
      'path.node.v1': (nodeId) => this.renderSphere(nodeId, this.getMaterial(MapMaterial.GREEN), 0.1),
      'voxels.tiler.v1': this.renderVoxelsTiler.bind(this),
      'voxels.v1': this.renderVoxels.bind(this),
      'voxblox.v1': this.renderTransformNode.bind(this),
      'voxblox.block.v1': this.renderVoxblox.bind(this),
      undefined: () => null,
    };
  }

  private initEdgeRenderers(): void {
    this.edgeRenderers = {
      'TRANSFORM': this.renderTransform.bind(this),
      'bd.spot.traversable.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.YELLOW), 0.05),
      'bd.spot.observation.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.TEAL), 0.025),
      'polygon.edge.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.RED), 0.025),
      'polygon.start.v1': null,
      'polygon.belongs.v1': null,
      'path.edge.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.GREEN), 0.05),
      'path.start.v1': (edgeId) => this.renderTube(edgeId, this.getMaterial(MapMaterial.GREEN), 0.05),
      'contains.tile': () => null,
      'ros.pc2.observation.v1': () => null,
      undefined: () => null,
    };
  }

  private deleteNode(nodeId: string): void {
    this.deleteMesh(nodeId);
    if (!this.transformTree.hasNode(nodeId)) return;
    this.transformTree.dropNode(nodeId);
  }

  private deleteEdge(edgeId: string): void {
    this.deleteMesh(edgeId);
    if (!this.transformTree.hasEdge(edgeId)) return;
    this.transformTree.dropEdge(edgeId);
  }

  private renderGraph(graph: GraphData): void {
    if (!this.parent) return;

    // Update the in memory transform tree
    const parentName = (this.parent as any)['displayName'];
    if (!parentName) return;

    this.meshes.set(parentName, this.parent as TransformNode | Mesh);
    this.transformTree.mergeNode(parentName);

    Object.entries(graph.nodes).forEach(([nodeID, node]) => {
      this.transformTree.mergeNode(nodeID, node);
    });

    Object.entries(graph.edges).forEach(([edgeID, edge]) => {
      if (edge?.type === 'TRANSFORM') {
        if (!edge.data['TransformMatrix']) {
          console.warn(`TransformMatrix missing for edge ${edgeID}`);
          return;
        }
        const matrix = Matrix.FromArray(edge.data['TransformMatrix'] as unknown as number[]).transpose();
        this.transformTree.mergeDirectedEdgeWithKey(edgeID, edge.from, edge.to, { matrix });
      }
    });

    // Add the new nodes and edges to the lookups
    this.nodes = { ...this.nodes, ...graph.nodes };
    this.edges = { ...this.edges, ...graph.edges };

    // Create sets of nodes and edges to render so we only render each once
    const nodesToRender = new Set(Object.keys(graph.nodes));
    const edgesToRender = new Set(Object.keys(graph.edges));

    // Render the nodes using the transform tree
    // We do shortest path search from the parent to each node to render, so we can handle messages that come in out
    // of order
    Object.values(graph.nodes).forEach((node) => {
      const nodePath = bidirectional(this.transformTree, parentName, node.id);
      if (!nodePath) return;

      const edgePath = edgePathFromNodePath(this.transformTree, nodePath);
      edgePath.forEach((edgeID, i) => {
        const fromNodeID = nodePath[i];
        const toNodeID = nodePath[i + 1];

        if (!nodesToRender.has(toNodeID)) return;
        nodesToRender.delete(toNodeID);

        const fromNode = this.meshes.get(fromNodeID);
        if (!fromNode) return;

        const matrix = this.transformTree.getEdgeAttribute(edgeID, 'matrix');
        if (!matrix) return;

        this.renderNode(fromNode, matrix, toNodeID);

        Object.keys(node.edges).forEach((edgeId) => {
          edgesToRender.add(edgeId);
        });
      });
    });

    // Render the edges
    edgesToRender.forEach((edgeID) => {
      edgesToRender.delete(edgeID);
      this.renderEdge(edgeID);

      if (this.edges[edgeID]?.type === 'TRANSFORM') {
        this.updateTransformMatrix(this.edges[edgeID]);
      }
    });
  }

  private updateTransformMatrix(edge: GraphEdge): void {
    if (!this.transformTree.hasEdge(edge.id)) return;

    const matrix = this.transformTree.getEdgeAttribute(edge.id, 'matrix');
    if (!matrix) return;

    const fromNode = this.meshes.get(edge.from);
    const toNode = this.meshes.get(edge.to);
    if (!fromNode || !toNode) return;

    if (fromNode !== toNode.parent) return;

    applyMatrix(toNode, matrix);
  }

  private getServiceSourceFromBoundUpdate(sourceKey: string): { callsign: string | null; source: string | null } {
    if (!sourceKey) return { callsign: null, source: null };

    const callsign = sourceKey.trim().split('/')[1];
    const source = '/' + sourceKey.trim().split('?')[0].split('/').slice(2).join('/') + '/get';
    return { callsign, source };
  }

  private clear(): void {
    const oldGraph = this.transformTree;
    const oldMeshes = new Map(this.meshes);
    this.transformTree = new DirectedGraph();
    this.meshes = new Map<string, Mesh | TransformNode>();
    oldGraph.clear();
    oldMeshes.forEach((mesh) => {
      if (mesh !== this.parent) mesh.dispose();
    });
  }

  private deleteMesh(meshName: string): void {
    const mesh = this.meshes.get(meshName);

    if (mesh === this.parent || mesh === this) return;

    if (mesh) mesh.dispose();
    this.meshes.delete(meshName);
  }

  private getMaterial(materialName: string): StandardMaterial {
    return this.scene.getMaterialByName(materialName) as StandardMaterial;
  }

  private renderNode(parentNode: TransformNode | Mesh, matrix: Matrix, nodeId: string): void {
    const nodeData = this.nodes[nodeId];
    if (!nodeData) return;

    let nodeMesh: Mesh | TransformNode;
    const renderFunction = this.nodeRenderers[nodeData.type];
    if (renderFunction) {
      nodeMesh = renderFunction(nodeId);
    }

    if (!nodeMesh) return;

    applyMatrix(nodeMesh, matrix);
    nodeMesh.parent = parentNode;

    this.meshes.set(nodeId, nodeMesh);
    nodeMesh.setEnabled(this.isEnabled());
  }

  private renderTransformNode(nodeId: string): TransformNode {
    let transformNode = this.getMesh(nodeId) as TransformNode;
    if (transformNode) return transformNode;

    transformNode = new TransformNode(nodeId, this.scene);

    if (this.debug) addAxes(transformNode);

    return transformNode;
  }

  private renderCylinder(nodeId: string, material: StandardMaterial, diameter: number, height: number): Mesh {
    if (this.getMesh(nodeId)) return this.getMesh(nodeId) as Mesh;

    const cylinder = MeshBuilder.CreateCylinder(nodeId, { diameter, height }, this.scene);
    cylinder.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.LOCAL);
    cylinder.material = material;
    return cylinder;
  }

  private renderSphere(nodeId: string, material: StandardMaterial, diameter: number): Mesh {
    if (this.getMesh(nodeId)) return this.getMesh(nodeId) as Mesh;

    const sphere = MeshBuilder.CreateSphere(nodeId, { diameter, segments: 8 }, this.scene);
    sphere.material = material;
    return sphere;
  }

  private renderVoxelsTiler(nodeId: string): VoxelsTiler {
    return (this.getMesh(nodeId) as VoxelsTiler) || new VoxelsTiler(nodeId, this.scene);
  }

  private renderVoxels(nodeId: string): AbstractMesh | TransformNode {
    const voxelsMesh = this.getMesh(nodeId) || new Voxels(nodeId, this.scene);
    const nodeData = this.nodes[nodeId].data;
    const voxelData = nodeData['voxelData'] as unknown as string;
    if (!voxelData || !(voxelsMesh instanceof Voxels)) {
      return voxelsMesh;
    }
    voxelsMesh.renderBoundUpdate(voxelData);
    return voxelsMesh;
  }

  private renderVoxblox(nodeId: string): Mesh {
    const voxbloxMesh = (this.getMesh(nodeId) as Voxblox) || new Voxblox(nodeId, this.scene);
    const nodeData = this.nodes[nodeId];
    if (!nodeData || !(voxbloxMesh instanceof Voxblox)) {
      return voxbloxMesh;
    }
    voxbloxMesh.renderBoundUpdate(nodeData.data as unknown as VoxbloxData);
    return voxbloxMesh;
  }

  private renderEdge(edgeId: string): void {
    const graphEdge = this.edges[edgeId];
    if (!graphEdge) return;

    let edgeMesh: Mesh;
    const renderFunction = this.edgeRenderers[graphEdge.type];
    if (renderFunction) {
      edgeMesh = renderFunction(edgeId);
    }

    if (!edgeMesh) return;

    this.meshes.set(edgeId, edgeMesh);
    edgeMesh.setEnabled(this.isEnabled());
  }

  private getNodeMeshesForEdge(
    edgeId: string,
    fromId: string,
    toId: string,
  ): { fromNode: TransformNode | Mesh | null; toNode: TransformNode | Mesh | null } | null {
    this.deleteMesh(edgeId);
    const fromNodeMesh = this.getMesh(fromId);
    const toNodeMesh = this.getMesh(toId);
    if (!fromNodeMesh || !toNodeMesh) return null;
    fromNodeMesh?.computeWorldMatrix(true);
    toNodeMesh?.computeWorldMatrix(true);
    return { fromNode: fromNodeMesh, toNode: toNodeMesh };
  }

  private renderTransform(edgeId: string): Mesh | null {
    if (!this.debug) return null;

    const edgeData = this.edges[edgeId];
    const nodeMeshes = this.getNodeMeshesForEdge(edgeId, edgeData.from, edgeData.to);
    if (!nodeMeshes) return null;

    this.getMesh(edgeId)?.dispose();
    return this.renderDashedLine(edgeId, 1.0, 0.1);
  }

  private getEdgePath(
    edgeId: string,
  ): { fromNode: TransformNode | Mesh; toNode: TransformNode | Mesh; path: Vector3[] } | null {
    const graphEdge = this.edges[edgeId];
    const nodeMeshes = this.getNodeMeshesForEdge(edgeId, graphEdge.from, graphEdge.to);
    if (!nodeMeshes) return null;

    const { fromNode, toNode } = nodeMeshes;
    const path = [fromNode.getAbsolutePosition(), toNode.getAbsolutePosition()];
    if (path[0].equals(path[1])) return null;

    return { fromNode, toNode, path };
  }

  private renderDashedLine = (edgeId: string, dashSize: number, gapSize: number): Mesh | null => {
    const edgePath = this.getEdgePath(edgeId);
    if (!edgePath) return null;

    const { fromNode, path } = edgePath;
    const dashedLines = MeshBuilder.CreateDashedLines(edgeId, { points: path, dashSize, gapSize }, this.scene);
    dashedLines.setParent(fromNode);
    return dashedLines;
  };

  private renderTube = (edgeId: string, material: StandardMaterial, radius: number): Mesh | null => {
    const edgePath = this.getEdgePath(edgeId);
    if (!edgePath) return null;

    const { fromNode, path } = edgePath;
    const tube = MeshBuilder.CreateTube(edgeId, { path, radius, tessellation: 16 }, this.scene);
    tube.material = material;
    tube.setParent(fromNode);
    return tube;
  };
}

const applyMatrix = (node: TransformNode | Mesh, matrix: Matrix): void => {
  const scale = new Vector3();
  const rotation = new Quaternion();
  const translation = new Vector3();
  matrix.decompose(scale, rotation, translation);
  node.position = translation;
  node.rotationQuaternion = rotation;
  node.scaling = scale;
  node.computeWorldMatrix(true);
};

const addAxes = (parent: TransformNode | Mesh): void => {
  const axes = new AxesViewer(parent.getScene());
  axes.xAxis.parent = parent;
  axes.yAxis.parent = parent;
  axes.zAxis.parent = parent;
};
