import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import type { CodeEvalEnvironment } from '../../models';
import { OperationPageContext } from '../../models';
import { RendererService, ToastService } from '../../services';
import type { WidgetMenuItem } from '../shared';
import { WidgetBaseComponent } from '../shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { EditorOptions } from '@shared-modules/monaco-editor';

export const vizRendererVariableName = 'web3d';
export const contextVariableName = 'context';

export enum MenuItemValue {
  closePanel = 'closePanel',
  rename = 'rename',
  delete = 'delete',
}

@UntilDestroy()
@Component({
  selector: 'app-widget-javascript',
  templateUrl: './widget-javascript.component.html',
  styleUrls: ['./widget-javascript.component.scss'],
})
export class WidgetJavascriptComponent extends WidgetBaseComponent implements OnInit {
  @Input() widgetId: string;
  @Input() code: string;
  @Output() codeChange = new EventEmitter<string>();

  @Input() autoRun: boolean = false;
  @Input() showPanelButton: boolean = false;
  @Input() showToolbarButton: boolean = false;
  @Input() autoRunExecuted: boolean = false;
  @Input() startNewRunObservable: Observable<string>;
  @Output() autoRunChange = new EventEmitter<boolean>();
  @Output() panelButtonChange = new EventEmitter<boolean>();
  @Output() toolbarButtonChange = new EventEmitter<boolean>();
  @Output() menuItemClicked = new EventEmitter<any>();
  @Output() autoRunExecutedWatcher = new EventEmitter<boolean>();
  @Output() renameEvent = new EventEmitter();

  @Input() vizRenderer: RendererService;
  @Input() context: any;
  @Input() listMode: boolean = false;
  @Input() operationPageContext: OperationPageContext;

  editorOptions: EditorOptions = {
    language: 'javascript',
    wordWrap: 'on',
    minimap: {
      enabled: false,
    },
  };

  private get codeEvalEnvironment(): CodeEvalEnvironment {
    return this.operationPageContext?.codeEvalEnvironment;
  }

  constructor(private toast: ToastService) {
    super();
  }

  public ngOnInit() {
    this.menuItems = [
      {
        text: 'Close Panel',
        value: MenuItemValue.closePanel,
      },
      {
        text: 'Rename',
        value: MenuItemValue.rename,
      },
      {
        text: 'Delete',
        value: MenuItemValue.delete,
        cssClass: 'red-text',
      },
    ];

    if (this.autoRun && !this.autoRunExecuted) {
      this.runCode();
      // Trigger auto run executed event
      this.autoRunExecutedWatcher.emit(true);
    }

    if (this.startNewRunObservable) {
      this.startNewRunObservable.pipe(untilDestroyed(this)).subscribe((widgetId: string) => {
        if (widgetId === this.widgetId) this.runCode();
      });
    }
  }

  public override onMenuItemClick(item: WidgetMenuItem): void {
    switch (item.value) {
      case MenuItemValue.closePanel:
        this.menuItemClicked.emit(item);
        break;
      case MenuItemValue.rename:
        this.onRename();
        break;
      case MenuItemValue.delete:
        this.onDelete();
        break;
    }
  }

  public runCode(): void {
    const codeEnv = this.codeEvalEnvironment;

    if (codeEnv) {
      const error = codeEnv.runJavascriptCode(this.code);
      if (error) {
        console.error('Exception during code execution', { error });
        this.toast.short(error.message || 'Unknown error', null, 'failure');
      } else {
        this.toast.short(`Completed ‘${this.heading}’.`, null, 'success');
      }
    } else {
      if (!window.rocos) window.rocos = {};
      if (this.vizRenderer) window.rocos[vizRendererVariableName] = this.vizRenderer;
      if (this.context) {
        window.rocos[contextVariableName] = Object.assign({}, window.rocos[contextVariableName], this.context);
      }

      try {
        // eslint-disable-next-line no-eval
        eval(this.code);
        this.toast.short(`Completed ‘${this.heading}’.`, null, 'success');
      } catch (error) {
        console.error('Exception during code execution', { err: error });
        this.toast.short(error?.['message'], null, 'failure');
      }
    }
  }

  public onRunCode($event): void {
    $event.stopPropagation();
    this.runCode();
  }

  onCodeChanged($event: string) {
    if (this.code !== $event) {
      this.code = $event;
      this.codeChange.emit(this.code);
    }
  }

  onToggleAutoRun() {
    this.autoRunChange.emit(this.autoRun);
  }

  onTogglePanelButton() {
    this.panelButtonChange.emit(this.showPanelButton);
  }

  onToggleToolbarButton() {
    this.toolbarButtonChange.emit(this.showToolbarButton);
  }

  onRename() {
    this.renameEvent.emit();
  }
}
