import { Injectable } from '@angular/core';
import type { Translation } from '@ngneat/transloco';
import { getBrowserCultureLang, getBrowserLang, TranslocoService } from '@ngneat/transloco';
import type { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  browserLang: string;
  browserCultureLang: string;

  constructor(private translocoService: TranslocoService) {
    this.browserLang = getBrowserLang();
    this.browserCultureLang = getBrowserCultureLang();
  }

  init(autoDetectLanguageAndSwitch: boolean): Observable<Translation> {
    if (autoDetectLanguageAndSwitch) {
      this.detectLanguageAndSwitch();
    }
    return this.translocoService.selectTranslation();
  }

  detectLanguageAndSwitch() {
    const list: any[] = this.translocoService.getAvailableLangs();
    const avilableLangs = this.getAvilableLanguageNames(list);

    const targetLang = avilableLangs.find((lang) => {
      return lang === this.browserCultureLang || lang === this.browserLang;
    });

    if (targetLang) {
      this.translocoService.setActiveLang(targetLang);
    }
  }

  getAvilableLanguageNames(list: any[]): string[] {
    const names: string[] = [];

    if (list) {
      list.forEach((item) => {
        if (item.id) {
          names.push(item.id);
        } else if (typeof item === 'string') {
          names.push(item);
        }
      });
    }

    return names;
  }
}
