<button
  mat-button
  class="colour-button"
  [class.active]="isOpen"
  (click)="toggle($event)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <div class="colour-button-content">
    <div class="colour-circle" [ngStyle]="{ 'background-color': selectedValue }"></div>
    <span class="ri-arrow-down"></span>
  </div>
</button>
<!-- This template displays the overlay content and is connected to the button -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
>
  <div class="colour-list">
    <div class="colour-circle-wrapper">
      <button
        mat-button
        class="colour-circle"
        *ngFor="let color of values"
        [ngStyle]="{ 'background-color': color }"
        [class.selected]="color === selectedValue"
        (click)="change(color, $event)"
      ></button>
    </div>
  </div>
</ng-template>
