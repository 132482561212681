<app-widget-base
  [widget]="widget"
  [heading]="heading"
  [type]="type"
  [dataURI]="dataURI"
  [editing]="editing"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [status]="status"
  [theme]="theme"
>
  <div class="donut-circle" [ngClass]="[theme, 'radius-' + radius]">
    <!-- Donut Chart -->
    <div #chartdiv class="the-chart">
      <circle-progress [percent]="percent" [radius]="radius"></circle-progress>
    </div>
    <!-- <div class="donut-cutter"></div> -->

    <div class="center-container" [ngClass]="[theme]">
      <div class="value">
        <span *ngIf="validValue; else invalidValue">
          {{ value | valueEval : valueExpression | number : numberFormat }}
        </span>

        <!-- Invalid Value-->
        <ng-template #invalidValue> -- </ng-template>
      </div>
      <div class="data-name">
        {{ dataName }}
      </div>
      <div class="unit">
        {{ unit }}
      </div>
    </div>
  </div>
</app-widget-base>
