<div class="rs-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 class="title" mat-dialog-title>
      Add Action
      <span class="right-buttons">
        <button type="button" mat-icon-button (click)="onCancel()" matTooltip="Cancel" tabindex="-1">
          <span class="ri-cancel icon-button grey-icon"></span>
        </button>
      </span>
    </h2>
    <div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <input matInput type="text" formControlName="name" placeholder="Name" />
            <mat-error *ngIf="form && form?.get('name')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Source</mat-label>
            <mat-select formControlName="source">
              <mat-option *ngFor="let source of sources" [value]="source.type">
                {{ source.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form && form?.get('source')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input matInput type="number" formControlName="frequency" placeholder="Interval" />
            <mat-hint>Milliseconds.</mat-hint>
            <mat-error *ngIf="form && form?.get('frequency')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Trigger Type</mat-label>
            <mat-select formControlName="triggerType">
              <mat-option *ngFor="let option of trigerTypeEnum | enumToArray : 'string'" [value]="option.value">
                <span>{{ option.name | titlecase }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form && form?.get('triggerType')?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 command-version" [hidden]="!isControlsVisible">
          <h3>Action</h3>
          <mat-radio-group formControlName="commandVersion" (change)="onSwitchcommandVersion($event)">
            <mat-radio-button class="mr-4" value="commands-v2">Command</mat-radio-button>
            <ng-container *ngIf="isControlsVisible">
              <mat-radio-button class="mr-4" value="controls">Control</mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

        <div *ngIf="commandVersion.value === 'controls'" class="col-12">
          <ng-container>
            <div class="control-warning-box">
              We recommend using a Command if possible, as Controls are deprecated. For more information contact
              <a href="mailto:support@rocos.io" appExternalLink>support@rocos.io</a>
            </div>
          </ng-container>
        </div>

        <div class="col-6" *ngIf="isControlsVisible && commandVersion.value === 'controls'">
          <!-- Controls -->
          <mat-form-field>
            <mat-select placeholder="Select a Control" formControlName="commandId">
              <mat-option *ngFor="let control of controls" [value]="control.id">
                {{ control.id }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commandId?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-6" *ngIf="commandVersion.value === 'commands-v2'">
          <!-- Command V2 -->
          <mat-form-field>
            <mat-select placeholder="Select a Command" formControlName="commandId">
              <mat-option *ngFor="let command of commandsV2" [value]="command.id">
                {{ command.id }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="commandId?.errors?.['required']">Required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-flat-button color="primary" (clicked)="onSubmit()">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>
