export type DeploymentStatus = 'deploying' | 'success' | 'failed' | 'unknown' | 'provisioning';

export class RobotDeploymentResultLaunch {
  name: string;
  url: string;
}

export class RobotDeploymentResult {
  /**
   * Deployment status.
   */
  public status: DeploymentStatus = 'unknown';
  /**
   * Deployment details information.
   */
  public message: string;

  /**
   * Service Ip address (host:port)
   */
  public serviceIp: string;

  /**
   * Services list
   */
  public launchList: RobotDeploymentResultLaunch[] = [];

  /**
   * Convert json responses to class
   */
  public static fromModel(model: any): RobotDeploymentResult {
    const result = new RobotDeploymentResult();

    if (model?.service?.ip && model?.pod?.status === 'Running') {
      result.status = 'success';
      result.message = 'Running';
    } else if (model?.pod && model.pod.status === 'Running' && model.deployment.namespace && !model.service.ip) {
      result.status = 'provisioning';
      result.message = 'Provisioning IP Address';
    } else if (model?.pod?.status && model.deployment.error) {
      result.status = 'deploying';
      result.message = 'Deploying';
    } else if (model?.deployment?.error) {
      result.status = 'failed';
      result.message = 'Deploy Failed';
    } else {
      result.status = 'unknown';
      result.message = 'Status Unknown';
    }

    if (model?.service) {
      const service = model.service;
      result.serviceIp = service.ip;
    }

    if (model?.launchList && model.launchList.length > 0) {
      result.launchList = model.launchList;
    }

    return result;
  }
}
