<div class="file-upload-container" appDragDrop (fileDropped)="onFileDropped($event)">
  <div class="instruction">
    <span class="ri-ig-add-folder upload-icon"></span>
    <p>Drag and drop a file here, or <span (click)="fileDropRef.click()">browse</span></p>
  </div>
  <ng-container *ngIf="showFileList">
    <div class="files-list" *ngFor="let file of files; let i = index">
      <h4 class="file-text">
        {{ file?.name }}
      </h4>
      <p class="file-text">
        {{ formatBytes(file?.size) }}
      </p>
      <button mat-icon-button matTooltip="Delete File" tabindex="-1" (click)="deleteFile(i)">
        <span class="ri-cancel icon-button grey-icon"></span>
      </button>
    </div>
  </ng-container>
  <input
    hidden
    type="file"
    #fileDropRef
    (change)="fileBrowseHandler($event.target['files'])"
    [attr.accept]="accept?.join(',')"
    [attr.multiple]="multiple ? true : null"
    [disabled]="disabled"
  />
</div>
