import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastService } from '@shared/services';
import { Utils } from '@shared/utils';

export enum AudioStatus {
  connected = 'connected',
  disconnected = 'disconnected',
}

@Component({
  selector: 'app-audio-widget',
  templateUrl: './audio-widget.component.html',
  styleUrls: ['./audio-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioWidgetComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('myaudio2')
  public myAudio2: ElementRef;
  @Input() public sampleFormat: string;
  @Input() public sampleRate: number;
  @Input() public encodingFormat: string;
  @Input() public numberOfChannel: number;
  @Input() public value: string = '';
  @Input() public shouldConnect: boolean = false;
  @Output() public audioStatusChanged: EventEmitter<AudioStatus> = new EventEmitter<AudioStatus>();
  public active: boolean;
  public muted: boolean = false;
  public volume: number = 50;
  public slider: UntypedFormControl;
  public isLoading: boolean = false;

  private startAt: number;
  private audioContext: AudioContext;
  private bufferCount = 20;
  private frameCount = 0;
  private audioBinrary: ArrayBuffer;
  private lastBlockOfFramesAdded: boolean;
  private startCapturingTime: number;
  private bytesPerSample: number;
  private isMp3: boolean;
  private gainNode: GainNode;
  private failToDecodeTimes: number = 0;
  private mediaSource: MediaSource;
  private sourceBuffer: SourceBuffer;
  private useMse: boolean;

  constructor(private toast: ToastService) {
    this.slider = new UntypedFormControl();
  }

  public ngAfterViewInit(): void {
    if (this.useMse) {
      this.myAudio2.nativeElement.src = URL.createObjectURL(this.mediaSource);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.decodeAudio();
    }
    if (changes['shouldConnect']) {
      this.active = this.shouldConnect;
      this.startCapturingTime = 0;
    }
  }

  public onVolumeChanged(value: number): void {
    this.volume = value;
    if (this.volume > 0) {
      this.muted = false;
    }
    if (this.volume === 0) {
      this.muted = true;
    }
    this.setAudioVolume();
  }

  public onMuteClick(): void {
    this.muted = !this.muted;
    this.setAudioVolume();
  }

  public ngOnInit(): void {
    this.active = false;
    this.audioContext = new AudioContext();

    if (this.encodingFormat?.toLocaleLowerCase() === 'mp3') {
      this.isMp3 = true;
    }

    let mineType = 'audio/wav';
    if (this.isMp3) {
      mineType = 'audio/mpeg';
    }

    if ('MediaSource' in window && MediaSource.isTypeSupported(mineType)) {
      this.mediaSource = new MediaSource();
      if (this.mediaSource && MediaSource.isTypeSupported(mineType)) {
        this.useMse = true;
      }
      this.mediaSource.addEventListener('sourceopen', () => {
        this.sourceBuffer = this.mediaSource.addSourceBuffer(mineType);
      });
    }

    // create gain node to control volume
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = this.muted ? 0 : this.volume / 100;
    this.gainNode.connect(this.audioContext.destination);

    if (!this.sampleRate) {
      this.sampleRate = 16;
    }
    this.startAt = 0;
    this.bytesPerSample = 2;

    if (!this.numberOfChannel) {
      this.numberOfChannel = 1;
    }

    if (this.sampleFormat) {
      const pcmBits = this.sampleFormat.replace(/\D/g, '');
      this.bytesPerSample = Math.round((parseInt(pcmBits, 10) / 8) * this.numberOfChannel);
    }
  }

  private setAudioVolume() {
    this.gainNode.gain.value = this.muted ? 0 : this.volume / 100;
    this.myAudio2.nativeElement.volume = this.gainNode.gain.value;
  }

  private decodeAudio() {
    if (!this.active) {
      return;
    }

    if (!this.value) {
      return;
    }
    if (!this.startCapturingTime) {
      this.startCapturingTime = Date.now();
    }
    const data = this.value;
    let audioFrameBinary: ArrayBufferLike;
    try {
      audioFrameBinary = Utils.base64ToArrayBuffer(data);
    } catch (e) {
      this.active = false;
      this.audioStatusChanged.emit(AudioStatus.disconnected);
      this.toast.short('Fail to decode base64 data, audio disconnected.', null, 'failure');
      return;
    }

    if (!this.audioBinrary || this.lastBlockOfFramesAdded) {
      this.audioBinrary = audioFrameBinary;
      this.lastBlockOfFramesAdded = false;
    } else {
      this.audioBinrary = Utils.appendBuffer(this.audioBinrary, audioFrameBinary);
    }

    this.frameCount++;

    if (this.frameCount % this.bufferCount === 0) {
      let audioData: ArrayBuffer | ArrayBufferView;

      if (this.isMp3) {
        audioData = this.audioBinrary;
      } else {
        const sampleCounts = Math.round(this.sampleRate * 10);
        const opts = {
          numFrames: this.frameCount * sampleCounts,
          numChannels: this.numberOfChannel || 1,
          sampleRate: this.sampleRate * 1000 || 16000,
          bytesPerSample: this.bytesPerSample || 2,
        };

        opts.numFrames = this.bufferCount * sampleCounts * (1 + this.failToDecodeTimes);
        const wavHeader = Utils.buildWaveHeader(opts);
        audioData = Utils.appendBuffer(wavHeader, this.audioBinrary);
      }

      if (this.useMse) {
        this.sourceBuffer.appendBuffer(audioData);
        this.lastBlockOfFramesAdded = true;
      } else {
        const source: AudioBufferSourceNode = this.audioContext.createBufferSource();
        void this.audioContext.decodeAudioData(
          audioData,
          (buffer) => {
            source.buffer = buffer;
            this.startAt = Math.max(this.audioContext.currentTime, this.startAt);
            source.connect(this.gainNode);
            source.start(this.startAt);
            this.startAt += buffer.duration;
            this.lastBlockOfFramesAdded = true;
            this.failToDecodeTimes = 0;
          },
          (err) => {
            console.log(err);
            this.failToDecodeTimes++;
          },
        );
      }
    }
  }
}
