import { Vector3 } from '@babylonjs/core';

// @Injectable({
//   providedIn: 'root'
// })

export class LaserRay {
  /** Coordinate of the start point of the ray */
  public startPoint: Vector3;

  /** Coordinate of the end point of the ray */
  public endPoint: Vector3;

  /** radians offset from parent body */
  public relativeAngle: number;

  /** how long it is */
  public length: number;

  /** intensity info from the original ray scan */
  public intensity: number;

  /** if the length / range was at max */
  public maxRange: boolean;
}

/**ROS topic type
 * http://docs.ros.org/api/sensor_msgs/html/msg/LaserScan.html
 * http://wiki.ros.org/depthimage_to_laserscan#Published_Topics */
export class ROSLaserScan {
  /** type Header, http://docs.ros.org/api/std_msgs/html/msg/Header.html */
  public header: any;

  /** start angle of the scan [rad] */
  public angle_min: number;

  /** end angle of the scan [rad] */
  public angle_max: number;

  /** angular distance between measurements */
  public angle_increment: number;

  /** time between measurements [seconds] - if your scanner
        is moving, this will be used in interpolating position
        of 3d points */
  public time_increment: number;

  /** The number of pixel rows to use to generate the laserscan. For each column,
   * the scan will return the minimum value for those pixels centered vertically
   * in the image */
  public scan_height: number = 1;

  /** time between scans [seconds] */
  public scan_time: number;

  /** minimum range value [m] */
  public range_min: number = 0.45;

  /** maximum range value [m] */
  public range_max: number = 10;

  /** distance (range) of each ray [m] (Note: values < range_min or > range_max should be discarded) */
  public ranges: Array<number>;

  /** intensity data [device-specific units].  If your
        device does not provide intensities, please leave
        the array empty. */
  public intensities: Array<number>;
}

/** Based on the LaserScan message in ROS: http://docs.ros.org/api/sensor_msgs/html/msg/LaserScan.html */
export class LaserscanService {
  /** Where the scanner was (coordinate) when it started the scan
   *  it may move during the scan, this is considered the parent object origin
   */
  public startCoord: Vector3;
  public rays: Array<LaserRay> = [];

  constructor(startCoord) {
    this.startCoord = startCoord;
  }

  /**
   * Creates the laser scan ray objects based on ROS LaserScan payload
   *
   * @param rosLaserScan LaserScan payload from ROS system
   * @param distanceMoved How far the robot moved during one full scan (all rays) in meters by axis
   */
  public fromROSLaserScan(rosLaserScan: ROSLaserScan, scanAxis: 'x' | 'y' | 'z' = 'z', distanceMoved?: Vector3) {
    this.rays = [];
    let rayIndex = 0;
    const rayCount = rosLaserScan.ranges.length;
    const distanceSoFar = new Vector3(0, 0, 0);
    let angle = rosLaserScan.angle_min;

    rosLaserScan.ranges.forEach((range) => {
      // start create ray (use to not generate if range was over max range)
      let ray = null;
      ray = new LaserRay();
      ray.relativeAngle = angle;
      ray.length = range;
      ray.intensity = rosLaserScan.intensities[rayIndex];

      if (distanceMoved) {
        ray.startPoint = this.startCoord.add(distanceSoFar);
      } else {
        ray.startPoint = this.startCoord;
      }

      if (range > rosLaserScan.range_min && range < rosLaserScan.range_max) {
        ray.endPoint = this.getPoint(ray.startPoint, ray.relativeAngle, ray.length, scanAxis);
        ray.maxRange = false;
      } else {
        ray.endPoint = this.getPoint(ray.startPoint, ray.relativeAngle, rosLaserScan.range_max, scanAxis);
        ray.maxRange = true;
      }

      this.rays.push(ray); // will push invalid rays as nulls

      if (distanceMoved) {
        distanceSoFar.add(
          new Vector3(distanceMoved.x / rayCount, distanceMoved.y / rayCount, distanceMoved.z / rayCount),
        ); // how much is moved between rays
      }
      angle += rosLaserScan.angle_increment;
      rayIndex++;
    });
  }

  // calulate rotation 2D system around Z axis
  private getPoint(pointSource: Vector3, angle: number, distance: number, axis: 'x' | 'y' | 'z'): Vector3 {
    let endPointAxisX;
    let endPointAxisY;
    let endPointAxisZ;

    switch (axis) {
      case 'x':
        endPointAxisX = pointSource.x;
        endPointAxisY = pointSource.y + Math.cos(angle) * distance;
        endPointAxisZ = pointSource.z + Math.sin(angle) * distance;
        break;
      case 'y':
        endPointAxisX = pointSource.x + Math.cos(angle) * distance;
        endPointAxisY = pointSource.y;
        endPointAxisZ = pointSource.z + Math.sin(angle) * distance;
        break;

      default:
        endPointAxisX = pointSource.x + Math.cos(angle) * distance;
        endPointAxisY = pointSource.y + Math.sin(angle) * distance;
        endPointAxisZ = pointSource.z;
        break;
    }

    return new Vector3(endPointAxisX, endPointAxisY, endPointAxisZ) as Vector3;
  }
}
