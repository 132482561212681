import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { gamepadDeadbandThreshold } from '@shared/gamepad';
import type { ButtonDefWithValue } from '@shared/models';

@Component({
  selector: 'app-gamepad-gui-indicator',
  templateUrl: './gamepad-gui-indicator.component.html',
  styleUrls: ['./gamepad-gui-indicator.component.scss'],
})
export class GamepadGuiIndicatorComponent implements OnChanges {
  @Input()
  active: boolean = false;
  @Input()
  pressedValues: ButtonDefWithValue[] = [];

  dPadPressed: boolean = false;
  dPadUpPressed: boolean = false;
  dPadDownPressed: boolean = false;
  dPadLeftPressed: boolean = false;
  dPadRightPressed: boolean = false;
  aButtonPressed: boolean = false;
  bButtonPressed: boolean = false;
  xButtonPressed: boolean = false;
  yButtonPressed: boolean = false;
  bumperLeftPressed: boolean = false;
  bumperRightPressed: boolean = false;
  triggerLeftPressed: boolean = false;
  triggerRightPressed: boolean = false;
  backPressed: boolean = false;
  startPressed: boolean = false;
  stickButtonLeftPressed: boolean = false;
  stickButtonRightPressed: boolean = false;
  guidePressed: boolean = false;

  threshold: number = gamepadDeadbandThreshold;
  stickOffsetFactor = 30;
  stickAxisXLeftValue: number;
  stickAxisYLeftValue: number;
  stickAxisYRightValue: number;
  stickAxisXRightValue: number;

  resetButtons() {
    this.dPadPressed = false;
    this.dPadUpPressed = false;
    this.dPadDownPressed = false;
    this.dPadLeftPressed = false;
    this.dPadRightPressed = false;
    this.aButtonPressed = false;
    this.bButtonPressed = false;
    this.xButtonPressed = false;
    this.yButtonPressed = false;
    this.bumperLeftPressed = false;
    this.bumperRightPressed = false;
    this.triggerLeftPressed = false;
    this.triggerRightPressed = false;
    this.backPressed = false;
    this.startPressed = false;
    this.stickButtonLeftPressed = false;
    this.stickButtonRightPressed = false;
    this.guidePressed = false;
  }

  get stickButtonLeftStyle() {
    return `transform: translate(${62 + this.stickAxisXLeftValue * this.stickOffsetFactor}px, ${
      53 + this.stickAxisYLeftValue * this.stickOffsetFactor
    }px)`;
  }
  get stickButtonRightStyle() {
    return `transform: translate(${364 + this.stickAxisXRightValue * this.stickOffsetFactor}px, ${
      180 + this.stickAxisYRightValue * this.stickOffsetFactor
    }px)`;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['active'] && changes['active'].currentValue === false) {
      this.resetButtons();
      return;
    }

    if (changes['pressedValues']) {
      this.resetButtons();
      this.pressedValues.forEach((v) => {
        if (v.text.includes('D-Pad')) {
          this.dPadPressed = true;
        }
        if (v.text.includes('D-Pad Up')) {
          this.dPadUpPressed = true;
        }
        if (v.text.includes('D-Pad Down')) {
          this.dPadDownPressed = true;
        }
        if (v.text.includes('D-Pad Left')) {
          this.dPadLeftPressed = true;
        }
        if (v.text.includes('D-Pad Right')) {
          this.dPadRightPressed = true;
        }
        if (v.text.includes('A Button')) {
          this.aButtonPressed = true;
        }
        if (v.text.includes('B Button')) {
          this.bButtonPressed = true;
        }
        if (v.text.includes('X Button')) {
          this.xButtonPressed = true;
        }
        if (v.text.includes('Y Button')) {
          this.yButtonPressed = true;
        }
        if (v.text.includes('Bumper (Left)')) {
          this.bumperLeftPressed = true;
        }
        if (v.text.includes('Bumper (Right)')) {
          this.bumperRightPressed = true;
        }
        if (v.text.includes('Trigger (Left)')) {
          this.triggerLeftPressed = true;
        }
        if (v.text.includes('Trigger (Right)')) {
          this.triggerRightPressed = true;
        }
        if (v.text.includes('Back')) {
          this.backPressed = true;
        }
        if (v.text.includes('Start')) {
          this.startPressed = true;
        }
        if (v.text.includes('Stick Button (Left)')) {
          this.stickButtonLeftPressed = true;
        }
        if (v.text.includes('Stick Axis X (Left)')) {
          if (Math.abs(v.value) > this.threshold) {
            this.stickButtonLeftPressed = true;
          }
          this.stickAxisXLeftValue = v.value;
        }
        if (v.text.includes('Stick Axis Y (Left)')) {
          if (Math.abs(v.value) > this.threshold) {
            this.stickButtonLeftPressed = true;
          }
          this.stickAxisYLeftValue = v.value;
        }
        if (v.text.includes('Stick Button (Right)')) {
          this.stickButtonRightPressed = true;
        }
        if (v.text.includes('Stick Axis X (Right)')) {
          if (Math.abs(v.value) > this.threshold) {
            this.stickButtonRightPressed = true;
          }
          this.stickAxisXRightValue = v.value;
        }
        if (v.text.includes('Stick Axis Y (Right)')) {
          if (Math.abs(v.value) > this.threshold) {
            this.stickButtonRightPressed = true;
          }
          this.stickAxisYRightValue = v.value;
        }
        if (v.text.includes('Guide')) {
          this.guidePressed = true;
        }
      });
    }
  }
}
