import type { Scene } from '@babylonjs/core';
import { TransformNode } from '@babylonjs/core';

export const gizmoId = (rootId: string, id: string) => {
  return `${rootId}_${id}`;
};

export const getGizmoRoot = (scene: Scene, rootId: string) => {
  let root = scene.getTransformNodeByName(rootId);
  if (!root) root = new TransformNode(rootId, scene);
  return root;
};
