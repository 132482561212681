import type { PrimitiveMetaData } from './primitives';

export type DefaultCameraTypes = 'orthographic' | 'arcrotate';

export const supportedCameraTypes: Record<DefaultCameraTypes, PrimitiveMetaData> = {
  orthographic: {
    key: 'orthographic',
    label: 'Orthographic',
    icon: 'ri-camera',
  },
  arcrotate: {
    key: 'arcrotate',
    label: 'Directional',
    icon: 'ri-camera',
  },
};
