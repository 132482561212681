export class PersonalAccessToken {
  projectId: string;
  robotDefinitionId?: string;
  name: string;
  id?: string;
  ttlDays?: number = 90;
  scope?: string[] = ['provisioning'];
  metaData?: Record<string, unknown>;
  expiry?: Date;

  static fromModel(model: PersonalAccessToken): PersonalAccessToken {
    const pat: PersonalAccessToken = new PersonalAccessToken();

    pat.projectId = model.projectId;
    pat.robotDefinitionId = model.robotDefinitionId;
    pat.name = model.name;

    if (model.ttlDays !== undefined) {
      pat.ttlDays = model.ttlDays;
    }
    if (model.id !== undefined) {
      pat.id = model.id;
    }
    if (model.scope !== undefined) {
      pat.scope = model.scope;
    }
    if (model.metaData !== undefined) {
      pat.metaData = model.metaData;
    }
    if (model.expiry !== undefined) {
      pat.expiry = new Date(model.expiry);
    }

    if (model.robotDefinitionId && !model.metaData?.['robotDefinitionId']) {
      pat.metaData = model.metaData ? model.metaData : {};

      pat.metaData['defaultRobotDefinitionId'] = model.robotDefinitionId;
    }

    return pat;
  }
}
