<app-widget-base
  [heading]="heading"
  [type]="type"
  [editing]="editing"
  [dataURI]="dataURI"
  (deleted)="onDelete()"
  [height]="height"
  [width]="width"
  [status]="status"
  [isGroupWidget]="isGroupWidget"
  (editClicked)="onEdit()"
  (backgroundLayerChanged)="onBackgroundLayerChanged($event)"
  [layerIndex]="layerIndex"
  [theme]="theme"
>
  <div class="main-content" #mainContent [ngClass]="theme">
    <div class="no-table-data" *ngIf="!hasData">No new data received yet</div>
    <ng-container>
      <!-- Display as Columns -->
      <ng-container *ngIf="messageFormat === 'table'">
        <app-json-data-table
          [isApendingPaused]="isApendingPaused"
          [isApendingMode]="appendRows === 'true'"
          [contentObservable]="dataForTableObservable"
          [displayedColumns]="displayedColumns"
          [displayedColumnsNames]="displayedColumnsNames"
          [maxHeight]="getMaxTableHeight()"
          [boldFirstColumn]="_tableLayout === 'rows'"
          [hideHeader]="_tableLayout === 'rows'"
        >
        </app-json-data-table>
      </ng-container>

      <!-- Display as Rows -->
      <ng-container *ngIf="messageFormat === 'raw-json'">
        <pre class="raw-json-block">{{ content | json }} </pre>
      </ng-container>
    </ng-container>
  </div>
</app-widget-base>
