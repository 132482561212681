import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment, urls } from '@env/environment';
import { RouterUtils } from '../../utils';
import { UserService } from '../user';

export const KEY_REDIRECT_URL = 'redirect_url';

@Injectable({
  providedIn: 'root',
})
export class GotoService {
  robotGlobalOperationSettingTabIndex = 1;
  robotProfileGlobalOperationSettingTabIndex = 1;
  private redirectUrl?: string;

  constructor(private router: Router, private userService: UserService, private location: Location) {}

  updateQueryParams(params: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([], {
        queryParams: params,
      }),
    );

    this.location.go(url);
  }

  public url(url: string) {
    this.router.navigateByUrl(url);
  }

  public home() {
    this.router.navigate(['/', urls.home]);
  }

  public dashboard() {
    this.router.navigate(['/', urls.dashboard]);
  }

  public noAccountPage() {
    const url = urls.noAccount.index();
    this.router.navigateByUrl(`/${url}`);
  }

  public accountIndexPage(accountId: string) {
    const url = urls.account.index(accountId);
    this.router.navigateByUrl(`/${url}`);
  }

  public defaultAccountPage(inviteCode?: string) {
    const accountId = this.userService.defaultAccount ? this.userService.defaultAccount.id : null;

    if (accountId) {
      const url = urls.account.index(accountId, inviteCode);

      if (inviteCode) {
        this.router.navigateByUrl(`/${url}?inviteCode=${inviteCode}`);
        return;
      }

      this.router.navigateByUrl(`/${url}`);
    } else {
      this.home();
    }
  }

  public robotsList(projId: string) {
    const url = urls.robots.list(projId);
    this.router.navigateByUrl(`/${url}`);
  }

  public robotsIntro(projId: string) {
    const url = urls.robots.intro(projId);
    return this.router.navigateByUrl(`/${url}`);
  }

  public robotInfo(projId: string, callsign: string) {
    const url = urls.robots.info(projId, callsign);
    this.router.navigateByUrl(`/${url}`);
  }

  public robotDashboard(projId: string, callsign: string, dashboardId: string) {
    const url = urls.robots.dashboard(projId, callsign, dashboardId);
    return this.router.navigateByUrl(`/${url}`);
  }

  public robotDetailsControls(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.controls(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotDetailsButtons(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.buttons(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotSettings(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.settings(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotEvents(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.events(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotCommands(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.commands(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotControls(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.controls(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotButtons(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.buttons(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotStorageStreams(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.storageStreams(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotDataViewer(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.dataViewer(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotFiles(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.files(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotShell(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.shell(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotAutomate(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.automate(projId, callsign);
    this.openUrl(url, newTab);
  }

  public robotPage(projId: string, callsign: string, page: string, newTab: boolean = false) {
    const url = urls.robots.page(projId, callsign, page);
    this.openUrl(url, newTab);
  }

  public robotGlobalOperationSettings(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robots.settings(projId, callsign);
    const serializedUrl = this.router
      .serializeUrl(
        this.router.createUrlTree([url], {
          queryParams: {
            tab: this.robotGlobalOperationSettingTabIndex,
          },
        }),
      )
      .substr(1); // Remove first slash ("/") to make it as a relative path
    this.openUrl(serializedUrl, newTab);
  }

  public streamInfo(projId: string, streamId: string) {
    const url = urls.streams.info(projId, streamId);
    this.router.navigateByUrl(`/${url}`);
  }

  public projectIndex(projId: string) {
    const url = urls.project.index(projId);
    this.router.navigateByUrl(`/${url}`);
  }

  public robotDefinitionIndex(projId: string) {
    const url = urls.robotDefinitions.index(projId);
    this.router.navigateByUrl(`/${url}`);
  }

  public robotDefinitionDetails(projId: string, defId: string) {
    const url = urls.robotDefinitions.details(projId, defId);
    this.router.navigateByUrl(`/${url}`);
  }

  public robotDefinitionSettings(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.settings(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionEvents(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.events(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionCommands(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.commands(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionControls(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.controls(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotFileTransferDoc() {
    const url = 'https://docs-automate.dronedeploy.com/robotics-toolkit/agent-plugins/file-transfer';
    RouterUtils.openExternalUrl(`${url}`);
  }

  public robotRemoteShellDoc() {
    const url = 'https://docs-automate.dronedeploy.com/robotics-toolkit/agent-plugins/shell';
    RouterUtils.openExternalUrl(`${url}`);
  }

  public robotDefinitionButtons(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.buttons(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotGamepads(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robots.gamepads(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionGamepads(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.gamepads(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionStorageStreams(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.storageStreams(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionDashboards(projId: string, defId: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.dashboards(projId, defId);
    this.openUrl(url, newTab);
  }

  public robotDefinitionDashboard(projId: string, callsign: string, dashboardId: string) {
    const url = urls.robotDefinitions.dashboard(projId, callsign, dashboardId);
    return this.router.navigateByUrl(`/${url}`);
  }

  public robotDefinitionGlobalOperationSettings(projId: string, callsign: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.settings(projId, callsign);
    const serializedUrl = this.router
      .serializeUrl(
        this.router.createUrlTree([url], {
          queryParams: {
            tab: this.robotProfileGlobalOperationSettingTabIndex,
          },
        }),
      )
      .substr(1); // Remove first slash ("/") to make it as a relative path
    this.openUrl(serializedUrl, newTab);
  }

  public robotDefinitionPage(projId: string, defId: string, page: string, newTab: boolean = false) {
    const url = urls.robotDefinitions.page(projId, defId, page);
    this.openUrl(url, newTab);
  }

  public operationList(projId: string) {
    const url = urls.operations.list(projId);
    this.router.navigateByUrl(`/${url}`);
  }

  public operationDetail(projId: string, pageId: string, pageType: string) {
    const url = urls.operations.detail(projId, pageId, pageType);
    this.router.navigateByUrl(`/${url}`);
  }

  public functionDetail(projectId: string, functionId: string) {
    const url = urls.functions.details(projectId, functionId);
    this.router.navigateByUrl(`/${url}`);
  }

  public storageStreamsIndex(projectId: string) {
    const url = urls.storageStreams.index(projectId);
    this.router.navigateByUrl(`/${url}`);
  }

  public storageExplorerIndex(projectId: string) {
    const url = urls.storageExplorer.index(projectId);
    this.router.navigateByUrl(`/${url}`);
  }

  public switchAccountPage() {
    const url = urls.switchAccount.index();

    this.router.navigate(['/', url], {
      queryParamsHandling: 'merge',
    });
  }

  public agentCallback() {
    const url = urls.agentCallback();

    this.router.navigate(['/', url], {
      queryParamsHandling: 'merge',
    });
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
    localStorage.setItem(KEY_REDIRECT_URL, url);
  }

  public getRedirectUrl(): string | undefined {
    if (!this.redirectUrl) {
      this.redirectUrl = localStorage.getItem(KEY_REDIRECT_URL);
    }
    return this.redirectUrl;
  }

  public clearRedirectUrl(): void {
    this.redirectUrl = undefined;
    localStorage.removeItem(KEY_REDIRECT_URL);
  }

  public workflowList(projId: string, newTab: boolean = false) {
    const url = urls.automate.index(projId);
    this.openUrl(url, newTab);
  }

  public workflowDetail(projId: string, workflowId: string, newTab: boolean = false) {
    const url = urls.automate.detail(projId, workflowId);
    this.openUrl(url, newTab);
  }

  public logout(sessionExpired?: boolean) {
    if (sessionExpired) {
      this.setRedirectUrl(location.pathname);
      this.login();
      return;
    }

    this.openDroneDeployLogout();
  }

  public login() {
    this.openDroneDeployLogin();
  }

  public openDroneDeployLogout() {
    RouterUtils.openExternalUrl(`${environment.links.dd}/app2/signout`, '_self');
  }

  public openDroneDeployProject(projectId: string) {
    RouterUtils.openExternalUrl(`${environment.links.dd}/app2/sites/${projectId}`);
  }

  private openUrl(url: string, newTab: boolean = false) {
    if (newTab) {
      RouterUtils.openExternalUrl(`/${url}`, '_blank');
    } else {
      this.router.navigateByUrl(`/${url}`);
    }
  }

  private openDroneDeployLogin() {
    let redirect: string;
    if (environment.fullyQualifiedLoginRedirectsEnabled) {
      // use fully qualified redirects if not in production
      redirect = new URL(location.origin).toString();
    }

    const query = redirect ? `?next=${redirect}` : '';
    RouterUtils.openExternalUrl(`${environment.links.dd}/app2/auth/dd-automate-signin${query}`, '_self');
  }
}
