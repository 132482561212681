export class StreamConfig {
  /**
   * The name of this config
   */
  public name: string;

  /**
   * The type of this config
   */
  public type: string;

  /**
   * The logo image url for this type of source
   */
  public logoURL: string;

  /**
   * Topic of this config
   */
  public topic: string;

  /**
   * Is this source enabled or not?
   */
  public enabled = false;

  public static fromModel(model: any): StreamConfig {
    const config = new StreamConfig();

    config.name = model.name;
    config.type = model.type;
    config.logoURL = model.logoURL;
    config.topic = model.topic;
    config.enabled = model.enabled;

    return config;
  }
}
