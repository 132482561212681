import type { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { WidgetLineGroup } from '../../models';
import { StreamDateFilterConfig } from '../../models';
import type { ChartSeries } from '../../widgets';
import { WidgetBaseComponent } from '../shared';
import { WidgetMultipleLinesChartComponent } from '../widget-multiple-lines-chart';

@Component({
  selector: 'app-widget-line-graph',
  templateUrl: './widget-line-graph.component.html',
  styleUrls: ['./widget-line-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetLineGraphComponent extends WidgetBaseComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart')
  chart: WidgetMultipleLinesChartComponent;

  @Input() lineGroups: WidgetLineGroup[];
  @Input() value: any;
  /**
   * Update this value will directly update the chart, no delay.
   */
  @Input() rawValue: any;
  @Input() demoMode: boolean = false;

  @Input() liveDataMode: boolean = true;

  @Input() override width: string = '100%';
  @Input() override height: string = '100%';
  @Input() chartHeight: string = '378px';

  @Input() rangeMinimumValue: number;
  @Input() rangeMaximumValue: number;

  /**
   * Slow lane line graph date filter.
   */
  @Input() dateFilter: StreamDateFilterConfig;

  @Output()
  refreshClicked = new EventEmitter<void>();

  viewIsReady: boolean;
  private isLineGroupsLoaded: boolean = false;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.viewIsReady) {
      // View is not ready yet, don't do anything
      return;
    }
    if (changes['lineGroups']) {
      if (this.lineGroups?.length > 0) {
        if (this.demoMode) {
          this.loadDemoData();
        } else {
          this.updateLineGroups();
        }
      }
    }

    if (changes['value']) {
      this.addNewValue(this.value);
    }

    if (changes['rawValue']) {
      this.addNewValueDirectly(this.rawValue);
    }
  }

  ngAfterViewInit() {
    this.viewIsReady = true;
    if (this.demoMode) {
      this.loadDemoData();
    }
  }

  onRefreshChart() {
    this.refreshClicked.next();
  }

  public removeDataByGroupIndex(groupIndex) {
    this.chart.removeDataByGroupIndex(groupIndex);
  }

  public clearGraph() {
    this.chart.clearAllData();
    this.isLineGroupsLoaded = false;
  }

  private addNewValue(newValue: any) {
    if (!this.isLineGroupsLoaded) {
      this.updateLineGroups();
    }

    this.chart.addNewValue(newValue);
  }

  private addNewValueDirectly(newValue: any) {
    if (!this.isLineGroupsLoaded) {
      this.updateLineGroups();
    }

    this.chart.addNewValueDirectly(newValue);
  }

  private updateLineGroups() {
    if (!this.chart) {
      throw new Error('The chart component should be initialized first.');
    }

    // Clear previous data.
    this.chart.clearAllData();

    const chartSeries: ChartSeries[] = [];

    this.lineGroups.forEach((group) => {
      const id = `${group.groupIndex}`;

      // Series
      chartSeries.push({
        name: `${group.dataLabel}`,
        field: `value-${id}`,
        dateField: `date-${id}`,
        isAddToScrollbar: false,
        connect: true,
        color: group.lineColor,
      } as ChartSeries);
    });

    // Load and show.
    if (chartSeries?.length > 0) {
      if (this.chart.isChartInitialized) {
        this.chart.loadInitData([], chartSeries);

        this.isLineGroupsLoaded = true;
      }
    }
  }

  private loadDemoData() {
    if (!this.demoMode) {
      throw new Error('Should not load demo data under non-demo mode.');
    }
    if (!this.chart) {
      throw new Error('The chart component should be initialized first.');
    }

    // Clear previous data.
    this.chart.clearAllData();

    const getDemoTimeRange = () => {
      const timeRange = 60; // Seconds
      const list = [];
      const time = new Date().getTime();

      for (let i = timeRange; i > 0; i--) {
        list.push(new Date(time - i * 1000));
      }

      return list;
    };

    // Ref: https://stackoverflow.com/a/19303725
    const random = (seed: number) => {
      const x = Math.sin(Math.pow(seed, 2)) * 7919;
      return x - Math.floor(x);
    };

    const demoTimeRange = getDemoTimeRange();

    const chartData = [];
    const chartSeries: ChartSeries[] = [];

    let randomSeed = 0;
    this.lineGroups.forEach((group, idx) => {
      const dates = demoTimeRange;
      let value = 0;
      const offset = 2;

      const id = `${idx}`;

      // Data
      dates.forEach((date) => {
        value = value + offset * (random(randomSeed++) - 0.5);
        chartData.push({
          [`date-${id}`]: date,
          [`value-${id}`]: value,
        });
      });

      // Series
      chartSeries.push({
        name: `${group.dataLabel}`,
        field: `value-${id}`,
        dateField: `date-${id}`,
        isAddToScrollbar: false,
        connect: true,
        color: group.lineColor,
      } as ChartSeries);
    });

    // Load and show.
    if (chartData?.length > 0 && chartSeries?.length > 0) {
      if (this.chart.isChartInitialized) {
        this.chart.loadInitData(chartData, chartSeries);
      }
    }
  }
}
