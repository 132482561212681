import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Project } from '@shared/models';
import { AppService, GotoService, ProjectService } from '@shared/services';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-navbar-dd-link',
  templateUrl: './navbar-dd-link.component.html',
  styleUrls: ['./navbar-dd-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarDDLinkComponent implements OnInit {
  public projectId: string;
  public project: Project;

  constructor(
    private appService: AppService,
    private projectService: ProjectService,
    private cdr: ChangeDetectorRef,
    private goto: GotoService,
  ) {}

  ngOnInit() {
    this.appService.projectIdChange.pipe(untilDestroyed(this)).subscribe((projId: string) => {
      this.loadProjectInfo(projId);
    });

    this.projectId = this.appService.projectId;
    if (this.projectId) {
      this.loadProjectInfo(this.projectId);
    }
  }

  public openWithDroneDeploy() {
    this.goto.openDroneDeployProject(this.project.linkedProjectId);
  }

  private loadProjectInfo(projectId: string) {
    this.projectService
      .info(projectId)
      .pipe(first(), untilDestroyed(this))
      .subscribe((project) => {
        this.project = project;
        this.cdr.markForCheck();
      });
  }
}
