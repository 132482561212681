import type { RobotType } from '../robot';

export type TreeItemType = 'root' | 'plugin' | 'object' | 'attribute' | 'service' | 'folder';
export type TreeItemDataType =
  | 'array'
  | 'boolean'
  | 'number'
  | 'integer'
  | 'object'
  | 'string'
  | 'unknown'
  | 'malformed';
export type TreeItemStatus = 'expanded' | 'collapsed';

export interface ITreeItem {
  id: string;
  text: string;
  itemType: TreeItemType;
  children: ITreeItem[];
}

export interface ITreeItemJSONSchema {
  [pluginName: string]: {
    definitions: ITreeItemJSONSchemaDef;
  }[];
}

export interface ITreeItemJSONSchemaDefContent {
  type?: TreeItemDataType;
  required: string[];
  properties: ITreeItemJSONSchemaDef;
  items?: ITreeItemJSONSchemaDefContent;
}

export interface ITreeItemJSONSchemaDef {
  [defName: string]: ITreeItemJSONSchemaDefContent;
}

export type RichSchemaItemSchemaType = 'object' | 'string' | 'unknown' | 'malformed';

export class RichSchemaItemSchema {
  title: string;
  type: RichSchemaItemSchemaType;

  parentPath: string;
  name: string;

  properties?: {
    [name: string]: RichSchemaItemSchema;
  };

  static fromModel(model: any, currentKey: string, parentPath?: string): RichSchemaItemSchema {
    if (!model) {
      return null;
    }

    if (!model.title && model.$id) {
      model.title = model.$id;
    }

    if (!model.title && currentKey && parentPath) {
      model.title = parentPath + '/' + currentKey;
    } else if (!model.title && currentKey) {
      model.title = currentKey;
    }

    if (!model.title) {
      return null;
    }

    const removeEndN = (path: string) => {
      const endsWith = '/[n]';
      if (path.endsWith(endsWith)) {
        path = path.replace(endsWith, '');
      }

      return path;
    };

    const schema = new RichSchemaItemSchema();

    schema.title = model.title;
    schema.type = model.type || 'unknown';

    schema.name = model.title.replace(`${parentPath}/`, '');
    schema.parentPath = parentPath;

    schema.properties = {};

    if (model.properties) {
      Object.keys(model.properties).forEach((key) => {
        const child = RichSchemaItemSchema.fromModel(model.properties[key], key, schema.title);
        if (child) {
          schema.properties[key] = child;
        }
      });
    }

    schema.title = removeEndN(schema.title);
    schema.name = removeEndN(schema.name);

    return schema;
  }
}

// Schema from Richard
export class RichSchemaItem {
  source: string;
  /**
   * Schema JSON string
   */
  schema: string;

  private _schemaJSON: RichSchemaItemSchema;
  get schemaJSON(): RichSchemaItemSchema {
    if (!this._schemaJSON) {
      this._schemaJSON = this.parseSchemaJSON();
    }

    return this._schemaJSON;
  }

  private _pluginName: string;
  get pluginName(): string {
    if (!this._pluginName) {
      if (this.source) {
        const parts = this.source.split('/');
        if (parts?.length > 1) {
          this._pluginName = parts.slice(1, 2).join('/');
        } else {
          this._pluginName = this.source;
        }
      } else {
        this._pluginName = this.source;
      }
    }

    return this._pluginName;
  }

  static fromModel(model: any): RichSchemaItem {
    const item = new RichSchemaItem();

    item.source = model.source;
    item.schema = model.schema;

    return item;
  }

  private parseSchemaJSON(): RichSchemaItemSchema {
    let schemaObject;
    try {
      schemaObject = JSON.parse(this.schema);
    } catch (e) {
      console.error('Fail to parse tree schema', {
        source: this.source,
        err: e,
      });
    }

    if (!schemaObject) {
      schemaObject = {
        $id: this.source,
        type: 'malformed',
      };
    }

    try {
      return RichSchemaItemSchema.fromModel(schemaObject, null, `/${this.pluginName}`);
    } catch (e) {
      console.error(`Fail to load RichSchema from model`, {
        source: this.source,
        err: e,
      });

      const richSchemaItemSchema = new RichSchemaItemSchema();
      richSchemaItemSchema.name = this.source.replace(`${this.pluginName}/`, '');
      richSchemaItemSchema.title = this.source;
      richSchemaItemSchema.type = 'malformed';
      richSchemaItemSchema.parentPath = `/${this.pluginName}`;
      return richSchemaItemSchema;
    }
  }
}

export class PathWithCountItem {
  path: string;
  count: number;
}

export class TreeItem implements ITreeItem {
  public id: string; // e.g. attitude
  public path: string; // e.g. /mavlink/attitude
  public text: string; // e.g. Attitude
  public querystring: string = '';
  public extraInfo: string[] = [];
  public itemType: TreeItemType;
  public isSelectable: boolean;
  public isInsertable: boolean;
  public layerLevel: number;
  public status: TreeItemStatus;
  public children: TreeItem[] = [];
  public childrenDict: any;
  public parent?: TreeItem;
  public selected: boolean = false;
  public dataType: TreeItemDataType;
  public hiddenChildren: boolean = false;
  public showPerfMon: boolean = false;
  public showStreamQuery: boolean = false;
  /**
   * Root source (topic) of current item.
   */
  public rootSource: string;

  public selectedChildrenCount: number = 0;
  /** Selected items (includes current item) */
  public selectedItems: TreeItem[] = [];

  public get hasChildren(): boolean {
    return this.children?.length > 0;
  }

  public get isExpanded(): boolean {
    return this.status === 'expanded';
  }

  public get isCollapsed(): boolean {
    return this.status === 'collapsed';
  }

  /**
   * Return icon CSS class name
   */
  public get iconClass(): string {
    let icon = 'ri-value';
    switch (this.itemType) {
      case 'folder':
        icon = 'ri-folder';
        break;
      case 'service':
        icon = 'ri-service';
        break;
      case 'plugin':
        icon = 'ri-plugin';
        break;
      case 'object':
        icon = 'ri-message';
        break;
      case 'attribute':
        // Switch data type for this level
        switch (this.dataType) {
          case 'integer':
          case 'object':
          case 'array':
          case 'string':
            icon = `ri-${this.dataType}`;
            break;
          case 'number':
            icon = `ri-float`;
            break;
          default:
            icon = 'ri-value';
            break;
        }
        break;
    }

    return icon;
  }

  public get prefix(): string {
    const suffixCount = this.id.split('/').length;
    const prefix = this.path.split('/').slice(0, -suffixCount).join('/');

    return `${prefix}/`;
  }

  public get source(): string {
    if (this.rootSource) {
      return this.rootSource;
    } else if (this.itemType === 'attribute') {
      return this.path.split('/').slice(0, -1).join('/');
    } else if (this.itemType === 'object') {
      return this.path;
    }

    return null;
  }

  //#region Load From Models

  public static fromModel(model: ITreeItem, level = 0, parent?: TreeItem): TreeItem {
    const item = new TreeItem();

    item.id = model.id;
    item.text = model.text;
    item.itemType = model.itemType;
    item.isSelectable = model.itemType === 'object' || model.itemType === 'attribute';
    item.isInsertable = model.itemType === 'object' || model.itemType === 'attribute';
    item.layerLevel = level;
    item.status = 'collapsed';

    if (parent) {
      const parentPath = parent.path ? parent.path : '';
      item.path = `${parentPath}/${item.id}`;

      // Add parent.
      item.parent = parent;
    }

    if (model.children) {
      item.children = model.children.map((child) => {
        return TreeItem.fromModel(child, level + 1, item);
      });
    }

    return item;
  }

  public static fromRichJSONSchemaSources(
    sources: RichSchemaItem[],
    existingTree?: TreeItem,
    itemType: TreeItemType = null,
    dataStreamMode = false,
  ): TreeItem {
    if (existingTree) {
      if (sources?.length > 0) {
        sources.forEach((source) => {
          existingTree.appendNewRichSourceV2(source, itemType, dataStreamMode);
        });

        // Sort topics in plugin
        if (existingTree.children?.length > 0) {
          existingTree.children.forEach((plugin) => {
            plugin.sortChildrenByText();
          });
          existingTree.sortChildrenByText();
        }
      }

      return existingTree;
    }

    const tree = new TreeItem();

    // Root, Level 0
    tree.id = '';
    tree.text = '';
    tree.itemType = 'root';
    tree.layerLevel = 0;
    tree.path = '';

    const treeObj: {
      [pluginName: string]: RichSchemaItem[];
    } = {} as any;

    if (sources?.length > 0) {
      sources.forEach((source) => {
        if (!treeObj[source.pluginName]) {
          treeObj[source.pluginName] = [];
        }

        treeObj[source.pluginName].push(source);
      });
    }

    // treeObj is the root - first level
    // each key of the treeObj is a plugin - second level

    Object.keys(treeObj).forEach((pluginName, _index) => {
      const plugin = new TreeItem();

      plugin.id = pluginName;
      plugin.text = pluginName;
      plugin.itemType = 'plugin';
      plugin.status = 'collapsed';
      plugin.layerLevel = 1;
      plugin.path = `/${pluginName}`;
      plugin.parent = tree;

      if (treeObj[pluginName] && treeObj[pluginName].length > 0) {
        // Loop sources
        treeObj[pluginName].forEach((source) => {
          // This create a multi level layout, folders are created.
          const sourceKeys = source.source.split('/').filter((x) => x);
          this.addToTree(plugin, sourceKeys.slice(1), source, 2, itemType, dataStreamMode);
        });
      }

      plugin.sortChildrenByText();

      tree.children.push(plugin);
      tree.sortChildrenByText();
      if (!tree.childrenDict) {
        tree.childrenDict = {};
      }
      tree.childrenDict[pluginName] = plugin;
    });

    return tree;
  }

  public static addToTree(
    parent: TreeItem,
    keySegments: Array<string>,
    source: RichSchemaItem,
    level: number,
    itemType: TreeItemType,
    dataStreamMode: boolean,
  ): void {
    if (!keySegments || keySegments.length === 0) {
      return;
    }

    let item;

    if (!parent.childrenDict) {
      parent.childrenDict = {};
    }

    let firstKeySegment;
    if (keySegments.length === 1) {
      // This is a leaf, create a node
      firstKeySegment = keySegments[0];
      try {
        item = TreeItem.fromRichJSONSchemaObjV2(
          source.schemaJSON,
          level,
          source.source,
          itemType,
          firstKeySegment,
          false,
          dataStreamMode,
        );
      } catch (e) {
        console.error('Fail to create tree item from rich json schema object', {
          source: source.source,
          err: e,
        });
      }

      if (!item) {
        return;
      }

      const folderItem = parent.childrenDict[firstKeySegment];
      if (folderItem) {
        // folder exist, reconstruct the parent children relationship
        item.children = folderItem.children;
        item.childrenDict = folderItem.childrenDict;
        if (item.children) {
          item.children.forEach((x) => (x.parent = item));
        }

        // remove folder item from tree
        parent.children = parent.children.filter((x) => x !== folderItem);
      }
      item.parent = parent;
      parent.childrenDict[firstKeySegment] = item;
      parent.children.push(item);
    } else {
      firstKeySegment = keySegments[0];
      // This is not a leaf, create a folder node if not exist
      item = parent.childrenDict[firstKeySegment];
      if (!item) {
        try {
          item = TreeItem.fromRichJSONSchemaObjV2(
            source.schemaJSON,
            level,
            source.source,
            'folder',
            firstKeySegment,
            false,
            dataStreamMode,
          );
        } catch (e) {
          console.error('Fail to create tree item from rich json schema object', {
            source: source.source,
            err: e,
          });
        }

        if (!item) {
          return;
        }

        item.parent = parent;
        // children and childrenDict share the same item reference.
        parent.children.push(item);
        parent.childrenDict[firstKeySegment] = item;
      }

      this.addToTree(item, keySegments.slice(1), source, level + 1, itemType, dataStreamMode);
    }
  }

  public static fromRichJSONSchemaObjV2(
    richSchemaItemSchema: RichSchemaItemSchema,
    level: number,
    rootSource: string,
    itemType: TreeItemType,
    key: string,
    isAttribute: boolean,
    dataStreamMode: boolean,
  ): TreeItem {
    const treeItem = new TreeItem();

    if (!richSchemaItemSchema) {
      return null;
    }

    treeItem.id = richSchemaItemSchema.name;
    treeItem.text = key;
    treeItem.itemType = 'object';

    if (isAttribute) {
      treeItem.itemType = 'attribute';
    }

    if (itemType === 'service' || itemType === 'folder') {
      treeItem.itemType = itemType;
    }

    treeItem.dataType = richSchemaItemSchema.type;

    treeItem.isSelectable = true;

    if (dataStreamMode && treeItem.itemType === 'attribute') {
      treeItem.isInsertable = false;
    } else if (itemType === 'folder') {
      treeItem.isSelectable = false;
      treeItem.isInsertable = false;
    } else {
      treeItem.isInsertable = true;
    }

    treeItem.layerLevel = level;
    treeItem.status = 'collapsed';
    treeItem.path = richSchemaItemSchema.title;

    treeItem.rootSource = rootSource;

    const properties = richSchemaItemSchema.properties;

    treeItem.children = [];
    // When in dataStreamMode, only topics will be displayed no children should be added.
    if (itemType !== 'folder' && properties && (!dataStreamMode || (dataStreamMode && level < 2))) {
      Object.keys(properties).forEach((propertiKey, _index) => {
        // resursively call the same method, children are properties.
        const child = TreeItem.fromRichJSONSchemaObjV2(
          properties[propertiKey],
          level + 1,
          rootSource,
          itemType,
          properties[propertiKey].name,
          true,
          dataStreamMode,
        );

        // Add parent.
        child.parent = treeItem;

        treeItem.children.push(child);
      });
    }

    return treeItem;
  }

  public static fromJSONSchema(schema: ITreeItemJSONSchema, robotType?: RobotType, dataStreamMode = false): TreeItem {
    const item = new TreeItem();

    // Root, Level 0
    item.id = '';
    item.text = '';
    item.itemType = 'root';
    item.layerLevel = 0;
    item.path = '';

    // Plugins, Level 1
    for (const pluginName in schema) {
      if (Object.prototype.hasOwnProperty.call(schema, pluginName)) {
        // TODO #405
        // A hacker way to filter plugins for now.
        if (robotType === 'mavlink' && pluginName === 'rosbridge') {
          continue;
        }
        if (robotType === 'ros' && pluginName === 'mavlink') {
          continue;
        }
        const objects = schema[pluginName];

        const pluginItem = new TreeItem();
        pluginItem.id = pluginName;
        pluginItem.text = pluginName;
        pluginItem.itemType = 'plugin';
        pluginItem.layerLevel = 1;
        pluginItem.status = 'collapsed';
        pluginItem.path = `${item.path}/${pluginItem.id}`;
        pluginItem.parent = item;

        if (objects?.length > 0) {
          objects.forEach((obj) => {
            const defs = obj.definitions;
            const defItems = TreeItem.fromJSONSchemaDefs(defs, 2, pluginItem.path, dataStreamMode);
            if (defItems) {
              defItems.forEach((defItem) => {
                // Update parent property.
                defItem.parent = pluginItem;
              });

              pluginItem.children.push(...defItems);
            }
          });
        }

        // Sort objects' name
        if (pluginItem.children?.length > 0) {
          pluginItem.children.sort((a, b) => {
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          });
        }

        item.children.push(pluginItem);
      }
    }

    return item;
  }

  public static fromJSONSchemaDefs(
    defs: ITreeItemJSONSchemaDef,
    level = 2,
    parentPath: string,
    dataStreamMode = false,
  ): TreeItem[] {
    const result = [];

    for (const objName in defs) {
      if (Object.prototype.hasOwnProperty.call(defs, objName)) {
        const def = defs[objName];

        // If no type for definition, set as 'object'
        if (def?.required && def.properties && !def.type) {
          def.type = 'object';
        }

        const item = new TreeItem();
        // Def name from back-end for level 2 should be lower cased
        item.id = level > 2 ? objName : objName.toLowerCase();
        item.text = objName;
        item.itemType = def?.type === 'object' ? 'object' : 'attribute';
        item.dataType = def?.type ? def.type : 'unknown';

        item.isSelectable = true;
        item.isInsertable = item.itemType !== 'object';

        item.layerLevel = level;
        item.status = 'collapsed';
        item.path = `${parentPath}/${item.id}`;

        const properties = def.properties;

        let children: TreeItem[] = [];
        if (item.dataType === 'array') {
          children = TreeItem.fromJSONSchemaDefs(def.items.properties, level + 1, item.path);
        } else {
          children = TreeItem.fromJSONSchemaDefs(properties, level + 1, item.path);
        }

        // Update parent property.
        if (children) {
          children.forEach((child) => {
            child.parent = item;
          });
        }

        item.children = children;

        if (item.children?.length > 0) {
          item.children.sort((a, b) => {
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          });
        }

        /**
         * If it is data stream mode, hide children
         */
        if (dataStreamMode) {
          // Enable insertable for data stream mode
          item.isInsertable = true;
          item.hiddenChildren = true;
        }

        result.push(item);
      }
    }

    if (result?.length > 0) {
      result.sort((a, b) => {
        return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      });
    }

    return result;
  }

  public static fromPathList(paths: string[], itemType?: TreeItemType, onlyLeafSelectable = false): TreeItem {
    const tree = new TreeItem();

    // Root, Level 0
    tree.id = '';
    tree.text = '';
    tree.itemType = 'root';
    tree.layerLevel = 0;
    tree.path = '';

    const treeObj = {} as any;

    // Ref: https://codereview.stackexchange.com/a/185408
    if (paths?.length > 0) {
      paths.forEach((path) => {
        let parts = path.split('/');
        if (parts?.length > 1) {
          parts = parts.slice(1);

          // 'rosbridge' special way, treat the parts after plugin name as a whole topic name
          if (parts[0] === 'rosbridge') {
            const pluginName = parts[0];
            const topicName = parts.slice(1).join('/');
            if (!treeObj[pluginName]) {
              treeObj[pluginName] = {};
            }
            if (!treeObj[pluginName][topicName]) {
              treeObj[pluginName][topicName] = {};
            }
          } else {
            let tempObj = treeObj;
            parts.forEach((part) => {
              if (!tempObj[part]) {
                tempObj[part] = {};
              }

              tempObj = tempObj[part];
            });
          }
        }
      });
    }

    const children = TreeItem.fromTreeObj(treeObj, 1, '', onlyLeafSelectable);

    // Update parent property.
    if (children) {
      children.forEach((child) => {
        child.parent = tree;
      });
    }
    tree.children = children;

    return tree;
  }

  public static fromPathWithCountList(list: PathWithCountItem[]): TreeItem {
    const tree = new TreeItem();

    // Root, Level 0
    tree.id = '';
    tree.text = '';
    tree.itemType = 'root';
    tree.layerLevel = 0;
    tree.path = '';

    const treeObj = {} as any;

    if (list?.length > 0) {
      list.forEach((item) => {
        const path = item.path;
        const count = item.count;
        let parts = path.split('/');
        if (parts?.length > 1) {
          parts = parts.slice(1);

          const pluginName = parts[0];
          const topicName = parts.slice(1).join('/');

          if (!treeObj[pluginName]) {
            treeObj[pluginName] = {};
          }
          if (!treeObj[pluginName][topicName]) {
            treeObj[pluginName][topicName] = {
              path,
              count,
            };
          }
        }
      });
    }

    // Plugin level
    for (const pluginName in treeObj) {
      if (Object.prototype.hasOwnProperty.call(treeObj, pluginName)) {
        const topicsObj = treeObj[pluginName];

        const item = new TreeItem();

        item.id = pluginName;
        item.text = pluginName;
        item.itemType = 'plugin';
        item.dataType = 'unknown';

        item.isSelectable = false;
        item.isInsertable = false;

        item.layerLevel = 1;
        item.status = 'collapsed';
        item.path = `/${item.id}`;

        item.parent = tree;

        const children = [];
        let sum = 0;

        // Topic level
        for (const topicName in topicsObj) {
          if (Object.prototype.hasOwnProperty.call(topicsObj, topicName)) {
            const topicObj = topicsObj[topicName];

            const topicItem = new TreeItem();

            topicItem.id = topicName;
            topicItem.text = `${topicName} (${topicObj.count})`;
            topicItem.itemType = 'object';
            topicItem.dataType = 'unknown';

            topicItem.isSelectable = true;
            topicItem.isInsertable = false;

            topicItem.layerLevel = 2;
            topicItem.status = 'collapsed';
            topicItem.path = topicObj.path;

            topicItem.parent = item;

            children.push(topicItem);

            sum += topicObj.count;
          }
        }

        item.text = `${item.text} (${sum})`;

        item.children = children;

        if (item.children?.length > 0) {
          item.children.sort((a, b) => {
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          });
        }

        tree.children.push(item);
      }
    }

    return tree;
  }

  public static fromTreeObj(obj, level = 1, parentPath: string = '', onlyLeafSelectable = false): TreeItem[] {
    const result = [];

    for (const objName in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, objName)) {
        const def = obj[objName];

        const item = new TreeItem();

        item.id = objName;
        item.text = objName;

        if (level === 1) {
          item.itemType = 'plugin';
        } else {
          item.itemType = 'object';
        }

        item.dataType = 'unknown';

        item.isSelectable = level > 1;
        item.isInsertable = item.itemType !== 'plugin';

        item.layerLevel = level;
        item.status = 'collapsed';
        item.path = `${parentPath}/${item.id}`;

        const children = TreeItem.fromTreeObj(def, level + 1, item.path, onlyLeafSelectable);
        // Update parent property.
        if (children) {
          children.forEach((child) => {
            child.parent = item;
          });
        }
        item.children = children;

        // If only leaf selectable and it has children,
        // then should not selectable
        if (onlyLeafSelectable && item.children.length > 0) {
          item.isSelectable = false;
        }

        if (item.children?.length > 0) {
          item.children.sort((a, b) => {
            return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
          });
        }

        result.push(item);
      }
    }

    if (result?.length > 0) {
      result.sort((a, b) => {
        return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      });
    }

    return result;
  }

  //#endregion

  public toggleStatus() {
    if (this.status === 'expanded') {
      this.status = 'collapsed';
    } else {
      this.status = 'expanded';
    }
  }

  public toggleSelected() {
    this.selected = !this.selected;

    this.updateSelectedItems();
  }

  public select() {
    this.selected = true;

    this.updateSelectedItems();
  }

  public unselect() {
    this.selected = false;

    this.updateSelectedItems();
  }

  public getSelectedItems(includeItself = true): TreeItem[] {
    const list = [];
    if (this.selected && includeItself) {
      list.push(this);
    }

    if (this.children?.length > 0) {
      this.children.forEach((child) => {
        const selected = child.getSelectedItems();

        list.push(...selected);
      });
    }

    return list;
  }

  public getDirectChildrenSelectedItems(): TreeItem[] {
    const list = [];

    if (this.children?.length > 0) {
      this.children.forEach((child) => {
        if (child.selected) {
          list.push(child);
        }
      });
    }

    return list;
  }

  public updateSelectedChildrenCount() {
    this.selectedChildrenCount = this.getSelectedItems(false).length;
  }

  /**
   * Update selected count until root level.
   * Will not recalculate the counts from children nodes.
   */
  public updateSelectedCountUntilRoot(includeCurrentNode: boolean = false) {
    let count = 0;

    if (this.children) {
      this.children.forEach((child) => {
        count += child.selectedChildrenCount;
        count += child.selected ? 1 : 0;
      });
    }

    if (includeCurrentNode && this.selected) {
      count += 1;
    }

    this.selectedChildrenCount = count;

    if (this.parent) {
      this.parent.updateSelectedCountUntilRoot();
    }
  }

  public clearAllSelection() {
    this.selected = false;
    this.selectedChildrenCount = 0;
    this.selectedItems = [];

    if (this.children?.length > 0) {
      this.children.forEach((child) => {
        child.clearAllSelection();
      });
    }
  }

  public getChildrenSchema() {
    let schema = {};
    if (this.children) {
      this.children.forEach((child) => {
        if (child.hasChildren) {
          schema[child.id] = child.getChildrenSchema();
        } else {
          schema[child.id] = child.dataType;
        }
      });
    }

    // Add brackets for array type.
    if (this.dataType === 'array') {
      schema = [schema];
    }

    return schema;
  }

  public getItemsFromRootToCurrent(): TreeItem[] {
    const getParentUntilTheTopicLevel = (item: TreeItem): TreeItem[] => {
      const items = [];

      if (item?.parent) {
        items.push(item);

        const newItems = getParentUntilTheTopicLevel(item.parent);
        items.push(...newItems);
      } else if (item) {
        items.push(item);
      }

      return items;
    };

    const result = getParentUntilTheTopicLevel(this);
    return result.reverse();
  }

  public getIdsFromTopicToCurrent(itemsFromRoot?: TreeItem[]): string[] {
    const ids: string[] = [];

    // Set items first.
    if (itemsFromRoot === undefined) {
      itemsFromRoot = this.getItemsFromRootToCurrent();
    }

    if (itemsFromRoot?.length > 0) {
      itemsFromRoot.forEach((item) => {
        if (item.itemType === 'attribute') {
          ids.push(item.id);
        }
      });
    }

    return ids;
  }

  public sortChildrenByText() {
    // Sort objects' name
    if (this.children?.length > 0) {
      this.children.sort((a, b) => {
        return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      });
    }
  }

  /**
   * The hosting tree item should be a root node.
   *
   * @param source New source
   */
  public appendNewRichSourceV2(source: RichSchemaItem, itemType: TreeItemType, dataStreamMode: boolean) {
    const targetPluginName = source.pluginName;
    let plugin = this.childrenDict[targetPluginName];

    if (!plugin) {
      plugin = new TreeItem();
      plugin.id = targetPluginName;
      plugin.text = targetPluginName;
      plugin.itemType = 'plugin';
      plugin.status = 'collapsed';
      plugin.layerLevel = 1;
      plugin.path = `/${targetPluginName}`;
      plugin.parent = this;
      this.children.push(plugin);
      this.childrenDict[targetPluginName] = plugin;
    }

    const sourceKeys = source.source.split('/').filter((x) => x);
    TreeItem.addToTree(plugin, sourceKeys.slice(1), source, 2, itemType, dataStreamMode);
  }

  private updateSelectedItems(includeCurrentNode: boolean = false) {
    const list: TreeItem[] = [];

    // Update self
    if (includeCurrentNode && this.selected) {
      list.push(this);
    }

    // Update direct children.
    if (this.children) {
      this.children.forEach((child) => {
        list.push(...child.selectedItems);

        if (child.selected) {
          list.push(child);
        }
      });
    }

    // // Append selectedItems
    // if (this.selectedItems) {
    //   list.push(...this.selectedItems);
    // }

    this.selectedItems = list;

    // Call parent
    if (this.parent) {
      this.parent.updateSelectedItems();
    }
  }
}
