<ng-container *transloco="let tCommon; read: 'common'">
  <ng-container *ngIf="hasInvites">
    <app-loading-content [isLoading]="isLoading">
      <!-- With Invites -->
      <div class="with-invites-page" *ngIf="invitations && invitations.length > 0">
        <div class="tabs">
          <mat-tab-group class="wider-padding higher-padding" animationDuration="0ms">
            <mat-tab [label]="'Account & Project Invites'">
              <table mat-table [dataSource]="invitations">
                <!-- Sent From -->
                <ng-container matColumnDef="sentFrom">
                  <mat-header-cell *matHeaderCellDef> Invited By </mat-header-cell>
                  <mat-cell *matCellDef="let invitation"> {{ invitation.sentFrom }}</mat-cell>
                </ng-container>

                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
                  <mat-cell *matCellDef="let invitation"> {{ invitation.createdAt | date }}</mat-cell>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                  <mat-cell *matCellDef="let invitation">
                    <span class="">
                      {{ invitation.type | titlecase }}
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Accepted Status Column -->
                <ng-container matColumnDef="acceptedStatus">
                  <mat-header-cell *matHeaderCellDef> Accepted </mat-header-cell>
                  <mat-cell *matCellDef="let invitation">
                    <span *ngIf="invitation.accepted">
                      <span class="ri-checkmark text-success"></span>
                    </span>
                    <span *ngIf="!invitation.accepted && isExpired(invitation)">
                      <span class="ri-cancel text-danger"></span>
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- Accepted Column -->
                <ng-container matColumnDef="accepted">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let invitation">
                    <span *ngIf="!invitation.accepted && !isExpired(invitation)">
                      <app-loading-stroked-button [isLoading]="invitation.isLoading" (clicked)="onAccept(invitation)">
                        <span>Accept Invite</span>
                      </app-loading-stroked-button>
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsForReceived; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsForReceived" class="invite-row"></mat-row>
              </table>
            </mat-tab>

            <mat-tab [label]="'Create Account'">
              <div class="create-account-tab">
                <ng-container *ngTemplateOutlet="createAccountTemplate"></ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <!-- Without Invites -->
      <div class="without-invites-page" *ngIf="!invitations || invitations.length === 0">
        <ng-container *ngTemplateOutlet="createAccountTemplate"></ng-container>
      </div>
    </app-loading-content>
  </ng-container>

  <ng-template #createAccountTemplate>
    <div class="create-form">
      <div class="title">Create Account</div>
      <form [formGroup]="form">
        <mat-form-field>
          <input matInput type="text" formControlName="accountName" placeholder="Account Name" />
          <mat-error *ngIf="accountName?.errors?.['required']">{{ tCommon('requiredLabel') }}</mat-error>
        </mat-form-field>

        <div class="footer">
          <app-loading-flat-button [isLoading]="isCreatingAccount" (clicked)="onCreateAccount()">
            <span>Create Account</span>
          </app-loading-flat-button>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>
