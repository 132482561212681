import type { ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';
import { isBoolean, JSONValidator } from '@shared/validators';
import type { IManifestArgument } from '../../../services/workflow-gateway/workflow-gateway.type';

/**
 * Removes any invalid arguments from the schema and ensures it is valid
 */
export const sanitiseArgumentSchema = (schema: unknown): Record<string, IManifestArgument> => {
  // quick sanity checks
  if (typeof schema !== 'object' || schema === null || Array.isArray(schema)) return {};

  // remove invalid arguments
  for (const [argumentId, value] of Object.entries(schema)) {
    if (typeof value !== 'object' || value === null) {
      delete schema[argumentId];
      continue;
    }

    if (typeof value.type !== 'string') {
      delete schema[argumentId];
    }
  }

  return schema as Record<string, IManifestArgument>;
};

export const getValidatorsForType = (type: string): ValidatorFn[] => {
  if (type.startsWith('[]')) return [JSONValidator.json, JSONValidator.isArray];
  if (type.startsWith('map[')) return [JSONValidator.json];

  switch (type) {
    case 'bool':
      return [isBoolean];
    case 'JSONString':
    case 'interface{}':
      return [JSONValidator.json];
    case 'uint8':
      return [Validators.min(0), Validators.max(255)];
    case 'uint16':
      return [Validators.min(0), Validators.max(65535)];
    case 'uint64':
      return [Validators.min(0)];
    case 'int8':
      return [Validators.min(-128), Validators.max(127)];
    case 'int16':
      return [Validators.min(-32768), Validators.max(32767)];
    default:
      return [];
  }
};

export const getInputType = (type: string): string => {
  switch (type) {
    case 'int8':
    case 'int16':
    case 'int32':
    case 'int64':
    case 'uint8':
    case 'uint16':
    case 'uint32':
    case 'uint64':
    case 'float32':
    case 'float64':
      return 'number';
    default:
      return 'text';
  }
};

export const getInputError = (type: string, value: unknown): string => {
  switch (type) {
    case 'isBoolean':
      return 'Must be either true or false';
    case 'min':
      return `Cannot be less than ${(value as { min: number }).min}`;
    case 'max':
      return `Cannot be greater than ${(value as { max: number }).max}`;
    case 'json':
      return 'Must be a valid JSON string';
    case 'required':
      return 'Value is required';
    default:
      return 'Invalid value';
  }
};
