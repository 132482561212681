import type { IResolution } from './../../directives/coordinate-picker.directive';
import { WidgetBase } from './widget-base';

export class WidgetVideo extends WidgetBase {
  public videoServer?: string;
  public videoId: string;
  public videoSourceType?: string;
  public videoCommand?: string;
  public videoConfigMode?: 'basic' | 'advanced';
  public videoCameraId?: string;
  public videoResolution?: string;
  public playoutDelayHint?: string;
  public pickerCommandId?: string;
  public pickerCommandParameters?: Record<string, string>;
  public pickerVideoResolution?: IResolution;
  public pickerAnimation?: boolean;
  public rotation?: number;

  public static fromModel(model: Partial<WidgetVideo>): WidgetVideo {
    const widget = new WidgetVideo();

    widget.readValuesFromModel(model);

    return widget;
  }

  public override readValuesFromModel(model: Partial<WidgetVideo>): void {
    super.readValuesFromModel(model);

    // Gridster
    this.minItemCols = model.minItemCols ? model.minItemCols : 2;
    this.minItemRows = model.minItemRows ? model.minItemRows : 2;

    this.videoServer = model.videoServer;
    this.videoId = model.videoId;
    this.playoutDelayHint = model.playoutDelayHint;
    this.videoSourceType = model.videoSourceType;
    this.videoCommand = model.videoCommand;
    this.videoConfigMode = model.videoConfigMode;
    this.videoCameraId = model.videoCameraId;
    this.videoResolution = model.videoResolution;
    if (model.pickerCommandId) {
      this.pickerCommandId = model.pickerCommandId;
      this.pickerCommandParameters = model.pickerCommandParameters;
      this.pickerVideoResolution = model.pickerVideoResolution;
      this.pickerAnimation = model.pickerAnimation;
    }
    if (model.rotation) {
      this.rotation = Number(model.rotation);
    }
  }
}
