export class ConfigGroupItem<T> {
  id: string;
  value: T;
  _isOverridden?: boolean;
  _globalItem?: ConfigGroupItem<T>;
  name?: string;
  dashboardId?: string;
  profileId?: string;
  callsign?: string;
  index: string;

  get isRobotDefItem(): boolean {
    return !!this._globalItem || !this._isOverridden;
  }

  constructor(value?: T, isOverridden: boolean = false, id?: string) {
    this.id = id ? id : crypto.randomUUID();

    if (value) {
      this.value = value;
    }
    this._isOverridden = isOverridden;
  }

  static fromModel<T>(model: ConfigGroupItem<T>): ConfigGroupItem<T> {
    const item = new ConfigGroupItem<T>();

    item.id = model.id;
    item.value = model.value;
    item._isOverridden = model._isOverridden;
    item._globalItem = model._globalItem;

    return item;
  }

  static getValues<T>(items: ConfigGroupItem<T>[]): T[] {
    const values: T[] = [];

    if (items?.length > 0) {
      items.forEach((item) => {
        if (item?.value) {
          values.push(item.value);
        }
      });
    }

    return values;
  }

  static getOverriddenItems<T>(items: ConfigGroupItem<T>[]): ConfigGroupItem<T>[] {
    return items.filter((item) => {
      return item._isOverridden;
    });
  }
}
