import type { IAccount } from '../../interfaces';
import { Project } from '../project';
import { AccountUser } from './account-user';

export class Account implements IAccount {
  id: string;
  name: string;
  createdAt?: Date;
  permissions?: {
    [key: string]: boolean;
  };
  isOwner: boolean = false;

  projects: Project[];

  users: AccountUser[];

  constructor(name?: string, projects?: Project[]) {
    this.name = name ? name : '';
    this.projects = projects ? projects : [];
  }

  public static fromModel(model: IAccount): Account {
    const account = new Account();

    account.id = model.id;
    account.name = model.name;

    if (model.projects) {
      account.projects = model.projects.map((project) => {
        return Project.fromModel(project);
      });
    }

    if (model.permissions) {
      account.permissions = model.permissions;
      account.isOwner = model.permissions['isOwner'];
    }

    if (model.users) {
      account.users = model.users.map((user) => {
        return AccountUser.fromModel(user);
      });
    }

    return account;
  }
}
