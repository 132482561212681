import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingContentComponent } from './loading-content';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner';

@NgModule({
  imports: [CommonModule, LoadingSpinnerModule],
  declarations: [LoadingContentComponent],
  exports: [LoadingContentComponent],
})
export class LoadingContentModule {}
