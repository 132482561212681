export interface JSONSchema {
  type?: string | string[];
  properties?: {
    [key: string]: JSONSchema;
  };
  items?: JSONSchema;
  default?: string | number | boolean | null | object;
}

export const generateOutputForJSONSchema = (schema: JSONSchema): object | string | number | boolean | undefined => {
  return getOutputFromSchema(schema);
};

const getOutputFromSchema = (schema: JSONSchema) => {
  const type = getFirstType(schema.type);
  switch (type) {
    case 'array':
      return getOutputFromSchemaArray(schema);
    case 'null':
      return null;
    case 'number':
    case 'string':
    case 'boolean':
    case 'integer':
      return extractDefaultValue(schema);
    case 'object':
    default:
      return getOutputFromSchemaObject(schema);
  }
};

const getOutputFromSchemaObject = (schema: JSONSchema) => {
  // if a default is defined then just return it
  if (schema.default) return extractDefaultValue(schema);

  const payload = {};

  if (!schema.properties) return payload;

  const keys = Object.keys(schema.properties);
  keys.forEach((key) => {
    const property = schema.properties[key];
    payload[key] = getOutputFromSchema(property);
  });

  return payload;
};

const getOutputFromSchemaArray = (schema: JSONSchema) => {
  // if a default is defined then just return it
  if (schema.default) return extractDefaultValue(schema);

  const payload = [];

  if (schema.items) {
    const output = getOutputFromSchema(schema.items);
    payload.push(output);
  }

  return payload;
};

const extractDefaultValue = (property: JSONSchema) => {
  const type = getFirstType(property.type);

  // Primitive Value
  if (isPrimitiveType(type)) {
    return property.default ?? getPrimitiveDefaultValue(type);
  }

  return property.default ?? null;
};

const PRIMITIVE_DEFAULTS = {
  'integer': 0,
  'number': 0.0,
  'string': '',
  'boolean': false,
};

const isPrimitiveType = (type: string | string[]): boolean => {
  return Object.keys(PRIMITIVE_DEFAULTS).includes(getFirstType(type));
};

const getPrimitiveDefaultValue = (type: string | string[]) => {
  return PRIMITIVE_DEFAULTS[getFirstType(type)];
};

const getFirstType = (type: string | string[]) => {
  if (Array.isArray(type)) {
    return type?.[0] ?? 'object';
  }
  return type ?? 'object';
};
