import type { OnChanges } from '@angular/core';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-heading',
  templateUrl: './widget-heading.component.html',
  styleUrls: ['../shared/styles.scss', './widget-heading.component.scss'],
})
export class WidgetHeadingComponent extends WidgetBaseComponent implements OnChanges {
  @Input() robotHeading: number;
  @Input() mapHeading: number;
  @Input() mapPitch: number;

  public imageDegreesOffset: number = -90;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  public radianToDegrees(radians: number): number {
    return Math.round((radians * 180) / Math.PI);
  }
}
