import { Injectable } from '@angular/core';
import { SceneManager } from '@shared/models';

@Injectable()
export class RendererModelService {
  private _sceneManager: SceneManager;
  public get sceneManager(): SceneManager {
    if (!this._sceneManager) this._sceneManager = new SceneManager('rocos-scene');
    return this._sceneManager;
  }

  public cleanUp() {
    this._sceneManager = null;
  }
}
