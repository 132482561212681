import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import type { IWorkflowResponse, WorkflowResource, WorkflowResources } from '../workflow-gateway/workflow-gateway.type';
import type { EResourceType } from '../workflow-gateway/workflow-gateway.type';
import { Utils } from '@shared/utils';

export enum ELoggerTabIndex {
  logs = 0,
  nodeInspector = 1,
}

export interface IResourceNav {
  name: string;
  modified: boolean;
  icon: string;
  resource?: WorkflowResource;
  type: EResourceType;
  modifiedValue?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WorkflowStateService {
  public saveAll$: Subject<void> = new Subject();

  private _type?: string = undefined;
  private _flowId: string;
  private _callsign: string = null;
  private _loggerTabIndex$ = new BehaviorSubject<ELoggerTabIndex>(ELoggerTabIndex.logs);
  private _current$: BehaviorSubject<IWorkflowResponse> = new BehaviorSubject(null);

  public get callsign(): string {
    return this._callsign;
  }

  public get tabLogsIndex$(): Observable<ELoggerTabIndex> {
    return this._loggerTabIndex$.asObservable();
  }

  public get currentWorkflow$(): Observable<IWorkflowResponse> {
    return this._current$.asObservable();
  }

  public get flowId(): string {
    return this._flowId;
  }

  public get type(): string | undefined {
    return this._type;
  }

  public get resources$(): Observable<WorkflowResources> {
    return this.currentWorkflow$.pipe(
      Utils.rxjsNullFilter,
      map((workflow) => {
        return (workflow ? workflow.resources : null) || {};
      }),
    );
  }

  public setLoggerTabIndex(tabIndex: ELoggerTabIndex): void {
    this._loggerTabIndex$.next(tabIndex);
  }

  public setCallsign(callsign: string): void {
    this._callsign = callsign;
  }

  public setCurrentWorkflow(workflow: IWorkflowResponse): void {
    this._flowId = workflow.flowId;
    this._type = workflow.type;
    this._current$.next(workflow);
  }

  public destroy(): void {
    this.setCallsign(null);
    this._current$.next(null);
  }
}
