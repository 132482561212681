import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-courier-wrapper',
  template: `
    <div class="courier-wrapper">
      <courier-inbox></courier-inbox>
      <courier-toast></courier-toast>
    </div>
  `,
  styleUrls: ['./courier-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CourierWrapperComponent {}
