import { Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '../shared';

@Component({
  selector: 'app-widget-html',
  templateUrl: './widget-html.component.html',
  styleUrls: ['./widget-html.component.scss'],
})
export class WidgetHtmlComponent extends WidgetBaseComponent {
  @Input()
  html: any;

  constructor() {
    super();
  }
}
