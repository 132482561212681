<div class="rs-card">
  <div class="head">
    <div class="title with-spinner">Simple Triggers</div>
    <div class="right-buttons"></div>
  </div>

  <app-loading-content [isLoading]="isLoading">
    <div class="body p-0">
      <div>
        <table mat-table [dataSource]="items" #componentsTable>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Trigger ID </mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item?.value?.id }}
            </mat-cell>
          </ng-container>

          <!-- Conditions Column -->
          <ng-container matColumnDef="conditions">
            <mat-header-cell *matHeaderCellDef> Button ID </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <ng-container *ngFor="let condition of item?.value?.widgetConditions">
                {{ condition.id }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Commands Column -->
          <ng-container matColumnDef="commands">
            <mat-header-cell *matHeaderCellDef> Control Name </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <ng-container *ngFor="let command of item?.value?.commands">
                {{ command.id }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <span>{{ item?.value?.description }}</span>
            </mat-cell>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="editControls">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item; let idx = index">
              <!-- Read-only -->
              <ng-container *ngIf="currentModule === 'robot' && !item._isOverridden">
                <div matTooltip="Defined in Profile">
                  <span class="rs-grey-icon rs-icon-button-padding ri-robot-definition"></span>
                </div>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="hover-row"></mat-row>
        </table>
      </div>
    </div>
  </app-loading-content>
</div>
