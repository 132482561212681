import { parseInt } from 'lodash';

interface Sized<T> {
  data: T;
  size: number;
}

export interface PLYElement {
  [name: string]: number | number[];
  x: number;
  y: number;
  z: number;
  red?: number;
  green?: number;
  blue?: number;
  alpha?: number;
}

interface PLYHeaderProperty {
  type: string;
  name: string;
  countType?: string;
  itemType?: string;
}

interface PLYHeaderElement {
  name: string;
  count: number;
  properties: PLYHeaderProperty[];
}

interface PLYHeader {
  comments: string[];
  elements: PLYHeaderElement[];
  headerLength: number;
  format: 'ascii' | 'binary_little_endian' | 'binary_big_endian';
  version: string;
}

interface PLYResult {
  header: PLYHeader;
  data: Record<string | 'vertex', PLYElement[]>;
}

export default class PointcloudPLY {
  public static parse(data: string | ArrayBuffer): PLYResult {
    if (data instanceof ArrayBuffer) {
      const text = new TextDecoder().decode(new Uint8Array(data));
      const header = PointcloudPLY.parseHeader(text);

      if (header.format === 'ascii') {
        return { header, data: this.parseASCII(text, header) };
      } else {
        return { header, data: this.parseBinary(data, header) };
      }
    } else {
      const header = this.parseHeader(data);
      return { header, data: this.parseASCII(data, header) };
    }
  }

  private static parseHeader(data: string): PLYHeader {
    let headerText = '';
    let headerLength = 0;
    const result = /ply([\s\S]*)end_header\r?\n/.exec(data);

    if (result !== null) {
      headerText = result[1];
      headerLength = result[0].length;
    }

    const header: PLYHeader = {
      comments: [],
      elements: [],
      headerLength,
      format: undefined,
      version: undefined,
    };

    let currentElement: PLYHeaderElement;

    const lines = headerText.split('\n');
    for (let line of lines) {
      line = line.trim();

      if (line.length === 0) continue;

      const lineValues = line.split(/\s+/);
      const lineType = lineValues.shift();
      line = lineValues.join(' ');

      switch (lineType) {
        case 'format':
          header.format = lineValues[0] as 'ascii' | 'binary_little_endian' | 'binary_big_endian';
          header.version = lineValues[1];
          break;
        case 'comment':
          header.comments.push(line);
          break;
        case 'element':
          if (currentElement) header.elements.push(currentElement);
          currentElement = {
            name: lineValues[0],
            count: parseInt(lineValues[1]),
            properties: [],
          };
          break;
        case 'property':
          if (currentElement) currentElement.properties.push(PointcloudPLY.makeElementProperty(lineValues));
          break;
      }
    }

    if (currentElement !== undefined) header.elements.push(currentElement);
    return header;
  }

  private static makeElementProperty(propertyValues): PLYHeaderProperty {
    const property: PLYHeaderProperty = { name: undefined, type: propertyValues[0] };

    if (property.type === 'list') {
      property.name = propertyValues[3];
      property.countType = propertyValues[1];
      property.itemType = propertyValues[2];
    } else {
      property.name = propertyValues[1];
    }

    return property;
  }

  private static parseASCIIElement(properties: PLYHeaderProperty[], line: string): PLYElement {
    const values = line.split(/\s+/);

    const element = {
      x: undefined,
      y: undefined,
      z: undefined,
    };

    for (const item of properties) {
      if (item.type === 'list') {
        const list: number[] = [];

        // get list length
        const n = this.parseASCIINumber(values.shift(), item.countType);

        // add list elements
        for (let j = 0; j < n; j++) {
          list.push(this.parseASCIINumber(values.shift(), item.itemType));
        }

        element[item.name] = list;
      } else {
        element[item.name] = this.parseASCIINumber(values.shift(), item.type);
      }
    }

    return element;
  }

  private static parseASCIINumber(n: string, type: string): number {
    switch (type) {
      case 'char':
      case 'uchar':
      case 'short':
      case 'ushort':
      case 'int':
      case 'uint':
      case 'int8':
      case 'uint8':
      case 'int16':
      case 'uint16':
      case 'int32':
      case 'uint32':
        return parseInt(n);

      case 'float':
      case 'double':
      case 'float32':
      case 'float64':
        return parseFloat(n);
      default:
        return undefined;
    }
  }

  private static parseASCII(data: string, header: PLYHeader): Record<string, PLYElement[]> {
    const patternBody = /end_header\s([\s\S]*)$/;
    let body = '';
    const result = patternBody.exec(data);
    if (result !== null) {
      body = result[1];
    }

    const elements: Record<string, PLYElement[]> = {};

    const lines = body.split('\n');
    let elementCount = 0;
    for (const currentElement of header.elements) {
      elements[currentElement.name] = [];

      for (let line of lines) {
        line = line.trim();
        if (line.length === 0) continue;

        if (elementCount >= currentElement.count) {
          elementCount = 0;
          continue;
        }

        elements[currentElement.name].push(this.parseASCIIElement(currentElement.properties, line));
        elementCount++;
      }
    }

    return elements;
  }

  private static parseBinary(data: ArrayBuffer, header: PLYHeader): Record<string, PLYElement[]> {
    const littleEndian = header.format === 'binary_little_endian';

    const elements: Record<string, PLYElement[]> = {};

    const body = new DataView(data, header.headerLength);
    let location = 0;
    for (const currentElement of header.elements) {
      elements[currentElement.name] = [];

      for (let elementCount = 0; elementCount < currentElement.count; elementCount++) {
        const result = this.binaryReadElement(body, location, currentElement.properties, littleEndian);
        location += result.size;

        elements[currentElement.name].push(result.data);
      }
    }

    return elements;
  }

  private static binaryReadElement(
    dataView: DataView,
    location: number,
    properties: PLYHeaderProperty[],
    littleEndian: boolean,
  ): Sized<PLYElement> {
    const element: PLYElement = {
      x: undefined,
      y: undefined,
      z: undefined,
    };

    let internalOffset = 0;
    for (const property of properties) {
      if (property.type === 'list') {
        const list: number[] = [];
        const sizeResult = this.binaryRead(dataView, location + internalOffset, property.countType, littleEndian);
        internalOffset += sizeResult.size;

        const listSize = sizeResult.data;
        for (let j = 0; j < listSize; j++) {
          const result = this.binaryRead(dataView, location + internalOffset, property.itemType, littleEndian);
          internalOffset += result.size;

          list.push(result.data);
        }

        element[property.name] = list;
      } else {
        const result = this.binaryRead(dataView, location + internalOffset, property.type, littleEndian);
        internalOffset += result.size;

        element[property.name] = result.data;
      }
    }

    return { data: element, size: internalOffset };
  }

  private static binaryRead(dataView: DataView, location: number, type: string, littleEndian: boolean): Sized<number> {
    switch (type) {
      case 'int8':
      case 'char':
        return { data: dataView.getInt8(location), size: 1 };
      case 'uint8':
      case 'uchar':
        return { data: dataView.getUint8(location), size: 1 };
      case 'int16':
      case 'short':
        return { data: dataView.getInt16(location, littleEndian), size: 2 };
      case 'uint16':
      case 'ushort':
        return { data: dataView.getUint16(location, littleEndian), size: 2 };
      case 'int32':
      case 'int':
        return { data: dataView.getInt32(location, littleEndian), size: 4 };
      case 'uint32':
      case 'uint':
        return { data: dataView.getUint32(location, littleEndian), size: 4 };
      case 'float32':
      case 'float':
        return { data: dataView.getFloat32(location, littleEndian), size: 4 };
      case 'float64':
      case 'double':
        return { data: dataView.getFloat64(location, littleEndian), size: 8 };
      default:
        return undefined;
    }
  }
}
