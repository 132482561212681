<div>
  <h3>Code:</h3>
  <ng-container *ngIf="code">
    <pre
      class="code-box"
      (mouseleave)="copyCode.resetStatus()"
    ><span class="code">{{ code }}</span><app-copy-code #copyCode class="copy-code-button" [copy]="code"></app-copy-code></pre>
  </ng-container>
  <ng-container *ngIf="!code">
    <app-loading-spinner [isLoading]="true"></app-loading-spinner>
  </ng-container>
</div>
