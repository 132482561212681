import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { StreamSource } from '../../models';
import { Stream, StreamPlugin, StreamConfig, StreamConfigROSBridge, StreamConfigUDP } from '../../models';
import { RocosClientService } from '../rocos-client';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class StreamService {
  @Output() robotStreamUpdate = new EventEmitter<any>();

  constructor(private rocosClientService: RocosClientService) {}

  public list(projId: string): Observable<Stream[]> {
    return this.rocosClientService.rocosClient.stream.list(projId).pipe(
      map((res) => {
        const data = res.data as any[];

        return data.map((stream) => {
          return Stream.fromModel(stream);
        });
      }),
    );
  }

  public create(projId: string, stream: Stream): Observable<string> {
    return this.rocosClientService.rocosClient.stream.create(projId, stream as any).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public info(projId: string, streamId: string): Observable<Stream> {
    return this.rocosClientService.rocosClient.stream.info(projId, streamId).pipe(
      map((res) => {
        const data = res.data as any;

        return Stream.fromModel(data);
      }),
    );
  }

  public addSources(projId: string, streamId: string, newSources: StreamSource[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.addData(projId, streamId, newSources as any[]).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public sources(): Observable<StreamPlugin[]> {
    return this.rocosClientService.rocosClient.stream.sources().pipe(
      map((res) => {
        const data = (res.data as any[]) || [];

        return data.map((plugin) => {
          return StreamPlugin.fromModel(plugin);
        });
      }),
    );
  }

  public sourceInfo(streamId: string): Observable<StreamPlugin> {
    return this.rocosClientService.rocosClient.stream.sourceInfo(streamId).pipe(
      map((res) => {
        const data = res.data as any;

        return StreamPlugin.fromModel(data);
      }),
    );
  }

  public dataSourceList(): Observable<StreamConfig[]> {
    return new Observable<StreamConfig[]>((observer) => {
      observer.next([
        StreamConfigROSBridge.fromModel({
          name: 'ROSBridge',
          type: 'ros-bridge',
          logoURL: 'assets/images/stream-sources/ros_logo.png',
          enabled: true,
        }),
        StreamConfig.fromModel({
          name: 'GStreamer',
          type: 'g-streamer',
          enabled: false,
        }),
        StreamConfig.fromModel({
          name: 'Netdata',
          type: 'netdata',
          enabled: false,
        }),
        StreamConfigUDP.fromModel({
          name: 'UDP',
          type: 'udp',
          enabled: false,
        }),
        StreamConfig.fromModel({
          name: 'TCP',
          type: 'tcp',
          enabled: false,
        }),
        StreamConfig.fromModel({
          name: 'Unix Pipe',
          type: 'unix-pipe',
          enabled: false,
        }),
      ]);
    });
  }

  public streamsForRobot(projId: string, callsign: string): Observable<Stream[]> {
    return this.rocosClientService.rocosClient.stream.streamsForRobot(projId, callsign).pipe(
      map((res) => {
        const data = res.data as any[];

        return data.map((stream) => {
          return Stream.fromModel(stream);
        });
      }),
    );
  }

  public bindRobots(projId: string, streamId: string, callsigns: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.bindRobot(projId, streamId, callsigns).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public unbindRobots(projId: string, streamId: string, callsigns: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.unbindRobot(projId, streamId, callsigns).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public removeStreams(projId: string, streamIds: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.removeStreams(projId, streamIds).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public removeStreamData(projId: string, streamId: string, dataIds: any[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.removeStreamData(projId, streamId, dataIds).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public removeStreamDataByIds(projId: string, streamId: string, ids: string[]): Observable<any> {
    return this.rocosClientService.rocosClient.stream.removeStreamDataByIds(projId, streamId, ids).pipe(
      map((res) => {
        return res.data as any;
      }),
    );
  }

  public publishStorageStreamChange(update) {
    this.robotStreamUpdate.emit(update);
  }
}
