import type { Graphs, UiGraphData } from 'src/app/workflow/modules/graph-editor/models/types';
import { ResourcesUtils } from 'src/app/workflow/modules/graph-editor/utils/resource-utils';

// Docs:
// https://dronedeploy.atlassian.net/wiki/spaces/GR/pages/2076279088/Embedded+Graph+Support

export const cBaseAgentGraph = (flowId: string): Graphs => {
  return {
    graphs: {
      [ResourcesUtils.AgentGraphName(flowId)]: {
        nodes: {},
        fi: {},
        fo: {},
        bi: {},
        bo: {},
        fc: [],
        bc: [],
        defaults: [],
      },
    },
    uiGraph: cBaseUiGraph,
    comments: [],
    schema: 'v2',
  } as Graphs;
};

export const cBaseUiGraph: UiGraphData = {
  id: 'demo@0.1.0',
  nodes: {
    '0': {
      id: 0,
      name: 'triggerIn',
      data: {
        config: {
          hidden: false,
          name: 'Trigger In',
          namespace: 'interface',
          fo: {
            in: {
              name: 'in',
            },
          },
          bi: {
            label: {
              kind: 'string',
              default: 'Entry',
              hidden: true,
              name: 'label',
            },
          },
          key: 'triggerIn',
          icon: ['ri-trigger-in', 'path1', 'path2'],
        },
      },
      inputs: {
        label: {
          connections: [],
        },
      },
      outputs: {
        in: {
          connections: [],
        },
      },

      position: [-320, -80],
    },
  },
  checksum: '129e3081',
  schema: 'v2',
};

/** Example clean v1 graph: 
    const v1 = {
      'path': 'clean.graph',
      'contentLink': '/projects/knut-s-project/automate/flows/clean/resource-contents/clean.graph',
      'link': '/projects/knut-s-project/automate/flows/clean/resources/clean.graph',
      'size': 79,
      'data': {
        'graphs': {
          'clean': {
            'nodes': {},
            'fi': {},
            'fo': {},
            'fc': [],
            'bc': [],
            'defaults': [],
          },
        },
      },
      'type': 'AgentGraph',
      'hash': '33071187a59826b70a9a4fd2975b144bf0ba9d0b012d50925df515e1db11092b',
    };
 */

/** Example clean v2 graph: 
    const v2 = {
      'path': 'cleanv2.graph',
      'contentLink': '/projects/knut-s-project/automate/flows/cleanv2/resource-contents/cleanv2.graph',
      'link': '/projects/knut-s-project/automate/flows/cleanv2/resources/cleanv2.graph',
      'size': 380,
      'data': {
        'graphs': { 'cleanv2': { 'nodes': {}, 'fi': {}, 'fc': [], 'bc': [], 'defaults': [] } },
        'uiGraph': {
          'id': 'demo@0.1.0',
          'nodes': {
            '-1': {
              'id': -1,
              'data': {
                'config': {
                  'hidden': false,
                  'name': 'Entry',
                  'fo': { 'Out': { 'name': 'Out' } },
                  'entry': true,
                  'key': 'entry',
                  'icon': ['ri-entry', 'path1', 'path2'],
                },
              },
              'inputs': {},
              'outputs': { 'Out': { 'connections': [] } },
              'position': [0, -81],
              'name': 'entry',
            },
          },
          'comments': [],
          'schema': 'v2',
        },
      },
      'type': 'AgentGraph',
      'hash': 'da41791bd5f719d552769e5fe2e07083005c8d091109da293792d74a6900c6c3',
    };
 */
