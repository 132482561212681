import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import type { CommandDialogResult } from '@shared/gamepad';
import { GamepadCommandDialogComponent } from '@shared/gamepad';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import { ConfigGroupItem } from '../../models';
import type { IsLoading } from '../../interfaces';
import { CommandV2, IAvailableCommandV2TypesCollection } from '@shared/models';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import type { MatSortable } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { CommandTypePipe } from '@shared/gamepad/command-type.pipe';
import { CommandParametersPipe } from '@shared/gamepad/command-parameters.pipe';

type CommandConfig = ConfigGroupItem<CommandV2>;

@Component({
  selector: 'app-gamepad-commands-v2',
  templateUrl: './gamepad-commands-v2.component.html',
  styleUrls: ['./gamepad-commands-v2.component.scss'],
  providers: [CommandTypePipe, CommandParametersPipe],
})
export class GamepadCommandsV2Component implements IsLoading, OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() public isLoading: boolean = false;
  @Input() public projectId: string;
  @Input() public currentModule: 'robot' | 'robot-definition' = 'robot';
  @Input() public availableCommandTypesCollection: IAvailableCommandV2TypesCollection = {};

  // New way that works with ConfigGroupItem
  @Input() public set items(items: CommandConfig[]) {
    this._items = items;
    this.dataSource.data = items;
  }
  public get items() {
    return this._items;
  }

  @Output() public itemsChange: EventEmitter<CommandConfig[]> = new EventEmitter<CommandConfig[]>();

  public displayedColumns: string[] = ['id', 'command', 'description', 'parameters', 'editControls'];
  public dataSource = new MatTableDataSource<CommandConfig>();

  private _items: CommandConfig[] = [];

  public constructor(
    private dialogService: DialogService,
    private dialog: MatDialog,
    private commandTypePipe: CommandTypePipe,
    private commandParameterPipe: CommandParametersPipe,
  ) {}

  public ngOnInit(): void {
    const defaultSort: MatSortable = { id: 'id', start: 'asc', disableClear: false };
    this.sort?.sort(defaultSort);
  }

  public ngAfterViewInit(): void {
    if (!this.sort) return;

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (!item?.value) return;

      switch (property) {
        case 'id':
          // Sort overridden commands to the bottom
          if (item._isOverridden) return `zzzzzzzzz${item.value.id}`;
          return item.value.id;
        case 'command':
          return this.commandTypePipe.transform(item.value);
        case 'description':
          return item.value.name;
        case 'parameters':
          return this.commandParameterPipe.transform(item.value?.parameters);
        default:
          return item[property];
      }
    };
  }

  public onRemoveCommand(commandConfig: CommandConfig): void {
    const title = 'Delete Command?';
    const message = '<p>Are you sure you want to delete this command?</p>';

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          const newItems = this.items.filter((x) => x.id !== commandConfig.id);
          this.itemsChange.next(newItems);
        }
      });
  }

  public onAddNewCommand(): void {
    const command = new CommandV2();
    const item = new ConfigGroupItem<CommandV2>(command);

    // Set the `_isOverridden` to true if creating command under *robot* module.
    if (this.currentModule === 'robot') {
      item._isOverridden = true;
    }

    const dialogRef: MatDialogRef<GamepadCommandDialogComponent, CommandDialogResult> = this.dialog.open(
      GamepadCommandDialogComponent,
      {
        data: {
          projectId: this.projectId,
          existingCommandIds: this.getExistingIds(),
          availableCommandTypesCollection: this.availableCommandTypesCollection,
          command,
        },
      },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result.cancelled) return;

      item.value = result.model;
      const newItems = [...this.items];
      newItems.push(item);
      this.itemsChange.next(newItems);
    });
  }

  public onEditCommand(commandConfig: CommandConfig) {
    const command = commandConfig.value;
    const dialogRef: MatDialogRef<GamepadCommandDialogComponent, CommandDialogResult> = this.dialog.open(
      GamepadCommandDialogComponent,
      {
        data: {
          projectId: this.projectId,
          existingCommandIds: this.getExistingIds(commandConfig.id),
          availableCommandTypesCollection: this.availableCommandTypesCollection,
          command,
        },
      },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result.cancelled) return;

      commandConfig.value = result.model;
      this.itemsChange.next([...this.items]);
    });
  }

  private getExistingIds(id?: string): string[] {
    return this.items.filter((i) => i.id !== id).map((x) => x.value.id);
  }
}
