import type { Scene } from '@babylonjs/core';
import { HemisphericLight } from '@babylonjs/core';
import {
  Axis,
  Color3,
  Matrix,
  Mesh,
  Quaternion,
  SceneLoader,
  Space,
  StandardMaterial,
  Vector3,
  VertexData,
} from '@babylonjs/core';
import type { IMesh } from '../primitives/visualizer/interface/IMesh';
import type { RocosSdkClientService } from '../../rocos-sdk-client';
import type { GpsLocation } from '../transform';
import { getPlanLayerByType, PlanLayerType } from '../../../utils/plan-utils';
import { load } from '@loaders.gl/core';
import { Tiles3DLoader } from '@loaders.gl/3d-tiles';
import type { Tile3D } from '@loaders.gl/tiles';
import { Tileset3D } from '@loaders.gl/tiles';
import { Ellipsoid } from '@math.gl/geospatial';
import { _PerspectiveFrustum as PerspectiveFrustum, CullingVolume } from '@math.gl/culling';
import type { PrimitiveMetaData } from '../primitives/primitives';
import { defaultPanels } from '@shared-modules/properties-editor-panel/pipes/editor-panel/editor-panel.pipe';

export const tiles3DMetaData: PrimitiveMetaData = {
  key: 'tiles',
  label: 'DroneDeploy 3D Model',
  icon: 'ri-3d-box',
  editorPanels: {
    ...defaultPanels,
    parent: ['geoTransform'],
    tiles: true,
    bindPosition: false,
    bindRotationEuler: false,
    bindRotationQuaternion: false,
    bindAdvanced: false,
  },
};

export class Tiles3D extends Mesh {
  private readonly planId: string;
  private readonly layerName: string;
  private tileset: Tileset3D;
  private updatePromise: Promise<number> | null = null;
  private readonly debounce: number = 250;
  private readonly sse: number;
  private readonly pointsMaterial: StandardMaterial;

  constructor(
    name: string,
    scene: Scene,
    private meshData: IMesh,
    private projectLocation: GpsLocation,
    private projectId: string,
    private sdk: RocosSdkClientService,
  ) {
    super(name, scene);
    this.planId = meshData.options?.ddPlanId;
    this.layerName = meshData.options?.layerName;
    this.sse = meshData.options?.maximumScreenSpaceError || 24;

    const light = new HemisphericLight(`${name}-light`, new Vector3(0, 0, 1), scene);
    light.parent = this;
    this.pointsMaterial = new StandardMaterial(`${name}-pnts`, scene);
    this.pointsMaterial.disableLighting = false;
    this.pointsMaterial.pointsCloud = true;
    this.pointsMaterial.pointSize = meshData?.geoJSON?.pointSize || 2;
    this.pointsMaterial.specularColor = new Color3(0, 0, 0);
    this.pointsMaterial.freeze();

    this.loadTiles().then(() =>
      this.getScene().onBeforeRenderObservable.add(() => {
        this.tileset._frameNumber++;
        this.update();
      }),
    );
  }

  override dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
    if (this.updatePromise) {
      this.tileset?.destroy();
      super.dispose(doNotRecurse, disposeMaterialAndTextures);
    }
  }

  private async getLayer(type: string): Promise<any> {
    const response = await this.sdk.client.getIntegrationService().getPlanById(this.projectId, this.planId);
    if (!response) {
      console.error(`Plan ID ${this.planId} does not exist`);
      return null;
    }

    const planType = type === 'Point Cloud' ? PlanLayerType.Tiled3DPointCloud : PlanLayerType.Tiled3DMesh;
    const layer = getPlanLayerByType(response, planType);
    if (!layer) {
      console.error(`Layer ${type} does not exist`);
      return null;
    }

    return layer;
  }

  private async loadTiles(): Promise<void> {
    if (!this.planId || !this.layerName) return null;
    const layer = await this.getLayer(this.layerName);

    const tilesetJson = await this.getTilesetJson(layer);
    if (!tilesetJson) {
      console.error(`Tileset ${layer.url} does not exist`);
      return null;
    }

    this.tileset = await this.createTileset(tilesetJson, layer.jwt);

    const projectLocationCartesian = Ellipsoid.WGS84.cartographicToCartesian([
      this.projectLocation.longitude,
      this.projectLocation.latitude,
      this.projectLocation.altitude,
    ]);
    this.tileset.modelMatrix = Ellipsoid.WGS84.eastNorthUpToFixedFrame(projectLocationCartesian).invert();
  }

  private async getTilesetJson(layer): Promise<Tileset3D> {
    return await load(layer.url, Tiles3DLoader, {
      fetch: {
        headers: {
          'Accept': `*/*;access_token=${layer.jwt}`,
        },
      },
    });
  }

  private async createTileset(tilesetJson: Tileset3D, jwt: string): Promise<Tileset3D> {
    return new Tileset3D(tilesetJson, {
      maximumMemoryUsage: 32,
      maximumScreenSpaceError: this.sse,
      viewDistanceScale: 1.0,
      updateTransforms: true,
      throttleRequests: true,
      maxRequests: 32,
      debounceTime: this.debounce,
      loadOptions: {
        worker: true,
        '3d-tiles': {
          loadGLTF: false,
        },
        fetch: {
          headers: {
            'Accept': `*/*;access_token=${jwt}`,
          },
        },
      },
      contentLoader: async (tile: Tile3D) => {
        if (this.getScene().getMeshById(tile.id)) {
          return;
        }
        switch (tile.content.type) {
          case 'pnts': {
            await this.createPoints(tile);
            break;
          }
          case 'b3dm': {
            await this.createMesh(tile);
            break;
          }
          default:
            break;
        }
      },
      onTileLoad: (tile: Tile3D) => {
        const mesh = this.getScene().getMeshById(tile.id);
        if (mesh) {
          mesh.setEnabled(true);
        }
      },
      onTileUnload: (tile: Tile3D) => {
        const mesh = this.getScene().getMeshById(tile.id);
        if (mesh) {
          mesh.dispose();
        }
      },
      onTileError: (tile: Tile3D, message: string) => {
        console.warn('Tile error', tile.id, message);
      },
    });
  }

  private update() {
    if (!this.updatePromise && this.isEnabled() && this.isVisible) {
      this.updatePromise = new Promise<number>(() => {
        setTimeout(() => {
          this._update().then(() => {
            this.updatePromise = null;
          });
        }, this.debounce);
      });
    }
  }

  private async _update(): Promise<void> {
    if (!this.tileset || !this.getScene().activeCamera) {
      return;
    }

    this.getScene().activeCamera.computeWorldMatrix();
    const cameraMatrix = this.getScene().activeCamera.getWorldMatrix().clone();
    const worldMatrix = this.getWorldMatrix().clone();
    const transformedCameraPosition = cameraMatrix.multiply(worldMatrix.invert()).getTranslation();

    const loadersFrustum = new PerspectiveFrustum({
      fov: this.getScene().activeCamera.fov,
      aspectRatio: this.getEngine().getScreenAspectRatio(),
      near: this.getScene().activeCamera.minZ,
      far: this.getScene().activeCamera.maxZ,
    });

    const frameState = {
      camera: {
        position: transformedCameraPosition.asArray(),
      },
      height: this.getEngine().getRenderHeight(),
      frameNumber: this.tileset._frameNumber,
      sseDenominator: loadersFrustum.sseDenominator,
      cullingVolume: new CullingVolume([]),
      viewport: {
        id: 0,
      },
    };

    try {
      this.tileset._traverser.traverse(this.tileset.root, frameState, this.tileset.options);
    } catch {
      /* Hide the errors that are generated by the traverser when a tileset is disposed */
    }
    const selectedTileIds = this.tileset.selectedTiles.map((tile) => tile.id);
    this.getChildren().forEach((node) => {
      if (selectedTileIds.includes(node.id)) {
        node.setEnabled(true);
      } else {
        node.setEnabled(false);
      }
    });
  }

  private async createPoints(tile: Tile3D) {
    const mesh = new Mesh(tile.id, this.getScene());
    mesh.setEnabled(false);
    mesh.doNotSyncBoundingInfo = true;
    mesh.isPickable = false;

    const colors = [];
    const normals = [];
    const positions = Array.from(tile.content.attributes.positions);

    for (let i = 0; i < positions.length; i += 3) {
      if (tile.content.attributes.colors != null) {
        colors.push(tile.content.attributes.colors.value[i] / 255);
        colors.push(tile.content.attributes.colors.value[i + 1] / 255);
        colors.push(tile.content.attributes.colors.value[i + 2] / 255);
        colors.push(1.0);
      } else {
        colors.push(1.0, 1.0, 1.0, 1.0);
      }
      normals.push(0, 0, 1);
    }

    const vertexData = new VertexData();
    vertexData.positions = Array.from(tile.content.attributes.positions);
    vertexData.colors = colors;
    vertexData.normals = normals;
    vertexData.applyToMesh(mesh);

    const computedTransform = Matrix.FromArray(tile.computedTransform);
    const rtcCenter = Matrix.Identity().setTranslation(Vector3.FromArray(tile.content.rtcCenter));
    const transform = computedTransform.multiply(rtcCenter);
    mesh.setPreTransformMatrix(transform);
    mesh.parent = this;
    mesh.material = this.pointsMaterial;
  }

  private async createMesh(tile: Tile3D) {
    const arrayBuffer = tile.content.gltfArrayBuffer;
    const file = new File([arrayBuffer], 'temp.glb');
    const result = await SceneLoader.ImportMeshAsync('', 'file:', file, this.getScene());

    const meshes = result.meshes.filter((m) => m !== result.meshes[0]) as Mesh[];
    for (const mesh of meshes) {
      mesh.id = tile.id;
      mesh.setEnabled(false);
      mesh.setParent(null);
      mesh.doNotSyncBoundingInfo = true;
      mesh.isPickable = false;
      mesh.rotate(Axis.X, Math.PI / 2, Space.WORLD);
      mesh.bakeCurrentTransformIntoVertices();

      const transform = Matrix.FromArray(tile.computedTransform).multiply(this.computeWorldMatrix().clone());
      const scale = new Vector3();
      const rotation = new Quaternion();
      const translation = new Vector3();
      transform.decompose(scale, rotation, translation);
      mesh.position = translation;
      mesh.rotationQuaternion = rotation;
      mesh.scaling = scale;
      mesh.bakeCurrentTransformIntoVertices();
      mesh.setParent(this);
      mesh.material.backFaceCulling = false;
      mesh.convertToUnIndexedMesh();
      mesh.material.freeze();
      mesh.setEnabled(true);
    }
    if (result.meshes?.length) result.meshes[0]?.dispose();
  }
}
