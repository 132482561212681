import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-dd-loading-spinner',
  template: `
    <div class="app-loading-container">
      <div class="loading-circle">
        <svg
          width="66px"
          height="55px"
          viewBox="0 0 66 55"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            fill="#3f48e9"
            d="M13.671402,0.389533646 C10.2777864,-0.389599368 0,7.40173076 0,7.40173076 
              C0,7.40173076 29.9607697,20.8416602 29.8637642,20.8416602 L26.4701487,53.5653125 
              C26.4701487,53.5653125 36.7479351,49.0850512 39.3659009,45.1897148 L65.7393069,0 
              L13.671402,0.389533646 Z"
          ></path>
        </svg>
      </div>
      <h2>
        <span>{{ subtext ?? 'Please wait ...' }}</span>
      </h2>
    </div>
  `,
  styleUrls: ['./dd-loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DDLoadingSpinnerComponent {
  @Input() subtext?: string;
}
