import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export enum EditingMode {
  Normal = 'normal',
  Full = 'full',
}

export enum ESidebarStatus {
  Full = 'full',
  Collapsed = 'collapsed',
  ForceHide = 'force-hide', // Special status of sidebar, which hide the sidebar but do not save status.
}

export const SAVED_SIDEBAR_STATUS = 'SAVED_SIDEBAR_STATUS';

@Injectable({
  providedIn: 'root',
})
export class GuiStateService {
  private _editingMode$ = new BehaviorSubject<EditingMode>(EditingMode.Normal);
  private _sidebarStatus$: BehaviorSubject<ESidebarStatus> = new BehaviorSubject(
    environment.defaultSidebarStatus as ESidebarStatus,
  );

  public constructor() {
    this.initFromStorage();
  }

  public get editingMode$(): Observable<string> {
    return this._editingMode$.asObservable();
  }

  public get sidebarStatus$(): Observable<ESidebarStatus> {
    return this._sidebarStatus$.asObservable();
  }

  public setEditingMode(mode: EditingMode): void {
    this._editingMode$.next(mode);
  }

  public saveSidebarVisibility(status: ESidebarStatus): void {
    localStorage.setItem(SAVED_SIDEBAR_STATUS, status);
  }

  public updateSidebarStatus(status: ESidebarStatus): void {
    this._sidebarStatus$.next(status);
    if (status !== ESidebarStatus.ForceHide) {
      this.saveSidebarVisibility(status);
    }
  }

  public recoverSidebar(): void {
    const savedSidebarStatus = localStorage.getItem(SAVED_SIDEBAR_STATUS) as ESidebarStatus;
    const current = this._sidebarStatus$.getValue();
    if (current !== savedSidebarStatus) {
      this.updateSidebarStatus(savedSidebarStatus);
    }
  }

  private initFromStorage(): void {
    const sidebarStatus = (localStorage.getItem(SAVED_SIDEBAR_STATUS) ||
      environment.defaultSidebarStatus) as ESidebarStatus;
    this.updateSidebarStatus(sidebarStatus);
  }
}
