export interface Euler {
  x: number;
  y: number;
  z: number;
}

/**
 * Generate a random (float) number between 0 to max.
 *
 * @param max The max value of random number
 */
const rand = (max = 1000): number => {
  return Math.random() * max;
};

/**
 * Transform a value by looking up arrays.
 *
 * @param value The value need to loopup
 * @param lookupArray The raw value array which should include the value
 * @param resultArray The same length array which contains transformed value
 * @param defaultValue The default value if can not find value in the `lookupArray`.
 */
const lookup = (value: any, lookupArray: any[], resultArray: any[], defaultValue = null) => {
  if (value !== undefined && lookupArray && resultArray) {
    const index = lookupArray.indexOf(value);

    if (index !== -1 && resultArray.length > index) {
      return resultArray[index];
    }
  }

  return defaultValue;
};

/**
 * Convert quaternion to euler.
 *
 * Ref: https://www.npmjs.com/package/quaternion-to-euler
 *
 * @param q0 Q0
 * @param q1 Q1
 * @param q2 Q2
 * @param q3 Q3
 *
 * @returns Euler ({x, y, z})
 */
const quaternionToEuler = (q0: number, q1: number, q2: number, q3: number): Euler => {
  const Rx = Math.atan2(2 * (q0 * q1 + q2 * q3), 1 - 2 * (q1 * q1 + q2 * q2));
  const Ry = Math.asin(2 * (q0 * q2 - q3 * q1));
  const Rz = Math.atan2(2 * (q0 * q3 + q1 * q2), 1 - 2 * (q2 * q2 + q3 * q3));

  return {
    x: Rx,
    y: Ry,
    z: Rz,
  };
};

/**
 * $lib provides some built-in functions.
 */
export const $lib = {
  rand,
  lookup,
  quaternionToEuler,
};
