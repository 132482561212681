import type { PointArray, Position } from '../primitives/primitives';
import type { WaypointLocation } from '../primitives/visualizer/interface/IWaypointPicker';
import type { Point } from '../transform';
import { Vector3 } from '@babylonjs/core';

export const positionToPoint = (position: Position): Point => {
  return [position.x, position.y, position.z];
};

export const pointToPosition = (point: Point): Position => {
  return { x: point[0], y: point[1], z: point[2] };
};

export const pointArrayToPoint = (pointArray: PointArray): Point => {
  return pointArray as Point;
};

export const roundLocationArrayToDecimals = (
  positions: WaypointLocation[],
  decimals: number = 2,
): WaypointLocation[] => {
  return positions.map((position) => {
    return {
      pos: roundPositionToDecimals(position.pos, decimals),
      rot: position.rot,
    };
  });
};

export const roundPositionArrayToDecimals = (positions: Position[], decimals: number = 2): Position[] => {
  return positions.map((position) => roundPositionToDecimals(position, decimals));
};

export const roundPositionToDecimals = (
  position: {
    x: number;
    y: number;
    z: number;
  },
  decimals: number = 2,
): Position => {
  return {
    x: roundToDecimals(position.x, decimals),
    y: roundToDecimals(position.y, decimals),
    z: roundToDecimals(position.z, decimals),
  };
};

export const roundToDecimals = (value: number, decimals: number = 2): number => {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const distanceBetweenPoints = (pa: Position, pb: Position) => {
  const a = pa.x - pb.x;
  const b = pa.y - pb.y;
  const c = pa.z - pb.z;
  return Math.sqrt(a * a + b * b + c * c);
};

export const positionToVector3 = (position: Position): Vector3 => {
  return new Vector3(position.x, position.y, position.z);
};

export const vector3ToPosition = (vector: Vector3): Position => {
  return { x: vector.x, y: vector.y, z: vector.z };
};
