import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import type { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { first } from 'rxjs/operators';
import { DialogService } from '../../dialogs';
import type {
  FunctionConfig,
  GamepadControlAction,
  GamepadControlActionType,
  GamepadControlParameter,
} from '../../models';
import { GamepadControl, gamepadControlActionDefaultTypes, GamepadNewControlAction } from '../../models';

@Component({
  selector: 'app-gamepad-control',
  templateUrl: './gamepad-control.component.html',
  styleUrls: ['./gamepad-control.component.scss'],
})
export class GamepadControlComponent implements OnInit {
  @Input()
  control: GamepadControl;
  /**
   * Current module: robot or robot-definition.
   * This value is just for indicating show/hide config group icon.
   */
  @Input()
  currentModule: 'robot' | 'robot-definition';

  @Input()
  isOverridden: boolean;

  @Input()
  isEditing: boolean = false;

  @Input()
  functions: FunctionConfig[] = [];

  @Input()
  controlActions: GamepadNewControlAction[] = [];

  @Output()
  controlChange: EventEmitter<GamepadControl> = new EventEmitter<GamepadControl>();

  @Output()
  deleteChange: EventEmitter<GamepadControl> = new EventEmitter<GamepadControl>();

  form: UntypedFormGroup;

  defaultControlActionTypes: GamepadControlActionType[];

  get parameters(): UntypedFormArray {
    return this.form && (this.form.get('parameters') as UntypedFormArray);
  }

  get actions(): UntypedFormArray {
    return this.form && (this.form.get('actions') as UntypedFormArray);
  }

  constructor(private dialogService: DialogService) {
    this.defaultControlActionTypes = gamepadControlActionDefaultTypes;
  }

  ngOnInit() {
    this.createForm();
  }

  onEdit() {
    this.isEditing = true;
    this.createForm();
  }

  onCancel() {
    this.isEditing = false;

    // Remove current command as it's new adding command.
    if (!this.control.id) {
      this.deleteChange.next(this.control);
    }
  }

  onRemoveControl() {
    this.deleteChange.next(this.control);
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }

    this.isEditing = false;

    let model = JSON.parse(JSON.stringify(this.form.value));
    model = this.replaceActionTypes(model);

    this.control = GamepadControl.fromModel(model);

    this.controlChange.next(this.control);
  }

  onAddNewParam() {
    this.parameters.push(this.createParamFromGroup());
  }

  onAddNewAction() {
    this.actions.push(this.createActionGroup());
  }
  onInsertNewAction(idx: number) {
    const newAction = this.createActionGroup();
    this.actions.insert(idx, newAction);
  }
  onRemoveAction(index: number) {
    this.confirmBeforeDelete('action', () => {
      this.actions.removeAt(index);
    });
  }

  onRemoveParam(index: number) {
    this.confirmBeforeDelete('parameter', () => {
      this.parameters.removeAt(index);
    });
  }

  onActionTypeSelectionChange(selection: MatSelectChange, index: number) {
    const type = selection.value;
    const controlAction = this.controlActions.find((action) => {
      return action.type === type;
    });

    const actionFormGroup = this.createActionGroup(controlAction);

    this.actions.setControl(index, actionFormGroup);
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.control.id, [Validators.required]),
      actions: this.createActionGroups(this.control.actions),
      parameters: this.createParamFormGroups(this.control.parameters),
    });
  }

  private createActionGroup(action?: GamepadNewControlAction) {
    const formGroup = new UntypedFormGroup({
      type: new UntypedFormControl(action ? action.type : null, [Validators.required]),
    });

    if (action?.type) {
      switch (action.type) {
        case 'agent-mavlink':
          formGroup.addControl('metadata', this.createActionMetadataForMavlinkAgentPlugin(action.metadata));
          break;
        case 'agent-ros':
          formGroup.addControl('metadata', this.createActionMetadataForRosAgentPlugin(action.metadata));
          break;
        case 'agent-rosbridge':
          formGroup.addControl('metadata', this.createActionMetadataForRosBridgeAgentPlugin(action.metadata));
          break;
        case 'agent-http':
          formGroup.addControl('metadata', this.createActionMetadataForHttpControlPlugin(action.metadata));
          break;
        case 'agent-tcp':
          formGroup.addControl('metadata', this.createActionMetadataForTcpControlPlugin(action.metadata));
          break;
        case 'server-slack':
          formGroup.addControl('metadata', this.createActionMetadataForSlackMessage(action.metadata));
          break;
        case 'server-function':
          formGroup.addControl('metadata', this.createActionMetadataForServerFunction(action.metadata));
          break;
        case 'client-delay':
          formGroup.addControl('metadata', this.createActionMetadataForDelay(action.metadata));
          break;
        default:
          break;
      }
    }

    return formGroup;
  }

  private createActionMetadataForMavlinkAgentPlugin(metadata: any, messageUri: string = '') {
    return new UntypedFormGroup({
      destination: new UntypedFormControl(metadata?.destination ? metadata.destination : messageUri, [
        Validators.required,
      ]),
      payload: new UntypedFormControl(metadata?.payload ? metadata.payload : '{}', [Validators.required]),
    });
  }

  private createActionMetadataForRosBridgeAgentPlugin(metadata: any, topicUri: string = '') {
    return new UntypedFormGroup({
      destination: new UntypedFormControl(metadata?.destination ? metadata.destination : topicUri, [
        Validators.required,
      ]),
      payload: new UntypedFormControl(metadata?.payload ? metadata.payload : '{}', [Validators.required]),
    });
  }

  private createActionMetadataForRosAgentPlugin(metadata: any, topicUri: string = '') {
    return new UntypedFormGroup({
      destination: new UntypedFormControl(metadata?.destination ? metadata.destination : topicUri, [
        Validators.required,
      ]),
      payload: new UntypedFormControl(metadata?.payload ? metadata.payload : '{}', [Validators.required]),
      meta: new UntypedFormControl(
        metadata?.meta
          ? metadata.meta
          : `{
  "ros.type": "geometry_msgs/Twist"
}`,
        [Validators.required],
      ),
    });
  }

  private createActionMetadataForHttpControlPlugin(metadata: any, topicUri: string = '') {
    return new UntypedFormGroup({
      destination: new UntypedFormControl(metadata?.destination ? metadata.destination : topicUri, [
        Validators.required,
      ]),
      payload: new UntypedFormControl(
        metadata?.payload
          ? metadata.payload
          : `{
  "address": "",
  "method": "POST",
  "body": "",
  "headers": {
    "Content-Type": "text/plain"
  }
}`,
        [Validators.required],
      ),
    });
  }

  private createActionMetadataForTcpControlPlugin(metadata: any, topicUri: string = '') {
    return new UntypedFormGroup({
      destination: new UntypedFormControl(metadata?.destination ? metadata.destination : topicUri, [
        Validators.required,
      ]),
      payload: new UntypedFormControl(metadata?.payload ? metadata.payload : `{}`, [Validators.required]),
    });
  }

  private createActionMetadataForSlackMessage(metadata: any) {
    return new UntypedFormGroup({
      webhook: new UntypedFormControl(metadata ? metadata.webhook : null, [Validators.required]),
      message: new UntypedFormControl(metadata?.message ? metadata.message : '{"text":"Hello robot"}', [
        Validators.required,
      ]),
    });
  }

  private createActionMetadataForServerFunction(metadata: any) {
    return new UntypedFormGroup({
      functionId: new UntypedFormControl(metadata ? metadata.functionId : null, [Validators.required]),
      payload: new UntypedFormControl(metadata?.payload ? metadata.payload : '{}', [Validators.required]),
    });
  }

  private createActionMetadataForDelay(metadata: any) {
    return new UntypedFormGroup({
      waitForMilliseconds: new UntypedFormControl(
        metadata?.waitForMilliseconds !== undefined ? metadata.waitForMilliseconds : 1000,
        [Validators.required],
      ),
    });
  }

  private createActionGroups(actions: GamepadControlAction[]) {
    const groups = actions.map((action) => {
      const newAction = GamepadNewControlAction.fromOldAction(action, this.controlActions);

      return this.createActionGroup(newAction);
    });

    return new UntypedFormArray(groups, [Validators.required]);
  }

  private createParamFormGroups(params: GamepadControlParameter[]) {
    const groups = params.map((param) => {
      return this.createParamFromGroup(param);
    });

    return new UntypedFormArray(groups, []);
  }

  private createParamFromGroup(param?: GamepadControlParameter) {
    return new UntypedFormGroup({
      name: new UntypedFormControl(param ? param.name : '', [Validators.required]),
    });
  }

  private confirmBeforeDelete(topic: string, callback: any) {
    const uppercaseTopic = topic.charAt(0).toUpperCase() + topic.slice(1);
    const title = `Delete ${uppercaseTopic}?`;
    const message = `<p>Are you sure you want to delete this ${topic}?</p>`;

    this.dialogService
      .deleteConfirm(title, message)
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          callback();
        }
      });
  }

  private replaceActionTypes(model: any) {
    if (model?.actions) {
      model.actions.forEach((action) => {
        const type = action?.type;

        if (type) {
          const targetAction = this.controlActions.find((ca) => {
            return ca.type === type;
          });

          if (targetAction) {
            action.type = targetAction.uri;
          }
        }
      });
    }

    return model;
  }
}
