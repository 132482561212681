import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gRPCSource, SubscriberStatus } from '@team-rocos/rocos-js';
import { interval, Subject } from 'rxjs';
import { RocosClientService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { Heartbeat, IHeartbeatStatus } from '@shared/models/robot/heartbeat';

@UntilDestroy()
@Component({
  selector: 'app-robot-status-dot',
  templateUrl: './robot-status-dot.component.html',
  styleUrls: ['./robot-status-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RobotStatusDotComponent implements OnInit, OnChanges {
  @Input() public projectId: string;
  @Input() public callsign: string;
  @Input() public size: 'large' | 'medium' | 'small' = 'medium';
  @Input() public dark: boolean = false;

  @Output() public heartbeat = new EventEmitter<Heartbeat>();

  public status: SubscriberStatus = SubscriberStatus.UNKNOWN;

  private lastHeartbeatReceived?: number;
  private startedAt?: number;
  private timeout = 5000; // if no heartbeat within 5 seconds then the robot is considered dead
  private robotChanged$: Subject<void> = new Subject<void>();

  constructor(private rocosClientService: RocosClientService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('projectId' in changes || 'callsign' in changes) {
      this.robotChanged$.next();
      this.subscribe();
    }
  }

  private updateHeartbeat() {
    const status = this.status === SubscriberStatus.ALIVE ? IHeartbeatStatus.Alive : IHeartbeatStatus.Disconnected;
    this.heartbeat.next(new Heartbeat(status));
    this.cdr.markForCheck();
  }

  private subscribe() {
    this.status = SubscriberStatus.UNKNOWN;
    this.updateHeartbeat();
    this.startedAt = new Date().getTime();

    // check every second if the robot is still alive
    interval(2000)
      .pipe(untilDestroyed(this), takeUntil(this.robotChanged$))
      .subscribe(() => {
        const now = new Date().getTime();
        const lastHeartbeat = this.lastHeartbeatReceived > 0 ? this.lastHeartbeatReceived : this.startedAt;

        // checks if the current time and the last heartbeat time is
        // greater than the timeout. If there was no heartbeat at all it
        // will check the initially requested time (startedAt)
        if (now - lastHeartbeat > this.timeout) {
          this.status = SubscriberStatus.DEAD;
          this.updateHeartbeat();
        }
      });

    this.rocosClientService
      .subscribeV2(this.projectId, [this.callsign], [gRPCSource.types.heartbeat], undefined, `status-dot`)
      .pipe(untilDestroyed(this), takeUntil(this.robotChanged$))
      .subscribe(() => {
        this.lastHeartbeatReceived = new Date().getTime();
        this.status = SubscriberStatus.ALIVE;
        this.updateHeartbeat();
      });
  }
}
