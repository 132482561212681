import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Shared the auth state values for controlling the sign in, sign up and reset password pages
 */
export class AuthStateService {
  public errorMsg = new Subject<string>();
  public successMsg = new Subject<string>();
  public warningMsg = new Subject<string>();
  public wider = new BehaviorSubject<boolean>(false);
  public showPrevBtn = new BehaviorSubject<boolean>(false);
  public reserveMessage = new BehaviorSubject<boolean>(false);
}
