import type { EmbeddedViewRef, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevelopmentService } from '../../services';

@UntilDestroy()
@Directive({
  selector: '[appDeveloping]',
})
export class DevelopingDirective implements OnChanges {
  @Input() public appDeveloping: boolean | string | undefined;
  public highlight = false;
  public hideDevelopingContent = true;
  public embeddedViewRef: EmbeddedViewRef<any>;
  private hasView = false;

  private get borderColor(): string {
    return 'red';
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private developmentService: DevelopmentService,
  ) {
    this.developmentService.hideDevelopingContent.pipe(untilDestroyed(this)).subscribe((hide) => {
      this.hideDevelopingContent = hide;
      this.statusUpdated();
    });

    this.developmentService.highlightMode.pipe(untilDestroyed(this)).subscribe((highlight) => {
      this.highlight = highlight;
      this.statusUpdated();
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['appDeveloping']) {
      this.statusUpdated();
    }
  }

  private statusUpdated() {
    const hideContent = this.appDeveloping !== false && (this.hideDevelopingContent || this.developmentService.isProd);
    if (!hideContent && !this.hasView) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (hideContent && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    const isHighlight = this.appDeveloping !== false && this.highlight && !this.developmentService.isProd;
    if (this.embeddedViewRef?.rootNodes) {
      const firstNode = this.embeddedViewRef.rootNodes[0];
      if (firstNode) {
        if (isHighlight) {
          firstNode.style.border = `1px solid ${this.borderColor}`;
        } else {
          firstNode.style.border = 'none';
        }
      }
    }
  }
}
